<template>
  <div class="section__background-item" :style="styling">
    <slot />
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BackgroundImage',

  props: {
    styling: {
      type: Object as PropType<CSSProperties>
    }
  }
});
</script>
