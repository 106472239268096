export enum GuessThePictureType {
  STANDARD,
  RANDOM
}
export enum GuessThePictureEffectType {
  ZOOM = 'zoom',
  BLUR = 'blur',
  PIXEL = 'pixelate'
}
export enum GuessThePictureDesign {
  RADIOBUTTONS = 'radiobuttons',
  TEXT = 'text',
  CUSTOM = 'custom',
  BULLETS = 'bullets'
}

export enum listStyleType {
  DISC = 'disc',
  DECIMAL = 'decimal',
  LETTERS = 'letters',
  NONE = 'none'
}

export enum GuessThePictureTextPosition {
  TOP = 'top',
  BOTTOM = 'bottom'
}
