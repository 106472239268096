import type { CSSProperties } from 'vue';
import type { AddonContactCtaData } from '@/src/components/addons/contact-cta/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { AlignContentType } from '@/src/typings/enums/enums';

interface CtaItemOptionState {
  enabled: boolean;
  info?: string;
  link: string;
  label: string;
  iconClass: string;
}

interface AddonContactCTAState extends AddonModelState {
  iconColor: string;
  iconBackgroundColor: string;
  iconSize: number;
  roundedButtons: boolean;
  alignment: AlignContentType;
  availableOptions: CtaItemOptionState[];
  elementStyling: {
    layoutStyle?: CSSProperties;
    ctaItemStyle?: CSSProperties;
  };
}

export class AddonContactCTAModel extends AddonModel<AddonContactCtaData, AddonContactCTAState, 'contact-cta'> {
  parseAddon(data: AddonContactCtaData): void {
    const state = this.state;
    state.iconColor = data.settings?.icon_color || '#2E2E2E';
    if (data.settings.icon_background_color && data.settings.icon_background_color !== '') {
      state.iconBackgroundColor = data.settings.icon_background_color;
    } else {
      state.iconBackgroundColor = '#ffffff';
    }
    if (data.settings.icon_size && data.settings.icon_size !== '') {
      state.iconSize = Number(data.settings?.icon_size);
    } else {
      state.iconSize = 28;
    }
    if (data.settings.rounded_buttons) {
      state.roundedButtons = data.settings.rounded_buttons === '1';
    } else {
      state.roundedButtons = true;
    }
    if (data.settings.alignment) {
      state.alignment = data.settings.alignment;
    } else {
      state.alignment = AlignContentType.CENTER;
    }
    const availableOptions: CtaItemOptionState[] = [];
    if (data.settings.cta_email === '1') {
      const ctaItem: CtaItemOptionState = {
        enabled: true,
        info: data.settings?.email_info,
        link: `mailto: ${data.settings.email_info}`,
        label: 'Email',
        iconClass: 'fa-envelope'
      };
      availableOptions.push(ctaItem);
    }
    if (data.settings.cta_phone === '1') {
      const ctaItem: CtaItemOptionState = {
        enabled: true,
        info: data.settings?.phone_info,
        link: `tel: ${data.settings?.cta_phone}`,
        label: 'Phone',
        iconClass: 'fa-phone'
      };
      availableOptions.push(ctaItem);
    }
    if (data.settings.cta_map === '1') {
      const ctaItem: CtaItemOptionState = {
        enabled: true,
        info: data.settings?.map_info,
        link: data.settings?.map_info ? data.settings.map_info : '',
        label: 'Map',
        iconClass: 'fa-map-marker'
      };
      availableOptions.push(ctaItem);
    }
    state.availableOptions = availableOptions;

    state.elementStyling = state.elementStyling ?? {};
    state.elementStyling.layoutStyle = {
      textAlign: state.alignment
    };
    state.elementStyling.ctaItemStyle = AddonContactCTAModel.constructItemStyle(state);
  }

  isAddonValid(): boolean {
    const data = this.getData();
    return !(
      Object.keys(data.settings).length === 0 ||
      (data.settings.cta_email === '0' && data.settings.cta_map === '0' && data.settings.cta_phone === '0')
    );
  }

  private static constructItemStyle(state: AddonContactCTAState): CSSProperties {
    const customIconSize: string = state.iconSize ? Math.round(state.iconSize * 1.7) + 'px' : '48px';

    return {
      backgroundColor: state.iconBackgroundColor,
      color: state.iconColor,
      borderRadius: state.roundedButtons ? '50%' : '0',
      width: customIconSize,
      height: customIconSize,
      lineHeight: customIconSize,
      fontSize: state.iconSize ? state.iconSize + 'px' : '28px'
    };
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
