import EventEmitter from 'events';
import type TypedEventEmitter from 'typed-emitter';
import type { FlowpageModel } from '@/src/components/layout/FlowpageModel';
import type ActionsModel from '@/src/models/actions/ActionsModel';
import type { RedirectEvent } from '@/src/services/url';
import type { FormSubmitEvent } from '@/src/events/formSubmit';
import type { SectionModelType } from '@/src/typings/types/types';
import type { FormSubmitResponseEvent } from '@/src/events/formSubmitResponse';
import type { TipAFriendSubmitEvent } from '@/src/events/tipAFriendSubmit';
import type { BlueprintChangeRequestEvent } from '@/src/events/blueprintChangeRequest';

// Available events that can be listened to.
export type Events = {
  flowPageChange: (from?: FlowpageModel, to?: FlowpageModel) => void;
  sectionReady: (container: Element, model: SectionModelType) => void;
  sectionBeforeEnterAnimation: (model: SectionModelType) => void;
  sectionBeforeLeaveAnimation: (model: SectionModelType) => void;
  sectionBeforeEnter: (model: SectionModelType) => void;
  sectionBeforeLeave: (model: SectionModelType) => void;
  sectionAfterLeave: (model: SectionModelType) => void;
  invalidAction: (action: ActionsModel) => void;
  resizeFrame: () => void;
  popoverShow: (popoverId: number) => void;
  popoverHide: () => void;
  redirect: (event: RedirectEvent) => void;
  formSubmit: (event: FormSubmitEvent) => void;
  formSubmitResponse: (event: FormSubmitResponseEvent) => void;
  formSubmitted: (flowModel: SectionModelType) => void;
  formRegistrationLimit: (flowModel: SectionModelType) => void;
  formInvalidFields: (flowModel: SectionModelType) => void;
  tipAFriendSubmit: (event: TipAFriendSubmitEvent) => void;
  deviceChanged: () => void;
  blueprintChangeRequest: (event: BlueprintChangeRequestEvent) => void;
};

/**
 * Construct our event emitter. This uses the already existing EventEmitter in NodeJS / Browser & with
 * typing (as TypedEventEmitter) we can then add correct type safety to it at no overhead.
 *
 * @author Dannie Hansen <dannie@leadfamly.com>
 */
export const events = new EventEmitter() as TypedEventEmitter<Events>;
