import { BaseModel } from '@/src/models/BaseModel';
import type { GameIndicatorData, GameIndicatorIcon, GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorSettingsModel } from '@/src/components/indicators/Model';
import type { MetricData } from '@/src/store/campaign';

export type InstantWinData = {
  winner?: boolean;
  [key: string]: string | number | boolean | undefined;
};

export interface GameState {
  gameValid: boolean;
  winner?: boolean;
  indicators?: GameIndicatorSettingsModel;
  timeChallenge?: GameTimeChallenge;
}

interface GameIndicatorSettingsData {
  indicators: GameIndicatorData;
}

export interface GameIndicator {
  indicatorKey: string;
  icon: GameIndicatorIcon;
  metricKey: {
    [key: string]: string;
  };
  value: {
    [key: string]: number;
  };
}

export interface GameTimeChallenge {
  enabled: boolean;
  limit?: number;
}

export interface HasGameTimeChallenge {
  parseTimeChallenge(): GameTimeChallenge | undefined;
}

export interface HasSound {
  getSounds(): string[];
}

/**
 * Author: Jannik Fischer & Dannie Hansen
 */

export abstract class GameModel<Data, State, SdkSettings = unknown> extends BaseModel<
  Data & GameIndicatorSettingsData,
  State & GameState
> {
  parse(data: Data & GameIndicatorSettingsData) {
    this.parseGame(data);

    this.state.gameValid = this.isGameValid();

    if (data.indicators) {
      if (this.state.indicators) {
        this.state.indicators.setData(data.indicators);
      } else {
        this.state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
      this.state.indicators.setIndicatorPosition(this.getIndicatorPosition());
      this.state.indicators.setAvailableIndicators(this.getIndicators());
    } else {
      this.state.indicators = undefined;
    }

    if (this.hasTimeChallenge()) {
      const timeChallengeState = this.parseTimeChallenge();

      if (timeChallengeState?.enabled && timeChallengeState.limit) {
        this.state.timeChallenge = timeChallengeState;
      } else {
        this.state.timeChallenge = undefined;
      }
    } else {
      this.state.timeChallenge = undefined;
    }
  }

  protected hasTimeChallenge(): this is HasGameTimeChallenge {
    return 'parseTimeChallenge' in this;
  }

  public hasInstantWin(): boolean {
    return false;
  }

  public isGameValid(): boolean {
    return false;
  }

  public setInstantWinnerData(data: InstantWinData): void {
    const state = this.state;
    state.winner = !!data.winner;
  }

  public abstract getIndicatorPosition(): GameIndicatorPosition;

  /**
   * Indicators take metric key and value as object, the key is indicator type.
   *  indicator type e.g. "spins_left" or "rounds_left"
   *  @example
   * indicatorKey: string,
   * icon: GameIndicatorIcon.SPINS
   * metricKey: {
   *  indicatorType: string
   * }
   * value: {
   *  indicatorType: number
   * }
   */

  public abstract getIndicators(): GameIndicator[];

  public abstract parseGame(data: Data): void;

  /**
   * Returns the currently known metric data which the game
   * would like to expose through the SDK.
   */
  public get sdkMetrics(): MetricData | undefined {
    return undefined;
  }

  public get sdkSettings(): SdkSettings | undefined {
    return undefined;
  }
}
