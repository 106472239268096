import type { ShowVoteType } from '@/src/components/games/poll/Enums';
import { DisplayType, ResultType, VoteType } from '@/src/components/games/poll/Enums';
import type {
  GamePollAdvancedData,
  GamePollAnswerData,
  GamePollData,
  GamePollGeneralData,
  GamePollLayoutData,
  GamePollQuestionData
} from '@/src/components/games/poll/Data';
import type { GameIndicator, GameState, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { hexToRGBA, shuffle } from '@/src/utilities/Utilities';
import useDevice from '@/src/hooks/useDevice';
import type { TransitionTypes } from '@/src/typings/types/types';
import type { AnswerLayoutSettingsState } from '@/src/models/settings/AnswerLayoutSettingsModel';
import { AnswerLayoutSettingsModel, ImageEffectTypes } from '@/src/models/settings/AnswerLayoutSettingsModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';
import { useCampaignStore } from '@/src/store/campaign';
import { CampaignAdsSizeType, CampaignDeviceType } from '@/src/typings/enums/enums';

export interface GamePollAnswerState {
  id: string | number; // its number in db, but the first 3 demo content are with strings (legacy)
  headline?: string;
  headline2?: string;
  thumbnail?: string;
  description?: string;
  value: string;
  text?: string;
  placement?: GamePollPlacementState;
  votes: number;
  buttonLabel: string;
  paused: boolean;
}

export interface GamePollQuestionState {
  question?: string;
  questionImage?: string;
  questionVideo?: string;
  displayType: DisplayType;
  videoWidth?: string;
  videoButtonEnable?: boolean;
  videoPopoverBg?: string;
  videoPopoverCloseColor?: string;
  multiAnswer: boolean;
  multiAnswerOptions?: number;
  pausedLabel?: string;
  answerLayout: AnswerLayoutSettingsModel;
}

export interface GamePollGeneralState {
  answerBtn: string;
  popupText: string;
  confirmText: string;
  cancelText: string;
  sortByResult: boolean;
  randomizeAnswers?: boolean;
  resultIn?: ResultType;
  animateResult?: boolean;
  showResultAfterSubmit?: boolean;
  showConfirmPopup?: boolean;
  selectAnswerBtn?: string;
  popupBackground?: string;
  confirmBackground?: string;
  closeBackground?: string;
  overlayBackground?: string;
  voteType?: VoteType;
  voteButton?: string;
}

export interface GamePollState extends GameState {
  general: GamePollGeneralState;
  question: GamePollQuestionState;
  layout: GamePollLayoutState;
  answers?: GamePollAnswerState[];
  elementStyling: string;
  iconStyles: IconStylesState;
  totalVotes: number;
  showVotes: ShowVoteType;
  showAnswerButton: boolean;
  advanced: GamePollAdvancedState;
}

export interface GamePollAdvancedState {
  sound: GamePollAdvancedSoundState;
  animation: GamePollAdvancedAnimationState;
}

export interface GamePollAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GamePollAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

export interface IconStylesState {
  checkmarkStyle?: iconStyle;
  checkmarkActiveStyle?: iconStyle;
  radioStyle?: iconStyle;
  radioActiveStyle?: iconStyle;
}

interface iconStyle {
  color?: string;
  backgroundColor?: string;
}

export interface GamePollTranslations {
  confirm?: string;
  yes?: string;
  no?: string;
}

export interface GamePollLayoutState {
  image: string;
  place: GamePollPlacementState;
}

interface GamePollPlacementState {
  id: number;
  label: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export class GamePollModel extends GameModel<GamePollData, GamePollState> implements HasSound {
  parseGame(data: GamePollData) {
    const { isMobile } = useDevice();
    const state = this.state;

    state.question = GamePollModel.constructQuestionState(data.question, data.general);
    state.general = GamePollModel.constructGeneralState(data.general);

    state.answers = GamePollModel.parseAnswersState(data);
    if (state.answers) {
      if (data.general.randomize_answers === '1') {
        shuffle(state.answers);
      }
      if (data.general.sort_by_result === '1') {
        state.answers.sort(function (a, b) {
          if (a.votes > b.votes) {
            // A greather than b,
            // allow it to be above b.
            return -1;
          } else if (a.votes < b.votes) {
            // A is less than b,
            // allow it to be below b.
            return 1;
          }

          return 0;
        });
      }

      state.totalVotes = 0;
      state.answers.forEach((answer) => {
        state.totalVotes += answer.votes;
      });
    }

    state.elementStyling = GamePollModel.constructElementStyling(state.question.answerLayout.state);

    if (data.layout) {
      state.layout = GamePollModel.constructLayoutState(data.layout, isMobile);
    }

    if (data.general.show_votes?.enabled === '1') {
      state.showVotes = data.general.show_votes.type;
    }

    const layoutType =
      (data.question?.display_type === DisplayType.IMAGE ||
        data.question?.display_type === DisplayType.IMAGE_HEADLINE) &&
      state.layout?.image;

    if (data.question.display_type !== DisplayType.VIDEO || data.question.multi_answer === '1' || layoutType) {
      state.showAnswerButton = data.general.show_answer_button ? data.general.show_answer_button === '1' : true;
    }

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GamePollModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GamePollModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructAdvancedSoundState(data: GamePollAdvancedData | undefined): GamePollAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructAdvancedAnimationState(
    data: GamePollAdvancedData | undefined
  ): GamePollAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private static parseAnswersState(data: GamePollData): GamePollAnswerState[] | undefined {
    if (!data.question.answers) {
      return undefined;
    }
    return data.question.answers.map((answer) => {
      return GamePollModel.constructAnswerState(answer, data.general.vote_btn);
    });
  }

  private static constructGeneralState(data: GamePollGeneralData): GamePollGeneralState {
    const answers: GamePollAnswerState[] = [];

    if (data?.randomize_answers === '1') {
      shuffle(answers);
    }

    return {
      sortByResult: data.sort_by_result === '1' || false,
      resultIn: data.result_in || ResultType.PERCENTAGE,
      animateResult: data.animate_result === '1' || false,
      showResultAfterSubmit: data.show_result_after_submit ? data.show_result_after_submit === '1' : false,
      popupBackground: data.popup_background || '#ffffff',
      confirmBackground: data.confirm_background || '#fc7169',
      closeBackground: data.close_background || '#b6bece',
      overlayBackground: hexToRGBA(data?.overlay_background ? data.overlay_background : '#5e6e8d', 0.9),
      ...(data.vote_btn && { voteButton: data.vote_btn }),
      answerBtn: data.answer_btn || 'Answer',
      selectAnswerBtn: data.select_answer_btn || 'Choose an answer',
      voteType: data.vote_type || VoteType.ITEM,
      showConfirmPopup: data.show_confirm_popup === '1' || false,
      confirmText: data.confirm_text || 'Yes',
      cancelText: data.cancel_text || 'No close again',
      popupText: data.popup_text || 'Confirm your vote'
    };
  }

  private static constructAnswerState(data: GamePollAnswerData, voteBtnLabel?: string): GamePollAnswerState {
    const { isMobile } = useDevice();

    const campaignState = useCampaignStore();

    let placement = isMobile ? data.placement?.mobile : data.placement?.desktop;

    if (
      campaignState.model?.state.deviceType === CampaignDeviceType.ADS &&
      campaignState.model?.state.adsSizeType === CampaignAdsSizeType.FIXED
    ) {
      placement = data.placement?.default;
    }

    return {
      id: data.id,
      buttonLabel: voteBtnLabel || data.button_label || 'Answer',
      value: data.value || '',
      votes: data.votes || 0,
      ...(data.description && { description: data.description }),
      ...(data.headline && { headline: data.headline }),
      ...(data.headline2 && { headline2: data.headline2 }),
      ...(data.thumbnail && { thumbnail: data.thumbnail }),
      ...(data.text && { text: data.text }),
      ...(placement && {
        placement: {
          id: Number(placement.id),
          label: placement.label,
          x: Number(placement.x),
          y: Number(placement.y),
          w: Number(placement.w),
          h: Number(placement.h)
        }
      }),
      paused: data.paused === '1'
    };
  }

  private static constructQuestionState(
    data: GamePollQuestionData,
    generalData: GamePollGeneralData
  ): GamePollQuestionState {
    let answerLayout: AnswerLayoutSettingsModel | undefined;

    if (answerLayout) {
      answerLayout.setData(generalData);
    } else {
      answerLayout = new AnswerLayoutSettingsModel(generalData);
    }

    return {
      ...(data.question && { question: data.question }),
      ...(data.question_video?.enable === '1' && { questionVideo: data.question_video.url }),
      ...(data.question_image && { questionImage: data.question_image }),
      ...{ displayType: data.display_type || DisplayType.TEXT },
      ...{ multiAnswer: data.multi_answer === '1' || false },
      ...(data.multi_answer_options && { multiAnswerOptions: Number(data.multi_answer_options) }),

      // type video...
      ...(data.display_type === DisplayType.VIDEO && {
        videoWidth: data.video_width || '640px',
        videoPopoverBg: data.video_popover_bg || '#000000',
        videoPopoverCloseColor: data.video_popover_close_color || '#ffffff',
        videoButtonEnable: data.video_button_enable === '1' || false
      }),
      ...(data.disable_answer && { pausedLabel: data.paused_label || 'This answer is disabled at the moment' }),
      answerLayout
    };
  }

  private static constructLayoutState(data: GamePollLayoutData, isMobile: boolean): GamePollLayoutState {
    const _data = isMobile ? data.mobile : data.default ?? data.desktop;
    return {
      image: _data.image,
      place: {
        id: Number(_data.place.id),
        label: _data.place.label,
        x: Number(_data.place.x),
        y: Number(_data.place.y),
        w: Number(_data.place.w),
        h: Number(_data.place.h)
      }
    };
  }

  private static constructElementStyling(answerLayout: AnswerLayoutSettingsState): string {
    const { textColor, border, backgroundColor, margin, padding, width, activeAnswer, imageEffect } = answerLayout;

    return `
    .poll .question .question__answers:not(.question__answers--images) .question__answer-item {
      ${textColor ? `color: ${textColor};` : ''}
      ${border?.thickness && border?.color ? `border: ${border.thickness}px solid ${border.color};` : ''}
      ${backgroundColor ? `background-color: ${backgroundColor};` : ''}
      ${margin ? `margin-bottom: ${margin}px;` : ''}
      ${
        padding
          ? `padding-top: ${Math.ceil(Number(padding) / 2) / 10}rem; padding-bottom: ${
              Math.ceil(Number(padding) / 2) / 10
            }rem;`
          : ''
      }
      ${width ? `width: ${width};` : ''}
    }
    .poll .question .question__answers:not(.question__answers--images) .question__answer-item.question__answer-item--selected,
    .category-desktop .poll .question .question__answers .question__answer-item:hover {
      ${activeAnswer?.textColor ? `color: ${activeAnswer.textColor} !important;` : ''}
      ${activeAnswer?.backgroundColor ? `background-color: ${activeAnswer.backgroundColor} !important;` : ''}
    }
    ${
      imageEffect?.type === ImageEffectTypes.BORDER && imageEffect.borderThickness && imageEffect.color
        ? `
      .poll .question .question__answers.question__answers--images .question__answer-item img {
        border: ${imageEffect.borderThickness}px solid transparent !important;
      }
      .site--desktop .poll .question .question__answers.question__answers--images .question__answer-item:hover img,
      .poll .question .question__answers.question__answers--images .question__answer-item.question__answer-item--selected img {
        border: ${imageEffect.borderThickness}px solid ${imageEffect.color} !important;
      }
    `
        : ''
    }
    ${
      imageEffect?.type === ImageEffectTypes.GREYSCALE
        ? `
      .poll .question .question__answers.question__answers--images .question__answer-item {
        -webkit-filter: grayscale(100%) !important;
        filter: grayscale(100%) !important;
      }
      .site--desktop .poll .question .question__answers.question__answers--images .question__answer-item:hover,
      .poll .question .question__answers.question__answers--images .question__answer-item.question__answer-item--selected {
        -webkit-filter: grayscale(0%) !important;
        filter: grayscale(0%) !important;
      }
    `
        : ''
    }
    ${
      imageEffect?.type === ImageEffectTypes.ZOOM_OUT
        ? `
      .site--desktop .poll .question .question__answers.question__answers--images .question__answer-item:hover,
      .poll .question .question__answers.question__answers--images .question__answer-item.question__answer-item--selected {
        -webkit-transform: scale(1.1) !important;
        transform: scale(1.1) !important;
        position: relative !important;
        z-index: 1 !important;
      }
    `
        : ''
    }
    ${
      imageEffect?.type === ImageEffectTypes.ZOOM_IN
        ? `
      .site--desktop .poll .question .question__answers.question__answers--images .question__answer-item:hover,
      .poll .question .question__answers.question__answers--images .question__answer-item.question__answer-item--selected {
        -webkit-transform: scale(0.9) !important;
        transform: scale(0.9) !important;
      }
    `
        : ''
    }
    ${
      imageEffect?.type === ImageEffectTypes.OVERLAY
        ? `
      .site--desktop .poll .question .question__answers.question__answers--images .question__answer-item:hover img,
      .poll .question .question__answers.question__answers--images .question__answer-item.question__answer-item--selected img {
        opacity: 0.5 !important;
      }
      .site--desktop .poll .question .question__answers.question__answers--images .question__image:hover,
      .poll .question .question__answers.question__answers--images .question__answer-item.question__answer-item--selected {
        background-color: ${imageEffect.color} !important;
      }
    `
        : ''
    }
    ${
      imageEffect === undefined // default opacity effect
        ? `
      .poll .question__answers .question__answer-item.question__image {
        opacity: 0.5;
      }
    `
        : ''
    }
  `;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
