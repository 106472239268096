import type { CSSProperties } from 'vue';
import type { BorderType, ContentPositionType, HorizontalPositionType } from '@/src/typings/enums/enums';

export enum InlineHeightEnum {
  FIXED,
  WINDOW,
  FLUID
}

export interface InlineElementHeight {
  type: InlineHeightEnum;
  value?: number;
  subtractWindow?: number;
  subtractFormat?: string;
}

export interface InlineElement {
  height?: InlineElementHeight;
  padding: {
    right?: number;
    left?: number;
    bottom: number;
    top: number;
  };
  margin?: {
    right?: number | string;
    left?: number | string;
    bottom?: number;
    top?: number;
  };
  verticalPosition?: ContentPositionType;
  horizontalPosition?: HorizontalPositionType;
  zIndex?: number;
  minHeight?: number;
  overflowY?: CSSProperties['overflowY'];
  overflowX?: CSSProperties['overflowX'];
  border?: BorderState;
  shadow?: ShadowState;
  color?: string;
}

export interface BorderState {
  type?: BorderType;
  color?: string;
  width?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

export interface ShadowState {
  color?: string;
  blur?: number;
  spread?: number;
  horizontal?: number;
  vertical?: number;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}
