import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import axiosRetry from 'axios-retry';
import type { IAxiosRetryConfig } from 'axios-retry';
import * as Sentry from '@sentry/vue';
import { serialize } from 'object-to-formdata';
import { useCampaignStore } from '@/src/store/campaign';

export default function useAxios<ResponseData>(
  url: string,
  data?: object,
  headers?: Record<string, string>,
  formDataSigner?: (data: object) => Promise<string>,
  retryCondition?: IAxiosRetryConfig['retryCondition']
) {
  const campaignStore = useCampaignStore();
  const requestId = uuidv4();
  const retryDelay = 1000;

  // Axios instance
  const client = axios.create();
  axiosRetry(client, {
    retryDelay: (retryCount) => {
      return retryDelay * 2 ** retryCount;
    },
    retries: 5,
    shouldResetTimeout: true,
    ...(retryCondition && { retryCondition })
  });

  const getTransformedData = async (): Promise<object> => {
    if (formDataSigner) {
      try {
        const signedData = await formDataSigner(data ?? {});

        return {
          data: signedData
        };
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Encountered an error when attempting to sign request data. Falling back to sending without.', e);

        Sentry.captureException(e);
      }
    }

    return data ?? {};
  };

  const fetchData = async (): Promise<ResponseData> => {
    const cookies = undefined;
    const transformedData = await getTransformedData();

    return (
      await client.get<ResponseData>(url, {
        url,
        // BE has a timeout of ~28 seconds - so we need to make sure we don't exceed that.
        timeout: 19000,
        params: {
          ...transformedData,
          request_id: requestId
        },
        headers: cookies
          ? {
              Cookie: cookies
            }
          : {}
      })
    ).data;
  };

  const fetchDataClean = async (): Promise<ResponseData> => {
    const transformedData = await getTransformedData();

    return (
      await client.get<ResponseData>(url, {
        params: {
          ...transformedData,
          request_id: requestId
        },
        // BE has a timeout of ~28 seconds - so we need to make sure we don't exceed that.
        timeout: 19000
      })
    ).data;
  };

  const prepareUrl = (urlToRequest: string): string => {
    // Append request ID to the URL.
    if (urlToRequest.includes('?')) {
      urlToRequest += '&request_id=' + encodeURIComponent(requestId);
    } else {
      urlToRequest += '?request_id=' + encodeURIComponent(requestId);
    }

    return urlToRequest;
  };

  const postData = async (withHeaders = true): Promise<ResponseData> => {
    const transformedData = await getTransformedData();

    return (
      await client.post<ResponseData>(url, transformedData, {
        // BE has a timeout of ~28 seconds - so we need to make sure we don't exceed that.
        timeout: 19000,
        headers: {
          ...(withHeaders &&
            campaignStore.model?.state.edit?.csrfToken && {
              'X-CSRF-TOKEN': campaignStore.model?.state.edit.csrfToken
            })
        }
      })
    ).data;
  };

  const postDataFormData = async (): Promise<ResponseData> => {
    // Fallback to old way of sending the form data when no signer is provided.
    const transformedData = formDataSigner ? await getTransformedData() : serialize(data, { indices: true });

    return (
      await client.post<ResponseData>(prepareUrl(url), transformedData, {
        // BE has a timeout of ~28 seconds - so we need to make sure we don't exceed that.
        timeout: 19000,
        headers: campaignStore.model?.state.edit?.csrfToken
          ? {
              'X-CSRF-TOKEN': campaignStore.model?.state.edit.csrfToken,
              'Content-Type': formDataSigner ? 'application/json' : 'application/x-www-form-urlencoded'
            }
          : {
              'Content-Type': formDataSigner ? 'application/json' : 'application/x-www-form-urlencoded'
            }
      })
    ).data;
  };

  const postDeleteFormData = async (): Promise<ResponseData> => {
    const transformedData = await getTransformedData();

    return (
      await client.delete(prepareUrl(url), {
        // BE has a timeout of ~28 seconds - so we need to make sure we don't exceed that.
        timeout: 19000,
        data: transformedData,
        headers: campaignStore.model?.state.edit?.csrfToken
          ? {
              'X-CSRF-TOKEN': campaignStore.model?.state.edit.csrfToken
            }
          : {}
      })
    ).data;
  };

  const deleteData = async (): Promise<ResponseData> => {
    return (
      await client.delete(prepareUrl(url), {
        // BE has a timeout of ~28 seconds - so we need to make sure we don't exceed that.
        timeout: 19000,
        headers: campaignStore.model?.state.edit?.csrfToken
          ? {
              'X-CSRF-TOKEN': campaignStore.model?.state.edit.csrfToken
            }
          : {}
      })
    ).data;
  };

  const putData = async (): Promise<ResponseData> => {
    const transformedData = await getTransformedData();

    return (
      await client.put(prepareUrl(url), transformedData, {
        // BE has a timeout of ~28 seconds - so we need to make sure we don't exceed that.
        // BE is only on relative paths. So absolute paths we allow unlimited timeout.
        ...(!url.startsWith('http') && { timeout: 19000 }),
        headers
      })
    ).data;
  };

  return {
    postDataFormData,
    postData,
    fetchData,
    fetchDataClean,
    postDeleteFormData,
    putData,
    deleteData
  };
}
