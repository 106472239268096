<template>
  <div
    class="content__item-form-type content__item-form-type--text"
    :class="{ 'content__item-form-type--phone-code': model.state.country.codesEnabled }"
  >
    <div
      v-if="model.state.country.codesEnabled"
      class="content__item-form-group content__item-form-group--select form-group"
      :class="{
        'form-group--error': showValidationErrors && !state.isValid,
        'form-group--has-value': countryCodeValue && countryCodeValue !== '',
        'form-group--valid': !!state.isValid
      }"
    >
      <select
        :id="`${model.state.id}`"
        v-model="countryCodeValue"
        class="content__item-form-select-origin form-control"
        :name="`form-codes[${model.state.id}]`"
        :disabled="model.state.readonly || model.state.country?.codeTags?.length === 1"
      >
        <option v-for="(item, index) in model.state.country.codeTags" :key="index" :value="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div
      class="content__item-form-group content__item-form-group--text form-group"
      :class="{
        'form-group--error': showValidationErrors && !state.isValid,
        'form-group--has-value': state.value?.input && state.value.input !== '',
        'form-group--is-focus': isFocus,
        'form-group--valid': !!state.isValid
      }"
    >
      <label :style="labelStyle" class="content__item-form-label content__item-form-label--text">{{
        model.state.label
      }}</label>

      <input
        v-model="inputValue"
        class="content__item-form-text form-control"
        :type="model.state.inputHtmlType"
        :name="`form[${model.state.id}]`"
        :disabled="fieldDisabled"
        :aria-label="model.state.label"
        :placeholder="model.state.placeholder"
        :autocomplete="model.state.disableAutocomplete ? 'off' : 'on'"
        :maxlength="model.state?.validation?.maxChars || undefined"
        @focus="onFocus"
        @blur="onBlur"
        @paste="onPaste"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType } from 'vue';
import { computed, defineComponent, ref, watch } from 'vue';
import type { FormElementTextModel } from '@/src/components/addons/registration/Models/FormElementTextModel';
import { FieldType } from '@/src/typings/enums/enums';

export default defineComponent({
  name: 'FormElementText',
  props: {
    model: {
      type: Object as PropType<FormElementTextModel>,
      required: true
    },
    showValidationErrors: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const model = props.model;
    const state = model.state;
    const isFocus = ref(false);
    const countryCodeValue = ref(state.value?.countryCode ?? '');
    const inputValue = ref(state.value?.input ?? '');

    const initialValue = model.getInitialValue();

    if (!countryCodeValue.value && state.country.codesEnabled && state.country.codeTags) {
      countryCodeValue.value = state.country.codeTags[0];
    }

    const onBlur = () => {
      isFocus.value = false;
    };

    const onFocus = () => {
      isFocus.value = true;
    };

    const fieldDisabled = computed(() => {
      if (state.readonlyEdit && initialValue?.toString.length !== 0) {
        return false;
      } else if (state.readonly && initialValue && initialValue.toString().length !== 0) {
        return true;
      } else if (state.readonly && !state.readonlyEdit) {
        return true;
      }
      return false;
    });

    const labelStyle = computed<CSSProperties>(() => {
      return state.country.codesEnabled ? { width: 'auto' } : {};
    });

    const onPaste = (e: ClipboardEvent) => {
      if (state.type === FieldType.EMAIL_CONFIRM) {
        e.preventDefault();
        return false;
      }
    };

    watch(inputValue, () => {
      if (!inputValue.value) {
        if (countryCodeValue.value) {
          state.value = {
            countryCode: countryCodeValue.value,
            input: inputValue.value
          };
        } else {
          state.value = undefined;
        }
        return;
      }

      if (!state.value) {
        state.value = {
          input: inputValue.value,
          countryCode: countryCodeValue.value
        };
        return;
      }

      if (inputValue.value !== state.value.input) {
        state.value.input = inputValue.value;
      }
    });

    watch(
      countryCodeValue,
      () => {
        if (!state.value) {
          state.value = {
            countryCode: countryCodeValue.value,
            input: inputValue.value
          };
          return;
        }

        if (countryCodeValue.value !== state.value.countryCode) {
          state.value.countryCode = countryCodeValue.value;
        }
      },
      {
        immediate: true
      }
    );

    watch(
      () => state.value?.input,
      () => {
        if (inputValue.value !== state.value?.input) {
          inputValue.value = state.value?.input ?? '';
        }
      }
    );

    watch(
      () => state.value?.countryCode,
      () => {
        if (countryCodeValue.value !== state.value?.countryCode) {
          countryCodeValue.value = state.value?.countryCode ?? '';
        }
      }
    );

    return {
      labelStyle,
      state,
      fieldDisabled,
      onFocus,
      onBlur,
      isFocus,
      onPaste,
      countryCodeValue,
      inputValue
    };
  }
});
</script>
