import type {
  GameCampaignLinkStateData,
  GameCampaignLinkCampaignData,
  GameCampaignLinkData,
  GameCampaignLinkDisplayData,
  GameCampaignLinkGeneralData
} from './Data';
import type { GameIndicator } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { AlignContentType } from '@/src/typings/enums/enums';
import ActionsModel from '@/src/models/actions/ActionsModel';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { getDeviceData } from '@/src/hooks/useDevice';
import { GameEndingConditionType } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';

export enum DisplayTypes {
  GRID,
  IMAGE
}

interface DisplayState {
  type: DisplayTypes;
  columns?: number;
  width?: string;
  alignment?: AlignContentType;
}

interface GeneralState {
  identifier: {
    field?: number;
  };
  onComplete?: ActionsModel;
  linear: boolean;
  endCampaignOnGameFinish: boolean;
  display?: DisplayState;
}

interface CampaignStateItemState {
  image: string;
  text: string;
}

export type CampaignStateType = 'inactive' | 'active' | 'result';

export type CampaignStateDeviceState = {
  [key in CampaignStateType]: CampaignStateItemState;
};

export interface CampaignState {
  id: number;
  url: string;
  campaignId?: number;
  name?: string;
  canRetake?: boolean;
  state?: CampaignStateDeviceState;
  conditionsToPass?: VisibilityConditionsModel;
  behavior?: {
    winner?: ActionsModel;
    loser?: ActionsModel;
  };
  visibilityCondition?: VisibilityConditionsModel;
}

export interface GameCampaignLinkState {
  general: GeneralState;
  campaigns?: CampaignState[];
}

export class GameCampaignLinkModel extends GameModel<GameCampaignLinkData, GameCampaignLinkState> {
  parseGame(data: GameCampaignLinkData) {
    const state = this.state;

    state.general = state.general ?? undefined;

    if (data.general) {
      state.general = GameCampaignLinkModel.constructGeneralState(data.general);
    }

    if (data.campaigns?.list) {
      const existingCampaignModels: Record<number, CampaignState> = {};

      if (state.campaigns) {
        state.campaigns.forEach((campaign) => {
          existingCampaignModels[campaign.id] = campaign;
        });
      }

      state.campaigns = data.campaigns.list?.map<CampaignState>((item) => {
        return GameCampaignLinkModel.constructCampaignState(item, existingCampaignModels);
      });
    } else {
      state.campaigns = undefined;
    }
  }

  private static constructGeneralState(data: GameCampaignLinkGeneralData): GeneralState {
    let onComplete: ActionsModel | undefined;

    if (data.on_complete) {
      if (onComplete) {
        onComplete.setData(data.on_complete);
      } else {
        onComplete = new ActionsModel(data.on_complete);
      }
    } else {
      onComplete = undefined;
    }

    return {
      identifier: {
        field: data.identifier?.field ? Number(data.identifier.field) : undefined
      },
      onComplete,
      linear: data.linear ? data.linear === '1' : true,
      endCampaignOnGameFinish: data.end_campaign_on_game_finish === '1',
      display: GameCampaignLinkModel.parseGeneralDisplayDeviceData(data)
    };
  }

  private static parseGeneralDisplayDeviceData(data: GameCampaignLinkGeneralData): DisplayState | undefined {
    if (data.display) {
      const useData = getDeviceData<GameCampaignLinkDisplayData>(data.display);

      if (!useData) {
        return undefined;
      }

      return GameCampaignLinkModel.constructGeneralDisplayState(useData);
    }

    return undefined;
  }

  private static constructGeneralDisplayState(data: GameCampaignLinkDisplayData): DisplayState {
    return {
      type: DisplayTypes.GRID,
      ...(data.col && { columns: Number(data.col) }),
      ...(data.width && { width: data.width }),
      ...(data.alignment && { alignment: data.alignment })
    };
  }

  private static constructCampaignState(
    data: GameCampaignLinkCampaignData,
    existingCampaigns: Record<number, CampaignState>
  ): CampaignState {
    const id = Number(data.id);
    // eslint-disable-next-line security/detect-object-injection
    const campaignState: CampaignState = existingCampaigns[id] ?? {};

    campaignState.id = id;
    campaignState.campaignId = Number(data.campaign_id);
    campaignState.name = campaignState.name ?? data.name;
    campaignState.url = campaignState.url ?? data.url;
    campaignState.canRetake = campaignState.canRetake ?? data.can_retake === '1';
    campaignState.behavior = campaignState.behavior ?? {};

    if (data.state) {
      campaignState.state = GameCampaignLinkModel.parseCampaignStateDeviceData(data);
    } else {
      campaignState.state = undefined;
    }

    if (data.behavior?.winner) {
      if (campaignState.behavior?.winner) {
        campaignState.behavior.winner.setData(data.behavior.winner);
      } else {
        campaignState.behavior.winner = new ActionsModel(data.behavior.winner);
      }
    } else {
      campaignState.behavior.winner = undefined;
    }

    if (data.conditions_to_pass) {
      if (campaignState.conditionsToPass) {
        campaignState.conditionsToPass.setData(data.conditions_to_pass);
      } else {
        campaignState.conditionsToPass = new VisibilityConditionsModel(data.conditions_to_pass);
      }
    } else {
      campaignState.conditionsToPass = undefined;
    }

    if (data.behavior?.loser) {
      if (campaignState.behavior?.loser) {
        campaignState.behavior.loser.setData(data.behavior.loser);
      } else {
        campaignState.behavior.loser = new ActionsModel(data.behavior.loser);
      }
    } else {
      campaignState.behavior.loser = undefined;
    }

    if (data.visibility_condition) {
      data.visibility_condition.condition = GameEndingConditionType.NONE;

      if (campaignState.visibilityCondition) {
        campaignState.visibilityCondition.setData(data.visibility_condition);
      } else {
        campaignState.visibilityCondition = new VisibilityConditionsModel(data.visibility_condition);
      }
    } else {
      campaignState.visibilityCondition = undefined;
    }

    return campaignState;
  }

  private static parseCampaignStateDeviceData(
    data: GameCampaignLinkCampaignData
  ): CampaignStateDeviceState | undefined {
    const useData = getDeviceData<GameCampaignLinkStateData>(data.state);

    if (!useData) {
      return undefined;
    }

    return GameCampaignLinkModel.constructCampaignStateState(useData);
  }

  public isGameValid(): boolean {
    return true;
  }

  private static constructCampaignStateState(data: GameCampaignLinkStateData): CampaignStateDeviceState {
    return {
      inactive: {
        image: data.inactive_image,
        text: data.inactive_text
      },
      active: {
        image: data.active_image,
        text: data.active_text
      },
      result: {
        image: data.result_image,
        text: data.result_text
      }
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }
}
