import type { GameState } from '@/src/models/GameModel';
import type { RepeatableData } from '@/src/store/repeatable';

export type PrioritySizeType = 'pixel' | 'percent';

export enum PriorityFontElementEnum {
  H1,
  H2,
  H3,
  H5,
  H4,
  H6
}

export enum PriorityAlignmentEnum {
  LEFT,
  CENTER,
  RIGHT
}

export enum PriorityItemType {
  SQUARE,
  CIRCLE
}

export enum PriorityItemSizeUnit {
  PIXEL,
  PERCENTAGE
}

export interface GamePriorityState extends GameState {
  general: PriorityGeneralSettings;
  layout: {
    enabled: boolean;
  };
  dropzones: PriorityDropzoneItem[];
  priorityItems: PriorityItem[];
}

export interface PriorityDropzoneStyling {
  backgroundColor?: string;
  border: {
    enabled: boolean;
    thickness?: number;
    color?: string;
  };
  height?: number;
  width?: PriorityCustomWidth;
  font: {
    enabled: boolean;
    color?: string;
    element?: PriorityFontElementEnum;
  };
}

export enum PriorityDropzonePlacementType {
  DEFAULT,
  CUSTOM
}

export interface PriorityDropzonePlacement {
  type: PriorityDropzonePlacementType;
  xAxis?: number;
  yAxis?: number;
  height?: number;
  width?: number;
}

export interface PriorityDropzoneSize {
  enabled: boolean;
  unit?: PriorityItemSizeUnit;
  width?: number;
  height?: number;
}

export interface PriorityDropzoneItem {
  id: string;
  image?: string;
  label?: string;
  groupLabel?: string;
  type: PriorityItemType;
  placement?: PriorityDropzonePlacement;
  group?: number;
  size: PriorityDropzoneSize;
  priorityItems: number[];
  allowedAnswerIds?: number[];
  validation: PriorityAnswerValidation;
  dropzoneType: PriorityDropzoneType;
}

export interface PriorityCustomWidth {
  enabled: boolean;
  value?: number;
  type?: PrioritySizeType;
}

export interface PriorityItemSize {
  width?: number;
  height?: number;
}

export interface PriorityItem {
  id: number;
  image: string;
  zIndex?: number;
  customWidth: PriorityCustomWidth;
  initialPosition?: {
    x: number;
    y: number;
  };
  initialSize: PriorityItemSize;
  isPlaced: boolean;
}
export interface PriorityAnswerButton {
  enabled: boolean;
  label: string;
}

export enum PriorityOrder {
  FIXED,
  RANDOMIZE
}

export interface PriorityImageOrder {
  enabled: boolean;
  shuffled?: boolean;
}

export type PriorityDropzoneType = 'image' | 'label' | 'none';

export type PriorityAnswerType = 'multiple' | 'default';

export interface PriorityAnswer {
  type: PriorityAnswerType;
  hide: boolean;
  showCorrect: boolean;
}

export interface PriorityDropzoneSettings {
  allowMultipleImages: boolean;
  alignment: PriorityAlignmentEnum;
  customPlacement: {
    enabled: boolean;
    background?: string;
  };
  layoutType: PriorityItemType;
  styling: PriorityDropzoneStyling;
  answer: PriorityAnswer;
  rows: number;
}

export interface PriorityGeneralSettings {
  mapToPlaceholder: boolean;
  removePlaceholderImage: boolean;
  answerButton: PriorityAnswerButton;
  fixedImageOrder: PriorityImageOrder;
  dropzone: PriorityDropzoneSettings;
  groupByDropzone: boolean;
}

export interface DraggableEmitData {
  id: number;
  z?: number;
  x?: number;
  y?: number;
}

export interface RelativeScreenPosition {
  x: number;
  y: number;
}

export interface PriorityGroup {
  label?: string;
  priorityItems?: PriorityItem[];
}

export interface PriorityAnswerValidation {
  enabled: boolean;
  isValidating: boolean;
  isCorrect: boolean;
}

export interface PriorityPlacedItems {
  [key: string]: PriorityItem[];
}

export interface PriorityRepeatable extends RepeatableData {
  image: string;
  placement_number: number;
}
