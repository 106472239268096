import type { CSSProperties } from 'vue';
import { getWithTimezone } from './../../../utilities/DateHelpers';
import type {
  AddonCountdownData,
  AddonCountdownMobileData,
  AddonCountdownSettingData,
  AddonCountdownSettingLabelData
} from '@/src/components/addons/countdown/Data';
import { CountdownType } from '@/src/typings/enums/enums';
import ActionsModel from '@/src/models/actions/ActionsModel';
import useDevice from '@/src/hooks/useDevice';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import type { ActionData } from '@/src/typings/interfaces/data/settings/actions';
import { transformMeasurementToNumber } from '@/src/utilities/Utilities';

interface AddonCountdownSettingLabelState {
  day: string;
  days: string;
  hour: string;
  hours: string;
  minute: string;
  minutes: string;
  second: string;
  seconds: string;
}

export interface AddonCountdownSettingState {
  countdownType?: CountdownType;
  countFrom?: number;
  countdownDate?: Date;
  countdownTime?: string;
  fontColor?: string;
  fontSize?: number;
  fontSizeIsPercentage?: boolean;
  textAfterCountdown?: string;
  labels: AddonCountdownSettingLabelState;
}

interface AddonCountdownMobileSettingsState {
  fontColor?: string;
  fontSize?: number;
  fontSizeIsPercentage?: boolean;
}

export interface AddonCountdownState extends AddonModelState {
  action?: ActionsModel;
  settings: AddonCountdownSettingState;
  countdownDate?: string;
  elementStyling?: {
    inline?: CSSProperties;
  };
}

export class AddonCountdownModel extends AddonModel<AddonCountdownData, AddonCountdownState, 'countdown'> {
  parseAddon(data: AddonCountdownData): void {
    const state = this.state;
    state.settings = state.settings ?? {};

    if (data.settings) {
      state.settings = AddonCountdownModel.constructSettingState(data.settings);
      if (useDevice().isMobile) {
        const mobileSettings = AddonCountdownModel.parseMobileSettingData(data);

        if (mobileSettings) {
          state.settings = { ...state.settings, ...mobileSettings };
        }
      }
    }

    state.elementStyling = state.elementStyling ?? {};
    state.elementStyling.inline = AddonCountdownModel.constructInlineStyles(state.settings);

    if (data.settings.countdown_action) {
      const actionObject: ActionData = {
        ...(data.settings.popover && { popover: data.settings.popover }),
        type: data.settings.countdown_action,
        ...(data.settings.action_delay && { delay: Number(data.settings.action_delay) })
      };

      state.action = new ActionsModel(actionObject);
    }
  }

  private static parseMobileSettingData(data: AddonCountdownData): AddonCountdownMobileSettingsState | undefined {
    if (data.settings?.mobile) {
      const useData = this.getMobileDeviceData(data.settings);

      if (!useData) {
        return undefined;
      }

      return AddonCountdownModel.constructMobileSettingState(useData);
    }
  }

  public setValue(path: string, value: string | number | boolean, shouldParse?: boolean): void {
    const data = this.getData();
    if (path === 'countdown_type') {
      delete data.settings.count_from;
      delete data.settings.countdown_date;
      delete data.settings.countdown_time;
    }

    super.setValue(path, value, shouldParse);
  }

  public static getMobileDeviceData(data: AddonCountdownSettingData): AddonCountdownMobileData {
    const desktopData = data;

    const { isMobile } = useDevice();

    const overwriteMobile = typeof desktopData.overwrite_mobile === 'undefined' || desktopData.overwrite_mobile === '1';

    if (isMobile && !overwriteMobile && data.mobile) {
      return data.mobile;
    }

    return desktopData;
  }

  private static constructMobileSettingState(data: AddonCountdownMobileData): AddonCountdownMobileSettingsState {
    const defaultFontSize = data?.font_size ? data.font_size : '24px';
    const fontSize = transformMeasurementToNumber(defaultFontSize);

    return {
      ...(data?.font_color && { fontColor: data.font_color }),
      fontSize,
      fontSizeIsPercentage: !!data?.font_size?.includes('%')
    };
  }

  private static constructSettingState(data: AddonCountdownSettingData): AddonCountdownSettingState {
    const countDownTime = data?.countdown_time ? data.countdown_time : '00:00:00';
    let countdownDate = data.countdown_date;

    // If the date is wrongly formatted, such as 2021-05-29, reformat it to correct format
    const splitDate = data?.countdown_date?.split('-');
    if (splitDate && splitDate[0].length === 2) {
      countdownDate = splitDate.reverse().join('-');
    }

    const defaultFontSize = data?.font_size ? data.font_size : '24px';
    const fontSize = transformMeasurementToNumber(defaultFontSize);

    return {
      ...(data?.countdown_type === CountdownType.SIMPLE && {
        countFrom: data.count_from ? Number(data.count_from) : 3
      }),
      ...(countdownDate && { countdownDate: getWithTimezone(`${countdownDate} ${countDownTime}`) }),
      countdownType: data.countdown_type ?? CountdownType.TODATE,
      countdownTime: countDownTime,
      ...(data?.font_color && { fontColor: data.font_color }),
      fontSize,
      fontSizeIsPercentage: !!data?.font_size?.includes('%'),
      ...(data?.text_after_countdown && {
        textAfterCountdown: data.text_after_countdown
      }),
      labels: AddonCountdownModel.constructSettingLabelState(data.labels)
    };
  }

  private static constructSettingLabelState(
    data: AddonCountdownSettingLabelData | undefined
  ): AddonCountdownSettingLabelState {
    return {
      day: data?.day ? data.day : 'day',
      days: data?.days ? data.days : 'days',
      hour: data?.hour ? data.hour : 'hour',
      hours: data?.hours ? data.hours : 'hours',
      minute: data?.minute ? data.minute : '',
      minutes: data?.minutes ? data.minutes : 'min.',
      second: data?.second ? data.second : '',
      seconds: data?.seconds ? data.seconds : 'sec.'
    };
  }

  private static constructInlineStyles(state: AddonCountdownSettingState): CSSProperties | undefined {
    if (!state) return;

    let sizeMeasurement = 'px';
    if (state.fontSizeIsPercentage) {
      sizeMeasurement = '%';
    }

    return {
      ...(state.fontSize && {
        fontSize: `${state.fontSize}${sizeMeasurement}`
      }),
      ...(state?.fontColor && { color: state.fontColor })
    };
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    const countdownType = data.settings.countdown_type ?? CountdownType.TODATE;

    return !(countdownType === CountdownType.TODATE && !data.settings?.countdown_date);
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
