import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export interface FormElementSignatureSettingsData extends BaseFormElementSettingsData {
  label_clear?: string;
  label_save?: string;
}

export interface FormElementSignatureData extends BaseFormElementData {
  settings?: FormElementSignatureSettingsData;
}

export interface FormElementSignatureState extends BaseFormElementState<string> {
  labelClear?: string;
  labelSave?: string;
}

export class FormElementSignatureModel extends BaseFormElementModel<
  string,
  FormElementSignatureData,
  FormElementSignatureState
> {
  parseFormElement(data: FormElementSignatureData) {
    this.state.labelClear = data.settings?.label_clear || 'Clear';
    this.state.labelSave = data.settings?.label_save || 'Save';
  }

  getInitialValue(): string | undefined {
    return this.getInitialStringValue();
  }

  parseStringValue(value: string): string | undefined {
    return value;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value && this.state.value !== '' ? this.state.value : undefined;
  }

  getSerializedPostValue(): string {
    return this.state.value ?? '';
  }

  getSerializedCookieValue(): string {
    return this.state.value ?? '';
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
