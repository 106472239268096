import { watch } from 'vue';
import { SdkReplacementTagModel } from '../models/replacementTags';
import { SdkTypeCheck } from '../utilities';
import { useCampaignStore } from '@/src/store/campaign';
import { applyReplacementTags } from '@/src/utilities/Utilities';

export class SdkReplacementTagsApi {
  public static async applyTag(value: string) {
    await useCampaignStore().readyPromise;

    try {
      SdkTypeCheck('string', value);

      return applyReplacementTags(value);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public static async getAll() {
    await useCampaignStore().readyPromise;

    return SdkReplacementTagsApi.getReplacementTags();
  }

  public static async setTag(key: string, value: string) {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    try {
      SdkTypeCheck('string', key);
      SdkTypeCheck('string', value);

      campaignStore.addReplacementTags({ [key]: value });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  private static getReplacementTags() {
    const campaignStore = useCampaignStore();
    const replacementTags = campaignStore.replacementTags;

    return Object.keys(replacementTags).map((key) => {
      return new SdkReplacementTagModel(key, replacementTags[String(key)]).tag;
    });
  }

  public static onChange(fn: (value: string | number) => void, name?: string) {
    try {
      if (name) {
        SdkTypeCheck('string', name);

        const campaignStore = useCampaignStore();
        const replacementTags = campaignStore.replacementTags;

        return watch(
          () => replacementTags[`${name}`],
          (newValue) => {
            fn(newValue);
          }
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public static onChangeOnce(fn: (value: string | number) => void, name?: string) {
    const stopHandler = this.onChange((value) => {
      if (stopHandler) {
        fn(value);
        stopHandler();
      }
    }, name);

    return stopHandler;
  }
}
