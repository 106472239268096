/**
 * This exception is thrown by registration addon if it needs to prevent the default behavior for when submitting a form.
 * Default behavior will be going to the next flow page. But it could for an example not be desired if API responds with
 * that it should go to a specific flow page.
 */
export class FormDisableDefaultBehavior extends Error {
  private _allowContinueInFlow: boolean;

  constructor(message?: string, allowContinueInFlow?: boolean) {
    super(message);
    this._allowContinueInFlow = allowContinueInFlow ?? false;
  }

  get allowContinueInFlow() {
    return this._allowContinueInFlow;
  }
}
