import type { CSSProperties } from 'vue';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonSocialShareData, AddonSocialShareSettingsData } from '@/src/components/addons/social-share/Data';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import type { AlignContentType } from '@/src/typings/enums/enums';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';

export interface AddonSocialShareState extends AddonModelState {
  settings: AddonSocialShareSettingsState;
  elementStyling: {
    iconStyling: CSSProperties;
    itemStyling?: CSSProperties;
  };
}

export interface AddonSocialShareIconState {
  color?: string;
  backgroundColor?: string;
  size?: number;
}

export interface AddonSocialShareProviderState {
  facebookEnabled: boolean;
  twitterEnabled: boolean;
  whatsappEnabled: boolean;
  linkedinEnabled: boolean;
  messengerEnabled: boolean;
  emailEnabled: boolean;
}

export interface AddonSocialShareCustomUrl {
  enabled: boolean;
  url?: string;
}

export interface AddonSocialShareSettingsState {
  icon?: AddonSocialShareIconState;
  socialProvider: AddonSocialShareProviderState;
  alignment: AlignContentType;
  title: string;
  description: string;
  image: string;
  advanced?: AdvancedAddonsStyleData;
  customShareUrl?: AddonSocialShareCustomUrl;
  campaignShareUrl: string;
}

export class AddonSocialShareModel extends AddonModel<AddonSocialShareData, AddonSocialShareState, 'social-share'> {
  parseAddon(data: AddonSocialShareData) {
    const state = this.state;
    state.settings = state.settings ?? {};
    if (data.settings) {
      state.settings = AddonSocialShareModel.constructSettingState(data.settings);

      state.elementStyling = state.elementStyling ?? {};

      if (state.settings) {
        if (state.settings.icon?.size) {
          state.elementStyling.iconStyling = AddonSocialShareModel.constructElementIconStyling(
            state.settings.icon?.size
          );
        }

        state.elementStyling.itemStyling = AddonSocialShareModel.constructElementItemStyling(state.settings);
      }
    }
  }

  private static constructElementItemStyling(state: AddonSocialShareSettingsState): CSSProperties | undefined {
    return {
      ...(state.icon?.backgroundColor && {
        backgroundColor: `${state.icon?.backgroundColor}`
      }),
      ...(state.icon?.color && {
        color: `${state.icon?.color}`
      }),

      ...(state.icon?.size && {
        width: `${Math.round(state.icon.size * 1.7)}px`,
        height: `${Math.round(state.icon.size * 1.7)}px`,
        lineHeight: `${Math.round(state.icon.size * 1.7)}px`
      })
    };
  }

  private static constructElementIconStyling(size: number): CSSProperties {
    return {
      fontSize: `${size / 10}rem`
    };
  }

  private static constructSettingProviderState(data: AddonSocialShareSettingsData): AddonSocialShareProviderState {
    return {
      facebookEnabled: data.facebook === '1',
      twitterEnabled: data.twitter === '1',
      whatsappEnabled: data.whatsapp === '1',
      linkedinEnabled: data.linkedin === '1',
      messengerEnabled: data.messenger === '1',
      emailEnabled: data.email === '1'
    };
  }

  private static constructSettingIconState(data: AddonSocialShareSettingsData): AddonSocialShareIconState {
    return {
      ...(data.icon_color && {
        color: data.icon_color
      }),
      ...(data.icon_background_color && {
        backgroundColor: data.icon_background_color
      }),
      ...(data.icon_size && {
        size: Number(data.icon_size)
      })
    };
  }

  private static constructSettingState(data: AddonSocialShareSettingsData): AddonSocialShareSettingsState {
    return {
      icon: AddonSocialShareModel.constructSettingIconState(data),
      socialProvider: AddonSocialShareModel.constructSettingProviderState(data),
      alignment: data.alignment,
      title: data.title,
      description: data.description,
      image: data.image,
      customShareUrl: {
        enabled: data.custom_share_url?.enabled === '1',
        ...(data.custom_share_url?.url && {
          url: data.custom_share_url.url
        })
      },
      campaignShareUrl: data.campaign_share_url
    };
  }

  public isAddonValid(): boolean {
    return true;
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
