import { useGameStepsStore } from '@/src/store/gamesteps';
import { StepGuideAlignmentEnum, StepGuideSizeEnum, StepGuideTypeEnum } from './Types';
import type { AddonStepGuideData, AddonStepGuideSettingsData } from '@/src/components/addons/stepguide/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

export interface AddonStepGuideState extends AddonModelState {
  settings: AddonStepGuideSettingsState;
}
export interface AddonStepGuideSettingsState {
  size: StepGuideSizeEnum;
  process: AddonStepGuideProcess;
  width?: string;
  alignment: StepGuideAlignmentEnum;
  advanced?: AdvancedAddonsStyleData;
}

export interface AddonStepGuideProcess {
  type: StepGuideTypeEnum;
  shouldCalculateQuestion?: boolean;
  backgroundColor?: string;
  color?: string;
  fillCircles?: boolean;
  fontColor?: string;
}

export class AddonStepGuideModel extends AddonModel<AddonStepGuideData, AddonStepGuideState, 'stepguide'> {
  parseAddon(data: AddonStepGuideData) {
    const state = this.state;

    state.settings = AddonStepGuideModel.contructSettingsState(data.settings);
  }

  private static contructSettingsState(data: AddonStepGuideSettingsData): AddonStepGuideSettingsState {
    let stepSize: StepGuideSizeEnum | undefined;
    switch (data.size) {
      case 'small':
        stepSize = StepGuideSizeEnum.SMALL;
        break;
      case 'big':
        stepSize = StepGuideSizeEnum.LARGE;
        break;

      default:
        stepSize = StepGuideSizeEnum.MEDIUM;
        break;
    }

    let alignment: StepGuideAlignmentEnum | undefined;

    switch (data.alignment) {
      case 'left':
        alignment = StepGuideAlignmentEnum.LEFT;
        break;
      case 'right':
        alignment = StepGuideAlignmentEnum.RIGHT;
        break;

      default:
        alignment = StepGuideAlignmentEnum.CENTER;
        break;
    }

    let processType: StepGuideTypeEnum | undefined;

    switch (data.progress_type) {
      case 'top_bar':
        processType = StepGuideTypeEnum.TOP_BAR;
        break;
      case 'step':
        processType = StepGuideTypeEnum.STEP;
        break;
      case 'percent':
        processType = StepGuideTypeEnum.PERCENTAGE;
        break;

      default:
        processType = StepGuideTypeEnum.CIRCLES;
        break;
    }

    return {
      size: stepSize,
      ...(data.width && { width: data.width }),
      alignment,
      process: {
        type: processType,
        shouldCalculateQuestion: data.progress_only_calc_questions === '1',
        fillCircles: data.fill_circles === '1',
        ...(data.progress_background_color && { backgroundColor: data.progress_background_color }),
        ...(data.progress_color && { color: data.progress_color }),
        ...(data.progress_font_color && { fontColor: data.progress_font_color })
      }
    };
  }

  public isAddonValid(): boolean {
    const gameStepsStore = useGameStepsStore();
    return !!gameStepsStore.totalSteps;
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
