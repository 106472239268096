<template>
  <component :is="integrationComp" :model="model" />
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineAsyncComponent, defineComponent, nextTick } from 'vue';
import type { IntegrationModel } from '@/src/models/integrations/types';

export default defineComponent({
  name: 'Integration',
  props: {
    model: {
      type: Object as PropType<IntegrationModel>,
      required: true
    }
  },
  setup(props) {
    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const integrationComp = defineAsyncComponent({
      loader: async () => {
        const component = await props.model.getVueComponent();

        if (!component) {
          throw new Error(`Unrecognized component for integration "${props.model.state.namespace}"`);
        }

        nextTick().then(() => {
          if (readyPromiseResolve) {
            readyPromiseResolve();
          }
        });

        return component;
      }
    });

    return {
      integrationComp,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>
