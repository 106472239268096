import { SdkFlowPageModel } from '../models/flowPage';
import type { SdkPopoverModel } from '../models/popover';
import type { SdkSectionModel } from '../models/section';
import type { FlowpageModel } from '@/src/components/layout/FlowpageModel';

type SdkToType = SdkFlowPageModel | SdkSectionModel | SdkPopoverModel;

export class SdkFlowPageChangeEvent {
  readonly #from?: SdkFlowPageModel;
  readonly #to: SdkToType;

  constructor(to: FlowpageModel, from?: FlowpageModel) {
    this.#from = from ? new SdkFlowPageModel(from) : undefined;
    this.#to = new SdkFlowPageModel(to);
  }

  public get from() {
    return this.#from;
  }

  public get to() {
    return this.#to;
  }
}
