<template>
  <Campaign
    v-if="campaignData && campaignData.config"
    :key="campaignData.config.campaign_id"
    :data="campaignData"
    :is-demo="false"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Campaign from '@/src/components/Campaign.vue';
import type { CampaignData } from '@/src/typings/interfaces/data/campaign';
import { useUtilityStore } from '@/src/store/utility';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'CampaignView',
  components: {
    Campaign
  },
  setup() {
    const utilityStore = useUtilityStore();
    const route = useRoute();

    utilityStore.overwrittenUrl = typeof window !== 'undefined' ? window.location.href : route.fullPath;

    const campaignData = ref<CampaignData | undefined>(
      window.PLAYABLE_CAMPAIGN !== undefined && window.PLAYABLE_CAMPAIGN !== '#pl_data#'
        ? JSON.parse(window.atob(window.PLAYABLE_CAMPAIGN))
        : undefined
    );

    return {
      campaignData
    };
  }
});
</script>
