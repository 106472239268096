import { defineStore } from 'pinia';
import type { RepeatableModel } from '@/src/models/settings/RepeatableModel';

export interface RepeatableDataMap {
  [key: string]: RepeatableMapItem;
}

export interface RepeatableMapItem {
  model: RepeatableModel;
  data: RepeatableData[];
}

export interface RepeatableData {
  id: number | string;
  [key: string]: number | string;
}

interface State {
  data: RepeatableDataMap;
}

export const useRepeatableStore = defineStore('repeatable', {
  state: (): State => ({
    data: {}
  })
});
