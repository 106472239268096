export interface IGameObject extends IProcessedImage {
  x: number;
  y: number;
  dy: number;
}

export interface IPickUpObject extends IGameObject {
  points: number;
  label: string;
}

export interface IPlayerObject extends IGameObject {
  isInvulnerable?: boolean;
  isRespawning?: boolean;
  isDeathAnimating?: boolean;
  isJumping?: boolean;
}

export interface IBackgroundObject extends IGameObject {
  placement: GravityDodgerVerticalPlacement;
  speed: GravityDodgerGameSpeed;
  offset: number;
}

export interface IObstacleObject extends IGameObject {
  flipVertically: boolean;
  placement: GravityDodgerVerticalPlacement;
  offset: number;
}

export interface IProcessedImage {
  image?: HTMLImageElement;
  width: number;
  height: number;
}

export interface ImageConfig {
  maxWidth?: number; // maxWidth is optional
}

export type NormalizedImagesMap = { [url: string]: HTMLImageElement };
export type JumpKey = 'Space' | 'ArrowUp' | 'KeyW';

export enum GravityDodgerVerticalPlacement {
  TOP,
  MIDDLE,
  BOTTOM,
  RANDOM
}

export enum Lane {
  TOP,
  MIDDLE,
  BOTTOM
}

export enum GravityDodgerGameSpeed {
  SLOW,
  NORMAL,
  FAST
}

export enum GravityDodgerAnimation {
  NO_ANIMATION,
  PULSE
}
