import type { SettingsData } from '@/src/typings/interfaces/data/settings/settings';
import type { SettingsType } from '@/src/components/layout/SettingsModel';
import { SettingsModel } from '@/src/components/layout/SettingsModel';
import type { VisibilityConditionsData } from '@/src/typings/interfaces/data/conditions/visibilityConditions';

export class ColumnSettingsModel extends SettingsModel {
  public getSettingsType(): SettingsType {
    return 'column';
  }

  public getDefaultPadding() {
    return 0;
  }

  protected constructVisibilityConditionData(data: SettingsData): VisibilityConditionsData {
    const showOnDesktop = Number(data.advanced?.show_on_desktop ?? '1') ? '1' : '0';
    const showOnTablet = Number(data.advanced?.show_on_tablet ?? '1') ? '1' : '0';
    const showOnMobile = Number(data.advanced?.show_on_mobile ?? '1') ? '1' : '0';

    return {
      ...(data.advanced?.visibility_condition ?? {}),
      devices: {
        desktop: showOnDesktop,
        tablet: showOnTablet,
        mobile: showOnMobile
      }
    };
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
