import { SdkFormFieldModel } from '../formField';
import { SdkBaseAddonModel } from '.';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';

export class SdkAddonRegistration extends SdkBaseAddonModel {
  #model: AddonRegistrationModel;

  constructor(model: AddonRegistrationModel) {
    super(model);
    this.#model = model;
  }

  public get formFields(): SdkFormFieldModel[] {
    if (this.#model.state.fields) {
      return this.#model.state.fields.map((field) => new SdkFormFieldModel(field)) ?? [];
    }

    return [];
  }
}
