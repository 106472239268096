export class SdkGameEndEvent {
  #winner: boolean;

  constructor(winner: boolean) {
    this.#winner = winner;
  }

  public get winner(): boolean {
    return this.#winner;
  }
}
