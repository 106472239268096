import { useCampaignStore } from '@/src/store/campaign';
import { FlowPageDoesNotExists } from '@/src/exceptions/FlowPageDoesNotExists';
import { sendNoFlowPageStatusEvent } from '@/src/utilities/iFrameUtils';
import type { AddonGameplayModel } from '@/src/components/addons/gameplay/Model';

export default function useFlow() {
  const campaignStore = useCampaignStore();

  /**
   * Some constraints are context-aware such as skipping the game. This constraint is by default enabled,
   * but you can disable it by settings onlyDefaultConstraints to true to avoid issues with context-aware
   * constraints.
   *
   * This can be problematic if you want to e.g. find the first flow-page available.
   */
  const getNextFlowPage = (currentIndex?: number | null, onlyDefaultConstraints = false): number | null => {
    if (typeof currentIndex === 'undefined') {
      currentIndex = campaignStore.flowIndex;
    }

    const nextFlowIndex = currentIndex === null || currentIndex === undefined ? 0 : currentIndex + 1;

    if (typeof campaignStore.model?.state.flowPages[Number(nextFlowIndex)] === 'undefined') {
      return null;
    }

    const nextFlowPageModel = campaignStore.model?.state.flowPages[Number(nextFlowIndex)];
    if (!nextFlowPageModel) return null;

    const nextFlowPageState = nextFlowPageModel.state;

    // Checks if the game has prematurely ended and skip next gameplay flow if true
    // happens when button action like "finish game" is triggered before gameplay
    if (!onlyDefaultConstraints && campaignStore.gameEnded && nextFlowPageModel.getAddons('gameplay').length > 0) {
      return getNextFlowPage(campaignStore.model.getFlowPageIndex(nextFlowPageModel.id), onlyDefaultConstraints);
    }

    const condition = nextFlowPageState.config?.settings?.state.advanced?.visibilityCondition;

    if (condition && !condition.check()) {
      return getNextFlowPage(campaignStore.model.getFlowPageIndex(nextFlowPageModel.id), onlyDefaultConstraints);
    }

    if (nextFlowPageState) {
      return nextFlowPageState.id;
    }

    return null;
  };

  const getFirstFlowPage = (): number | null => {
    return getNextFlowPage(null, true);
  };

  const makeSpecificFlowPageActive = (flowId: number) => {
    if (!campaignStore.model?.getFlowPageModel(flowId)) {
      throw new FlowPageDoesNotExists(`The requested flow page ${flowId} does not exist on the campaign.`);
    }

    // if editMode is active and flowPage is before gameplay then we reset the game stats
    if (campaignStore.model?.state.isEditModeActive) {
      const currentIndex = campaignStore.model.state.flowPages.findIndex((pageModel) => pageModel.id === flowId);

      const firstGameplayPageIndex = campaignStore.model.state.flowPages.findIndex(
        (pageModel) => pageModel.getAddons<AddonGameplayModel>('gameplay').length > 0
      );

      // if before or current flowpage is "gameplay" we should reset
      const shouldReset =
        currentIndex !== -1 && firstGameplayPageIndex !== -1 && currentIndex <= firstGameplayPageIndex;

      if (shouldReset) {
        campaignStore.resetGameState();
      }
    }

    campaignStore.flowId = flowId;
    campaignStore.fictiveFlowPage = undefined;
  };

  const goToNextFlowPage = () => {
    const nextFlowPage = getNextFlowPage();

    if (nextFlowPage) {
      campaignStore.flowId = nextFlowPage;
      campaignStore.fictiveFlowPage = undefined;
    } else {
      // No more flowpages available. Notify potential listeners outside iframe.
      campaignStore.noMoreFlowPages = true;
      sendNoFlowPageStatusEvent();
    }
  };

  return {
    getNextFlowPage,
    makeSpecificFlowPageActive,
    getFirstFlowPage,
    goToNextFlowPage
  };
}
