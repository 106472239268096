import type { CSSProperties } from 'vue';
import type {
  GameGuessThePictureAdvancedData,
  GameGuessThePictureData,
  GameGuessThePictureGeneralData,
  GameGuessThePicturePictureData
} from '@/src/components/games/guessthepicture/Data';
import type { listStyleType } from '@/src/components/games/guessthepicture/Enums';
import {
  GuessThePictureDesign,
  GuessThePictureEffectType,
  GuessThePictureTextPosition,
  GuessThePictureType
} from '@/src/components/games/guessthepicture/Enums';
import type {
  GameIndicator,
  GameState,
  GameTimeChallenge,
  HasGameTimeChallenge,
  HasSound
} from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import { AlignContentType } from '@/src/typings/enums/enums';
import type { TransitionTypes } from '@/src/typings/types/types';
import useDevice from '@/src/hooks/useDevice';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { GameEndingConditionType } from '@/src/typings/interfaces/data/conditions/visibilityConditions';

interface CSSClass {
  [key: string]: boolean;
}

export interface GameGuessThePictureState extends GameState {
  general: GameGuessThePictureGeneralState;
  pictures: GameGuessThePicturePictureState[];
  guessClasses: CSSClass;
  elementStyling: string;
  feedbackMessage?: GameGuessThePictureFeedbackState;
  advanced: GameGuessThePictureAdvancedState;
}

export interface GameGuessThePictureFeedbackState {
  feedbackButtonLabel?: string;
  correctAnswerMessage?: string;
  wrongAnswerMessage?: string;
}

export interface GameGuessThePictureGeneralState {
  type: GuessThePictureType;
  imagesShown?: number;
  imageEffect: GuessThePictureEffectType;
  roundImages: boolean;
  numberOfPictures: number;
  numberOfCorrectGuesses: number;
  pictureFillTimer: number;
  delay: number;
  showFeedback: boolean;
  showDescription: boolean;
  textPosition?: GuessThePictureTextPosition;
  feedbackButtonLabel?: string;
  correctAnswerMessage?: string;
  wrongAnswerMessage?: string;
  answerDesign?: GuessThePictureDesign;
  listStyle?: listStyleType;
  answerPadding: number;
  answerMargin: number;
  answerAlignment: AlignContentType;
  answerFontType: string;
  showAnswerButton: boolean;
  answerBtn?: string;
  enableBestTime: boolean;
  enableTimeRanking: boolean;
  customCheckmark?: string;
  customCheckmarkCircle?: string;
  customRadio?: string;
  customLabels: {
    timeLeft: string;
  };
  listStyling?: GameGuessListStyling;
}

export interface GameGuessThePictureAnswer {
  title: string;
  id: number;
  isCorrect: boolean;
}

export interface GameGuessThePicturePictureState {
  id: number;
  description?: string;
  image: string;
  answers: GameGuessThePictureAnswer[];
  guessOne: { title: 'guess_1'; value: string };
  guessTwo: { title: 'guess_2'; value: string };
  guessThree: { title: 'guess_3'; value: string };
  correctGuess: string;
  feedBackButtonLabel?: string;
  correctAnswerMessage?: string;
  wrongAnswerMessage?: string;
  visibilityCondition?: VisibilityConditionsModel;
}

export interface GameGuessListStyling {
  checkmarkActiveStyle: CSSProperties;
  checkmarkStyle: CSSProperties;
  radioStyle: CSSProperties;
  radioActiveStyle: CSSProperties;
}

export interface GameGuessThePictureAdvancedSoundState {
  enabled: boolean;
  src?: string;
}
export interface GameGuessThePictureAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

export interface GameGuessThePictureAdvancedState {
  sound: GameGuessThePictureAdvancedSoundState;
  animation: GameGuessThePictureAdvancedAnimationState;
}

export class GameGuessThePictureModel
  extends GameModel<GameGuessThePictureData, GameGuessThePictureState>
  implements HasGameTimeChallenge, HasSound
{
  parseGame(data: GameGuessThePictureData): void {
    const state = this.state;

    state.general = GameGuessThePictureModel.constructGuessThePictureGeneralState(data.general);

    state.pictures = state.pictures ?? [];

    state.pictures = this.parseGuessPictures(data.pictures.picture);

    state.elementStyling = GameGuessThePictureModel.constructElementStyling(data.general);

    if (state.general.showFeedback) {
      state.feedbackMessage = GameGuessThePictureModel.constructGuessThePictureFeedbackState(data.general);
    }

    state.general.listStyling = GameGuessThePictureModel.constructListStyling(data.general);
    state.guessClasses = GameGuessThePictureModel.constructGuessClasses(data.general);

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameGuessThePictureModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GameGuessThePictureModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructAdvancedSoundState(
    data: GameGuessThePictureAdvancedData | undefined
  ): GameGuessThePictureAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructAdvancedAnimationState(
    data: GameGuessThePictureAdvancedData | undefined
  ): GameGuessThePictureAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private parseGuessPictures(data: GameGuessThePicturePictureData[]): GameGuessThePicturePictureState[] {
    // Convert the object into an array of its values before mapping
    return Object.values(data).map((pictureData) => this.constructGuessThePicturePictureState(pictureData));
  }

  private static constructGuessClasses(data: GameGuessThePictureGeneralData): CSSClass {
    return {
      ...(data.answer_design && { [`guess--${data.answer_design}`]: true }),
      ...(data.list_style && {
        [`guess--bullets-${data.list_style}`]: data.answer_design === GuessThePictureDesign.BULLETS
      }),
      ...(data.answer_alignment && { [`guess--answers-align-${data.answer_alignment}`]: true }),
      'guess--has-active-state': !!data.active_state_bg_color || !!data.active_state_color
    };
  }

  private static constructGuessThePictureFeedbackState(
    data: GameGuessThePictureGeneralData
  ): GameGuessThePictureFeedbackState {
    return {
      feedbackButtonLabel: data.next_question ?? 'Next',
      ...(data?.correct_answer_message && { correctAnswerMessage: data.correct_answer_message }),
      ...(data?.wrong_answer_message && { wrongAnswerMessage: data.wrong_answer_message })
    };
  }

  private static constructGuessThePictureGeneralState(
    data: GameGuessThePictureGeneralData
  ): GameGuessThePictureGeneralState {
    let guessType: GuessThePictureType;
    switch (data.guessthepicture_type) {
      case 'randomize':
        guessType = GuessThePictureType.RANDOM;
        break;

      default:
        guessType = GuessThePictureType.STANDARD;
        break;
    }

    // For some reason, if choosing 5 seconds in the BE, the data is 4
    // So to get the "correct" delay, we need to increment with 1
    const delay = data.delay ? Number(data.delay) + 1 : 0;

    return {
      type: guessType,
      imagesShown: data.images_shown ? Number(data.images_shown) : undefined,
      answerDesign: data.answer_design || GuessThePictureDesign.TEXT,
      answerFontType: data.answer_font_type || 'h4',
      answerMargin: Number(data.answer_margin) || 1,
      answerPadding: Number(data.answer_padding) || 5,
      numberOfCorrectGuesses: Number(data.correct_guesses) || 2,
      enableBestTime: data.enable_best_time === '1' || false,
      enableTimeRanking: data.enable_time_ranking === '1' || false,
      pictureFillTimer: Number(data.picture_fill_timer) || 10,
      numberOfPictures: Number(data.pictures) || 3,
      showAnswerButton: data.show_answer_button === '1' || false,
      showDescription: data.show_description === '1' || false,
      showFeedback: data.show_feedback === '1' || false,
      answerBtn: data.answer_btn || 'Answer',
      roundImages: data.round_images === 'yes',
      listStyle: data.list_style,
      ...{ delay: Number(delay) * 1000 || 500 },
      ...{ imageEffect: data.image_effect || GuessThePictureEffectType.ZOOM },
      ...(data.pictures && { pictures: Number(data?.pictures) }),
      ...{ answerAlignment: data.answer_alignment || AlignContentType.CENTER },
      ...{ customLabels: { timeLeft: data.customlabels.time_left || 'Time left' } },
      ...{ textPosition: data.text_position || GuessThePictureTextPosition.BOTTOM }
    };
  }

  private constructGuessThePicturePictureState(data: GameGuessThePicturePictureData): GameGuessThePicturePictureState {
    const answers: GameGuessThePictureAnswer[] = [
      {
        title: data.guess_1,
        id: Number(data.id),
        isCorrect: Object.keys(data).filter((key) => key === data.correct_guess)[0] === 'guess_1'
      },
      {
        title: data.guess_2,
        id: Number(data.id),
        isCorrect: Object.keys(data).filter((key) => key === data.correct_guess)[0] === 'guess_2'
      },
      {
        title: data.guess_3,
        id: Number(data.id),
        isCorrect: Object.keys(data).filter((key) => key === data.correct_guess)[0] === 'guess_3'
      }
    ];

    const existingPictureState = this.state?.pictures?.find(
      (existingPicture) => existingPicture.id === Number(data.id)
    );

    let visibilityCondition: VisibilityConditionsModel | undefined;

    if (data.date_range?.enabled === '1') {
      visibilityCondition = existingPictureState ? existingPictureState.visibilityCondition : undefined;

      const visibilityConditionData = {
        condition: GameEndingConditionType.NONE,
        date_range: {
          enabled: data.date_range.enabled,
          date_to: data.date_range.to ? data.date_range.to : '',
          date_from: data.date_range.from ? data.date_range.from : ''
        }
      };

      if (visibilityCondition) {
        visibilityCondition.setData(visibilityConditionData);
      } else {
        visibilityCondition = new VisibilityConditionsModel(visibilityConditionData);
      }
    }

    return {
      id: Number(data.id),
      ...(data.description && { description: data?.description }),
      image: data.image,
      answers,
      guessOne: { title: 'guess_1', value: data.guess_1 },
      guessTwo: { title: 'guess_2', value: data.guess_2 },
      guessThree: { title: 'guess_3', value: data.guess_3 },
      correctGuess: data.correct_guess,
      ...(data.correct_answer_message && { correctAnswerMessage: data.correct_answer_message }),
      ...(data.wrong_answer_message && { wrongAnswerMessage: data.wrong_answer_message }),
      ...(data.feedback_button_label && { feedBackButtonLabel: data.feedback_button_label }),
      visibilityCondition
    };
  }

  private static constructElementStyling(data: GameGuessThePictureGeneralData): string {
    const { isMobile } = useDevice();
    const color = data.answer_color ? `color: ${data.answer_color} !important;` : '';
    const border =
      data?.border_color && data.border_thickness
        ? `border: ${data.border_thickness}px solid ${data.border_color};`
        : '';
    const backgroundColor = data.bullets_bg_color
      ? `background-color: ${data.bullets_bg_color}; margin-bottom: 1px;`
      : '';

    const colorActive = data.active_state_color ? `color: ${data?.active_state_color} !important;` : '';
    const backgroundColorActive = data.active_state_bg_color
      ? `background-color: ${data.active_state_bg_color} !important;`
      : '';
    const marginBottom = data.answer_margin ? `margin-bottom: ${data.answer_margin}px;` : '';
    const padding = data.answer_padding ? `padding: ${Math.ceil(Number(data.answer_padding) / 2) / 10}rem 0;` : '';
    const width = !isMobile ? `width: ${data.width};` : '';

    return `.guessthepicture .guess .guess__answer-item {${color}${border}${backgroundColor}${marginBottom}${padding}${width}}
            .guessthepicture .guess .guess__answer-item.guess__answer-item--selected,
            .category-desktop .guess .guess__answer-item:hover {${backgroundColorActive}${colorActive}}`;
  }

  private static constructListStyling(data: GameGuessThePictureGeneralData): GameGuessListStyling {
    const customCheckmark = !!data.custom_checkmark;
    const customCheckmarkCircle = !!data.custom_checkmark_circle;

    const radioActiveStyle = {
      backgroundColor: data.custom_radio
    };
    const radioStyle = {
      backgroundColor: data.custom_checkmark_circle
    };

    const checkmarkStyle = {
      color: customCheckmark ? 'transparent' : '',
      ...(data.custom_checkmark_circle && { backgroundColor: data.custom_checkmark_circle })
    };
    const checkmarkActiveStyle = {
      color: customCheckmarkCircle ? data.custom_checkmark : '',
      ...(data.custom_checkmark_circle && { backgroundColor: data.custom_checkmark_circle })
    };
    return {
      checkmarkActiveStyle,
      checkmarkStyle,
      radioStyle,
      radioActiveStyle
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 30,
          time_used: 1
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
