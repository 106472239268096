import axios from 'axios';
import { GameSliceitModel } from '@/src/components/games/sliceit/Model';
import { useCampaignStore } from '@/src/store/campaign';
import type { AddonGameplayData } from '@/src/components/addons/gameplay/Data';
import { GameSwipeitModel } from '@/src/components/games/swipeit/Model';
import { GameDiceModel } from '@/src/components/games/dice/Model';
import { GameShootItModel } from '@/src/components/games/shootit/Model';
import { GameSnakeModel } from '@/src/components/games/snake/Model';
import { GameSpinTheBottleModel } from '@/src/components/games/spinthebottle/Model';
import { GameWofModel } from '@/src/components/games/wof/Model';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { GameRockPaperScissorsModel } from '@/src/components/games/rockpaperscissors/Model';
import { GameLuckyNumberModel } from '@/src/components/games/luckynumber/Model';
import { GameMemoryModel } from '@/src/components/games/memory/Model';
import useAxios from '@/src/hooks/useAxios';
import type { InstantWinData } from '@/src/models/GameModel';
import { GameDropGameModel } from '@/src/components/games/dropgame/Model';
import { GameSpotTheDifferenceModel } from '@/src/components/games/spotthedifference/Model';
import { GamePredictionModel } from '@/src/components/games/prediction/Model';
import { GameGuessTheWordModel } from '@/src/components/games/guesstheword/Model';
import { GamePollModel } from '@/src/components/games/poll/Model';
import { GameHitTheTargetModel } from '@/src/components/games/hitthetarget/Model';
import { GameProductSelectorModel } from '@/src/components/games/product-selector/Model';
import { GameGuessThePictureModel } from '@/src/components/games/guessthepicture/Model';
import { GameShellModel } from '@/src/components/games/shell/Model';
import { GameSlidingPuzzleModel } from '@/src/components/games/sliding-puzzle/Model';
import { GameCampaignLinkModel } from '@/src/components/games/campaign-link/Model';
import { GamePersonalityTestModel } from '@/src/components/games/personality-test/Model';
import { GameSurveyModel } from '@/src/components/games/survey/Model';
import { GameQuizModel } from '@/src/components/games/quiz/Model';
import { GameCalendarModel } from '@/src/components/games/calendar/Model';
import { GamePriorityModel } from '@/src/components/games/priority/Model';
import { GameScratchCardModel } from '@/src/components/games/scratchcard/Model';
import { GamePlaceTheItemModel } from '@/src/components/games/placetheitem/Model';
import { GamePuzzleModel } from '@/src/components/games/puzzle/Model';
import { GameSlotModel } from '@/src/components/games/slot-machine/Model';
import { GameVideoQuizModel } from '@/src/components/games/video-quiz/Model';
import { GameRouletteModel } from '@/src/components/games/roulette/Model';
import { GameTappingModel } from '@/src/components/games/tapping/Model';
import { GameBounceBattleModel } from '@/src/components/games/bounce-battle/Model';
import { GameGravityDodgerModel } from '@/src/components/games/gravity-dodger/Model';
import { GameHitAMoleModel } from '@/src/components/games/hit-a-mole/Model';
import { GameBankoModel } from '@/src/components/games/banko/Model';
import { GameSudokuModel } from '@/src/components/games/sudoku/Model';
import { GameWordRiddleModel } from '@/src/components/games/wordriddle/Model';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { GameOverlayData } from '@/src/typings/interfaces/data/settings/settings';
import { GameRushRunnerModel } from '@/src/components/games/rush-runner/Model';

export type ContentGameModel =
  | GameVideoQuizModel
  | GameSlotModel
  | GameGuessThePictureModel
  | GamePlaceTheItemModel
  | GameScratchCardModel
  | GameHitTheTargetModel
  | GameGuessTheWordModel
  | GamePredictionModel
  | GameSpotTheDifferenceModel
  | GameMemoryModel
  | GameLuckyNumberModel
  | GameSwipeitModel
  | GameDiceModel
  | GameShootItModel
  | GameSnakeModel
  | GameProductSelectorModel
  | GameSpinTheBottleModel
  | GameWofModel
  | GameSlidingPuzzleModel
  | GameRockPaperScissorsModel
  | GameShellModel
  | GameDropGameModel
  | GameCampaignLinkModel
  | GamePersonalityTestModel
  | GameQuizModel
  | GameCalendarModel
  | GameSurveyModel
  | GamePuzzleModel
  | GamePriorityModel
  | GameSliceitModel
  | GameRouletteModel
  | GameTappingModel
  | GameBounceBattleModel
  | GameGravityDodgerModel
  | GameHitAMoleModel
  | GameBankoModel
  | GameSudokuModel
  | GameWordRiddleModel;

export type ContentGameType =
  | typeof GameVideoQuizModel
  | typeof GameSlotModel
  | typeof GameGuessThePictureModel
  | typeof GamePollModel
  | typeof GamePlaceTheItemModel
  | typeof GameScratchCardModel
  | typeof GameHitTheTargetModel
  | typeof GameGuessTheWordModel
  | typeof GamePredictionModel
  | typeof GameSpotTheDifferenceModel
  | typeof GameMemoryModel
  | typeof GameLuckyNumberModel
  | typeof GameSwipeitModel
  | typeof GameDiceModel
  | typeof GameShootItModel
  | typeof GameSnakeModel
  | typeof GameProductSelectorModel
  | typeof GameSpinTheBottleModel
  | typeof GameWofModel
  | typeof GameSlidingPuzzleModel
  | typeof GameRockPaperScissorsModel
  | typeof GameShellModel
  | typeof GameDropGameModel
  | typeof GameCampaignLinkModel
  | typeof GamePersonalityTestModel
  | typeof GameQuizModel
  | typeof GameCalendarModel
  | typeof GameSurveyModel
  | typeof GamePuzzleModel
  | typeof GamePriorityModel
  | typeof GameSliceitModel
  | typeof GameRouletteModel
  | typeof GameTappingModel
  | typeof GameBounceBattleModel
  | typeof GameGravityDodgerModel
  | typeof GameBankoModel
  | typeof GameHitAMoleModel
  | typeof GameSudokuModel
  | typeof GameWordRiddleModel
  | typeof GameRushRunnerModel;

const GameMapping: Record<string, ContentGameType> = {
  video_quiz: GameVideoQuizModel,
  slotmachine: GameSlotModel,
  guessthepicture: GameGuessThePictureModel,
  poll: GamePollModel,
  placetheitem: GamePlaceTheItemModel,
  scratchcard: GameScratchCardModel,
  hitthetarget: GameHitTheTargetModel,
  prediction: GamePredictionModel,
  guesstheword: GameGuessTheWordModel,
  finderrors: GameSpotTheDifferenceModel,
  memory: GameMemoryModel,
  luckynumber: GameLuckyNumberModel,
  dice: GameDiceModel,
  swipeit: GameSwipeitModel,
  shootit: GameShootItModel,
  snake: GameSnakeModel,
  spinthebottle: GameSpinTheBottleModel,
  wof: GameWofModel,
  rockpaperscissors: GameRockPaperScissorsModel,
  dropgame: GameDropGameModel,
  product_selector: GameProductSelectorModel,
  slidingpuzzle: GameSlidingPuzzleModel,
  shell: GameShellModel,
  campaign_link: GameCampaignLinkModel,
  personality_test: GamePersonalityTestModel,
  quiz: GameQuizModel,
  calendar: GameCalendarModel,
  survey: GameSurveyModel,
  puzzle: GamePuzzleModel,
  priority: GamePriorityModel,
  sliceit: GameSliceitModel,
  roulette: GameRouletteModel,
  tapping: GameTappingModel,
  bounce_battle: GameBounceBattleModel,
  gravity_dodger: GameGravityDodgerModel,
  hit_a_mole: GameHitAMoleModel,
  banko: GameBankoModel,
  sudoku: GameSudokuModel,
  wordriddle: GameWordRiddleModel,
  rush_runner: GameRushRunnerModel
};

interface AddonGameplayState extends AddonModelState {
  settings?: {
    gameOverlay?: number;
    gameAlias: string;
    game?: ContentGameModel;
  };
  hasInstantWin: boolean;
}

export class AddonGameplayModel extends AddonModel<AddonGameplayData, AddonGameplayState, 'gameplay'> {
  parseAddon(data: AddonGameplayData): void {
    const state = this.state;
    state.alias = data.alias;

    if (data.settings?.game_alias) {
      if (!state.settings) {
        state.settings = {
          gameAlias: data.settings.game_alias
        };
      } else {
        state.settings.gameAlias = data.settings.game_alias;
      }

      state.settings.gameOverlay = AddonGameplayModel.parseGameOverlayData(
        data.settings.advanced?.advanced?.game_overlay
      );

      if (typeof GameMapping[data.settings.game_alias] !== 'undefined') {
        if (state.settings.game && state.settings.game instanceof GameMapping[data.settings.game_alias]) {
          // Investigate if we're able to more strongly type this. Unfortunately i don't see any other way around this
          // due to data being different from game to game. Let's revisit this at a later point.
          // @ts-ignore
          state.settings.game.setData(data.settings.game);
        } else {
          // Investigate if we're able to more strongly type this. Unfortunately i don't see any other way around this
          // due to data being different from game to game. Let's revisit this at a later point.
          // @ts-ignore
          state.settings.game = new GameMapping[data.settings.game_alias](data.settings.game);
        }
      } else {
        state.settings.game = undefined;
      }
    } else {
      state.settings = undefined;
    }

    state.hasInstantWin = state.settings?.game ? state.settings.game.hasInstantWin() : false;
  }

  private static parseGameOverlayData(data: DeviceData<GameOverlayData> | undefined): number | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return Number(useData.page);
    }
  }

  async fetchInstantWinData(): Promise<void> {
    const campaignStore = useCampaignStore();

    const instantWinData = campaignStore.instantWinData;
    const postUrl = `${campaignStore.model?.state?.config?.campaignApiRoot ?? ''}/api/v1/campaign/action?campaign_id=${
      campaignStore.model?.id
    }&action=auth`;

    const { postDataFormData } = useAxios<InstantWinData>(
      postUrl,
      instantWinData
        ? {
            ...instantWinData
          }
        : {}
    );

    try {
      const response = await postDataFormData();

      if (this.state.settings?.game && response) {
        this.state.settings.game.setInstantWinnerData(response ?? {});
      }
    } catch (e) {
      // TODO: Recreate maintenance page
      if (axios.isAxiosError(e) && e.response?.status === 503) {
        /*throw createError({
          statusCode: 503,
          fatal: true
        });*/
      }

      campaignStore.addStaticFormData({
        instantWinFetchError: '1'
      });

      if (this.state.settings?.game) {
        this.state.settings.game.setInstantWinnerData({});
      }
    }
  }
}
