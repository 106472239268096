import { defineStore } from 'pinia';
import type { ModelTypes } from '@/src/typings/types/types';
import type { Raw } from 'vue';

interface State {
  elementInClipboard: Raw<ModelTypes> | null;
  dataInClipboard: string | null;
}

export const useClipboardStore = defineStore('clipboard', {
  state: (): State => ({
    elementInClipboard: null,
    dataInClipboard: null
  }),
  getters: {},
  actions: {
    add(model: ModelTypes) {
      this.elementInClipboard = model;
      this.dataInClipboard = model.serialize();
    },
    clear() {
      this.dataInClipboard = null;
      this.elementInClipboard = null;
    }
  }
});
