import { SdkTypeCheck } from '../utilities';
import { useCampaignStore } from '@/src/store/campaign';

interface SdkAgencyProject<T extends object | undefined = undefined> {
  id: string;
  settings: T | undefined;
}

export class SdkCustomSolutionsApi {
  public static async get<T extends object | undefined = undefined>(solutionId: string): Promise<SdkAgencyProject<T>> {
    const campaignStore = useCampaignStore();

    await campaignStore.readyPromise;

    SdkTypeCheck('string', solutionId);

    if (!solutionId) {
      throw new TypeError(`[SDK] solutionId must not be empty`);
    }

    const project = campaignStore.model?.state.config?.customSolutions?.find((p) => p.id === solutionId);

    if (project) {
      return {
        id: project.id,
        settings: project.settings ? ({ ...project.settings } as T) : undefined
      };
    }

    return Promise.reject(new Error(`[SDK] Custom solution with id ${solutionId} not found`));
  }
}
