import EventEmitter from 'events';
import type TypedEventEmitter from 'typed-emitter';
import type { SdkFlowPageChangeEvent } from './events/flowPageChangeEvent';
import type { SdkFormSubmitEvent } from './events/formSubmit';
import type { SdkFormSubmitResponseEvent } from './events/formSubmitResponse';
import type { SdkActionEvent } from '@/src/sdk/events/actionEvent';
import type { SdkGameEndEvent } from '@/src/sdk/events/gameEndEvent';

export type SdkEvents = {
  formSubmit: (e: SdkFormSubmitEvent) => void;
  formSubmitResponse: (e: SdkFormSubmitResponseEvent) => void;
  flowPageChange: (e: SdkFlowPageChangeEvent) => void;
  gameEnd: (e: SdkGameEndEvent) => void;
  action: (e: SdkActionEvent) => void;
};

export const SdkEventsEmitter = new EventEmitter() as TypedEventEmitter<SdkEvents>;
