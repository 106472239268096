import type { GameIndicator, GameState, GameTimeChallenge, HasGameTimeChallenge } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import { getDeviceData } from '@/src/hooks/useDevice';
import type {
  GameHitAMolePositionType,
  IGameHitAMoleCursorData,
  IGameHitAMoleData,
  IGameHitAMoleGeneralData,
  IGameHitAMoleHolesData,
  IGameHitAMoleLayoutData,
  IGameHitAMoleVisualData,
  IGameHitMoleData,
  IGameHitMoleImageData
} from '@/src/components/games/hit-a-mole/Data';
import { useUtilityStore } from '@/src/store/utility';

export type HitAMoleGameLevel = 'very_easy' | 'easy' | 'normal' | 'hard' | 'custom';

interface Difficulty {
  minTimeUp: number;
  maxTimeUp: number;
  animationDuration: number;
}

interface DifficultyLevels {
  very_easy: Difficulty;
  easy: Difficulty;
  normal: Difficulty;
  hard: Difficulty;
}

interface IGameHitAMoleGeneralState extends Difficulty {
  pointsToWin: number;
  level: HitAMoleGameLevel;
}

export interface IGameHitAMoleCursorState {
  image: string;
  offset: {
    x: number;
    y: number;
  };
}

export interface IGameHitAMoleHolesState {
  holeImage?: string;
  maxWidth?: string;
}

export interface IGameHitAMoleLayoutState {
  holeSpacing: string;
  fontSize: string;
  fontFamily?: string;
  negativeColor: string;
  positiveColor: string;
  rowSpacing: string;
  rows: number;
}

export interface IGameHitMoleImageState {
  id: number;
  image: string;
  label: string;
  points: number;
  maxWidth: string | undefined;
}

export interface GameTargetHole {
  id: number;
  showMole: boolean;
  timeUp: number;
  holeImage: string;
  holeMaxWidth?: string;
  position: GameHitAMolePositionType;
  offsetY: number;
  zIndex: number;
}

export type IGameHitAMoleEnterAnimation = 'fadeIn' | 'bounceIn' | 'slideIn' | 'rotateIn';

export interface IGameHitMoleState {
  images: IGameHitMoleImageState[];
  enter: IGameHitAMoleEnterAnimation;
  offsetY?: number;
  position: GameHitAMolePositionType;
}

interface IGameHitAMoleVisualState {
  cursor: IGameHitAMoleCursorState;
  holes?: IGameHitAMoleHolesState;
  layout?: IGameHitAMoleLayoutState;
  moles?: IGameHitMoleState;
}

interface IGameHitAMoleState extends GameState {
  general: IGameHitAMoleGeneralState;
  visuals: IGameHitAMoleVisualState;
}

export class GameHitAMoleModel
  extends GameModel<IGameHitAMoleData, IGameHitAMoleState>
  implements HasGameTimeChallenge
{
  parseGame(data: IGameHitAMoleData) {
    const state = this.state;

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }

    state.general = GameHitAMoleModel.constructGeneralState(data.general);
    state.visuals = GameHitAMoleModel.constructVisualsState(data);

    if (state.visuals.layout?.fontFamily) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(state.visuals.layout.fontFamily);
    }
  }

  private static constructGeneralState(data: IGameHitAMoleGeneralData): IGameHitAMoleGeneralState {
    const defaultDifficulties: DifficultyLevels = {
      very_easy: { minTimeUp: 1000, maxTimeUp: 4000, animationDuration: 1400 },
      easy: { minTimeUp: 700, maxTimeUp: 3500, animationDuration: 1200 },
      normal: { minTimeUp: 500, maxTimeUp: 2000, animationDuration: 1000 },
      hard: { minTimeUp: 200, maxTimeUp: 800, animationDuration: 200 }
    };

    const level = data?.level ?? 'easy';

    let difficulty: Difficulty;
    if (level === 'custom') {
      difficulty = {
        minTimeUp: Number(data?.custom_level.min_time_up ?? 200),
        maxTimeUp: Number(data?.custom_level.max_time_up ?? 3000),
        animationDuration: Number(data?.custom_level.animation_duration ?? 1000)
      };
    } else {
      switch (level) {
        case 'very_easy':
          difficulty = defaultDifficulties.very_easy;
          break;

        case 'easy':
          difficulty = defaultDifficulties.easy;
          break;

        case 'normal':
          difficulty = defaultDifficulties.normal;
          break;

        case 'hard':
          difficulty = defaultDifficulties.hard;
          break;
      }
    }

    return {
      pointsToWin: data?.points_to_win ? Number(data.points_to_win) : 500,
      level,
      ...difficulty
    };
  }

  private static constructCursorState(data: IGameHitAMoleCursorData): IGameHitAMoleCursorState {
    return {
      image: data.image,
      offset: {
        x: Number(data.image_offset_x),
        y: Number(data.image_offset_y)
      }
    };
  }

  private static constructLayoutState(data: IGameHitAMoleGeneralData): IGameHitAMoleLayoutState | undefined {
    const deviceLayoutData = getDeviceData<IGameHitAMoleLayoutData>(data.layout);

    if (deviceLayoutData) {
      return {
        holeSpacing: deviceLayoutData.hole_spacing || '30px',
        fontSize: deviceLayoutData.font_size || '20px',
        fontFamily: deviceLayoutData.font_type,
        negativeColor: deviceLayoutData.negative_color,
        positiveColor: deviceLayoutData.positive_color,
        rowSpacing: deviceLayoutData.row_spacing || '30px',
        rows: Number(deviceLayoutData.rows ?? 3)
      };
    }
  }

  private static constructMoleImageState(data: IGameHitMoleImageData): IGameHitMoleImageState {
    return {
      id: Number(data.id),
      image: data.image,
      points: Number(data.points),
      maxWidth: data.max_width,
      label: data.label
    };
  }

  private static constructHolesState(data: IGameHitAMoleVisualData): IGameHitAMoleHolesState | undefined {
    const deviceLayoutData = getDeviceData<IGameHitAMoleHolesData>(data.holes);

    if (deviceLayoutData) {
      return {
        holeImage: deviceLayoutData.hole_image,
        ...(deviceLayoutData.max_width && {
          maxWidth: deviceLayoutData.max_width
        })
      };
    }
  }

  private static constructMoleState(data: IGameHitAMoleVisualData): IGameHitMoleState | undefined {
    const deviceMoleData = getDeviceData<IGameHitMoleData>(data.moles);

    if (deviceMoleData) {
      return {
        offsetY: Number(deviceMoleData.offset_y),
        images: deviceMoleData.images.map((mole) => {
          return GameHitAMoleModel.constructMoleImageState(mole);
        }),
        enter: deviceMoleData.enter,
        position: deviceMoleData.position
      };
    }
  }

  private static constructVisualsState(data: IGameHitAMoleData): IGameHitAMoleVisualState {
    return {
      cursor: GameHitAMoleModel.constructCursorState(data.visuals.cursor),
      holes: GameHitAMoleModel.constructHolesState(data.visuals),
      layout: GameHitAMoleModel.constructLayoutState(data.general),
      moles: GameHitAMoleModel.constructMoleState(data.visuals)
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 60,
          time_used: 0
        }
      },
      {
        indicatorKey: 'points',
        metricKey: {
          points_used: 'points'
        },
        icon: GameIndicatorIcon.POINTS,
        value: {
          points_used: 0
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.general?.time === 1,
      ...(data.general?.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }
}
