<template>
  <teleport to="#app">
    <transition name="fade" :appear="true">
      <div
        v-if="!isShow && !campaignStore.model?.state.isEditModeActive"
        :class="{
          'cookie-consent-toggle': true,
          top: !isPositionBottom,
          bottom: isPositionBottom
        }"
        :style="{
          background: model.state.settings?.palette?.button?.background,
          color: model.state.settings?.palette?.button?.text?.accept,
          fontFamily: popupFont
        }"
        @click="handleToggleShowClick"
      >
        <CookieIcon class="cookie-icon" :style="{ fill: model.state.settings?.palette?.button?.text?.accept }" />
      </div>
    </transition>
    <transition name="fade" :appear="true">
      <div
        v-if="isShow && !campaignStore.model?.state.isEditModeActive"
        class="cookie-consent-wrapper"
        :style="{
          background: model.state.settings?.palette?.popup?.background,
          color: model.state.settings?.palette?.popup?.text,
          fontFamily: popupFont,
          top: isPositionBottom ? 'auto' : 0,
          bottom: isPositionBottom ? 0 : 'auto',
          zIndex: 7777777
        }"
      >
        <h6 class="cookie-consent-headline">
          {{ model.state.settings?.content?.headline }}
        </h6>
        <div class="cookie-consent-message">
          {{ model.state.settings?.content?.message }}
          <a
            :href="model.state.settings?.content?.href"
            target="_blank"
            :style="{ color: model.state.settings?.palette?.popup?.text }"
          >
            {{ model.state.settings?.content?.link }}
          </a>
        </div>
        <div class="cookie-consent-buttons">
          <div class="row">
            <div class="col-md-6 col-sm-12 cookie-categories">
              <div v-if="isFunctionalEnabled" class="btn-switch">
                <label class="switch">
                  <input v-model="selectedFunctional" type="checkbox" />
                  <span
                    class="slider"
                    :style="{
                      backgroundColor: selectedFunctional ? model.state.settings?.palette?.button?.background : '#ccc'
                    }"
                  ></span>
                </label>
                <span class="label">{{ model.state.settings?.content?.functional }}</span>
              </div>
              <div v-if="isStatisticsEnabled" class="btn-switch">
                <label class="switch">
                  <input v-model="selectedStatistics" type="checkbox" />
                  <span
                    class="slider"
                    :style="{
                      backgroundColor: selectedStatistics ? model.state.settings?.palette?.button?.background : '#ccc'
                    }"
                  ></span>
                </label>
                <span class="label">{{ model.state.settings?.content?.statistics }}</span>
              </div>
              <div v-if="isMarketingEnabled" class="btn-switch">
                <label class="switch">
                  <input v-model="selectedMarketing" type="checkbox" />
                  <span
                    class="slider"
                    :style="{
                      backgroundColor: selectedMarketing ? model.state.settings?.palette?.button?.background : '#ccc'
                    }"
                  ></span>
                </label>
                <span class="label">{{ model.state.settings?.content?.marketing }}</span>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 cookie-actions">
              <button
                class="btn btn-lg"
                type="button"
                :style="{
                  borderColor: model.state.settings?.palette?.button?.background,
                  borderRadius: model.state.settings?.palette?.button?.radius,
                  color: model.state.settings?.palette?.button?.text?.deny
                }"
                @click="handleButtonDenyAllClick"
              >
                {{ model.state.settings?.content?.deny }}
              </button>
              <button
                class="btn btn-lg"
                type="button"
                :style="{
                  borderColor: model.state.settings?.palette?.button?.background,
                  borderRadius: model.state.settings?.palette?.button?.radius,
                  color: model.state.settings?.palette?.button?.text?.deny
                }"
                @click="handleButtonAllowSelectedClick"
              >
                {{ model.state.settings?.content?.allowselected }}
              </button>
              <button
                class="btn btn-lg"
                type="button"
                :style="{
                  background: model.state.settings?.palette?.button?.background,
                  borderRadius: model.state.settings?.palette?.button?.radius,
                  color: model.state.settings?.palette?.button?.text?.accept
                }"
                @click="handleButtonAllowAllClick"
              >
                {{ model.state.settings?.content?.allow }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, ref } from 'vue';
import type { IntegrationCookieConsentV2Model } from './Model';
import {
  CookieCategory,
  cookieConsentAccept,
  cookieConsentReject,
  hasChosenCookieConsent,
  hasCookieAccess
} from '@/src/services/cookieConsent';
import { useUtilityStore } from '@/src/store/utility';
import { PopoverPosition } from '@/src/components/integrations/cookie-consent-v2/Types';
import CookieIcon from '@/src/assets/svg/cookie.svg?component';
import { useCampaignStore } from '@/src/store/campaign';

export default defineComponent({
  name: 'integration-cookie-consent-v2',
  components: {
    CookieIcon
  },
  props: {
    model: {
      type: Object as PropType<IntegrationCookieConsentV2Model>,
      required: true
    }
  },
  setup(props) {
    const utilityStore = useUtilityStore();
    const campaignStore = useCampaignStore();

    const popupFont = computed(() => {
      const font = props.model.state.settings?.palette?.popup?.font;

      if (font) {
        utilityStore.loadFont(font);
      }

      return font ? `${font}, Arial` : undefined;
    });

    const isPositionBottom = computed(() => {
      return props.model.state.settings?.position === PopoverPosition.BOTTOM;
    });

    const isFunctionalEnabled = computed(() => {
      return props.model.state.settings?.content?.enableFunctional;
    });

    const isStatisticsEnabled = computed(() => {
      return props.model.state.settings?.content?.enableStatistics;
    });

    const isMarketingEnabled = computed(() => {
      return props.model.state.settings?.content?.enableMarketing;
    });

    if (typeof window !== 'undefined') {
      window.cookieWait = true;
    }

    const selectedFunctional = ref(hasCookieAccess(CookieCategory.FUNCTIONAL));
    const selectedStatistics = ref(hasCookieAccess(CookieCategory.STATISTICS));
    const selectedMarketing = ref(hasCookieAccess(CookieCategory.MARKETING));
    const isShow = ref(!hasChosenCookieConsent());

    if (!hasChosenCookieConsent()) {
      selectedFunctional.value = true;
      selectedStatistics.value = false;
      selectedMarketing.value = false;
    }

    const handleButtonDenyAllClick = () => {
      if (isFunctionalEnabled.value) {
        cookieConsentReject(CookieCategory.FUNCTIONAL);
        selectedFunctional.value = false;
      }

      if (isStatisticsEnabled.value) {
        cookieConsentReject(CookieCategory.STATISTICS);
        selectedStatistics.value = false;
      }

      if (isMarketingEnabled.value) {
        cookieConsentReject(CookieCategory.MARKETING);
        selectedMarketing.value = false;
      }

      isShow.value = false;
    };

    const handleButtonAllowSelectedClick = () => {
      if (isFunctionalEnabled.value) {
        if (selectedFunctional.value) {
          cookieConsentAccept(CookieCategory.FUNCTIONAL);
        } else {
          cookieConsentReject(CookieCategory.FUNCTIONAL);
        }
      }

      if (isStatisticsEnabled.value) {
        if (selectedStatistics.value) {
          cookieConsentAccept(CookieCategory.STATISTICS);
        } else {
          cookieConsentReject(CookieCategory.STATISTICS);
        }
      }

      if (isMarketingEnabled.value) {
        if (selectedMarketing.value) {
          cookieConsentAccept(CookieCategory.MARKETING);
        } else {
          cookieConsentReject(CookieCategory.MARKETING);
        }
      }

      isShow.value = false;
    };

    const handleButtonAllowAllClick = () => {
      if (isFunctionalEnabled.value) {
        cookieConsentAccept(CookieCategory.FUNCTIONAL);
        selectedFunctional.value = true;
      }

      if (isStatisticsEnabled.value) {
        cookieConsentAccept(CookieCategory.STATISTICS);
        selectedStatistics.value = true;
      }

      if (isMarketingEnabled.value) {
        cookieConsentAccept(CookieCategory.MARKETING);
        selectedMarketing.value = true;
      }

      isShow.value = false;
    };

    const handleToggleShowClick = () => {
      isShow.value = true;
    };

    return {
      handleButtonDenyAllClick,
      handleButtonAllowSelectedClick,
      handleButtonAllowAllClick,
      handleToggleShowClick,
      selectedFunctional,
      selectedStatistics,
      selectedMarketing,
      isShow,
      isPositionBottom,
      popupFont,
      campaignStore,
      isFunctionalEnabled,
      isStatisticsEnabled,
      isMarketingEnabled
    };
  }
});
</script>

<style lang="scss" scoped>
.cookie-consent-toggle {
  left: 20px;
  padding: 0.5em;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5em;
  display: flex;
  flex-wrap: nowrap;
  z-index: 9999;
  transition: transform 1s ease;
  cursor: pointer;
  border-radius: 50%;

  &.top {
    top: 20px;
  }

  &.bottom {
    bottom: 20px;
  }

  svg {
    width: 32px;
    height: 32px;
    position: relative;
  }
}

.cookie-consent-wrapper {
  padding: 16px 75px;
  position: fixed;
  width: 100%;

  .cookie-consent-headline {
    font-weight: bold;
  }

  .cookie-consent-message {
    padding-top: 8px;

    a {
      text-decoration: underline;
    }
  }

  .cookie-consent-buttons {
    padding-top: 24px;

    .btn-switch {
      float: left;
      margin-right: 28px;

      .switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 23px;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }

      .label {
        padding-left: 8px;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 34px;
        background-color: #ccc;
        transition: 0.4s;

        &:before {
          position: absolute;
          content: '';
          height: 16px;
          width: 16px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      input:checked + .slider {
        background-color: #2196f3;
      }

      input:checked + .slider:before {
        transform: translateX(12px);
      }
    }

    .cookie-actions {
      text-align: right;

      button {
        font-weight: bold;
        min-width: 148px;
        height: 39px;
        margin-left: 13px;
      }
    }
  }
}

.site--mobile {
  .cookie-consent-wrapper {
    padding: 20px 16px 16px;

    .cookie-consent-headline {
      font-size: 1.7rem;
    }

    .cookie-consent-buttons {
      .btn-switch {
        margin-bottom: 8px;
        margin-right: 16px;
      }

      .cookie-categories {
        margin-bottom: 16px;
      }

      .cookie-actions {
        text-align: inherit;

        button {
          width: 100%;
          margin-left: 0;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
