<template>
  <div
    v-if="(isTablet && (deviceRotation === 'portrait-primary' || deviceRotation === 'portrait-secondary')) || forceShow"
    class="lf-rotation"
  >
    <!--Tablet -->
    <svg
      viewBox="0 0 86 114"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Tablet_Portrait" transform="translate(-341.000000, -437.000000)" fill="#ffffff">
          <g id="Group" transform="translate(341.000000, 437.050000)">
            <g id="Group-4" transform="translate(0.000000, 27.950000)">
              <path
                id="Combined-Shape"
                d="M44.9999,-0.0004 L45.9999,1.0006 L45.9999,39.9996 L84.9999,39.9996 L86.0009,40.9996 L86.0009,85.0006 L84.9999,85.9996 L23.0009,85.9996 L21.9999,85.0006 L21.9999,64.0006 L0.9999,64.0006 L-0.0001,62.9996 L-0.0001,1.0006 L0.9999,-0.0004 L44.9999,-0.0004 Z M83.9999,41.9996 L45.9999,41.9996 L45.9999,62.9996 L44.9999,64.0006 L23.9999,64.0006 L23.9999,84.0006 L83.9999,84.0006 L83.9999,41.9996 Z M43.9999,2.0006 L1.9999,2.0006 L1.9999,61.9996 L21.9999,61.9996 L21.9999,40.9996 L23.0009,39.9996 L43.9999,39.9996 L43.9999,2.0006 Z M43.9999,41.9996 L23.9999,41.9996 L23.9999,61.9996 L43.9999,61.9996 L43.9999,41.9996 Z M55,10.0459 C64.021,10.0459 71.402,17.1139 71.947,25.9999 L71.947,25.9999 L75.208,25.9999 L71.212,32.2569 L67.215,25.9999 L69.947,25.9999 C69.406,18.2169 62.918,12.0459 55,12.0459 C54.447,12.0459 54,11.5979 54,11.0459 C54,10.4939 54.447,10.0459 55,10.0459 Z"
              ></path>
            </g>
            <path
              id="Flip"
              d="M7.38071429,2.1 L2.95571429,2.1 L2.95571429,5.04 L7.23071429,5.04 L7.23071429,6.69 L2.95571429,6.69 L2.95571429,10.95 L1.23071429,10.95 L1.23071429,0.45 L7.38071429,0.45 L7.38071429,2.1 Z M9.75285714,10.95 L9.75285714,2.84217094e-14 L11.3728571,2.84217094e-14 L11.3728571,10.95 L9.75285714,10.95 Z M14.915,2.325 C14.345,2.325 13.88,1.845 13.88,1.29 C13.88,0.72 14.345,0.255 14.915,0.255 C15.485,0.255 15.95,0.72 15.95,1.29 C15.95,1.845 15.485,2.325 14.915,2.325 Z M14.105,10.95 L14.105,3.45 L15.725,3.45 L15.725,10.95 L14.105,10.95 Z M22.7021429,3.255 C24.7421429,3.255 26.4371429,4.98 26.4371429,7.2 C26.4371429,9.435 24.7421429,11.145 22.7021429,11.145 C21.5321429,11.145 20.6471429,10.665 20.0771429,9.885 L20.0771429,13.95 L18.4571429,13.95 L18.4571429,3.45 L20.0771429,3.45 L20.0771429,4.53 C20.6471429,3.735 21.5321429,3.255 22.7021429,3.255 Z M22.4471429,9.6 C23.7971429,9.6 24.8171429,8.595 24.8171429,7.2 C24.8171429,5.805 23.7971429,4.8 22.4471429,4.8 C21.0971429,4.8 20.0771429,5.805 20.0771429,7.2 C20.0771429,8.595 21.0971429,9.6 22.4471429,9.6 Z"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import useDevice from '@/src/hooks/useDevice';
import { useUtilityStore } from '@/src/store/utility';

export default defineComponent({
  name: 'DeviceRotater',
  props: {
    forceShow: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const utilityStore = useUtilityStore();
    const { isMobile, isTablet } = useDevice();
    const deviceRotation = ref<string | undefined>();

    const iosOrientationChange = () => {
      switch (deviceRotation.value) {
        case 'portrait-primary':
        case 'portrait-secondary':
          utilityStore.removeBodyClasses(['site--rotated-tablet']);
          break;

        case 'landscape-primary':
        case 'landscape-secondary':
          utilityStore.addBodyClasses(['site--rotated-tablet']);
      }
    };

    // iOS doesn't support window.screen.orientation.type
    const readISODeviceOrientation = () => {
      switch (window.orientation) {
        case 0:
          // iPad is in Portrait mode.
          return 'portrait-primary';
        case 180:
          // Upside down portrait.
          return 'portrait-primary';
        case -90:
          // iPad is in Landscape mode. The screen is turned to the right.
          return 'landscape-secondary';
        case 90:
          // iPad is in Landscape mode. The screen is turned to the left.
          return 'landscape-primary';
        case -180:
          return 'portrait-secondary';
      }
    };

    onMounted(() => {
      if (window.screen.orientation) {
        deviceRotation.value = window.screen.orientation.type;
      } else {
        deviceRotation.value = readISODeviceOrientation();
      }

      iosOrientationChange();
    });

    const handleResize = () => {
      iosOrientationChange();
    };

    const handleOrientationChange = () => {
      if (window.screen.orientation) {
        deviceRotation.value = window.screen.orientation.type;
      } else {
        deviceRotation.value = readISODeviceOrientation();
      }

      iosOrientationChange();
    };

    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('resize', handleResize);

    onUnmounted(() => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleOrientationChange);
      }
    });

    return {
      isMobile,
      isTablet,
      deviceRotation
    };
  }
});
</script>

<style lang="scss">
.lf-rotation {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  justify-content: center;
  align-items: center;

  svg {
    display: inline-block;
    max-width: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0 !important;
    .site--mobile & {
      width: 150px;
    }

    .site--tablet & {
      width: 250px;
    }
  }
}
</style>
