<template>
  <component is="style" type="text/css">
    {{ style }}
  </component>
  <div
    class="section__divider"
    :style="dividerStyles"
    :class="{
      'section__divider--flip': settings?.flip,
      'section__divider--to-front': settings?.front
    }"
  >
    <component :is="DIVIDERS[settings?.type]" v-if="settings?.type" />
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import CurveAsymmetrical from '../../assets/svg/curve-asymmetrical-negative.svg?component';
import Curve from '../../assets/svg/curve-negative.svg?component';
import Tilt from '../../assets/svg/tilt.svg?component';
import TiltOpacity from '../../assets/svg/opacity-tilt.svg?component';
import Clouds from '../../assets/svg/clouds.svg?component';
import Triangle from '../../assets/svg/triangle.svg?component';
import TriangleAsymmetrical from '../../assets/svg/triangle-asymmetrical-negative.svg?component';
import Waves from '../../assets/svg/waves-negative.svg?component';
import WavesPattern from '../../assets/svg/waves-pattern.svg?component';
import WavesBrush from '../../assets/svg/wave-brush.svg?component';
import Zigzag from '../../assets/svg/zigzag.svg?component';
import Pyramids from '../../assets/svg/pyramids.svg?component';
import Drops from '../../assets/svg/drops.svg?component';
import Split from '../../assets/svg/split-negative.svg?component';
import Mountains from '../../assets/svg/mountains.svg?component';
import FanOpacity from '../../assets/svg/opacity-fan.svg?component';
import Book from '../../assets/svg/book-negative.svg?component';
import type { DividerState } from '@/src/components/layout/SettingsModel';
import { DividerPositionType } from '@/src/typings/enums/enums';

const DIVIDERS = {
  curve_asymmetrical: CurveAsymmetrical,
  curve: Curve,
  tilt: Tilt,
  tilt_opacity: TiltOpacity,
  clouds: Clouds,
  triangle: Triangle,
  triangle_asymmetrical: TriangleAsymmetrical,
  waves: Waves,
  waves_pattern: WavesPattern,
  waves_brush: WavesBrush,
  zigzag: Zigzag,
  pyramids: Pyramids,
  drops: Drops,
  split: Split,
  mountains: Mountains,
  fan_opacity: FanOpacity,
  book: Book
};

export default defineComponent({
  name: 'UIDivider',
  props: {
    settings: {
      type: Object as PropType<DividerState>
    },
    sectionId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const style = computed(() => {
      let invert = '';
      let rotate = '';
      if (props.settings && props.settings.invert) {
        invert += ' rotateX(180deg) ';
      }
      if (props.settings && props.settings.flip) {
        rotate += ' rotateY(180deg) ';
      }
      const scalaX =
        (props.settings && props.settings.width ? 'scaleX(' + props.settings.width / 100 + ')' : '') + invert + rotate;

      let style = '';
      style +=
        '#prefix# svg { transform: ' +
        scalaX +
        '!important; -webkiz-transform: ' +
        scalaX +
        '!important; -moz-transform:' +
        scalaX +
        '!important; }';

      if (props.settings?.position) {
        switch (props.settings?.position) {
          case DividerPositionType.TOP:
            style += '#prefix# { top: 0px !important; bottom: auto !important; }';

            break;

          case DividerPositionType.BOTTOM:
            style += '#prefix# { top: auto !important; bottom: 0px !important; }';

            break;
          default:
            style += '#prefix# { top: 0px !important; bottom: auto !important; }';
        }
      }

      const prefix = `.site .section.section--${props.sectionId} .section__divider`;
      style = style.replace(/#prefix#/g, prefix);
      return style;
    });

    const dividerStyles = computed<CSSProperties>(() => {
      return {
        height: Number(props.settings?.height) + 'px',
        width: '100%',
        color: props.settings?.color
      };
    });

    return {
      dividerStyles,
      style,
      DIVIDERS
    };
  }
});
</script>

<style lang="scss">
.section__divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  pointer-events: none;
  overflow: hidden;

  &--flip {
    svg {
      transform: rotate(180deg);
    }
  }
  &--to-front {
    z-index: 4;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    fill: currentColor;
  }

  path {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    fill: currentColor;
  }
}
</style>
