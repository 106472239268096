import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export interface FormElementDawaSettingsData extends BaseFormElementSettingsData {
  only_address?: string;
  limit?: string;
  validate_dawa_input?: string;
  manual_input?: {
    allow?: string;
    on_empty?: string;
    label?: string;
  };
  fuzzy?: string;
}

export interface FormElementDawaData extends BaseFormElementData {
  settings?: FormElementDawaSettingsData;
}

export interface FormElementDawaValue {
  tekst: string;
  adresse?: FormElementDawaValueData;
}

export interface FormElementDawaValueData {
  id?: string;
  status?: number;
  darstatus?: number;
  vejkode?: string;
  adresseringsvejnavn?: string;
  etage?: string;
  dør?: string;
  supplerendebynavn?: string;
  stormodtagerpostnr?: string;
  stormodtagerpostnrnavn?: string;
  adgangsadresseid?: string;
  x?: number;
  y?: number;
  navn?: string;
  postnr?: string;
  husnr?: string;
  vejnavn?: string;
  postnrnavn?: string;
  href?: string;
  kvhx?: string;
  kommunekode?: string;
}

export interface FormElementDawaState extends BaseFormElementState<FormElementDawaValue> {
  onlyAddress?: boolean;
  limit?: number;
  validateDawaInput?: boolean;
}

export class FormElementDawaModel extends BaseFormElementModel<
  FormElementDawaValue,
  FormElementDawaData,
  FormElementDawaState
> {
  parseFormElement(data: FormElementDawaData) {
    this.state.onlyAddress = data.settings?.only_address === '1';
    this.state.limit = data.settings?.limit ? Number(data.settings?.limit) : 10;
    this.state.validateDawaInput = data.settings?.validate_dawa_input === '1';
  }

  getValidationRules(): string[] {
    const rules = super.getValidationRules();

    if (this.state.validateDawaInput) {
      rules.push('has_dawa_selection');
    }

    return rules;
  }

  getInitialValue(): FormElementDawaValue | undefined {
    return this.parseStringValue(this.getInitialStringValue() ?? '');
  }

  getStringifiedValue(): string | undefined {
    return this.state.value
      ? `${this.state.value.tekst}, ${this.state.value.adresse?.id}, ${this.state.value.adresse?.kvhx}`
      : undefined;
  }

  parseStringValue(value: string): FormElementDawaValue | undefined {
    if (value && value[0] === '{') {
      return JSON.parse(value) as FormElementDawaValue;
    }

    return undefined;
  }

  getSerializedPostValue(): string {
    return typeof this.state.value === 'object' ? JSON.stringify(this.state.value) : '';
  }

  getSerializedCookieValue(): string {
    return this.getSerializedPostValue();
  }
}
