<template>
  <component
    v-bind="$attrs"
    :is="tag"
    v-if="isContenteditable"
    ref="textRef"
    :is-contenteditable="isContenteditable"
    :contenteditable="true"
    :tabindex="tabindex"
    nosem
    >{{ truncatedHtmlValue }}</component
  >

  <component
    v-bind="$attrs"
    :is="tag"
    v-else
    ref="textRef"
    :tabindex="tabindex"
    nosem
    v-html="truncatedHtmlValue"
  ></component>

  <component
    :is="truncateHtmlTag"
    v-if="truncate?.enabled"
    class="truncate-toggle"
    @click.stop.prevent="toggleFullHtml"
  >
    {{ !showTruncatedHtml ? truncate.labelLess : truncate.labelMore }}
  </component>
</template>

<script lang="ts">
import type { PropType, VNode } from 'vue';
import { computed, defineComponent, onMounted, onUpdated, ref } from 'vue';
import { replaceHrefsWithUrlTrackingAndReplacementTags } from '@/src/utilities/Url';
import { applyReplacementTags } from '@/src/utilities/Utilities';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import UIButton from '@/src/components/ui/UIButton.vue';
import type { TruncateState } from '@/src/utilities/Truncate';
import { truncateHtmlContent } from '@/src/utilities/Truncate';

export default defineComponent({
  name: 'RichTextRenderer',
  components: {
    UIButton
  },
  inheritAttrs: false,
  props: {
    html: {
      type: String
    },
    tag: {
      type: String,
      default: 'span'
    },
    extraReplacementTags: {
      type: Object as PropType<ReplacementTags>,
      required: false
    },
    truncate: {
      type: Object as PropType<TruncateState>,
      required: false
    },
    tabindex: {
      type: Number,
      default: undefined
    },
    isContenteditable: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const campaignStore = useCampaignStore();
    const textRef = ref<HTMLElement | VNode>();
    const showTruncatedHtml = ref(true);
    const toggleFullHtml = () => {
      showTruncatedHtml.value = !showTruncatedHtml.value;
    };

    /**
     * TODO: We might need to find a way to handle internal links and scroll to section more gracefully
     */
    const htmlValue = computed(() => {
      if (props.html) {
        const html = props.html.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>');

        // TODO: Refactor this. We should not construct HTML in a computed from actual DOM
        let link: null | string = null;
        const temp = document.createElement('div');
        temp.innerHTML = html;
        const tempSelector = temp.querySelectorAll('a');
        let apply = applyReplacementTags(temp.innerHTML, props.extraReplacementTags);

        if (tempSelector) {
          tempSelector.forEach((selector) => {
            link = selector.getAttribute('href');

            if (link && link.includes('#/?') && link.includes('section')) {
              const linkParts = link?.split('=');

              if (linkParts && linkParts.length > 0 && selector) {
                selector?.classList.add('section-link');
                selector?.setAttribute('data-section', linkParts[1]);
                apply = applyReplacementTags(temp.innerHTML, props.extraReplacementTags);
              }
            } else if (link && link.includes('#/?') && !link.includes('section')) {
              const linkParts = link?.split('=');

              if (linkParts && linkParts.length > 0) {
                selector?.classList.add('internal-link');
                selector?.setAttribute('data-pageid', !link.includes('close-popover') ? linkParts[1] : '');
                apply = applyReplacementTags(temp.innerHTML, props.extraReplacementTags);
              }
            }
          });

          return replaceHrefsWithUrlTrackingAndReplacementTags(apply, props.extraReplacementTags);
        }

        /**
         * If the link is internal, we dont want to add tracking to the URL, as that cant be resolved
         * Instead, we want to open a popover, which we handle in the onMounted
         */

        return replaceHrefsWithUrlTrackingAndReplacementTags(html, props.extraReplacementTags);
      }

      return '';
    });

    // Usage within the setup function
    const truncatedHtmlValue = computed(() => {
      return props.truncate?.enabled && showTruncatedHtml.value && props.truncate?.limit
        ? truncateHtmlContent(htmlValue.value, props.truncate?.limit)
        : htmlValue.value;
    });

    // we want to match the truncate text with same format as the html text
    // for now we just find the first tag in html and use that to wrap the truncate text
    // might be a better way, but the html can hold multiple tags, so we need to pick one
    const truncateHtmlTag = computed(() => {
      if (!props.html) {
        return 'p';
      }
      // look only for h1, h2, h3, h4, h5, h6, and p
      const matches = /<\s*(h1|h2|h3|h4|h5|h6|p)\b[^>]*>/i.exec(props.html);
      return matches ? matches[1] : 'p'; // Default to p tag
    });

    const applyEventListeners = () => {
      const links =
        textRef.value instanceof HTMLElement
          ? textRef.value?.querySelectorAll('a')
          : textRef.value?.el instanceof HTMLElement
          ? textRef.value?.el.querySelectorAll('a')
          : undefined;

      if (links && links.length > 0) {
        links.forEach((link) => {
          if (link.classList.contains('internal-link')) {
            link.addEventListener('click', (e) => {
              e.preventDefault();

              const pageId = link.getAttribute('data-pageid');
              campaignStore.setActivePopover(Number(pageId));
            });
          }

          if (link.classList.contains('section-link')) {
            link.addEventListener('click', (e) => {
              e.preventDefault();

              const sectionId = link.getAttribute('data-section');
              const el = document.querySelector(`.section--${sectionId}`);

              if (el) {
                el.scrollIntoView({
                  behavior: 'smooth'
                });
              }
            });
          }
        });
      }
    };

    onMounted(() => {
      applyEventListeners();
    });

    onUpdated(() => {
      applyEventListeners();
    });

    return { htmlValue, textRef, truncatedHtmlValue, showTruncatedHtml, toggleFullHtml, truncateHtmlTag };
  }
});
</script>

<style lang="scss" scoped>
.truncate-toggle {
  cursor: pointer;
}
</style>
