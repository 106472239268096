import type { CSSProperties } from 'vue';
import type {
  GameDiceAdvancedData,
  GameDiceCupLayoutData,
  GameDiceCustomLabelData,
  GameDiceData,
  GameDiceDiceLayoutData,
  GameDiceGeneralData
} from '@/src/components/games/dice/Data';

import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { GameIndicator, GameState, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition, GameIndicatorSettingsModel } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import type { CampaignIntervalType } from '@/src/typings/enums/enums';
import { DrawType } from '@/src/typings/enums/enums';
import type { DiceTriggerType } from '@/src/components/games/dice/enums';
import { DiceTypes } from '@/src/components/games/dice/enums';

export interface GameDiceGeneralState {
  type: DiceTypes;
  tries: number;
  delay: number;
  rollDiceTrigger: DiceTriggerType;
  winnerChance?: number;
  throwDice: boolean;
  drawType: DrawType;
  campaignLimitInterval?: CampaignIntervalType;
  campaignLimit?: number;
  fieldsOccupied?: number[];
}

export interface GameDiceCustomLabelState {
  rollDice: string;
  triesLeft: string;
}

export interface GameDiceCupState {
  hue: number;
  saturation: number;
  brightness: number;
  contrast: number;
}

export interface GameDiceDiceState {
  type?: 'custom' | '';
  images?: string[];
  color?: string;
  eyesColor?: string;
}

export interface GameDiceLayoutState {
  cup?: GameDiceCupState;
  dice?: GameDiceDiceState;
}

export interface GameDiceAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GameDiceAdvancedState {
  sound: GameDiceAdvancedSoundState;
}

export interface GameDiceState extends GameState {
  general: GameDiceGeneralState;
  customlabels: GameDiceCustomLabelState;
  layout: GameDiceLayoutState;
  indicators: GameIndicatorSettingsModel;
  advanced: GameDiceAdvancedState;
  elementStyling?: {
    inline?: CSSProperties;
    diceStyle?: string;
    layout?: string;
  };
  images: string[];
}

export class GameDiceModel extends GameModel<GameDiceData, GameDiceState> implements HasSound {
  parseGame(data: GameDiceData) {
    const state = this.state;

    state.general = GameDiceModel.constructGeneralState(data.general);

    state.layout = state.layout || {};

    if (data.layout.cup) {
      state.layout.cup = GameDiceModel.parseLayoutCupData(data.layout.cup);
    }

    if (data.layout.dice) {
      state.layout.dice = GameDiceModel.parseLayoutDiceData(data.layout.dice);
    }

    state.customlabels = GameDiceModel.constructCustomLabelsState(data.customlabels);

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameDiceModel.constructAdvancedSoundState(data.advanced);

    state.elementStyling = {};
    if (state) {
      state.elementStyling.diceStyle = GameDiceModel.constructStyleClassesStyles(state);
    }
  }

  private static constructAdvancedSoundState(data: GameDiceAdvancedData | undefined): GameDiceAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructStyleClassesStyles(state: GameDiceState): string | undefined {
    let style = '';

    let diceIndex: string;
    const diceMap = ['back', 'left', 'top', 'bottom', 'right', 'front'];

    if (state.general.type === DiceTypes.DICE) {
      if (state.layout.dice?.eyesColor) {
        style = `.dice-game__dice-eye {
          background-color: ${state.layout.dice.eyesColor};
        } `;
      }

      if (state.layout.dice?.color) {
        style += `.dice-game__dice-side {
          background-color: ${state.layout.dice.color};
        } `;
      }
    }

    // If we have a dice game only with custom images, create the necessary styling
    if (state.general.type === DiceTypes.DICE && state.layout.dice?.type === 'custom') {
      style += `.site--desktop .dice-game__dice-side, .site--tablet .dice-game__dice-side {
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-box-shadow: none;
        box-shadow: none;
      }`;

      style += `.site--desktop .dice-game__dice-eye, .site--tablet .dice-game__dice-eye {\
                  display: none;\
                }`;
      for (diceIndex in state.layout.dice?.images) {
        if (state.layout.dice?.images) {
          style += `
          .site--desktop .dice-game__dice-side.dice-game__dice-side--${diceMap[Number(diceIndex)]},
          .site--tablet .dice-game__dice-side.dice-game__dice-side--${diceMap[Number(diceIndex)]}{
              background-image: url(${state.layout.dice?.images[Number(diceIndex)]});
          }
        `;

          style += `
          .site--mobile .dice-game__dice-side.dice-game__dice-side--${diceMap[Number(diceIndex)]} {
            background-image: url(${state.layout.dice?.images[Number(diceIndex)]});
          }
        `;
        }
      }

      style += `
        .site--mobile .dice-game__dice-side {
                  background-color: transparent;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                  -webkit-box-shadow: none;
                  box-shadow: none;
                }
      `;

      style += `
        .site--mobile .dice-game__dice-eye {
                  display: none;
                }
      `;
    }

    if (state.general.type === DiceTypes.CUP && state.layout.cup) {
      const filters: string[] = [];

      filters.push('hue-rotate(' + state.layout.cup.hue * 2 + 'deg)');
      filters.push('saturate(' + state.layout.cup.saturation * 2 + '%)');
      filters.push('brightness(' + state.layout.cup.brightness * 2 + '%)');
      filters.push('contrast(' + state.layout.cup.contrast * 2 + '%)');

      return `
      .dice-game__canvas {
        -webkit-filter:${filters.join(' ')};
        -moz-filter:${filters.join(' ')};
        filter: :${filters.join(' ')};
      }
      `;
    }
    return style;
  }

  private static constructCustomLabelsState(data: GameDiceCustomLabelData): GameDiceCustomLabelState {
    return {
      rollDice: data.roll_dice,
      triesLeft: data.tries_left
    };
  }

  private static parseLayoutDiceData(data: DeviceData<GameDiceDiceLayoutData>): GameDiceDiceState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return GameDiceModel.constructLayoutDiceState(useData);
    }
  }

  private static constructLayoutDiceState(data: GameDiceDiceLayoutData): GameDiceDiceState {
    const customImages: string[] = [];

    if (data.image && data.type === 'custom') {
      for (const image in data.image) {
        if (Object.hasOwnProperty.call(data.image, image)) {
          // eslint-disable-next-line security/detect-object-injection
          customImages.push(data.image[image]);
        }
      }
    }

    return {
      ...(data.type && { type: data.type }),
      ...(data.color && { color: data.color }),
      ...(data.eyes_color && { eyesColor: data.eyes_color }),
      images: customImages
    };
  }

  private static parseLayoutCupData(data: DeviceData<GameDiceCupLayoutData>): GameDiceCupState | undefined {
    const useData = getDeviceData(data);

    if (useData) {
      return GameDiceModel.constructLayoutCupState(useData);
    }

    return undefined;
  }

  private static constructLayoutCupState(data: GameDiceCupLayoutData): GameDiceCupState {
    return {
      hue: Number(data.hue),
      saturation: Number(data.saturation),
      brightness: Number(data.brightness),
      contrast: Number(data.contrast)
    };
  }

  private static constructGeneralState(data: GameDiceGeneralData): GameDiceGeneralState {
    // set default gametype to cup game
    // when creating a new dice game it dosn't have a default type from data
    // should be a cup game as default therefore we are just making sure to set it
    let gameType = DiceTypes.CUP;

    if (data.type === DiceTypes.DICE || data.type === DiceTypes.CUP) {
      gameType = data.type;
    }

    return {
      type: gameType,
      tries: Number(data.tries),
      ...(data.winner_chance && {
        winnerChance: parseInt(data.winner_chance, 10)
      }),
      delay: Number(data.delay),
      rollDiceTrigger: data.roll_dice_trigger,
      throwDice: data.throw_dice === '1',
      drawType: data.draw_type,
      ...(data.fields_occupied && {
        fieldsOccupied: data.fields_occupied
      })
    };
  }

  public hasInstantWin(): boolean {
    const state = this.state;
    return state.general.drawType === DrawType.AUTO;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'tries',
        metricKey: {
          tries_used: 'rolls',
          tries_left: 'tries_left'
        },
        icon: GameIndicatorIcon.TRIES,
        value: {
          tries_used: 0,
          tries_left: this.state.general.tries
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
