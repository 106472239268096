import type { Component } from 'vue';
import type { IntegrationCookieConsentData } from './Data';
import type { CookieConsentOptions } from './Types';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';
import View from '@/src/components/integrations/cookie-consent/View.vue';

export interface IntegrationCookieConsentState extends BaseIntegrationState {
  plugin?: CookieConsentOptions;
}

export class IntegrationCookieConsentModel extends BaseIntegration<
  IntegrationCookieConsentData,
  IntegrationCookieConsentState
> {
  parse(data: IntegrationCookieConsentData) {
    super.parse(data);
    this.state.plugin = data.settings?.plugin;
  }

  getVueComponent(): Promise<Component> {
    return Promise.resolve(View);
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Dannie Birk Hansen';
  }
}
