import type { FormElementCaptchaData } from '@/src/components/addons/registration/Models/FormElementCaptchaModel';
import { FormElementCaptchaModel } from '@/src/components/addons/registration/Models/FormElementCaptchaModel';
import type { FormElementCheckboxData } from '@/src/components/addons/registration/Models/FormElementCheckboxModel';
import { FormElementCheckboxModel } from '@/src/components/addons/registration/Models/FormElementCheckboxModel';
import type { FormElementCvrData } from '@/src/components/addons/registration/Models/FormElementCVRModel';
import { FormElementCVRModel } from '@/src/components/addons/registration/Models/FormElementCVRModel';
import type { FormElementDateData } from '@/src/components/addons/registration/Models/FormElementDateModel';
import { FormElementDateModel } from '@/src/components/addons/registration/Models/FormElementDateModel';
import type { FormElementDawaData } from '@/src/components/addons/registration/Models/FormElementDawaModel';
import { FormElementDawaModel } from '@/src/components/addons/registration/Models/FormElementDawaModel';
import type { FormElementDividerData } from '@/src/components/addons/registration/Models/FormElementDividerModel';
import { FormElementDividerModel } from '@/src/components/addons/registration/Models/FormElementDividerModel';
import type { FormElementFileData } from '@/src/components/addons/registration/Models/FormElementFileModel';
import { FormElementFileModel } from '@/src/components/addons/registration/Models/FormElementFileModel';
import type { FormElementMaskData } from '@/src/components/addons/registration/Models/FormElementMaskModel';
import { FormElementMaskModel } from '@/src/components/addons/registration/Models/FormElementMaskModel';
import type { FormElementRadioData } from '@/src/components/addons/registration/Models/FormElementRadioModel';
import { FormElementRadioModel } from '@/src/components/addons/registration/Models/FormElementRadioModel';
import type { FormElementRatingData } from '@/src/components/addons/registration/Models/FormElementRatingModel';
import { FormElementRatingModel } from '@/src/components/addons/registration/Models/FormElementRatingModel';
import type { FormElementSelectData } from '@/src/components/addons/registration/Models/FormElementSelectModel';
import { FormElementSelectModel } from '@/src/components/addons/registration/Models/FormElementSelectModel';
import type { FormElementSignatureData } from '@/src/components/addons/registration/Models/FormElementSignatureModel';
import { FormElementSignatureModel } from '@/src/components/addons/registration/Models/FormElementSignatureModel';
import type { FormElementSliderData } from '@/src/components/addons/registration/Models/FormElementSliderModel';
import { FormElementSliderModel } from '@/src/components/addons/registration/Models/FormElementSliderModel';
import type { FormElementTextareaData } from '@/src/components/addons/registration/Models/FormElementTextareaModel';
import { FormElementTextareaModel } from '@/src/components/addons/registration/Models/FormElementTextareaModel';
import type { FormElementTextData } from '@/src/components/addons/registration/Models/FormElementTextModel';
import { FormElementTextModel } from '@/src/components/addons/registration/Models/FormElementTextModel';
import type { FormElementParagraphData } from '@/src/components/addons/registration/Models/FormElementParagraphModel';
import { FormElementParagraphModel } from '@/src/components/addons/registration/Models/FormElementParagraphModel';
import { FormElementHiddenModel } from '@/src/components/addons/registration/Models/FormElementHiddenModel';
import { FormElementReCaptchaModel } from '@/src/components/addons/registration/Models/FormElementReCaptchaModel';
import type { CampaignMessageData } from '@/src/typings/interfaces/data/campaign';
import type { MetricData, ReplacementTags } from '@/src/store/campaign';

export type FormElementData =
  | FormElementCaptchaData
  | FormElementParagraphData
  | FormElementCheckboxData
  | FormElementCvrData
  | FormElementDateData
  | FormElementDawaData
  | FormElementDividerData
  | FormElementFileData
  | FormElementMaskData
  | FormElementRadioData
  | FormElementRatingData
  | FormElementSelectData
  | FormElementSignatureData
  | FormElementSliderData
  | FormElementTextareaData
  | FormElementTextData;

export type FormElementModel =
  | FormElementReCaptchaModel
  | FormElementCaptchaModel
  | FormElementCheckboxModel
  | FormElementCVRModel
  | FormElementDateModel
  | FormElementDawaModel
  | FormElementDividerModel
  | FormElementFileModel
  | FormElementMaskModel
  | FormElementRadioModel
  | FormElementRatingModel
  | FormElementSelectModel
  | FormElementSignatureModel
  | FormElementSliderModel
  | FormElementParagraphModel
  | FormElementTextareaModel
  | FormElementTextModel
  | FormElementHiddenModel;

export type FormElementModelType =
  | typeof FormElementCaptchaModel
  | typeof FormElementCheckboxModel
  | typeof FormElementCVRModel
  | typeof FormElementDateModel
  | typeof FormElementDawaModel
  | typeof FormElementDividerModel
  | typeof FormElementFileModel
  | typeof FormElementMaskModel
  | typeof FormElementRadioModel
  | typeof FormElementRatingModel
  | typeof FormElementSelectModel
  | typeof FormElementSignatureModel
  | typeof FormElementParagraphModel
  | typeof FormElementSliderModel
  | typeof FormElementTextareaModel
  | typeof FormElementTextModel
  | typeof FormElementReCaptchaModel
  | typeof FormElementHiddenModel;

export const FormElementMapping: Record<string, FormElementModelType> = {
  text: FormElementTextModel,
  email: FormElementTextModel,
  tel: FormElementTextModel,
  textarea: FormElementTextareaModel,
  checkbox: FormElementCheckboxModel,
  select: FormElementSelectModel,
  date: FormElementDateModel,
  radio: FormElementRadioModel,
  rating: FormElementRatingModel,
  signature: FormElementSignatureModel,
  cvr: FormElementCVRModel,
  divider: FormElementDividerModel,
  slider: FormElementSliderModel,
  dawa: FormElementDawaModel,
  file: FormElementFileModel,
  hidden: FormElementHiddenModel,
  mask: FormElementMaskModel,
  captcha: FormElementCaptchaModel,
  paragraph: FormElementParagraphModel,
  recaptcha: FormElementReCaptchaModel
};

export interface RegistrationApiResponse {
  object?: {
    id: string;
    token: string;
    prize_id?: number;
    winner?: boolean;
  };
  show_message: CampaignMessageData;
  token_expired: boolean;
  error?: boolean;
  message?: string;
  isError: boolean;
  replacementtags?: ReplacementTags;
  metrics?: MetricData;
  votes?: MetricData;
  goto_flow_page?: number;
  errors?: {
    [key: string]: string[];
  };
  leaderboard_update?: number[];
}

export interface FieldOptionItem {
  label: string;
  value: string;
  selected?: boolean;
}
