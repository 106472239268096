import type { AddonSponsorData, AddonSponsorsData } from '@/src/components/addons/sponsors/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { AlignContentType } from '@/src/typings/enums/enums';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';
import { SponsorsImageType } from '@/src/components/addons/sponsors/enums';
import { shuffle } from '@/src/utilities/Utilities';
import { formatUrl } from '@/src/utilities/Url';

export interface AddonSponsorState {
  companyName?: string;
  contactEmail?: string;
  contactName?: string;
  image?: string;
  www?: string;
  prizeDate?: Date;
}

interface AddonSponsorsSettingsState {
  imageToShow?: SponsorsImageType;
  image?: string;
  size?: string;
  placement?: AlignContentType;
  grid?: number;
  linkImage?: boolean;
  random?: boolean;
  todayFilter?: boolean;
  advanced?: AdvancedAddonsStyleData;
}

export interface AddonSponsorsState extends AddonModelState {
  settings: AddonSponsorsSettingsState;
  sponsors?: AddonSponsorState[];
  wrapperClasses: string[];
}

export class AddonSponsorsModel extends AddonModel<AddonSponsorsData, AddonSponsorsState, 'sponsors'> {
  parseAddon(data: AddonSponsorsData) {
    const state = this.state;

    state.settings = AddonSponsorsModel.constructSponsorsSettingsState(data);

    if (data.settings.sponsors) {
      state.sponsors = AddonSponsorsModel.parseSponsorsState(data);

      if (data.settings.random) {
        shuffle(state.sponsors);
      }
    }
    if (state.settings) {
      state.wrapperClasses = AddonSponsorsModel.constructSponsorsWrapperClasses(state.settings);
    }
  }

  private static parseSponsorsState(data: AddonSponsorsData): AddonSponsorState[] {
    if (data.settings.image_to_show === SponsorsImageType.IMAGE) {
      return data.settings.sponsors.map((sponsor) =>
        AddonSponsorsModel.constructSponsors(sponsor, SponsorsImageType.IMAGE)
      );
    } else {
      // make sure matches the default fallback is logo
      return data.settings.sponsors.map((sponsor) =>
        AddonSponsorsModel.constructSponsors(sponsor, SponsorsImageType.LOGO)
      );
    }
  }

  private static constructSponsors(sponsor: AddonSponsorData, type = SponsorsImageType.LOGO): AddonSponsorState {
    // eslint-disable-next-line security/detect-object-injection
    const imageChosen: string | undefined = sponsor[type] ?? undefined;

    return {
      ...(sponsor.company_name && {
        companyName: sponsor.company_name
      }),
      ...(sponsor.contact_email && {
        contactEmail: sponsor.contact_email
      }),
      ...(sponsor.contact_name && {
        contactName: sponsor.contact_name
      }),
      ...(!!imageChosen && { image: imageChosen }),
      ...(!!sponsor.www && { www: formatUrl(sponsor.www) }),
      prizeDate: new Date(Number(sponsor.prize_date) * 1000)
    };
  }

  private static constructSponsorsSettingsState(data: AddonSponsorsData): AddonSponsorsSettingsState {
    return {
      grid: Number(data.settings.grid) || 3,

      imageToShow: data.settings.image_to_show || SponsorsImageType.LOGO,
      linkImage: data.settings.link_image === '1' || true,

      placement: data.settings.placement || AlignContentType.CENTER,
      random: data.settings.random === '1' || false,
      todayFilter: data.settings.today_filter === '1' || false,
      ...(data.settings.size && { size: data.settings.size })
    };
  }

  private static constructSponsorsWrapperClasses(settings: AddonSponsorsSettingsState): string[] {
    return [
      'sponsors--placement-' + settings.placement,
      'sponsors--grid-' + settings.grid,
      'sponsors--image-to-show-' + settings.imageToShow
    ];
  }

  public isAddonValid(): boolean {
    if (this.getData().settings.sponsors.length) {
      return true;
    } else {
      return false;
    }
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
