import type { Component } from 'vue';
import type { IntegrationRitualsData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationRitualsState extends BaseIntegrationState {
  endpoint?: string;
}

export class IntegrationRitualsModel extends BaseIntegration<IntegrationRitualsData, IntegrationRitualsState> {
  parse(data: IntegrationRitualsData) {
    super.parse(data);
    this.state.endpoint = data.settings?.endpoint;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/rituals/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
