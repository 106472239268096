import type { FormElementModel } from '@/src/components/addons/registration/types';
import { watch } from 'vue';

export class SdkFormFieldModel {
  #model: FormElementModel;

  constructor(formField: FormElementModel) {
    this.#model = formField;
  }

  public get id(): number | string {
    const id = Number(this.#model.state.id);

    if (isNaN(id)) {
      return String(this.#model.state.id);
    }

    return id;
  }

  public get sdkIdentifier(): string | undefined {
    return this.#model.state.sdkIdentifier;
  }

  public get label(): string | undefined {
    return this.#model.state.label;
  }

  public get type(): string | undefined {
    return this.#model.state.inputHtmlType;
  }

  public get value(): string | undefined {
    return this.#model.getStringifiedValue();
  }

  public set value(value: string | undefined) {
    if (!['string', 'number', 'boolean'].includes((typeof value).toLowerCase())) {
      throw new TypeError(`[SDK] ${value} is not typeof string, number or boolean.`);
    }

    this.#model.state.value = this.#model.parseStringValue(value ?? '');
  }

  public set valid(value: boolean) {
    try {
      if (typeof value !== 'boolean') {
        throw new TypeError(`[SDK] ${value} is not typeof boolean.`);
      }
      this.#model.state.isValid = value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public get valid(): boolean {
    throw new Error('This functionality is not yet available');
  }

  public onChange(fn: (value: unknown) => void) {
    return watch(
      () => this.value,
      (newValue) => {
        fn(newValue);
      },
      { deep: true }
    );
  }

  public onChangeOnce(fn: (value: unknown) => void) {
    const stopHandler = this.onChange((value) => {
      if (stopHandler) {
        fn(value);
        stopHandler();
      }
    });

    return stopHandler;
  }
}
