import type { GameIndicatorData } from '@/src/components/indicators/Model';
import type { DrawType, GameActionType } from '@/src/typings/enums/enums';
import type { InstantWinData } from '@/src/models/GameModel';

// GameData that needs to be sent after game has ended
// use to secure if a user dont get the right prize
// then we can lookup the gameData and see what the user got.
export interface GameDataWof {
  calculation: number[];
  wheelComputed: Record<string, string>;
  wheelHeight: number;
  wheelWidth: number;
  fieldsOccupied: number[];
  fields: {
    [key: string]: GameWofFieldItemData;
  };
  spinsLeft: number;
  spinsUsed: number;
  winnerFieldId: number | undefined;
  winner: boolean | undefined; // winner only set if instant win is on else its undefined
  data: number | null; // the prize position for the current spin
  position: number;
}

// InstantWin data
export interface WofInstantWinData extends InstantWinData {
  winner_field?: string;
  winner_field_id?: string;
}

export enum GameWofSpinButtonType {
  USE_BUTTON = 'use_button',
  USE_CLICK = 'use_click',
  AUTO = 'auto'
}

export enum GameWofSpinButtonPositionType {
  BELOW = 'below',
  ABOVE = 'above'
}

export enum GameWofMarkerType {
  IMAGE = 'image',
  ICON = 'icon'
}

export enum GameWofCampaignLimit {
  CAMPAIGN_PERIOD = 'CAMPAIGN_PERIOD',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum GameWofMarkerSize {
  HUGE = 'huge',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  XSMALL = 'x-small'
}

export interface GameWofFieldItemData {
  action: GameActionType;
  field_label?: string;
}

export interface GameWofMobileMarkerData {
  marker_type: GameWofMarkerType;
  marker_image: string;
  marker_color?: string;
  marker_size?: GameWofMarkerSize;
  marker_position?: string;
  marker_animation: string;
}

export interface GameWofVisualData {
  border_color: string;
  border_thickness: string;
  color1: string;
  color2: string;
  color3: string;
  divider: string;
  font_color: string;
  font_size: string;
  font_type: string;
  wheel_size: string;
}

export enum GameWofWheelDisplayType {
  COLOR = 'colors',
  IMAGE = 'image'
}

export interface GameWofGeneralData {
  image: string;
  image_alt_text?: string;
  num_fields: string;
  spins: string;
  delay: string;
  auto_delay: string;
  spin_button: GameWofSpinButtonType;
  spin_button_position: GameWofSpinButtonPositionType;
  marker_type: GameWofMarkerType;
  marker_image: string;
  marker_image_alt_text?: string;
  marker_position?: string;
  marker_animation: string;
  marker_color?: string;
  mobile?: GameWofMobileMarkerData;
  marker_size?: GameWofMarkerSize;
  overwrite_mobile_marker: string;
  draw_type: DrawType;
  fields: {
    [key: string]: GameWofFieldItemData;
  };
  fields_occupied: number[] | { [key: string]: string }; // In situations we have identified data has been served as an object, but most cases its normally number array
  campaign_limit_interval?: GameWofCampaignLimit;
  campaign_limit?: string;
  visuals?: GameWofVisualData;
  wheel_display: GameWofWheelDisplayType;
}

export interface GameWofCustomLabelsData {
  spin_button: string;
  spins_left: string;
}

export interface GameWofAdvancedData {
  sound?: string;
}

export interface GameWofData {
  general: GameWofGeneralData;
  customlabels?: GameWofCustomLabelsData;
  indicators: GameIndicatorData;
  advanced?: GameWofAdvancedData;
}
