export interface SDKReplacementTags {
  name: string;
  value: string | undefined;
}

export class SdkReplacementTagModel {
  #name: string;
  #value: string | undefined;

  constructor(name: string, value: string | number) {
    this.#name = name;

    if (typeof value === 'string' || typeof value === 'number') {
      this.#value = String(value);
    }
  }

  public get tag(): SDKReplacementTags {
    return {
      name: this.#name,
      value: this.#value
    };
  }
}
