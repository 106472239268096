import { FormDisableDefaultBehavior } from '@/src/exceptions/FormDisableDefaultBehavior';
import { events } from '@/src/services/events';
import type { SectionModelType } from '@/src/typings/types/types';

class CustomEventPoly<T> extends Event {
  detail: T;
  constructor(message: string, data: EventInit & { detail: T }) {
    super(message, data);
    this.detail = data.detail;
  }
}

export class FormSubmitEvent extends CustomEventPoly<SectionModelType> {
  #promises: Promise<void>[] = [];

  public suspense(fn: () => Promise<void>) {
    this.#promises.push(fn());
  }

  public async waitForPromises() {
    await Promise.all(this.#promises);
  }
}

export const EmitFormSubmitEvent = async (pageModel: SectionModelType): Promise<FormSubmitEvent> => {
  const event = new FormSubmitEvent('submitForm', {
    detail: pageModel,
    cancelable: true
  });

  events.emit('formSubmit', event);

  await event.waitForPromises();

  if (event.defaultPrevented) {
    throw new FormDisableDefaultBehavior(
      'Prevent default behavior for form submit, was prevented in formSubmit SDK event'
    );
  }

  return event;
};
