import { ContentType } from '@/src/components/layout/section/SectionBaseModel';
import { goToFictiveFlowPage } from '@/src/utilities/Flow';
import { SdkFlowPageModel } from '@/src/sdk/models/flowPage';
import { useCampaignStore } from '@/src/store/campaign';

export class SdkFlowpageApi {
  public static showMessage(title: string, description: string) {
    goToFictiveFlowPage(title, {
      type: ContentType.TEXT,

      content: description
    });
  }

  public static async getAll(): Promise<SdkFlowPageModel[]> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    return (
      campaignStore.model?.state.flowPages.map((flowPage) => {
        return new SdkFlowPageModel(flowPage);
      }) ?? []
    );
  }

  public static async getActive(): Promise<SdkFlowPageModel | undefined> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    // Not sure yet of the implementation around fictive flow pages.
    // Because of that, return undefined for now.
    if (campaignStore.fictiveFlowPage) {
      return undefined;
    }

    const flowPage = campaignStore.flowModel;

    if (flowPage) {
      return new SdkFlowPageModel(flowPage);
    }

    return undefined;
  }

  public static async makeActive(id: number): Promise<void> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    const flowPage = campaignStore.model?.state.flowPages.find((flowPage) => flowPage.state.id === id);

    if (!flowPage) {
      throw new Error('Unrecognized flow page id. Not found in collection');
    }

    if (
      flowPage.state.config.settings.state.advanced.visibilityCondition &&
      !flowPage.state.config.settings.state.advanced.visibilityCondition?.check()
    ) {
      throw new Error('Cannot go to flow page that user is not allowed to see (visibility conditions)');
    }

    const gameFlowPage = campaignStore.model?.state.flowPages.find(
      (flowPage) => flowPage.getAddons('gameplay').length > 0
    );

    const isInstantWin =
      campaignStore.model?.getGamePlayModel()?.state.settings?.game?.hasInstantWin() &&
      campaignStore.model?.getGamePlayModel()?.state.settings?.game?.state.winner !== undefined;

    const gameFlowPageIndex = gameFlowPage ? campaignStore.model?.state.flowPages.indexOf(gameFlowPage) : undefined;

    if (gameFlowPageIndex !== undefined && isInstantWin) {
      const flowPageIndex = campaignStore.model?.state.flowPages.indexOf(flowPage);

      if (flowPageIndex !== undefined && flowPageIndex < gameFlowPageIndex && campaignStore.gameEnded) {
        throw new Error('Cannot go to flow page that is before the game flow page when game has ended');
      }

      if (flowPageIndex !== undefined && flowPageIndex === gameFlowPageIndex && campaignStore.gameEnded) {
        throw new Error('Cannot go to flow page that is the game flow page when game has ended');
      }
    }

    campaignStore.flowId = id;
    campaignStore.fictiveFlowPage = undefined;
  }
}
