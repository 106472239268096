import { SdkFormFieldModel } from '../formField';
import { SdkBaseAddonModel } from '.';
import type { AddonTipAFriendModel } from '@/src/components/addons/tipafriend/Model';

export class SdkAddonTipAFriend extends SdkBaseAddonModel {
  #model: AddonTipAFriendModel;

  constructor(model: AddonTipAFriendModel) {
    super(model);
    this.#model = model;
  }

  public get formFields(): SdkFormFieldModel[] {
    if (this.#model.state.fields) {
      return this.#model.state.fields.map((field) => new SdkFormFieldModel(field)) ?? [];
    }

    return [];
  }
}
