import type { Component } from 'vue';
import type { IntegrationCodanData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationCodanState extends BaseIntegrationState {
  adobeTracking: boolean;
}

export class IntegrationCodanModel extends BaseIntegration<IntegrationCodanData, IntegrationCodanState> {
  parse(data: IntegrationCodanData) {
    super.parse(data);
    this.state.adobeTracking = !!data.settings?.tracking?.adobe?.enabled;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/codan/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
