<template>
  <svg class="game-indicator-item__icon-path" width="46px" height="40px" viewBox="0 0 46 40">
    <defs>
      <polygon id="path-1" points="0 -9.51278416e-05 45.5757917 -9.51278416e-05 45.5757917 39.0616 0 39.0616" />
    </defs>

    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-1" />
      </mask>

      <path
        id="Fill-1"
        d="M10.1185,37.5336 C10.0245,37.5786 9.9415,37.5596 9.8995,37.5456 C9.8585,37.5306 9.7815,37.4926 9.7365,37.3996 L1.5295,20.3446 C1.4845,20.2516 1.5025,20.1676 1.5165,20.1256 C1.5315,20.0836 1.5705,20.0076 1.6625,19.9626 L18.7185,11.7546 C18.7635,11.7336 18.8055,11.7256 18.8415,11.7256 C18.8825,11.7256 18.9155,11.7346 18.9375,11.7416 C18.9785,11.7566 19.0555,11.7956 19.1005,11.8876 L21.6305,17.1466 L18.1515,18.8206 C17.3705,19.1966 17.0415,20.1366 17.4175,20.9166 L22.5375,31.5566 L10.1185,37.5336 Z M45.4195,28.3716 L37.2415,11.3766 C36.8665,10.5976 35.9275,10.2666 35.1455,10.6426 L22.9825,16.4956 L20.4515,11.2376 C20.2455,10.8076 19.8835,10.4846 19.4325,10.3266 C18.9835,10.1686 18.4985,10.1956 18.0685,10.4026 L6.3195,16.0566 C4.5885,10.4476 7.4845,4.3626 13.0465,2.2396 C18.4405,0.1796 24.4525,2.5656 27.0445,7.5786 L23.7855,6.6226 C23.3885,6.5066 22.9715,6.7336 22.8545,7.1306 C22.7375,7.5286 22.9655,7.9456 23.3625,8.0616 L28.1605,9.4706 C28.2315,9.4906 28.3025,9.5006 28.3725,9.5006 C28.6965,9.5006 28.9955,9.2886 29.0915,8.9616 L30.4995,4.1646 C30.6165,3.7666 30.3895,3.3496 29.9915,3.2336 C29.5945,3.1176 29.1775,3.3446 29.0605,3.7416 L28.2185,6.6116 C25.2025,1.1246 18.5195,-1.4574 12.5115,0.8376 C6.1365,3.2716 2.8535,10.2966 4.9565,16.7126 L1.0125,18.6106 C0.5825,18.8176 0.2595,19.1796 0.1015,19.6296 C-0.0565,20.0796 -0.0295,20.5646 0.1775,20.9946 L8.3855,38.0506 C8.6925,38.6886 9.3315,39.0616 9.9975,39.0616 C10.2565,39.0616 10.5195,39.0056 10.7685,38.8856 L23.1875,32.9086 L25.5955,37.9116 C25.8665,38.4716 26.4285,38.8006 27.0135,38.8006 C27.2415,38.8006 27.4725,38.7506 27.6915,38.6446 L44.6855,30.4666 C45.0635,30.2846 45.3475,29.9676 45.4865,29.5706 C45.6255,29.1756 45.6015,28.7496 45.4195,28.3716 L45.4195,28.3716 Z"
        fill="#FFFFFF"
        mask="url(#mask-2)"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MovesIndicator'
});
</script>
