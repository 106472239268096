import type { VisibilityConditionsData } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import type {
  AlignContentType,
  BackgroundType,
  BorderType,
  DividerTypes,
  GradientPositionType,
  GradientType,
  OverlayType,
  PositionType,
  VideoFormatType,
  TextTransformType,
  TextStyleType,
  TextDecorationType,
  SliderTypes,
  ContentPositionType,
  HorizontalPositionType,
  HeightType,
  RepeatType,
  AdvancedWidthType,
  AdvancedPositionType,
  AdvancedHorizontalAlignmentType,
  AdvancedVerticalAlignmentType,
  DividerPositionType,
  GapType,
  ActionType
} from '@/src/typings/enums/enums';
import type { ActionData } from '@/src/typings/interfaces/data/settings/actions';
import type { TransitionTypes } from '@/src/typings/types/types';
import type { DeviceData } from '@/src/hooks/useDevice';

export interface HeightData {
  width_type: 'grid' | 'full_width';
  width: string;
  gap: GapType;
  type: HeightType;
  subtract_window?: `${number}px | ${number}%`;
  fixed: string;
  minimum: string;
  content_position: ContentPositionType;

  // Popup specific properties on the height data.
  overlay?: string;
  overlay_bgcolor?: string;
}

export interface LayoutData {
  width?: string;
  alignment?: AlignContentType;
  content_position?: ContentPositionType;
  horizontal_position?: HorizontalPositionType;
}

export interface BackgroundData {
  background_type: BackgroundType;
  color: string;

  video?: {
    type?: VideoFormatType;
    url: string;
    disable_looping: string;
    opacity: string;
    fallback_image: string;
  };

  image?: {
    src: string;
    behavior: 'tile' | 'actual_size' | 'stretch' | 'contain' | 'cover' | 'center';
    tile_repeat: RepeatType;
    position: PositionType;
    x_position: string;
    y_position: string;
    opacity: string;
  };

  slider?: {
    images: Array<string>;
    interval: {
      time: string;
    };

    transition: SliderTypes;
    transition_time: string;
    shuffle: string;
  };

  background_to_section: string;
}

export interface OverlayData {
  overlay_to_section?: '0' | '1';
  overlay_type: OverlayType;
  overlay_color: string;
  location_1?: string | number;
  color_2: string;
  location_2?: string | number;
  gradient_type: GradientType;
  gradient_angle: string;
  gradient_position: GradientPositionType;
}

export interface BoxShadowData {
  color: string;
  blur: string;
  spread: string;
  horizontal: string;
  vertical: string;
}

export interface TextShadowData {
  color: string;
  blur: string;
  horizontal: string;
  vertical: string;
}

export interface ContentColorData {
  use_default_text_color: string;
  color: string;
}

export interface DividerData {
  enabled: string;
  position: DividerPositionType;
  type: DividerTypes;
  color: string;
  width: string;
  height: string;
  flip: string;
  invert: string;
  front: string;
}

export interface BorderData {
  type: BorderType | '';
  color?: string;
  width?: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  };
}

export interface AdvancedStyleData {
  margin?: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  };
  padding?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  width?: {
    type: AdvancedWidthType;
    custom?: string;
  };
  min_height?: string;
  horisontal_alignment?: string;
  customcss: DeviceData<CustomCSS>;
}

export interface TransitionsData {
  enter?: TransitionTypes;
  enter_duration?: string;
  enter_delay?: string;
  leave?: TransitionTypes;
  leave_duration?: string;
  leave_delay?: string;
}

//* ************ Exports ************///

export interface PositionData {
  position: AdvancedPositionType;
  horizontal?: {
    offset: string;
    alignment?: AdvancedHorizontalAlignmentType;
  };
  vertical?: {
    offset: string;
    alignment?: AdvancedVerticalAlignmentType;
  };
}

export interface CustomCSS {
  code: string;
}

export interface GameOverlayData {
  page: string;
}

export interface AdvancedElementData {
  zindex: string;
  element_id: string;
  classname: string;
  game_overlay: DeviceData<GameOverlayData>;
}

export interface SettingsData<Advanced extends SettingsAdvancedData = SettingsAdvancedData> {
  basic?: SettingsBasicData;
  style?: SettingsStyleData;
  advanced?: Advanced;
  show_on_desktop?: string;
  show_on_mobile?: string;
}

export enum PopupPositionType {
  TOP_LEFT = 'top_left',
  TOP_CENTER = 'top_center',
  TOP_RIGHT = 'top_right',
  CENTER_CENTER = 'center_center',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_CENTER = 'bottom_center',
  BOTTOM_RIGHT = 'bottom_right'
}

export interface PopupPositionData {
  type: PopupPositionType;
  x: number | string;
  y: number | string;
}

export interface SettingsBasicData {
  height?: DeviceData<HeightData>;
  name?: string;
  layout?: DeviceData<LayoutData>;
  action?: ActionData;
  position?: DeviceData<PopupPositionData>;
  lock_grid?: `${number}`;
}

export interface SettingsStyleData {
  background?: DeviceData<BackgroundData>;
  overlay?: DeviceData<OverlayData>;
  border?: DeviceData<BorderData>;
  shadow?: DeviceData<BoxShadowData>;
  content?: DeviceData<ContentColorData>;
  divider?: DeviceData<DividerData>;
}

export interface SettingsAdvancedData {
  style?: DeviceData<AdvancedStyleData>;
  advanced?: AdvancedElementData;
  transitions?: DeviceData<TransitionsData>;
  redirect?: {
    delay: string;
    enabled: string;
    target: string;
    url: string;
    type: ActionType;
  };
  visibility_condition?: VisibilityConditionsData;
  customcss?: DeviceData<CustomCSS>;
  show_on_desktop?: string;
  show_on_tablet?: string;
  show_on_mobile?: string;
}

export interface TypographyData {
  alignment?: AlignContentType;
  color?: string;
  font_family?: string;
  font_weight?: string;
  gradient_1?: string;
  gradient_2?: string;
  letter_spacing?: string;
  line_height?: string;
  size?: string;
  shape_color?: string;
  text_decoration?: TextDecorationType;
  rotate_text_color?: string;
  text_style?: TextStyleType;
  transform?: TextTransformType;
}

export interface AdvancedAddonsStyleData {
  generic?: DeviceData<AdvancedStyleData> & {
    customcss?: DeviceData<CustomCSS>;
  };
  advanced?: AdvancedElementData;
  position?: DeviceData<PositionData>;
  transitions?: DeviceData<TransitionsData>;
  visibility_condition?: VisibilityConditionsData;

  devices?: {
    desktop?: `${number}`;
    mobile?: `${number}`;
  };
}
