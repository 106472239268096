<template>
  <div class="content__item-form-element content__item-form-element--hidden col-12">
    <input
      v-model="state.value"
      :class="`content__item-form-${model.state.id} content__item-form-hidden form-control`"
      :name="`form[${model.state.id}]`"
      type="hidden"
      autocomplete="off"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { FormElementHiddenModel } from '@/src/components/addons/registration/Models/FormElementHiddenModel';

export default defineComponent({
  name: 'FormElementHidden',
  props: {
    model: {
      type: Object as PropType<FormElementHiddenModel>,
      required: true
    }
  },
  setup(props) {
    const model = props.model;
    const state = model.state;

    return {
      state
    };
  }
});
</script>
