import type { GameScratchcardSdkSettings } from './sdk/exports';
import type {
  GameScratchAdvancedData,
  GameScratchCardData,
  GameScratchCardPoolData,
  GameScratchGeneralData,
  GameScratchImagePoolData,
  GameScratchLayoutData
} from '@/src/components/games/scratchcard/Data';
import type { GameIndicator, HasSound, InstantWinData } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { DrawType } from '@/src/typings/enums/enums';
import { CampaignAdsSizeType, CampaignDeviceType } from '@/src/typings/enums/enums';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import type { MetricData } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';

export interface GameScratchCardState {
  general: GameScratchCardGeneralState;
  layout: GameScratchLayoutState;
  advanced: GameScratchCardAdvancedState;
  pool: GameScratchImagePoolState[];
  hasScratchTool: boolean;
  fields: GameScratchFieldsState[];
  winner: boolean;
  winnerImageId: string;
  winnerImage: string;
}

export interface GameScratchCardAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GameScratchCardAdvancedState {
  sound: GameScratchCardAdvancedSoundState;
}

export type GameScratchCardLoserImage = Omit<GameScratchImagePoolState, 'id'>;

interface GameScratchCardGeneralState {
  drawType: DrawType;
  scratchfieldAmount: number;
  imagePoolAmount: number;
  scratchAreaSize?: number;
  delay: number;
  winnerPercentage: number;
  winnerImage?: number | string; // number or string because it can contain 'all' as string
  scratchToolImage: string;
  loserImage?: GameScratchCardLoserImage;
}

interface GameScratchLayoutState {
  scratchcardImage: string;
  scratchcardImageAltText?: string;
  coinImage?: string;
  coinImageAltText?: string;
  coinOffsetX?: string;
  coinOffsetY?: string;
}

export interface GameScratch {
  x: number;
  y: number;
}

export interface GameScratchFieldsState {
  id: number;
  x: number;
  y: number;
  w: number;
  h: number;
  origW: number;
  origH: number;
}

export interface GameScratchImagePoolState {
  id: number;
  image: string;
  altText?: string;
}

export interface GameScratchInstantWin extends InstantWinData {
  winner_image?: string;
  winner_image_id?: string;
}

export class GameScratchCardModel extends GameModel<GameScratchCardData, GameScratchCardState> implements HasSound {
  parseGame(data: GameScratchCardData) {
    const state = this.state;

    state.general = GameScratchCardModel.constructGeneralState(data.general);

    state.layout = GameScratchCardModel.parseLayoutState(data);

    state.pool = GameScratchCardModel.parseImagePoolState(data.pool);

    state.hasScratchTool = useDevice().isDesktop && !!state.layout.coinImage;

    state.fields = GameScratchCardModel.parseScratchFieldsState(data);

    state.advanced = state.advanced ?? {};

    state.advanced.sound = GameScratchCardModel.constructAdvancedSoundState(data?.advanced);
  }

  private static parseScratchFieldsState(data: GameScratchCardData): GameScratchFieldsState[] {
    const layoutData = getDeviceData<GameScratchLayoutData>(data.layout.layout);

    if (!layoutData) {
      return [];
    }

    const campaignStore = useCampaignStore();

    // Scratchcard is a bit special when it comes to ads.
    // We want it to indicate that scratch tool is only for desktop
    // which is why it saves the settings there.
    //
    // However, there is no desktop device data on ads, causing getDeviceData to prioritize
    // the desktop index when it is found over "default".
    // So to support fields placement and desktop scratch tool, we need to merge them on ads.
    if (
      campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
      campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.FIXED &&
      data.layout.layout.default
    ) {
      return GameScratchCardModel.constructScratchFieldsState({ ...layoutData, ...data.layout.layout.default });
    }

    return GameScratchCardModel.constructScratchFieldsState(layoutData);
  }

  private static constructScratchFieldsState(data: GameScratchLayoutData): GameScratchFieldsState[] {
    return data.place.map<GameScratchFieldsState>((place) => {
      return {
        id: Number(place.id),
        x: Number(place.x),
        y: Number(place.y),
        w: Number(place.w),
        h: Number(place.h),
        origW: Number(place.w),
        origH: Number(place.h)
      };
    });
  }

  private static parseImagePoolState(poolData: GameScratchCardPoolData): GameScratchImagePoolState[] {
    const device = useDevice();

    // Temporarily store parsed data in an object with keys as ids
    const parsedPool: Record<number, { id: number; image: string; altText: string }> = {};

    // Helper function to process either desktop or mobile pool
    const processPool = (pool: GameScratchImagePoolData) => {
      Object.keys(pool).forEach((key) => {
        if (key.endsWith('_alt_text')) {
          return; // Skip alt text keys, these are handled along with images
        }

        const id = Number(key);
        const image = pool[Number(id)]?.toString(); // Ensure the image is a string
        const altText = pool[`${Number(key)}_alt_text`] ?? ''; // Fallback to empty string if alt text is missing

        parsedPool[Number(id)] = { id, image, altText }; // Include id in the object
      });
    };

    // Process desktop pool
    processPool(poolData.pool);

    // If mobile, overwrite desktop pool images with mobile-specific data if available
    if (device.isMobile && poolData.pool_mobile) {
      processPool(poolData.pool_mobile);
    }

    // Convert the parsedPool object into an array
    return Object.values(parsedPool);
  }

  private static constructGeneralState(data: GameScratchGeneralData): GameScratchCardGeneralState {
    // default delay 1750ms
    const delay = Number(data.delay ? Number(data.delay) : 1.75) * 1000;

    const device = useDevice();

    let loserImage = data.loser_image;
    let loserAltText = data.loser_image_alt_text;

    if (device.isMobile && data.loser_image_mobile) {
      loserImage = data.loser_image_mobile;
      loserAltText = data.loser_image_mobile_alt_text ?? data.loser_image_alt_text;
    }

    if (data.loser_image_overwrite === '1') {
      loserImage = data.loser_image;
      loserAltText = data.loser_image_alt_text; // Or provide a specific overwrite alt text if needed
    }

    return {
      delay,
      scratchfieldAmount: Number(data.scratchfield_amount) || 3,
      imagePoolAmount: Number(data.image_pool_amount) || 4,
      drawType: data.draw_type,
      scratchAreaSize: Number(data.scratch_area_size) || 60,
      scratchToolImage: data.scratch_tool_image,
      winnerImage: data.winner_image,
      winnerPercentage: Number(data.winner_percentage) || 60,

      ...(loserImage && {
        loserImage: {
          image: loserImage,
          altText: loserAltText ?? ''
        }
      })
    };
  }

  private static parseLayoutState(data: GameScratchCardData): GameScratchLayoutState {
    const layoutData = getDeviceData<GameScratchLayoutData>(data.layout.layout);

    // Support ads where data is stores in a mix of default and desktop. Desktop for coin settings
    // and default for all else.
    if (data.layout.layout.desktop && data.layout.layout.default) {
      return GameScratchCardModel.constructLayoutState({
        ...(data.layout.layout.desktop && { ...data.layout.layout.desktop }),

        scratchcard_image: data.layout.layout.default?.scratchcard_image,
        scratchcard_image_alt_text: data.layout.layout.default?.scratchcard_image_alt_text,
        place: data.layout.layout.default?.place
      });
    }

    if (layoutData) {
      return GameScratchCardModel.constructLayoutState(layoutData);
    }

    throw new Error('Game assets not available');
  }

  private static constructLayoutState(data: GameScratchLayoutData): GameScratchLayoutState {
    return {
      scratchcardImage: data.scratchcard_image,
      scratchcardImageAltText: data.scratchcard_image_alt_text,
      ...(data.coin_image && { coinImage: data.coin_image }),
      ...(data.coin_offset_x && { coinOffsetX: data.coin_offset_x }),
      ...(data.coin_offset_y && { coinOffsetY: data.coin_offset_y }),
      ...(data.coin_image_alt_text && { coinImageAltText: data.coin_image_alt_text })
    };
  }

  private static constructAdvancedSoundState(
    data: GameScratchAdvancedData | undefined
  ): GameScratchCardAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  public setInstantWinnerData(data: GameScratchInstantWin) {
    super.setInstantWinnerData(data);

    const winnerImageId = data.winner_image_id;
    const winnerImage = data.winner_image;

    if (winnerImageId) {
      this.state.winnerImageId = String(winnerImageId);
    }

    if (winnerImage) {
      this.state.winnerImage = winnerImage;
    }
  }

  public hasInstantWin(): boolean {
    // we always need to call the auth api, instantWin condition uses. and returns if the player is a winner, on % chance aswell.
    return true;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }

  public get sdkMetrics(): MetricData | undefined {
    const campaignStore = useCampaignStore();

    if (Object.keys(campaignStore.metricData).length === 0) {
      return undefined;
    }

    const exposedMetrics: MetricData = {};
    const metricData: MetricData = { ...campaignStore.metricData };

    for (const metricKey in metricData) {
      if (Object.prototype.hasOwnProperty.call(metricData, metricKey) && metricKey.startsWith('scratch_field_')) {
        const fieldImage = metricData[String(metricKey)];
        const poolItem = this.state.pool.find((pool) => pool.image === fieldImage);

        if (poolItem) {
          exposedMetrics[String(metricKey)] = String(poolItem.id);
        }
      }
    }

    return exposedMetrics;
  }

  public get sdkSettings(): GameScratchcardSdkSettings {
    return {
      layout: {
        scratchcardImage: this.state.layout.scratchcardImage
      },
      pool: this.state.pool.map((pool) => {
        return {
          id: String(pool.id),
          image: pool.image
        };
      })
    };
  }
}
