import { EmailableValidationModel } from './Models/Emailable';
// import { EmaillistverifyValidationModel } from './Models/Emaillistverify';
// import { ZeroBounceValidationModel } from './Models/ZeroBounce';

export type EmailValidationType = typeof EmailableValidationModel;

export type EmailValidationModel = EmailableValidationModel;

export const EmailValidationMap: Record<string, EmailValidationType> = {
  emailable: EmailableValidationModel
};
