import type { CSSProperties } from 'vue';
import { InlineHeightEnum } from '../typing/enums';
import type { InlineElement } from '../typing/interfaces';
import {
  CampaignAdsSizeType,
  CampaignDeviceType,
  ContentPositionType,
  HorizontalPositionType
} from '@/src/typings/enums/enums';
import { useCampaignStore } from '@/src/store/campaign';
import { getDeviceHeight } from '@/src/hooks/useDevice';

export default function useLayoutSettings() {
  const applyInlineStyles = (settings?: InlineElement) => {
    const campaignStore = useCampaignStore();
    const inlineStylesOutput: CSSProperties = {};

    const inlineElementStyles = settings;

    if (inlineElementStyles?.color) {
      inlineStylesOutput.color = inlineElementStyles?.color;
    }

    if (inlineElementStyles?.padding) {
      if (inlineElementStyles?.padding.bottom || inlineElementStyles?.padding.bottom === 0) {
        inlineStylesOutput.paddingBottom = `${inlineElementStyles?.padding.bottom}px`;
      }
      if (inlineElementStyles?.padding.top || inlineElementStyles?.padding.top === 0) {
        inlineStylesOutput.paddingTop = `${inlineElementStyles?.padding.top}px`;
      }

      if (typeof inlineElementStyles?.padding.left === 'number') {
        inlineStylesOutput.paddingLeft = `${inlineElementStyles?.padding.left}px`;
      }

      if (typeof inlineElementStyles?.padding.right === 'number') {
        inlineStylesOutput.paddingRight = `${inlineElementStyles?.padding.right}px`;
      }
    }
    if (inlineElementStyles?.zIndex) {
      inlineStylesOutput.zIndex = inlineElementStyles.zIndex;
    }
    if (inlineElementStyles?.minHeight) {
      inlineStylesOutput.minHeight = `${inlineElementStyles?.minHeight}px`;
    }

    if (inlineElementStyles?.border) {
      if (inlineElementStyles?.border.type) {
        inlineStylesOutput.borderStyle = inlineElementStyles?.border.type;
      }
      if (inlineElementStyles?.border.color) {
        inlineStylesOutput.borderColor = inlineElementStyles?.border.color;
      }
      if (inlineElementStyles?.border.width) {
        inlineStylesOutput.borderRightWidth = `${inlineElementStyles?.border?.width?.right || 0}px`;
        inlineStylesOutput.borderLeftWidth = `${inlineElementStyles?.border?.width?.left || 0}px`;
        inlineStylesOutput.borderTopWidth = `${inlineElementStyles?.border?.width?.top || 0}px`;
        inlineStylesOutput.borderBottomWidth = `${inlineElementStyles?.border?.width?.bottom || 0}px`;
      }
    }

    if (inlineElementStyles?.height) {
      switch (inlineElementStyles?.height.type) {
        case InlineHeightEnum.FIXED:
          inlineStylesOutput.height = `${inlineElementStyles?.height.value}px`;

          if (
            campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
            campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.FIXED
          ) {
            inlineStylesOutput.overflow = 'hidden';
          } else {
            inlineStylesOutput.overflowY = 'auto';
            inlineStylesOutput.overflowX = 'hidden';
          }
          break;

        case InlineHeightEnum.WINDOW:
          const subtractWindowValue = inlineElementStyles?.height.subtractWindow;

          if (subtractWindowValue) {
            const subtractWindowStr = subtractWindowValue.toString();

            if (inlineElementStyles?.height?.subtractFormat && inlineElementStyles?.height.subtractFormat === '%') {
              const percentageValue = parseFloat(subtractWindowStr);
              inlineStylesOutput.height = `calc(${getDeviceHeight()}px - (${getDeviceHeight()}px * ${
                percentageValue / 100
              }))`;
            } else {
              const pixelValue = parseFloat(subtractWindowStr);
              inlineStylesOutput.height = `calc(${getDeviceHeight()}px - ${pixelValue}px)`;
            }
          } else {
            inlineStylesOutput.height = `${getDeviceHeight()}px`;
          }

          if (
            campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
            campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.FIXED
          ) {
            inlineStylesOutput.overflow = 'hidden';
          } else {
            inlineStylesOutput.overflowY = 'auto';
            inlineStylesOutput.overflowX = 'hidden';
          }
          break;

        case InlineHeightEnum.FLUID:
          inlineStylesOutput.minHeight = `${inlineElementStyles?.height.value}px`;
          break;
      }
    }

    if (inlineElementStyles?.overflowX) {
      inlineStylesOutput.overflowX = inlineElementStyles?.overflowX;
    }

    if (inlineElementStyles?.overflowY) {
      inlineStylesOutput.overflowY = inlineElementStyles?.overflowY;
    }

    if (inlineElementStyles?.margin) {
      if (inlineElementStyles.margin.left !== 'auto') {
        inlineStylesOutput.marginLeft = `${inlineElementStyles.margin.left}px`;
      }
      if (inlineElementStyles.margin.right !== 'auto') {
        inlineStylesOutput.marginRight = `${inlineElementStyles.margin.right}px`;
      }
      if (inlineElementStyles.margin.top || inlineElementStyles.margin.top === 0) {
        inlineStylesOutput.marginTop = `${inlineElementStyles.margin.top}px`;
      }
      if (inlineElementStyles.margin.bottom || inlineElementStyles.margin.bottom === 0) {
        inlineStylesOutput.marginBottom = `${inlineElementStyles.margin.bottom}px`;
      }
    }

    if (inlineElementStyles?.shadow && inlineElementStyles?.shadow?.color) {
      inlineStylesOutput.boxShadow = `${inlineElementStyles?.shadow?.horizontal ?? 0}px ${
        inlineElementStyles?.shadow?.vertical ?? 0
      }px ${inlineElementStyles?.shadow?.blur ?? 0}px ${inlineElementStyles?.shadow?.spread ?? 0}px ${
        inlineElementStyles?.shadow?.color
      }`;
    }

    if (inlineElementStyles?.horizontalPosition === HorizontalPositionType.RIGHT) {
      inlineStylesOutput.alignItems = 'flex-end';
    } else if (inlineElementStyles?.horizontalPosition === HorizontalPositionType.CENTER) {
      inlineStylesOutput.alignItems = 'center';
    }
    if (inlineElementStyles?.verticalPosition === ContentPositionType.BOTTOM) {
      inlineStylesOutput.justifyContent = 'flex-end';
    } else if (inlineElementStyles?.verticalPosition === ContentPositionType.CENTER) {
      inlineStylesOutput.justifyContent = 'center';
    }

    return inlineStylesOutput;
  };

  return {
    applyInlineStyles
  };
}
