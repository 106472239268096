import type { IntegrationRaptorSmartAdvisorData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationRaptorSmartAdvisorState extends BaseIntegrationState {
  apiKey?: string;
  customerId?: number;
  cookieId?: string;
}

export class IntegrationRaptorSmartAdvisorModel extends BaseIntegration<
  IntegrationRaptorSmartAdvisorData,
  IntegrationRaptorSmartAdvisorState
> {
  parse(data: IntegrationRaptorSmartAdvisorData) {
    super.parse(data);
    this.state.apiKey = data.settings?.raptorsmartadvisor?.api_key;
    this.state.customerId = data.settings?.raptorsmartadvisor?.customer_id
      ? Number(data.settings?.raptorsmartadvisor?.customer_id)
      : undefined;
    this.state.cookieId = data.settings?.raptorsmartadvisor?.cookie_id;
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
