<template>
  <div
    class="addon gameflow"
    :class="{
      'gameflow--is-disabled':
        (editingStore.activeTabCategory === SectionType.SECTION && campaignState?.isEditModeActive) ||
        (editingStore.activeTabCategory === SectionType.POPOVER && campaignState?.isEditModeActive)
    }"
  >
    <Flow :ejected="false">
      <div
        v-if="flowModel && campaignState?.isPopup"
        :key="flowModel?.state?.id"
        :class="[
          'positioner',
          `positioner--position-${(flowModel?.state.config.settings.state?.basic?.position?.type ?? '').replace(
            '_',
            '-'
          )}`
        ]"
      >
        <LFSection :model="flowModel" :section-order="flowModel?.index" />
      </div>

      <template v-else-if="flowModel">
        <LFSection :key="flowModel?.state.id" :model="flowModel" :section-order="flowModel?.index" />
      </template>
    </Flow>

    <div v-if="campaignStore.flowOverlay" class="flow__overlay"></div>
    <ControlCode
      v-if="controlCodeSettings.enabled"
      :settings="controlCodeSettings"
      @onClose="isControlCodeClosed = true"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, computed, ref, watch, nextTick } from 'vue';
import type { AddonGameflowModel } from '@/src/components/addons/gameflow/Model';
import type { ControlCodeSettings } from '@/src/components/components/ControlCode.vue';
import ControlCode from '@/src/components/components/ControlCode.vue';
import { SectionType } from '@/src/typings/enums/enums';
import Flow from '@/src/components/Flow/Flow.vue';
import type { FlowpageModel } from '@/src/components/layout/FlowpageModel';
import type { IntegrationBulkPrizeVouchersModel } from '@/src/components/integrations/bulk-prize-vouchers/Model';
import { ControlCodeTypes } from '@/src/components/integrations/bulk-prize-vouchers/Model';
import { generateUniqueId } from '@/src/utilities/Utilities';
import { addDataLayerEvent } from '@/src/utilities/Tracking';
import { useCampaignStore } from '@/src/store/campaign';
import { useEditingStore } from '@/src/store/editing';
import { useUtilityStore } from '@/src/store/utility';

export default defineComponent({
  name: 'AddonGameflow',
  components: {
    Flow,
    ControlCode
  },
  inheritAttrs: false,
  props: {
    model: {
      type: Object as PropType<AddonGameflowModel>,
      required: true
    }
  },
  setup() {
    const campaignStore = useCampaignStore();
    const campaignModel = campaignStore.model;
    const editingStore = useEditingStore();
    const utilityStore = useUtilityStore();

    const flowModel = computed<FlowpageModel | undefined>(() => {
      if (campaignStore.fictiveFlowPage) {
        return campaignStore.fictiveFlowPage;
      }

      return campaignStore.flowModel;
    });

    const controlPrizeCode = computed(() => {
      const code = campaignStore.replacementTags.control_code
        ? campaignStore.replacementTags.control_code
        : campaignStore.replacementTags.bulk_prize_control_code;

      if (code === '' || typeof code === 'undefined') {
        return;
      }

      return String(code).toUpperCase();
    });

    const isControlCodeClosed = ref(false);

    const isWinner = computed(() => {
      return !!campaignStore.gameWinner;
    });

    const isControlCodeOpen = computed(() => {
      // looking at is winner allow us to only transition when game is actually ended and not when registration is set as winner.

      if (controlCodeData.value?.type === ControlCodeTypes.REPLACEMENT_TAG) {
        return false;
      }

      // registration response adds replacement tags for bulk prizes. If its exits we add it to the page.
      return isWinner.value && !isControlCodeClosed.value;
    });

    const controlCodeData = computed(() => {
      const bulkControlCodeModel = campaignModel?.state.config?.integrations.find(
        (integration) => integration.state.namespace === 'bulk_prize_vouchers'
      ) as IntegrationBulkPrizeVouchersModel; // not sure if there is any way to type this as its this map of IntegrationModel

      // if bulk prizes control code
      if (campaignStore.replacementTags.bulk_prize_control_code && bulkControlCodeModel.state?.controlCode?.enabled) {
        return bulkControlCodeModel.state.controlCode;
      }

      // if instant win control code
      if (campaignModel?.state.advanced.controlCode?.enabled && campaignStore.replacementTags.control_code) {
        return campaignModel?.state.advanced.controlCode;
      }

      return undefined;
    });

    const constrolCodeLabel = computed(() => {
      return controlPrizeCode.value ? controlCodeData.value?.label?.replace('@code', controlPrizeCode.value) : '';
    });

    const controlCodeSettings = computed<ControlCodeSettings>(() => {
      return { ...controlCodeData.value, active: isControlCodeOpen.value, label: constrolCodeLabel.value };
    });

    const setCurrentFlowPageClass = (index: number) => {
      /**
       * I dont have a good explanation on the 20, but that is how the old platform does it
       */
      const classNamesToRemove: string[] = [];

      for (let i = 0; i <= 20; i++) {
        classNamesToRemove.push(`current-flow-page-${i}`);
      }

      utilityStore.removeBodyClasses(classNamesToRemove);
      utilityStore.addBodyClasses([`current-flow-page-${index + 1}`]);
    };

    watch(
      () => campaignStore.flowIndex,
      async () => {
        const currentFlowIndex = campaignStore.flowIndex;
        const currentFlowModel = campaignStore.flowModel;

        if (currentFlowModel) {
          addDataLayerEvent('flow_page', {
            pageId: currentFlowModel.id,
            pageTitle: currentFlowModel.state.title ?? 'Page'
          });
        }

        await nextTick();
        setCurrentFlowPageClass(currentFlowIndex ?? 0);
      },
      {
        immediate: true
      }
    );

    return {
      flowModel,
      SectionType,
      editingStore,
      campaignStore,
      campaignState: campaignModel?.state,
      controlCodeSettings,
      isControlCodeClosed,
      generateUniqueId
    };
  }
});
</script>

<style lang="scss">
.gameflow {
  &--is-disabled {
    pointer-events: none;
  }
}

.flow {
  position: relative;
  z-index: 1;
  transition: height 525ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 0;
    transition: opacity 400ms linear;
  }
}
</style>
