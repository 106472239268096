<template>
  <span ref="elementWrapper" class="content__item-action-throbber">
    <span v-for="i in 3" :key="i" class="content__item-action-throbber-dot" :style="{ backgroundColor: color }"></span>
  </span>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'DotsLoader',
  setup() {
    const color = ref<string | undefined>('#383838');
    const elementWrapper = ref<HTMLElement | undefined>();

    onMounted(() => {
      if (elementWrapper.value) {
        color.value = window.getComputedStyle(elementWrapper.value).color;
      }
    });

    return {
      elementWrapper,
      color
    };
  }
});
</script>
