import type { IntegrationGfAdvancedMapData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationGfAdvancedMapState extends BaseIntegrationState {
  mapKey?: string;
}

export class IntegrationGfAdvancedMapModel extends BaseIntegration<
  IntegrationGfAdvancedMapData,
  IntegrationGfAdvancedMapState
> {
  parse(data: IntegrationGfAdvancedMapData) {
    super.parse(data);
    this.state.mapKey = data.settings?.map_key;
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
