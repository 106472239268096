import { VueCookieNext } from 'vue-cookie-next';
import { useCampaignStore } from '@/src/store/campaign';
import { getStorageItem, removeStorageItem, setStorageItem } from '@/src/utilities/StorageHelper';

export interface CookieOptions {
  path?: string;
  domain?: string;
  sameSite?: string;
  secure?: string;
  expire?: Date;
}

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  cookieObject?: CookieOptions | undefined,
  cookieCheck = false,
  allowStorageFallback = true
) => {
  const campaignStore = useCampaignStore();

  const defaultCookieObject: CookieOptions = {
    path: '/',
    domain: document.domain,
    sameSite: 'none',
    secure: 'true',
    expire: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
  };

  /**
   * Reason for a check, is that some cookies are not dependent on !store.getters['campaign/state'].config?.cookiesEnabled
   * Eg: If a navigator is docked or not. This should be saved in cookies no matter what
   * Which is why we do a check a param to check if it needs to go see if cookies are enabled in config.
   */
  if (cookieCheck) {
    if (typeof document === 'undefined' || campaignStore.model?.state.config?.cookiesEnabled === false) {
      return;
    }

    if (cookieName && cookieName !== '') {
      VueCookieNext.setCookie(cookieName, cookieValue, { ...defaultCookieObject, ...cookieObject });
    }
  } else if (cookieName && cookieName !== '') {
    VueCookieNext.setCookie(cookieName, cookieValue, { ...defaultCookieObject, ...cookieObject });
  }

  if (allowStorageFallback && cookieName && cookieValue) {
    setStorageItem(cookieName, cookieValue);
  }
};

export const getCookie = <T = unknown>(cookieName: string): T | null => {
  if (typeof document === 'undefined' || !cookieName) {
    return null;
  }

  let cookie = VueCookieNext.getCookie(cookieName);

  if (!cookie) {
    cookie = getStorageItem(cookieName);
  }

  /**
   * Checks if cookie needs parsing
   * This is used because we had a lot of bad formatting cookies getting around
   * This will clean up and secure that cookies get set in a correct format
   */
  if ((typeof cookie).toLowerCase() === 'string') {
    if (cookie[0] === '{' || cookie[0] === '[') {
      try {
        const cookieValue = JSON.parse(cookie);

        if ((typeof cookieValue).toLowerCase() !== 'string') {
          return cookieValue as T;
        }

        return null;
      } catch (e) {
        return null;
      }
    } else if (cookie[0] === '"') {
      return null;
    }
  }

  return cookie as T;
};

export const removeCookie = (cookieName: string) => {
  if (typeof document === 'undefined' || !cookieName) {
    return;
  }

  // Always remove the localStorage item as we also always set it.
  removeStorageItem(cookieName);

  // Since Vue cookie next package doesn't respect out option for SameSite, then we need to manually remove it here using native API.
  document.cookie =
    encodeURIComponent(cookieName) +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
    '; domain=' +
    document.domain +
    '; path=/' +
    '; Secure' +
    '; SameSite=none';
};

export const getAllCookies = () => {
  if (typeof document === 'undefined') {
    return [];
  }

  const localStorageKeys = [];
  const keys = VueCookieNext.keys();

  if (window.localStorage && (!keys || keys.length === 0)) {
    for (let i = 0; i < localStorage.length; i++) {
      localStorageKeys.push(localStorage.key(i));
    }

    return localStorageKeys;
  }

  return keys;
};
