<template>
  <div
    v-if="model.state.inputType !== 'hidden'"
    ref="wrapperEl"
    class="content__item-form-element"
    :class="formElementClasses"
  >
    <component :is="comp" :show-validation-errors="showValidationErrors" :model="model" />
    <span
      v-if="model?.state?.tooltip?.enabled || (model?.state?.tooltip?.text && model?.state?.tooltip?.text?.length > 0)"
      class="tooltip-wrapper"
    >
      <i class="tooltip-wrapper__icon fa fa-info-circle"></i>
      <span class="tooltip-wrapper__item">{{ model.state?.tooltip?.text }}</span>
    </span>
    <div
      v-if="
        (model.state.elementMessage || model.state.validation?.message) && showValidationErrors && !model.state.isValid
      "
      class="content__item-form-element-message"
    >
      <i class="content__item-form-element-message-icon fa fa-info-circle" aria-hidden="true"></i>
      <span class="sr-only">Message Info</span>
      {{ model.state.validation?.message || model.state.elementMessage }}
    </div>
  </div>
  <template v-else>
    <component :is="comp" :show-validation-errors="showValidationErrors" :model="model" />
  </template>
</template>

<script lang="ts">
import type { Component, PropType } from 'vue';
import { computed, defineAsyncComponent, defineComponent, nextTick, ref } from 'vue';
import type { FormElementModel } from '@/src/components/addons/registration/types';
import FormElementText from '@/src/components/content/form/elements/types/Text.vue';
import FormElementSelect from '@/src/components/content/form/elements/types/Select.vue';
import FormElementRadio from '@/src/components/content/form/elements/types/Radio.vue';
import FormElementHidden from '@/src/components/content/form/elements/types/Hidden.vue';
import FormElementCheckbox from '@/src/components/content/form/elements/types/Checkbox.vue';

const formFieldComponents: { [key: string]: (() => Promise<Component>) | string } = {
  text: 'form-element-text',
  select: 'form-element-select',
  radio: 'form-element-radio',
  hidden: 'form-element-hidden',
  checkbox: 'form-element-checkbox',
  textarea: () => import('@/src/components/content/form/elements/types/Textarea.vue'),
  slider: () => import('@/src/components/content/form/elements/types/Slider.vue'),
  signature: () => import('@/src/components/content/form/elements/types/Signature.vue'),
  paragraph: () => import('@/src/components/content/form/elements/types/Paragraph.vue'),
  mask: () => import('@/src/components/content/form/elements/types/Mask.vue'),
  file: () => import('@/src/components/content/form/elements/types/File.vue'),
  divider: () => import('@/src/components/content/form/elements/types/Divider.vue'),
  dawa: () => import('@/src/components/content/form/elements/types/Dawa.vue'),
  date: () => import('@/src/components/content/form/elements/types/Date.vue'),
  cvr: () => import('@/src/components/content/form/elements/types/Cvr.vue'),
  captcha: () => import('@/src/components/content/form/elements/types/Captcha.vue'),
  rating: () => import('@/src/components/content/form/elements/types/Rating.vue'),
  recaptcha: () => import('@/src/components/content/form/elements/types/ReCaptcha.vue')
};

export default defineComponent({
  name: 'FormElement',
  components: {
    'form-element-text': FormElementText,
    'form-element-select': FormElementSelect,
    'form-element-radio': FormElementRadio,
    'form-element-hidden': FormElementHidden,
    'form-element-checkbox': FormElementCheckbox
  },
  props: {
    model: {
      type: Object as PropType<FormElementModel>,
      required: true
    },
    showValidationErrors: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const wrapperEl = ref<HTMLElement | null>(null);

    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const comp =
      typeof formFieldComponents[props.model.state.inputType] === 'function'
        ? defineAsyncComponent({
            loader: async () => {
              const asyncLoader = formFieldComponents[props.model.state.inputType];

              if (typeof asyncLoader !== 'function') {
                throw new TypeError(`Unrecognized form input type: ${props.model.state.inputType}`);
              }

              const component = await asyncLoader();

              if (readyPromiseResolve) {
                readyPromiseResolve();
              }

              return component;
            }
          })
        : formFieldComponents[props.model.state.inputType];

    const separateClasses = (): string => {
      if (props.model.state.fieldClass) {
        if (props.model.state.fieldClass.includes(',')) {
          return props.model.state.fieldClass.replace(',', ' ');
        } else {
          return props.model.state.fieldClass;
        }
      }
      return '';
    };

    const formElementClasses = computed(() => {
      return {
        'd-none': !props.model.state?.isVisible,
        'col-md-6': props.model.state?.fieldSize === '2column',
        'col-12': props.model.state?.fieldSize === '1column' || !props.model.state?.fieldSize,
        [`content__item-form-element--${props.model.state.inputType}`]: true,
        [separateClasses()]: !!props.model.state.fieldClass,
        'content__item-form-element--tooltiped': props.model.state?.tooltip?.enabled,
        'content__item-form-element--hidden': props.model.state.hidden
      };
    });

    if (typeof formFieldComponents[props.model.state.inputType] !== 'function' && readyPromiseResolve) {
      readyPromiseResolve();
    }

    return {
      formElementClasses,
      comp,
      wrapperEl,
      onBeforeEnter: async () => {
        await readyPromise;

        // Not sure why 2 nextTicks are needed. But they are...
        await nextTick();
        await nextTick();

        return wrapperEl.value;
      }
    };
  }
});
</script>

<style lang="scss">
.content__item-form-element {
  margin-bottom: 2.5rem;

  .content__item--form &.content__item-form-element--hidden,
  .site .section .grid__addon .content__item--form &.content__item-form-element--hidden,
  .category-landingpage .section .grid__addon .content__item--form &.content__item-form-element--hidden {
    margin-bottom: 0 !important;
  }

  &--location {
    .content__item-form-type--select:last-child .content__item-form-element {
      margin-bottom: 0;
    }
  }

  & > div:not(.content__item-form-element-message) {
    position: relative;
  }
}

.form-group {
  margin-bottom: 0;

  &:before {
    bottom: 0;
  }

  &:after {
    bottom: 0;
  }
}

.form-control:focus,
.form-control {
  line-height: 1.3em;
  height: 3.4rem;
  font-size: 1.8rem;
  background-color: transparent;
  border: 0 none;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}

.form-control:focus {
  box-shadow: none;
}

label {
  margin-bottom: 0;
}

.content__item--form {
  .form-check {
    margin-top: 1rem;
    padding-left: 0;
  }

  .form-check:only-child {
    margin-top: 0;
  }

  .form-check-input {
    opacity: 0;
  }

  .form-check-label {
    display: inline-block;
    line-height: size(22px);
    padding-left: size(34px);
    position: relative;
    cursor: pointer;
    user-select: none;

    &:before,
    &:after {
      display: block !important;
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: size(22px);
      height: size(22px);
      border: 1px solid $base-color;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      transition: box-shadow 150ms linear, border-color 300ms;
    }
  }

  .content__item-form-element--slider .text-field__label,
  .content__item-form-type--rating .content__item-form-label {
    top: 0 !important;
    transform: translateY(0) !important;
    position: static !important;
  }

  .content__item-form-type--rating {
    .content__item-form-group:before {
      border-bottom: 0 none;
    }
  }

  .content__item-form-group--paragraph-click {
    cursor: pointer;
  }

  .content__item-form-element--divider {
    line-height: 0;

    .content__item-form-group--border {
      display: inline-block;
      width: 100%;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }
  }

  .form-check-input:not(:checked) + .form-check-label:before {
    opacity: 1;
  }

  .form-check-input:checked + .form-check-label:before,
  .form-check-input:not(:checked) + .form-check-label:after {
    opacity: 0;
  }

  .form-check-input:checked + .form-check-label:after {
    opacity: 1;
    content: '\f00c';
    font: normal normal normal 14px/1 FontAwesome;
    font-size: size(14px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: auto;
    text-align: center;
  }

  .content__item-form-checkbox-input:checked + .content__item-form-label--checkbox:after {
    content: '\f00c';
    color: $white;
    border: 0;
    background: $base-color;
    text-align: center;
    padding: 4px 0 0 0;
  }

  .content__item-form-label--radio {
    line-height: size(17px);

    &:before,
    &:after {
      width: size(17px);
      height: size(17px);
    }

    &:before {
      border-radius: 50%;
    }

    &:after {
      color: rgba(0, 0, 0, 0);
    }
  }

  .content__item-form-radio-input:checked + .content__item-form-label--radio:after {
    content: '\f111';
    color: $base-color;
    border-radius: 50%;
    line-height: 1.5rem;
    font-size: 1rem;
    text-align: center;
  }

  .content__item-form-checkbox-input:checked:focus + .content__item-form-label--checkbox:after,
  .content__item-form-checkbox-input:not(:checked):focus + .content__item-form-label--checkbox:before {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  .content__item-form-checkbox__input:focus + .content__item-form-label--checkbox:before {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    transition: box-shadow 150ms linear;
  }
}

.content__item-form {
  &-element {
    position: relative;
    font-size: 1.8rem;

    &--hidden {
      display: none;
    }

    .no-touch &:hover,
    .touch &:active,
    &--focus {
      .content__item-form-element-message {
        transform: translateY(0) translateZ(0);
        opacity: 1;
      }
    }
  }

  &-element-message {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 3.8rem;
    left: 3rem;
    max-width: 100%;
    margin: -0.6rem 0 0 0;
    padding: 0.8rem 1.2rem 0.8rem 2.2em;
    background: $base-color;
    transform: translateY(1rem) translateZ(0);
    backface-visibility: hidden;
    font-size: 0.8em;
    line-height: 1.2em;
    color: $white;
    z-index: 100;
    transition: transform $transition-speed-transform $transition-easing-transform,
      opacity $transition-speed-color $transition-easing-color;
    opacity: 0;
    pointer-events: none;

    &-icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 0.6rem;
      transform: translateY(-50%);
      font-size: 1.4em;
      opacity: 0.3;
    }
  }

  &-group {
    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
    }

    &.content__item-form-group--select {
      &::before,
      &::after {
        z-index: 1;
      }

      .content__item-form-select-origin[disabled] {
        opacity: 0.5;
      }
    }

    &::before {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: $base-color;
    }

    &::after {
      height: 2px;
      border-color: transparent;
      transform: scale(0);
      transition-property: transform;
      transition-duration: 0.6s;
      transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    &--paragraph {
      &::before,
      &::after {
        display: none;
      }
    }

    /*&.form-group--is-focus:after {
      background-color: $base-color;
    }*/

    .form-control {
      height: size(34px);
      /* padding-bottom: 2px; */
      transition: transform 300ms $transition-easing-default, opacity 250ms linear;

      &.content__item-form-text--hide {
        transform: translateX(-200px) translateZ(0);
        opacity: 0;
      }
    }

    .content__item-form-type--phone-code &.content__item-form-group--select {
      &::before,
      &::after {
        width: 10rem;
      }
    }

    .content__item-form-type--phone-code &.content__item-form-group--text {
      &::before,
      &::after {
        left: 11rem;
      }
    }
  }

  &-label:not(.form-check-label) {
    position: absolute;
    top: -2.4rem;
    left: 0;
    transform: translateY(24px);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 3.2rem;
    display: block;
    margin-bottom: 0;
    transform-origin: bottom left;
    transition-property: transform, color;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    pointer-events: none;

    .content__item-form-type--phone-code & {
      left: 11rem;
    }

    &.content__item-form-label-cvr-name {
      top: 3.6rem;
      transform: translateY(0) !important;
    }
  }

  &-label--signature {
    display: inline-block !important;
    line-height: 2.2rem !important;
    padding-left: 0;
    position: relative !important;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    pointer-events: all !important;

    &.ng-leave {
      display: none !important;
    }
  }

  &-autofill-message {
    display: block;
    position: absolute;
    top: size(20px);
    left: 0;
    font-size: size(18px);
    transition: transform 300ms $transition-easing-default, opacity 250ms linear;

    &.ng-enter {
      transform: translateX(200px) translateZ(0);
      opacity: 0;
    }

    &.ng-enter-active {
      transform: translateX(0%) translateZ(0);
      opacity: 1;
    }

    &.ng-leave {
      transform: translateX(0%) translateZ(0);
      opacity: 1;
    }

    &.ng-leave-active {
      transform: translateX(200px) translateZ(0);
      opacity: 0;
    }

    &-autofill-action {
      position: absolute !important;
      bottom: 0;
      right: 0;
      padding: 1rem 1.5rem;
      font-size: size(12px);
      transition: opacity 150ms linear;
      min-width: 80px;
      background: transparent;

      &.ng-enter {
        opacity: 0;
      }

      &.ng-enter-active {
        opacity: 1;
      }

      &.ng-leave {
        opacity: 1;
      }

      &.ng-leave-active {
        opacity: 0;
      }

      &--loading &-text {
        opacity: 0;
      }
    }
  }

  &-textarea:focus,
  &-textarea {
    padding: size(10px) 0;
    min-height: 100px;
    line-height: 1.3em;
    transition: transform 300ms $transition-easing-default, opacity 250ms linear;
    margin: 0;
  }

  &-label--date {
    top: -2.4rem;
    transform: translateY(24px);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  &-date {
    opacity: 0;
    transition: opacity 150ms linear;

    .form-group--is-active &,
    .form-group--is-focus &,
    .form-group--has-value & {
      opacity: 1;
    }

    &:focus {
      opacity: 1;
    }
  }

  &-element--cvr .content__item-form-status {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);

    &.ng-enter {
      transition: opacity 175ms linear;
      opacity: 0;

      &-active {
        opacity: 1;
      }
    }

    &.ng-leave {
      transition: opacity 175ms linear;
      opacity: 1;

      &-active {
        opacity: 0;
      }
    }
  }

  &-element--select {
    .form-group {
      .content__item-form-label--select {
        top: -2.4rem;
        transform: translateY(25px);
        width: 100%;
        height: size(34px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 1;
      }
    }
    .form-group--is-focus .content__item-form-label--select {
      transform: scale(0.8) translateY(-1px);
      opacity: 0.6;
    }
  }
}

.registration-form--inline-messages .content__item-form-element-message {
  position: static;
  opacity: 1;
  display: inline-block;
  transform: none;
  background-color: transparent;
  padding-left: 0;
  color: #f44336;
  margin: 0 0 0.5rem;

  .content__item-form-element-message-icon {
    display: none;
  }
}

.category-mobile .content__item-form-select-origin,
.content__item-form-element--select .select2-container {
  width: 100% !important;
}

.site--popover-active .select2-container {
  z-index: 1070;
}

.select2-container,
.select2-container.select2-container--bootstrap4 {
  .select2-selection,
  .select2-choice {
    padding: 0;
    outline: 0;
    height: size(34px);
    display: block;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    line-height: 1.3em;
    border: 0 none;
    background-color: transparent;
    color: inherit;
    border-radius: 0;
  }

  .select2-selection__rendered {
    line-height: 3.4rem;
    height: 3.4rem;
    font-size: 1.8rem;
    padding: 0;
    color: inherit;
  }

  .select2-selection--single .select2-selection__rendered {
    color: inherit;
  }

  .select2-arrow,
  .select2-selection__arrow {
    width: 3.4rem !important;
    height: 100% !important;
    right: 0 !important;

    b {
      border-color: rgba(0, 0, 0, 0.38) transparent transparent transparent;
      border-style: solid !important;
      border-width: 0.6rem 0.6rem 0 0.6rem !important;
      height: 0 !important;
      right: 0 !important;
      margin-left: 1rem !important;
      margin-top: -0.2rem !important;
      position: absolute !important;
      top: 50% !important;
      width: 0 !important;
    }
  }

  &.select2-container--open .select2-selection .select2-selection__arrow b {
    border-color: transparent transparent rgba(0, 0, 0, 0.38) transparent;
    border-width: 0 0.6rem 0.6rem 0.6rem;
  }

  .select2-dropdown {
    border-color: #ced4da;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 0.2rem;
  }

  .select2-search--dropdown {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 0.8rem;

    &:before {
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 1rem;
      width: 4rem;
      height: 4rem;
      text-align: center;
      display: inline-block;
      font: normal normal normal 1.3rem/4rem FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\f002';
      color: #000;
      transition: background-color 300ms;
    }

    &:hover:before {
      background-color: rgba(0, 0, 0, 0.1);
      transition: background-color 300ms;
    }
  }

  .select2-search--dropdown .select2-search__field {
    border-radius: 0;
    font-size: 1.6rem;
    color: #000;
    padding: 0.2rem 0.8rem 0.2rem 4.8rem;
    height: 4rem;
    margin: 0 0 0.4rem 1rem;
    border: 0 none;
  }

  .select2-results__option {
    font-size: 1.6rem;
    color: #000;
  }

  .select2-results .select2-results__option[data-select2-id*='string'] {
    padding: 0 !important;
    background-color: transparent !important;
  }

  .select2-results__option[aria-selected='true'] {
    background-color: transparent;
  }

  .select2-results .select2-results__option--highlighted[aria-selected] {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.select2.select2-container--bootstrap4.select2-container--disabled {
  .select2-selection,
  .select2-selection--multiple .select2-selection__choice {
    background-color: transparent;
  }
}

.select2-container--disabled {
  opacity: 0.5;
}

.content__item-form-carbuddii {
  .content__item-form-type {
    position: relative;
    margin-bottom: 2.5rem;
  }
}

.category-mobile {
  .content__item-form-select-origin {
    padding: 0;
    outline: 0;
    height: size(34px);
    display: block;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    line-height: 1.3em;
    border: 0 none;
    background-color: transparent;
    color: inherit;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.form-group--error:after,
.form-group--is-focus:after {
  transform: scale(1);
}

.content__item-form-checkbox .content__item-form-label--checkbox a {
  text-decoration: underline;
}

.content__item-form-checkbox .content__item-form-label--checkbox,
.content__item-form-checkbox.form-check--error .content__item-form-label--checkbox {
  transition: color 300ms;
}

.content__item-form-checkbox.form-check--error .content__item-form-label--checkbox:before {
  transition: border-color 300ms, opacity 300ms;
}

.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label,
.form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
.content__item-form-type--phone-code
  .form-group--text.form-group--has-value:not(.form-group--fixed-label)
  + .content__item-form-group
  .content__item-form-label {
  transform: scale(0.8) translateY(-1px) !important;
  opacity: 0.6 !important;
}

.content__item-form-element .content__item-form-label a {
  color: inherit !important;
  font-size: inherit !important;
}

.dawa-autocomplete {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 100;

  &__list {
    padding: 0.4rem 1.3rem;
    background-color: $white;
    border-bottom: 1px solid #dbdbdb;
    color: $black;
    transition: background-color 125ms linear;
    cursor: pointer;
    list-style: none;

    &:hover {
      background-color: $gray;
    }

    &--active {
      background-color: darken($gray, 5%) !important;
      color: lighten($black, 5%) !important;
    }

    &.ng-leave {
      display: none;
    }
  }
}

.content__item-form-group--signature {
  line-height: 0;
}

.content__item-form-type--file {
  .content__item-form-group--file,
  .content__item-form-label--file,
  .content__item-form-group--file-is-focus .content__item-form-label--file {
    color: inherit !important;
  }

  .content__item-form-group--file {
    transition: opacity 175ms linear;
    opacity: 1;
  }

  &.content__item-form-type--file-loading {
    .content__item-form-group--file {
      opacity: 0.5;
    }
  }

  .content__item-form-label--file {
    top: -2.4rem;
    z-index: 10;
    pointer-events: none;
    user-select: none;
  }

  input[type='file'],
  input[type='file']::-webkit-file-upload-button {
    cursor: pointer;
  }

  .content__item-form-file {
    position: absolute;
    left: 0;
    z-index: 11;
    top: 0;
    height: 3.4rem;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .content__item-form-filename {
    pointer-events: none;
    user-select: none;
    display: block;
    height: 3.4rem;
    width: 100%;
    overflow: hidden;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.content__item-form-element--tooltiped {
  position: relative;
  padding-right: 3.5rem;

  &.content__item-form-element--checkbox {
    .site--mobile & {
      padding-right: 6rem !important;
    }

    > div {
      display: inline;

      * {
        display: inline;
      }
    }

    .tooltip-wrapper {
      position: relative;
      margin-left: 1.5rem;
      bottom: 0;

      &__item {
        .site--mobile & {
          transform: translateX(-50%) translateY(1rem) translateZ(0) !important;
          right: auto !important;
          left: 50% !important;
        }
      }
    }
  }

  &.content__item-form-element--radio {
    .tooltip-wrapper {
      bottom: auto;
      top: 2.4rem;
    }
  }

  .tooltip {
    &-wrapper {
      position: absolute;
      right: 0;
      top: 0.8rem;
      z-index: 1;
      cursor: pointer;

      &__item {
        position: absolute;
        bottom: 3.5rem;
        left: 50%;
        padding: 0.6rem 1rem 0.7rem 1rem;
        width: 20rem;
        background: #292828;
        border-radius: 0.4rem;
        opacity: 0;
        transform: translateX(-50%) translateY(1rem) translateZ(0);
        color: #9e9e9e;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
        transition: visibility 0ms linear 150ms, opacity 150ms linear, transform 150ms ease;
        z-index: 10;
        visibility: hidden;
        pointer-events: none;
        backface-visibility: hidden;
        will-change: transform, opacity;

        @media (max-width: 1220px) {
          left: auto;
          right: 0;
          transform: translateX(0) !important;
          width: 16rem;
        }
      }

      &__icon {
        color: #a29e9c;
        font-size: 2rem;
      }

      &:hover {
        .tooltip-wrapper__item {
          opacity: 1;
          transform: translateX(-50%) translateY(0) translateZ(0);
          visibility: visible;
          transition-delay: 0ms;
        }
      }
    }
  }
}

.content__item-form-type--phone-code {
  .content__item-form-group--select {
    display: inline-block;
    float: left;
    margin-right: 1.5rem !important;
    width: 10rem;
    vertical-align: bottom;

    &:after {
      display: none;
    }

    .select2 {
      width: 10rem !important;
    }

    .content__item-form-select.form-control {
      height: 3.4rem;

      option {
        color: $base-color !important;
        -webkit-appearance: none;
      }
    }
  }

  .content__item-form-group--text {
    width: calc(100% - 11.5rem);
    display: inline-block;
  }
}
.select2-results {
  .select2-results__option {
    color: $base-color !important;
    padding: 0.6rem 1.2rem !important;
    font-size: 1.4rem;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #f8f9fa;
  }
}

.content__item-form-group--mask:not(.form-group--is-focus) .form-control::placeholder {
  color: transparent;
  opacity: 0;
}

.content__item-form-group--mask:not(.form-group--is-focus) .form-control:-ms-input-placeholder {
  color: transparent;
}

.content__item-form-group--mask:not(.form-group--is-focus) .form-control::-ms-input-placeholder {
  color: transparent;
}

.select2-display-none {
  display: none !important;
}

.category-mobile .content__item--form .content__item-form-element--date .form-group .form-control {
  line-height: 3.6rem !important;
}

.vue-select {
  .vue-select-header {
    height: 100%;
  }

  .vue-select-header .icon.loading,
  .vue-select-header .icon.arrow-downward {
    margin-right: 12px;
  }

  .arrow-downward {
    border-color: rgba(0, 0, 0, 0.38) transparent transparent transparent;
    border-style: solid !important;
    border-width: 0.6rem 0.6rem 0 0.6rem !important;
    height: 0 !important;
    right: 0 !important;
    margin-left: 1rem !important;
    margin-top: -0.2rem !important;
    position: absolute !important;
    top: 50% !important;
    width: 0 !important;
  }
}

//@import "./types/captcha/captcha"; //TODO: Need to add captcha scss
</style>
