<template>
  <div class="lf-toast">
    <div v-if="tip" class="lf-toast__icon">
      <div class="circle">TIP<span class="highlight">!</span></div>
    </div>
    <!-- nosem -->
    <div class="lf-toast__content" v-html="message"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DotsLoader',
  props: {
    message: String,
    tip: Boolean
  },
  emits: ['close-toast']
});
</script>

<style lang="scss">
.lf-toast {
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;

  .lf-toast__icon {
    width: 25%;
    margin-right: 2rem;
    font-size: 1.6rem;
  }
  .lf-toast__content {
    line-height: inherit;
    font-size: inherit;
    margin-right: 2rem;
  }

  .lf-toast__close {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
  }

  .circle {
    border: 3px solid;
    border-radius: 50%;
    height: calc(2rem * 3);
    width: calc(2rem * 3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .highlight {
    color: #0fffac;
  }
}

.Vue-Toastification__toast--info {
  background-color: #232424;
}
</style>
