export interface TruncateState {
  enabled?: boolean;
  labelMore?: string;
  labelLess?: string;
  limit?: number;
}

export interface TruncateData {
  enable: string;
  label_more?: string;
  label_less?: string;
  limit?: string;
}

// Helper function to truncate HTML content
export function truncateHtmlContent(html: string, maxLength: number): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null);

  let textLength = 0;
  let currentNode = walker.nextNode();
  const truncationSuffix = '...';
  const placeholderPattern = /#[a-z_]+#/gi;

  while (currentNode) {
    let textContent = currentNode.textContent || '';
    if (textLength + textContent.length > maxLength) {
      // Check if we are cutting off in the middle of a placeholder
      let cutPosition = maxLength - textLength;
      const possibleCutOffText = textContent.slice(cutPosition - 20, cutPosition + 20); // Look-ahead and look-behind
      const match = placeholderPattern.exec(possibleCutOffText);

      if (match && match.index < 20) {
        // If a placeholder is cut, extend cutPosition so that the placeholder isn't truncated
        cutPosition += match.index + match[0].length - 20;
      }

      // Truncate the text at the cutPosition
      textContent = textContent.slice(0, cutPosition) + truncationSuffix;
      currentNode.textContent = textContent;
      // Remove all nodes beyond this point
      removeNodesAfter(walker.currentNode);
      break;
    } else {
      textLength += textContent.length;
      currentNode = walker.nextNode();
    }
  }

  return doc.body.innerHTML;
}

// Helper function to remove all nodes after a certain node
function removeNodesAfter(node: Node) {
  while (node.nextSibling) {
    node.parentNode?.removeChild(node.nextSibling);
  }
  if (node.parentNode && node.parentNode !== node.ownerDocument?.body) {
    removeNodesAfter(node.parentNode);
  }
}
