import type { Component } from 'vue';
import type { BaseIntegrationData, BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export class IntegrationDagrofaModel extends BaseIntegration<BaseIntegrationData, BaseIntegrationState> {
  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/dagrofa/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
