import { SdkBaseAddonModel } from './addons';
import { SdkAddonRegistration } from './addons/registration';
import { SdkAddonTipAFriend } from './addons/tip-a-friend';
import { SdkAddonButton } from './addons/button';
import { SdkAddonIcon } from './addons/icon';
import { SdkAddonImage } from './addons/image';
import { SdkAddonAuthentication } from './addons/authentication';
import type ColumnModel from '@/src/components/layout/column/ColumnModel';

export class SdkColumnModel {
  #model: ColumnModel;

  constructor(model: ColumnModel) {
    this.#model = model;
  }

  public get addons() {
    return this.#model.state.addons.map<
      | SdkAddonRegistration
      | SdkAddonButton
      | SdkAddonIcon
      | SdkAddonImage
      | SdkAddonTipAFriend
      | SdkAddonAuthentication
      | SdkBaseAddonModel
    >((addon) => {
      if (addon.alias === 'registration') {
        return new SdkAddonRegistration(addon);
      } else if (addon.alias === 'lf-button') {
        return new SdkAddonButton(addon);
      } else if (addon.alias === 'lf-icon') {
        return new SdkAddonIcon(addon);
      } else if (addon.alias === 'lf-image') {
        return new SdkAddonImage(addon);
      } else if (addon.alias === 'tipafriend') {
        return new SdkAddonTipAFriend(addon);
      } else if (addon.alias === 'authentication') {
        return new SdkAddonAuthentication(addon);
      }

      return new SdkBaseAddonModel(addon);
    });
  }

  public get id(): string {
    return String(this.#model.state.id);
  }

  public get label(): string {
    return this.#model.state.label;
  }
}
