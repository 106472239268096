import { useUtilityStore } from '@/src/store/utility';
import type { GameIndicator } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GameCalendarFieldsData,
  GameCalendarGeneralData,
  GameCalendarLayoutData,
  GameCalendarPrizesData,
  GameCalendarData,
  GameCalendarFieldsPlacementData,
  GameCalendarFieldsDoorSettingsData,
  GameCalendarFieldQuestionData,
  GameCalendarLayoutDesktopData,
  GameCalendarLayoutMobileData,
  CalenderBackgroundBehaviorType,
  CalenderBackgroundPositionType
} from '@/src/components/games/calendar/Data';
import type { DeviceData } from '@/src/hooks/useDevice';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import { AnswerLayoutSettingsModel } from '@/src/models/settings/AnswerLayoutSettingsModel';
import { AlignContentType, BehaviorTypes, PositionTypes } from '@/src/typings/enums/enums';
import { formatUrl } from '@/src/utilities/Url';
import { replaceFontIdWithFontString } from '@/src/utilities/Utilities';
import type { GameSectionImageOverlay } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';

export interface FieldAnswerState {
  id: number; // 1-3
  value: string; // text or image
}

export interface GameCalendarSectionImageOverlay extends GameSectionImageOverlay {
  onFeedback: boolean;
  tileRepeat?: string;
}

export enum DateType {
  SINGLE,
  RANGE
}

export enum VideoPosition {
  ABOVE,
  LEFT,
  RIGHT
}

export enum FieldActionType {
  QUIZ,
  URL,
  GOTO_FLOW_PAGE,
  GOTO_CAMPAIGN
}

type LinkTargetTypes = '_blank' | '_self';

interface FieldLinkState {
  enabled: boolean;
  url?: string;
  text?: string;
  target?: LinkTargetTypes;
}

interface FieldVideoState {
  enabled: boolean;
  position?: VideoPosition;
  url?: string;
  width?: number;
}

interface FieldActionState {
  type: FieldActionType;
  delay?: number;
  flowPage?: number;
  url?: string;
  campaignLiveUrl?: string;
}

interface FieldPlacementState {
  h: number;
  w: number;
  x: number;
  y: number;
}

type PrizeContentTypes = 'slider' | 'text';

export interface CalendarPrizeContentObject {
  class: string;
  config: {
    contentType: string;
  };
  content?: CalendarPrizeContentGroups[];
  type: PrizeContentTypes;
}

export interface CalendarPrizeContentGroups {
  id: string;
  items: CalendarPrizeContent[];
}

export interface CalendarPrizeContent {
  id: string;
  fieldId: number;
  direction?: string;
  headline: string;
  description: string;
  fullDescription: boolean;
  image: string;
  removeDefaultStyle: boolean;
  subheadline?: string;
  date?: string;
  questionPrizes: boolean;
  link: {
    url: string;
    target: string;
  };
}

export interface FieldQuestionState {
  answers?: FieldAnswerState[];
  correctAnswers?: number[];
  id: number;
  link: FieldLinkState;
  question: string;
  video: FieldVideoState;
  answerLayout: AnswerLayoutSettingsModel;
  answerAsImage?: boolean;
  backgroundImage: GameCalendarSectionImageOverlay;
  prizeContent?: CalendarPrizeContentObject[];
  prizeText?: string;
  answerColor: {
    correct: string;
    wrong: string;
  };
}

export interface FieldDoorImage {
  image?: string;
  frontImage?: string;
  behindImage?: string;
}
export enum FieldStateType {
  NONE,
  DISPLAY_IMAGE,
  CAN_INTERACT
}

export interface GameCalendarFieldsState {
  fieldState: FieldStateType;
  action: FieldActionState;
  date: {
    from: string;
    to: string;
    type: DateType;
  };
  doorSettings?: FieldDoorImage;
  id: number;
  image?: string;
  loserOnIncorrectAnswer?: boolean;
  message: {
    loser: string;
    winner: string;
  };
  label?: string;
  multipleQuestions: boolean;
  placement?: FieldPlacementState;
  questions?: FieldQuestionState[];
  mobileImage?: string;
  correctAnswers?: number;
}

export enum FieldInteraction {
  SCRATCH,
  CLICK
}

export enum FieldAnimation {
  OPEN,
  FADE,
  INSTANT
}

export enum QuizAlign {
  LEFT,
  CENTER,
  RIGHT
}

export interface GameCalendarTestDate {
  date?: string;
  enabled: boolean;
}

export interface GameCalendarGeneralState {
  fieldInteraction: FieldInteraction;
  fieldAnimation?: FieldAnimation;
  linkLabel: string;
  message: {
    loser: string;
    winner: string;
  };
  quizAlign: QuizAlign;
  answerAlignment: AlignContentType;
  scratchAreaSize: number;
  showFieldPrizes: boolean;
  fieldPrizeHeadline?: string;
  removeTextLimit?: boolean;
  showAnswerButton: boolean;
  showCorrectAnswer: boolean;
  showCorrectAnswerDelay?: number;
  activeStateColor?: string;
  activeStateBackgroundColor?: string;
  oneDoor: CalendarOneDoor;
  test: GameCalendarTestDate;
}

export interface CalendarOneDoor {
  enabled: boolean;
  oneDoorFallback?: string;
}

export interface CalendarAnswerSettings {
  answerBtn: string;
  answerLayout: AnswerLayoutSettingsModel;
  selectAnswerBtn: string;
  showAnswerButton: boolean;
}

export interface LayoutCoinState {
  image?: string;
  offset?: {
    x?: number;
    y?: number;
  };
}

interface LayoutPlaceState {
  h: number;
  id: number;
  label: string;
  w: number;
  x: number;
  y: number;
}

interface LayoutFrameState {
  height: number;
  width: number;
}

export enum LayoutType {
  IMAGE_WITH_DOORS,
  IMAGE_WITHOUT_DOORS,
  ONLY_DOORS
}

export interface CalendarLayoutState {
  calendarImage?: string;
  coin?: LayoutCoinState;
  type?: LayoutType;
  place?: LayoutPlaceState[];
  frame?: LayoutFrameState;
  doorSettings?: {
    backsideColor: string;
  };
  background?: {
    color?: string;
    type?: LayoutMobileBackgroundType;
    image?: string;
    place?: LayoutPlaceState[];
  };
  font?: {
    color: string;
    family?: string;
    size: number;
    weight: number;
  };
  border?: {
    color?: string;
    type?: string;
    thickness?: number;
    radius?: number;
  };

  visibilityDateRange?: LayoutMobileVisibilityDateRange;
}

export enum LayoutMobileBackgroundType {
  COLOR,
  IMAGE
}

export enum LayoutMobileVisibilityDateRange {
  ALL,
  ACTIVE
}

export interface GameCalendarPrizeState {
  campaignSettings?: {
    field: string;
    prizeMessage: string;
  };
  description: string;
  image: string;
  name: string;
  prizeId: number;
  sponsor: {
    companyName: string;
    contactEmail: string;
    contactName: string;
    enabled: boolean;
    image: string;
    logo: string;
    www: string;
  };
  url: string;
  value: string;
  weight: number;
}

export interface GameCalendarState {
  fields: GameCalendarFieldsState[];
  general: GameCalendarGeneralState;
  answerSettings: CalendarAnswerSettings;
  layout?: CalendarLayoutState;
  prizes?: GameCalendarPrizeState[];
}

export class GameCalendarModel extends GameModel<GameCalendarData, GameCalendarState> {
  parseGame(data: GameCalendarData): void {
    const state = this.state;

    state.general = this.constructGeneralState(data.general);
    state.answerSettings = this.constructAnswerSettings(data.general);
    state.layout = {};
    state.layout = this.parseDeviceLayout(data.layout.layout, state.general);

    state.fields = data.fields.fields.map<GameCalendarFieldsState>((item) => {
      return GameCalendarModel.constructFieldsState(item, data.general, data.prizes, state.layout);
    });

    state.prizes = data.prizes.map<GameCalendarPrizeState>((item) => {
      return GameCalendarModel.constructPrizesState(item);
    });

    if (state.general.test.date) {
      const campaignStore = useCampaignStore();
      campaignStore.setGameTestDate(state.general.test.date);
    }
  }

  private static constructPrizesContent(
    item: GameCalendarPrizesData,
    generalData: GameCalendarGeneralData
  ): CalendarPrizeContent {
    let fieldLimit = false;

    if (generalData.remove_text_limit === '1') {
      fieldLimit = true;
    }

    return {
      id: item.prize_id.toString(),
      fieldId: item?.campaign_settings?.field ? Number(item.campaign_settings.field) : 0,
      headline: item.name,
      description: item.description,
      fullDescription: fieldLimit,
      image: item.image,
      removeDefaultStyle: true,
      questionPrizes: true,
      link: {
        url: item.url,
        target: '_blank'
      }
    };
  }

  private static constructPrizesState(item: GameCalendarPrizesData): GameCalendarPrizeState {
    return {
      ...((item.campaign_settings?.field || item.campaign_settings?.prize_message) && {
        campaignSettings: {
          field: item.campaign_settings.field,
          prizeMessage: item.campaign_settings.prize_message
        }
      }),
      description: item.description,
      image: item.image,
      name: item.name,
      prizeId: item.prize_id,
      sponsor: {
        companyName: item.sponsor_company_name,
        contactEmail: item.sponsor_contact_email,
        contactName: item.sponsor_contact_name,
        enabled: item.sponsor_enabled,
        image: item.sponsor_image,
        logo: item.sponsor_logo,
        www: item.sponsor_www
      },
      url: item.url,
      value: item.value,
      weight: item.weight
    };
  }

  private parseDeviceLayout(
    data: GameCalendarLayoutData,
    general: GameCalendarGeneralState
  ): CalendarLayoutState | undefined {
    let layout: CalendarLayoutState | undefined;
    if (useDevice().isMobile && data.mobile) {
      layout = GameCalendarModel.constructLayoutMobileState(data.mobile, general);
    }

    if (useDevice().isTablet && !data.desktop.overwrite_tablet && data.tablet) {
      layout = GameCalendarModel.constructCalendarLayoutState(data.tablet);
    }

    if (useDevice().isDesktop || (useDevice().isTablet && data.desktop.overwrite_tablet)) {
      layout = GameCalendarModel.constructCalendarLayoutState(data.desktop);
    }

    return {
      ...layout,
      ...(data.door_settings?.backside_color && {
        doorSettings: {
          backsideColor: data.door_settings.backside_color
        }
      })
    };
  }

  private static constructLayoutMobileState(data: GameCalendarLayoutMobileData, general: GameCalendarGeneralState) {
    const utilityStore = useUtilityStore();

    if (data.font?.family) {
      utilityStore.loadFont(data.font.family);
    }

    let mobileLayoutBackgroundType: LayoutMobileBackgroundType;

    switch (data.background?.type) {
      case 'image':
        mobileLayoutBackgroundType = LayoutMobileBackgroundType.IMAGE;
        break;

      case 'color':
        mobileLayoutBackgroundType = LayoutMobileBackgroundType.COLOR;
        break;
    }

    let visibilityDateRange: LayoutMobileVisibilityDateRange | undefined;

    if (data?.visibility_date_range) {
      switch (data.visibility_date_range) {
        case 'all':
          visibilityDateRange = LayoutMobileVisibilityDateRange.ALL;
          break;

        case 'active':
          visibilityDateRange = LayoutMobileVisibilityDateRange.ACTIVE;
          break;
      }
    }

    let borderColor;

    if (data.border?.color) {
      borderColor = data.border.color;
    }

    return {
      background: {
        ...(data.background?.color && { color: data.background.color }),
        ...(data.background?.image && {
          image: data.background.image
        }),
        type: mobileLayoutBackgroundType,

        ...(data.background?.place && {
          place: data.background.place.map<LayoutPlaceState>((item) => {
            return {
              h: Number(item.h),
              id: Number(item.id),
              label: item.label,
              w: Number(item.w),
              x: Number(item.x),
              y: Number(item.y)
            };
          })
        })
      },

      font: {
        color: data.font?.color ? data.font.color : '',
        family: data.font?.family ? replaceFontIdWithFontString(data.font?.family) : '',
        size: data.font?.size ? Number(data.font.size) : 56,
        weight: Number(data.font?.weight) || 400
      },
      border: {
        ...(borderColor && { color: borderColor }),
        type: data.border?.type ? data.border.type : 'none',
        ...(data.border?.width && {
          thickness: Number(data.border.width)
        }),
        // Border radius is not supported on the click+open animation combination
        // as the construction of the DOM is completely different than the normal renderer.
        ...(data.border?.radius &&
          !(general.fieldAnimation === FieldAnimation.OPEN && general.fieldInteraction === FieldInteraction.CLICK) && {
            radius: Number(data.border.radius)
          })
      },

      ...(typeof visibilityDateRange === 'number' && { visibilityDateRange })
    };
  }

  private static constructCalendarLayoutState(data: GameCalendarLayoutDesktopData): CalendarLayoutState {
    const utilityStore = useUtilityStore();

    let desktopPlaceStates: LayoutPlaceState[] | undefined;

    if (data.door?.font?.family) {
      utilityStore.loadFont(data.door.font.family);
    }

    if (data.place) {
      desktopPlaceStates = data.place.map<LayoutPlaceState>((item) => {
        return {
          h: Number(item.h),
          id: Number(item.id),
          label: item.label,
          w: Number(item.w),
          x: Number(item.x),
          y: Number(item.y)
        };
      });
    }

    let desktopLayoutType: LayoutType;

    switch (data.type) {
      case 'calendar_image_with_doors':
        desktopLayoutType = LayoutType.IMAGE_WITH_DOORS;
        break;
      case 'calendar_image_without_doors':
        desktopLayoutType = LayoutType.IMAGE_WITHOUT_DOORS;
        break;
      case 'only_doors':
        desktopLayoutType = LayoutType.ONLY_DOORS;
        break;
      default:
        desktopLayoutType = LayoutType.IMAGE_WITH_DOORS;
    }

    return {
      ...(data.calendar_image && {
        calendarImage: data.calendar_image
      }),

      ...(data?.coin_image && {
        coin: {
          image: data.coin_image,
          offset: {
            ...(data.coin_offset_x && {
              x: Number(data.coin_offset_x)
            }),
            ...(data.coin_offset_y && {
              y: Number(data.coin_offset_y)
            })
          }
        }
      }),
      type: desktopLayoutType,

      ...(desktopLayoutType === LayoutType.IMAGE_WITH_DOORS &&
        data.door && {
          border: {
            radius: Number(data.door.border.radius)
          }
        }),

      ...(desktopLayoutType !== LayoutType.IMAGE_WITH_DOORS &&
        data.door && {
          background: {
            ...(data.door.background.color && {
              color: data.door.background.color
            })
          },
          border: {
            color: data.door.border.color,
            radius: Number(data.door.border.radius),
            thickness: Number(data.door.border.thickness),
            type: data.door.border.type
          },
          font: {
            color: data.door.font.color,
            family: replaceFontIdWithFontString(data.door.font.family),
            size: Number(data.door.font.size),
            weight: Number(data.door.font.weight) || 400
          }
        }),

      ...(desktopPlaceStates && {
        place: desktopPlaceStates
      }),
      ...(data.frame && {
        frame: {
          height: Number(data.frame.height),
          width: Number(data.frame.width)
        }
      })
    };
  }

  private constructAnswerSettings(data: GameCalendarGeneralData): CalendarAnswerSettings {
    let answerLayout: AnswerLayoutSettingsModel | undefined;

    if (this.state.answerSettings?.answerLayout) {
      this.state.answerSettings.answerLayout.setData(data);
      answerLayout = this.state.answerSettings.answerLayout;
    } else {
      answerLayout = new AnswerLayoutSettingsModel(data);
    }
    return {
      answerBtn: data.answer_btn,
      answerLayout,
      selectAnswerBtn: data.select_answer_btn,
      showAnswerButton: data.show_answer_button === '1'
    };
  }

  private constructGeneralState(data: GameCalendarGeneralData): GameCalendarGeneralState {
    let fieldInteraction: FieldInteraction = FieldInteraction.SCRATCH;

    switch (data.field_interaction) {
      case 'click':
        fieldInteraction = FieldInteraction.CLICK;
        break;

      case 'scratch':
        fieldInteraction = FieldInteraction.SCRATCH;
        break;
    }

    let quizAlign: QuizAlign | undefined;

    switch (data.quiz_align) {
      case 'left':
        quizAlign = QuizAlign.LEFT;
        break;

      case 'center':
        quizAlign = QuizAlign.CENTER;
        break;

      case 'right':
        quizAlign = QuizAlign.RIGHT;
        break;
    }

    let fieldAnimation: FieldAnimation = FieldAnimation.FADE;
    if (data.field_animation) {
      switch (data.field_animation) {
        case 'fade':
          fieldAnimation = FieldAnimation.FADE;
          break;

        case 'instant':
          fieldAnimation = FieldAnimation.INSTANT;
          break;
        case 'open':
          fieldAnimation = FieldAnimation.OPEN;
          break;

        default:
          fieldAnimation = FieldAnimation.FADE;
      }
    }

    // seems like old campaigns have "dd-mm-yyyy" format while newer campaigns has "yyyy-mm-dd" format
    // just to be sure we check for year placement to be sure we have the correct format - if the date is wrongly formatted, such as 01-02-2022, we reformat it to correct format
    let testDate;

    if (data.test.date) {
      testDate = data.test.date;
      const splitDateFormat = testDate.split('-');

      if (splitDateFormat && splitDateFormat[2].length === 4) {
        testDate = splitDateFormat.reverse().join('-');
      }
    }

    return {
      fieldInteraction,
      fieldAnimation,
      linkLabel: data.link_label,
      message: {
        loser: data.message.loser,
        winner: data.message.winner
      },
      quizAlign,
      answerAlignment: data.answer_alignment ?? AlignContentType.CENTER,
      showAnswerButton: data.show_answer_button === '1',
      showCorrectAnswer: data.show_correct_answer === '1', // 1 boolean
      ...(data.show_correct_answer === '1' && {
        showCorrectAnswerDelay: data.show_correct_answer_delay ? Number(data.show_correct_answer_delay) : 0
      }),
      scratchAreaSize: Number(data.scratch_area_size),
      showFieldPrizes: data.show_field_prizes === '1',
      ...(data.field_prize_headline && {
        fieldPrizeHeadline: data.field_prize_headline
      }),
      ...(data.remove_text_limit && {
        removeTextLimit: data.remove_text_limit === '1'
      }),
      test: {
        ...(testDate && {
          date: testDate
        }),
        enabled: data.test.enable === '1'
      },
      oneDoor: {
        enabled: data.one_door ?? false,
        ...(data.one_door_fallback && {
          oneDoorFallback: data.one_door_fallback
        })
      },
      activeStateColor: data.active_state_color,
      activeStateBackgroundColor: data.active_state_bg_color
    };
  }

  private static constructDateFormat = (string: string) => {
    const splitString = string.split('-');

    if (splitString[2].length === 4) {
      return `${splitString[2]}-${splitString[1]}-${splitString[0]}`;
    } else {
      return string;
    }
  };

  private static constructFieldsState(
    item: GameCalendarFieldsData,
    generalData: GameCalendarGeneralData,
    prizes: GameCalendarPrizesData[],
    layout: CalendarLayoutState | undefined
  ): GameCalendarFieldsState {
    let dateType: DateType | undefined;

    switch (item.date_type) {
      case '1':
        dateType = DateType.SINGLE;
        break;
      case '2':
        dateType = DateType.RANGE;
        break;
    }

    let actionType: FieldActionType = FieldActionType.QUIZ;

    switch (item.action_type) {
      case 'quiz':
        actionType = FieldActionType.QUIZ;
        break;
      case 'url':
        actionType = FieldActionType.URL;
        break;
      case 'goto_flow_page':
        actionType = FieldActionType.GOTO_FLOW_PAGE;
        break;
      case 'goto_campaign':
        actionType = FieldActionType.GOTO_CAMPAIGN;
        break;
    }

    const action: FieldActionState = {
      type: actionType,
      ...(item.action?.delay && {
        delay: Number(item.action.delay)
      }),
      ...(item.action?.flow_page && {
        flowPage: Number(item.action.flow_page)
      }),
      ...(item.action?.url && {
        url: item.action.url
      }),
      ...(item.action?.campaign_live_url && {
        campaignLiveUrl: item.action.campaign_live_url
      })
    };

    let dateFrom = '';
    let dateTo = '';

    if (item.date_from && item.date_to) {
      dateFrom = this.constructDateFormat(item.date_from);
      dateTo = this.constructDateFormat(item.date_to);
    } else if (Array.isArray(item.date)) {
      dateFrom = this.constructDateFormat(item.date[0]);
      dateTo = this.constructDateFormat(item.date[1]);
    } else {
      const getDate = this.constructDateFormat(item.date);
      dateFrom = getDate;
      dateTo = getDate;
    }

    const placementTypes = {
      desktop:
        item.placement.desktop && item.placement.desktop.w
          ? GameCalendarModel.parsePlacementDeviceData(item.placement.desktop)
          : undefined,
      tablet:
        item.placement.tablet && item.placement.tablet.w
          ? GameCalendarModel.parsePlacementDeviceData(item.placement.tablet)
          : undefined,
      mobile: item.placement.mobile ? GameCalendarModel.parsePlacementDeviceData(item.placement.mobile) : undefined
    };

    let placement = placementTypes.desktop;
    if (useDevice().isDesktop) {
      placement = placementTypes.desktop;
    }
    if (useDevice().isTablet) {
      placement = placementTypes.tablet || placementTypes.desktop;
    }
    if (useDevice().isMobile && !useDevice().isTablet) {
      placement = placementTypes.mobile;
    }

    // returning question and questions data as an  state array
    // instead of having an object for question and an array for questions
    const questionsItems = [];

    if (item.question) {
      questionsItems.push(GameCalendarModel.constructFieldQuestionState(item.question, generalData, prizes, item.id));
    }

    if (item.questions) {
      item.questions.forEach((question) => {
        questionsItems.push(GameCalendarModel.constructFieldQuestionState(question, generalData, prizes, item.id));
      });
    }

    let fieldTextLabel: string | undefined;

    if (item.desktop_label) {
      fieldTextLabel = item.desktop_label;
    }

    if (useDevice().isMobile) {
      fieldTextLabel = item.mobile_label;
    } else if (
      !useDevice().isMobile &&
      fieldTextLabel === undefined &&
      item.date_type === '1' &&
      typeof item.date === 'string'
    ) {
      fieldTextLabel = parseInt(item.date.substr(8), 10).toString();
    }

    return {
      fieldState: FieldStateType.NONE,
      action,
      date: {
        from: dateFrom,
        to: dateTo,
        type: dateType
      },
      doorSettings: GameCalendarModel.parseDoorImageData(item.door_settings),
      id: Number(item.id),
      ...(item.mobile_image &&
        layout &&
        layout.background?.type === LayoutMobileBackgroundType.IMAGE && {
          mobileImage: item.mobile_image
        }),

      image: item.image,

      ...(item.loser_on_incorrect_answer && {
        loserOnIncorrectAnswer: item.loser_on_incorrect_answer === '1'
      }),
      message: {
        loser: item.message?.loser,
        winner: item.message?.winner
      },
      ...(fieldTextLabel && {
        label: fieldTextLabel
      }),

      multipleQuestions: item.multiple_questions === '1',
      placement,
      questions: questionsItems,

      ...(item.correct_answers && {
        correctAnswers: Number(item.correct_answers)
      })
    };
  }

  private static parseDoorImageData(
    doorImage: DeviceData<GameCalendarFieldsDoorSettingsData>
  ): FieldDoorImage | undefined {
    if (doorImage) {
      const useData = getDeviceData<GameCalendarFieldsDoorSettingsData>(doorImage);

      if (!useData) {
        return undefined;
      }

      return GameCalendarModel.constructDoorImageState(useData);
    }
  }

  private static constructDoorImageState(data: GameCalendarFieldsDoorSettingsData): FieldDoorImage | undefined {
    return {
      ...(data.image && {
        image: data.image
      }),
      ...(data.image_front && {
        frontImage: data.image_front
      }),
      ...(data.image_front && {
        behindImage: data.image_behind
      })
    };
  }

  private static constructFieldQuestionState(
    item: GameCalendarFieldQuestionData,
    generalData: GameCalendarGeneralData,
    prizes: GameCalendarPrizesData[],
    fieldId: string
  ): FieldQuestionState {
    const answers: Array<FieldAnswerState> = [];
    const answerLayout = new AnswerLayoutSettingsModel(generalData);

    if (item.answer_1) {
      answers.push({
        id: 1,
        value: item.answer_1
      });
    }

    if (item.answer_2) {
      answers.push({
        id: 2,
        value: item.answer_2
      });
    }

    if (item.answer_3) {
      answers.push({
        id: 3,
        value: item.answer_3
      });
    }

    let videoPosition: VideoPosition | undefined;

    if (item.question_video?.position) {
      switch (item.question_video.position) {
        case 'left':
          videoPosition = VideoPosition.LEFT;
          break;
        case 'above':
          videoPosition = VideoPosition.ABOVE;
          break;
        case 'right':
          videoPosition = VideoPosition.RIGHT;
          break;
      }
    }

    const video: FieldVideoState = {
      enabled: item.question_video?.enable === '1',
      ...(videoPosition && {
        position: videoPosition
      }),
      ...(item.question_video?.url && {
        url: item.question_video.url
      }),
      ...(item.question_video?.width && {
        width: Number(item.question_video.width)
      })
    };

    let linkTarget: LinkTargetTypes = '_blank';
    let linkUrl: string | undefined;

    if (item.link) {
      linkUrl = formatUrl(item.link);
      if (linkUrl && linkUrl.split('#').length - 1 >= 2) {
        linkTarget = '_self';
      }
    }

    const link: FieldLinkState = {
      enabled: !!item.link,
      ...(!!item.link && {
        url: linkUrl,
        text: item.link_text ? item.link_text : generalData.link_label,
        target: linkTarget
      })
    };

    const correctAnswers: number[] = [];
    if (typeof item.correct_answer === 'string' && item.correct_answer !== '') {
      correctAnswers.push(parseInt(item.correct_answer.replace(/^\D+/g, ''), 10));
    }

    let contentPrizes;

    if (prizes.length > 0) {
      contentPrizes = prizes
        .map((item) => {
          return GameCalendarModel.constructPrizesContent(item, generalData);
        })
        .filter((item) => {
          return Number(fieldId) === item.fieldId;
        });
    }

    const prizeContent: CalendarPrizeContentObject[] = [];

    if (contentPrizes && contentPrizes.length > 0) {
      const prizes = contentPrizes.map((prize) => {
        return {
          id: prize.id.toString(),
          items: [prize]
        };
      });

      prizeContent.push({
        class: 'field-prizes',
        type: 'slider',
        config: {
          contentType: 'json'
        },
        content: prizes
      });
    }

    let behaviourType: BehaviorTypes = BehaviorTypes.COVER;
    let positionType: PositionTypes = PositionTypes.CENTER_CENTER;
    let tileRepeat;
    switch (item.background_behavior) {
      case 'center':
        behaviourType = BehaviorTypes.CENTER;
        break;
      case 'cover':
        behaviourType = BehaviorTypes.COVER;
        break;
      case 'contain':
        behaviourType = BehaviorTypes.CONTAIN;
        break;
      case 'stretch':
        behaviourType = BehaviorTypes.STRETCH;
        break;
      case 'tile':
        behaviourType = BehaviorTypes.TILE;
        tileRepeat = item.tile_repeat;
        break;
      case 'actual_size':
        behaviourType = BehaviorTypes.ACTUAL_SIZE;
        break;
    }

    switch (item.background_position) {
      case 'custom':
        positionType = PositionTypes.CUSTOM;
        break;
      case 'bottom_center':
        positionType = PositionTypes.BOTTOM_CENTER;
        break;
      case 'bottom_left':
        positionType = PositionTypes.BOTTOM_LEFT;
        break;
      case 'bottom_right':
        positionType = PositionTypes.BOTTOM_RIGHT;
        break;
      case 'center_center':
        positionType = PositionTypes.CENTER_CENTER;
        break;
      case 'center_left':
        positionType = PositionTypes.CENTER_LEFT;
        break;
      case 'center_right':
        positionType = PositionTypes.CENTER_RIGHT;
        break;
      case 'top_center':
        positionType = PositionTypes.TOP_CENTER;
        break;
      case 'top_left':
        positionType = PositionTypes.TOP_LEFT;
        break;
      case 'top_right':
        positionType = PositionTypes.TOP_RIGHT;
        break;
    }

    /**
     * only assign mobile values if overwrite mobile is false else ignore mobile values and assign default object.
     * This functionality is much like the check we have in getDeviceData, however
     * we have pulled this functionality in locally in this model as the data in this
     * is formatted differently, so we couldn't make a 100% use of getDeviceData
     */

    let backgroundImage: GameCalendarSectionImageOverlay = {
      enabled: !!item.background_image,
      src: item.background_image,
      behavior: behaviourType,
      position: positionType,
      ...(tileRepeat && { tileRepeat }),
      overlay: item.background_overlay,
      onFeedback: item.background_feedback_enabled === '1'
    };

    const { isMobile } = useDevice();
    const overwriteMobile = typeof item.overwrite_mobile === 'undefined' || item.overwrite_mobile === '1';
    if (isMobile && !overwriteMobile) {
      backgroundImage = {
        enabled: !!item.mobile.background_image,
        src: item.mobile.background_image,
        behavior: this.getBackgroundBehavior(item.mobile.background_behavior),
        position: this.getBackgroundPosition(item.mobile.background_position),
        overlay: item.mobile.background_overlay,
        onFeedback: item.mobile.background_feedback_enabled === '1'
      };
    }

    return {
      answers,
      ...(correctAnswers && {
        correctAnswers
      }),
      answerLayout,
      ...(prizeContent.length > 0 && {
        prizeContent
      }),
      id: Number(item.id),
      link,
      video: {
        ...video
      },
      ...(generalData.field_prize_headline &&
        generalData.field_prize_headline !== '' && {
          prizeText: '<h4 class="content__item-prizes-headline">' + generalData.field_prize_headline + '</h4>'
        }),
      backgroundImage,
      answerColor: {
        correct: generalData.correct_answer_color ? generalData.correct_answer_color : '#68bb5a',
        wrong: generalData.wrong_answer_color ? generalData.wrong_answer_color : '#bb5a80'
      },
      question: item.question,
      answerAsImage: item.type_image === '1'
    };
  }

  private static parsePlacementDeviceData(data: GameCalendarFieldsPlacementData): FieldPlacementState {
    const deviceData = data;

    return {
      h: deviceData.h,
      w: deviceData.w,
      x: deviceData.x,
      y: deviceData.y
    };
  }

  private static getBackgroundPosition(data?: CalenderBackgroundPositionType) {
    let positionType: PositionTypes | undefined;

    switch (data) {
      case 'custom':
        positionType = PositionTypes.CUSTOM;
        break;
      case 'bottom_center':
        positionType = PositionTypes.BOTTOM_CENTER;
        break;
      case 'bottom_left':
        positionType = PositionTypes.BOTTOM_LEFT;
        break;
      case 'bottom_right':
        positionType = PositionTypes.BOTTOM_RIGHT;
        break;
      case 'center_left':
        positionType = PositionTypes.CENTER_LEFT;
        break;
      case 'center_right':
        positionType = PositionTypes.CENTER_RIGHT;
        break;
      case 'top_center':
        positionType = PositionTypes.TOP_CENTER;
        break;
      case 'top_left':
        positionType = PositionTypes.TOP_LEFT;
        break;
      case 'top_right':
        positionType = PositionTypes.TOP_RIGHT;
        break;
      default:
        positionType = PositionTypes.CENTER_CENTER;
        break;
    }
    return positionType;
  }

  private static getBackgroundBehavior(data?: CalenderBackgroundBehaviorType) {
    let behaviourType: BehaviorTypes | undefined;

    switch (data) {
      case 'contain':
        behaviourType = BehaviorTypes.CONTAIN;
        break;
      case 'stretch':
        behaviourType = BehaviorTypes.STRETCH;
        break;
      case 'tile':
        behaviourType = BehaviorTypes.TILE;
        break;
      case 'actual_size':
        behaviourType = BehaviorTypes.ACTUAL_SIZE;
        break;
      default:
        behaviourType = BehaviorTypes.COVER;
        break;
    }
    return behaviourType;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }
}
