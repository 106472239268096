import type { Component } from 'vue';
import type { IntegrationJwtData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationJwtState extends BaseIntegrationState {
  fieldsMapped: number[];
  parameterName?: string;
}

export class IntegrationJwtModel extends BaseIntegration<IntegrationJwtData, IntegrationJwtState> {
  parse(data: IntegrationJwtData) {
    super.parse(data);

    this.state.fieldsMapped = data.settings?.jwt_fields_mapped ?? [];
    this.state.parameterName = data.settings?.jwt_parameter_name;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/jwt/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }
}
