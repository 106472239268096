<template>
  <div v-show="isReady" class="preloader">
    <component is="style" type="text/css">
      {{ effectStyle }}
      {{ preloaderStyle }}
    </component>
    <div v-if="data.image" class="preloader__custom">
      <div :style="customPreloaderStyles"></div>
    </div>
    <div v-else>
      <div class="preloader__default">
        <span class="preloader__default-inner"></span>
      </div>
      <div class="preloader__dots">
        <span class="preloader__dot"></span>
        <span class="preloader__dot"></span>
        <span class="preloader__dot"></span>
      </div>

      <div class="preloader__lf">
        <div class="preloader__lf-dots">
          <div class="preloader__lf-dot"></div>
          <div class="preloader__lf-dot"></div>
        </div>

        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                result="goo"
              />
              <feBlend in2="goo" in="SourceGraphic" result="mix" />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
    <div class="preloader__overlay" :style="{ backgroundColor: data.overlay?.color }"></div>
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType } from 'vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import type { PreloaderState } from '@/src/models/CampaignModel';
import { preloadImagePromise } from '@/src/utilities/Utilities';

export default defineComponent({
  name: 'Preloader',
  props: {
    data: {
      type: Object as PropType<PreloaderState>,
      default: () => {
        return {};
      }
    }
  },
  setup(props) {
    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });
    const isReady = ref(false);
    const preloaderStyle = computed(() => {
      if (props.data.color) {
        return `
        .site > .preloader .preloader__default .preloader__default-inner:after {
          border-color: ${props.data.color};
        }
      `;
      }
      return undefined;
    });
    const image = ref<HTMLImageElement | undefined>();

    const effectStyle = computed(() => {
      if (props.data?.effect) {
        switch (props.data.effect) {
          case 'bounce':
            return `.site .preloader .preloader__custom {
              animation: bounce 500ms cubic-bezier(0.64, 0.12, 0.79, 0.35) infinite alternate;
            }`;
          case 'pulse':
            return `.site .preloader .preloader__custom {
              animation: pulse 1500ms ease-out infinite;
            }`;

          case 'rotate':
            return `.site .preloader .preloader__custom {
             animation: rotate 2000ms linear infinite;
            }`;

          default:
            break;
        }
      }
      return undefined;
    });

    const preloadImageStyle = computed(() => {
      let maxWidth: string | undefined;

      if (props.data?.keepSize) {
        maxWidth = '100%';
      }
      if (!props.data?.keepSize) {
        maxWidth = '300px';
      }

      return {
        'max-width': maxWidth
      };
    });

    const customPreloaderStyles = computed<CSSProperties>(() => {
      const sizeWidth = image.value?.naturalWidth ? image.value.naturalWidth : 100;
      let backgroundSize: string | undefined;

      if (sizeWidth < 300 || props.data?.keepSize) {
        backgroundSize = `${sizeWidth}px`;
      }

      return {
        backgroundImage: `url(${props.data.image})`,
        ...(backgroundSize && {
          backgroundSize
        }),
        ...(props.data.keepSize && { width: `${image.value?.naturalWidth}px` }),
        ...(props.data.keepSize && { height: `${image.value?.naturalHeight}px` })
      };
    });

    onMounted(async () => {
      if (props.data.image) {
        try {
          image.value = await preloadImagePromise(props.data.image);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Image preload failed:', error);
        }
      }
      if (readyPromiseResolve) {
        readyPromiseResolve();
      }
      isReady.value = true;
    });

    return {
      isReady,
      customPreloaderStyles,
      effectStyle,
      preloaderStyle,
      preloadImageStyle,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>

<style lang="scss">
.preloader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  pointer-events: none;

  &__overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    background: $white;
  }

  &__default {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    height: 6rem;
    width: 6rem;
    margin-top: -3rem;
    margin-left: -3rem;
    animation: loader 3s linear infinite;

    &-inner {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 5rem;
      width: 5rem;
      clip: rect(2.5rem, 5rem, 5rem, 0);
      animation: loader-inner 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 5rem;
        width: 5rem;
        border: 5px solid #fff;
        border-radius: 50%;
      }
    }
  }

  &__dots {
    display: none;
    position: absolute;
    width: 8rem;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 0;
    line-height: 0;
    z-index: 1;
  }

  &__dot {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
    background: $black;
    border-radius: 50%;
    animation: dotBounce 1400ms infinite ease-in-out both;

    &:first-child,
    &:last-child {
      margin: 0;
    }

    &:nth-child(1) {
      animation-delay: -320ms;
    }

    &:nth-child(2) {
      animation-delay: -160ms;
    }
  }

  &__lf {
    display: none;
    position: absolute;
    width: 8rem;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;

    &-dots {
      filter: url('#goo');
    }

    &-dot {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: $black;

      &:first-child {
        animation: lf-dot-left cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;
      }

      &:last-child {
        animation: lf-dot-right cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;
      }
    }
  }

  &__custom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    div {
      width: 30rem;
      height: 30rem;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
