import type { SurveyAligmentType, SurveyFontType } from './Types';
import { AnswerType, NumbersPosition, SurveyAlignment } from './Types';
import type { GameIndicator, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GameSurveyAdvancedData,
  GameSurveyData,
  GameSurveyGeneralData,
  GameSurveyQuestionData
} from '@/src/components/games/survey/Data';
import type { TransitionTypes } from '@/src/typings/types/types';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';

export enum SurveyType {
  STANDARD,
  RANDOMIZE
}

export interface GameSurveyGeneralState {
  surveyType: SurveyType;
  conditional?: boolean;
  contentAlign: SurveyAlignment;
}

export enum AnswerActionType {
  QUESTION,
  FLOW_PAGE,
  FIRST_PAGE_AFTER,
  NEXT_QUESTION
}

interface QuestionAnswerAction {
  // type can be "flow_page" or one of the questions
  // since we don't know how many questions there are, we leave it as a text field instead of an enum
  type: AnswerActionType;
  flowPage?: number;
  questionIndex?: number;
}

export interface GameSurveyQuestionAnswerState {
  id?: number; // comment has no id which makes its optional
  value?: number | string;
  title?: string;
  action?: QuestionAnswerAction;
}

interface QuestionNumbers {
  items?: number[];
  position: NumbersPosition;
}

export interface GameSurveyAnswerLayout {
  activeSmileyColor?: string;
  activeStateColor?: string;
  activeStateBgColor?: string;
  alignment?: SurveyAligmentType;
  color?: string;
  fontType?: SurveyFontType;
  margin?: number;
  padding?: number;
  bulletsCheckedColor?: string;
  bulletsColor?: string;
  smileyColor?: string;
  width?: string;
}

export interface GameSurveyQuestionState {
  answerType: AnswerType;
  answers?: GameSurveyQuestionAnswerState[];
  comments?: {
    enable: boolean;
    label?: string;
  };
  id: number;
  optional: boolean;
  question: string;
  defaultAnswer?: number;
  maxAnswers?: number;
  minAnswers?: number;
  minAnswersValidationMessage?: string;
  maxAnswersValidationMessage?: string;
  stars: {
    enabled: boolean;
    number: number;
    highEnd?: string;
    lowEnd?: string;
  };
  allAnswersMandatory?: boolean;
  sliderRotation?: boolean;
  numbers?: QuestionNumbers;
  answerLayout: GameSurveyAnswerLayout;
}

export interface GameSurveyAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GameSurveyAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

export interface GameSurveyAdvancedState {
  sound: GameSurveyAdvancedSoundState;
  animation: GameSurveyAdvancedAnimationState;
}

export interface GameSurveyLabelsState {
  answerBtn: string;
  answerBtnLast: string;
  selectAnswerBtn: string;
  skipAnswer: string;
}

export interface GameSurveyState {
  general: GameSurveyGeneralState;
  labels: GameSurveyLabelsState;
  questions: GameSurveyQuestionState[];
  advanced: GameSurveyAdvancedState;
}

export class GameSurveyModel extends GameModel<GameSurveyData, GameSurveyState> implements HasSound {
  parseGame(data: GameSurveyData): void {
    const state = this.state;
    state.general = GameSurveyModel.constructGeneralState(data.general);
    state.questions = data.questions.questions.map<GameSurveyQuestionState>((item) => {
      return this.constructQuestionState(item, data.general);
    });

    state.labels = state.labels ?? {};
    state.labels.answerBtn = data.general.answer_btn;
    state.labels.selectAnswerBtn = data.general.select_answer_btn;
    state.labels.answerBtnLast = data.general.answer_btn_last;
    state.labels.skipAnswer = data.general.skip_answer_btn;
    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameSurveyModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GameSurveyModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructAdvancedSoundState(data: GameSurveyAdvancedData | undefined): GameSurveyAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructAdvancedAnimationState(
    data: GameSurveyAdvancedData | undefined
  ): GameSurveyAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private constructQuestionState(
    item: GameSurveyQuestionData,
    generalData: GameSurveyGeneralData
  ): GameSurveyQuestionState {
    let answers: GameSurveyQuestionAnswerState[] = [];
    if (item.answers && item.answers.length > 0) {
      answers =
        item.answers?.map<GameSurveyQuestionAnswerState>((answer) => {
          let action: QuestionAnswerAction | undefined;
          const answerId = Number(answer.id);

          if (item.answers_action) {
            let actionType = AnswerActionType.NEXT_QUESTION;
            let actionFlowPage: number | undefined;
            let actionQuestionIndex: number | undefined;

            // eslint-disable-next-line security/detect-object-injection
            if (item.answers_action[answerId] === 'flow_page') {
              // eslint-disable-next-line security/detect-object-injection
              if (item.answers_action_page && item.answers_action_page[answerId]) {
                actionType = AnswerActionType.FLOW_PAGE;
                // eslint-disable-next-line security/detect-object-injection
                actionFlowPage = Number(item.answers_action_page[answerId]);
              } else {
                actionType = AnswerActionType.FIRST_PAGE_AFTER;
              }
              // eslint-disable-next-line security/detect-object-injection
            } else if (item.answers_action[answerId].includes('question')) {
              actionType = AnswerActionType.QUESTION;
              // eslint-disable-next-line security/detect-object-injection
              actionQuestionIndex = Number(item.answers_action[answerId].replace('question_', '')) - 1;
            }

            action = {
              type: actionType,
              ...(actionFlowPage && { flowPage: actionFlowPage }),
              ...(actionQuestionIndex && { questionIndex: actionQuestionIndex })
            };
          }

          return {
            id: answerId,
            title: answer.title,
            ...(action && { action })
          };
        }) || [];
    }

    let answerType: AnswerType;

    switch (item.answer_type) {
      case 'multi':
        answerType = AnswerType.MULTI;
        break;
      case 'stars':
        answerType = AnswerType.STARS;
        break;
      case 'comment':
        answerType = AnswerType.COMMENT;
        break;
      case 'slider':
        answerType = AnswerType.SLIDER;
        break;
      case 'smiles':
        answerType = AnswerType.SMILES;
        break;
      case 'numbers':
        answerType = AnswerType.NUMBERS;
        break;
      case 'nps':
        answerType = AnswerType.NPS;
        break;
      default:
        answerType = AnswerType.SINGLE;
    }

    let numbersData: QuestionNumbers | undefined;

    if (item.numbers_position && (answerType === AnswerType.NPS || answerType === AnswerType.NUMBERS)) {
      let numbersPosition: NumbersPosition;
      switch (item.numbers_position) {
        case 'vertical':
          numbersPosition = NumbersPosition.VERTICAL;
          break;
        default:
          numbersPosition = NumbersPosition.HORIZONTAL;
      }

      let items: number[] | undefined;
      switch (answerType) {
        case AnswerType.NUMBERS:
          if (item.numbers_num) {
            items = Array.from({ length: Number(item.numbers_num) }, (_value, index) => index + 1);
          }
          break;
        case AnswerType.NPS:
          items = Array.from(Array(11).keys());
          break;
      }

      numbersData = {
        ...(items && { items }),
        position: numbersPosition
      };
    }

    return {
      answerType,
      answers,
      comments: {
        enable: item.comments?.enable === '1',
        ...(item.comments?.label && {
          label: item.comments.label
        })
      },
      id: Number(item.id),
      optional: item.optional === '1',
      question: item.question,
      ...(item.default_answer && {
        defaultAnswer: Number(item.default_answer)
      }),
      ...(item.max_answers && {
        maxAnswers: Number(item.max_answers)
      }),
      ...(item.min_answers && {
        minAnswers: Number(item.min_answers)
      }),
      ...(item.min_answers_validation_message && {
        minAnswersValidationMessage: item.min_answers_validation_message
      }),
      ...(item.max_answers_validation_message && {
        maxAnswersValidationMessage: item.max_answers_validation_message
      }),
      stars: {
        enabled: !!item.stars_num,
        number: Number(item.stars_num),
        ...{ highEnd: item.high_end },
        ...{ lowEnd: item.low_end }
      },
      ...(item.vote2all && {
        allAnswersMandatory: item.vote2all === '1'
      }),
      ...(item.slider_rotation && {
        sliderRotation: item.slider_rotation === '1'
      }),
      ...(numbersData && { numbers: numbersData }),
      answerLayout: {
        ...(generalData.active_smiles_color && { activeSmileyColor: generalData.active_smiles_color }),
        ...(generalData.active_state_color && { activeStateColor: generalData.active_state_color }),
        ...(generalData.answer_alignment && { alignment: generalData.answer_alignment }),
        ...(generalData.answer_color && { color: generalData.answer_color }),
        ...(generalData.answer_font_type && { fontType: generalData.answer_font_type }),
        ...(generalData.answer_margin && { margin: Number(generalData.answer_margin) }),
        ...(generalData.answer_padding && { padding: Number(generalData.answer_padding) }),
        ...(generalData.bullets_checked_color && { bulletsCheckedColor: generalData.bullets_checked_color }),
        ...(generalData.bullets_color && { bulletsColor: generalData.bullets_color }),
        ...(generalData.smiles_color && { smileyColor: generalData.smiles_color }),
        ...(generalData.width && { width: generalData.width })
      }
    };
  }

  private static constructGeneralState(data: GameSurveyGeneralData): GameSurveyGeneralState {
    let surveyType: SurveyType | undefined;

    switch (data.survey_type) {
      case 'standard':
        surveyType = SurveyType.STANDARD;
        break;
      case 'randomize':
        surveyType = SurveyType.RANDOMIZE;
        break;
    }

    let contentAlign: SurveyAlignment | undefined;

    switch (data.content_align) {
      case 'left':
        contentAlign = SurveyAlignment.LEFT;
        break;
      case 'right':
        contentAlign = SurveyAlignment.RIGHT;
        break;
      default:
        contentAlign = SurveyAlignment.CENTER;
        break;
    }

    return {
      ...(data.conditional && { conditional: data.conditional === '1' }),
      surveyType,
      contentAlign
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
