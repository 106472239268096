export enum SwipeLayoutTypes {
  ONLY_IMAGE = 'only_image',
  DEFAULT = 'default'
}

export enum SwipeMessageTypes {
  IMAGE_SIZE = 'swipeitImageSize',
  MESSAGE_TOP = 'swipeitMessageTop',
  HIDE_CARD = 'hideCard',
  CARD_ACCEPTED = 'cardAccepted',
  CARD_REJECTED = 'cardRejected'
}

export enum SwipeDescriptionPositionTypes {
  BOTTOM = 'bottom',
  TOP = 'top'
}

export enum SwipedPosition {
  Left = 'left',
  Right = 'right'
}

export enum GameSwipeItButtonsType {
  ARROWS = 'arrows',
  THUMBS = 'thumbs',
  CHECKMARK = 'checkmark',
  HEART = 'heart',
  CUSTOM = 'custom'
}
