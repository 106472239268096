import type { RegistrationApiResponse } from '@/src/components/addons/registration/types';
import { FormDisableDefaultBehavior } from '@/src/exceptions/FormDisableDefaultBehavior';
import { events } from '@/src/services/events';
import type { SectionModelType } from '@/src/typings/types/types';

interface EventData {
  pageModel: SectionModelType;
  response: RegistrationApiResponse;
}

export type FormSubmitResponseEvent = CustomEvent<EventData>;
export const EmitFormSubmitResponseEvent = async (
  pageModel: SectionModelType,
  response: RegistrationApiResponse
): Promise<FormSubmitResponseEvent> => {
  const event = new CustomEvent<EventData>('formSubmitResponse', {
    detail: {
      pageModel,
      response
    },
    cancelable: true
  });

  events.emit('formSubmitResponse', event);
  if (event.defaultPrevented) {
    throw new FormDisableDefaultBehavior(
      'Prevent default behavior for form submit, was prevented in formSubmitResponse SDK event'
    );
  }

  return event;
};
