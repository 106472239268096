import type {
  GameShootItAdvancedData,
  GameShootItData,
  GameShootItGeneralData,
  GameShootItImagesSymbolData
} from '@/src/components/games/shootit/Data';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { transformMeasurementToNumber } from '@/src/utilities/Utilities';
import type { TransitionTypes } from '@/src/typings/types/types';

interface GameShootItDimensionsState {
  canvasHeight: number;
  canvasHeightIsViewPort: boolean;
  shooterOffsetY: number;
  shootSpeed: number;
  spawnSpeed: number;
  shooterWidth: number;
  dropWidth: number;
  overwriteMobile?: boolean;
}

interface GameShootItGeneralState {
  scoreToWin?: number;
  colorPoint?: string;
  colorShot?: string;
  dimensions: GameShootItDimensionsState;
  enableBestTime?: boolean;
  enableTimeRanking?: boolean;
}

export interface GameShootItImageSymbolState {
  image: string;
  points: number;
  health: number;
  speed: number;
  rotation: number;
  label: string;
  id: number;
  height?: number;
  width?: number;
}

export interface GameShootItAdvancedSoundState {
  enabled: boolean;
  plusSound?: string;
  minusSound?: string;
}

export interface GameShootItAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

export interface GameShootItAdvancedState {
  sound: GameShootItAdvancedSoundState;
  animation: GameShootItAdvancedAnimationState;
}

export interface GameShootItState {
  general: GameShootItGeneralState;
  elementStyling: {
    animationClass?: string;
  };
  images: {
    shooterImage: string;
    shootImage: string;
    symbol: GameShootItImageSymbolState[];
  };
  advanced: GameShootItAdvancedState;
  indicators: GameIndicatorSettingsModel;
}

export class GameShootItModel
  extends GameModel<GameShootItData, GameShootItState>
  implements HasGameTimeChallenge, HasSound
{
  parseGame(data: GameShootItData) {
    const state = this.state;
    state.general = GameShootItModel.constructGeneralState(data.general);

    state.images = state.images || {};
    if (data.images.shooter_image) {
      state.images.shooterImage = data.images.shooter_image;
    }

    state.images.shootImage = data.images.shoot_image ? data.images.shoot_image : '';

    state.elementStyling = state.elementStyling ?? {};

    state.images.symbol = [];

    data.images.symbol.reverse().forEach((symbol) => {
      state.images.symbol.push(GameShootItModel.constructImagesState(symbol));
    });

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameShootItModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GameShootItModel.constructAdvancedAnimationState(data?.advanced);

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    }

    if (state.advanced.animation.enabled && state.advanced.animation.type) {
      state.elementStyling.animationClass = `animated ${state.advanced.animation.type}`;
    }
  }

  private static constructAdvancedAnimationState(
    data: GameShootItAdvancedData | undefined
  ): GameShootItAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private static constructAdvancedSoundState(data: GameShootItAdvancedData | undefined): GameShootItAdvancedSoundState {
    return {
      enabled: !!data?.plus_sound || !!data?.minus_sound,
      ...(data?.minus_sound && { minusSound: data.minus_sound }),
      ...(data?.plus_sound && { plusSound: data.plus_sound })
    };
  }

  private static constructImagesState(data: GameShootItImagesSymbolData): GameShootItImageSymbolState {
    return {
      id: data.id,
      health: data.health,
      image: data.image,
      label: data.label,
      points: data.points,
      rotation: data.rotation,
      speed: data.speed
    };
  }

  public static parseDimensionsDeviceData(data: GameShootItGeneralData): GameShootItDimensionsState {
    const dimensionsData = getDeviceData(data.dimensions);

    return {
      canvasHeight: dimensionsData?.canvas_height ? transformMeasurementToNumber(dimensionsData.canvas_height) : 0,
      canvasHeightIsViewPort: !!dimensionsData?.canvas_height?.includes('vh'),
      shooterOffsetY: (data.dimensions.default ?? data.dimensions.desktop)?.shooter_offset_y ?? 0,
      shootSpeed: dimensionsData?.shoot_speed ?? 125,
      spawnSpeed: dimensionsData?.spawn_speed ?? 200,
      shooterWidth: dimensionsData?.shooter_width ?? 75,
      dropWidth: dimensionsData?.drop_width ?? 50
    };
  }

  private static constructGeneralState(data: GameShootItGeneralData): GameShootItGeneralState {
    return {
      ...(data?.score_towin && { scoreToWin: data.score_towin }),
      ...(data?.color_point && { colorPoint: data.color_point }),
      ...(data?.color_shot && { colorShot: data.color_shot }),
      ...(data?.enable_best_time && {
        enableBestTime: data.enable_best_time === '1'
      }),
      ...(data?.enable_time_ranking && {
        enableTimeRanking: data.enable_time_ranking === '1'
      }),

      dimensions: GameShootItModel.parseDimensionsDeviceData(data)
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit ?? 0,
          time_used: 1
        }
      },
      {
        indicatorKey: 'score',
        metricKey: {
          score_used: 'score'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          score_used: 0
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.minusSound) {
      sounds.push(this.state.advanced.sound.minusSound);
    }

    if (this.state.advanced.sound.plusSound) {
      sounds.push(this.state.advanced.sound.plusSound);
    }

    return sounds;
  }
}
