import type {
  AdvancedAddonsStyleData,
  BorderData,
  BoxShadowData
} from '@/src/typings/interfaces/data/settings/settings';
import type { AlignContentType, ImageSizeType, TransitionEnterType } from '@/src/typings/enums/enums';
import type { ActionData } from '@/src/typings/interfaces/data/settings/actions';
import type { DeviceData } from '@/src/hooks/useDevice';

export interface LayoutSettingsData {
  alignment: AlignContentType;
}

export interface ImageLayoutFilterData {
  opacity: string;
  blur: string;
  brightness: string;
  contrast: string;
  saturation: string;
  hue: string;
  enter?: TransitionEnterType;
  enter_duration?: string;
}

export interface ImageFilters {
  normal?: ImageLayoutFilterData;
  hover?: ImageLayoutFilterData;
}

export enum ImageSourceType {
  REPLACEMENT_TAG = 'use_replacement_tag',
  BROWSE = 'browse'
}

export interface AddonLfImageData {
  alias: string;
  icon: 'icon-addon-image';
  settings: {
    source?: string;
    source_type?: ImageSourceType;
    replacement_tag?: string;
    alt: string;
    size?: ImageSizeType;
    width?: string;
    height?: string;
    action: ActionData;
    layout: {
      settings: DeviceData<LayoutSettingsData>;
      filters: ImageFilters;
      border: DeviceData<BorderData>;
      shadow: DeviceData<BoxShadowData>;
    };
    advanced?: AdvancedAddonsStyleData;
  };
}
