import { getQueryParams } from '../utilities/Url';
import { useUtilityStore } from '@/src/store/utility';
import { useCampaignStore } from '@/src/store/campaign';
import useFlow from '@/src/hooks/useFlow';
import { sendGameStateEvent } from '@/src/utilities/iFrameUtils';
import { submitForm } from '@/src/utilities/Registration';

let endingGame = false;

export async function endGame(
  won: boolean,
  actionPage: number | undefined = undefined,
  preventRedirect: boolean | undefined = false
): Promise<void> {
  if (endingGame) {
    return Promise.reject(new Error('endGame already in progress'));
  }

  const utilityStore = useUtilityStore();
  const params = getQueryParams();
  const isDemo = (utilityStore.url ?? window.location.href).includes('/campaign/view/demo');

  // If emulation device is in the header, and size is defined, then it's a previewer which cannot be interacted with.
  // In these instances it should never end the game.
  if (isDemo && params.device && params.size) {
    return Promise.reject(new Error('endGame cannot be called when previewing'));
  }

  endingGame = true;

  const campaignStore = useCampaignStore();

  campaignStore.gameEnded = true;
  campaignStore.gameWinner = won;

  utilityStore.addBodyClass(won ? 'current-flow-winner' : 'current-flow-loser');

  const currentFlowId = campaignStore.flowId;
  const flowRegistrationInfo = campaignStore.flowRegistrationInfo;
  const canSave = flowRegistrationInfo?.id || campaignStore.model?.state.config?.ghostUser;

  if (currentFlowId && canSave) {
    const flowPage = campaignStore.model?.state.flowPages.find((page) => page.id === currentFlowId);

    if (flowPage) {
      await submitForm(flowPage, true);
    }
  }

  sendGameStateEvent();

  // Unlock the endGame hook after 1 second of already successfully ending the game.
  // This is useful for games that allows for the result to be re-submitted.
  setTimeout(() => {
    endingGame = false;
  }, 1000);

  if (preventRedirect) {
    return;
  }

  if (actionPage) {
    useFlow().makeSpecificFlowPageActive(actionPage);
    return;
  }

  useFlow().goToNextFlowPage();
}

export default endGame;
