import type { IntegrationLidlProductsData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

interface ProductsItemState {
  description: string;
  id: number;
  image: string;
  limit: number;
  name: string;
  productsLeft: number;
}

export interface IntegrationLidlProductsState extends BaseIntegrationState {
  outOfStockMessage?: string;
  products: {
    enabled: boolean;
    items: ProductsItemState[];
  };
}

export class IntegrationLidlProductsModel extends BaseIntegration<
  IntegrationLidlProductsData,
  IntegrationLidlProductsState
> {
  parse(data: IntegrationLidlProductsData) {
    super.parse(data);

    this.state.products = this.state.products ?? { enabled: false, items: [] };
    this.state.outOfStockMessage = data.settings?.out_of_stock_message;
    this.state.products.items =
      data.settings?.products.map<ProductsItemState>((item) => {
        return {
          description: item.description,
          id: item.id,
          image: item.image,
          limit: Number(item.limit),
          name: item.name,
          productsLeft: item.products_left
        };
      }) || [];
    this.state.products.enabled = !!data.settings?.products_enabled;
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }
}
