<template>
  <svg class="game-indicator-item__icon-path" width="33px" height="45px" viewBox="0 0 33 45">
    <defs>
      <polygon id="path-1" points="0 0.798 17.702 0.798 17.702 45 0 45" />
    </defs>

    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-1" />
      </mask>

      <path
        id="Fill-1"
        d="M1.5,9.6484 C1.5,5.5954 4.798,2.2974 8.851,2.2974 C12.904,2.2974 16.202,5.5954 16.202,9.6484 C16.202,13.7014 12.904,16.9994 8.851,16.9994 C4.798,16.9994 1.5,13.7014 1.5,9.6484 M9.601,42.3804 L9.601,18.4614 C14.131,18.0784 17.702,14.2754 17.702,9.6484 C17.702,4.7684 13.731,0.7974 8.851,0.7974 C3.971,0.7974 0,4.7684 0,9.6484 C0,14.2754 3.571,18.0784 8.101,18.4614 L8.101,44.2504 C8.101,44.6644 8.437,45.0004 8.851,45.0004 C8.896,45.0004 8.937,44.9814 8.98,44.9734 C8.982,44.9734 8.984,44.9744 8.986,44.9744 C9.019,44.9744 9.049,44.9584 9.081,44.9534 C9.102,44.9464 9.126,44.9474 9.146,44.9394 C9.307,44.9034 9.462,44.8324 9.573,44.6924 L10.508,43.5194 C10.766,43.1954 10.712,42.7234 10.389,42.4644 C10.157,42.2824 9.852,42.2624 9.601,42.3804"
        fill="#FFFFFF"
        mask="url(#mask-2)"
      />
    </g>

    <path
      id="Fill-4"
      d="M13.1914,37.9462 L11.2624,40.3662 C11.0044,40.6902 11.0584,41.1622 11.3814,41.4212 C11.5204,41.5302 11.6854,41.5842 11.8494,41.5842 C12.0694,41.5842 12.2884,41.4872 12.4364,41.3012 L14.3654,38.8822 C14.6234,38.5572 14.5694,38.0862 14.2464,37.8272 C13.9214,37.5722 13.4514,37.6222 13.1914,37.9462"
      fill="#FFFFFF"
    />

    <path
      id="Fill-6"
      d="M17.0488,33.1054 L15.1198,35.5254 C14.8618,35.8494 14.9158,36.3214 15.2388,36.5804 C15.3778,36.6894 15.5428,36.7434 15.7068,36.7434 C15.9268,36.7434 16.1458,36.6464 16.2938,36.4604 L18.2228,34.0414 C18.4808,33.7164 18.4268,33.2454 18.1038,32.9864 C17.7788,32.7304 17.3078,32.7814 17.0488,33.1054"
      fill="#FFFFFF"
    />

    <path
      id="Fill-8"
      d="M24.6104,29.997 C21.2164,29.997 18.4564,27.236 18.4564,23.843 C18.4564,20.449 21.2164,17.687 24.6104,17.687 C28.0044,17.687 30.7654,20.449 30.7654,23.843 C30.7654,27.236 28.0044,29.997 24.6104,29.997 M24.6104,16.187 C20.3894,16.187 16.9564,19.622 16.9564,23.843 C16.9564,26.211 18.0384,28.332 19.7324,29.736 L18.9774,30.684 C18.7194,31.009 18.7734,31.48 19.0964,31.739 C19.2354,31.849 19.4004,31.902 19.5644,31.902 C19.7844,31.902 20.0034,31.806 20.1514,31.62 L20.9804,30.579 C22.0614,31.164 23.2984,31.497 24.6104,31.497 C28.8314,31.497 32.2654,28.063 32.2654,23.843 C32.2654,19.622 28.8314,16.187 24.6104,16.187"
      fill="#FFFFFF"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CreditsIndicator'
});
</script>
