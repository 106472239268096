import { BaseModel } from '@/src/models/BaseModel';
import type { TransitionTypes } from '@/src/typings/types/types';

export interface GameAdvancedSettingsData {
  sound?: string;
  plus_sound?: string;
  minus_sound?: string;
  animation?: TransitionTypes;
}

export interface GameAdvancedSettingsState {
  sound?: string;
  plusSound?: string;
  minusSound?: string;
  animation?: TransitionTypes;
}

export class GameAdvancedSettingsModel extends BaseModel<GameAdvancedSettingsData, GameAdvancedSettingsState> {
  parse(data: GameAdvancedSettingsData) {
    const state = this.state;

    if (data?.animation) {
      state.animation = data.animation;
    }
    if (data?.sound) {
      state.sound = data.sound;
    }
    if (data?.minus_sound) {
      state.minusSound = data.minus_sound;
    }
    if (data?.plus_sound) {
      state.plusSound = data.plus_sound;
    }
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
