import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export interface FormElementMaskSettingsData extends BaseFormElementSettingsData {
  date_format?: string;
}

export interface FormElementMaskData extends BaseFormElementData {
  settings?: FormElementMaskSettingsData;
}

export interface FormElementMaskState extends BaseFormElementState<string> {
  dateFormat?: string;
}

export class FormElementMaskModel extends BaseFormElementModel<string, FormElementMaskData, FormElementMaskState> {
  parseFormElement(data: FormElementMaskData) {
    this.state.dateFormat = data.settings?.date_format ?? '99-99-9999';
  }

  getInitialValue(): string | undefined {
    return this.getInitialStringValue();
  }

  parseStringValue(value: string): string | undefined {
    return value;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value && this.state.value !== '' ? this.state.value : undefined;
  }

  getSerializedPostValue(): string {
    return (this.state.value ?? '').replace(/-/g, '');
  }

  getSerializedCookieValue(): string {
    return this.state.value ?? '';
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
