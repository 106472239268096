import type { Component } from 'vue';
import type { IntegrationQiagenData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationQiagenState extends BaseIntegrationState {
  type: string;
}

export class IntegrationQiagenModel extends BaseIntegration<IntegrationQiagenData, IntegrationQiagenState> {
  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/qiagen/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
