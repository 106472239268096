import type { CSSProperties } from 'vue';
import type { ClassList } from '@/src/typings/types/types';
import type {
  AddonLfImageData,
  ImageFilters,
  ImageLayoutFilterData,
  LayoutSettingsData
} from '@/src/components/addons/lf-image/Data';
import { ImageSourceType } from '@/src/components/addons/lf-image/Data';
import ActionsModel from '@/src/models/actions/ActionsModel';
import type { BorderType, TransitionEnterType } from '@/src/typings/enums/enums';
import { AlignContentType, ImageSizeType } from '@/src/typings/enums/enums';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { BorderData, BoxShadowData } from '@/src/typings/interfaces/data/settings/settings';
import type { ShadowState } from '@/src/typings/interfaces/state/SharedAddons';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import type { ColorRGBA } from '@/src/utilities/ColorHelper';
import { parseColorToRGBA } from '@/src/utilities/ColorHelper';
import { applyReplacementTags, transformMeasurementToNumber } from '@/src/utilities/Utilities';

interface ImageLayoutSettingsState {
  alignment?: AlignContentType;
}

export interface ImageFilterState {
  opacity: number;
  blur: number;
  brightness: number;
  contrast: number;
  saturation: number;
  hue: number;
  enter?: TransitionEnterType;
  enterDuration: number;
}

interface ImageBorderState {
  type?: BorderType;
  color?: string;
  width?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
}

interface FiltersState {
  normal: ImageFilterState;
  hover: ImageFilterState;
}

interface ImageLayoutState {
  settings?: ImageLayoutSettingsState;
  filters?: FiltersState;
  border?: ImageBorderState;
  shadow?: ShadowState;
}

interface ImageSettingsState {
  layout?: ImageLayoutState;
}

interface ImageStyle {
  filter?: string;
  transition?: string;
}

export interface AddonLfImageState extends AddonModelState {
  src?: string;
  sourceType: ImageSourceType;
  replacementTag?: string;
  width?: number;
  height?: number;
  altText?: string;
  size: ImageSizeType;
  action?: ActionsModel;
  imageClasses: ClassList;
  settings?: ImageSettingsState;
  elementStyling?: {
    wrapper?: CSSProperties;
    inline?: CSSProperties;
    normal?: ImageStyle;
    hover?: ImageStyle;
    imageNormalStyles?: string;
    imageHoverStyles?: string;
  };
}

export class AddonLfImageModel extends AddonModel<AddonLfImageData, AddonLfImageState, 'lf-image'> {
  parseAddon(data: AddonLfImageData) {
    const state = this.state;

    state.sourceType = data.settings.source_type ?? ImageSourceType.BROWSE;
    state.src = state.sourceType === ImageSourceType.BROWSE ? data.settings.source : undefined;
    state.replacementTag = data.settings.replacement_tag;

    if (state.sourceType === ImageSourceType.REPLACEMENT_TAG) {
      state.src = applyReplacementTags(`#${state.replacementTag}#`, this.column?.replacementTags);
    }

    if (data.settings.width) {
      state.width = transformMeasurementToNumber(data.settings.width);
    } else {
      state.width = undefined;
    }

    if (data.settings.height) {
      state.height = transformMeasurementToNumber(data.settings.height);
    } else {
      state.height = undefined;
    }

    state.altText = data.settings?.alt;
    state.size = data.settings.size ?? ImageSizeType.FULL;

    if (data.settings.action?.type) {
      if (state.action) {
        state.action.setData(data.settings?.action);
      } else {
        state.action = new ActionsModel(data.settings?.action);
      }
    } else {
      state.action = undefined;
    }

    state.settings = state.settings ?? {};
    state.elementStyling = state.elementStyling || {};

    if (data.settings.layout) {
      state.settings.layout = state.settings.layout ?? {};
      state.settings.layout.border = AddonLfImageModel.parseLayoutBorderData(data.settings.layout.border);
      state.settings.layout.shadow = AddonLfImageModel.parseLayoutShadowData(data.settings.layout.shadow);
      state.settings.layout.settings = AddonLfImageModel.parseLayoutSettingsData(data.settings.layout.settings);

      if (data.settings.layout.filters) {
        state.settings.layout.filters = AddonLfImageModel.constructLayoutFiltersState(data.settings.layout.filters);
      } else {
        state.settings.layout.filters = undefined;
      }

      state.elementStyling.wrapper = {
        textAlign: state.settings?.layout?.settings?.alignment ?? AlignContentType.LEFT
      };
    } else {
      state.settings.layout = undefined;
    }

    state.elementStyling.inline = AddonLfImageModel.constructImageInlineStyling(state);

    state.imageClasses = {
      'lf-image__img--clickable': !!state.action?.state
    };
  }

  public isAddonValid(): boolean {
    const data = this.getData();

    if (data.settings.source_type === ImageSourceType.REPLACEMENT_TAG) {
      return false;
    }

    return !!data.settings.source;
  }

  /* BORDER DATA */
  private static parseLayoutBorderData(data: DeviceData<BorderData>): ImageBorderState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return AddonLfImageModel.constructLayoutBorderState(useData);
    }
  }

  private static constructLayoutBorderState(data: BorderData): ImageBorderState {
    return {
      ...(data.color && { color: data.color }),
      ...(data.type && { type: data.type }),
      ...(data.width && {
        width: {
          ...(data.width?.top && { top: Number(data?.width?.top) }),
          ...(data.width?.right && {
            right: Number(data?.width?.right)
          }),
          ...(data.width?.bottom && {
            bottom: Number(data?.width?.bottom)
          }),
          ...(data.width?.left && {
            left: Number(data?.width?.left)
          })
        }
      })
    };
  }

  /* FILTERS DATA */
  private static constructLayoutFiltersState(data: ImageFilters): FiltersState {
    const normalData = data.normal;
    const hoverData = data.hover;

    return {
      normal: AddonLfImageModel.constructLayoutFilterState(normalData),
      hover: AddonLfImageModel.constructLayoutFilterState(hoverData)
    };
  }

  private static constructLayoutFilterState(data?: ImageLayoutFilterData): ImageFilterState {
    return {
      blur: data?.blur !== undefined && data.blur !== '' ? Number(data.blur) : 0,
      brightness: data?.brightness && (data?.brightness !== '') !== undefined ? Number(data.brightness) : 100,
      contrast: data?.contrast !== undefined && data.contrast !== '' ? Number(data.contrast) : 100,
      hue: data?.hue !== undefined && data.hue !== '' ? Number(data.hue) : 0,
      opacity: data?.opacity !== undefined && data?.opacity !== '' ? Number(data.opacity) : 1,
      saturation: data?.saturation !== undefined && data.saturation !== '' ? Number(data.saturation) : 100,
      enterDuration: data?.enter_duration ? Number(data.enter_duration) : 1,
      ...(data?.enter && { enter: data.enter })
    };
  }

  /* SHADOW DATA */
  private static parseLayoutShadowData(data: DeviceData<BoxShadowData>): ShadowState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }
      return AddonLfImageModel.constructLayoutShadowState(useData);
    }
  }

  private static constructLayoutShadowState(data: BoxShadowData): ShadowState {
    return {
      blur: data.blur ? Number(data.blur) : 0,
      horizontal: data.horizontal ? Number(data.horizontal) : 0,
      spread: data.spread ? Number(data.spread) : 0,
      vertical: data.vertical ? Number(data.vertical) : 0,
      ...(data.color && { color: data.color })
    };
  }

  /* SETTINGS DATA */
  private static parseLayoutSettingsData(data: DeviceData<LayoutSettingsData>): ImageLayoutSettingsState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }
      return AddonLfImageModel.constructLayoutSettingsState(useData);
    }
  }

  private static constructLayoutSettingsState(data: LayoutSettingsData): ImageLayoutSettingsState {
    return {
      alignment: data.alignment
    };
  }

  private static constructImageInlineStyling(state: AddonLfImageState): CSSProperties {
    const imageSizeThumb = 150;
    const imageSizeMedium = 300;

    let imageWidth;
    let imageHeight;

    if (state.size === ImageSizeType.THUMB) {
      imageWidth = imageSizeThumb;
      imageHeight = imageSizeThumb;
    }

    if (state.size === ImageSizeType.MEDIUM) {
      imageWidth = imageSizeMedium;
      imageHeight = imageSizeMedium;
    }

    if (state.size === ImageSizeType.CUSTOM) {
      imageWidth = state.width;
      imageHeight = state.height;
    }

    const borderState = state.settings?.layout?.border;
    const shadowState = state.settings?.layout?.shadow;

    const borderWidth = borderState?.width;
    const borderStyle = borderState?.type;
    const borderColor = borderState?.color;

    // boxShadow values
    const horizontal = shadowState?.horizontal ? shadowState.horizontal : 0;
    const vertical = shadowState?.vertical ? shadowState.vertical : 0;
    const blur = shadowState?.blur ? shadowState.blur : 0;
    const spread = shadowState?.spread ? shadowState.spread : 0;

    let validColor: ColorRGBA | undefined;

    if (shadowState?.color) {
      validColor = parseColorToRGBA(shadowState.color);
    }

    const defaultBorderWidth = 0;

    let animationDuration = 525;

    if (state.settings?.layout?.filters?.hover.enterDuration) {
      animationDuration = state.settings.layout.filters.hover.enterDuration * 1000;
    }

    return {
      ...(imageWidth !== undefined && { width: `${imageWidth}px` }),
      ...(imageHeight !== undefined && { height: `${imageHeight}px` }),

      borderTop: `${borderWidth?.top || defaultBorderWidth}px`,
      borderRight: `${borderWidth?.right || defaultBorderWidth}px`,
      borderBottom: `${borderWidth?.bottom || defaultBorderWidth}px`,
      borderLeft: `${borderWidth?.left || defaultBorderWidth}px`,
      ...(borderStyle && { borderStyle }),
      ...(borderColor && { borderColor }),

      ...(validColor && {
        boxShadow: `${horizontal}px ${vertical}px ${blur}px ${spread}px ${shadowState?.color}`
      }),

      ...(state.settings?.layout?.filters?.hover.enter && {
        animationDuration: `${animationDuration}ms`
      })
    };
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
