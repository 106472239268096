<template>
  <div class="lf-section__toolbar" style="display: none">
    <div
      v-if="!model.state.config.clone"
      class="lf-section__toolbar-item lf-section__toolbar-item--insert-block lf-section__toolbar-item--active"
    >
      <a class="lf-section__toolbar-item-btn" @click.stop="insertBlock"><i class="icon-plus-small"></i></a>
    </div>

    <div
      class="lf-section__toolbar-item lf-section__toolbar-item--move-up"
      :class="{ 'lf-section__toolbar-item--active': canMoveUp }"
    >
      <a class="lf-section__toolbar-item-btn" @click="moveUp"><i class="icon-arrow-up"></i></a>
    </div>

    <div
      class="lf-section__toolbar-item lf-section__toolbar-item--move-down"
      :class="{ 'lf-section__toolbar-item--active': canMoveDown }"
    >
      <a class="lf-section__toolbar-item-btn" @click="moveDown"><i class="icon-arrow-down"></i></a>
    </div>

    <div
      v-if="!model.state.config.global"
      class="lf-section__toolbar-item lf-section__toolbar-item--delete lf-section__toolbar-item--active"
    >
      <a class="lf-section__toolbar-item-btn" @click="handleDelete"><i class="icon-trash"></i></a>
    </div>
  </div>

  <ModalContentAddContent
    v-if="showContentAddModal"
    :context-model="model"
    :type="BlockTypes.BLOCKS"
    @close-request="showContentAddModal = false"
  />
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, ref } from 'vue';
import ModalContentAddContent from '@/src/components/components/editing/Modals/ModalContentAddContent.vue';
import type { SectionModelType } from '@/src/typings/types/types';
import { BlockTypes } from '@/src/typings/types/types';
import { useCampaignStore } from '@/src/store/campaign';
import { useEditingStore } from '@/src/store/editing';

export default defineComponent({
  name: 'SectionEditToolbar',
  components: {
    ModalContentAddContent
  },
  props: {
    model: {
      type: Object as PropType<SectionModelType>,
      required: true
    }
  },
  setup(props) {
    const editingStore = useEditingStore();
    const showContentAddModal = ref(false);

    const campaignStore = useCampaignStore();
    const campaignState = campaignStore.model?.state;

    const insertBlock = () => {
      showContentAddModal.value = true;
    };

    const hide = () => {
      props.model.saveBlock();
    };

    const moveUp = () => {
      props.model.onMoveUp();
      props.model.saveBlock();
    };

    const moveDown = () => {
      props.model.onMoveDown();
      props.model.saveBlock();
    };

    /**
     * The reason for naming this handleDelete, is that "delete" is a reserved javascript word
     */
    const handleDelete = () => {
      if (
        props.model.state.config?.hasRegistrationAddon &&
        campaignState?.config &&
        campaignState.config.hasRegistrations
      ) {
        editingStore.showError(
          'Attention!',
          'Looks like you are trying to delete a registration form, however, this campaign already has registrations.\n' +
            'In order to delete this element, you must first delete all registrations collected on the campaign.'
        );
        return;
      }

      editingStore.showDeletePrompt(handlePromptButtonClicked);
    };

    const handlePromptButtonClicked = async (): Promise<void> => {
      editingStore.removeActiveModel();
      await props.model.delete();
      hide();
    };

    const canMoveDown = computed(() => {
      return props.model.canMoveDown();
    });

    const canMoveUp = computed(() => {
      return props.model.canMoveUp();
    });

    return {
      insertBlock,
      moveUp,
      moveDown,
      handleDelete,
      canMoveDown,
      canMoveUp,
      showContentAddModal,
      BlockTypes,
      handlePromptButtonClicked
    };
  }
});
</script>

<style lang="scss">
.lf-section {
  &__toolbar {
    display: block !important;
    position: absolute;
    left: 100px;
    top: 0px;
    z-index: 20;

    &-item {
      display: block;
      float: left;
      padding-right: 1px;
      pointer-events: none;

      &--active {
        pointer-events: all;

        .lf-section__toolbar-item-btn {
          transition: color 125ms linear, background-color 125ms linear;
          color: #959595 !important;
        }
      }

      &-btn {
        display: block;
        width: 25px;
        height: 25px;
        background: #232424;
        line-height: 25px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        text-decoration: none !important;

        &.lf-section__toolbar-item-btn {
          color: darken(#959595, 30%) !important;
        }

        &:hover {
          color: #fff !important;
          background: lighten(#232424, 5%);
        }

        .lf-section__toolbar-item--open & {
          background: #003fff;
          color: #fff !important;

          &:hover {
            background: lighten(#003fff, 5%);
          }
        }

        .icon-plus-small {
          position: relative;
          top: -1px;
          font-size: 9px;
        }

        .icon-arrow-up,
        .icon-arrow-down {
          display: inline-block;
          position: relative;
          top: -1px;
          font-size: 7px;
        }

        .icon-arrow-up {
          display: inline-block;
          position: relative;
          top: -4px;
          transform: rotate(180deg);
        }

        .icon-trash {
          display: inline-block;
          position: relative;
          top: -1px;
          font-size: 11px;
        }

        .icon-more {
          display: inline-block;
          position: relative;
          top: -3px;
          transform: scale(0.5);
          font-size: 3px;
        }
      }
    }

    &::after {
      content: '';
      display: block;
      clear: both;
      line-break: normal;
      font-size: 0;
    }
  }
}
</style>
