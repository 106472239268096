import { BaseModel } from '@/src/models/BaseModel';

export interface BaseEmailValidationData {
  api_key: string;
  status: string[];
  message: string;
  bypass_service_failure: string;
}

export interface BaseEmailValidationState {
  apiKey: string;
  status: string[];
  message: string;
  bypassServiceFailure: boolean;
}

export abstract class BaseValidationModel<
  Data extends BaseEmailValidationData,
  State extends BaseEmailValidationState
> extends BaseModel<Data, State> {
  public abstract validate(email: string): Promise<string>;

  public parse(data: Data): void {
    this.state.apiKey = data.api_key;
    this.state.message = data.message;
    this.state.status = data.status;
    this.state.bypassServiceFailure = data.bypass_service_failure === '1';
    this.parseValidationSettings(data);
  }

  public abstract parseValidationSettings(data: Data): void;
}
