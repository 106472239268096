import type {
  GameSpinTheBottleCampaignLimit,
  GameSpinTheBottleCustomLabelsData,
  GameSpinTheBottleData,
  GameSpinTheBottleGeneralData,
  GameSpinTheBottleSpinButtonPositionType,
  GameSpinTheBottleFieldItemData,
  SpinTheBottleInstantWin,
  GameSpinTheBottleAdvancedData,
  GameSpinTheBottleVisualData
} from '@/src/components/games/spinthebottle/Data';
import {
  GameSpinTheBottleSpinButtonType,
  GameSpinTheBottleWheelDisplayType
} from '@/src/components/games/spinthebottle/Data';
import useDevice from '@/src/hooks/useDevice';
import type { GameIndicator, GameState, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { DrawType } from '@/src/typings/enums/enums';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import { extractUnitFromSize, StringUnit, transformMeasurementToNumber } from '@/src/utilities/Utilities';

export interface GameSpinTheBottleVisualState {
  borderColor?: string;
  borderWidth?: number;
  color1?: string;
  color2?: string;
  color3?: string;
  dividerColor?: string;
  fontColor?: string;
  fontSize?: number;
  fontType?: string;
  wheelSize?: number;
  wheelUnit?: StringUnit;
}
// GameData that needs to be sent after game has ended
// use to secure if a user dont get the right prize
// then we can lookup the gameData and see what the user got.
export interface GameDataSpinTheBottle {
  calculation: number[];
  wheelComputed: Record<string, string>;
  wheelComputedOriginal: Record<string, string>;
  wheelHeight: number;
  wheelWidth: number;
  fieldsOccupied: number[];
  fields: {
    [key: string]: GameSpinTheBottleFieldItemData;
  };
  spinsLeft: number;
  spinsUsed: number;
  winnerFieldId: number | undefined;
  winner: boolean | undefined; // winner only set if instant win is on else its undefined
  data: number | null; // the prize position for the current spin
  position: number;
}

interface GameSpinTheBottleGeneralState {
  bottleImage?: string;
  bottleAltText?: string;
  bottleHeight?: number;
  bottleWidth?: number;
  bottleHeightMobile?: number;
  bottleWidthMobile?: number;
  wheelImage: string;
  wheelImageAltText?: string;
  numFields: number;
  spins: number;
  delay: number;
  autoSpinDelay?: number;
  spinButton?: GameSpinTheBottleSpinButtonType;
  spinButtonPosition?: GameSpinTheBottleSpinButtonPositionType;
  drawType?: DrawType;
  fields: {
    [key: string]: GameSpinTheBottleFieldItemData;
  };
  fieldsOccupied?: number[];
  campaignLimitInterval?: GameSpinTheBottleCampaignLimit;
  campaignLimit?: string;
  visuals?: GameSpinTheBottleVisualState;
  wheelDisplay: GameSpinTheBottleWheelDisplayType;
}

interface GameSpinTheBottleCustomLabelsState {
  spinButton?: string;
  spinsLeft?: string;
}
export interface GameSpinTheBottleAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GameSpinTheBottleAdvancedState {
  sound: GameSpinTheBottleAdvancedSoundState;
}

interface GameSpinTheBottleWheelSize {
  size: number;
  unit: StringUnit | undefined;
}

export interface GameSpinTheBottleState extends GameState {
  clickEnabled: boolean;
  general: GameSpinTheBottleGeneralState;
  customLabels?: GameSpinTheBottleCustomLabelsState;
  advanced: GameSpinTheBottleAdvancedState;
  winnerField?: number;
  winnerFieldId?: number;
}

export class GameSpinTheBottleModel
  extends GameModel<GameSpinTheBottleData, GameSpinTheBottleState>
  implements HasSound
{
  parseGame(data: GameSpinTheBottleData): void {
    const state = this.state;
    const { isMobile } = useDevice();

    state.general = GameSpinTheBottleModel.constructGeneralState(data.general);

    if (isMobile) {
      if (data.general.mobile) {
        state.general.bottleImage = data.general.mobile.bottle;
      }
    }

    if (data.general?.fields_occupied && data.general?.fields_occupied.length > 0) {
      state.general.fieldsOccupied = data.general.fields_occupied.map((i: number) => Number(i));
    }

    state.clickEnabled = GameSpinTheBottleSpinButtonType.USE_CLICK === state.general?.spinButton;

    if (data.customlabels) {
      state.customLabels = GameSpinTheBottleModel.constructCustomLabelsState(data.customlabels);
    }
    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameSpinTheBottleModel.constructAdvancedSoundState(data?.advanced);
  }

  private static constructAdvancedSoundState(
    data: GameSpinTheBottleAdvancedData | undefined
  ): GameSpinTheBottleAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructCustomLabelsState(data: GameSpinTheBottleCustomLabelsData) {
    return {
      ...(data?.spin_button && { spinButton: data.spin_button }),
      ...(data?.spins_left && { spinsLeft: data.spins_left })
    };
  }

  private static constructVisualState(
    data: GameSpinTheBottleVisualData,
    wheelDisplay: GameSpinTheBottleWheelDisplayType
  ): GameSpinTheBottleVisualState {
    // Default values for when data.wheel_size is not provided
    const defaultWheelSize = wheelDisplay === GameSpinTheBottleWheelDisplayType.COLOR ? 400 : undefined;
    const defaultWheelUnit = StringUnit.PIXEL;

    // Parse wheelSize and wheelUnit from data.wheel_size if available
    let wheelSizeParsed: GameSpinTheBottleWheelSize | undefined;
    if (data.wheel_size) {
      wheelSizeParsed = {
        size: transformMeasurementToNumber(data.wheel_size),
        unit: extractUnitFromSize(data.wheel_size)
      };
    }

    return {
      ...(data?.border_color && { borderColor: data.border_color }),
      ...(data?.border_thickness && { borderWidth: parseInt(data.border_thickness, 10) ?? 0 }),
      ...(data?.color1 && { color1: data.color1 }),
      ...(data?.color2 && { color2: data.color2 }),
      ...(data?.color3 && { color3: data.color3 }),
      ...(data?.divider && { dividerColor: data.divider }),
      ...(data?.font_color && { fontColor: data.font_color }),
      ...(data?.font_size && { fontSize: parseInt(data.font_size, 10) ?? 16 }),
      ...(data?.font_type && { fontType: data.font_type }),
      ...(data?.wheel_size && { wheelSize: parseInt(data.wheel_size, 10) ?? 400 }),
      wheelSize: wheelSizeParsed ? wheelSizeParsed.size : defaultWheelSize,
      wheelUnit: wheelSizeParsed ? wheelSizeParsed.unit : defaultWheelUnit
    };
  }

  private static constructGeneralState(data: GameSpinTheBottleGeneralData): GameSpinTheBottleGeneralState {
    const wheelDisplay = data.wheel_display ?? 'image';

    return {
      ...(data?.bottle && { bottleImage: data.bottle }),
      ...(data?.bottle_alt_text && { bottleAltText: data.bottle_alt_text }),
      ...(data?.bottle_height && { bottleHeight: Number(data.bottle_height) }),
      ...(data?.bottle_width && { bottleWidth: Number(data.bottle_width) }),

      ...(data?.bottle_height_mobile && { bottleHeightMobile: Number(data.bottle_height_mobile) }),
      ...(data?.bottle_width_mobile && { bottleWidthMobile: Number(data.bottle_width_mobile) }),
      wheelImage: data.image,
      ...(data?.image_alt_text && { wheelImageAltText: data.image_alt_text }),
      numFields: Number(data.num_fields),
      spins: Number(data.spins),
      delay: Number(data.delay),
      ...(data?.auto_delay && { autoSpinDelay: Number(data.auto_delay) }),
      ...(data?.spin_button && { spinButton: data.spin_button }),
      ...(data?.spin_button_position && {
        spinButtonPosition: data.spin_button_position
      }),
      ...(data?.draw_type && { drawType: data.draw_type }),
      ...(data?.campaign_limit_interval && {
        campaignLimitInterval: data.campaign_limit_interval
      }),
      ...(data?.campaign_limit && {
        campaignLimit: data.campaign_limit
      }),

      fields: data.fields,
      ...(data.visuals && {
        visuals: GameSpinTheBottleModel.constructVisualState(data.visuals, wheelDisplay)
      }),
      wheelDisplay
    };
  }

  public setInstantWinnerData(data: SpinTheBottleInstantWin) {
    super.setInstantWinnerData(data);
    const winnerField = data.winner_field;
    const winnerFieldId = data.winner_field_id;

    if (winnerField) {
      this.state.winnerField = Number(winnerField);
    }

    if (winnerFieldId) {
      this.state.winnerFieldId = Number(winnerFieldId);
    }
  }

  public hasInstantWin(): boolean {
    const state = this.state;
    return state.general.drawType === DrawType.AUTO;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    if (this.state.general.spins <= 1) {
      return [];
    }
    return [
      {
        indicatorKey: 'spins',
        metricKey: {
          spins_left: 'spins_left',
          spins_used: 'spins_used'
        },
        icon: GameIndicatorIcon.SPINS,
        value: {
          spins_left: this.state.general.spins,
          spins_used: 0
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
