import type { IntegrationNfcData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationNfcState extends BaseIntegrationState {
  registrationPageId?: number;
}

export class IntegrationNfcModel extends BaseIntegration<IntegrationNfcData, IntegrationNfcState> {
  parse(data: IntegrationNfcData) {
    super.parse(data);
    this.state.registrationPageId = Number(data.settings?.registration_page_id);
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
