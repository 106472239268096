import type {
  GameMemoryAdvancedData,
  GameMemoryCardAmountType,
  GameMemoryData,
  GameMemoryFrontSliceData,
  GameMemoryFrontType,
  GameMemoryGeneralData,
  GameMemoryImageBacksideData,
  GameMemoryImagesData
} from '@/src/components/games/memory/Data';
import type {
  GameIndicator,
  GameState,
  GameTimeChallenge,
  HasGameTimeChallenge,
  HasSound
} from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import { shuffle } from '@/src/utilities/Utilities';
import type { TransitionTypes } from '@/src/typings/types/types';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { GameEndingConditionType } from '@/src/typings/interfaces/data/conditions/visibilityConditions';

interface VisibilityConditionData {
  condition: GameEndingConditionType;
  date_range: {
    enabled: string;
    date_to: string;
    date_from: string;
  };
}

export interface GameMemoryGeneralState {
  turns: number;
  matchImages?: boolean;
  cardAmount: GameMemoryCardAmountType;
  addCards?: boolean;
  extraPairs?: number;
  grid: {
    x: number;
    y: number;
  };
  cardAmountOverwrite: boolean;
  enableBestTime?: boolean;
  enableTimeRanking?: boolean;
  gameLabels?: {
    timeLeft: string;
    timeUsed: string;
    movesLeft: string;
    movesUsed: string;
  };
}

export interface GameMemoryImageBacksideState {
  image: string;
  id: number;
  match?: string;
  date: {
    enabled: boolean;
    from?: string;
    to?: string;
  };
  visibilityCondition?: VisibilityConditionsModel;
}

export interface GameMemoryFrontSliceState {
  image: string;
}

export interface GameMemoryImagesState {
  backside: GameMemoryImageBacksideState[];
  frontType: GameMemoryFrontType;
  front: string;
  frontSlice?: GameMemoryFrontSliceState;
}

export interface GameMemoryAdvancedSoundState {
  enabled: boolean;
  src?: string;
}
export interface GameMemoryAdvancedAnimationState {
  enabled: boolean;
  animation?: TransitionTypes;
}

export interface GameMemoryAdvancedState {
  sound: GameMemoryAdvancedSoundState;
  animation: GameMemoryAdvancedAnimationState;
}

export interface GameMemoryState extends GameState {
  general: GameMemoryGeneralState;
  parsedGrid: number[];
  images: GameMemoryImagesState;
  advanced: GameMemoryAdvancedState;
}

export class GameMemoryModel
  extends GameModel<GameMemoryData, GameMemoryState>
  implements HasGameTimeChallenge, HasSound
{
  parseGame(data: GameMemoryData): void {
    const state = this.state;

    state.parsedGrid = data.general.card_amount.split('x').map((item) => {
      return parseInt(item, 10);
    });

    if (data.general) {
      state.general = GameMemoryModel.constructGeneralState(data.general, state.parsedGrid);
    }

    if (useDevice().isMobile && data.general.card_amount_mobile) {
      state.general.cardAmount = data.general.card_amount_mobile;

      const parsedGrid = data.general.card_amount_mobile.split('x').map((item) => {
        return parseInt(item, 10);
      });

      const newGrid = {
        x: parsedGrid[0],
        y: parsedGrid[1]
      };

      state.general.grid = newGrid;
    }

    if (data.images) {
      state.images = GameMemoryModel.constructImagesState(data.images);
    }

    // shuffle images order
    if (state.images.backside) {
      shuffle(state.images.backside);
    }

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameMemoryModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GameMemoryModel.constructAdvancedAnimationState(data?.advanced);

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }

    if (data.images.front_slice) {
      state.images.frontSlice = GameMemoryModel.parseFrontSliceData(data.images);
    } else {
      state.images.frontSlice = undefined;
    }
  }

  private static constructAdvancedAnimationState(
    data: GameMemoryAdvancedData | undefined
  ): GameMemoryAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { src: data.animation })
    };
  }

  private static constructAdvancedSoundState(data: GameMemoryAdvancedData | undefined): GameMemoryAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static parseFrontSliceData(data: GameMemoryImagesData): GameMemoryFrontSliceState | undefined {
    if (data.front_slice) {
      const deviceData = getDeviceData(data.front_slice, 'overwrite');

      if (!deviceData) {
        return undefined;
      }

      return GameMemoryModel.constructFrontSliceState(deviceData);
    }

    return undefined;
  }

  private static constructFrontSliceState(data: GameMemoryFrontSliceData) {
    return {
      image: data.image
    };
  }

  private static constructBacksideState(data: GameMemoryImageBacksideData): GameMemoryImageBacksideState {
    let visibilityCondition: VisibilityConditionsModel | undefined;

    const visibilityConditionData: VisibilityConditionData = {
      condition: GameEndingConditionType.NONE,
      date_range: {
        enabled: data.date_to !== undefined || data.date_from !== undefined ? '1' : '0',
        date_to: data.date_to ? data.date_to : '',
        date_from: data.date_from ? data.date_from : ''
      }
    };

    if (visibilityCondition) {
      visibilityCondition.setData(visibilityConditionData);
    } else {
      visibilityCondition = new VisibilityConditionsModel(visibilityConditionData);
    }

    return {
      image: data.image,
      id: Number(data.id),
      ...(data.match && { match: data.match }),
      date: {
        enabled: data.date_to !== undefined || data.date_from !== undefined,
        ...(data.date_from && { from: data.date_from }),
        ...(data.date_to && { to: data.date_to })
      },
      visibilityCondition
    };
  }

  private static constructImagesState(data: GameMemoryImagesData): GameMemoryImagesState {
    const backside: GameMemoryImageBacksideState[] = [];

    for (const property in data.backside) {
      if (Object.hasOwnProperty.call(data.backside, property)) {
        backside.push(GameMemoryModel.constructBacksideState(data.backside[Number(property)]));
      }
    }

    return {
      backside,
      frontType: data.front_type,
      front: data.front
    };
  }

  private static constructGeneralState(data: GameMemoryGeneralData, parsedGrid: number[]): GameMemoryGeneralState {
    return {
      turns: Number(data.turns),
      ...(data.match_images && { matchImages: data.match_images === '1' }),
      cardAmount: data.card_amount,
      grid: {
        x: parsedGrid[0],
        y: parsedGrid[1]
      },
      ...(data.add_cards && { addCards: data.add_cards === '1' }),
      ...(data.extra_pairs && { extraPairs: Number(data.extra_pairs) }),
      cardAmountOverwrite: data.card_amount_overwrite === '1',
      ...(data.time && { time: data.time === 1 }),
      ...(data.enable_best_time && { enableBestTime: data.enable_best_time === '1' }),
      ...(data.enable_time_ranking && { enableTimeRanking: data.enable_time_ranking === '1' })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit ?? 0,
          time_used: 1
        }
      },
      {
        indicatorKey: 'moves',
        metricKey: {
          moves_left: 'movesleft',
          moves_used: 'movesused'
        },
        icon: GameIndicatorIcon.MOVES,
        value: {
          moves_left: this.state.general.turns,
          moves_used: 0
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
