import { useUtilityStore } from '@/src/store/utility';
import { useCampaignStore } from '@/src/store/campaign';

export default function useDate() {
  const campaignStore = useCampaignStore();
  const utilityStore = useUtilityStore();

  // hook to get the current date based on timezone and testDate data
  const getDate = () => {
    const currentDate = new Date();

    const isDemo = (utilityStore.url ?? window.location.href).includes('/campaign/view/demo');
    const currentTestDate = campaignStore.gameTestDate;

    if (isDemo && currentTestDate) {
      return currentTestDate;
    }

    return currentDate;
  };

  return {
    getDate
  };
}
