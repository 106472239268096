import { SdkBaseAddonModel } from '.';
import type { AddonLfImageModel } from '@/src/components/addons/lf-image/Model';
import { SdkTypeCheck } from '@/src/sdk/utilities';

export class SdkAddonImage extends SdkBaseAddonModel {
  #model: AddonLfImageModel;

  constructor(model: AddonLfImageModel) {
    super(model);
    this.#model = model;
  }

  public get source(): string | undefined {
    return this.#model.state.src;
  }

  public set source(value: string | undefined) {
    try {
      SdkTypeCheck('string', value);
      this.#model.state.src = value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public get alt(): string | undefined {
    return this.#model.state.altText;
  }

  public set alt(value: string | undefined) {
    try {
      SdkTypeCheck('string', value);

      this.#model.state.altText = value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
