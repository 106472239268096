export type SurveyAligmentType = 'left' | 'center' | 'right';

export type SurveyAnswerType = 'single' | 'multi' | 'stars' | 'comment' | 'slider' | 'smiles' | 'numbers' | 'nps';

export type SurveyFontType = 'p' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export enum AnswerType {
  SINGLE,
  MULTI,
  STARS,
  COMMENT,
  SLIDER,
  SMILES,
  NUMBERS,
  NPS
}

export enum NumbersPosition {
  HORIZONTAL,
  VERTICAL
}

export enum SurveyAlignment {
  LEFT,
  CENTER,
  RIGHT
}
