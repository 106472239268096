import { isValid } from 'date-fns';
import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';
import { convertDateFormat, formatDate, parseDateStr } from '@/src/utilities/DateHelpers';

export interface FormElementDateSettingsData extends BaseFormElementSettingsData {
  date_field_type: 'input' | 'calendar';
  field_format: 'Y-m-d' | 'd-m-Y' | 'm-d-Y' | 'Y.m.d' | 'd.m.Y' | 'm.d.Y' | 'Y/m/d' | 'd/m/Y' | 'm/d/Y';
  time_enabled?: string;
  time_format12?: string;
  time_min?: string;
  time_max?: string;
  minage?: string;
  mindate?: string;
  maxdate?: string;
  disable_dates: string;
  disabled_dates_values: string[];
  dates_from_tomorrow?: string;
}

export interface FormElementDateData extends BaseFormElementData {
  settings?: FormElementDateSettingsData;
}

export interface FormElementDateState extends BaseFormElementState<string> {
  dateFieldType: 'input' | 'calendar';
  fieldFormat: 'Y-m-d' | 'd-m-Y' | 'm-d-Y' | 'Y.m.d' | 'd.m.Y' | 'm.d.Y' | 'Y/m/d' | 'd/m/Y' | 'm/d/Y';
  time: {
    enabled: boolean;
    format12?: string;
    min?: string;
    max?: string;
  };
  minDate?: string;
  maxDate?: string;
  minAge?: number;
  maskType?: boolean;
  mask: string;
  disableCookie: boolean;
  disableDates: boolean;
  disabledDatesValues: string[];
  datesFromTomorrow?: boolean;
  config: {
    altFormat: string;
    timeformat: string;
  };
}

export class FormElementDateModel extends BaseFormElementModel<string, FormElementDateData, FormElementDateState> {
  parseFormElement(data: FormElementDateData) {
    this.state.dateFieldType = data.settings?.date_field_type || 'calendar';
    this.state.fieldFormat = data.settings?.field_format || 'Y-m-d';
    this.state.time = this.state.time ?? {};
    this.state.time.enabled = data.settings?.time_enabled === '1';
    this.state.time.format12 = data.settings?.time_format12;
    this.state.time.min = data.settings?.time_min;
    this.state.time.max = data.settings?.time_max;
    this.state.minDate = data.settings?.mindate;
    this.state.maxDate = data.settings?.maxdate;
    this.state.minAge = data.settings?.minage ? parseInt(data.settings.minage, 10) : undefined;
    this.state.disableDates = data.settings?.disable_dates === '1';
    this.state.disabledDatesValues = data.settings?.disabled_dates_values || [];
    this.state.datesFromTomorrow = data.settings?.dates_from_tomorrow === '1';
    this.state.maskType = false;
    this.state.mask = '####-##-##';
    if (this.state.dateFieldType && this.state.dateFieldType === 'input') {
      this.state.maskType = true;

      if (this.state.fieldFormat) {
        this.state.mask = this.state.fieldFormat.replace('Y', '####').replace('d', '##').replace('m', '##');
      }
    }

    this.state.defaultValue = convertDateFormat(data.default_value, this.state.fieldFormat);
  }

  getInitialValue(): string | undefined {
    return this.getInitialStringValue();
  }

  getStringifiedValue(): string | undefined {
    return this.state.value && this.state.value !== '' ? this.state.value : undefined;
  }

  parseStringValue(value: string): string | undefined {
    return value;
  }

  getSerializedPostValue(): string {
    if (this.state.value) {
      let formatString: string = this.state.fieldFormat;

      if (this.state.time.enabled) {
        formatString += ' H:i';
        const dateTime = parseDateStr(this.state.value, formatString);
        if (isValid(dateTime)) {
          return formatDate(dateTime, 'Y-m-d H:i');
        }
      } else {
        const date = parseDateStr(this.state.value, formatString);

        if (isValid(date)) {
          return formatDate(date, 'Y-m-d', false);
        }
      }
    }

    return this.state.value ?? '';
  }

  getSerializedCookieValue(): string {
    return this.state.value ?? '';
  }

  public getValidationRules(): string[] {
    const rules = super.getValidationRules();

    if (this.state.validation?.types.includes('min_age') && this.state.minAge) {
      rules.push(
        `min_age:${this.state.minAge},${
          this.state.time.enabled && this.state.dateFieldType === 'calendar' ? this.state.fieldFormat + ' H:i' : 'Y-m-d'
        }`
      );
    }

    if (this.state.minDate) {
      rules.push(
        `min_date:${this.state.minDate},${
          this.state.time.enabled && this.state.dateFieldType === 'calendar' ? this.state.fieldFormat + ' H:i' : 'Y-m-d'
        }`
      );
    }

    if (this.state.maxDate) {
      rules.push(
        `max_date:${this.state.maxDate},${
          this.state.time.enabled && this.state.dateFieldType === 'calendar' ? this.state.fieldFormat + ' H:i' : 'Y-m-d'
        }`
      );
    }

    if (this.state.maskType) {
      rules.push('is_mask_date:Y-m-d');
    }

    return rules;
  }
}
