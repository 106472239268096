import AddonModel from '@/src/models/grid/AddonModel';
import type { AlignContentType, BorderType, TransitionEnterType } from '@/src/typings/enums/enums';
import type { ShadowState } from '@/src/typings/interfaces/state/SharedAddons';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import ActionsModel from '@/src/models/actions/ActionsModel';
import type {
  AddonLottieData,
  LottieAnimationData,
  LottieFilters,
  LottieLayoutFilterData,
  LottieLayoutFilterHoverData,
  LottieLayoutSettingsData
} from '@/src/components/addons/lottie/Data';
import { isValidValue, transformMeasurementToNumber } from '@/src/utilities/Utilities';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { BorderData, BoxShadowData } from '@/src/typings/interfaces/data/settings/settings';
import type { LottieSizeTypeData } from '@/src/components/addons/lottie/Interfaces';
import { LottieAnimationActionEnum, LottieSizeType } from '@/src/components/addons/lottie/Interfaces';

interface LottieLayoutSettingsState {
  alignment?: AlignContentType;
}

export interface LottieFilterHoverState extends LottieFilterState {
  enterAnimation?: TransitionEnterType;
  enterDuration: number;
}

export interface LottieFilterState {
  opacity: number;
  blur: number;
  brightness: number;
  contrast: number;
  saturation: number;
  hue: number;
}

interface LottieBorderState {
  type?: BorderType;
  color?: string;
  width?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
}

interface FiltersState {
  normal: LottieFilterState;
  hover: LottieFilterHoverState;
}

interface LottieLayoutState {
  settings?: LottieLayoutSettingsState;
  filters?: FiltersState;
  border?: LottieBorderState;
  shadow?: ShadowState;
}

interface LottieSettingsState {
  layout?: LottieLayoutState;
}

export interface LottieAnimationState {
  action?: LottieAnimationActionEnum;
  autoplay: boolean;
  loop: boolean;
  interval?: number;
}

export interface AddonLottieState extends AddonModelState {
  source?: string;
  width?: number;
  height?: number;
  altText?: string;
  size: LottieSizeType;
  action?: ActionsModel;
  animation: LottieAnimationState;
  settings?: LottieSettingsState;
}

export class AddonLottieModel extends AddonModel<AddonLottieData, AddonLottieState, 'lottie'> {
  parseAddon(data: AddonLottieData) {
    const state = this.state;

    state.source = data.settings.source;

    if (data.settings.width) {
      state.width = transformMeasurementToNumber(data.settings.width);
    } else {
      state.width = undefined;
    }

    if (data.settings.height) {
      state.height = transformMeasurementToNumber(data.settings.height);
    } else {
      state.height = undefined;
    }

    state.altText = data.settings?.alt;

    state.size = this.getSizeType(data.settings.size);

    if (data.settings.action?.type) {
      if (state.action) {
        state.action.setData(data.settings?.action);
      } else {
        state.action = new ActionsModel(data.settings?.action);
      }
    } else {
      state.action = undefined;
    }

    state.settings = state.settings ?? {};
    state.animation = state.animation ?? {};

    state.animation = AddonLottieModel.constructAnimationState(data.settings?.animation);

    if (data.settings.layout) {
      state.settings.layout = state.settings.layout ?? {};

      state.settings.layout.border = AddonLottieModel.parseLayoutBorderData(data.settings.layout.border);
      state.settings.layout.shadow = AddonLottieModel.parseLayoutShadowData(data.settings.layout.shadow);
      state.settings.layout.settings = AddonLottieModel.parseLayoutSettingsData(data.settings.layout.settings);

      if (data.settings.layout.filters) {
        state.settings.layout.filters = AddonLottieModel.constructLayoutFiltersState(data.settings.layout.filters);
      } else {
        state.settings.layout.filters = undefined;
      }
    } else {
      state.settings.layout = undefined;
    }
  }

  private static constructAnimationState(data: LottieAnimationData | undefined): LottieAnimationState {
    const autoplay = data?.autoplay !== '0'; // Determine autoplay value
    let action: undefined | LottieAnimationActionEnum;

    if (!autoplay && !data?.action) {
      // when no autoplay the default action to play the animation is click
      action = LottieAnimationActionEnum.Click;
    } else if (!autoplay && data?.action) {
      action = this.mapStringToEnum(data.action);
    }

    return {
      ...(action !== undefined && { action }),
      ...(data?.interval && {
        interval: Number(data.interval)
      }),
      autoplay,
      loop: data?.loop !== '0'
    };
  }

  private static mapStringToEnum(actionType: string | undefined): LottieAnimationActionEnum {
    switch (actionType) {
      case 'click':
        return LottieAnimationActionEnum.Click;
      case 'hover':
        return LottieAnimationActionEnum.Hover;
      default:
        return LottieAnimationActionEnum.Click;
    }
  }

  public getSizeType(size: LottieSizeTypeData | undefined): LottieSizeType {
    if (!size) return LottieSizeType.FULL;
    switch (size.toLowerCase()) {
      case 'full':
        return LottieSizeType.FULL;
      case 'thumb':
        return LottieSizeType.THUMB;
      case 'medium':
        return LottieSizeType.MEDIUM;
      case 'custom':
        return LottieSizeType.CUSTOM;
      default:
        return LottieSizeType.FULL;
    }
  }

  public isAddonValid(): boolean {
    const data = this.getData();

    return !!data.settings.source;
  }

  /* BORDER DATA */
  private static parseLayoutBorderData(data: DeviceData<BorderData>): LottieBorderState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return AddonLottieModel.constructLayoutBorderState(useData);
    }
  }

  private static constructLayoutBorderState(data: BorderData): LottieBorderState {
    return {
      ...(data.color && { color: data.color }),
      ...(data.type && { type: data.type }),
      ...(data.width && {
        width: {
          ...(data.width?.top && { top: Number(data?.width?.top) }),
          ...(data.width?.right && {
            right: Number(data?.width?.right)
          }),
          ...(data.width?.bottom && {
            bottom: Number(data?.width?.bottom)
          }),
          ...(data.width?.left && {
            left: Number(data?.width?.left)
          })
        }
      })
    };
  }

  private static constructLayoutFiltersState(data: LottieFilters): FiltersState {
    const normalData = data.normal;
    const hoverData = data.hover;

    return {
      normal: AddonLottieModel.constructLayoutFilterState(normalData),
      hover: AddonLottieModel.constructLayoutFilterHoverState(hoverData)
    };
  }

  private static parseNumericValue(value: string | undefined, defaultValue: number): number {
    return isValidValue(value) ? Number(value) : defaultValue;
  }

  private static constructLayoutFilterHoverState(data?: LottieLayoutFilterHoverData): LottieFilterHoverState {
    return {
      blur: this.parseNumericValue(data?.blur, 0),
      brightness: this.parseNumericValue(data?.brightness, 100),
      contrast: this.parseNumericValue(data?.contrast, 100),
      hue: this.parseNumericValue(data?.hue, 0),
      opacity: this.parseNumericValue(data?.opacity, 1),
      saturation: this.parseNumericValue(data?.saturation, 100),
      enterDuration: this.parseNumericValue(data?.enter_duration, 1),
      ...(data?.enter && { enterAnimation: data.enter })
    };
  }

  private static constructLayoutFilterState(data?: LottieLayoutFilterData): LottieFilterState {
    return {
      blur: this.parseNumericValue(data?.blur, 0),
      brightness: this.parseNumericValue(data?.brightness, 100),
      contrast: this.parseNumericValue(data?.contrast, 100),
      hue: this.parseNumericValue(data?.hue, 0),
      opacity: this.parseNumericValue(data?.opacity, 1),
      saturation: this.parseNumericValue(data?.saturation, 100)
    };
  }

  private static parseLayoutShadowData(data: DeviceData<BoxShadowData>): ShadowState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }
      return AddonLottieModel.constructLayoutShadowState(useData);
    }
  }

  private static constructLayoutShadowState(data: BoxShadowData): ShadowState {
    return {
      blur: this.parseNumericValue(data.blur, 0),
      horizontal: this.parseNumericValue(data.horizontal, 0),
      spread: this.parseNumericValue(data.spread, 0),
      vertical: this.parseNumericValue(data.vertical, 0),
      ...(data.color && { color: data.color })
    };
  }

  private static parseLayoutSettingsData(
    data: DeviceData<LottieLayoutSettingsData>
  ): LottieLayoutSettingsState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }
      return AddonLottieModel.constructLayoutSettingsState(useData);
    }
  }

  private static constructLayoutSettingsState(data: LottieLayoutSettingsData): LottieLayoutSettingsState {
    return {
      alignment: data.alignment
    };
  }
}
