import type { SectionModel } from '@/src/components/layout/section/SectionModel';
import type RowModel from '@/src/components/layout/row/RowModel';
import type { ContentAddonType } from '@/src/components/layout/column/ColumnModel';
import type ColumnModel from '@/src/components/layout/column/ColumnModel';
import type { FlowpageModel } from '@/src/components/layout/FlowpageModel';
import type { PopoverModel } from '@/src/models/PopoverModel';
import type { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';

export enum BlockTypes {
  SECTIONS = 'sections',
  BLOCKS = 'blocks',
  PAGES_FLOW = 'pages-flow',
  PAGES_PAGE = 'pages-page'
}

export type GridTypes = 'grid' | 'full_width';

export type SectionModelType = SectionModel | FlowpageModel | PopoverModel;

export type TransitionTypes =
  | ''
  | 'none'
  | 'fadeOut'
  | 'fadeOutDown'
  | 'fadeOutDownBig'
  | 'fadeOutLeft'
  | 'fadeOutLeftBig'
  | 'fadeOutRight'
  | 'fadeOutRightBig'
  | 'fadeOutUp'
  | 'fadeOutUpBig'
  | 'slideOutUp'
  | 'slideOutDown'
  | 'slideOutLeft'
  | 'slideOutRight'
  | 'zoomOut'
  | 'zoomOutDown'
  | 'zoomOutLeft'
  | 'zoomOutRight'
  | 'zoomOutUp'
  | 'rotateIn'
  | 'rotateOutDownLeft'
  | 'rotateOutDownRight'
  | 'rotateOutUpLeft'
  | 'rotateOutUpRight'
  | 'flip'
  | 'flipOutX'
  | 'flipOutY'
  | 'bounceOut'
  | 'bounceOutDown'
  | 'bounceOutLeft'
  | 'bounceOutRight'
  | 'bounceOutUp';

export type AnimatedHeadlineTransitionType = 'slide' | 'flip' | 'slide-down' | 'drop-in';

// Maybe add CampaignModel
export type ModelTypes =
  | SectionBaseModel
  | SectionModel
  | FlowpageModel
  | PopoverModel
  | RowModel
  | ColumnModel
  | ContentAddonType;

export type GoogleMapZoomType =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20';

type ClassListItem = boolean | '' | undefined | string[] | ClassList;

export interface ClassList {
  [key: string]: ClassListItem;
}

export type modalButtonId = 'yes' | 'no' | 'unlink';
