import type { GameShellAdvancedData, GameShellData } from './Data';
import type { GameIndicator, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { TransitionTypes } from '@/src/typings/types/types';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';

interface GameShellSettingsState {
  goBtnLabel: string;
  level: number;
  looseMessage: string;
  moves: number;
  nextBtnLabel: string;
  rounds: number;
  whereMsg: string;
  winMsg: string;
}

export interface GameShellAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GameShellAdvancedAnimationState {
  enabled: boolean;
  animation?: TransitionTypes;
}

export interface GameShellAdvancedState {
  sound: GameShellAdvancedSoundState;
  animation: GameShellAdvancedAnimationState;
}

export interface GameShellState {
  settings: GameShellSettingsState;
  images: {
    ball: string;
    shell: string;
  };
  startInterval: number;
  delay: number;
  speed: number;
  halfSpeed: number;
  toLoad: string[];
  advanced: GameShellAdvancedState;
}

export class GameShellModel extends GameModel<GameShellData, GameShellState> implements HasSound {
  parseGame(data: GameShellData) {
    const state = this.state;

    state.settings = state.settings ?? {};
    state.settings.nextBtnLabel = data.general.next_btn;
    state.settings.goBtnLabel = data.general.go_btn;
    state.settings.looseMessage = data.general.loose_msg || 'You didnt find it';
    state.settings.winMsg = data.general.win_msg || 'You win';
    state.settings.whereMsg = data.general.where_msg || 'Where is the ball ?';
    state.settings.level = data.general.level && data.general.level !== '' ? Number(data.general.level) : 500;
    state.settings.moves = data.general.moves && data.general.moves !== '' ? Number(data.general.moves) : 15;
    state.settings.rounds = data.general.rounds && data.general.rounds !== '' ? Number(data.general.rounds) : 1;

    state.speed = state.settings.level;
    state.halfSpeed = state.speed / 2;

    state.images = state.images ?? {};
    state.images.ball = data.images.ball;
    state.images.shell = data.images.shell;

    state.startInterval = state.speed + 10;
    state.delay = 1800;

    if (state.images.shell && state.images.ball) {
      state.toLoad = [state.images.ball, state.images.shell];
    }
    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameShellModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GameShellModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructAdvancedSoundState(data: GameShellAdvancedData | undefined): GameShellAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructAdvancedAnimationState(
    data: GameShellAdvancedData | undefined
  ): GameShellAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
