import type { Component } from 'vue';
import type { IntegrationFacebookConversionData as IntegrationEspFacebookConversionData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export enum IntegrationEspFacebookConversionType {
  ID,
  CODE
}

export interface IntegrationEspFacebookConversionState extends BaseIntegrationState {
  pixelId?: string;
  type?: IntegrationEspFacebookConversionType;
}

export class IntegrationEspFacebookConversionModel extends BaseIntegration<
  IntegrationEspFacebookConversionData,
  IntegrationEspFacebookConversionState
> {
  parse(data: IntegrationEspFacebookConversionData) {
    super.parse(data);

    this.state.pixelId = data.settings?.pixel_id;

    let type: IntegrationEspFacebookConversionType | undefined;

    if (data.settings?.type) {
      switch (data.settings.type) {
        case 'id':
          type = IntegrationEspFacebookConversionType.ID;
          break;
        case 'code':
          type = IntegrationEspFacebookConversionType.CODE;
          break;
      }
    }

    this.state.type = type;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/esp/facebook-conversion/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }
}
