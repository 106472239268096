import { BaseModel } from '@/src/models/BaseModel';
import { AlignContentType } from '@/src/typings/enums/enums';
import useDevice from '@/src/hooks/useDevice';

export enum AnswerDesignTypes {
  BULLETS,
  RADIOBUTTONS,
  TEXT,
  CUSTOM
}

export enum AnswerBulletsTypes {
  DISC,
  DECIMAL,
  LETTER,
  NONE
}

// How should answer be styled when the user selects to show the correct answer
export enum AnswerValidationTypes {
  LABEL,
  CONTAINER
}

export enum AnswerFontTypes {
  P,
  H2,
  H3,
  H4,
  H5,
  H6
}

export enum ImageColumnsTypes {
  NONE,
  ONE_COLUMN,
  TWO_COLUMNS,
  THREE_COLUMNS
}

export enum ImageEffectTypes {
  BORDER,
  GREYSCALE,
  ZOOM_OUT,
  ZOOM_IN,
  OVERLAY,
  NONE
}

interface AnswerSliderLayoutData {
  dot_checked_color: string;
  dot_color: string;
  dot_size: `${number}px`;
  line_color: string;
}

interface AnswerSliderLayoutState {
  dotCheckedColor: string;
  dotColor: string;
  dotSize: `${number}px`;
  lineColor: string;
}

export interface AnswerLayoutSettingsData {
  answer_design: 'bullets' | 'radiobuttons' | 'text' | 'custom';
  list_style?: 'disc' | 'decimal' | 'letter' | 'none';
  custom_checkmark_circle?: string;
  custom_radio?: string;
  custom_checkmark?: string;
  answer_padding: string;
  answer_margin: string;
  width?: string;
  answer_alignment: AlignContentType;
  bullets_bg_color?: string;
  answer_color?: string;
  active_state_bg_color?: string;
  active_state_color?: string;
  focus_state_background?: string;
  focus_state_text?: string;
  border_color?: string;
  border_thickness?: string;
  answer_color_type?: 'label' | 'container';
  answer_font_type: 'p' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  enable_answer_opacity?: string;
  answer_columns_desktop:
    | '""'
    | 'question__answers--images-desktop-1'
    | 'question__answers--images-desktop-2'
    | 'question__answers--images-desktop-3';
  answer_columns: '""' | 'one_column' | 'two_columns';
  image_effect?: 'border' | 'grayscale' | 'zoom_out' | 'zoom_in' | 'overlay' | 'none';
  image_effect_color?: string;
  image_border_thickness?: string;
  slider: AnswerSliderLayoutData;
}

export interface AnswerLayoutSettingsState {
  designType: AnswerDesignTypes;
  listStyle?: AnswerBulletsTypes;
  circleColor?: string;
  checkedCircleColor?: string;
  checkmarkColor?: string;
  padding: number;
  margin: number;
  width?: string;
  alignment: AlignContentType;
  backgroundColor?: string;
  textColor?: string;
  enableAnswerOpacity?: boolean;
  activeAnswer?: {
    textColor?: string;
    backgroundColor?: string;
  };
  focusAnswer?: {
    textColor?: string;
    backgroundColor?: string;
  };
  border?: {
    color?: string;
    thickness?: number;
  };
  validationType?: AnswerValidationTypes;
  fontType: AnswerFontTypes;
  imageColumns?: ImageColumnsTypes;
  imageEffect?: {
    type?: ImageEffectTypes;
    color?: string;
    borderThickness?: number;
  };
  elementStyling?: {
    answer: {
      normal?: string;
      active?: string;
      focus?: string;
    };
  };
  slider?: AnswerSliderLayoutState;
}

export class AnswerLayoutSettingsModel extends BaseModel<AnswerLayoutSettingsData, AnswerLayoutSettingsState> {
  parse(data: AnswerLayoutSettingsData) {
    const state = this.state;

    switch (data.answer_design) {
      case 'bullets':
        state.designType = AnswerDesignTypes.BULLETS;
        break;

      case 'radiobuttons':
        state.designType = AnswerDesignTypes.RADIOBUTTONS;
        break;

      case 'custom':
        state.designType = AnswerDesignTypes.CUSTOM;
        break;

      default:
        state.designType = AnswerDesignTypes.TEXT;
    }

    if (data.list_style) {
      switch (data.list_style) {
        case 'disc':
          state.listStyle = AnswerBulletsTypes.DISC;
          break;

        case 'decimal':
          state.listStyle = AnswerBulletsTypes.DECIMAL;
          break;

        case 'letter':
          state.listStyle = AnswerBulletsTypes.LETTER;
          break;

        default:
          state.listStyle = AnswerBulletsTypes.NONE;
      }
    } else {
      state.listStyle = undefined;
    }

    if (data.slider) {
      state.slider = {
        dotCheckedColor: data.slider.dot_checked_color ?? undefined,
        dotColor: data.slider.dot_color ?? undefined,
        dotSize: data.slider.dot_size ?? undefined,
        lineColor: data.slider.line_color ?? undefined
      };
    } else {
      state.slider = undefined;
    }

    state.circleColor = data?.custom_checkmark_circle;
    state.checkedCircleColor = data?.custom_radio;
    state.checkmarkColor = data?.custom_checkmark;
    state.padding = data?.answer_padding ? Number(data?.answer_padding) : 5;
    state.margin = data?.answer_margin ? Number(data?.answer_margin) : 1;
    state.width = data?.width;
    state.alignment = data?.answer_alignment ?? AlignContentType.CENTER;
    state.backgroundColor = data?.bullets_bg_color;
    state.textColor = data?.answer_color;

    if (data?.active_state_bg_color || data?.active_state_color) {
      state.activeAnswer = {
        backgroundColor: data?.active_state_bg_color,
        textColor: data?.active_state_color
      };
    } else {
      state.activeAnswer = undefined;
    }

    if (data?.focus_state_background || data?.focus_state_text) {
      state.focusAnswer = {
        backgroundColor: data?.focus_state_background,
        textColor: data?.focus_state_text
      };
    } else {
      state.focusAnswer = undefined;
    }

    if (data?.border_thickness) {
      state.border = {
        color: data?.border_color,
        thickness: Number(data.border_thickness)
      };
    } else {
      state.border = undefined;
    }

    if (data?.answer_color_type === 'container') {
      state.validationType = AnswerValidationTypes.CONTAINER;
    } else {
      state.validationType = AnswerValidationTypes.LABEL;
    }

    switch (data.answer_font_type) {
      case 'p':
        state.fontType = AnswerFontTypes.P;
        break;

      case 'h2':
        state.fontType = AnswerFontTypes.H2;
        break;

      case 'h3':
        state.fontType = AnswerFontTypes.H3;
        break;

      case 'h5':
        state.fontType = AnswerFontTypes.H5;
        break;

      case 'h6':
        state.fontType = AnswerFontTypes.H6;
        break;

      default:
        state.fontType = AnswerFontTypes.H4;
    }

    state.enableAnswerOpacity = data?.enable_answer_opacity !== '0';

    if (useDevice().isMobile) {
      if (data.answer_columns) {
        switch (data.answer_columns) {
          case 'one_column':
            state.imageColumns = ImageColumnsTypes.ONE_COLUMN;
            break;

          case 'two_columns':
            state.imageColumns = ImageColumnsTypes.TWO_COLUMNS;
            break;

          default:
            state.imageColumns = ImageColumnsTypes.NONE;
        }
      } else {
        state.imageColumns = undefined;
      }
    } else if (data.answer_columns_desktop) {
      switch (data.answer_columns_desktop) {
        case 'question__answers--images-desktop-1':
          state.imageColumns = ImageColumnsTypes.ONE_COLUMN;
          break;

        case 'question__answers--images-desktop-2':
          state.imageColumns = ImageColumnsTypes.TWO_COLUMNS;
          break;

        case 'question__answers--images-desktop-3':
          state.imageColumns = ImageColumnsTypes.THREE_COLUMNS;
          break;

        default:
          state.imageColumns = ImageColumnsTypes.NONE;
      }
    } else {
      state.imageColumns = undefined;
    }

    if (data?.image_effect) {
      state.imageEffect = state.imageEffect ?? {};

      switch (data.image_effect) {
        case 'border':
          state.imageEffect.type = ImageEffectTypes.BORDER;
          break;

        case 'grayscale':
          state.imageEffect.type = ImageEffectTypes.GREYSCALE;
          break;

        case 'zoom_out':
          state.imageEffect.type = ImageEffectTypes.ZOOM_OUT;
          break;

        case 'zoom_in':
          state.imageEffect.type = ImageEffectTypes.ZOOM_IN;
          break;

        case 'overlay':
          state.imageEffect.type = ImageEffectTypes.OVERLAY;
          break;

        case 'none':
          state.imageEffect.type = ImageEffectTypes.NONE;
          break;

        default:
          state.imageEffect.type = undefined;
      }

      state.imageEffect.color = data?.image_effect_color;
      state.imageEffect.borderThickness = data?.image_border_thickness
        ? Number(data?.image_border_thickness)
        : undefined;
    } else {
      state.imageEffect = undefined;
    }

    state.elementStyling = state.elementStyling ?? { answer: {} };
    state.elementStyling.answer.active = AnswerLayoutSettingsModel.constructAnswerActiveStyle(state);
    state.elementStyling.answer.normal = AnswerLayoutSettingsModel.constructAnswerNormalStyle(state);
    state.elementStyling.answer.focus = AnswerLayoutSettingsModel.constructAnswerFocusStyle(state);
  }

  private static constructAnswerNormalStyle(state: AnswerLayoutSettingsState): string {
    let CSSString = '';

    if (state.textColor && state.textColor !== '') {
      CSSString += `color: ${state.textColor} !important;`;
    }

    if (state.border?.color && state.border?.thickness) {
      CSSString += `border: ${state.border.thickness}px solid ${state.border.color};`;
    }

    if (state.backgroundColor) {
      CSSString += `background-color: ${state.backgroundColor};`;
      CSSString += 'margin-bottom: 0px;';
    }

    if (state.margin) {
      CSSString += `margin-bottom: ${state.margin}px;`;
    }

    if (state.padding) {
      CSSString += `padding-bottom: ${Math.ceil(state.padding / 2) / 10}rem;`;
      CSSString += `padding-top: ${Math.floor(state.padding / 2) / 10}rem;`;

      if (state.designType === AnswerDesignTypes.BULLETS && state.listStyle === AnswerBulletsTypes.DISC) {
        CSSString += 'list-style-position: inside;';
        CSSString += 'text-indent: 0;';
      }
    }

    if (!useDevice().isMobile && state.width) {
      CSSString += `width: ${state.width};`;
    }

    return CSSString;
  }

  private static constructAnswerActiveStyle(state: AnswerLayoutSettingsState): string {
    let CSSString = '';

    if (state.activeAnswer?.backgroundColor) {
      CSSString += `background-color: ${state.activeAnswer.backgroundColor};`;
    }

    if (state.activeAnswer?.textColor) {
      CSSString += `color: ${state.activeAnswer.textColor};`;
    }

    return CSSString;
  }

  private static constructAnswerFocusStyle(state: AnswerLayoutSettingsState): string {
    let CSSString = '';

    if (state.focusAnswer?.backgroundColor) {
      CSSString += `background-color: ${state.focusAnswer.backgroundColor};`;
    }

    if (state.focusAnswer?.textColor) {
      CSSString += `color: ${state.focusAnswer.textColor};`;
    }

    return CSSString;
  }
}
