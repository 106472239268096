import Toast from 'vue-toastification';
import type { App } from 'vue';

export default (app: App<Element>) => {
  // @ts-ignore
  app.use(typeof Toast.default !== 'undefined' ? Toast.default : Toast, {
    toastClassName: 'lf-toast',
    bodyClassName: 'lf-toast__body',
    closeButtonClassName: 'lf-toast__close',
    icon: false,
    hideProgressBar: true,
    timeout: false
  });

  return {};
};
