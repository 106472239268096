<template>
  <teleport to="#app">
    <p-modal-plain
      :show.prop="true"
      :ready="contentAddModalReady"
      headline="Blocks"
      position="top"
      @close-request="$emit('close-request')"
    >
      <p-container align-items="flex-start">
        <p-row justify-content="space-between">
          <p-tabs v-if="tabs && tabs.length > 1">
            <p-tabs-item
              v-for="tab in tabs"
              :key="tab.id ?? tab.label"
              :selected.prop="tab.label?.toLocaleLowerCase() === selectedTab"
              :text="tab.label"
              size="medium"
              :disabled.prop="isLoadingCategories || isLoadingAddons"
              @select="setActiveTab(tab)"
            />
          </p-tabs>

          <p-row v-if="initialLoadDone" class="content-filtering" gap="small">
            <p-input
              v-if="selectedTab !== 'grid'"
              :value="search"
              :disabled.prop="isLoadingAddons || isLoadingCategories"
              placeholder="Search"
              @change="search = $event.detail[0]"
            />

            <p-select
              v-if="categories.length > 1"
              searchable
              :disabled.prop="isLoadingAddons || isLoadingCategories"
              @select="onUpdateCategory($event.detail[0])"
            >
              <p-select-option
                v-for="category in categories"
                :key="category.id"
                :value="String(category.id)"
                :selected.prop="String(category.id) === selectedCategory"
                >{{ category.label }}</p-select-option
              >
            </p-select>
          </p-row>
        </p-row>

        <template v-if="!isLoadingAddons && !isLoadingCategories">
          <template v-if="filteredAddons.length > 0">
            <p-row gap="small" align-items="stretch">
              <p-card
                v-for="addon in filteredAddons"
                :key="addon.label"
                :headline="addon.label"
                :icon="addon.icon"
                can-select
                @click="onAddItem(String(addon.id))"
              />
            </p-row>
          </template>
          <p-message v-else type="info" display="inline" description="No results found" />
        </template>
        <template v-else>
          <p-container align-items="center">
            <p-loading size="extra-large" />
          </p-container>
        </template>
      </p-container>
    </p-modal-plain>

    <p-modal-loader v-if="isAddingItem" />
  </teleport>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import useAxios from '@/src/hooks/useAxios';
import { stringToSlug } from '@/src/utilities/Utilities';
import type ColumnModel from '@/src/components/layout/column/ColumnModel';
import type { APICategory, Addon, TabItem } from '@/src/components/components/editing/Modals/interfaces';
import { useCampaignStore } from '@/src/store/campaign';
import { getAdminUiEndpoint } from '@/src/utilities/Url';

const tabItems: TabItem[] = [
  {
    id: 1,
    label: 'Element',
    category: 'element'
  },
  {
    id: 2,
    label: 'Addons',
    category: 'addon'
  }
];

export default defineComponent({
  name: 'ModalContentAddAddon',
  inheritAttrs: false,
  props: {
    columnModel: {
      type: Object as PropType<ColumnModel>,
      required: true
    }
  },
  emits: ['close-request'],
  setup(props, context) {
    const campaignStore = useCampaignStore();

    const selectedTab = ref('');

    if (tabItems && tabItems[0].label) {
      selectedTab.value = tabItems[0].label.toLocaleLowerCase();
    }

    const selectedCategory = ref<string | number>('all');
    const campaignState = campaignStore.model?.state;
    const tabs = ref<TabItem[]>(tabItems);
    const isLoadingCategories = ref(true);
    const isLoadingAddons = ref(true);
    const isSaving = ref(false);
    const isAddingItem = ref(false);
    const categories = ref<APICategory[]>([]);
    const addons = ref<Addon[]>([]);
    const contentAddModalReady = ref(false);
    const initialLoadDone = ref(false);
    const search = ref('');

    const defaultParameters = reactive({
      category: 'element',
      pageId: props.columnModel.row.section.state.id
    });

    const subCategory = ref<string | number>('all');

    const onFetchCategories = async () => {
      const categoriesUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/addonSubcategories?campaign_id=${
        campaignState?.id
      }&page_id=${defaultParameters.pageId}&category=${encodeURIComponent(defaultParameters.category)}&vue=1`;
      const { fetchData } = useAxios<APICategory[]>(categoriesUrl);
      const response = await fetchData();

      isLoadingCategories.value = false;
      categories.value = response;
    };

    const onFetchAddons = async () => {
      const addonsUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/addonsByCategory?campaign_id=${
        campaignState?.id
      }&page_id=${defaultParameters.pageId}&category=${encodeURIComponent(
        defaultParameters.category
      )}&subcategory=${encodeURIComponent(subCategory.value)}&vue=1`;
      const { fetchData } = useAxios<Addon[]>(addonsUrl);
      const response = await fetchData();

      isLoadingAddons.value = false;
      initialLoadDone.value = true;

      if (response) {
        addons.value = Object.values(response).map((addon) => {
          return {
            faq: addon.faq,
            icon: addon.icon,
            id: addon.id,
            label: addon.label
          };
        });
      }
    };

    const setActiveTab = async (value: TabItem) => {
      categories.value = [];
      addons.value = [];
      isLoadingCategories.value = true;
      initialLoadDone.value = false;
      isLoadingAddons.value = true;
      selectedCategory.value = 'all';
      subCategory.value = 'all';

      if (value && value.category) {
        defaultParameters.category = value.category;
      }

      await onFetchCategories();
      await onFetchAddons();

      if (value && value.label) {
        selectedTab.value = value.label.toLocaleLowerCase();
      }
    };

    const onUpdateCategory = async (categoryId: string) => {
      subCategory.value = categoryId;
      await onFetchAddons();
      selectedCategory.value = categoryId;
    };

    const onAddItem = async (addonId: string) => {
      if (isAddingItem.value) {
        return;
      }

      isAddingItem.value = true;
      await props.columnModel.addAddon(addonId, null);
      isAddingItem.value = false;
      context.emit('close-request');
    };

    const filteredAddons = computed<Addon[]>(() => {
      if (search.value !== '') {
        return addons.value.filter((block) => {
          return block.label.toLowerCase().includes(search.value.toLowerCase());
        });
      }

      return addons.value;
    });

    onMounted(async () => {
      await onFetchCategories();
      await onFetchAddons();
      contentAddModalReady.value = true;
    });

    return {
      onUpdateCategory,
      selectedCategory,
      selectedTab,
      addons,
      categories,
      tabs,
      contentAddModalReady,
      isLoadingCategories,
      isLoadingAddons,
      isSaving,
      setActiveTab,
      stringToSlug,
      search,
      onAddItem,
      initialLoadDone,
      isAddingItem,
      filteredAddons
    };
  }
});
</script>

<style scoped lang="scss">
p-card {
  flex-grow: 1;
  max-width: 165px;
}

.content-filtering {
  width: auto;

  p-input {
    width: 165px;
  }

  p-select {
    width: 165px;
  }
}
</style>
