import type { Component } from 'vue';
import type { IntegrationJumboData } from '@/src/components/integrations/custom/jumbo/Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationJumboState extends BaseIntegrationState {
  fieldsMapped: number[];
  parameterName?: string;
}

export class IntegrationJumboModel extends BaseIntegration<IntegrationJumboData, IntegrationJumboState> {
  parse(data: IntegrationJumboData) {
    super.parse(data);

    this.state.fieldsMapped = data.settings?.jwt_fields_mapped ?? [];
    this.state.parameterName = data.settings?.jwt_parameter_name;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/jumbo/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
