import { SdkPopoverModel } from '../models/popover';
import { useCampaignStore } from '@/src/store/campaign';

export class SdkPopoverApi {
  public static async getAll(): Promise<SdkPopoverModel[]> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    return (
      campaignStore.model?.state.popovers.map((popover) => {
        return new SdkPopoverModel(popover);
      }) ?? []
    );
  }

  public static async makeActive(id: number): Promise<void> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    const popover = campaignStore.model?.state.popovers.find((popover) => popover.state.id === id);

    if (!popover) {
      throw new Error('Unrecognized popover id. Not found in collection');
    }

    if (
      popover.state.config.settings.state.advanced.visibilityCondition &&
      !popover.state.config.settings.state.advanced.visibilityCondition?.check()
    ) {
      throw new Error('Cannot go to popover that user is not allowed to see (visibility conditions)');
    }

    campaignStore.setActivePopover(id);
  }
}
