import type { GameIndicator, GameState, GameTimeChallenge, HasGameTimeChallenge } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type {
  GameBounceBattleData,
  GameBounceBattleDifficulties,
  GameBounceBattleGeneralData,
  GameBounceBattleVisualsBallData,
  GameBounceBattleVisualsDividerData,
  GameBounceBattleVisualsPaddlesData,
  GameBounceBattleGeneralSizeData,
  BounceBattleGameLevel,
  BounceBattleObjectsTypes
} from '@/src/components/games/bounce-battle/Data';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import { Orientation } from '@/src/components/games/bounce-battle/View.vue';

interface GameBounceBattleGeneralState {
  pointsToWin: number;
  level: BounceBattleGameLevel;
  speed: number;
  ballSpeed: number;
}

export interface GameBounceBattleVisualsPaddleState {
  type: BounceBattleObjectsTypes;
  color?: string;
  length?: number;
  thickness?: number;
  image?: {
    src: string;
    maxWidth?: number;
  };
}

export interface GameBounceBattleVisualsPaddlesState {
  differentLayout: boolean;
  enemy?: GameBounceBattleVisualsPaddleState;
  player: GameBounceBattleVisualsPaddleState;
}

export interface GameBounceBattleVisualsBallState {
  type: BounceBattleObjectsTypes;
  color?: string;
  size: number;
  image?: {
    src: string;
    maxWidth?: number;
  };
}

export interface GameBounceBattleVisualsDividerState {
  color?: string;
  gap?: number;
  thickness?: number;
  enabled: boolean;
}

interface GameBounceBattleVisualsState {
  height: string;
  type: Orientation;
  paddles: GameBounceBattleVisualsPaddlesState;
  ball: GameBounceBattleVisualsBallState;
  divider: GameBounceBattleVisualsDividerState;
}

interface GameBounceBattleState extends GameState {
  general: GameBounceBattleGeneralState;
  visuals: GameBounceBattleVisualsState;
}

interface DifficultyLevels {
  very_easy: Difficulty;
  easy: Difficulty;
  normal: Difficulty;
  hard: Difficulty;
}

interface Difficulty {
  speed: number;
  ballSpeed: number;
}

interface DefaultDifficulties {
  desktop: DifficultyLevels;
  mobile: DifficultyLevels;
}

export class GameBounceBattleModel
  extends GameModel<GameBounceBattleData, GameBounceBattleState>
  implements HasGameTimeChallenge
{
  parseGame(data: GameBounceBattleData) {
    const state = this.state;

    state.visuals = GameBounceBattleModel.constructVisualsState(data);
    state.general = GameBounceBattleModel.constructGeneralState(data.general);

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.general?.time === 1,
      ...(data.general?.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  private static constructGeneralState(data: GameBounceBattleGeneralData): GameBounceBattleGeneralState {
    const deviceDifficultiesData = getDeviceData<GameBounceBattleDifficulties>(data.difficulties);
    const isMobile = useDevice().isMobile;

    const defaultDifficulties: DefaultDifficulties = {
      desktop: {
        very_easy: { speed: 300, ballSpeed: 200 },
        easy: { speed: 600, ballSpeed: 300 },
        normal: { speed: 800, ballSpeed: 400 },
        hard: { speed: 1000, ballSpeed: 600 }
      },
      mobile: {
        very_easy: { speed: 170, ballSpeed: 70 },
        easy: { speed: 300, ballSpeed: 200 },
        normal: { speed: 400, ballSpeed: 250 },
        hard: { speed: 500, ballSpeed: 400 }
      }
    };

    const level = deviceDifficultiesData?.level ?? 'easy';
    const isCustom = deviceDifficultiesData?.custom_enabled === '1';
    let difficulty: Difficulty;

    if (isCustom) {
      difficulty = {
        speed: Number(deviceDifficultiesData?.custom_enemy_speed) ?? 600,
        ballSpeed: Number(deviceDifficultiesData?.custom_ball_speed) ?? 300
      };
    } else if (isMobile) {
      switch (level) {
        case 'very_easy':
          difficulty = defaultDifficulties.mobile.very_easy;
          break;

        case 'easy':
          difficulty = defaultDifficulties.mobile.easy;
          break;

        case 'normal':
          difficulty = defaultDifficulties.mobile.normal;
          break;

        case 'hard':
          difficulty = defaultDifficulties.mobile.hard;
          break;
      }
    } else {
      switch (level) {
        case 'very_easy':
          difficulty = defaultDifficulties.desktop.very_easy;
          break;

        case 'easy':
          difficulty = defaultDifficulties.desktop.easy;
          break;

        case 'normal':
          difficulty = defaultDifficulties.desktop.normal;
          break;

        case 'hard':
          difficulty = defaultDifficulties.desktop.hard;
          break;
      }
    }

    return {
      pointsToWin: Number(data?.points_to_win) ?? 10,
      level,
      ...difficulty
    };
  }

  private static constructVisualsState(data: GameBounceBattleData): GameBounceBattleVisualsState {
    const deviceSizeData = getDeviceData<GameBounceBattleGeneralSizeData>(data.general.size);
    const devicePaddlesData = getDeviceData<GameBounceBattleVisualsPaddlesData>(data.visuals.paddles);
    const deviceBallData = getDeviceData<GameBounceBattleVisualsBallData>(data.visuals.ball);
    const deviceDividerData = getDeviceData<GameBounceBattleVisualsDividerData>(data.visuals.divider);
    const deviceDifficultiesData = getDeviceData<GameBounceBattleDifficulties>(data.general.difficulties);
    return {
      height: deviceSizeData?.height ?? '500',
      type: deviceDifficultiesData?.type === 'horizontal' ? Orientation.LANDSCAPE : Orientation.PORTRAIT,
      paddles: {
        // if the paddles should have same layout
        differentLayout: devicePaddlesData?.use_same_paddle_layout === '1',

        ...(devicePaddlesData?.use_same_paddle_layout === '1' && {
          enemy: {
            type: devicePaddlesData?.bot.type ?? 'icon',
            ...(devicePaddlesData?.bot.length && { length: Number(devicePaddlesData.bot.length) }),
            ...(devicePaddlesData?.bot.thickness && { thickness: Number(devicePaddlesData.bot.thickness) }),
            ...(devicePaddlesData?.bot.color && { color: devicePaddlesData.bot.color }),
            ...(devicePaddlesData?.bot.image && {
              image: {
                src: devicePaddlesData.bot.image,
                ...(devicePaddlesData?.bot.image_max_width && {
                  maxWidth: parseInt(devicePaddlesData.bot.image_max_width, 10)
                })
              }
            })
          }
        }),

        player: {
          type: devicePaddlesData?.player.type ?? 'icon',
          ...(devicePaddlesData?.player?.length && { length: Number(devicePaddlesData.player.length) }),
          ...(devicePaddlesData?.player?.thickness && { thickness: Number(devicePaddlesData.player.thickness) }),
          ...(devicePaddlesData?.player?.color && { color: devicePaddlesData.player.color }),
          ...(devicePaddlesData?.player.image && {
            image: {
              src: devicePaddlesData.player.image,
              ...(devicePaddlesData?.player.image_max_width && {
                maxWidth: parseInt(devicePaddlesData.player.image_max_width, 10)
              })
            }
          })
        }
      },
      ball: {
        type: deviceBallData?.type ?? 'icon',
        size: Number(deviceBallData?.size ?? 50),
        ...(deviceBallData?.color && { color: deviceBallData?.color }),
        ...(deviceBallData?.image && {
          image: {
            src: deviceBallData.image,
            ...(deviceBallData?.image_max_width && {
              maxWidth: parseInt(deviceBallData.image_max_width, 10)
            })
          }
        })
      },
      divider: {
        enabled: deviceDividerData?.enabled === '1',
        ...(deviceDividerData?.color && { color: deviceDividerData?.color }),
        ...(deviceDividerData?.gap && { gap: Number(deviceDividerData?.gap) }),
        ...(deviceDividerData?.thickness && { thickness: Number(deviceDividerData?.thickness) })
      }
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'opponent_score',
        metricKey: {
          opponent_score_used: 'opponent_score'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          opponent_score: 0
        }
      },
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 0,
          time_used: 0
        }
      },
      {
        indicatorKey: 'score',
        metricKey: {
          score_used: 'score'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          score: 0
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }
}
