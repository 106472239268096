import type { IntegrationRecaptchaData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationRecaptchaState extends BaseIntegrationState {
  recaptchaSiteKey?: string;
}

export class IntegrationRecaptchaModel extends BaseIntegration<IntegrationRecaptchaData, IntegrationRecaptchaState> {
  parse(data: IntegrationRecaptchaData) {
    super.parse(data);
    this.state.recaptchaSiteKey = data.settings?.recaptcha_site_key;
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
