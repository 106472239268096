<template>
  <div class="row section flow__page" :class="{ 'flow__page--ready': isReady }">
    <div class="col section__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, getCurrentInstance, nextTick, ref } from 'vue';
import type { FlowAnimation } from '@/src/components/Flow/Types';
import { defaultEnterAnimation, defaultLeaveAnimation } from '@/src/components/Flow/Types';
import { recursivelyWaitForPromises } from '@/src/utilities/virtualDom';

export default defineComponent({
  name: 'FlowPage',
  components: {},
  inheritAttrs: false,
  props: {
    animationEnter: {
      type: Object as PropType<FlowAnimation>,
      default: () => {
        return defaultEnterAnimation;
      }
    },
    animationLeave: {
      type: Object as PropType<FlowAnimation>,
      default: () => {
        return defaultLeaveAnimation;
      }
    }
  },
  emits: ['entered'],
  setup(props, context) {
    const isReady = ref(false);
    const currentInstance = getCurrentInstance();
    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const enterAnimation = (): FlowAnimation => {
      return props.animationEnter;
    };

    const leaveAnimation = (): FlowAnimation => {
      return props.animationLeave;
    };

    nextTick(async () => {
      if (currentInstance?.vnode) {
        await recursivelyWaitForPromises(currentInstance.vnode, 'onBeforeEnter', 3);
      }

      if (readyPromiseResolve) {
        isReady.value = true;

        readyPromiseResolve();
      }
    });

    return {
      enterAnimation,
      leaveAnimation,
      isReady,
      onBeforeEnter: async () => {
        await readyPromise;
      },
      onAfterEnter: () => {
        context.emit('entered');
      }
    };
  }
});
</script>
