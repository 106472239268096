import type {
  AddonGoogleMapData,
  AddonGoogleMapSettingsData,
  GoogleMarkerData
} from '@/src/components/addons/googlemap/Data';
import type { GoogleMapDisplayColor } from '@/src/typings/enums/enums';
import { GoogleMapType } from '@/src/typings/enums/enums';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

export interface AddonGoogleMapsMarkers {
  address?: string;
  addressLatLng?: string;
  infoWindow?: boolean;
  infoWindowAutoShow?: boolean;
  infoWindowContent?: string;
}

export interface AddonGoogleMapsSettingsState {
  address: string;
  addressLatLng: string;
  zoom: number;
  type: GoogleMapType;
  displayColor?: GoogleMapDisplayColor;
  height: number;
  locked?: boolean;
  cluster?: boolean;
  zoomToFit?: boolean;
  searchEnabled?: boolean;
  marker?: boolean;
  markerIcon?: string;
  infoWindow?: boolean;
  infoWindowAutoShow?: boolean;
  infoWindowContent?: string;
  markers?: Array<AddonGoogleMapsMarkers>;
}

interface AddonGoogleMapsState extends AddonModelState {
  settings: AddonGoogleMapsSettingsState;
}

export class AddonGoogleMapsModel extends AddonModel<AddonGoogleMapData, AddonGoogleMapsState, 'googlemap'> {
  parseAddon(data: AddonGoogleMapData) {
    const state = this.state;

    state.settings = state.settings || {};

    if (data.settings) {
      state.settings = AddonGoogleMapsModel.constructSettingState(data.settings);
    }
  }

  private static constructSettingState(data: AddonGoogleMapSettingsData): AddonGoogleMapsSettingsState {
    return {
      address: data.address || 'Tueager 1, 8200 Århus N',
      addressLatLng: data.address_latlng || '56.1949528,10.1752825',
      zoom: Number(data.zoom) || 12,
      type: data.type || GoogleMapType.NORMAL,
      height: Number(data?.height) || 300,
      ...(data.display_color && {
        displayColor: data?.display_color
      }),
      locked: data?.locked !== undefined ? data.locked === '1' : true,
      ...(data.cluster && { cluster: data?.cluster === '1' }),
      ...(data.zoom_to_fit && {
        zoomToFit: data?.zoom_to_fit === '1'
      }),
      ...(data.search_enabled && {
        searchEnabled: data?.search_enabled === '1'
      }),
      marker: data?.marker === '1',
      ...(data.marker_icon && { markerIcon: data?.marker_icon }),
      ...(data.infowindow && {
        infoWindow: data?.infowindow === '1'
      }),
      ...(data.infowindow_autoshow && {
        infoWindowAutoShow: data?.infowindow_autoshow === '1'
      }),
      ...(data.infowindow_content && {
        infoWindowContent: data?.infowindow_content
      }),
      ...(data.markers && {
        markers: AddonGoogleMapsModel.mapMarkers(data.markers)
      })
    };
  }

  public static mapMarkers(dataMarkerArray: GoogleMarkerData[]): AddonGoogleMapsMarkers[] {
    const mapsMarkersArr: AddonGoogleMapsMarkers[] = [];
    dataMarkerArray.forEach((marker) => {
      if (marker.address) {
        mapsMarkersArr.push({
          ...(marker.address && {
            address: marker.address
          }),
          ...(marker.address_latlng && {
            addressLatLng: marker.address_latlng
          }),
          ...(marker.infowindow && {
            infoWindow: marker.infowindow === '1'
          }),
          ...(marker.infowindow_autoshow && {
            infoWindowAutoShow: marker.infowindow_autoshow
          }),
          ...(marker.infowindow_content && {
            infoWindowContent: marker.infowindow_content
              ? marker.infowindow_content.replace(/&#60;/gi, '<').replace(/&#62;/gi, '>') // &#60 = less than(<) &#62 = greater then(>)
              : ''
          })
        });
      }
    });
    return mapsMarkersArr;
  }
}
