import type { FieldOptionItem } from './../types';
import { InputOptionType } from '@/src/typings/enums/enums';
import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export interface FormElementRadioSettingsData extends BaseFormElementSettingsData {
  option_type?: InputOptionType; // without countries
  options_interval?: {
    interval_from: string;
    interval_to: string;
  };
  options?: {
    [key: string]: string;
  };
  options_line?: string;
  paste_options?: string;
}

export interface FormElementRadioData extends BaseFormElementData {
  settings?: FormElementRadioSettingsData;
  options?: {
    [key: string]: string;
  };
}

export interface FormElementRadioState extends BaseFormElementState<string> {
  optionType?: InputOptionType; // without countries
  optionsInterval?: {
    intervalFrom: number;
    intervalTo: number;
  };
  options?: FieldOptionItem[];
  optionsLine?: string;
}

export class FormElementRadioModel extends BaseFormElementModel<string, FormElementRadioData, FormElementRadioState> {
  parseFormElement(data: FormElementRadioData) {
    this.state.optionType = data.settings?.option_type;
    this.state.optionsLine = data.settings?.options_line;

    /**
     * Handle/Support legacy settings format
     */
    if (!this.state.optionType || this.state.optionType.length === 0) {
      if (data.options) {
        this.state.options = Object.entries(data.options).map((item) => {
          return {
            label: item[1], // Value
            value: item[0], // Key
            selected: false
          };
        });
      }
    } else {
      /**
       * Newest settings format / 2021 - 2022 and forward
       */
      switch (this.state.optionType) {
        case InputOptionType.INTERVAL:
          if (data.settings?.options_interval) {
            this.state.optionsInterval = {
              intervalTo: Number(data.settings?.options_interval.interval_to),
              intervalFrom: Number(data.settings?.options_interval.interval_from)
            };

            const options: FieldOptionItem[] = [];

            for (let i = this.state.optionsInterval.intervalFrom; i <= this.state.optionsInterval.intervalTo; i++) {
              options.push({
                value: `${i}`,
                label: `${i}`
              });
            }

            this.state.options = options;
          } else {
            this.state.options = undefined;
          }
          break;

        case InputOptionType.OPTIONS:
          this.state.options = (
            data.settings?.options ? this.parseFieldOptions(data.settings.options) : undefined
          )?.map((option) => {
            return {
              label: option.label,
              value: option.value ? option.value : option.label
            };
          });
          break;

        case InputOptionType.LINE:
          this.state.options = data.options
            ? Object.entries(data.options).map<FieldOptionItem>((value) => {
                return {
                  value: value[1],
                  label: value[1]
                };
              })
            : undefined;
          break;

        case InputOptionType.PASTE:
          if (data.options && !Array.isArray(data.options)) {
            const options: FieldOptionItem[] = [];

            for (const optionIndex in data.options) {
              if (Object.prototype.hasOwnProperty.call(data.options, optionIndex)) {
                options.push({
                  value: optionIndex,
                  label: data.options[`${optionIndex}`]
                });
              }
            }

            this.state.options = options;
          } else {
            this.state.options = undefined;
          }
          break;

        default:
          this.state.options = undefined;
      }
    }
  }

  getInitialValue(): string | undefined {
    return this.getInitialStringValue();
  }

  getStringifiedValue(): string | undefined {
    return this.state.value && this.state.value !== '' ? this.state.value : undefined;
  }

  parseStringValue(value: string): string | undefined {
    return value;
  }

  getSerializedPostValue(): string {
    return this.state.value ?? '';
  }

  getSerializedCookieValue(): string {
    return this.state.value ?? '';
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
