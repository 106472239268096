export interface ColorRGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}

export const isBetweenNumber = (number: number, first: number, last: number): boolean => {
  return (number - first) * (number - last) <= 0;
};

export const isRgbValid = (rgb: ColorRGBA): boolean => {
  return isBetweenNumber(rgb.r, 0, 255) && isBetweenNumber(rgb.g, 0, 255) && isBetweenNumber(rgb.b, 0, 255);
};

export const rgbaToRGBAObject = (color: string): ColorRGBA | undefined => {
  // eslint-disable-next-line security/detect-unsafe-regex
  const match = color.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);

  if (match) {
    const arr = [match[1], match[2], match[3]];
    if (match[4]) {
      arr.push(match[4]);
    }
    return {
      r: Number(arr[0]),
      g: Number(arr[1]),
      b: Number(arr[2]),
      a: Number(arr[3])
    };
  }

  return undefined;
};

export const parseColorToRGBA = (color: string): ColorRGBA | undefined => {
  if (color[0] === '#') {
    return hexToRGBAObject(color);
  }
  return rgbaToRGBAObject(color);
};

export const hexToRGBAObject = (hex: string | undefined, alpha = 1): ColorRGBA | undefined => {
  if (!hex) {
    return undefined;
  }

  if (hex.length === 4) {
    hex += hex.substr(1);
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return undefined;
  }

  return {
    r,
    g,
    b,
    a: alpha
  };
};
