import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import type {
  GameGuessTheWordAdvancedData,
  GameGuessTheWordData,
  GameGuessTheWordGeneralData,
  GameGuessTheWordWordsData
} from '@/src/components/games/guesstheword/Data';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { randomIndexes, shuffleLetters } from '@/src/utilities/Utilities';

export interface GameGuessTheWordGeneralState {
  correctAnswers: number;
  showLetters: boolean;
  addTip: boolean;
  nextIfCorrect: boolean;
  answerBtn: string;
  selectAnswerBtn: string;
  enableBestTime: boolean;
  enableTimeRanking: boolean;
}

export interface WordRandomLetters {
  space: boolean;
  used: boolean;
  value: string;
  word: number;
}

export interface WordLetters {
  space: boolean;
  correct: string;
  value: string;
  word: number;
}

export interface GameGuessTheWordWordsState {
  id: string;
  tip: string;
  word: string;
  visibilityCondition?: VisibilityConditionsModel;
  letters?: WordLetters[];
  randomLetters?: WordRandomLetters[];
  words?: string[];
}

export interface GameGuessTheWordAdvancedState {
  skipWordEnabled: boolean;
  skipWordLabel?: string;
}

export interface GameGuessTheWordState {
  general: GameGuessTheWordGeneralState;
  words: GameGuessTheWordWordsState[];
  advanced: GameGuessTheWordAdvancedState;
}

export class GameGuessTheWordModel
  extends GameModel<GameGuessTheWordData, GameGuessTheWordState>
  implements HasGameTimeChallenge
{
  parseGame(data: GameGuessTheWordData): void {
    const state = this.state;
    state.general = GameGuessTheWordModel.constructGeneralState(data.general);

    state.words = [];

    for (const key in data.words.words) {
      if (Object.hasOwnProperty.call(data.words.words, key)) {
        state.words.push(GameGuessTheWordModel.constructWordsState(data.words.words[Number(key)], state.general));
      }
    }

    state.advanced = GameGuessTheWordModel.constructAdvancedState(data?.advanced);
  }

  private static constructAdvancedState(data: GameGuessTheWordAdvancedData | undefined): GameGuessTheWordAdvancedState {
    return {
      skipWordEnabled: data?.skip_word === '1',
      ...(data?.skip_word_label && { skipWordLabel: data.skip_word_label })
    };
  }

  private static constructWordsState(
    data: GameGuessTheWordWordsData,
    generalState: GameGuessTheWordGeneralState
  ): GameGuessTheWordWordsState {
    const randomLetters = GameGuessTheWordModel.constructWordsRandomLetterState(data.word);

    return {
      id: data.id,
      tip: data.tip,
      word: data.word,
      ...(data.visibility_condition?.date_range?.enabled && {
        visibilityCondition: new VisibilityConditionsModel(data.visibility_condition)
      }),
      randomLetters,
      letters: GameGuessTheWordModel.constructWordsLetterState(data.word, generalState),
      words: data.word.split(' ')
    };
  }

  private static constructWordsRandomLetterState(word: string) {
    let randomWords = shuffleLetters(word);

    let i = 0;
    while (randomWords === word && i < 100) {
      randomWords = shuffleLetters(word);
      i++;
    }

    let wordCount = 0;

    const randomLetters = [];
    for (let i = 0; i < randomWords.length; i++) {
      randomLetters.push({
        // eslint-disable-next-line security/detect-object-injection
        value: randomWords[i].toLowerCase(),
        used: false,
        // eslint-disable-next-line security/detect-object-injection
        space: randomWords[i] === ' ',
        word: wordCount
      });

      // eslint-disable-next-line security/detect-object-injection
      if (randomWords[i] === ' ') {
        wordCount++;
      }
    }

    return randomLetters;
  }

  private static constructWordsLetterState(word: string, generalState: GameGuessTheWordGeneralState) {
    const letters = [];
    let wordCount = 0;
    for (let i = 0; i < word.length; i++) {
      letters.push({
        value: '',
        // eslint-disable-next-line security/detect-object-injection
        correct: word[i],
        // eslint-disable-next-line security/detect-object-injection
        space: word[i] === ' ',
        word: wordCount
      });

      // eslint-disable-next-line security/detect-object-injection
      if (word[i] === ' ') {
        wordCount++;
      }
    }
    const randIndex: number[] = randomIndexes(word);

    if (generalState?.addTip && letters.length > 0) {
      for (const index in randIndex) {
        const indexValue = randIndex[Number(index)];
        letters[Number(indexValue)].value = letters[Number(indexValue)].correct;
      }
    }

    return letters;
  }

  private static constructGeneralState(data: GameGuessTheWordGeneralData): GameGuessTheWordGeneralState {
    return {
      correctAnswers: Number(data.correct_answers),
      showLetters: data.show_letters === '1',
      addTip: data.add_tip === '1',
      nextIfCorrect: data.next_if_correct === '1',
      answerBtn: data.answer_btn,
      selectAnswerBtn: data.select_answer_btn !== '' ? data.select_answer_btn : 'V\u00E6lg svar',
      enableBestTime: data.enable_best_time === '1',
      enableTimeRanking: data.enable_best_time === '1'
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 60,
          time_used: 1
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }
}
