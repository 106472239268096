import { SdkColumnModel } from './column';
import type RowModel from '@/src/components/layout/row/RowModel';

export class SdkRowModel {
  #model: RowModel;

  constructor(model: RowModel) {
    this.#model = model;
  }

  public get Columns() {
    // eslint-disable-next-line no-console
    console.warn('[Playable] SdkRowModel.Columns is deprecated, use SdkRowModel.columns instead');
    return this.columns;
  }

  public get columns() {
    return this.#model.state.columns.map((column) => new SdkColumnModel(column));
  }

  public get id(): string {
    return String(this.#model.state.id);
  }

  public get label(): string | undefined {
    return this.#model.state.label;
  }
}
