import { TransitionEnterType } from '@/src/typings/enums/enums';

export interface FlowAnimation {
  name: string;
  duration: number;
  delay: number;
}

export const defaultEnterAnimation = {
  name: TransitionEnterType.FADE_IN,
  duration: 525,
  delay: 0
};

export const defaultLeaveAnimation = {
  name: TransitionEnterType.FADE_OUT,
  duration: 525,
  delay: 0
};
