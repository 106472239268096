import type { HasGameTimeChallenge, GameIndicator, GameTimeChallenge } from './../../../models/GameModel';
import { GameModel } from './../../../models/GameModel';
import type {
  GameDropGameData,
  GameDropGameGeneralData,
  GameDropGameGeneralHeightData,
  GameDropGameGeneralWidthData,
  GameDropGameImagesData,
  GameDropGameImagesSymbolData
} from '@/src/components/games/dropgame/Data';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import useDevice from '@/src/hooks/useDevice';
import { transformMeasurementToNumber } from '@/src/utilities/Utilities';
import type { TransitionTypes } from '@/src/typings/types/types';
import { useUtilityStore } from '@/src/store/utility';

export interface GameDropGameGeneralHeightState {
  height: number;
  playerOffsetY: number;
  type: DropGameHeightType;
}

export interface GameDropGameGeneralWidthState {
  bucket: number;
  drop: number;
}

export interface GameDropGameGeneralState {
  pointsToWin: number;
  positiveColor: string;
  negativeColor: string;
  fontSize: string;
  fontFamily?: string;
  height?: GameDropGameGeneralHeightState;
  width?: GameDropGameGeneralWidthState;
  enableBestTime?: boolean;
  enableTimeRanking?: boolean;
  enableScoreTop10?: boolean;
}

export interface GameDropGameImagesSymbolState {
  id: number;
  image: string;
  points: number;
  label?: string;
}

export interface GameDropGameImagesState {
  bucketImage: string;
  symbol: GameDropGameImagesSymbolState[];
}

export interface GameDropGameAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

interface GameDropGameTop10ScoreState {
  id: number;
  score: number;
}

export interface GameDropGameRepeatableState {
  scoreTop10?: GameDropGameTop10ScoreState[];
}

export interface GameDropGameSounds {
  positive?: string;
  negative?: string;
}

export interface GameDropGameState {
  general: GameDropGameGeneralState;
  images: GameDropGameImagesState;
  repeatable?: GameDropGameRepeatableState;
  elementStyling: {
    bucketAnimation?: string;
  };
  sounds: GameDropGameSounds;
}
export enum DropGameHeightType {
  VIEWHEIGHT,
  PIXEL
}

export class GameDropGameModel extends GameModel<GameDropGameData, GameDropGameState> implements HasGameTimeChallenge {
  parseGame(data: GameDropGameData) {
    const state = this.state;

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    }

    state.repeatable = state.repeatable || {};
    if (data.repeatable) {
      state.repeatable.scoreTop10 = data.repeatable.score_top10.map((repeatableData) => {
        return {
          id: repeatableData.id,
          score: repeatableData.score
        };
      });
    }

    state.sounds = {
      positive: data.advanced?.plus_sound,
      negative: data.advanced?.minus_sound
    };

    state.general = GameDropGameModel.constructGeneralState(data.general);

    if (state.general.fontFamily) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(state.general.fontFamily);
    }

    if (data.general.height) {
      state.general.height = GameDropGameModel.contructGeneralHeightDeviceState(data.general.height);
    }

    if (data.general.width) {
      state.general.width = GameDropGameModel.parseGeneralWidthDeviceData(data.general.width);
    }

    state.images = GameDropGameModel.constructImagesState(data.images);

    state.elementStyling = {};
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  private static constructImagesState(data: GameDropGameImagesData): GameDropGameImagesState {
    return {
      bucketImage: data.bucket_image,
      symbol: Object.keys(data.symbol).map((key) => {
        return GameDropGameModel.constructImagesSymbolState(data.symbol[Number(key)]);
      })
    };
  }

  private static constructImagesSymbolState(data: GameDropGameImagesSymbolData): GameDropGameImagesSymbolState {
    return {
      id: Number(data.id),
      image: data.image,
      ...(data?.label && { label: data.label }),
      points: Number(data.points)
    };
  }

  private static parseGeneralWidthDeviceData(
    data: GameDropGameGeneralWidthData
  ): GameDropGameGeneralWidthState | undefined {
    if (data) {
      const useData = this.getMobileGeneralWidthDeviceData(data);
      if (!useData) {
        return undefined;
      }

      return useData;
    }
  }

  public static getMobileGeneralWidthDeviceData(data: GameDropGameGeneralWidthData): GameDropGameGeneralWidthState {
    const { isMobile } = useDevice();
    if (isMobile && data.mobile_bucket) {
      const dropWidth = transformMeasurementToNumber(data.mobile_drop ?? '');
      const bucketWidth = transformMeasurementToNumber(data.mobile_bucket);
      return {
        drop: dropWidth,
        bucket: bucketWidth
      };
    }

    const dropWidth = transformMeasurementToNumber(data.default_drop ?? data.desktop_drop ?? '');
    const bucketWidth = transformMeasurementToNumber(data.default_bucket ?? data.desktop_bucket ?? '');
    return {
      drop: dropWidth,
      bucket: bucketWidth
    };
  }

  public static contructGeneralHeightDeviceState(data: GameDropGameGeneralHeightData): GameDropGameGeneralHeightState {
    let heightType = (data.default ?? data.desktop ?? '').includes('vh')
      ? DropGameHeightType.VIEWHEIGHT
      : DropGameHeightType.PIXEL;

    if (useDevice().isMobile && data.mobile) {
      heightType = data.mobile.includes('vh') ? DropGameHeightType.VIEWHEIGHT : DropGameHeightType.PIXEL;
      return {
        height: parseInt(data.mobile, 10),
        playerOffsetY: Number(data.mobile_player_offset_y),
        type: heightType
      };
    }
    return {
      height: parseInt(data.default ?? data.desktop ?? '', 10),
      playerOffsetY: Number(data.desktop_player_offset_y),
      type: heightType
    };
  }

  private static constructGeneralState(data: GameDropGameGeneralData): GameDropGameGeneralState {
    return {
      pointsToWin: Number(data.points_towin),
      positiveColor: data.positive_color,
      negativeColor: data.negative_color,
      fontSize: data.font_size,
      fontFamily: data.font_type,
      ...(data.time_limit && { timeLimit: Number(data.time_limit) }),
      ...(data.enable_best_time && { enableBestTime: data.enable_best_time === '1' }),
      ...(data.enable_time_ranking && { enableTimeRanking: data.enable_time_ranking === '1' }),
      ...(data.enable_score_top10 && { enableScoreTop10: data.enable_score_top10 === '1' })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.ABSOLUTE,
      bottom: GameIndicatorPositionType.RELATIVE
    };
  }

  public getSounds(): string[] {
    const sounds: string[] = [];

    if (this.state.sounds.positive) {
      sounds.push(this.state.sounds.positive);
    }

    if (this.state.sounds.negative) {
      sounds.push(this.state.sounds.negative);
    }

    return sounds;
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 0,
          time_used: 0
        }
      },
      {
        indicatorKey: 'score',
        metricKey: {
          score_used: 'points_collected'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          score_used: 0
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }
}
