export enum ResultType {
  PERCENTAGE = 'percentage',
  NUMBERS = 'numbers'
}

export enum DisplayType {
  IMAGE = 'image',
  IMAGE_DESCRIPTION = 'image_description',
  TEXT = 'text',
  IMAGE_HEADLINE = 'image_headline',
  VIDEO = 'video'
}

export enum AnswerColumnType {
  NONE = 'none',
  ONE_COLUMN = 'one_column',
  TWO_COLUMN = 'two_column',
  THREE_COLUMN = 'three_column'
}

export enum VoteType {
  BUTTON = 'button_click',
  ITEM = 'item_click'
}

export enum ShowVoteType {
  CONTENT = 'content_repeater',
  GAME = 'game'
}
