import { IntegrationCarlsJrModel } from '@/src/components/integrations/custom/carlsjr/Model';
import { IntegrationCookieModel } from '@/src/components/integrations/cookie/Model';
import { IntegrationCodanModel } from '@/src/components/integrations/custom/codan/Model';
import { IntegrationEspBrazeModel } from '@/src/components/integrations/esp/braze/Model';
import { IntegrationGrundfosModel } from '@/src/components/integrations/custom/grundfos/Model';
import { IntegrationClerkModel } from '@/src/components/integrations/clerk/Model';
import { IntegrationBulkPrizeVouchersModel } from '@/src/components/integrations/bulk-prize-vouchers/Model';
import { IntegrationDanskeSpilModel } from '@/src/components/integrations/custom/danskespil/Model';
import { IntegrationScandlinesModel } from '@/src/components/integrations/custom/scandlines/Model';
import { IntegrationSportsAllianceModel } from '@/src/components/integrations/custom/sports-alliance/Model';
import { IntegrationNfcModel } from '@/src/components/integrations/custom/nfc/Model';
import { IntegrationEspClickdimensionModel } from '@/src/components/integrations/esp/clickdimension/Model';
import { IntegrationStockHandlingModel } from '@/src/components/integrations/stockhandling/Model';
import { IntegrationEspCustomKlasselotterietModel } from '@/src/components/integrations/esp/custom/klasselotteriet/Model';
import { IntegrationBezzerwisserModel } from '@/src/components/integrations/custom/bezzerwisser/Model';
import { IntegrationEspFacebookConversionModel } from '@/src/components/integrations/esp/facebook-conversion/Model';
import { IntegrationGfAdvancedMapModel } from '@/src/components/integrations/custom/gf-advanced-map/Model';
import { IntegrationCoopModel } from '@/src/components/integrations/custom/coop/Model';
import { IntegrationDagrofaModel } from '@/src/components/integrations/custom/dagrofa/Model';
import { IntegrationEspEloquaModel } from '@/src/components/integrations/esp/eloqua/Model';
import { IntegrationQiagenModel } from '@/src/components/integrations/custom/qiagen/Model';
import { IntegrationLidlModel } from '@/src/components/integrations/custom/lidl/Model';
import { IntegrationRecaptchaModel } from '@/src/components/integrations/recaptcha/Model';
import { IntegrationSaxoModel } from '@/src/components/integrations/custom/saxo/Model';
import { IntegrationKlasselotterietSegmentsModel } from '@/src/components/integrations/custom/klasselotteriet-segments/Model';
import { IntegrationKraeftensBekaempelseModel } from '@/src/components/integrations/custom/kaeftens-bekaempelse/Model';
import { IntegrationLidlProductsModel } from '@/src/components/integrations/custom/lidl-products/Model';
import { IntegrationPhilipMorrisModel } from '@/src/components/integrations/custom/philip-morris/Model';
import { IntegrationJwtModel } from '@/src/components/integrations/jwt/Model';
import { IntegrationOpenIdConnectModel } from '@/src/components/integrations/open-id-connect/Model';
import { IntegrationLobycoModel } from '@/src/components/integrations/custom/lobyco/Model';
import { IntegrationRitualsModel } from '@/src/components/integrations/custom/rituals/Model';
import { IntegrationPixelTrackingModel } from '@/src/components/integrations/pixel-tracking/Model';
import { IntegrationRaptorSmartAdvisorModel } from '@/src/components/integrations/raptor-smart-advisor/Model';
import { IntegrationParticleGeneratorModel } from '@/src/components/integrations/particle-generator/Model';
import { IntegrationCookieConsentModel } from '@/src/components/integrations/cookie-consent/Model';
import { IntegrationCookieConsentV2Model } from '@/src/components/integrations/cookie-consent-v2/Model';
import { IntegrationJumboModel } from '@/src/components/integrations/custom/jumbo/Model';

export type IntegrationModel =
  | IntegrationStockHandlingModel
  | IntegrationEspEloquaModel
  | IntegrationQiagenModel
  | IntegrationDagrofaModel
  | IntegrationCarlsJrModel
  | IntegrationLidlModel
  | IntegrationCookieModel
  | IntegrationRecaptchaModel
  | IntegrationCodanModel
  | IntegrationEspBrazeModel
  | IntegrationSaxoModel
  | IntegrationKlasselotterietSegmentsModel
  | IntegrationKraeftensBekaempelseModel
  | IntegrationLidlProductsModel
  | IntegrationPhilipMorrisModel
  | IntegrationDanskeSpilModel
  | IntegrationJwtModel
  | IntegrationOpenIdConnectModel
  | IntegrationScandlinesModel
  | IntegrationLobycoModel
  | IntegrationCoopModel
  | IntegrationGrundfosModel
  | IntegrationClerkModel
  | IntegrationBulkPrizeVouchersModel
  | IntegrationRitualsModel
  | IntegrationPixelTrackingModel
  | IntegrationSportsAllianceModel
  | IntegrationNfcModel
  | IntegrationGfAdvancedMapModel
  | IntegrationRaptorSmartAdvisorModel
  | IntegrationEspClickdimensionModel
  | IntegrationParticleGeneratorModel
  | IntegrationEspFacebookConversionModel
  | IntegrationBezzerwisserModel
  | IntegrationEspCustomKlasselotterietModel
  | IntegrationCookieConsentModel
  | IntegrationCookieConsentV2Model
  | IntegrationJumboModel;

export type IntegrationModelType =
  | typeof IntegrationStockHandlingModel
  | typeof IntegrationEspEloquaModel
  | typeof IntegrationQiagenModel
  | typeof IntegrationCookieModel
  | typeof IntegrationDagrofaModel
  | typeof IntegrationLidlModel
  | typeof IntegrationCarlsJrModel
  | typeof IntegrationRecaptchaModel
  | typeof IntegrationCodanModel
  | typeof IntegrationEspBrazeModel
  | typeof IntegrationSaxoModel
  | typeof IntegrationKlasselotterietSegmentsModel
  | typeof IntegrationKraeftensBekaempelseModel
  | typeof IntegrationLidlProductsModel
  | typeof IntegrationPhilipMorrisModel
  | typeof IntegrationDanskeSpilModel
  | typeof IntegrationJwtModel
  | typeof IntegrationOpenIdConnectModel
  | typeof IntegrationScandlinesModel
  | typeof IntegrationLobycoModel
  | typeof IntegrationCoopModel
  | typeof IntegrationGrundfosModel
  | typeof IntegrationClerkModel
  | typeof IntegrationBulkPrizeVouchersModel
  | typeof IntegrationRitualsModel
  | typeof IntegrationPixelTrackingModel
  | typeof IntegrationSportsAllianceModel
  | typeof IntegrationNfcModel
  | typeof IntegrationGfAdvancedMapModel
  | typeof IntegrationRaptorSmartAdvisorModel
  | typeof IntegrationEspClickdimensionModel
  | typeof IntegrationParticleGeneratorModel
  | typeof IntegrationEspFacebookConversionModel
  | typeof IntegrationBezzerwisserModel
  | typeof IntegrationEspCustomKlasselotterietModel
  | typeof IntegrationCookieConsentModel
  | typeof IntegrationCookieConsentV2Model
  | typeof IntegrationJumboModel;

export const IntegrationModelMapping: Record<string, IntegrationModelType> = {
  stock_handling: IntegrationStockHandlingModel,
  'esp/eloqua': IntegrationEspEloquaModel,
  qiagen: IntegrationQiagenModel,
  customer_leadfamly_dagrofa: IntegrationDagrofaModel,
  customer_leadfamly_carls_jr: IntegrationCarlsJrModel,
  cookie: IntegrationCookieModel,
  customer_leadfamly_lidl: IntegrationLidlModel,
  recaptcha: IntegrationRecaptchaModel,
  customer_leadfamly_codan: IntegrationCodanModel,
  'esp/braze': IntegrationEspBrazeModel,
  customer_leadfamly_saxo: IntegrationSaxoModel,
  customer_leadfamly_klasselotteriet_segments: IntegrationKlasselotterietSegmentsModel,
  customer_leadfamly_kraeftens_bekaempelse: IntegrationKraeftensBekaempelseModel,
  customer_leadfamly_lidl_products: IntegrationLidlProductsModel,
  customer_leadfamly_philip_morris: IntegrationPhilipMorrisModel,
  customer_leadfamly_danskespil: IntegrationDanskeSpilModel,
  jwt: IntegrationJwtModel,
  openid_connect: IntegrationOpenIdConnectModel,
  ajax: IntegrationOpenIdConnectModel,
  scandlines: IntegrationScandlinesModel,
  customer_lobyco: IntegrationLobycoModel,
  customer_coop_app: IntegrationCoopModel,
  customer_leadfamly_grundfos: IntegrationGrundfosModel,
  clerk: IntegrationClerkModel,
  bulk_prize_vouchers: IntegrationBulkPrizeVouchersModel,
  customer_leadfamly_rituals: IntegrationRitualsModel,
  pixel_tracking: IntegrationPixelTrackingModel,
  sports_alliance: IntegrationSportsAllianceModel,
  nfc: IntegrationNfcModel,
  customer_leadfamly_gf_advanced_map: IntegrationGfAdvancedMapModel,
  raptorsmartadvisor: IntegrationRaptorSmartAdvisorModel,
  'esp/clickdimension': IntegrationEspClickdimensionModel,
  particle_generator: IntegrationParticleGeneratorModel,
  'esp/facebook_conversion': IntegrationEspFacebookConversionModel,
  customer_leadfamly_politikken_bezzerwisser: IntegrationBezzerwisserModel,
  'esp/customer_leadfamly_klasselotteriet': IntegrationEspCustomKlasselotterietModel,
  cookie_consent: IntegrationCookieConsentModel,
  cookie_consent_v2: IntegrationCookieConsentV2Model,
  jumbo: IntegrationJumboModel
};
