import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { SdkFormSubmitResponseEvent } from './../sdk/events/formSubmitResponse';
import { SdkEventsEmitter } from './../sdk/interface';
import { EmitFormSubmitResponseEvent } from './../events/formSubmitResponse';
import { EmitFormSubmitEvent } from './../events/formSubmit';
import { getQueryParams } from './Url';
import { signJws } from './jws';
import { useUtilityStore } from '@/src/store/utility';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import useAxios from '@/src/hooks/useAxios';
import useDevice from '@/src/hooks/useDevice';
import type { FormElementModel, RegistrationApiResponse } from '@/src/components/addons/registration/types';
import { getCookie, removeCookie, setCookie } from '@/src/utilities/CookieHelpers';
import { CookieCategory, waitForCookieAccess } from '@/src/services/cookieConsent';
import type { SectionModelType } from '@/src/typings/types/types';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';
import { FieldType, SectionType } from '@/src/typings/enums/enums';
import useLeadScore from '@/src/hooks/useLeadscore';
import { FormDisableDefaultBehavior } from '@/src/exceptions/FormDisableDefaultBehavior';
import { ContentType } from '@/src/components/layout/section/SectionBaseModel';
import { SdkFormSubmitEvent } from '@/src/sdk/events/formSubmit';

export interface FormCookies {
  [key: string]: string;
}

interface FormattedMetricDataItem {
  [key: string]: string;
}

interface SerializedFormData {
  formData: Record<string, unknown>;
  encrypted: Record<string, unknown>;
}

export const getFormCookieData = (): FormCookies => {
  const campaignStore = useCampaignStore();
  return getCookie<FormCookies>(`form_${campaignStore.model?.id}`) ?? {};
};

/**
 * Get cookie that contains the array of forms remembered.
 *
 * @see addToFormsCookie
 */
const getFormsCookie = (): number[] => {
  const formsCookie = getCookie<number[]>('form_list');
  return formsCookie || [];
};

/**
 * Add a form identifier to the form list collection.
 * This is used to keep track of form_x cookies set so that we can
 * do cleanup every now and then.
 */
const addToFormsCookie = (id: number) => {
  let forms = getFormsCookie();
  if (Array.isArray(forms) && !forms.includes(id)) {
    forms.push(id);
  }

  if (forms.length > 3) {
    // Slice last 3 items off the array. These are the ones we want to remember.
    const formsToKeep = forms.slice(-2, 3);

    forms.forEach((form) => {
      if (!formsToKeep.includes(form)) {
        // Remove the cookies from forms that should be forgotten.
        removeCookie(`form_${form}`);
      }
    });

    forms = formsToKeep;
  }

  setCookie('form_list', JSON.stringify(forms));
};

export const getGlobalFormCookieData = (): FormCookies => {
  const campaignStore = useCampaignStore();
  const cookieData = getCookie<FormCookies>(`form_global_${campaignStore.model?.state.config?.customerId}`) ?? {};
  const newCookieData: FormCookies = {};

  Object.keys(cookieData).forEach((key) => {
    if (
      !campaignStore.model?.state.config?.userRecognition?.parameters.includes(key) &&
      !key.includes('<span') &&
      !key.includes('<p') &&
      !key.includes('<a')
    ) {
      newCookieData[`${key}`] = cookieData[`${key}`];
    }
  });

  return newCookieData;
};

/**
 * Save global form data in a cookie so that it can be retrieved later.
 */
export const saveGlobalFormCookies = async (formFields: FormElementModel[]) => {
  const campaignStore = useCampaignStore();

  if (!campaignStore.model?.state.config?.cookiesEnabled) {
    return;
  }

  await waitForCookieAccess(CookieCategory.FUNCTIONAL);

  const existingCookie = getGlobalFormCookieData();
  const newCookieData: Record<string, unknown> = {};

  formFields.forEach((field) => {
    const cookieValue = field.getSerializedCookieValue();

    // Global form data is serialized in cookies. For this reason we wouldn't like for it to take up too much space.
    // This is why we exclude form fields with labels that has more than 100 characters in length.
    if (cookieValue.length > 100) {
      return;
    }

    // Extract the text from the HTML string.
    const tmp = document.createElement('div');
    tmp.innerHTML = field.state.name; // nosem

    newCookieData[(tmp.textContent || tmp.innerText || '').trim()] = cookieValue;
  });

  /**
   * Due to size constraints for cookies we need to ensure that our cookie takes up less space than 4096 bytes.
   * This is done by serializing the cookie and measuring it's size. If the size is above our threshold
   * (+ a margin of 200). Then we attempt at shrinking it. The shrinking is done by removing the first item of the
   * existing cookies array until the combined size is less than the desired result.
   */
  const newCookieSize = encodeURIComponent(JSON.stringify({ ...existingCookie, ...newCookieData })).length;

  if (newCookieSize + 200 >= 2048) {
    let i = 0;

    while (
      encodeURIComponent(JSON.stringify({ ...existingCookie, ...newCookieData })).length + 200 >= 2048 &&
      i < 5000
    ) {
      i++;

      const firstKey = Object.keys(existingCookie)[0];

      if (firstKey) {
        delete existingCookie[`${firstKey}`];
      }
    }

    setCookie(
      `form_global_${campaignStore.model?.state.config?.customerId}`,
      JSON.stringify({ ...existingCookie, ...newCookieData })
    );
  } else {
    setCookie(
      `form_global_${campaignStore.model?.state.config?.customerId}`,
      JSON.stringify({ ...existingCookie, ...newCookieData })
    );
  }
};

/**
 * Save all form data into our form_x cookie so that we can remember previously entered information.
 */
export const saveFormCookies = async (pageModel: SectionModelType): Promise<void> => {
  const campaignStore = useCampaignStore();

  if (!campaignStore.model?.state.config?.cookiesEnabled) {
    return;
  }

  await waitForCookieAccess(CookieCategory.FUNCTIONAL);
  const campaign = campaignStore.model;

  if (!campaign) {
    return;
  }

  const existingCookie = getFormCookieData();
  const cookie = existingCookie;

  pageModel.getAddons<AddonRegistrationModel>('registration').forEach((registrationModel) => {
    registrationModel.state.fields?.forEach((field) => {
      if (!field.state.containsEncryptedValue) {
        cookie[field.id] = field.getSerializedCookieValue();
      }
    });
  });

  setCookie(`form_${campaign.id}`, JSON.stringify(cookie));
  addToFormsCookie(campaign.id);
};

export const getLocation = (href: string) => {
  // eslint-disable-next-line security/detect-unsafe-regex
  const match = href.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
  return (
    match && {
      href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7]
    }
  );
};

export const getSerializedFormDataForPages = (pages: SectionModelType[]): SerializedFormData => {
  const formData: Record<string, unknown> = {};
  const encrypted: Record<string, unknown> = {};

  pages.forEach((page) => {
    page.getAddons<AddonRegistrationModel>('registration').forEach((registrationModel) => {
      registrationModel.state.fields?.forEach((field) => {
        const value = field.getSerializedPostValue();

        if (!field.state.includeInApi) {
          return;
        }

        // @ts-ignore
        if (field.state.type === FieldType.CHECKBOX && field.state?.checkbox?.type === 'multiple' && value === '0') {
          return;
        }

        if (field.state.containsEncryptedValue) {
          encrypted[field.id] = value;
        } else {
          formData[field.id] = value;
        }
      });
    });
  });

  return {
    formData,
    encrypted
  };
};

/**
 * Get serialized form data for use in form submit.
 */
export const getSerializedFormData = (page: SectionModelType): SerializedFormData => {
  const campaignStore = useCampaignStore();

  let formData: SerializedFormData = { formData: {}, encrypted: {} };

  if (page.getSectionType() !== SectionType.FLOWPAGE) {
    formData = getSerializedFormDataForPages([page]);
  } else if (
    campaignStore.model &&
    // If flow registration is skipped, then we should never include any form data from these forms.
    !campaignStore.skippedFlowRegistration
  ) {
    const mainRegistrationPage = campaignStore.model.mainRegistrationPage;

    formData = getSerializedFormDataForPages(
      campaignStore.model.state.flowPages.filter((pageModel) => {
        const registrationAddon = pageModel.getAddons<AddonRegistrationModel>('registration');

        if (
          mainRegistrationPage &&
          mainRegistrationPage !== page &&
          // Check if current page is an extra registration page extending the registration
          !registrationAddon.some((registrationAddon) => {
            return registrationAddon.state.fields?.some((field) => field.state.type === FieldType.EMAIL);
          }) &&
          (!campaignStore.flowRegistrationInfo ||
            (campaignStore.flowRegistrationInfo &&
              !campaignStore.flowRegistrationInfo.pages.includes(mainRegistrationPage.id)))
        ) {
          return false;
        }

        return pageModel.index <= page.index && pageModel.getAddons<AddonRegistrationModel>('registration').length > 0;
      })
    );
  }

  return formData;
};

export const setFormReplacementTags = () => {
  const replacementTag: ReplacementTags = {};
  const campaignStore = useCampaignStore();
  const flowRegistrationInfo = campaignStore.flowRegistrationInfo;

  campaignStore.model?.formFields.forEach((field) => {
    if (field.shouldSetReplacementTags) {
      const replacementValue = field.getStringifiedValue();

      if (typeof replacementValue !== 'undefined') {
        replacementTag[`registration_field_${field.id}`] = replacementValue;
      }
    }
  });

  if (flowRegistrationInfo) {
    replacementTag.registration_id = Number(flowRegistrationInfo.id);
  }

  campaignStore.addReplacementTags(replacementTag);
};

export const submitForm = async (
  pageModel: SectionModelType,
  triggerByGameEnd = false
): Promise<RegistrationApiResponse> => {
  const campaignStore = useCampaignStore();
  const utilityStore = useUtilityStore();

  if (!campaignStore.model) {
    throw new Error('Campaign model is undefined in store');
  }

  const flowRegistrationInfo = campaignStore.flowRegistrationInfo;

  await EmitFormSubmitEvent(pageModel);

  // Before submit SDK event
  if (!pageModel.state.programmatic) {
    const sdkFormSubmitEvent = new SdkFormSubmitEvent(pageModel);
    if (sdkFormSubmitEvent.formFields.length > 0) {
      SdkEventsEmitter.emit('formSubmit', sdkFormSubmitEvent);

      try {
        await sdkFormSubmitEvent.waitForPromises();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('[SDK] Form submit exception caught', e);
        throw new FormDisableDefaultBehavior('Default behavior prevented because of exception in SDK form submit');
      }
    }
  }

  const pageIdQuery = `&page=${flowRegistrationInfo?.pages[0] ?? campaignStore.flowId}`;

  const postUrl = `${campaignStore.model?.state?.config?.campaignApiRoot ?? ''}/api/v1/campaign/register?campaign_id=${
    campaignStore.model.state.id
  }${campaignStore.flowId ? pageIdQuery : ''}${
    campaignStore.model.state.config?.hash ? '&hash=' + campaignStore.model.state.config.hash : ''
  }`;

  const metricData = campaignStore.metricData;
  const isGameWinner = campaignStore.gameWinner;

  // We should only sign the request for campaigns using the new leaderboard here to start with.
  // At a later point we will activate this for all campaigns.
  const shouldSignRequest = campaignStore.model.state.config?.hasLeaderboard;

  // TODO: Figure out typing at a later point
  // @ts-ignore
  const gameData = campaignStore.gameData;
  const instantWinData = campaignStore.instantWinData;

  const formattedMetricData = Object.keys(metricData).reduce((acc: FormattedMetricDataItem, curr) => {
    acc[`metrics[${curr}]`] = metricData[`${curr}`] + '';
    return acc;
  }, {});

  const { isDesktop, isTablet, isMobile } = useDevice();
  const { formData: serializedFormData, encrypted } = getSerializedFormData(pageModel);

  const params = getQueryParams();

  const formData: Record<string, unknown> = {
    ...campaignStore.staticFormData,
    ...serializedFormData,
    ...(Object.keys(encrypted).length > 0 && { encrypted }),
    ...instantWinData,
    ...(gameData && {
      gameData
    }),
    mobile: isMobile ? '1' : '0',
    tablet: isTablet ? '1' : '0',
    desktop: isDesktop ? '1' : '0',

    // Depending on if we sign the request or not, then we have to send it as JSON or www-url-form-encoded
    ...(metricData && shouldSignRequest && { metrics: metricData }),
    ...(formattedMetricData && !shouldSignRequest && { ...formattedMetricData }),

    ...(isGameWinner !== null && { won: isGameWinner ? '1' : '0' }),
    ...(campaignStore.model.state.config?.leadScoreEnabled && { leadscore: useLeadScore().getTracked() }),
    ...(utilityStore.url && { created_from_url: utilityStore.url }),
    ...(params && params['foreign-key'] && { foreign_key: params['foreign-key'] })
  };

  const pageItems =
    pageModel.getSectionType() === SectionType.FLOWPAGE
      ? campaignStore.model.state.flowPages.filter((pageItem) => pageItem.index <= pageModel.index)
      : [pageModel];

  saveFormCookies(pageModel);

  let pages: number[] = [];
  const hasRegistrationAddon = pageModel.getAddons('registration').length > 0;
  const hasGameplayAddon = pageModel.getAddons('gameplay').length > 0;

  if (hasRegistrationAddon || hasGameplayAddon) {
    pages.push(pageModel.id);
  }

  if (flowRegistrationInfo?.pages) {
    pages = [...flowRegistrationInfo.pages, ...pages];
  }

  if (pageModel.getSectionType() === SectionType.FLOWPAGE && flowRegistrationInfo && flowRegistrationInfo.id) {
    formData.id = flowRegistrationInfo.id.toString();
    formData.token = flowRegistrationInfo.token;
  } else {
    /**
     * Needs to be cleared so we dont overwrite same registration as their might have been previously
     */
    formData.id = undefined;
  }

  formData.pages = pages;

  if (hasRegistrationAddon && !flowRegistrationInfo?.pages[0]) {
    formData.page = pageModel.id.toString();
  } else if (hasGameplayAddon) {
    formData.page = pageModel.id.toString();
  } else if (pages.length > 0) {
    formData.page = pages[pages.length - 1].toString();
  } else {
    formData.page = pageModel.id.toString();
  }

  if (campaignStore.flowRegistrationInfo) {
    campaignStore.flowRegistrationInfo.pages = pages;
  } else {
    campaignStore.flowRegistrationInfo = {
      pages: [...pages]
    };
  }

  if (
    !(
      typeof formData.won !== 'undefined' ||
      pageItems.filter((pageItem) => {
        return pageItem.getAddons<AddonRegistrationModel>('registration').some((registrationAddon) => {
          return registrationAddon.state.fields?.some((field) => field.state.type === FieldType.EMAIL);
        });
      }).length > 0
    )
  ) {
    throw new FormDisableDefaultBehavior('Default behavior prevented because of exception in SDK form submit', true);
  }

  const mainRegistrationPage = campaignStore.model.mainRegistrationPage;

  if (
    (!flowRegistrationInfo ||
      (mainRegistrationPage && !flowRegistrationInfo.pages.includes(mainRegistrationPage.id))) &&
    pageModel.getSectionType() === SectionType.FLOWPAGE &&
    // Always allow page containing the actual game to submit, so that results are submitted to the BE.
    !triggerByGameEnd &&
    pageModel.state.content[0] &&
    // Always allow fictive flow page to submit registration form so that game results are submitted to the BE.
    // This is because some games use fictive flow page as part of the game. So when game ends, it will land here.
    pageModel.state.content[0].type !== ContentType.COMPONENT &&
    !pageModel.getAddons<AddonRegistrationModel>('registration').some((registrationAddon) => {
      return registrationAddon.state.fields?.some((field) => field.state.type === FieldType.EMAIL);
    })
  ) {
    throw new FormDisableDefaultBehavior('Disabled save behavior because form submit was an extra fillout', true);
  }

  const { postDataFormData } = useAxios<RegistrationApiResponse>(
    postUrl,
    formData,
    undefined,
    shouldSignRequest
      ? (obj: object) => {
          if (!campaignStore.model) {
            throw new Error('Campaign model is undefined');
          }

          return signJws(campaignStore.model, obj);
        }
      : undefined
  );

  let response: RegistrationApiResponse | undefined;

  try {
    // If no registration is found, then we shouldn't attempt to retry as this
    // can result in multiple registrations being created when only one was intended.
    response = await postDataFormData();
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'An unknown error occurred';

    if (axios.isAxiosError(e) && e.response?.status === 503) {
      // TODO: Re-introduce maintenance page
      /*throw createError({
        statusCode: 503,
        fatal: true
      });*/
    }

    if (axios.isAxiosError<RegistrationApiResponse>(e) && e.response?.data?.errors) {
      await EmitFormSubmitResponseEvent(pageModel, e.response.data);
    } else if (errorMessage.includes('timeout')) {
      Sentry.captureException(new Error('Registration API timed out'), {
        contexts: {
          'Error Details': {
            error: errorMessage,
            response: JSON.stringify(response),
            postUrl,
            statusCode: axios.isAxiosError<RegistrationApiResponse>(e) ? e.response?.status : undefined,
            onLine: window.navigator.onLine
          }
        }
      });

      throw e;
    } else {
      Sentry.captureException(new Error('Something went wrong with the registration API'), {
        contexts: {
          'Error Details': {
            error: errorMessage,
            response: JSON.stringify(response),
            postUrl,
            statusCode: axios.isAxiosError<RegistrationApiResponse>(e) ? e.response?.status : undefined,
            onLine: window.navigator.onLine
          }
        }
      });

      throw e;
    }
  }

  // If API responds with replacement tags we should add these.
  if (response && response.replacementtags) {
    campaignStore.addReplacementTags(response.replacementtags);
  }

  if (response && response.metrics) {
    campaignStore.metricData = { ...campaignStore.metricData, ...response.metrics };
  }

  if (
    response &&
    response.object &&
    pageModel.getSectionType() === SectionType.FLOWPAGE &&
    response.object.id &&
    response.object.token
  ) {
    const token = response.object.token;
    const id = response.object.id;

    campaignStore.instantWinData = response.object;

    campaignStore.flowRegistrationInfo = {
      id: Number(id),
      token,
      pages
    };
  }

  // After submit SDK event
  if (!pageModel.state.programmatic) {
    const sdkFormSubmitResponseEvent = new SdkFormSubmitResponseEvent(
      pageModel,

      // Response is only OK if registration id and token exists
      !!(response && response.object && response.object.id && response.object.token),

      response && response.object && response.object.id ? Number(response.object.id) : undefined
    );

    if (sdkFormSubmitResponseEvent.formFields.length > 0) {
      SdkEventsEmitter.emit('formSubmitResponse', sdkFormSubmitResponseEvent);

      try {
        await sdkFormSubmitResponseEvent.waitForPromises();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('[SDK] Form submit exception caught', e);
        throw new FormDisableDefaultBehavior('Default behavior prevented because of exception in SDK form submit');
      }
    }
  }

  if (response && response.error && response.message === 'game limit reached') {
    await EmitFormSubmitResponseEvent(pageModel, response);
    return response;
  }

  if (response && response.show_message) {
    await EmitFormSubmitResponseEvent(pageModel, response);
    return response;
  }

  if (!response || !response.object) {
    throw new Error('Unrecognized API response');
  }

  await EmitFormSubmitResponseEvent(pageModel, response);

  return response;
};
