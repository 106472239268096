import type { ContentAddonType } from '@/src/components/layout/column/ColumnModel';

export class SdkBaseAddonModel {
  #model: ContentAddonType;

  constructor(addon: ContentAddonType) {
    this.#model = addon;
  }

  public get alias(): string {
    return this.#model.alias;
  }

  public get idAttribute(): string | undefined {
    return this.#model.state.genericAdvancedSettings.state.advanced?.elementId;
  }
}
