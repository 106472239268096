export enum LottieSizeType {
  FULL,
  THUMB,
  MEDIUM,
  CUSTOM
}

export type LottieSizeTypeData = 'thumb' | 'medium' | 'full' | 'custom';

export enum LottieTransitionType {
  NORMAL,
  HOVER
}

export type LottieAnimationActionType = 'click' | 'hover';
export enum LottieAnimationActionEnum {
  Click,
  Hover
}
