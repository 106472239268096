import type { AddonVideoEmbedData, AddonVideoEmbedSettingsData } from '@/src/components/addons/video-embed/Data';
import type { VideoEmbedType } from '@/src/typings/enums/enums';
import { AlignContentType } from '@/src/typings/enums/enums';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import useDevice from '@/src/hooks/useDevice';
import ActionsModel from '@/src/models/actions/ActionsModel';

export interface BaseVideoEmbedSettings {
  videoType?: VideoEmbedType;
  videoUrl?: string;
  videoOpacity?: number;
  backgroundColor?: string;
  sound?: boolean;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  custom: AddonVideoEmbedCustomState;
  actionEnded?: ActionsModel;
}
export interface AddonVideoEmbedCustomState {
  videoWidth?: string;
  videoHeight?: string;
  align: AlignContentType;
}

export interface AddonVideoEmbedSettingsState extends BaseVideoEmbedSettings {
  overwriteMobile?: boolean;
  mobile?: BaseVideoEmbedSettings;
  advanced?: AdvancedAddonsStyleData;
}

interface AddonVideoEmbedState extends AddonModelState {
  settings: AddonVideoEmbedSettingsState;
  custom: AddonVideoEmbedCustomState;
}

export class AddonVideoEmbedModel extends AddonModel<AddonVideoEmbedData, AddonVideoEmbedState, 'video-embed'> {
  parseAddon(data: AddonVideoEmbedData) {
    const state = this.state;

    /**
     * only assign mobile values if overwrite mobile is false else ignore mobile values and assign default object.
     * This functionality is much like the check we have in getDeviceData, however
     * we have pulled this functionality in locally in this model as the data in this
     * is formatted differently, so we couldn't make a 100% use of getDeviceData
     */

    state.settings = state.settings || {};

    state.settings = AddonVideoEmbedModel.parseSettingsData(data.settings);
  }

  private static parseSettingsData(data: AddonVideoEmbedSettingsData): BaseVideoEmbedSettings {
    const { isMobile } = useDevice();
    const overwriteMobile = data.overwrite_mobile === undefined || data.overwrite_mobile === '1';
    const useMobile = isMobile && !overwriteMobile;

    const dataSettings: AddonVideoEmbedSettingsData = {
      video_type: useMobile && data.mobile?.video_type ? data.mobile?.video_type : data.video_type,
      video_url: useMobile && data.mobile?.video_url ? data.mobile?.video_url : data.video_url,
      video_opacity: useMobile && data.mobile?.video_opacity ? data.mobile?.video_opacity : data.video_opacity,
      background_color:
        useMobile && data.mobile?.background_color ? data.mobile?.background_color : data.background_color,
      sound: useMobile && data.mobile?.sound ? data.mobile?.sound : data.sound,
      autoplay: useMobile && data.mobile?.autoplay ? data.mobile?.autoplay : data.autoplay,
      controls: useMobile && data.mobile?.controls ? data.mobile?.controls : data.controls,
      loop: useMobile && data.mobile?.loop ? data.mobile?.loop : data.loop,
      custom: useMobile && data.mobile?.custom ? data.mobile?.custom : data.custom,
      action_ended: data.action_ended
    };

    if (dataSettings.autoplay && dataSettings.sound) {
      dataSettings.sound = '0';
    }

    return AddonVideoEmbedModel.constructVideoEmbedSettings(dataSettings);
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    return !(!data.settings.video_url || data.settings.video_url === '');
  }

  private static constructVideoEmbedSettings(settings: AddonVideoEmbedSettingsData): BaseVideoEmbedSettings {
    return {
      videoType: settings.video_type,
      videoUrl: settings.video_url,
      videoOpacity: settings.video_opacity ? Number(settings.video_opacity) : 100,
      backgroundColor: settings.background_color,
      sound: settings.sound === '1',
      controls: settings.controls === '1',
      loop: settings.loop === '1',
      autoplay: settings.autoplay === '1',
      custom: {
        videoWidth: settings.custom?.video_width,
        videoHeight: settings.custom?.video_height,
        align: settings.custom?.align || AlignContentType.CENTER
      },
      actionEnded: settings.action_ended ? new ActionsModel(settings.action_ended) : undefined
    };
  }
}
