import { registerRepeatable } from '@/src/services/repeatable';
import useAxios from '@/src/hooks/useAxios';
import { Cache } from '@/src/services/cache';
import type { RepeatableData } from '@/src/store/repeatable';

const register = () => {
  registerRepeatable('json_feed', async (model) => {
    const cacheKey = `json-feed-${model.state.feedUrl}`;

    if (Cache.exists(cacheKey)) {
      return Cache.get<RepeatableData[]>(cacheKey) ?? [];
    }

    if (!model.state.feedUrl) {
      Cache.set<RepeatableData[]>(cacheKey, []);
      return [];
    }

    const { fetchData } = useAxios<Record<string, unknown>[]>(model.state.feedUrl);
    const data = await fetchData();

    const formattedData = data.map<RepeatableData>((item, index) => {
      return {
        id: index,
        ...item
      };
    });

    Cache.set(cacheKey, formattedData);

    return formattedData;
  });
};

export default register;
