export type StepGuideSizeType = 'small' | 'medium' | 'big';

export type StepGuideType = 'circles' | 'percent' | 'top_bar' | 'step';

export type StepGuideAlignmentType = 'left' | 'center' | 'right';

export enum StepGuideSizeEnum {
  SMALL,
  MEDIUM,
  LARGE
}

export enum StepGuideTypeEnum {
  CIRCLES,
  PERCENTAGE,
  TOP_BAR,
  STEP
}

export enum StepGuideAlignmentEnum {
  LEFT,
  CENTER,
  RIGHT
}
