import { SdkTypeCheck } from '../utilities';
import useDevice from '@/src/hooks/useDevice';
import { useUtilityStore } from '@/src/store/utility';

export class SdkUtilsApi {
  static #classList: string[] = [];

  public static addBodyClass(className: string): void {
    SdkTypeCheck('string', className);

    if (!className) {
      throw new TypeError(`[SDK] className must not be empty`);
    }

    const utilityStore = useUtilityStore();

    if (utilityStore.bodyClassList.includes(className)) {
      throw new TypeError(`[SDK] Body class ${className} already exists`);
    }

    this.#classList.push(className);

    utilityStore.addBodyClass(className);
  }

  public static removeBodyClass(className: string): void {
    SdkTypeCheck('string', className);

    if (!className) {
      throw new TypeError(`[SDK] className must not be empty`);
    }

    const utilityStore = useUtilityStore();

    if (!utilityStore.bodyClassList.includes(className)) {
      throw new TypeError(`[SDK] Body class ${className} does not exist`);
    }

    if (!this.#classList.includes(className)) {
      throw new TypeError(`[SDK] Body class ${className} is not allowed to be removed`);
    }

    this.#classList = this.#classList.filter((item) => item !== className);

    utilityStore.removeBodyClass(className);
  }

  public static hasBodyClass(className: string): boolean {
    SdkTypeCheck('string', className);

    if (!className) {
      throw new TypeError(`[SDK] className must not be empty`);
    }

    const utilityStore = useUtilityStore();

    return utilityStore.bodyClassList.includes(className);
  }

  public static getDevice(allowSimulation = true): string {
    SdkTypeCheck('boolean', allowSimulation);

    const device = useDevice(allowSimulation);

    return String(device.device);
  }
}
