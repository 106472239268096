import type { Component } from 'vue';
import type { IntegrationCookieConsentV2Data } from './Data';
import type { CookieConsentOptionsState } from './Types';
import { PopoverPosition } from './Types';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';
import View from '@/src/components/integrations/cookie-consent-v2/View.vue';

export interface IntegrationCookieConsentV2State extends BaseIntegrationState {
  settings?: CookieConsentOptionsState;
}

export class IntegrationCookieConsentV2Model extends BaseIntegration<
  IntegrationCookieConsentV2Data,
  IntegrationCookieConsentV2State
> {
  parse(data: IntegrationCookieConsentV2Data) {
    super.parse(data);
    const settings: CookieConsentOptionsState = this.state.settings || {};

    settings.position = data.settings?.position || PopoverPosition.BOTTOM;
    settings.content = settings.content || {};
    settings.content.headline = data.settings?.content?.headline || 'Cookies';
    settings.content.message =
      data.settings?.content?.message ||
      'We use cookies and other technologies to ensure our website works as intended and to performs analytics. Select the cookies you will allow to be set. You can select specific types of cookies or allow all by clicking Accept All. By choosing Decline no cookies will be set.';
    settings.content.allow = data.settings?.content?.allow || 'Accept all';
    settings.content.allowselected = data.settings?.content?.allowselected || 'Accept selected';
    settings.content.deny = data.settings?.content?.deny || 'Deny all';
    settings.content.enableFunctional =
      typeof data.settings?.content?.enable_functional === 'undefined' ||
      data.settings?.content?.enable_functional === '1';
    settings.content.enableStatistics =
      typeof data.settings?.content?.enable_statistics === 'undefined' ||
      data.settings?.content?.enable_statistics === '1';
    settings.content.enableMarketing =
      typeof data.settings?.content?.enable_marketing === 'undefined' ||
      data.settings?.content?.enable_marketing === '1';
    settings.content.functional = data.settings?.content?.functional || 'Functional';
    settings.content.statistics = data.settings?.content?.statistics || 'Statistics';
    settings.content.marketing = data.settings?.content?.marketing || 'Marketing';
    settings.content.link = data.settings?.content?.link || 'Read more about the cookies used here.';
    settings.content.href = data.settings?.content?.href || 'https://showcase.leadfamly.com/cookie-page';

    settings.palette = settings.palette || {};
    settings.palette.popup = settings.palette.popup || {};
    settings.palette.popup.font = data.settings?.palette?.popup?.font ?? '';
    settings.palette.popup.background = data.settings?.palette?.popup?.background ?? '#2e2e2e';
    settings.palette.popup.text = data.settings?.palette?.popup?.text ?? '#ffffff';

    settings.palette.button = settings.palette.button || {};
    settings.palette.button.background = data.settings?.palette?.button?.background ?? '#fae24c';
    settings.palette.button.text = settings.palette.button.text || {};
    settings.palette.button.text.accept = data.settings?.palette?.button?.text?.accept ?? '#151515';
    settings.palette.button.text.deny = data.settings?.palette?.button?.text?.deny ?? '#ffffff';
    settings.palette.button.radius = data.settings?.palette?.button?.radius ?? '6px';

    this.state.settings = settings;
  }

  getVueComponent(): Promise<Component> {
    return Promise.resolve(View);
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Aleksa Booth';
  }
}
