import type { AddonSlideShowData } from '@/src/components/addons/slideshow/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { generateUniqueId, shuffle } from '@/src/utilities/Utilities';
import type { SlideshowTransition } from '@/src/components/addons/slideshow/enums';
import ActionsModel from '@/src/models/actions/ActionsModel';

export interface SliderItem {
  id?: string;
  image?: string;
  action?: ActionsModel;
  width?: number;
  height?: number;
}

interface AddonSlideShowState extends AddonModelState {
  key: string;
  isReady: boolean;
  general: {
    images?: number;
    disableSwipe?: boolean;
    transition?: SlideshowTransition;
    transitionTime?: number;
    slidesPerView?: number;
    slidesSpaceBetween?: number;
    interval?: {
      enabled?: boolean;
      time?: number;
    };
    shuffle?: boolean;
    navigation?: {
      enabled?: boolean;
      arrowButtons?: boolean;
      bullets?: boolean;
    };
  };
  images?: SliderItem[];
}

export class AddonSlideShowModel extends AddonModel<AddonSlideShowData, AddonSlideShowState, 'slideshow'> {
  parseAddon(data: AddonSlideShowData) {
    const state = this.state;
    state.isReady = false;
    state.general = state.general ?? {};
    if (data.settings.general?.disable_swipe) {
      state.general.disableSwipe = data.settings.general?.disable_swipe === '1';
    }
    if (data.settings.general?.interval && data.settings.general?.interval.enabled === '1') {
      state.general.interval = {};
      state.general.interval.enabled = data.settings.general?.interval?.enabled === '1';
      state.general.interval.time = data.settings.general?.interval?.time
        ? Number(data.settings.general?.interval?.time)
        : 6000;
      state.key = generateUniqueId();
    } else {
      state.general = {};
      state.general.interval = {};
      state.general.interval.enabled = false;
      state.general.interval.time = 6000;
      state.key = generateUniqueId();
    }
    if (data.settings.general?.transition_time) {
      state.general.transitionTime = Number(data.settings.general.transition_time);
    }

    if (data.settings.general?.transition === 'fade') {
      state.general.slidesPerView = 1;
      state.general.slidesSpaceBetween = 0;
    } else {
      state.general.slidesPerView = Number(data.settings.general?.slides_per_view ?? 1);
      if (data.settings.general?.slides_space_between) {
        const parsedValue = parseInt(data.settings.general.slides_space_between);
        state.general.slidesSpaceBetween = isNaN(parsedValue) ? 0 : parsedValue;
      } else {
        state.general.slidesSpaceBetween = 0;
      }
    }

    if (data.settings.general?.navigation) {
      state.general.navigation = {};
      if (data.settings.general?.navigation?.arrow_buttons === '1') {
        state.general.navigation.arrowButtons = true;
      } else if (data.settings.general?.navigation?.arrow_buttons === '0') {
        state.general.navigation.arrowButtons = false;
      } else state.general.navigation.arrowButtons = !!data.settings.general?.navigation?.enabled;

      if (data.settings.general?.navigation?.bullets === '1') {
        state.general.navigation.bullets = true;
      } else if (data.settings.general?.navigation?.bullets === '0') {
        state.general.navigation.bullets = false;
      } else state.general.navigation.bullets = !!data.settings.general?.navigation?.enabled;
      state.general.navigation.enabled = data.settings.general?.navigation?.enabled === '1';
    }

    if (data.settings.general?.images) {
      state.general.images = Number(data.settings.general?.images);
    } else {
      state.general.images = 0;
    }

    state.general.shuffle = data.settings.general?.shuffle === '1';
    state.general.transition = data.settings.general?.transition;
    state.general.transitionTime = Number(data.settings.general?.transition_time);
    state.images = [];

    if (state.general?.shuffle) {
      if (data.settings.images) {
        data.settings.images = shuffle(data.settings.images);
      }
      if (state.images && state.images.length > 0) {
        state.images = shuffle(state.images);
      }
    }

    if (data.settings.images && data.settings.images.length > 0) {
      data.settings.images.forEach((image) => {
        if (image.image && image.image !== '') {
          state.images?.push({
            id: image.id,
            ...(image?.link &&
              image?.link.enabled &&
              image.link.enabled === '1' && {
                action: new ActionsModel({ ...image.link })
              }),
            height: image.height,
            ...(image.image && image.image !== '' && { image: image.image }),
            width: image.width
          });
        }
      });
    }

    if ((state.images && state.images.length === 1) || state.general.images === 1) {
      state.general.interval.enabled = false;
      if (state.general && state.general.navigation) {
        state.general.navigation.enabled = false;
      }
    }

    setTimeout(() => {
      state.isReady = true;
    }, 100);
  }

  public setValue(path: string, value: string | number | boolean, shouldParse?: boolean): void {
    const data = this.getData();

    if (path === 'general.transition' && value === 'slide' && data.settings.general?.slides_per_view) {
      data.settings.general.slides_per_view = '1';
      data.settings.general.slides_space_between = '0px';
    }

    super.setValue(path, value, shouldParse);
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    return !(!data.settings.images || data.settings.images.length === 0);
  }
}
