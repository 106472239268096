import { useEditingStore } from '@/src/store/editing';
import { useCampaignStore } from '@/src/store/campaign';
import type { ModelTypes } from '@/src/typings/types/types';
import { SectionType } from '@/src/typings/enums/enums';

export default function useNavigator(model: ModelTypes) {
  const campaignStore = useCampaignStore();
  const editingStore = useEditingStore();
  const state = model.state;

  const setFocus = () => {
    if (
      campaignStore.model?.state.isEditModeActive &&
      model.getSection().getSectionType() === editingStore.activeTabCategory &&
      state.edit
    ) {
      state.edit = state.edit ?? {};
      state.edit.isFocus = true;
    }
  };

  const removeFocus = () => {
    if (campaignStore.model?.state.isEditModeActive && state.edit) {
      state.edit = state.edit ?? {};
      state.edit.isFocus = false;
    }
  };

  const removeActiveFromState = () => {
    if (state.edit) {
      state.edit = state.edit ?? {};
      state.edit.isActive = false;
    }
  };

  const toggleCollapse = () => {
    if (campaignStore.model?.state.isEditModeActive && state.edit) {
      state.edit = state.edit ?? {};
      state.edit.isCollapsed = !state.edit.isCollapsed;
    }
  };

  const isPopoverTab = () => {
    return editingStore.activeTabCategory === SectionType.POPOVER;
  };
  const isSectionsTab = () => {
    return editingStore.activeTabCategory === SectionType.SECTION;
  };
  const isFlowPagesTab = () => {
    return editingStore.activeTabCategory === SectionType.FLOWPAGE;
  };

  return {
    isSectionsTab,
    isFlowPagesTab,
    isPopoverTab,
    setFocus,
    removeFocus,
    removeActiveFromState,
    toggleCollapse
  };
}
