import { createPinia, setActivePinia } from 'pinia';
import type { App } from 'vue';

export default (app: App<Element>) => {
  const pinia = createPinia();
  app.use(pinia);
  setActivePinia(pinia);

  /* app.config.warnHandler = (msg, _instance, trace) => {
    if (
      ![
        // Ignore warning caused by use of stores outside of components.
        // This is perfectly safe in our case since we're using Lambd that
        // only process one request at a time.
        'getCurrentInstance() called inside a computed getter',

        // Ignore warning of hydration mismatch for style tag.
        // This cannot be avoided due to how SSR formats the CSS vs the browser.
        'Hydration text mismatch in[object HTMLStyleElement]'
      ].some((warning) => msg.includes(warning))
    ) {
      // eslint-disable-next-line no-console
      console.warn('[Vue warn]: '.concat(msg).concat(trace));
    }

    return null;
  }; */
};
