<template>
  <div class="content__item-form-type content__item-form-type--select">
    <div
      class="content__item-form-group content__item-form-group--select form-group"
      :class="{
        'form-group--error': showValidationErrors && !model.state.isValid,
        'form-group--has-value': model.state.value,
        'form-group--is-focus': isFocus,
        'form-group--valid': !!model.state.isValid,
        'select-is-empty': model.state.isEmpty
      }"
    >
      <label class="content__item-form-label content__item-form-label--select">{{ model.state.label }}</label>

      <vue-select
        ref="vueSelect"
        v-model="selectedValue"
        :options="model.state?.options"
        :searchable="!!model.state.selectSearchEnabled"
        :search-placeholder="placeholder"
        :placeholder="placeholder"
        clear-on-select
        close-on-select
        label-by="label"
        :aria-label="model.state.label"
        @focus="isFocus = true"
        @blur="isFocus = false"
      >
        <template #dropdown-item="{ option }">
          <template v-if="option.label === 'No results found'">
            <div class="pointer-events-none no-results">
              {{ option.label ?? option.value }}
            </div>
          </template>
          <template v-else>
            <div>{{ option.label ?? option.value }}</div>
          </template>
        </template>
      </vue-select>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import VueNextSelect from 'vue-next-select';
import type { FormElementSelectModel } from '@/src/components/addons/registration/Models/FormElementSelectModel';
import useAxios from '@/src/hooks/useAxios';
import { InputOptionType } from '@/src/typings/enums/enums';
import type { FieldOptionItem } from '@/src/components/addons/registration/types';
import { useCampaignStore } from '@/src/store/campaign';

type VueNextSelectInstance = InstanceType<typeof VueNextSelect>;

// SEE docs for custom select here: https://iendeavor.github.io/vue-next-select/
export default defineComponent({
  name: 'FormElementSelect',
  components: {
    'vue-select': VueNextSelect
  },
  props: {
    model: {
      type: Object as PropType<FormElementSelectModel>,
      required: true
    },

    showValidationErrors: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const campaignStore = useCampaignStore();
    const campaignState = campaignStore.model?.state;

    const initialValue = props.model.getInitialValue();

    const isFocus = ref(false);
    const selectedValue = ref<FieldOptionItem | undefined>(undefined);
    const vueSelect = ref<VueNextSelectInstance | null>(null);

    // used to set the aria-label attribute on the input element in vue-select
    // could not find a way to do this in the template
    const setAriaLabel = () => {
      const inputElement = vueSelect.value?.$el.querySelector('input');
      if (inputElement) {
        inputElement.setAttribute('aria-label', props.model.state.label);
      }
    };

    if (initialValue !== null && initialValue !== undefined && props.model.state.options) {
      selectedValue.value = props.model.state.options.find((option) => option.value === initialValue);
    }

    const placeholder = computed(() => {
      if (selectedValue.value?.label) {
        return selectedValue.value?.label;
      }

      return props.model.state.placeholder ?? '';
    });

    const checkCountry = async () => {
      if (!campaignState?.config?.ipstackApi) {
        throw new Error('Missing IP stack API');
      }

      const { fetchDataClean } = useAxios<{ country_code: string }>(`https://api.ipstack.com/check`, {
        access_key: campaignState.config.ipstackApi,
        format: '1'
      });

      const response = await fetchDataClean();

      if (response && response.country_code) {
        const selectedCountry = props.model.state.options?.find((option) => option.value === response.country_code);

        if (selectedCountry) {
          selectedValue.value = selectedCountry;
        }
      }
    };

    const onFocus = () => {
      isFocus.value = true;
    };

    onMounted(() => {
      setAriaLabel();

      if (
        props.model.state.optionType === InputOptionType.COUNTRIES &&
        !props.model.state.disableDefaultCountry &&
        !selectedValue.value
      ) {
        checkCountry();
      }
    });

    watch(
      selectedValue,
      () => {
        if (selectedValue.value) {
          /**
           * When option is selected, but no value is chosen in the backend - we have to use the label.
           * As there is no validation, and its the behavior the old platform had.
           */
          // eslint-disable-next-line vue/no-mutating-props
          props.model.state.value = selectedValue.value.value ? selectedValue.value.value : selectedValue.value.label;
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          props.model.state.value = '';
        }
      },
      { immediate: true }
    );

    onUnmounted(() => {
      if (!props.model.state.visibilityConditions?.check()) {
        // eslint-disable-next-line vue/no-mutating-props
        props.model.state.value = '';
      }
    });

    return {
      vueSelect,
      selectedValue,
      placeholder,
      isFocus,
      onFocus
    };
  }
});
</script>

<style lang="scss">
.select-is-empty {
  li {
    pointer-events: none !important;
    &.vue-dropdown-item.highlighted {
      background-color: #fff;
    }
  }
}

.vue-input {
  input {
    font-size: inherit;
    color: inherit;
  }
}
</style>
