import type { FormElementTextData } from '@/src/components/addons/registration/Models/FormElementTextModel';
import { FormElementTextModel } from '@/src/components/addons/registration/Models/FormElementTextModel';

export class FormElementHiddenModel extends FormElementTextModel {
  parseFormElement(data: FormElementTextData) {
    super.parseFormElement(data);
    this.state.disableCookie = true;
  }

  getValidationRules(): string[] {
    const rules = super.getValidationRules();

    if (rules.includes('required')) {
      rules.splice(rules.indexOf('required'), 1);
    }

    return rules;
  }

  authorSignature(): string {
    return 'Dannie Hansen';
  }
}
