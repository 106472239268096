import type { IntegrationKlasselotterietData as IntegrationEspCustomKlasselotterietData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';
import type { Component } from 'vue';

export type IntegrationEspCustomKlasselotterietState = BaseIntegrationState;

export class IntegrationEspCustomKlasselotterietModel extends BaseIntegration<
  IntegrationEspCustomKlasselotterietData,
  IntegrationEspCustomKlasselotterietState
> {
  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/esp/custom/klasselotteriet/View.vue');
  }

  hasVueComponent() {
    return true;
  }
}
