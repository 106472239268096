import type { AddonGigyaData } from '@/src/components/addons/gigya/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

interface AddonGigyaState extends AddonModelState {
  settings: {
    apiKey?: string;
    language?: string;
    screenSet?: string;
  };
}

export class AddonGigyaModel extends AddonModel<AddonGigyaData, AddonGigyaState, 'gigya'> {
  parseAddon(data: AddonGigyaData) {
    const state = this.state;

    if (data.settings) {
      state.settings = state.settings ?? {};
      if (data.settings.api_key && data.settings.api_key !== '') {
        state.settings.apiKey = data.settings.api_key;
      } else {
        state.settings.apiKey = undefined;
      }
      if (data.settings.language && data.settings.language !== '') {
        state.settings.language = data.settings.language;
      } else {
        state.settings.language = undefined;
      }
      if (data.settings.screen_set && data.settings.screen_set !== '') {
        state.settings.screenSet = data.settings.screen_set;
      } else {
        state.settings.screenSet = undefined;
      }
    } else {
      state.settings = {};
    }
  }

  isAddonValid(): boolean {
    const data = this.getData();
    return !!(data.settings.api_key && data.settings.screen_set);
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
