import type { SettingsType } from '@/src/components/layout/SettingsModel';
import { SettingsModel } from '@/src/components/layout/SettingsModel';
import type { VisibilityConditionsData } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import type { RowSettings } from '@/src/typings/interfaces/data/settings/row-settings';
import type { SettingsData } from '@/src/typings/interfaces/data/settings/settings';

export class RowSettingsModel extends SettingsModel<RowSettings> {
  public getSettingsType(): SettingsType {
    return 'row';
  }

  public getDefaultPadding() {
    return 0;
  }

  protected constructVisibilityConditionData(data: SettingsData): VisibilityConditionsData {
    const showOnDesktop = Number(data.advanced?.show_on_desktop ?? '1') ? '1' : '0';
    const showOnMobile = Number(data.advanced?.show_on_mobile ?? '1') ? '1' : '0';

    return {
      ...(data.advanced?.visibility_condition ?? {}),
      devices: {
        desktop: showOnDesktop,
        tablet: showOnDesktop,
        mobile: showOnMobile
      }
    };
  }
}
