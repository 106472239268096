import type { Component } from 'vue';
import type { IntegrationEspBrazeData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export type IntegrationEspBrazeState = BaseIntegrationState;

export class IntegrationEspBrazeModel extends BaseIntegration<IntegrationEspBrazeData, IntegrationEspBrazeState> {
  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/esp/braze/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }
}
