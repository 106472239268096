<template>
  <component is="style" v-if="globalStyling" type="text/css">{{ globalStyling }}</component>

  <GameIndicatorsV2
    v-if="model && topIndicators && topIndicators?.length > 0 && hasPosition"
    :model="model"
    :items="topIndicators"
    placement="top"
  />
  <GameIndicatorsV2
    v-if="model && centerIndicators && centerIndicators?.length > 0 && hasPosition"
    :model="model"
    :items="centerIndicators"
    placement="center"
  />
  <GameIndicators
    v-if="model && hasTopIndicators && !hasPosition"
    :indicators="model"
    :indicator-class="indicatorsClasses"
  />
  <!-- Dynamic game component -->
  <slot />

  <GameIndicators
    v-if="
      model &&
      !hasTopIndicators &&
      ((bottomIndicators && bottomIndicators.length > 0) || (centerIndicators && centerIndicators.length > 0)) &&
      !hasPosition
    "
    :indicators="model"
    :indicator-class="indicatorsClasses"
  />
  <GameIndicatorsV2
    v-if="model && bottomIndicators && bottomIndicators?.length > 0 && hasPosition"
    :model="model"
    :items="bottomIndicators"
    placement="bottom"
  />
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';

import type { GameIndicatorSettingsModel, GameIndicatorTypeState } from '@/src/components/indicators/Model';
import {
  GameIndicatorDirectionTypes,
  GameIndicatorHAlignTypes,
  GameIndicatorVAlignTypes
} from '@/src/components/indicators/Model';
import GameIndicatorsV2 from '@/src/components/indicators/v2/View.vue';
import GameIndicators from '@/src/components/indicators/v1/View.vue';
import useDevice from '@/src/hooks/useDevice';

export default defineComponent({
  name: 'Indicators',
  components: {
    GameIndicators,
    GameIndicatorsV2
  },
  props: {
    model: {
      type: Object as PropType<GameIndicatorSettingsModel>,
      required: false
    },
    hasPosition: {
      type: Boolean,
      required: true
    },
    topIndicators: {
      type: Array as PropType<GameIndicatorTypeState[]>,
      default: () => []
    },
    centerIndicators: {
      type: Array as PropType<GameIndicatorTypeState[]>,
      default: () => []
    },
    bottomIndicators: {
      type: Array as PropType<GameIndicatorTypeState[]>,
      default: () => []
    }
  },

  setup(props) {
    const hasTopIndicators = computed(() => {
      return props.topIndicators && props.topIndicators.length > 0;
    });

    const indicatorsClasses = computed(() => {
      if (props.hasPosition) {
        return {};
      }

      let hasTopPosition = false;
      let hasBottomPosition = false;
      let sameVAlign = true;
      let sameHAlign = true;
      let prevVAlign: string | undefined;
      let prevHAlign: string | undefined;
      let hasIcon = false;

      const direction = props.model?.state.layout?.direction || GameIndicatorDirectionTypes.HORIZONTAL;

      const { isDesktop } = useDevice();

      props.model?.state.indicators.forEach((indicator) => {
        /**
         * Its important to look at the last indicator, otherwise we just overwrite the values we need to set.
         * if the next is false, and the frist is true. this is the reason for looking at the previus.
         */
        if (prevVAlign && indicator.valign !== prevVAlign) {
          sameVAlign = false;
        }

        if (prevHAlign && indicator.halign !== prevHAlign) {
          sameHAlign = false;
        }
        if (indicator.icon && !hasIcon) {
          hasIcon = true;
        }

        if (isDesktop) {
          if (indicator.halign === GameIndicatorHAlignTypes.CENTER) {
            hasTopPosition = indicator.valign === GameIndicatorVAlignTypes.TOP;
            hasBottomPosition = indicator.valign === GameIndicatorVAlignTypes.BOTTOM;
          }
        } else if (indicator.valign === 'top') {
          hasTopPosition = true;
        } else {
          hasBottomPosition = true;
        }

        prevVAlign = indicator.valign;
        prevHAlign = indicator.halign;
      });

      return {
        [`game-indicator--with-icon`]: hasIcon,
        [`game-indicator--align-${prevVAlign}-${prevHAlign}`]: sameVAlign && sameHAlign,
        [`game-indicator--${direction}`]: true,
        [`game-indicator--fill-above`]: hasTopPosition,
        [`game-indicator--fill-below`]: hasBottomPosition
      };
    });

    const globalStyling = computed(() => {
      const styleRules: string[] = [];

      const baseVersion = props.hasPosition ? '-v2' : '';

      const layout = props.model?.state.layout;

      if (layout?.color) {
        styleRules.push(`
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion} {
        color: ${layout.color};
      }
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion}__icon-path,
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion}__icon-path path,
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion}__icon-path--dice circle {
        fill: ${layout.color};
      }
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion}__icon-path--dice rect,
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion}__circle-path {
        stroke: ${layout.color};
      }
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion}::before {
        background-color: ${layout.color};
      }
    `);
      }

      if (layout?.fontFamily) {
        styleRules.push(`
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion} {
        font-family: ${layout.fontFamily}, Arial;
      }
    `);
      }

      if (layout?.fontSize) {
        styleRules.push(`
      body .game-indicator${baseVersion} .game-indicator-item${baseVersion}__value {
        font-size: ${layout.fontSize};
      }
    `);
      }

      // Only if there are styles defined, we join them; otherwise, we return an empty string
      return styleRules.length > 0 ? styleRules.join(' ') : '';
    });

    return {
      indicatorsClasses,
      globalStyling,
      hasTopIndicators
    };
  }
});
</script>
