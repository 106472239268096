import type { AddonHtmlElementData } from '@/src/components/addons/html-element/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

export interface AddonHTMLState extends AddonModelState {
  html: string;
}

export class AddonHTMLModel extends AddonModel<AddonHtmlElementData, AddonHTMLState, 'html-element'> {
  parseAddon(data: AddonHtmlElementData) {
    const state = this.state;
    state.html = data.settings.html ?? '<p>Hello world!</p>';
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
