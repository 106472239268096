import { defineStore } from 'pinia';

interface State {
  currentStep: number;
  totalSteps: number | undefined;
}

export const useGameStepsStore = defineStore('gamesteps', {
  state: (): State => ({
    currentStep: 0,
    totalSteps: undefined
  })
});
