import type { IntegrationKlasselotterietSegmentsData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export type IntegrationKlasselotterietSegmentsState = BaseIntegrationState;

export class IntegrationKlasselotterietSegmentsModel extends BaseIntegration<
  IntegrationKlasselotterietSegmentsData,
  IntegrationKlasselotterietSegmentsState
> {
  authorSignature(): string {
    return 'Dannie Hansen';
  }

  hasVueComponent() {
    return false;
  }
}
