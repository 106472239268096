import AppConfig from '@/app.config';

interface AppConfigData {
  adminUiEndpoint: string;
  apiEndpoint: string;
  leaderboardUI: string;
  facebookId: string;

  sentry: {
    dsn: string;
    environment: string;
  };

  adminUiEndpointOverwrites: {
    lookup: string;
    overwrite: string;
  }[];

  analyticsEndpoint?: string;
}

export const useAppConfig = (): AppConfigData => {
  return AppConfig;
};
