import type { GameIndicator } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GameRouletteData,
  GameRouletteFieldItemData,
  GameRouletteGeneralData,
  GameRouletteInputData,
  GameRouletteInputLayoutData,
  GameRouletteIndicatorData,
  GameRouletteVisualData,
  GameRouletteWheelData
} from '@/src/components/games/roulette/Data';
import { GameRouletteBallType, GameRouletteInputPositionType } from '@/src/components/games/roulette/Data';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import { DrawType } from '@/src/typings/enums/enums';
import { getDeviceData } from '@/src/hooks/useDevice';
import { useUtilityStore } from '@/src/store/utility';
import { useCampaignStore } from '@/src/store/campaign';

export interface GameRouletteInputLayoutState {
  fontFamily?: string;
  fontSize?: number;
  verticalPadding?: string;
  width?: string;
  horizontalPadding?: string;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  borderThickness?: string;
  borderRadius?: string;
}

export interface GameRouletteInputState {
  inputLayout?: GameRouletteInputLayoutState;
  placement: GameRouletteInputPositionType;
}

export interface GameRouletteIndicatorState {
  displayType: GameRouletteBallType;
  image?: string;
  startPosition: string;
  endPosition: string;
  color?: string;
  size?: number;
}
export interface GameRouletteWheelState {
  image?: string;
  numberOfFields?: number;
}

export interface GameRouletteVisualState {
  input?: GameRouletteInputState;
  indicator?: GameRouletteIndicatorState;
  wheel?: GameRouletteWheelState;
  fields?: {
    [key: string]: GameRouletteFieldItemData;
  };
}

export interface GameRouletteGeneralState {
  spins: number;
  drawType: DrawType;
  winnerChance?: string;
}

export interface GameRouletteState {
  general: GameRouletteGeneralState;
  visuals: GameRouletteVisualState;
  indicators: GameIndicatorSettingsModel;
}

export class GameRouletteModel extends GameModel<GameRouletteData, GameRouletteState> {
  parseGame(data: GameRouletteData) {
    const state = this.state;
    state.general = GameRouletteModel.constructGeneralState(data.general);
    state.visuals = {};
    state.visuals.input = GameRouletteModel.parseInputData(data.visuals);
    state.visuals.indicator = GameRouletteModel.parserIndicatorData(data.visuals);
    state.visuals.wheel = GameRouletteModel.parseWheelData(data.visuals);
    state.visuals.fields = data.visuals.fields;

    if (state.visuals.input?.inputLayout?.fontFamily) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(state.visuals.input?.inputLayout?.fontFamily);
    }

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    }
  }

  private static constructWheelState(data: GameRouletteWheelData): GameRouletteWheelState {
    return {
      image: data.image,
      numberOfFields: Number(data.fields_number)
    };
  }

  private static parseWheelData(data: GameRouletteVisualData): GameRouletteWheelState | undefined {
    if (data) {
      const useData = getDeviceData(data.wheel);

      if (!useData) {
        return undefined;
      }
      return GameRouletteModel.constructWheelState(useData);
    }
  }

  private static constructIndicatorState(data: GameRouletteIndicatorData): GameRouletteIndicatorState {
    const campaignStore = useCampaignStore();
    const layout = campaignStore.model?.state.layout;
    return {
      displayType: data.display_type ?? GameRouletteBallType.IMAGE,
      image: data.image,
      startPosition: data.start_position ?? '0px',
      endPosition: data.end_position ?? '0px',
      ...(data.display_type !== GameRouletteBallType.IMAGE && { color: data.icon_color || layout?.baseColor }),
      size: Number(data.size)
    };
  }

  private static parserIndicatorData(data: GameRouletteVisualData): GameRouletteIndicatorState | undefined {
    if (data) {
      const useData = getDeviceData(data.indicator);

      if (!useData) {
        return undefined;
      }
      return GameRouletteModel.constructIndicatorState(useData);
    }
  }

  private static constructInputLayoutState(data: GameRouletteInputLayoutData): GameRouletteInputLayoutState {
    return {
      fontFamily: data.font_type,
      fontSize: Number(data.text_size),
      verticalPadding: data.vertical_padding,
      horizontalPadding: data.horizontal_padding,
      backgroundColor: data.background_color,
      textColor: data.text_color,
      borderColor: data.border_color,
      borderThickness: data.border_thickness,
      borderRadius: data.corner_radius,
      width: data.width
    };
  }

  private static constructInputState(data: GameRouletteInputData): GameRouletteInputState {
    return {
      ...(data.input_layout && { inputLayout: this.constructInputLayoutState(data.input_layout) }),
      placement: data.placement ?? GameRouletteInputPositionType.BELOW
    };
  }

  private static parseInputData(data: GameRouletteVisualData): GameRouletteInputState | undefined {
    if (data) {
      const useData = getDeviceData(data.input_box);

      if (!useData) {
        return undefined;
      }
      return GameRouletteModel.constructInputState(useData);
    }
  }

  private static constructGeneralState(data: GameRouletteGeneralData): GameRouletteGeneralState {
    return {
      spins: Number(data.spins),
      drawType: data.draw_type,
      ...(data.winner_chance && { winnerChance: data.winner_chance })
    };
  }

  public hasInstantWin(): boolean {
    const state = this.state;
    return state.general?.drawType === DrawType.AUTO;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'tries',
        metricKey: {
          tries_used: 'tries_used'
        },
        icon: GameIndicatorIcon.TRIES,
        value: {
          tries_used: 1
        }
      }
    ];
  }
}
