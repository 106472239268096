import type {
  GameRockPaperScissorsAdvancedData,
  GameRockPaperScissorsData,
  GameRockPaperScissorsGeneralData,
  GameRockPaperScissorsLabelsData
} from '@/src/components/games/rockpaperscissors/Data';
import type { TransitionTypes } from '@/src/typings/types/types';
import type { GameIndicator, GameState, HasSound, InstantWinData } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { DrawType } from '@/src/typings/enums/enums';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';

export interface GameRockPaperScissorsGeneralState {
  rounds: number;
  rock: string;
  paper: string;
  scissors: string;
  drawType?: string;
  winnerChance: string;
  campaignLimitInterval?: string;
  campaignLimit?: boolean;
  disableDrawOutput?: boolean;
  fieldsOccupied?: string[];
}

interface GameRockPaperScissorsLabelsState {
  headline: string;
  rockLabel: string;
  paperLabel: string;
  scissorsLabel: string;
  endWon: string;
  endLost: string;
  endEven: string;
}
interface GameRockPaperScissorsAdvancedSoundState {
  enabled: boolean;
  winSound?: string;
  looseSound?: string;
  drawSound?: string;
}
interface GameRockPaperScissorsAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}
interface GameRockPaperScissorsAdvancedState {
  sound: GameRockPaperScissorsAdvancedSoundState;
  animation: GameRockPaperScissorsAdvancedAnimationState;
}

interface GameRockPaperScissorsState extends GameState {
  alias: string;
  general: GameRockPaperScissorsGeneralState;
  customLabels: GameRockPaperScissorsLabelsState;
  advanced: GameRockPaperScissorsAdvancedState;
}

export class GameRockPaperScissorsModel
  extends GameModel<GameRockPaperScissorsData, GameRockPaperScissorsState>
  implements HasSound
{
  parseGame(data: GameRockPaperScissorsData) {
    const state = this.state;
    state.general = GameRockPaperScissorsModel.constructGeneralState(data.general);

    if (data.customlabels) {
      state.customLabels = GameRockPaperScissorsModel.constructCustomLabelsStste(data.customlabels);
    }

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameRockPaperScissorsModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GameRockPaperScissorsModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructAdvancedAnimationState(
    data: GameRockPaperScissorsAdvancedData | undefined
  ): GameRockPaperScissorsAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private static constructAdvancedSoundState(
    data: GameRockPaperScissorsAdvancedData | undefined
  ): GameRockPaperScissorsAdvancedSoundState {
    return {
      enabled: !!data?.win_sound || !!data?.loose_sound || !!data?.draw_sound,
      ...(data?.win_sound && { winSound: data.win_sound }),
      ...(data?.loose_sound && { looseSound: data.loose_sound }),
      ...(data?.draw_sound && { drawSound: data.draw_sound })
    };
  }

  private static constructGeneralState(data: GameRockPaperScissorsGeneralData): GameRockPaperScissorsGeneralState {
    return {
      drawType: data.draw_type,
      rounds: Number(data.rounds),
      rock: data.rock,
      paper: data.paper,
      scissors: data.scissors,
      winnerChance: data.winner_chance || 'random',
      ...(data.campaign_limit && { campaignLimit: data.campaign_limit === '1' }),
      ...(data.campaign_limit_interval && { campaignLimitInterval: data.campaign_limit_interval }),
      ...(data.disable_draw_output && { disableDrawOutput: data.disable_draw_output === '1' })
    };
  }

  private static constructCustomLabelsStste(data: GameRockPaperScissorsLabelsData): GameRockPaperScissorsLabelsState {
    return {
      headline: data.headline,
      rockLabel: data.rock_label,
      paperLabel: data.paper_label,
      scissorsLabel: data.scissors_label,
      endWon: data.end_won,
      endLost: data.end_lost,
      endEven: data.end_even
    };
  }

  public setInstantWinnerData(data: InstantWinData) {
    super.setInstantWinnerData(data);
  }

  public hasInstantWin(): boolean {
    const state = this.state;
    return state.general?.drawType === DrawType.AUTO;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'wins',
        metricKey: {
          wins_used: 'wins'
        },
        icon: GameIndicatorIcon.WINS,
        value: {
          wins_used: 0
        }
      },
      {
        indicatorKey: 'round',
        metricKey: {
          round_used: 'rounds'
        },
        icon: GameIndicatorIcon.ROUNDS,
        value: {
          round_used: 1
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.drawSound) {
      sounds.push(this.state.advanced.sound.drawSound);
    }

    if (this.state.advanced.sound.looseSound) {
      sounds.push(this.state.advanced.sound.looseSound);
    }

    if (this.state.advanced.sound.winSound) {
      sounds.push(this.state.advanced.sound.winSound);
    }

    return sounds;
  }
}
