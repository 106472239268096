export const setStorageItem = (name: string, value: string) => {
  try {
    localStorage.setItem(name, value);
  } catch (e) {}
};

export const getStorageItem = (name: string) => {
  try {
    // @ts-ignore
    return localStorage.getItem(name);
  } catch (e) {}
};

export const removeStorageItem = (name: string) => {
  try {
    localStorage.removeItem(name);
  } catch (e) {}
};

export const getAllStorageItems = () => {
  const localStorageKeys: string[] = [];

  try {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key !== null) {
        localStorageKeys.push(key);
      }
    }
  } catch (e) {}

  return localStorageKeys;
};
