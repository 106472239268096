import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export enum RatingType {
  STARS = 'stars',
  SMILES = 'smiles'
}

export interface FormElementRatingSettingsData extends BaseFormElementSettingsData {
  rating_type?: RatingType;
  stars_num?: string;
  rate_color?: string;
  rate_color_active?: string;
}

export interface FormElementRatingData extends BaseFormElementData {
  settings?: FormElementRatingSettingsData;
}

export interface FormElementRatingState extends BaseFormElementState<number> {
  elementMessage?: string;
  ratingType: RatingType;
  starsNum: number;
  rateColor: string;
  rateColorActive: string;
}

export class FormElementRatingModel extends BaseFormElementModel<
  number,
  FormElementRatingData,
  FormElementRatingState
> {
  parseFormElement(data: FormElementRatingData) {
    const state = this.state;
    // rating Model
    state.ratingType = data.settings?.rating_type || RatingType.STARS;

    if (state.ratingType === RatingType.STARS) {
      state.starsNum = data.settings?.stars_num ? parseInt(data.settings?.stars_num) : 5;
    } else {
      state.starsNum = 5;
    }

    state.rateColor = data.settings?.rate_color || '#d3d3d3';
    state.rateColorActive = data.settings?.rate_color_active || '#ffd700';
  }

  getInitialValue(): number | undefined {
    return this.parseStringValue(this.getInitialStringValue() ?? '');
  }

  parseStringValue(value: string): number | undefined {
    return Number(value);
  }

  getStringifiedValue(): string | undefined {
    return this.state.value || this.state.value === 0 ? this.state.value.toString() : undefined;
  }

  getSerializedPostValue(): string {
    return typeof this.state.value !== 'undefined' ? this.state.value.toString() : '';
  }

  getSerializedCookieValue(): string {
    return this.getSerializedPostValue();
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
