import { SdkFormFieldModel } from '../formField';
import { SdkBaseAddonModel } from '.';
import type { AddonAuthenticationModel } from '@/src/components/addons/authentication/Model';

export class SdkAddonAuthentication extends SdkBaseAddonModel {
  #model: AddonAuthenticationModel;

  constructor(model: AddonAuthenticationModel) {
    super(model);
    this.#model = model;
  }

  public get formFields(): SdkFormFieldModel[] | undefined {
    if (this.#model.state.fields && !this.#model.state.settings.customIdentifier) {
      return this.#model.state.fields.map((field) => new SdkFormFieldModel(field)) ?? [];
    }

    return undefined;
  }
}
