import type { CSSProperties } from 'vue';
import { AlignContentType, BorderType } from '@/src/typings/enums/enums';
import type { AddonLfDividerData, DividerStyleData } from '@/src/components/addons/lf-divider/Data';

import { getDeviceData } from '@/src/hooks/useDevice';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

interface DividerStyleState {
  type?: BorderType;
  weight?: string;
  color?: string;
  width?: string;
  alignment?: AlignContentType;
}

export interface AddonLfDividerState extends AddonModelState {
  styles: DividerStyleState;
  elementStyling?: {
    inline?: CSSProperties;
    align?: CSSProperties;
  };
}

export class AddonLfDividerModel extends AddonModel<AddonLfDividerData, AddonLfDividerState, 'lf-divider'> {
  parseAddon(data: AddonLfDividerData) {
    const state = this.state;
    state.styles = state.styles ?? {};

    const defaultStyles = {
      alignment: AlignContentType.CENTER,
      width: '100%',
      weight: '1',
      color: '#000000',
      type: BorderType.SOLID
    };

    // default styles
    if (data.settings?.general?.styles) {
      state.styles = AddonLfDividerModel.parseStyleData(data) ?? defaultStyles;
    } else {
      state.styles = defaultStyles;
    }

    // inline styles
    state.elementStyling = state.elementStyling || {};
    if (state.styles) {
      state.elementStyling.inline = AddonLfDividerModel.constructElementInlineStyles(state.styles);
      state.elementStyling.align = {
        textAlign: state.styles.alignment || AlignContentType.LEFT
      };
    } else {
      state.elementStyling = {};
    }
  }

  private static parseStyleData(data: AddonLfDividerData): DividerStyleState | undefined {
    if (data.settings?.general?.styles) {
      const useData = getDeviceData<DividerStyleData>(data.settings.general.styles);

      if (!useData) {
        return undefined;
      }
      return AddonLfDividerModel.constructStyleState(useData);
    }
  }

  private static constructStyleState(data: DividerStyleData): DividerStyleState {
    return {
      alignment: data.alignment || AlignContentType.CENTER,
      weight: data.weight || '1',
      color: data.color || '#000000',
      width: data.width || '100%',
      type: data.type || BorderType.SOLID
    };
  }

  private static constructElementInlineStyles(state: DividerStyleState): CSSProperties {
    return {
      ...(state.type && { borderBottomStyle: state.type }),
      borderBottomWidth: `${state.weight}px`,
      ...(state.color && { borderBottomColor: state.color }),
      ...(state.width && { width: state.width })
    };
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
