import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GameHitTheTargetAdvancedData,
  GameHitTheTargetData,
  GameHitTheTargetGeneralData,
  GameHitTheTargetImagesData,
  GameHitTheTargetImagesSymbolData
} from '@/src/components/games/hitthetarget/Data';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import { useUtilityStore } from '@/src/store/utility';

export interface GameHitTheTargetGeneralGameSettingsState {
  height: string;
  symbolWidth: number;
  symbolSpeed: number;
}

export interface GameHitTheTargetGeneralState {
  pointsToWin: number;
  positiveColor: string;
  negativeColor: string;
  fontSize?: string;
  fontFamily?: string;
  gameSettings: GameHitTheTargetGeneralGameSettingsState;
  enableBestTime: boolean;
  enableTimeRanking: boolean;
}

export interface GameHitTheTargetImagesSymbolState {
  id: number;
  image: string;
  points: number;
  label: string;
  direction: 'left_right' | 'right_left' | 'top_bottom' | 'bottom_top';
  degree: number;
}

export interface GameHitTheTargetImagesState {
  cursorImage: string;
  cursorImageOffsetX: number;
  cursorImageOffsetY: number;
  symbol: GameHitTheTargetImagesSymbolState[];
}

export interface GameHitTheTargetAdvancedSoundState {
  enabled: boolean;
  plusSound?: string;
  minusSound?: string;
}

export interface GameHitTheTargetAdvancedState {
  sound: GameHitTheTargetAdvancedSoundState;
}

export interface GameHitTheTargetState {
  general: GameHitTheTargetGeneralState;
  images: GameHitTheTargetImagesState;
  advanced: GameHitTheTargetAdvancedState;
}

export class GameHitTheTargetModel
  extends GameModel<GameHitTheTargetData, GameHitTheTargetState>
  implements HasGameTimeChallenge, HasSound
{
  parseGame(data: GameHitTheTargetData): void {
    const state = this.state;
    state.general = GameHitTheTargetModel.constructGeneralState(data.general);
    state.images = GameHitTheTargetModel.constructImagesState(data.images);

    if (state.general.fontFamily) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(state.general.fontFamily);
    }

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameHitTheTargetModel.constructAdvancedSoundState(data?.advanced);
  }

  public static constructAdvancedSoundState(
    data: GameHitTheTargetAdvancedData | undefined
  ): GameHitTheTargetAdvancedSoundState {
    return {
      enabled: !!data?.minus_sound || !!data?.plus_sound,
      ...(data?.minus_sound && {
        minusSound: data.minus_sound
      }),
      ...(data?.plus_sound && {
        plusSound: data.plus_sound
      })
    };
  }

  public static constructImagesSymbolState(data: GameHitTheTargetImagesSymbolData): GameHitTheTargetImagesSymbolState {
    return {
      id: Number(data.id),
      image: data.image,
      points: Number(data.points),
      label: data.label,
      direction: data.direction,
      degree: Number(data.degree)
    };
  }

  public static constructImagesState(data: GameHitTheTargetImagesData): GameHitTheTargetImagesState {
    return {
      cursorImage: data.cursor_image,
      cursorImageOffsetX: Number(data.cursor_image_offset_x),
      cursorImageOffsetY: Number(data.cursor_image_offset_y),
      symbol: data.symbol.map((symbol) => {
        return GameHitTheTargetModel.constructImagesSymbolState(symbol);
      })
    };
  }

  public static constructGeneralGameSettings(
    data: GameHitTheTargetGeneralData
  ): GameHitTheTargetGeneralGameSettingsState {
    const deviceData = getDeviceData(data.game_settings);

    if (!deviceData) {
      return {
        height: '500px',
        symbolWidth: 0,
        symbolSpeed: 90
      };
    }

    return {
      ...{ symbolWidth: parseInt(deviceData.symbol_width) },
      ...{ height: deviceData.height },
      ...{ symbolSpeed: Number(deviceData.symbol_speed) }
    };
  }

  private static constructGeneralState(data: GameHitTheTargetGeneralData): GameHitTheTargetGeneralState {
    return {
      pointsToWin: Number(data.points_towin),
      positiveColor: data.positive_color,
      negativeColor: data.negative_color,
      fontSize: data.font_size,
      fontFamily: data.font_type,
      gameSettings: GameHitTheTargetModel.constructGeneralGameSettings(data),
      ...(data?.time &&
        data.time === 1 && {
          timeEnabled: data.time === 1,
          timeLimit: Number(data.time_limit)
        }),
      enableBestTime: data.enable_best_time === '1',
      enableTimeRanking: data.enable_time_ranking === '1'
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 60,
          time_used: 0
        }
      },
      {
        indicatorKey: 'score',
        metricKey: {
          score_used: 'points'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          score_used: 0
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.minusSound) {
      sounds.push(this.state.advanced.sound.minusSound);
    }
    if (this.state.advanced.sound.plusSound) {
      sounds.push(this.state.advanced.sound.plusSound);
    }

    return sounds;
  }
}
