export enum AgeGateType {
  YEAR = 'type_year',
  DATE = 'date_line',
  ACCEPT = 'accept_year'
}
export enum AgeGateCookieTimer {
  EMPTY = '',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}
export enum AgeGateFormatType {
  MONTHDAY = 'MM-DD-YYYY',
  DAYMONTH = 'DD-MM-YYYY',
  YEARMONTH = 'YYYY-MM-DD'
}

export enum AgeGateGroupAliasType {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}
