import { goToFictiveFlowPage } from './../../utilities/Flow';
import { BaseModel } from '@/src/models/BaseModel';
import { redirect, RedirectTarget } from '@/src/services/url';
import type { CampaignMessageData } from '@/src/typings/interfaces/data/campaign';
import { MessageDisplayTypes, MessageTypes } from '@/src/typings/interfaces/data/campaign';
import useFlow from '@/src/hooks/useFlow';
import { ContentType } from '@/src/components/layout/section/SectionBaseModel';

export interface MessagesState {
  description?: string;
  displayType?: MessageDisplayTypes;
  headline: string;
  message: MessageTypes;
  redirectTarget?: '_blank' | '_self' | '_top';
  redirectUrl?: string;
  flowPage?: number;
  inlineStyles?: string;
  style?: {
    alignment?: string;
  };
}

export default class MessagesHandlerModel extends BaseModel<CampaignMessageData, MessagesState> {
  parse(data: CampaignMessageData) {
    const state = this.state;

    state.description = data.description;
    state.headline = data.headline;
    state.message = data.message;

    if (data.display_type) {
      state.displayType = data.display_type;
    } else {
      state.displayType = MessageDisplayTypes.INLINE_MESSAGE;
    }

    if (data.redirect_target) {
      state.redirectTarget = data.redirect_target;
    }

    if (data.redirect_url) {
      state.redirectUrl = data.redirect_url;
    }

    if (data.flow_page) {
      state.flowPage = Number(data.flow_page);
    }

    state.inlineStyles = data.inline_styles;
    if (state.displayType === MessageDisplayTypes.INLINE_MESSAGE && data.inline_styles) {
      state.inlineStyles = data.inline_styles;
    }
  }

  trigger(): void {
    const { makeSpecificFlowPageActive } = useFlow();
    const state = this.state;

    if (
      (state.message === MessageTypes.CHEATING || state.message === MessageTypes.GAME_LIMIT) &&
      this.state.displayType === MessageDisplayTypes.INLINE_MESSAGE
    ) {
      goToFictiveFlowPage('Registration limit', {
        type: ContentType.TEXT,

        content: `<div class="row--registration-limit row-registration-limit row--game-limit" style="${this.state.inlineStyles}">
                          <h2>${this.state.headline}</h2>
                          <div class="message-body" style="${this.state.inlineStyles}">
                            ${this.state.description}
                          </div>
                       </div>`
      });
      return;
    } else if (this.state.displayType === MessageDisplayTypes.INLINE_MESSAGE) {
      goToFictiveFlowPage('Message', {
        type: ContentType.TEXT,

        content: `<div class="row row--message row-expired row--expired-campaign" style="${this.state.inlineStyles}">
                          <h2 style="${this.state.inlineStyles}">${this.state.headline}</h2>
                          <div class="message-body" style="${this.state.inlineStyles}">
                            ${this.state.description ?? ''}
                          </div>
                       </div>`
      });
      return;
    }

    // Show flowpage
    if (this.state.displayType === MessageDisplayTypes.FLOWPAGE) {
      makeSpecificFlowPageActive(Number(this.state.flowPage));
      return;
    }

    // Handle redirect
    if (this.state.displayType === MessageDisplayTypes.REDIRECT && this.state.redirectUrl) {
      let target = RedirectTarget.SELF;
      switch (this.state.redirectTarget) {
        case '_blank':
          target = RedirectTarget.BLANK;
          break;

        case '_top':
          target = RedirectTarget.TOP;
          break;

        case '_self':
          target = RedirectTarget.SELF;
          break;
      }

      redirect({
        url: this.state.redirectUrl,
        target: target ?? RedirectTarget.BLANK
      });
    }
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
