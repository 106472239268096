import type { GameIndicatorData } from '@/src/components/indicators/Model';
import type { TransitionTypes } from '@/src/typings/types/types';
import type { DeviceData } from '@/src/hooks/useDevice';

export enum GamePuzzleTileTypes {
  SQUARE_TILES = 'square_tiles',
  PUZZLE_TILES = 'puzzle_tiles'
}

export interface GamePuzzleGridData {
  image: string;
  background: string;
  rows: string;
  columns: string;
}

export interface DefaultData {
  delay: string;
  enable_best_time: string;
  enable_time_ranking: string;
  time: number;
  time_limit: string;
  tile_type?: GamePuzzleTileTypes;
  grid: DeviceData<GamePuzzleGridData>;
}

export interface GamePuzzleAdvancedData {
  sound?: string;
  drag_animation?: TransitionTypes;
  image_animation?: TransitionTypes;
}

export interface GamePuzzleData {
  alias: string;
  default: DefaultData;
  indicators: GameIndicatorData;
  advanced?: GamePuzzleAdvancedData;
}
