import type { IntegrationSaxoData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export type IntegrationSaxoState = BaseIntegrationState;

export class IntegrationSaxoModel extends BaseIntegration<IntegrationSaxoData, IntegrationSaxoState> {
  authorSignature(): string {
    return 'Ani Mikova';
  }

  hasVueComponent() {
    return false;
  }
}
