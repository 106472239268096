import { AddonLfTextModel } from '@/src/components/addons/lf-text/Model';
import { AddonLfImageModel } from '@/src/components/addons/lf-image/Model';
import { AddonGameflowModel } from '@/src/components/addons/gameflow/Model';
import { AddonLfHeadlineModel } from '@/src/components/addons/lf-headline/Model';
import { AddonLfAnimatedHeadlineModel } from '@/src/components/addons/lf-animated-headline/Model';
import { AddonLfButtonModel } from '@/src/components/addons/lf-button/Model';
import { AddonLfIconModel } from '@/src/components/addons/lf-icon/Model';
import { AddonHTMLModel } from '@/src/components/addons/html-element/Model';
import { AddonLfDividerModel } from '@/src/components/addons/lf-divider/Model';
import { AddonAccordionModel } from '@/src/components/addons/accordion/Model';
import { AddonRSSModel } from '@/src/components/addons/rss/Model';
import { AddonShareModel } from '@/src/components/addons/share/Model';
import { AddonIframeElementModel } from '@/src/components/addons/iframe-element/Model';
import { AddonCountdownModel } from '@/src/components/addons/countdown/Model';
import { AddonContactCTAModel } from '@/src/components/addons/contact-cta/Model';
import { AddonGoogleMapsModel } from '@/src/components/addons/googlemap/Model';
import { AddonLFShareModel } from '@/src/components/addons/lf-share/Model';
import { AddonMenuModel } from '@/src/components/addons/menu/Model';
import { AddonPrizesModel } from '@/src/components/addons/prizes/Model';
import { AddonRegistrationModel } from '@/src/components/addons/registration/Model';
import { AddonSliderModel } from '@/src/components/addons/slider/Model';
import { AddonSlideShowModel } from '@/src/components/addons/slideshow/Model';
import { AddonSponsorsModel } from '@/src/components/addons/sponsors/Model';
import { AddonTipAFriendModel } from '@/src/components/addons/tipafriend/Model';
import { AddonTrustPilotModel } from '@/src/components/addons/trustpilot/Model';
import { AddonVideoEmbedModel } from '@/src/components/addons/video-embed/Model';
import { AddonGameplayModel } from '@/src/components/addons/gameplay/Model';
import { AddonStepGuideModel } from '@/src/components/addons/stepguide/Model';
import { AddonGigyaModel } from '@/src/components/addons/gigya/Model';
import { AddonQuestionValidationModel } from '@/src/components/addons/question-validation/Model';
import { AddonAuthenticationModel } from '@/src/components/addons/authentication/Model';
import { AddonAgeGateModel } from '@/src/components/addons/age-gate/Model';
import { AddonIFrameGamesModel } from '@/src/components/addons/iframe-games/Model';
import { AddonSocialShareModel } from '@/src/components/addons/social-share/Model';
import { AddonLeaderboardModel } from '@/src/components/addons/leaderboard/Model';
import { AddonLottieModel } from '@/src/components/addons/lottie/Model';
import { AddonLeaderboardv2Model } from '@/src/components/addons/leaderboard-v2/Model';

// eslint-disable-next-line
export const AddonModelMapping: Record<string, any> = {
  countdown: AddonCountdownModel,
  'iframe-element': AddonIframeElementModel,
  'iframe-games': AddonIFrameGamesModel,
  share: AddonShareModel,
  rss: AddonRSSModel,
  accordion: AddonAccordionModel,
  'lf-divider': AddonLfDividerModel,
  'lf-headline': AddonLfHeadlineModel,
  'lf-text': AddonLfTextModel,
  'lf-image': AddonLfImageModel,
  'lf-animated-headline': AddonLfAnimatedHeadlineModel,
  'lf-button': AddonLfButtonModel,
  'lf-icon': AddonLfIconModel,
  'html-element': AddonHTMLModel,
  'contact-cta': AddonContactCTAModel,
  googlemap: AddonGoogleMapsModel,
  'lf-share': AddonLFShareModel,
  menu: AddonMenuModel,
  prizes: AddonPrizesModel,
  registration: AddonRegistrationModel,
  slider: AddonSliderModel,
  slideshow: AddonSlideShowModel,
  sponsors: AddonSponsorsModel,
  tipafriend: AddonTipAFriendModel,
  trustpilot: AddonTrustPilotModel,
  'video-embed': AddonVideoEmbedModel,
  stepguide: AddonStepGuideModel,
  gigya: AddonGigyaModel,
  'question-validation': AddonQuestionValidationModel,
  authentication: AddonAuthenticationModel,
  'age-gate': AddonAgeGateModel,
  gameflow: AddonGameflowModel,
  gameplay: AddonGameplayModel,
  'social-share': AddonSocialShareModel,
  leaderboard: AddonLeaderboardModel,
  lottie: AddonLottieModel,
  'leaderboard-v2': AddonLeaderboardv2Model
};
