import type { Component } from 'vue';
import type { IntegrationEspClickdimensionData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationEspClickdimensionState extends BaseIntegrationState {
  apiDomain?: string;
  accountKey?: string;
  domain?: string;
}

export class IntegrationEspClickdimensionModel extends BaseIntegration<
  IntegrationEspClickdimensionData,
  IntegrationEspClickdimensionState
> {
  parse(data: IntegrationEspClickdimensionData) {
    super.parse(data);

    this.state.apiDomain = data.settings?.api_domain;
    this.state.accountKey = data.settings?.cd_accountkey;
    this.state.domain = data.settings?.cd_domain;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/esp/clickdimension/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Istvan Bovan / Dannie Hansen';
  }
}
