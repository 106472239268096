import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

interface FormElementTextareaSettingsData extends BaseFormElementSettingsData {
  textarea_rows?: string;
}
export interface FormElementTextareaData extends BaseFormElementData {
  settings: FormElementTextareaSettingsData;
}

export interface FormElementTextareaState extends BaseFormElementState<string> {
  textareaRows: number;
}

export class FormElementTextareaModel extends BaseFormElementModel<
  string,
  FormElementTextareaData,
  FormElementTextareaState
> {
  parseFormElement(data: FormElementTextareaData) {
    this.state.textareaRows = Number(data.settings?.textarea_rows ?? 5);
  }

  getInitialValue(): string | undefined {
    return this.getInitialStringValue();
  }

  parseStringValue(value: string): string | undefined {
    return value;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value ?? '';
  }

  getSerializedPostValue(): string {
    return this.state.value ?? '';
  }

  getSerializedCookieValue(): string {
    return this.state.value ?? '';
  }
}
