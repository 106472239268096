import type { CSSProperties } from 'vue';
import type {
  AddonAccordionData,
  AddonAccordionLayout,
  AddonAccordionPaneData
} from '@/src/components/addons/accordion/Data';
import { AlignContentType } from '@/src/typings/enums/enums';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { transformMeasurementToNumber } from '@/src/utilities/Utilities';

export interface AddonAccordionPaneState {
  id: number;
  label: string;
  content: string;
  isOpen: boolean;
}

interface AddonAccordionLayoutState {
  headlineColor?: string;
  headlineFontSize?: number;
  contentColor?: string;
  contentFontSize?: number;
  dividerColor?: string;
  width?: number;
  headlineFontSizeIsPercentage?: boolean;
  contentFontSizeIsPercentage?: boolean;
  widthIsPercentage?: boolean;
  align?: AlignContentType;
}

interface AccordionSettingsState {
  panes?: AddonAccordionPaneState[];
  layout?: AddonAccordionLayoutState;
}

export interface AddonAccordionState extends AddonModelState {
  settings: AccordionSettingsState;
  expandedByDefault?: number;
  elementStyling?: {
    inline?: CSSProperties;
    headline?: CSSProperties;
    content?: CSSProperties;
    border?: CSSProperties;
  };
}

export class AddonAccordionModel extends AddonModel<AddonAccordionData, AddonAccordionState, 'accordion'> {
  parseAddon(data: AddonAccordionData) {
    const state = this.state;
    state.settings = state.settings || {};
    state.settings.layout = state.settings.layout || {};

    if (data?.settings?.layout) {
      state.settings.layout = AddonAccordionModel.constructLayoutState(data.settings.layout);
    }

    state.elementStyling = state.elementStyling || {};

    if (state.settings?.layout) {
      state.elementStyling.headline = AddonAccordionModel.constructHeadlineStyles(state.settings.layout);
      state.elementStyling.content = AddonAccordionModel.constructContentStyles(state.settings.layout);
      state.elementStyling.inline = AddonAccordionModel.constructInlineStyles(state.settings.layout);
      state.elementStyling.border = AddonAccordionModel.constructBorderStyles(state.settings.layout);
    }

    state.settings.panes = [];

    if (data.settings?.panes) {
      let expandedByDefault: number | undefined;

      Object.values(data.settings.panes).forEach((pane, index) => {
        if (state.settings?.panes !== undefined) {
          state.settings.panes.push(AddonAccordionModel.constructPaneState(pane, index));
          if (state.settings.panes[Number(index)].isOpen && typeof expandedByDefault === 'undefined') {
            expandedByDefault = state.settings.panes[Number(index)].id;
          }
        }
      });

      state.expandedByDefault = expandedByDefault;
    } else {
      state.expandedByDefault = undefined;
      state.settings.panes = undefined;
    }
  }

  public isAddonValid(): boolean {
    return !!this.getData().settings.panes;
  }

  public static constructBorderStyles(state: AddonAccordionLayoutState): CSSProperties | undefined {
    if (state.dividerColor) {
      return {
        borderBottom: `1px solid ${state.dividerColor}`
      };
    }
    return undefined;
  }

  //
  private static constructPaneState(data: AddonAccordionPaneData, index: number): AddonAccordionPaneState {
    return {
      id: index + 1,
      isOpen: data.is_open === '1',
      content: data.content,
      label: data.label
    };
  }

  private static constructLayoutState(data: AddonAccordionLayout): AddonAccordionLayoutState | undefined {
    let headlineFontSize;
    let contentFontSize;
    let width;

    if (data.headline_font && data.headline_font !== '') {
      headlineFontSize = transformMeasurementToNumber(data.headline_font);
    }

    if (data.content_font && data.content_font !== '') {
      contentFontSize = transformMeasurementToNumber(data.content_font);
    }

    if (data.width && data.width !== '') {
      width = transformMeasurementToNumber(data.width);
    }

    return {
      ...(data?.align && { align: data.align }),
      ...(data?.content_color && {
        contentColor: data.content_color
      }),
      ...(contentFontSize && { contentFontSize }),
      ...(data?.divider_color && {
        dividerColor: data.divider_color
      }),
      ...(data?.headline_color && {
        headlineColor: data.headline_color
      }),
      ...(headlineFontSize && { headlineFontSize }),
      ...(width && { width }),
      headlineFontSizeIsPercentage: !!data?.headline_font?.includes('%'),
      contentFontSizeIsPercentage: !!data?.content_font?.includes('%'),
      widthIsPercentage: !!data?.width?.includes('%')
    };
  }

  private static constructHeadlineStyles(state: AddonAccordionLayoutState): CSSProperties | undefined {
    let sizeMeasurement = 'px';
    if (state.headlineFontSizeIsPercentage) {
      sizeMeasurement = '%';
    }

    return {
      ...(state.headlineColor && { color: state.headlineColor }),
      ...(state.headlineFontSize && {
        fontSize: `${state.headlineFontSize}${sizeMeasurement}`
      })
    };
  }

  private static constructContentStyles(state: AddonAccordionLayoutState): CSSProperties | undefined {
    let sizeMeasurement = 'px';
    if (state.contentFontSizeIsPercentage) {
      sizeMeasurement = '%';
    }

    return {
      ...(state.contentColor && { color: state.contentColor }),
      ...(state.contentFontSize && {
        fontSize: `${state.contentFontSize}${sizeMeasurement}`
      })
    };
  }

  private static constructInlineStyles(state: AddonAccordionLayoutState): CSSProperties | undefined {
    let sizeMeasurement = 'px';
    if (state.widthIsPercentage) {
      sizeMeasurement = '%';
    }

    return {
      ...(state.align && {
        marginLeft: state.align === AlignContentType.LEFT ? '0px' : `auto`,
        marginRight: state.align === AlignContentType.RIGHT ? '0px' : `auto`
      }),
      ...(state.width && { width: `${state.width}${sizeMeasurement}` })
    };
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
