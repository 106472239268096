import type { DrawType, GameActionType } from '@/src/typings/enums/enums';
import type { GameIndicatorData } from '@/src/components/indicators/Model';
import type { InstantWinData } from '@/src/models/GameModel';

export interface GameSpinTheBottleVisualData {
  border_color: string;
  border_thickness: string;
  color1: string;
  color2: string;
  color3: string;
  divider: string;
  font_color: string;
  font_size: string;
  font_type: string;
  wheel_size: string;
}

export enum GameSpinTheBottleWheelDisplayType {
  COLOR = 'colors',
  IMAGE = 'image'
}

export interface SpinTheBottleInstantWin extends InstantWinData {
  winner_field?: string;
  winner_field_id?: string;
}

export enum GameSpinTheBottleSpinButtonType {
  USE_BUTTON = 'use_button',
  USE_CLICK = 'use_click',
  AUTO = 'auto'
}

export enum GameSpinTheBottleSpinButtonPositionType {
  BELOW = 'below',
  ABOVE = 'above'
}

export enum GameSpinTheBottleMarkerType {
  IMAGE = 'image',
  ICON = 'icon'
}

export enum GameSpinTheBottleCampaignLimit {
  CAMPAIGN_PERIOD = 'CAMPAIGN_PERIOD',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum GameSpinTheBottleMarkerSize {
  HUGE = 'huge',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

export interface GameSpinTheBottleFieldItemData {
  action: GameActionType;
  field_label?: string;
}

export interface GameSpinTheBottleBottleData {
  bottle: string;
}

export interface GameSpinTheBottleGeneralData {
  bottle: string;
  bottle_alt_text?: string;
  bottle_height: number;
  bottle_width: number;
  bottle_height_mobile?: number;
  bottle_width_mobile?: number;
  image: string;
  image_alt_text?: string;
  num_fields: string;
  spins: string;
  delay: string;
  auto_delay?: string;
  spin_button: GameSpinTheBottleSpinButtonType;
  spin_button_position: GameSpinTheBottleSpinButtonPositionType;
  overwrite_mobile_bottle: string;
  mobile?: GameSpinTheBottleBottleData;
  draw_type: DrawType;
  fields: {
    [key: string]: GameSpinTheBottleFieldItemData;
  };

  fields_occupied: number[];
  campaign_limit_interval?: GameSpinTheBottleCampaignLimit;
  campaign_limit?: string;
  visuals?: GameSpinTheBottleVisualData;
  wheel_display: GameSpinTheBottleWheelDisplayType;
}

export interface GameSpinTheBottleCustomLabelsData {
  spin_button: string;
  spins_left: string;
}

export interface GameSpinTheBottleAdvancedData {
  sound?: string;
}

export interface GameSpinTheBottleData {
  general: GameSpinTheBottleGeneralData;
  customlabels?: GameSpinTheBottleCustomLabelsData;
  advanced?: GameSpinTheBottleAdvancedData;
  indicators: GameIndicatorData;
}
