import type { GameIndicator, GameState, GameTimeChallenge, HasGameTimeChallenge } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GameWordRiddleData,
  GameWordRiddleLangDataType,
  GameWordRiddleLayoutData,
  GameWordRiddleWordsData
} from '@/src/components/games/wordriddle/Data';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { GameWordRiddleLangType } from '@/src/components/games/wordriddle/Interfaces';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import { useUtilityStore } from '@/src/store/utility';

export interface GameWordRiddleWordsState {
  id: string;
  text: string;
  visibilityCondition?: VisibilityConditionsModel;
}

export interface GameWordRiddleGeneralState {
  lang: GameWordRiddleLangType;
}

export interface GameWordRiddleKeyboardState {
  color: string;
  background: string;
}

export interface GameWordRiddleLayoutState {
  font: {
    color: string;
    type: string | undefined;
  };
  grid: {
    color: string;
    maxWidth: string;
    borderRadius?: string;
    keyboardGap: string;
  };

  keyboard: GameWordRiddleKeyboardState;
}

export interface GameWordRiddleMessagesState {
  winner: string;
  loser: string;
  wordNotFound: string;
  wordMissingLetters: string;
}

interface GameWordRiddleState extends GameState {
  general: GameWordRiddleGeneralState;
  layout: GameWordRiddleLayoutState | undefined;
  words: GameWordRiddleWordsState[];
  messages: GameWordRiddleMessagesState;
}

export class GameWordRiddleModel
  extends GameModel<GameWordRiddleData, GameWordRiddleState>
  implements HasGameTimeChallenge
{
  parseGame(data: GameWordRiddleData) {
    const state = this.state;
    state.general = state.general || {};
    state.general.lang = GameWordRiddleModel.mapStringToEnum(data.general.lang);
    state.layout = GameWordRiddleModel.parseLayoutData(data.general.layout);

    state.words = Object.values(data.words.words).map((wordData: GameWordRiddleWordsData) => ({
      visibilityCondition:
        wordData?.visibility_condition?.date_range?.enabled === '1'
          ? new VisibilityConditionsModel(wordData.visibility_condition)
          : undefined,
      text: wordData.word.toLowerCase(),
      id: wordData.id
    }));

    state.messages = {
      winner: data.customlabels?.winner ?? 'winner!',
      loser: data.customlabels?.loser ?? 'You lost!',
      wordNotFound: data.customlabels?.word_not_found ?? 'word not found',
      wordMissingLetters: data.customlabels?.word_missing_letters ?? 'word missing letters'
    };

    if (state.layout?.font?.type) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(state.layout.font.type);
    }

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }
  }

  private static mapStringToEnum(lang: GameWordRiddleLangDataType | undefined): GameWordRiddleLangType {
    switch (lang) {
      case 'en':
        return GameWordRiddleLangType.ENGLISH;
      case 'da':
        return GameWordRiddleLangType.DANISH;
      case 'nl':
        return GameWordRiddleLangType.DUTCH;
      case 'de':
        return GameWordRiddleLangType.GERMAN;
      case 'sv':
        return GameWordRiddleLangType.SWEDISH;
      case 'fi':
        return GameWordRiddleLangType.FINNISH;
      default:
        return GameWordRiddleLangType.ENGLISH;
    }
  }

  private static parseLayoutData(data: DeviceData<GameWordRiddleLayoutData>): GameWordRiddleLayoutState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return GameWordRiddleModel.constructLayoutState(useData);
    }
  }

  private static constructLayoutState(data: GameWordRiddleLayoutData): GameWordRiddleLayoutState {
    const maxWidth = data.grid.max_width
      ? data.grid.max_width.endsWith('%') || data.grid.max_width.endsWith('px')
        ? data.grid.max_width
        : `${parseInt(data.grid.max_width, 10)}px`
      : '100%';

    const keyboardGap = data.grid.keyboard_gap
      ? data.grid.keyboard_gap.endsWith('px') || data.grid.keyboard_gap.endsWith('vh')
        ? data.grid.keyboard_gap
        : `${parseInt(data.grid.keyboard_gap, 10)}px`
      : '30px';

    return {
      keyboard: { background: data.keyboard.background, color: data.keyboard.color },
      font: {
        color: data.font.color ?? '#ffffff',
        type: data.font.type
      },
      grid: {
        color: data.grid.color ?? '#d3d3d3',
        maxWidth,
        borderRadius: data.grid.border_radius ?? '3px',
        keyboardGap
      }
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.RELATIVE,
      bottom: GameIndicatorPositionType.RELATIVE
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_used: 'timeused',
          time_left: 'timeleft'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_used: 0,
          time_left: this.state.timeChallenge?.limit || 60
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: Number(data.general.time) === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }
}
