import type {
  AddonIframeElementData,
  AddonIframeElementSettingData
} from '@/src/components/addons/iframe-element/Data';
import { AlignContentType, BooleanDataType, IframeWidthType } from '@/src/typings/enums/enums';
import useDevice from '@/src/hooks/useDevice';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

interface AddonIframeElementSettingsState {
  alignment: AlignContentType;
  fixedHeight: number;
  fixedWidth?: number;
  responsiveHeight: boolean;
  showScrollbars: boolean;
  width: IframeWidthType;
  url: string;
}

export interface AddonIframeElementState extends AddonModelState {
  settings?: AddonIframeElementSettingsState;
}

export class AddonIframeElementModel extends AddonModel<
  AddonIframeElementData,
  AddonIframeElementState,
  'iframe-element'
> {
  parseAddon(data: AddonIframeElementData) {
    const state = this.state;

    state.settings = state.settings ?? undefined;

    if (data.settings) {
      state.settings = AddonIframeElementModel.getParsedSettingsState(data.settings);
    } else {
      state.settings = undefined;
    }
  }

  private static getParsedSettingsState = (data: AddonIframeElementSettingData): AddonIframeElementSettingsState => {
    const { isMobile } = useDevice();
    const url = data.url ? data.url : '';

    if (isMobile && data.overwrite_mobile === BooleanDataType.FALSE && data.mobile) {
      return AddonIframeElementModel.constructSettingState(data.mobile, url);
    }

    return AddonIframeElementModel.constructSettingState(data, url);
  };

  private static constructSettingState(
    data: AddonIframeElementSettingData,
    url: string
  ): AddonIframeElementSettingsState {
    return {
      url,
      fixedHeight: data.fixed_height ? Number(data.fixed_height) : 300,
      ...(data.fixed_width && { fixedWidth: Number(data.fixed_width) }),
      showScrollbars: data.show_scrollbars === '1',
      responsiveHeight: data.responsive_height === '1',
      width: data.width ? data.width : IframeWidthType.CONTENT,
      alignment: data.alignment ? data.alignment : AlignContentType.CENTER
    };
  }

  // if path is width then value on fixed width should be undefined
  public setValue(path: string, value: string | number | boolean, shouldParse?: boolean) {
    const data = this.getData();
    // fixed_width should be undefined
    if (path === 'width' && value !== 'fixed') {
      delete data.settings.fixed_width;
      delete data.settings.alignment;
    }

    super.setValue(path, value, shouldParse);
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    return !(!data.settings?.url || data.settings?.url === '');
  }
}
