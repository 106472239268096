import { SdkAddonButton } from './addons/button';
import { SdkAddonImage } from './addons/image';
import { SdkColumnModel } from './column';
import { SdkFlowPageModel } from './flowPage';
import { SdkSectionModel } from './section';
import { SdkPopoverModel } from './popover';
import { AddonLfButtonModel } from '@/src/components/addons/lf-button/Model';
import { AddonLfImageModel } from '@/src/components/addons/lf-image/Model';
import ColumnModel from '@/src/components/layout/column/ColumnModel';
import { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import type ActionsModel from '@/src/models/actions/ActionsModel';
import type { ActionType } from '@/src/typings/enums/enums';
import { SectionType } from '@/src/typings/enums/enums';
import type { ModelTypes } from '@/src/typings/types/types';

export class SdkActionModel {
  #action: ActionsModel;
  #originModel: ModelTypes;

  constructor(action: ActionsModel, origin: ModelTypes) {
    this.#action = action;
    this.#originModel = origin;
  }

  public get type(): ActionType {
    return this.#action.state.type;
  }

  public get flowPage(): number | null {
    return this.#action.state.flowPage ?? null;
  }

  public get origin() {
    if (this.#originModel instanceof SectionBaseModel) {
      return new SdkFlowPageModel(this.#originModel);
    } else if (this.#originModel instanceof AddonLfButtonModel) {
      return new SdkAddonButton(this.#originModel);
    } else if (this.#originModel instanceof AddonLfImageModel) {
      return new SdkAddonImage(this.#originModel);
    } else if (this.#originModel instanceof ColumnModel) {
      return new SdkColumnModel(this.#originModel);
    }
  }

  public get page(): SdkSectionModel | SdkFlowPageModel | SdkPopoverModel | undefined {
    if (this.#originModel instanceof SectionBaseModel) {
      return this.getSectionModel(this.#originModel);
    } else if (this.#originModel instanceof ColumnModel) {
      return this.getSectionModel(this.#originModel.row.section);
    } else if (
      (this.#originModel instanceof AddonLfButtonModel || this.#originModel instanceof AddonLfImageModel) &&
      this.#originModel.column?.row.section
    ) {
      return this.getSectionModel(this.#originModel.column?.row.section);
    }
  }

  private getSectionModel(model: SectionBaseModel): SdkSectionModel | SdkFlowPageModel | SdkPopoverModel | undefined {
    if (model.getSectionType() === SectionType.SECTION) {
      return new SdkSectionModel(model);
    } else if (model.getSectionType() === SectionType.FLOWPAGE) {
      return new SdkFlowPageModel(model);
    } else if (model.getSectionType() === SectionType.POPOVER) {
      return new SdkPopoverModel(model);
    }
  }
}
