import { SdkFlowPageModel } from '../models/flowPage';
import type { FlowpageModel } from '@/src/components/layout/FlowpageModel';

export class SdkFormSubmitResponseEvent {
  #section: SdkFlowPageModel;
  #promises: Promise<void>[] = [];
  #success: boolean;
  #registrationId: number | undefined;

  constructor(page: FlowpageModel, success: boolean, registrationId: number | undefined = undefined) {
    this.#section = new SdkFlowPageModel(page);
    this.#success = success;
    this.#registrationId = registrationId;
  }

  public suspense(fn: () => Promise<void>) {
    this.#promises.push(fn());
  }

  public async waitForPromises() {
    await Promise.all(this.#promises);
  }

  public get section() {
    return this.#section;
  }

  public get formFields() {
    return this.#section.formFields;
  }

  public get success(): boolean {
    return this.#success;
  }

  public get registrationId(): number | undefined {
    return this.#registrationId;
  }
}
