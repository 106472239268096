import type { GameIndicator, GameTimeChallenge, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type { GameSlidingPuzzleAdvancedData, GameSlidingPuzzleData } from '@/src/components/games/sliding-puzzle/Data';

interface GameSlidingPuzzleDefaultState {
  enableBestTime: boolean;
  enableTimeRanking: boolean;
  image: string;
  tiles: number;
  timeLeft: number;
}

export interface GameSlidingPuzzleAdvancedSoundState {
  enabled: boolean;
  src?: string;
}
export interface GameSlidingPuzzleAdvancedState {
  sound: GameSlidingPuzzleAdvancedSoundState;
}

export interface GameSlidingPuzzleState {
  default: GameSlidingPuzzleDefaultState;
  advanced: GameSlidingPuzzleAdvancedState;
}

export class GameSlidingPuzzleModel
  extends GameModel<GameSlidingPuzzleData, GameSlidingPuzzleState>
  implements HasSound
{
  parseGame(data: GameSlidingPuzzleData) {
    const state = this.state;
    state.default = state.default ?? {};
    state.default.enableBestTime = data.default.enable_best_time === '1';
    state.default.enableTimeRanking = data.default.enable_time_ranking === '1';
    state.default.image = data.default.image;
    state.default.tiles = data.default.tiles && data.default.tiles !== '' ? Number(data.default.tiles) : 3;

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    }
    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameSlidingPuzzleModel.constructAdvancedSoundState(data?.advanced);
  }

  private static constructAdvancedSoundState(
    data: GameSlidingPuzzleAdvancedData | undefined
  ): GameSlidingPuzzleAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 30,
          time_used: 0
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    const timeChallenge: GameTimeChallenge = {
      enabled: false
    };

    if ((typeof data.default.time !== 'number' && data.default.time.enabled === '1') || data.default.time === 1) {
      timeChallenge.enabled = true;
      timeChallenge.limit = Number(data.default.time_limit);
    }
    return timeChallenge;
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
