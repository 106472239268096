<template>
  <transition-group :name="`image-${transition}`">
    <div
      v-for="background in activeSlides"
      :key="background.imageUrl"
      class="section__background-item"
      :style="{
        ...background.style
      }"
    ></div>
  </transition-group>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { ref, defineComponent, onUnmounted, computed } from 'vue';
import type { SliderTypes } from '@/src/typings/enums/enums';
import type { BackgroundSlideState } from '@/src/components/layout/SettingsModel';
import { preloadImagePromise } from '@/src/utilities/Utilities';

export default defineComponent({
  name: 'BackgroundImagesSlider',
  inheritAttrs: false,

  props: {
    items: {
      type: Array as PropType<BackgroundSlideState[]>,
      required: true
    },
    transition: {
      type: String as PropType<SliderTypes>
    },
    transitionDuration: {
      type: Number
    },
    transitionInterval: {
      type: Number
    }
  },
  setup(props) {
    const currentIndex = ref(0);
    let timer: number | undefined;

    const cssTransition = computed<string>(() => {
      return `all ${props.transitionDuration}ms ease`;
    });

    const nextSlide = () => {
      currentIndex.value = currentIndex.value === props.items.length - 1 ? 0 : currentIndex.value + 1;

      // Preload the next image in line
      const nextIndex = currentIndex.value === props.items.length - 1 ? 0 : currentIndex.value + 1;
      const nextImageUrl = props.items[Number(nextIndex)]?.imageUrl;

      if (nextImageUrl) {
        preloadImagePromise(nextImageUrl);
      }

      // Start the timer again
      nextSliderTimer();
    };

    const nextSliderTimer = () => {
      if (timer !== undefined) {
        window.clearTimeout(timer);
      }

      timer = window.setTimeout(nextSlide, props.transitionInterval || 3000);
    };

    const activeSlides = computed<BackgroundSlideState[]>(() => {
      return props.items.filter((_slide, slideIndex) => {
        return slideIndex === currentIndex.value;
      });
    });

    onUnmounted(() => {
      if (timer !== undefined) {
        window.clearTimeout(timer);
        timer = undefined;
      }
    });

    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    // Preload the first image, if no images exists, resolve and let the component render
    if (props.items[0] && props.items[0].imageUrl) {
      preloadImagePromise(props.items[0].imageUrl)
        .then(() => {
          if (readyPromiseResolve) {
            readyPromiseResolve();
          }

          // Wait to start the auto-timer until the first image is fully loaded and otherwise ready
          nextSliderTimer();
        })
        .catch(() => {
          // Ignore exceptions, such as network errors to avoid rendering of the component to be blocked
          if (readyPromiseResolve) {
            readyPromiseResolve();
          }
        });
    } else if (readyPromiseResolve) {
      readyPromiseResolve();
    }

    return {
      cssTransition,
      activeSlides,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>

<style lang="scss">
/* FADE */
.image-fade-enter-from,
.image-fade-leave-to {
  opacity: 0 !important;
}

.image-fade-leave-from,
.image-fade-enter-to {
  opacity: 1 !important;
}

.image-fade-enter-active,
.image-fade-leave-active {
  transition: v-bind('cssTransition');
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.image-fade-enter,
.image-fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

/* SLIDE */

/* Enter and leave animations can use different */
/* durations and timing functions.              */

.image-slide-from,
.image-slide-to {
  transform: translateX(0);
}

.image-slide-leave-to {
  transform: translateX(-100%);
}
.image-slide-enter-from {
  transform: translateX(100%);
}

.image-slide-enter-active,
.image-slide-leave-active {
  transition: v-bind('cssTransition');
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}
</style>
