import { SdkPersonalityTestQuestionModel } from './sdk/question-model';
import { SdkPersonalityTestPersonalityModel } from './sdk/personality-model';
import type { GamePersonalityTestSdkSettings } from './sdk/exports';
import type { GameIndicator, GameState, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GamePersonalityTestAdvancedData,
  GamePersonalityTestData,
  GamePersonalityTestGeneralData,
  GamePersonalityTestPersonalityData,
  GamePersonalityTestQuestionData,
  GamePersonalityTestSwipeButtonData,
  PersonalityTestSwipeItButtonsType
} from '@/src/components/games/personality-test/Data';
import { AnswerLayoutSettingsModel } from '@/src/models/settings/AnswerLayoutSettingsModel';
import { AlignContentType, BehaviorTypes, PositionTypes } from '@/src/typings/enums/enums';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import type { TransitionTypes } from '@/src/typings/types/types';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { GameEndingConditionType } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import type { BackgroundBehaviorType, BackgroundPositionType } from '@/src/components/games/quiz/Data';
import type { GameSectionImageOverlay, MetricData } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';

export interface GamePersonalityTestSectionImageOverlay extends GameSectionImageOverlay {
  onFeedback: boolean;
}

interface FeedbackState {
  enabled: boolean;
  redirectToNextQuestion?: boolean;
  delayUntilNext?: number;
  buttonNextLabel?: string;
}

interface VisibilityConditionData {
  condition: GameEndingConditionType;
  date_range: {
    enabled: string;
    date_to: string;
    date_from: string;
  };
}

export interface CustomLabelState {
  buttons: {
    answer?: string;
    lastAnswer?: string;
    selectAnswer?: string;
  };
  linkLabel?: string;
}

export enum ResultCalculationType {
  INTERVAL = 'interval',
  POINTS = 'points'
}

export interface GamePersonalityTestSwipeItButtonsState {
  color?: string;
  enabled: boolean;
  leftText?: string;
  leftImage?: string;
  rightImage?: string;
  rightText?: string;
  onlyFacebook?: boolean;
  type?: PersonalityTestSwipeItButtonsType;
}

export interface GamePersonalityTestGeneralState {
  answerLayout?: AnswerLayoutSettingsModel;
  contentAlign: AlignContentType;
  answerAlignment: AlignContentType;
  resultCalculation: ResultCalculationType;
  enableSkipLogic: boolean;
  multiplePersonalitiesLoser: boolean;
  personalityPercentage: boolean;
  answerFeedback: FeedbackState;
  feedback: FeedbackState;
  buttons: GamePersonalityTestSwipeItButtonsState;
  showAnswerButton: boolean;
  customLabels: CustomLabelState;
  time: {
    enabled: boolean;
    limit?: number;
    perQuestion: boolean;
  };
  features: {
    bestTime: boolean;
    timeRanking: boolean;
  };
  activeStateColor?: string;
  activeStateBackgroundColor?: string;

  focusStateBackgroundColor?: string;
  focusStateTextColor?: string;
}

export enum PersonalityTestAnswerType {
  TEXT,
  IMAGE,
  SLIDER,
  SWIPE
}

export enum PersonalityTestVideoPosition {
  ABOVE,
  LEFT,
  RIGHT
}

export enum PersonalityFlow {
  WINNER,
  LOSER
}

export interface GamePersonalityTestAnswerActionState {
  action?: string | number;
  actionPage?: number;
}

export interface GamePersonalityTestAnswerState extends GamePersonalityTestAnswerActionState {
  id: number;
  text: string;
  description?: string;
  middleStep?: string;
  percentage?: number;
  alternativeText?: string;
  personalityScore?: Record<number, number>;
  personalityScoreInterval?: number;
}

export interface GamePersonalityTestQuestionFeedbackState {
  enabled: boolean;
  redirectToNextQuestion?: boolean;
  delayUntilNext?: number;
  buttonNextLabel?: string;
  previousQuestionLabel?: string;
  feedbackButtonLabel?: string;
  buttonLastLabel?: string;
  message?: string;
}

export interface GamePersonalityTestQuestionState {
  id: number;
  questionNumber: number;
  question: string;
  video: {
    enabled: boolean;
    url?: string;
    position?: PersonalityTestVideoPosition;
    width?: number;
  };
  enableDescription: boolean;
  backgroundImage: GamePersonalityTestSectionImageOverlay;
  questionImage?: string;
  answerFeedback: GamePersonalityTestQuestionFeedbackState;
  feedback: GamePersonalityTestQuestionFeedbackState;
  answerType: PersonalityTestAnswerType;
  enablePeopleAnswers: boolean;
  answerLayout: AnswerLayoutSettingsModel;
  enableMultipleAnswers: boolean;
  minAnswers?: number;
  maxAnswers?: number;
  minAnswersValidationMessage?: string;
  maxAnswersValidationMessage?: string;
  swipeImage?: {
    src: string;
    alt?: string;
  };
  sliderRotation: {
    enabled: boolean;
    onlyMobile?: boolean;
  };
  sliderAnswerButton?: {
    enabled: boolean;
    label?: string;
  };
  answers: GamePersonalityTestAnswerState[];
  questionString: string;
  date: {
    enabled: boolean;
    from?: string;
    to?: string;
  };
  visibilityCondition?: VisibilityConditionsModel;
  defaultAnswer?: number;
}

export interface GamePersonalityTestPersonalityState {
  id: number;
  name: string;
  image: string;
  description: string;
  url: string;
  videoUrl: string;
  flow: PersonalityFlow;
  interval: {
    enabled: boolean;
    from?: number;
    to?: number;
  };
  percentageBars: {
    enabled: boolean;
    bgColor?: string;
    textColor?: string;
  };
}

interface GamePersonalityTestAdvancedSoundState {
  enabled: boolean;
  src?: string;
}
interface GamePersonalityTestAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

interface GamePersonalityTestAdvancedState {
  sound: GamePersonalityTestAdvancedSoundState;
  animation: GamePersonalityTestAdvancedAnimationState;
}

export interface GamePersonalityTestTranslationState {
  swipe?: {
    left?: string;
    right?: string;
  };
}

export interface GamePersonalityTestState extends GameState {
  general: GamePersonalityTestGeneralState;
  questions: GamePersonalityTestQuestionState[];
  personalities: GamePersonalityTestPersonalityState[];
  translation?: GamePersonalityTestTranslationState;
  advanced: GamePersonalityTestAdvancedState;
}

export class GamePersonalityTestModel
  extends GameModel<GamePersonalityTestData, GamePersonalityTestState>
  implements HasSound
{
  parseGame(data: GamePersonalityTestData): void {
    const state = this.state;
    state.general = this.constructGeneralState(data.general);

    if (data.personalities) {
      state.personalities = data.personalities.personalities.map<GamePersonalityTestPersonalityState>((item) => {
        return this.constructPersonalitiesState(item);
      });
    }

    const questions: GamePersonalityTestQuestionState[] = [];

    let i = 1;
    if (Array.isArray(data.questions.question)) {
      data.questions.question.forEach((element) => {
        questions.push(this.constructQuestionsState(element, data.general, i));
        i++;
      });
    } else {
      for (const property in data.questions.question) {
        if (Object.hasOwnProperty.call(data.questions.question, property)) {
          questions.push(this.constructQuestionsState(data.questions.question[Number(property)], data.general, i));
          i++;
        }
      }
    }

    state.questions = questions;

    if (data.translation) {
      state.translation = {
        swipe: {
          right: data.translation.right_swipe,
          left: data.translation.left_swipe
        }
      };
    } else {
      state.translation = undefined;
    }

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GamePersonalityTestModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GamePersonalityTestModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructAdvancedAnimationState(
    data: GamePersonalityTestAdvancedData | undefined
  ): GamePersonalityTestAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private static constructAdvancedSoundState(
    data: GamePersonalityTestAdvancedData | undefined
  ): GamePersonalityTestAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private constructQuestionsState(
    data: GamePersonalityTestQuestionData,
    generalData: GamePersonalityTestGeneralData,
    index: number
  ): GamePersonalityTestQuestionState {
    let questionVideoPosition: PersonalityTestVideoPosition = PersonalityTestVideoPosition.ABOVE;

    if (data.question_video?.position) {
      switch (data.question_video.position) {
        case 'left':
          questionVideoPosition = PersonalityTestVideoPosition.LEFT;
          break;

        case 'right':
          questionVideoPosition = PersonalityTestVideoPosition.RIGHT;
          break;

        default:
          questionVideoPosition = PersonalityTestVideoPosition.ABOVE;
          break;
      }
    }

    let behaviourType: BehaviorTypes;
    let positionType: PositionTypes = PositionTypes.CENTER_CENTER;

    switch (data.background_behavior) {
      case 'center':
        behaviourType = BehaviorTypes.CENTER;
        break;

      case 'contain':
        behaviourType = BehaviorTypes.CONTAIN;
        break;
      case 'stretch':
        behaviourType = BehaviorTypes.STRETCH;
        break;
      case 'tile':
        behaviourType = BehaviorTypes.TILE;
        break;
      case 'actual_size':
        behaviourType = BehaviorTypes.ACTUAL_SIZE;
        break;
      default:
        behaviourType = BehaviorTypes.COVER;
        break;
    }

    switch (data.background_position) {
      case 'custom':
        positionType = PositionTypes.CUSTOM;
        break;
      case 'bottom_center':
        positionType = PositionTypes.BOTTOM_CENTER;
        break;
      case 'bottom_left':
        positionType = PositionTypes.BOTTOM_LEFT;
        break;
      case 'bottom_right':
        positionType = PositionTypes.BOTTOM_RIGHT;
        break;
      case 'center_center':
        positionType = PositionTypes.CENTER_CENTER;
        break;
      case 'center_left':
        positionType = PositionTypes.CENTER_LEFT;
        break;
      case 'center_right':
        positionType = PositionTypes.CENTER_RIGHT;
        break;
      case 'top_center':
        positionType = PositionTypes.TOP_CENTER;
        break;
      case 'top_left':
        positionType = PositionTypes.TOP_LEFT;
        break;
      case 'top_right':
        positionType = PositionTypes.TOP_RIGHT;
        break;
    }

    const existingQuestionState = this.state?.questions?.find(
      (existingQuestion) => existingQuestion.id === Number(data.id)
    );

    let visibilityCondition: VisibilityConditionsModel | undefined;

    if (data.date_range?.enabled === '1') {
      visibilityCondition = existingQuestionState ? existingQuestionState.visibilityCondition : undefined;

      const visibilityConditionData: VisibilityConditionData = {
        condition: GameEndingConditionType.NONE,
        date_range: {
          enabled: data.date_range.enabled,
          date_to: data.date_range.to ? data.date_range.to : '',
          date_from: data.date_range.from ? data.date_range.from : ''
        }
      };

      if (visibilityCondition) {
        visibilityCondition.setData(visibilityConditionData);
      } else {
        visibilityCondition = new VisibilityConditionsModel(visibilityConditionData);
      }
    }

    let answerLayout: AnswerLayoutSettingsModel | undefined;
    answerLayout = existingQuestionState ? existingQuestionState.answerLayout : undefined;
    if (answerLayout) {
      answerLayout.setData(generalData);
    } else {
      answerLayout = new AnswerLayoutSettingsModel(generalData);
    }
    /**
     * only assign mobile values if overwrite mobile is false else ignore mobile values and assign default object.
     * This functionality is much like the check we have in getDeviceData, however
     * we have pulled this functionality in locally in this model as the data in this
     * is formatted differently, so we couldn't make a 100% use of getDeviceData
     */

    let backgroundImage: GamePersonalityTestSectionImageOverlay = {
      enabled: !!data.background_image,
      src: data.background_image,
      behavior: behaviourType,
      position: positionType,
      overlay: data.background_overlay,
      onFeedback: data.background_feedback_enabled === '1'
    };

    const { isMobile } = useDevice();
    const overwriteMobile = typeof data.overwrite_mobile === 'undefined' || data.overwrite_mobile === '1';

    if (isMobile && !overwriteMobile) {
      backgroundImage = {
        enabled: !!data.mobile.background_image,
        src: data.mobile.background_image,
        behavior: this.getBackgroundBehavior(data.mobile.background_behavior),
        position: this.getBackgroundPosition(data.mobile.background_position),
        overlay: data.mobile.background_overlay,
        onFeedback: data.mobile.background_feedback_enabled === '1'
      };
    }

    const questionState: GamePersonalityTestQuestionState = {
      id: Number(data.id),
      questionNumber: index,
      question: data.question,
      video: {
        enabled: data.question_video?.enable === '1' || false,
        ...(data.question_video?.enable &&
          data.question_video?.enable === '1' && {
            url: data.question_video.url,
            width: data.question_video.width ? Number(data.question_video.width) : undefined,
            position: questionVideoPosition
          })
      },

      enableMultipleAnswers: data.multiple_answers === '1',
      ...(data.multiple_answers && {
        ...(data.max_answers && { maxAnswers: Number(data.max_answers) }),
        ...(data.min_answers && { minAnswers: Number(data.min_answers) }),
        ...(data.min_answers_validation_message && {
          minAnswersValidationMessage: data.min_answers_validation_message
        }),
        ...(data.max_answers_validation_message && { maxAnswersValidationMessage: data.max_answers_validation_message })
      }),
      questionImage: data.question_image,
      enablePeopleAnswers: !!generalData.other_peoples_answer,
      answerLayout,
      backgroundImage,
      visibilityCondition,
      answerFeedback: {
        enabled: generalData.show_answer === '1',
        ...(generalData.show_answer &&
          generalData.show_answer === '1' && {
            redirectToNextQuestion: generalData.show_answer_redirect === '1',
            ...(generalData.show_answer_delay && {
              delayUntilNext: Number(generalData.show_answer_delay)
            }),
            buttonNextLabel: generalData.next_question ?? 'Næste spørgsmål',
            buttonLastLabel: generalData.answer_btn_last
          })
      },
      feedback: {
        enabled: generalData.show_feedback && generalData.feedback_message !== '',
        redirectToNextQuestion: generalData.show_answer_redirect === '1',
        ...(generalData.show_answer_delay && {
          delayUntilNext: Number(generalData.show_answer_delay)
        }),
        buttonNextLabel: generalData.next_question ?? 'Næste spørgsmål',
        buttonLastLabel: generalData.answer_btn_last,

        message: GamePersonalityTestModel.feedbackMessagePrioritize(
          data.feedback_message ?? '',
          generalData.feedback_message
        )
      },
      enableDescription: data.additional_description === '1',
      answers: this.constructAnswersArray(data),
      date: {
        enabled: data.date_range?.enabled === '1',
        ...(data.date_range?.enabled &&
          data.date_range?.enabled === '1' && {
            from: data.date_range.from,
            to: data.date_range.to
          })
      },
      questionString: data.question_string,
      sliderRotation: {
        enabled: data.slider_rotation === '1',
        ...(data.slider_rotation_mobile && { onlyMobile: data.slider_rotation_mobile === '1' })
      },
      sliderAnswerButton: {
        enabled: data.show_answer_button === '1' && generalData.show_answer_button !== '1',
        ...(data.answer_btn && { label: data.answer_btn })
      },
      defaultAnswer: data.default_answer ? Number(data.default_answer) : undefined,
      answerType: PersonalityTestAnswerType.TEXT
    };

    switch (data.answer_type) {
      case 'image':
        questionState.answerType = PersonalityTestAnswerType.IMAGE;
        break;

      case 'slider':
        questionState.answerType = PersonalityTestAnswerType.SLIDER;

        break;

      case 'swipe':
        questionState.answerType = PersonalityTestAnswerType.SWIPE;

        if (data.swipe_image) {
          questionState.swipeImage = {
            src: data.swipe_image,
            alt: data.swipe_image_alt_text
          };
        }
        break;

      case 'text':
        questionState.answerType = PersonalityTestAnswerType.TEXT;
        break;
    }

    return questionState;
  }

  private constructAnswersArray(data: GamePersonalityTestQuestionData): Array<GamePersonalityTestAnswerState> {
    const answers: GamePersonalityTestAnswerState[] = [];
    if (data.answer_1 || data.answer_1_readonly) {
      answers.push({
        id: 1,
        text: data.answer_1,
        ...(data?.answer_1_action && { action: data.answer_1_action }),
        ...(data?.answer_1_action_page && { actionPage: Number(data.answer_1_action_page) }),
        ...(Number.isInteger(data?.answer_1_percentage) && { percentage: data.answer_1_percentage }),
        ...(data?.answer_1_additional && { description: data.answer_1_additional }),
        ...(data?.answer_1_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_1_middlestep)
        }),
        ...(data.answer_1_personality &&
          data.answer_1_personality_score &&
          Array.isArray(data.answer_1_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_1_personality,
              data.answer_1_personality_score
            )
          }),
        ...(data.answer_1_personality_score &&
          typeof data.answer_1_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_1_personality_score)
          }),
        ...(data?.answer_1_alt_text && { alternativeText: data.answer_1_alt_text })
      });
    }

    if (data.answer_2 || data.answer_2_readonly) {
      answers.push({
        id: 2,
        text: data.answer_2,
        ...(data?.answer_2_action && { action: data.answer_2_action }),
        ...(data?.answer_2_action_page && { actionPage: Number(data.answer_2_action_page) }),
        ...(Number.isInteger(data?.answer_2_percentage) && { percentage: data.answer_2_percentage }),
        ...(data?.answer_2_additional && { description: data.answer_2_additional }),
        ...(data?.answer_2_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_2_middlestep)
        }),
        ...(data.answer_2_personality &&
          data.answer_2_personality_score &&
          Array.isArray(data.answer_2_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_2_personality,
              data.answer_2_personality_score
            )
          }),
        ...(data.answer_2_personality_score &&
          typeof data.answer_2_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_2_personality_score)
          }),
        ...(data?.answer_2_alt_text && { alternativeText: data.answer_2_alt_text })
      });
    }

    if (data.answer_3) {
      answers.push({
        id: 3,
        text: data.answer_3,
        ...(data?.answer_3_action && { action: data.answer_3_action }),
        ...(data?.answer_3_action_page && { actionPage: Number(data.answer_3_action_page) }),
        ...(Number.isInteger(data?.answer_3_percentage) && { percentage: data.answer_3_percentage }),
        ...(data?.answer_3_additional && { description: data.answer_3_additional }),
        ...(data?.answer_3_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_3_middlestep)
        }),
        ...(data.answer_3_personality &&
          data.answer_3_personality_score &&
          Array.isArray(data.answer_3_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_3_personality,
              data.answer_3_personality_score
            )
          }),
        ...(data.answer_3_personality_score &&
          typeof data.answer_3_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_3_personality_score)
          }),
        ...(data?.answer_3_alt_text && { alternativeText: data.answer_3_alt_text })
      });
    }

    if (data.answer_4) {
      answers.push({
        id: 4,
        text: data.answer_4,
        ...(data?.answer_4_action && { action: data.answer_4_action }),
        ...(data?.answer_4_action_page && { actionPage: Number(data.answer_4_action_page) }),
        ...(Number.isInteger(data?.answer_4_percentage) && { percentage: data.answer_4_percentage }),
        ...(data?.answer_4_additional && { description: data.answer_4_additional }),
        ...(data?.answer_4_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_4_middlestep)
        }),
        ...(data.answer_4_personality &&
          data.answer_4_personality_score &&
          Array.isArray(data.answer_4_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_4_personality,
              data.answer_4_personality_score
            )
          }),
        ...(data.answer_4_personality_score &&
          typeof data.answer_4_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_4_personality_score)
          }),
        ...(data?.answer_4_alt_text && { alternativeText: data.answer_4_alt_text })
      });
    }

    if (data.answer_5) {
      answers.push({
        id: 5,
        text: data.answer_5,
        ...(data?.answer_5_action && { action: data.answer_5_action }),
        ...(data?.answer_5_action_page && { actionPage: Number(data.answer_5_action_page) }),
        ...(Number.isInteger(data?.answer_5_percentage) && { percentage: data.answer_5_percentage }),
        ...(data?.answer_5_additional && { description: data.answer_5_additional }),
        ...(data?.answer_5_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_5_middlestep)
        }),
        ...(data.answer_5_personality &&
          data.answer_5_personality_score &&
          Array.isArray(data.answer_5_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_5_personality,
              data.answer_5_personality_score
            )
          }),
        ...(data.answer_5_personality_score &&
          typeof data.answer_5_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_5_personality_score)
          }),
        ...(data?.answer_5_alt_text && { alternativeText: data.answer_5_alt_text })
      });
    }

    if (data.answer_6) {
      answers.push({
        id: 6,
        text: data.answer_6,
        ...(data?.answer_6_action && { action: data.answer_6_action }),
        ...(data?.answer_6_action_page && { actionPage: Number(data.answer_6_action_page) }),
        ...(Number.isInteger(data?.answer_6_percentage) && { percentage: data.answer_6_percentage }),
        ...(data?.answer_6_additional && { description: data.answer_6_additional }),
        ...(data?.answer_6_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_6_middlestep)
        }),
        ...(data.answer_6_personality &&
          data.answer_6_personality_score &&
          Array.isArray(data.answer_6_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_6_personality,
              data.answer_6_personality_score
            )
          }),
        ...(data.answer_6_personality_score &&
          typeof data.answer_6_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_6_personality_score)
          }),
        ...(data?.answer_6_alt_text && { alternativeText: data.answer_6_alt_text })
      });
    }

    if (data.answer_7) {
      answers.push({
        id: 7,
        text: data.answer_7,
        ...(data?.answer_7_action && { action: data.answer_7_action }),
        ...(data?.answer_7_action_page && { actionPage: Number(data.answer_7_action_page) }),
        ...(Number.isInteger(data?.answer_7_percentage) && { percentage: data.answer_7_percentage }),
        ...(data?.answer_7_additional && { description: data.answer_7_additional }),
        ...(data?.answer_7_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_7_middlestep)
        }),
        ...(data.answer_7_personality &&
          data.answer_7_personality_score &&
          Array.isArray(data.answer_7_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_7_personality,
              data.answer_7_personality_score
            )
          }),
        ...(data.answer_7_personality_score &&
          typeof data.answer_7_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_7_personality_score)
          }),
        ...(data?.answer_7_alt_text && { alternativeText: data.answer_7_alt_text })
      });
    }

    if (data.answer_8) {
      answers.push({
        id: 8,
        text: data.answer_8,
        ...(data?.answer_8_action && { action: data.answer_8_action }),
        ...(data?.answer_8_action_page && { actionPage: Number(data.answer_8_action_page) }),
        ...(Number.isInteger(data?.answer_8_percentage) && { percentage: data.answer_8_percentage }),
        ...(data?.answer_8_additional && { description: data.answer_8_additional }),
        ...(data?.answer_8_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_8_middlestep)
        }),
        ...(data.answer_8_personality &&
          data.answer_8_personality_score &&
          Array.isArray(data.answer_8_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_8_personality,
              data.answer_8_personality_score
            )
          }),
        ...(data.answer_8_personality_score &&
          typeof data.answer_8_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_8_personality_score)
          }),
        ...(data?.answer_8_alt_text && { alternativeText: data.answer_8_alt_text })
      });
    }

    if (data.answer_9) {
      answers.push({
        id: 9,
        text: data.answer_9,
        ...(data?.answer_9_action && { action: data.answer_9_action }),
        ...(data?.answer_9_action_page && { actionPage: Number(data.answer_9_action_page) }),
        ...(Number.isInteger(data?.answer_9_percentage) && { percentage: data.answer_9_percentage }),
        ...(data?.answer_9_additional && { description: data.answer_9_additional }),
        ...(data?.answer_9_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_9_middlestep)
        }),
        ...(data.answer_9_personality &&
          data.answer_9_personality_score &&
          Array.isArray(data.answer_9_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_9_personality,
              data.answer_9_personality_score
            )
          }),
        ...(data.answer_9_personality_score &&
          typeof data.answer_9_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_9_personality_score)
          }),
        ...(data?.answer_9_alt_text && { alternativeText: data.answer_9_alt_text })
      });
    }

    if (data.answer_10) {
      answers.push({
        id: 10,
        text: data.answer_10,
        ...(data?.answer_10_action && { action: data.answer_10_action }),
        ...(data?.answer_10_action_page && { actionPage: Number(data.answer_10_action_page) }),
        ...(Number.isInteger(data?.answer_10_percentage) && { percentage: data.answer_10_percentage }),
        ...(data?.answer_10_additional && { description: data.answer_10_additional }),
        ...(data?.answer_10_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_10_middlestep)
        }),
        ...(data.answer_10_personality &&
          data.answer_10_personality_score &&
          Array.isArray(data.answer_10_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_10_personality,
              data.answer_10_personality_score
            )
          }),
        ...(data.answer_10_personality_score &&
          typeof data.answer_10_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_10_personality_score)
          }),
        ...(data?.answer_10_alt_text && { alternativeText: data.answer_10_alt_text })
      });
    }

    if (data.answer_11) {
      answers.push({
        id: 11,
        text: data.answer_11,
        ...(data?.answer_11_action && { action: data.answer_11_action }),
        ...(data?.answer_11_action_page && { actionPage: Number(data.answer_11_action_page) }),
        ...(Number.isInteger(data?.answer_11_percentage) && { percentage: data.answer_11_percentage }),
        ...(data?.answer_11_additional && { description: data.answer_11_additional }),
        ...(data?.answer_11_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_11_middlestep)
        }),
        ...(data.answer_11_personality &&
          data.answer_11_personality_score &&
          Array.isArray(data.answer_11_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_11_personality,
              data.answer_11_personality_score
            )
          }),
        ...(data.answer_11_personality_score &&
          typeof data.answer_11_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_11_personality_score)
          }),
        ...(data?.answer_11_alt_text && { alternativeText: data.answer_11_alt_text })
      });
    }

    if (data.answer_12) {
      answers.push({
        id: 12,
        text: data.answer_12,
        ...(data?.answer_12_action && { action: data.answer_12_action }),
        ...(data?.answer_12_action_page && { actionPage: Number(data.answer_12_action_page) }),
        ...(Number.isInteger(data?.answer_12_percentage) && { percentage: data.answer_12_percentage }),
        ...(data?.answer_12_additional && { description: data.answer_12_additional }),
        ...(data?.answer_12_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_12_middlestep)
        }),
        ...(data.answer_12_personality &&
          data.answer_12_personality_score &&
          Array.isArray(data.answer_12_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_12_personality,
              data.answer_12_personality_score
            )
          }),
        ...(data.answer_12_personality_score &&
          typeof data.answer_12_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_12_personality_score)
          }),
        ...(data?.answer_12_alt_text && { alternativeText: data.answer_12_alt_text })
      });
    }

    if (data.answer_13) {
      answers.push({
        id: 13,
        text: data.answer_13,
        ...(data?.answer_13_action && { action: data.answer_13_action }),
        ...(data?.answer_13_action_page && { actionPage: Number(data.answer_13_action_page) }),
        ...(Number.isInteger(data?.answer_13_percentage) && { percentage: data.answer_13_percentage }),
        ...(data?.answer_13_additional && { description: data.answer_13_additional }),
        ...(data?.answer_13_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_13_middlestep)
        }),
        ...(data.answer_13_personality &&
          data.answer_13_personality_score &&
          Array.isArray(data.answer_13_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_13_personality,
              data.answer_13_personality_score
            )
          }),
        ...(data.answer_13_personality_score &&
          typeof data.answer_13_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_13_personality_score)
          }),
        ...(data?.answer_13_alt_text && { alternativeText: data.answer_13_alt_text })
      });
    }

    if (data.answer_14) {
      answers.push({
        id: 14,
        text: data.answer_14,
        ...(data?.answer_14_action && { action: data.answer_14_action }),
        ...(data?.answer_14_action_page && { actionPage: Number(data.answer_14_action_page) }),
        ...(Number.isInteger(data?.answer_14_percentage) && { percentage: data.answer_14_percentage }),
        ...(data?.answer_14_additional && { description: data.answer_14_additional }),
        ...(data?.answer_14_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_14_middlestep)
        }),
        ...(data.answer_14_personality &&
          data.answer_14_personality_score &&
          Array.isArray(data.answer_14_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_14_personality,
              data.answer_14_personality_score
            )
          }),
        ...(data.answer_14_personality_score &&
          typeof data.answer_14_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_14_personality_score)
          }),
        ...(data?.answer_14_alt_text && { alternativeText: data.answer_14_alt_text })
      });
    }

    if (data.answer_15) {
      answers.push({
        id: 15,
        text: data.answer_15,
        ...(data?.answer_15_action && { action: data.answer_15_action }),
        ...(data?.answer_15_action_page && { actionPage: Number(data.answer_15_action_page) }),
        ...(Number.isInteger(data?.answer_15_percentage) && { percentage: data.answer_15_percentage }),
        ...(data?.answer_15_additional && { description: data.answer_15_additional }),
        ...(data?.answer_15_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_15_middlestep)
        }),
        ...(data.answer_15_personality &&
          data.answer_15_personality_score &&
          Array.isArray(data.answer_15_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_15_personality,
              data.answer_15_personality_score
            )
          }),
        ...(data.answer_15_personality_score &&
          typeof data.answer_15_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_15_personality_score)
          }),
        ...(data?.answer_15_alt_text && { alternativeText: data.answer_15_alt_text })
      });
    }

    if (data.answer_16) {
      answers.push({
        id: 16,
        text: data.answer_16,
        ...(data?.answer_16_action && { action: data.answer_16_action }),
        ...(data?.answer_16_action_page && { actionPage: Number(data.answer_16_action_page) }),
        ...(Number.isInteger(data?.answer_16_percentage) && { percentage: data.answer_16_percentage }),
        ...(data?.answer_16_additional && { description: data.answer_16_additional }),
        ...(data?.answer_16_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_16_middlestep)
        }),
        ...(data.answer_16_personality &&
          data.answer_16_personality_score &&
          Array.isArray(data.answer_16_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_16_personality,
              data.answer_16_personality_score
            )
          }),
        ...(data.answer_16_personality_score &&
          typeof data.answer_16_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_16_personality_score)
          }),
        ...(data?.answer_16_alt_text && { alternativeText: data.answer_16_alt_text })
      });
    }

    if (data.answer_17) {
      answers.push({
        id: 17,
        text: data.answer_17,
        ...(data?.answer_17_action && { action: data.answer_17_action }),
        ...(data?.answer_17_action_page && { actionPage: Number(data.answer_17_action_page) }),
        ...(Number.isInteger(data?.answer_17_percentage) && { percentage: data.answer_17_percentage }),
        ...(data?.answer_17_additional && { description: data.answer_17_additional }),
        ...(data?.answer_17_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_17_middlestep)
        }),
        ...(data.answer_17_personality &&
          data.answer_17_personality_score &&
          Array.isArray(data.answer_17_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_17_personality,
              data.answer_17_personality_score
            )
          }),
        ...(data.answer_17_personality_score &&
          typeof data.answer_17_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_17_personality_score)
          }),
        ...(data?.answer_17_alt_text && { alternativeText: data.answer_17_alt_text })
      });
    }

    if (data.answer_18) {
      answers.push({
        id: 18,
        text: data.answer_18,
        ...(data?.answer_18_action && { action: data.answer_18_action }),
        ...(data?.answer_18_action_page && { actionPage: Number(data.answer_18_action_page) }),
        ...(Number.isInteger(data?.answer_18_percentage) && { percentage: data.answer_18_percentage }),
        ...(data?.answer_18_additional && { description: data.answer_18_additional }),
        ...(data?.answer_18_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_18_middlestep)
        }),
        ...(data.answer_18_personality &&
          data.answer_18_personality_score &&
          Array.isArray(data.answer_18_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_18_personality,
              data.answer_18_personality_score
            )
          }),
        ...(data.answer_18_personality_score &&
          typeof data.answer_18_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_18_personality_score)
          }),
        ...(data?.answer_18_alt_text && { alternativeText: data.answer_18_alt_text })
      });
    }

    if (data.answer_19) {
      answers.push({
        id: 19,
        text: data.answer_19,
        ...(data?.answer_19_action && { action: data.answer_19_action }),
        ...(data?.answer_19_action_page && { actionPage: Number(data.answer_19_action_page) }),
        ...(Number.isInteger(data?.answer_19_percentage) && { percentage: data.answer_19_percentage }),
        ...(data?.answer_19_additional && { description: data.answer_19_additional }),
        ...(data?.answer_19_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_19_middlestep)
        }),
        ...(data.answer_19_personality &&
          data.answer_19_personality_score &&
          Array.isArray(data.answer_19_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_19_personality,
              data.answer_19_personality_score
            )
          }),
        ...(data.answer_19_personality_score &&
          typeof data.answer_19_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_19_personality_score)
          }),
        ...(data?.answer_19_alt_text && { alternativeText: data.answer_19_alt_text })
      });
    }

    if (data.answer_20) {
      answers.push({
        id: 20,
        text: data.answer_20,
        ...(data?.answer_20_action && { action: data.answer_20_action }),
        ...(data?.answer_20_action_page && { actionPage: Number(data.answer_20_action_page) }),
        ...(Number.isInteger(data?.answer_20_percentage) && { percentage: data.answer_20_percentage }),
        ...(data?.answer_20_additional && { description: data.answer_20_additional }),
        ...(data?.answer_20_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_20_middlestep)
        }),
        ...(data.answer_20_personality &&
          data.answer_20_personality_score &&
          Array.isArray(data.answer_20_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_20_personality,
              data.answer_20_personality_score
            )
          }),
        ...(data.answer_20_personality_score &&
          typeof data.answer_20_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_20_personality_score)
          }),
        ...(data?.answer_20_alt_text && { alternativeText: data.answer_20_alt_text })
      });
    }

    if (data.answer_21) {
      answers.push({
        id: 21,
        text: data.answer_21,
        ...(data?.answer_21_action && { action: data.answer_21_action }),
        ...(data?.answer_21_action_page && { actionPage: Number(data.answer_21_action_page) }),
        ...(Number.isInteger(data?.answer_21_percentage) && { percentage: data.answer_21_percentage }),
        ...(data?.answer_21_additional && { description: data.answer_21_additional }),
        ...(data?.answer_21_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_21_middlestep)
        }),
        ...(data.answer_21_personality &&
          data.answer_21_personality_score &&
          Array.isArray(data.answer_21_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_21_personality,
              data.answer_21_personality_score
            )
          }),
        ...(data.answer_21_personality_score &&
          typeof data.answer_21_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_21_personality_score)
          }),
        ...(data?.answer_21_alt_text && { alternativeText: data.answer_21_alt_text })
      });
    }

    if (data.answer_22) {
      answers.push({
        id: 22,
        text: data.answer_22,
        ...(data?.answer_22_action && { action: data.answer_22_action }),
        ...(data?.answer_22_action_page && { actionPage: Number(data.answer_22_action_page) }),
        ...(Number.isInteger(data?.answer_22_percentage) && { percentage: data.answer_22_percentage }),
        ...(data?.answer_22_additional && { description: data.answer_22_additional }),
        ...(data?.answer_22_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_22_middlestep)
        }),
        ...(data.answer_22_personality &&
          data.answer_22_personality_score &&
          Array.isArray(data.answer_22_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_22_personality,
              data.answer_22_personality_score
            )
          }),
        ...(data.answer_22_personality_score &&
          typeof data.answer_22_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_22_personality_score)
          }),
        ...(data?.answer_22_alt_text && { alternativeText: data.answer_22_alt_text })
      });
    }

    if (data.answer_23) {
      answers.push({
        id: 23,
        text: data.answer_23,
        ...(data?.answer_23_action && { action: data.answer_23_action }),
        ...(data?.answer_23_action_page && { actionPage: Number(data.answer_23_action_page) }),
        ...(Number.isInteger(data?.answer_23_percentage) && { percentage: data.answer_23_percentage }),
        ...(data?.answer_23_additional && { description: data.answer_23_additional }),
        ...(data?.answer_23_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_23_middlestep)
        }),
        ...(data.answer_23_personality &&
          data.answer_23_personality_score &&
          Array.isArray(data.answer_23_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_23_personality,
              data.answer_23_personality_score
            )
          }),
        ...(data.answer_23_personality_score &&
          typeof data.answer_23_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_23_personality_score)
          }),
        ...(data?.answer_23_alt_text && { alternativeText: data.answer_23_alt_text })
      });
    }

    if (data.answer_24) {
      answers.push({
        id: 24,
        text: data.answer_24,
        ...(data?.answer_24_action && { action: data.answer_24_action }),
        ...(data?.answer_24_action_page && { actionPage: Number(data.answer_24_action_page) }),
        ...(Number.isInteger(data?.answer_24_percentage) && { percentage: data.answer_24_percentage }),
        ...(data?.answer_24_additional && { description: data.answer_24_additional }),
        ...(data?.answer_24_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_24_middlestep)
        }),
        ...(data.answer_24_personality &&
          data.answer_24_personality_score &&
          Array.isArray(data.answer_24_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_24_personality,
              data.answer_24_personality_score
            )
          }),
        ...(data.answer_24_personality_score &&
          typeof data.answer_24_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_24_personality_score)
          }),
        ...(data?.answer_24_alt_text && { alternativeText: data.answer_24_alt_text })
      });
    }

    if (data.answer_25) {
      answers.push({
        id: 25,
        text: data.answer_25,
        ...(data?.answer_25_action && { action: data.answer_25_action }),
        ...(data?.answer_25_action_page && { actionPage: Number(data.answer_25_action_page) }),
        ...(Number.isInteger(data?.answer_25_percentage) && { percentage: data.answer_25_percentage }),
        ...(data?.answer_25_additional && { description: data.answer_25_additional }),
        ...(data?.answer_25_middlestep && {
          // Only include feedback if content is actually valid. Here we utilize feedbackMessagePrioritize to clense it.
          middleStep: GamePersonalityTestModel.feedbackMessagePrioritize(data.answer_25_middlestep)
        }),
        ...(data.answer_25_personality &&
          data.answer_25_personality_score &&
          Array.isArray(data.answer_25_personality_score) && {
            personalityScore: this.constructAnswerPersonalityScore(
              data.answer_25_personality,
              data.answer_25_personality_score
            )
          }),
        ...(data.answer_25_personality_score &&
          typeof data.answer_25_personality_score === 'string' && {
            personalityScoreInterval: Number(data.answer_25_personality_score)
          }),
        ...(data?.answer_25_alt_text && { alternativeText: data.answer_25_alt_text })
      });
    }

    return answers;
  }

  private static feedbackMessagePrioritize(...messages: string[]): string | undefined {
    return messages.find((message) => {
      return (
        message &&
        message.trim() &&
        ![
          '<div><br></div>',
          '<br>',
          '<p><br></p>',
          '<h1><br></h1>',
          '<h2><br></h2>',
          '<h3><br></h3>',
          '<h4><br></h4>',
          '<h5><br></h5>',
          '<h6><br></h6>',
          '<div style="text-align: center;"><br></div>',
          '<p style="text-align: center;"><br></p>',
          '<h1 style="text-align: center;"><br></h1>',
          '<h2 style="text-align: center;"><br></h2>',
          '<h3 style="text-align: center;"><br></h3>',
          '<h4 style="text-align: center;"><br></h4>',
          '<h5 style="text-align: center;"><br></h5>',
          '<h6 style="text-align: center;"><br></h6>'
        ].includes(message.replace(/&#60;/gi, '<').replace(/&#62;/gi, '>'))
      );
    });
  }

  private constructAnswerPersonalityScore(personality: string[], score: string[]): Record<number, number> {
    // TODO: Refactor to use personality IDs instead.
    const personalityScore: Record<number, number> = {};

    personality.forEach((personalityItem, personalityIndex) => {
      if (this.state.personalities[Number(personalityItem)]) {
        personalityScore[Number(personalityItem)] = Number(score[Number(personalityIndex)]);
      }
    });

    return personalityScore;
  }

  private constructPersonalitiesState(item: GamePersonalityTestPersonalityData): GamePersonalityTestPersonalityState {
    let personalityFlow: PersonalityFlow | undefined;

    switch (item.flow) {
      case 'loser':
        personalityFlow = PersonalityFlow.LOSER;
        break;
      case 'winner':
        personalityFlow = PersonalityFlow.WINNER;
        break;
    }

    return {
      id: Number(item.id_unique),
      name: item.name,
      image: item.image,
      description: item.description,
      url: item.url,
      videoUrl: item.video_url,
      flow: personalityFlow,
      interval: {
        enabled: this.state.general.resultCalculation === ResultCalculationType.INTERVAL,
        ...(item.interval_from && { from: Number(item.interval_from) }),
        ...(item.interval_to && { to: Number(item.interval_to) })
      },
      percentageBars: {
        enabled: item.percentage_bars_active === '1',
        ...(item.percentage_bars_bg_color && { bgColor: item.percentage_bars_bg_color }),
        ...(item.percentage_bars_text_color && { textColor: item.percentage_bars_text_color })
      }
    };
  }

  private constructGeneralState(data: GamePersonalityTestGeneralData): GamePersonalityTestGeneralState {
    let answerLayout: AnswerLayoutSettingsModel | undefined;

    if (this.state.general?.answerLayout) {
      this.state.general.answerLayout.setData(data);
      answerLayout = this.state.general.answerLayout;
    } else {
      answerLayout = new AnswerLayoutSettingsModel(data);
    }
    return {
      multiplePersonalitiesLoser: data.multiple_personalities_loser === '1',
      personalityPercentage: data.personality_percentage,
      resultCalculation: data.result_calculation ? data.result_calculation : ResultCalculationType.POINTS,
      enableSkipLogic: data.conditional ? data.conditional === '1' : false,
      answerLayout,
      contentAlign: data.quiz_align ?? AlignContentType.CENTER,
      answerAlignment: data.answer_alignment ?? AlignContentType.CENTER,
      buttons: GamePersonalityTestModel.parseGeneralButtonsData(data) || { enabled: false },
      answerFeedback: {
        enabled: data.show_answer === '1',
        ...(data.show_answer &&
          data.show_answer === '1' && {
            redirectToNextQuestion: data.show_answer_redirect === '1',
            ...(data.show_answer_delay && {
              delayUntilNext: Number(data.show_answer_delay)
            }),
            buttonNextLabel: data.next_question ?? 'Næste spørgsmål'
          })
      },
      feedback: {
        enabled: data.show_feedback,
        ...(data.show_feedback && {
          redirectToNextQuestion: data.show_answer_redirect === '1',
          ...(data.show_answer_delay && {
            delayUntilNext: Number(data.show_answer_delay)
          }),
          buttonNextLabel: data.next_question ?? 'Næste spørgsmål'
        })
      },
      showAnswerButton: data.show_answer_button === '1',
      customLabels: {
        buttons: {
          answer: data.answer_btn,
          lastAnswer: data.answer_btn_last,
          selectAnswer: data.select_answer_btn
        },
        linkLabel: data?.link
      },
      features: {
        bestTime: data.enable_best_time === '1',
        timeRanking: data.enable_time_ranking === '1'
      },
      time: {
        enabled: data.time === 1,
        ...(data?.time_limit && { limit: Number(data.time_limit) }),
        perQuestion: data.time_per_question === '1'
      },
      activeStateColor: data.active_state_color,
      activeStateBackgroundColor: data.active_state_bg_color,
      ...(data.focus_state_background && {
        focusStateBackgroundColor: data.focus_state_background
      }),
      ...(data.focus_state_text && {
        focusStateTextColor: data.focus_state_text
      })
    };
  }

  private static parseGeneralButtonsData(
    data: GamePersonalityTestGeneralData
  ): GamePersonalityTestSwipeItButtonsState | undefined {
    if (data.buttons) {
      const useData = getDeviceData<GamePersonalityTestSwipeButtonData>(data.buttons);

      if (!useData) {
        return undefined;
      }

      return GamePersonalityTestModel.constructGamePersonalityTestSwipeButtonsState(useData);
    }

    return undefined;
  }

  private static constructGamePersonalityTestSwipeButtonsState(
    data: GamePersonalityTestSwipeButtonData
  ): GamePersonalityTestSwipeItButtonsState | undefined {
    if (data) {
      return {
        enabled: data.enabled === '1',
        color: data.color,
        leftText: data.left_text,
        rightText: data.right_text,
        ...(data.left_image && {
          leftImage: data.left_image
        }),
        ...(data.right_image && {
          rightImage: data.right_image
        }),
        onlyFacebook: data.only_facebook === '1',
        type: data.type
      };
    }
    return data;
  }

  private getBackgroundPosition(data?: BackgroundPositionType) {
    let positionType: PositionTypes | undefined;

    switch (data) {
      case 'custom':
        positionType = PositionTypes.CUSTOM;
        break;
      case 'bottom_center':
        positionType = PositionTypes.BOTTOM_CENTER;
        break;
      case 'bottom_left':
        positionType = PositionTypes.BOTTOM_LEFT;
        break;
      case 'bottom_right':
        positionType = PositionTypes.BOTTOM_RIGHT;
        break;
      case 'center_left':
        positionType = PositionTypes.CENTER_LEFT;
        break;
      case 'center_right':
        positionType = PositionTypes.CENTER_RIGHT;
        break;
      case 'top_center':
        positionType = PositionTypes.TOP_CENTER;
        break;
      case 'top_left':
        positionType = PositionTypes.TOP_LEFT;
        break;
      case 'top_right':
        positionType = PositionTypes.TOP_RIGHT;
        break;
      default:
        positionType = PositionTypes.CENTER_CENTER;
        break;
    }
    return positionType;
  }

  private getBackgroundBehavior(data?: BackgroundBehaviorType) {
    let behaviourType: BehaviorTypes | undefined;

    switch (data) {
      case 'contain':
        behaviourType = BehaviorTypes.CONTAIN;
        break;
      case 'stretch':
        behaviourType = BehaviorTypes.STRETCH;
        break;
      case 'tile':
        behaviourType = BehaviorTypes.TILE;
        break;
      case 'actual_size':
        behaviourType = BehaviorTypes.ACTUAL_SIZE;
        break;
      default:
        behaviourType = BehaviorTypes.COVER;
        break;
    }
    return behaviourType;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.general.time.limit ?? 0,
          time_used: 1
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }

  public get sdkMetrics(): MetricData | undefined {
    const campaignStore = useCampaignStore();

    if (Object.keys(campaignStore.metricData).length === 0) {
      return undefined;
    }

    let currentPersonaltiy: GamePersonalityTestPersonalityState | undefined;

    if (campaignStore.metricData.personality) {
      currentPersonaltiy = this.state.personalities.find((personality) => {
        return personality.id === Number(campaignStore.metricData.personality);
      });
    }

    return {
      timeused: Number(campaignStore.metricData.timeused ?? 0),
      timeused_ms: Number(campaignStore.metricData.timeused_ms ?? 0),
      timeleft: Number(campaignStore.metricData.timeleft ?? 0),
      total_answers: Number(campaignStore.metricData.total_answers ?? 0),
      ...(currentPersonaltiy && { personality_id: Number(currentPersonaltiy.id) })
    };
  }

  public get sdkSettings(): GamePersonalityTestSdkSettings {
    const lastQuestion = this.state.questions[this.state.questions.length - 1];

    return {
      questions: this.state.questions.map((question) => {
        return new SdkPersonalityTestQuestionModel(question, lastQuestion === question);
      }),
      personalities: this.state.personalities.map((personality) => {
        return new SdkPersonalityTestPersonalityModel(personality);
      })
    };
  }
}
