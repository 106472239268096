import type { GameSwipePictureCardsState } from '../Model';

export class SdkSwipeitPictureModel {
  public readonly id: number;
  public readonly image: string;
  public readonly description?: string;
  public readonly url?: string;

  constructor(state: GameSwipePictureCardsState) {
    this.id = state.id;
    this.image = state.image;
    this.description = state.description;
    this.url = state.url;
  }
}
