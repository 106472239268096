import { BaseModel } from '@/src/models/BaseModel';
import type { CustomCSS } from '@/src/typings/interfaces/data/settings/settings';

export interface CustomCSState {
  code?: string;
}

export class CustomCSSModel extends BaseModel<CustomCSS, CustomCSState> {
  public identifier: string;

  constructor(data: CustomCSS, identifier: string) {
    super(data);
    this.identifier = identifier;
    this.parse(this.getData());
  }

  shallSkipInitialParse(): boolean {
    return true;
  }

  parse(data: CustomCSS) {
    const state = this.state;
    if (data.code && data.code.length > 0) {
      state.code = CustomCSSModel.parseCSSCodeWithIdentifier(data, this.identifier);
    } else {
      state.code = undefined;
    }
  }

  private static parseCSSCodeWithIdentifier(data: CustomCSS, identifier: string): string {
    return data?.code
      .replace(/^selector/gm, '.' + identifier)
      .replace(/ selector/gm, ' .' + identifier)
      .replace(/&#62;/gi, '>')
      .replace(/&#60;/gi, '<')
      .replace(/&#92;/gi, '\\');
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
