import type { IntegrationBulkPrizeVouchersData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export enum ControlCodeTypes {
  TOP,
  BOTTOM,
  OVERLAY,
  REPLACEMENT_TAG
}

interface BulkPrizeVouchersControlCodeState {
  enabled?: boolean;
  label?: string;
  type?: ControlCodeTypes;
  backgroundColor?: string;
  textColor?: string;
  active?: boolean;
  closed?: boolean;
}

export interface IntegrationBulkPrizeVouchersState extends BaseIntegrationState {
  controlCode?: BulkPrizeVouchersControlCodeState;
}

export class IntegrationBulkPrizeVouchersModel extends BaseIntegration<
  IntegrationBulkPrizeVouchersData,
  IntegrationBulkPrizeVouchersState
> {
  parse(data: IntegrationBulkPrizeVouchersData) {
    super.parse(data);
    if (data.settings?.control_code) {
      this.state.controlCode = this.state.controlCode ?? {};
      this.state.controlCode.enabled = !!data.settings.control_code.enabled;
      this.state.controlCode.label = data.settings.control_code.label;
      this.state.controlCode.backgroundColor = data.settings.control_code.background_color;
      this.state.controlCode.textColor = data.settings.control_code.text_color;

      switch (data.settings.control_code.type) {
        case 'top':
          this.state.controlCode.type = ControlCodeTypes.TOP;
          break;

        case 'bottom':
          this.state.controlCode.type = ControlCodeTypes.BOTTOM;
          break;

        case 'overlay':
          this.state.controlCode.type = ControlCodeTypes.OVERLAY;
          break;

        case 'replacementtag':
          this.state.controlCode.type = ControlCodeTypes.REPLACEMENT_TAG;
          break;

        default:
          this.state.controlCode.type = undefined;
      }
    } else {
      this.state.controlCode = undefined;
    }
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
