import type { CSSProperties } from 'vue';
import { useUtilityStore } from '@/src/store/utility';
import type { AddonLfTextData, AddonLfTextEditType } from '@/src/components/addons/lf-text/Data';
import type { ShadowState, TextTypographyState } from '@/src/typings/interfaces/state/SharedAddons';
import type { TextShadowData, TypographyData } from '@/src/typings/interfaces/data/settings/settings';
import { getDeviceData } from '@/src/hooks/useDevice';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { replaceFontIdWithFontString } from '@/src/utilities/Utilities';
import type { TruncateData, TruncateState } from '@/src/utilities/Truncate';

interface TextLayoutState {
  shadow?: ShadowState;
  typography?: TextTypographyState;
}

export interface AddonLfTextState extends AddonModelState {
  name: string;
  text: string;
  textIdentifier: string;

  settings?: {
    truncate?: TruncateState;
    editorType?: AddonLfTextEditType;
    layout?: TextLayoutState;
  };
  css: string;
  elementStyling?: {
    inline?: CSSProperties;
    wrapper?: CSSProperties;
  };
}

export class AddonLfTextModel extends AddonModel<AddonLfTextData, AddonLfTextState, 'lf-text'> {
  parseAddon(data: AddonLfTextData) {
    const utilityStore = useUtilityStore();

    const state = this.state;
    state.text =
      data.settings.text ||
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas venenatis enim vitae massa pellentesque interdum. Nulla dapibus enim purus, molestie semper risus sagittis eu.';

    state.settings = state.settings ?? {};
    state.settings.editorType = data.settings.editor_type;
    state.settings.truncate = state.settings.truncate ?? {};
    state.settings.layout = state.settings.layout ?? {};
    state.settings.layout.typography = state.settings.layout.typography ?? {};
    state.settings.layout.shadow = state.settings.layout.shadow ?? {};

    if (data.settings?.truncate) {
      state.settings.truncate = AddonLfTextModel.constructTruncateState(data.settings.truncate);
    } else {
      state.settings.truncate = {};
    }

    if (data.settings?.layout?.typography && Object.keys(data.settings.layout.typography).length > 0) {
      state.settings.layout.typography = AddonLfTextModel.parseTypographyData(data);
    } else {
      state.settings.layout.typography = undefined;
    }

    state.settings.layout.shadow = AddonLfTextModel.parseShadowData(data);

    if (state.settings.layout.typography && this.state.classIdentifier) {
      state.css = AddonLfTextModel.constructElementStyling(
        state.settings.layout.typography,
        this.state.classIdentifier
      );
    } else {
      state.css = '';
    }

    let shadowStyle: CSSProperties | undefined;
    if (state.settings.layout.shadow) {
      shadowStyle = AddonLfTextModel.constructElementShadowStyles(state.settings.layout.shadow);
    }

    state.elementStyling = {};
    state.elementStyling.inline = {
      ...shadowStyle
    };

    if (state.settings.layout.typography) {
      state.elementStyling.wrapper = AddonLfTextModel.constructElementWrapperStyles(state.settings.layout.typography);
    } else {
      state.elementStyling.wrapper = undefined;
    }

    // Load font
    if (state.settings.layout?.typography?.fontFamily) {
      utilityStore.loadFont(state.settings.layout.typography.fontFamily);
    }
  }

  private static constructTruncateState(data: TruncateData): TruncateState {
    return {
      enabled: data.enable === '1',
      labelMore: data.label_more || 'read more',
      labelLess: data.label_less || 'read less',
      limit: Number(data.limit) || 100
    };
  }

  private static parseTypographyData(data: AddonLfTextData): TypographyData | undefined {
    if (data?.settings?.layout?.typography) {
      const useData = getDeviceData(data.settings.layout.typography) as TypographyData | undefined;

      if (!useData) {
        return undefined;
      }
      return AddonLfTextModel.constructTypographyState(useData);
    }
  }

  private static constructElementWrapperStyles(data: TextTypographyState): CSSProperties {
    // TODO: Find a way to type this with the important property
    // @ts-ignore
    return {
      ...(data.alignment && { textAlign: `${data.alignment} !important` })
    };
  }

  private static constructTypographyState(data: TypographyData): TextTypographyState {
    return {
      ...(data.alignment && { alignment: data.alignment }),
      ...(data.color && { color: data.color }),
      ...(data.font_family && { fontFamily: replaceFontIdWithFontString(data.font_family) }),
      ...(data.font_weight && {
        fontWeight: Number(data.font_weight)
      }),
      ...(data.gradient_1 && { gradientOne: data.gradient_1 }),
      ...(data.gradient_2 && { gradientTwo: data.gradient_2 }),
      ...(data.letter_spacing && {
        letterSpacing: parseFloat(data.letter_spacing)
      }),
      ...(data.line_height && {
        lineHeight: parseFloat(data.line_height)
      }),
      ...(data.size && { fontSize: data.size }),
      ...(data.text_decoration && {
        textDecoration: data.text_decoration
      }),
      ...(data.text_style && { textStyle: data.text_style }),
      ...(data.transform && { transform: data.transform })
    };
  }

  private static parseShadowData(data: AddonLfTextData): ShadowState | undefined {
    if (data.settings?.layout?.shadow) {
      const useData = getDeviceData(data.settings.layout.shadow) as TextShadowData | undefined;

      if (!useData) {
        return undefined;
      }

      return AddonLfTextModel.constructShadowState(useData);
    }
  }

  private static constructShadowState(data: TextShadowData): ShadowState | undefined {
    return {
      ...(data?.blur && { blur: Number(data.blur) }),
      ...(data?.color && { color: data.color }),
      ...(data?.horizontal && { horizontal: Number(data.horizontal) }),
      ...(data?.vertical && { vertical: Number(data.vertical) })
    };
  }

  private static constructElementStyling(state: TextTypographyState, identifier: string): string {
    const styles: CSSProperties = {
      ...(state.fontSize && { fontSize: `${state.fontSize}` }),
      ...(state.color && { color: state.color }),
      ...(state.alignment && { textAlign: state.alignment }),
      ...(state.fontWeight && { fontWeight: state.fontWeight }),
      ...(state.lineHeight && { lineHeight: state.lineHeight }),
      ...(state.letterSpacing && {
        letterSpacing: `${state.letterSpacing}px`
      }),
      ...(state.fontFamily && { fontFamily: state.fontFamily }),
      ...(state.textDecoration && {
        textDecoration: state.textDecoration
      }),
      ...(state.textStyle && { fontStyle: state.textStyle }),
      ...(state.transform && { textTransform: state.transform as CSSProperties['textTransform'] }),
      ...(state.gradientOne &&
        state.gradientTwo && {
          background: `linear-gradient(to right, ${state.gradientOne}, ${state.gradientTwo})`
        }),
      ...(state.gradientOne && state.gradientTwo && { WebkitTextFillColor: 'transparent' }),
      ...(state.gradientOne && state.gradientTwo && { WebkitBackgroundClip: 'text' })
    };

    return `
      .${identifier} .lf-text__content,
      .${identifier} .lf-text__content a,
      .${identifier} .lf-text__content p,
      .${identifier} .lf-text__content div,
      .${identifier} .lf-text__content h1,
      .${identifier} .lf-text__content h2,
      .${identifier} .lf-text__content h3,
      .${identifier} .lf-text__content h4,
      .${identifier} .lf-text__content h5,
      .${identifier} .lf-text__content h6 {
        ${styles.fontSize ? 'font-size: ' + styles.fontSize + ' !important;' : ''}
        ${styles.color ? 'color: ' + styles.color + ' !important;' : ''}
        ${styles.fontWeight ? 'font-weight:' + styles.fontWeight + ' !important;' : ''}
        ${styles.lineHeight ? 'line-height: ' + styles.lineHeight + ' !important;' : ''}
        ${styles.letterSpacing ? 'letter-spacing: ' + styles.letterSpacing + ' !important;' : ''}
        ${
          styles.fontFamily
            ? 'font-family: "' + styles.fontFamily + '", "Helvetica Neue", Arial, sans-serif !important;'
            : ''
        }
        ${styles.textDecoration ? 'text-decoration: ' + styles.textDecoration + ' !important;' : ''}
        ${styles.fontStyle ? 'font-style: ' + styles.fontStyle + ' !important;' : ''}
        ${styles.textTransform ? 'text-transform: ' + styles.textTransform + ' !important;' : ''}
        ${
          state.gradientOne && state.gradientTwo
            ? 'background: linear-gradient(to right, ' + state.gradientOne + ', ' + state.gradientTwo + ') !important;'
            : ''
        }
        ${state.gradientOne && state.gradientTwo ? '-webkit-background-clip: text;' : ''}
        ${state.gradientOne && state.gradientTwo ? '-webkit-text-fill-color: transparent;' : ''}
      }
    `;
  }

  private static constructElementShadowStyles(state: ShadowState): CSSProperties {
    return {
      ...(state.color && {
        textShadow: `${state.horizontal ?? 0}px ${state.vertical ?? 0}px ${state.blur ?? 0}px ${state.color}`
      })
    };
  }
}
