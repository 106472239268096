import { registerRepeatable } from '@/src/services/repeatable';
import { Cache } from '@/src/services/cache';
import useAxios from '@/src/hooks/useAxios';
import type { RepeatableData } from '@/src/store/repeatable';

const register = () => {
  registerRepeatable('rss_feed', async (model) => {
    const cacheKey = `json-feed-${model.state.feedUrl}`;

    if (Cache.exists(cacheKey)) {
      return Cache.get<RepeatableData[]>(cacheKey) ?? [];
    }

    if (!model.state.feedUrl) {
      Cache.set<RepeatableData[]>(cacheKey, []);
      return [];
    }

    const { fetchData } = useAxios<string>(model.state.feedUrl);
    const data = await fetchData();

    // Use built-in DOM parser for extracting xml
    const xml = new window.DOMParser().parseFromString(data, 'text/xml');

    // RSS is located in the 'channel' directive
    const channel = xml.querySelector('channel');

    const items: RepeatableData[] = [];

    // If we cannot find the channel or if there is no channel nodes - then we skip parsing
    if (!channel || !channel.childNodes) {
      Cache.set(cacheKey, items);
      return items;
    }

    // Loop over channel nodes to append them to the data
    channel.childNodes.forEach((channelChild) => {
      if (channelChild.nodeName.trim().toLowerCase() !== '#text') {
        // If node is of nodeName 'item' then we expect it to be a item that is to be appended
        if (channelChild.nodeName.trim().toLowerCase() === 'item') {
          const itemData: RepeatableData = {
            id: items.length
          };

          channelChild.childNodes.forEach((item) => {
            if (item.nodeName.trim().toLowerCase() !== '#text' && item.textContent) {
              itemData[item.nodeName] = item.textContent.trim();
            }
          });

          items.push(itemData);
        }
      }
    });

    Cache.set(cacheKey, items);

    return items;
  });
};

export default register;
