import { defineStore } from 'pinia';
import type { CSSProperties, Raw } from 'vue';
import { nextTick } from 'vue';
import type { CampaignModel } from '@/src/models/CampaignModel';
import type { FlowpageModel } from '@/src/components/layout/FlowpageModel';
import type { ContentType } from '@/src/components/layout/section/SectionBaseModel';
import type { BehaviorTypes, PositionTypes } from '@/src/typings/enums/enums';
import { CampaignAdsSizeType, CampaignDeviceType, DeviceTypes } from '@/src/typings/enums/enums';
import { useUtilityStore } from '@/src/store/utility';
import { formatDate, getWithTimezone } from '@/src/utilities/DateHelpers';
import { getAvailableSimulationDevices } from '@/src/hooks/useDevice';
import { getCookie, setCookie } from '@/src/utilities/CookieHelpers';
import { events } from '@/src/services/events';

export interface MetricData {
  [key: string]: string | number;
}

export interface ReplacementTags {
  [key: string]: string | number;
}

export interface InstantWinData {
  [key: string]: string | number | boolean;
}

type Json = string | number | boolean | undefined | null | Json[] | { [key: string]: Json };
export type GameData = Json;

export interface FlowRegistrationInfo {
  id?: number;
  token?: string;
  pages: number[];
}

export interface GameSectionImageOverlay {
  enabled: boolean;
  behavior: BehaviorTypes;
  src?: string;
  overlay?: string; // This is a color, could be eg: ffffff or #ffffff
  position: PositionTypes;
  tileRepeat?: string;
  overwriteMobile?: boolean;
}

export interface SectionOverlayContent {
  type: ContentType;
  content: string;
}

export interface GameSectionOverlayContent {
  style: CSSProperties;
  content: SectionOverlayContent[];
}

export interface GameTestDate {
  enabled: boolean;
  date: string;
}

export interface DeviceSimulatedView {
  view: string;
  width?: number;
  height?: number;
}

interface State {
  /**
   * Demo token is that unique campaign token that is used to
   * identify calls throughout the system as coming from the demo view.
   */
  demoToken?: string;
  model?: Raw<CampaignModel>;
  flowId?: number;
  popoverId?: number;
  flowOverlay: boolean;
  fictiveFlowPage?: Raw<FlowpageModel>;
  noMoreFlowPages: boolean;

  skippedFlowRegistration?: boolean;

  metricData: MetricData;
  replacementTags: ReplacementTags;
  instantWinData: InstantWinData | null;
  staticFormData: Record<string, string | number | Record<string, string | number>>;
  staticQueryParams: Record<string, string>;
  gameData: GameData | null; // gameData is only being used on Wof & Spinthebottle & Roulette game
  gameWinner: boolean | null;
  gameEnded: boolean;
  currentDevice: DeviceTypes | null;
  currentDeviceSimulateView?: DeviceSimulatedView;
  currentDeviceOrientation?: 'portrait' | 'landscape' | null;
  initializationTime: number | undefined;
  flowRegistrationInfo?: FlowRegistrationInfo;
  gameSectionImageOverlay?: GameSectionImageOverlay;
  gameTestDate?: Raw<Date>;
  mountedSections: number[];
  mountedFlowpages: number[];
  readyPromiseResolve: (() => void) | undefined;
  readyPromise: Promise<void>;
  enableCSS: boolean;

  // Used to indicate if device is changing. This allow us to prepare content by e.g. fading it down
  // so that we avoid flickering.
  changingDevice: boolean;
  hasResponsiveScript: boolean;
}

export const useCampaignStore = defineStore('campaign', {
  state: (): State => {
    let readyPromiseResolve: (() => void) | undefined;
    // @ts-ignore-next-line

    const readyPromise = new Promise<void>((resolve) => {
      // @ts-ignore-next-line
      readyPromiseResolve = resolve;
    });

    return {
      changingDevice: false,
      demoToken: undefined,
      model: undefined,
      flowId: undefined,
      popoverId: undefined,
      flowOverlay: false,
      noMoreFlowPages: false,
      hasResponsiveScript: false,
      mountedSections: [],
      mountedFlowpages: [],
      metricData: {},
      replacementTags: {},
      gameData: null,
      gameWinner: null,
      gameEnded: false,
      instantWinData: null,
      staticFormData: {},
      staticQueryParams: {},
      currentDevice: null,
      currentDeviceSimulateView: undefined,
      currentDeviceOrientation: getCookie<'portrait' | 'landscape'>('p-device-orientation'),
      initializationTime: new Date().getTime(),
      flowRegistrationInfo: undefined,
      gameSectionImageOverlay: undefined,
      gameTestDate: undefined,
      readyPromise,
      readyPromiseResolve,
      enableCSS: true
    };
  },
  getters: {
    flowIndex(state) {
      if (state.flowId === undefined) {
        return undefined;
      }

      return state?.model?.getFlowPageIndex(state.flowId) ?? undefined;
    },
    flowModel(state) {
      if (state.flowId === undefined) {
        return undefined;
      }

      return state?.model?.getFlowPageModel(state.flowId) ?? undefined;
    },
    hasGamePopover: (state) => {
      const gamePlayModel = state.model?.getGamePlayModel();
      if (
        !gamePlayModel ||
        !gamePlayModel.state ||
        !gamePlayModel.state.settings ||
        typeof gamePlayModel.state.settings.gameOverlay === 'undefined'
      ) {
        return false;
      }
      return state.popoverId === gamePlayModel.state.settings.gameOverlay;
    },

    currentSimulatedView(state): DeviceSimulatedView | undefined {
      const currentDevice = state.currentDevice ?? DeviceTypes.DESKTOP;

      if (state.currentDeviceSimulateView) {
        if (
          state.currentDeviceSimulateView.height !== undefined &&
          state.currentDeviceSimulateView.width !== undefined
        ) {
          return state.currentDeviceSimulateView;
        } else {
          const availableSimulationDevices = getAvailableSimulationDevices();

          const simulatedDevice =
            state.model?.state.deviceType === CampaignDeviceType.ADS &&
            state.model.state.adsSizeType === CampaignAdsSizeType.FIXED
              ? availableSimulationDevices[0]
              : availableSimulationDevices.find((device) => device.id === currentDevice);

          if (!simulatedDevice) {
            return undefined;
          }

          const size = simulatedDevice.sizes.find((size) => size.id === state.currentDeviceSimulateView?.view);

          if (!size) {
            return undefined;
          }

          // only for mobile and tablet
          if (
            this.currentDeviceOrientation &&
            this.currentDeviceOrientation === 'landscape' &&
            currentDevice !== DeviceTypes.DESKTOP
          ) {
            return {
              view: state.currentDeviceSimulateView.view,
              width: size.dimensions?.height,
              height: size.dimensions?.width
            };
          }

          return {
            view: state.currentDeviceSimulateView.view,
            width: size.dimensions?.width,
            height: size.dimensions?.height
          };
        }
      }
    }
  },
  actions: {
    setActivePopover(popoverId: number | undefined) {
      if (popoverId === undefined) {
        this.popoverId = undefined;
      } else if (this.model?.state.popovers?.some((popover) => popover.id === popoverId)) {
        this.popoverId = popoverId;
      } else {
        this.popoverId = undefined;
      }
    },

    toggleDeviceOrientation() {
      this.currentDeviceOrientation = this.currentDeviceOrientation === 'portrait' ? 'landscape' : 'portrait';
      setCookie('p-device-orientation', this.currentDeviceOrientation);
    },

    setDeviceOrientation(orientation: 'portrait' | 'landscape') {
      this.currentDeviceOrientation = orientation;
      setCookie('p-device-orientation', this.currentDeviceOrientation);
    },

    // Reset the game state for the campaign
    // for now only gameEnded is getting reset, so it possible to go back and start the game again in edit mode
    resetGameState() {
      this.gameEnded = false;
    },

    requestDeviceChange(device: DeviceTypes, onChange?: () => void) {
      if (this.changingDevice) {
        return;
      }

      this.changingDevice = true;

      if (device !== DeviceTypes.DESKTOP) {
        document.body.style.cssText = 'background: var(--color-base-200) !important;';
      } else {
        document.body.style.cssText = '';
      }

      nextTick().then(() => {
        setTimeout(() => {
          this.currentDevice = device;
          this.model?.redoParse();

          onChange?.();

          this.model?.redoParse();
        }, 500);

        setTimeout(() => {
          events.emit('deviceChanged');
          this.changingDevice = false;
        }, 700);
      });
    },
    addReplacementTags(replacementTags: ReplacementTags) {
      for (const replacementTagKey in replacementTags) {
        if (Object.prototype.hasOwnProperty.call(replacementTags, replacementTagKey)) {
          this.replacementTags[`${replacementTagKey}`] = replacementTags[`${replacementTagKey}`];
        }
      }
    },
    addStaticQueryParams(params: Record<string, string>) {
      Object.assign(this.staticQueryParams, params);
    },

    addStaticFormData(params: Record<string, string | number | Record<string, string | number>>) {
      Object.assign(this.staticFormData, params);
    },
    setGameTestDate(date: string) {
      const utilityStore = useUtilityStore();
      if (!utilityStore.url.includes('/campaign/view/demo') || !this.demoToken) return;
      const currentTestDate = getWithTimezone(`${date} 00:00:00`);
      this.addReplacementTags({
        date: formatDate(currentTestDate, 'Y-m-d')
      });
      this.gameTestDate = currentTestDate;
    }
  }
});
