import type { IntegrationPhilipMorrisData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';
import type { Component } from 'vue';

export enum PmiEventCondition {
  FLOWPAGE
}

export interface PmiEventsItemState {
  condition: {
    flowpage: number;
  };
  id: number;
  on?: PmiEventCondition;
}

export interface IntegrationPhilipMorrisState extends BaseIntegrationState {
  decryption: {
    enabled: boolean;
    fields: number[];
  };

  endpoint?: string;
  onlyAllowViewingInIframe: boolean;
  events: PmiEventsItemState[];
}

export class IntegrationPhilipMorrisModel extends BaseIntegration<
  IntegrationPhilipMorrisData,
  IntegrationPhilipMorrisState
> {
  parse(data: IntegrationPhilipMorrisData) {
    super.parse(data);

    if (this.state.decryption) {
      this.state.decryption.enabled = !!data.settings?.decryption.enabled;
      this.state.decryption.fields = data.settings?.decryption.fields.map((item) => Number(item)) || [];
    } else {
      this.state.decryption = {
        enabled: !!data.settings?.decryption.enabled,
        fields: data.settings?.decryption.fields.map((item) => Number(item)) || []
      };
    }

    this.state.endpoint = data.settings?.endpoint;
    this.state.onlyAllowViewingInIframe = !!data.settings?.only_allow_viewing_in_iframe;
    this.state.events =
      data.settings?.pmi_events.map<PmiEventsItemState>((item) => {
        let condition: PmiEventCondition | undefined;

        switch (item.on) {
          case 'flowpage':
            condition = PmiEventCondition.FLOWPAGE;
            break;
        }

        return {
          condition: {
            flowpage: Number(item.condition.flowpage)
          },
          id: Number(item.id),
          on: condition
        };
      }) || [];
  }

  hasVueComponent() {
    return true;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/philip-morris/View.vue');
  }
}
