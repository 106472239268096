import { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import { SectionType } from '@/src/typings/enums/enums';
import { FlowPageSettingsModel } from '@/src/components/layout/FlowPageSettingsModel';
import type { SettingsModel } from '@/src/components/layout/SettingsModel';
import type { SettingsData } from '@/src/typings/interfaces/data/settings/settings';
import useFlow from '@/src/hooks/useFlow';
import { useEditingStore } from '@/src/store/editing';
import { router } from '@/src/router';

export class FlowpageModel extends SectionBaseModel {
  activateEditing(): void {
    const { makeSpecificFlowPageActive } = useFlow();
    const route = router.currentRoute.value;

    if (route.query?.pageId) {
      const newQuery = { ...route.query };
      delete newQuery.pageId;

      router.push({
        query: newQuery
      });
    }

    makeSpecificFlowPageActive(this.id);

    const editingStore = useEditingStore();
    editingStore.setActiveModel(this);
  }

  public getSectionType() {
    return SectionType.FLOWPAGE;
  }

  public getSettingsModel(data: SettingsData): SettingsModel {
    return new FlowPageSettingsModel(data, this);
  }

  get index(): number {
    return this.campaignModel.state.flowPages.indexOf(this);
  }
}
