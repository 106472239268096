import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  DefaultData,
  GamePuzzleAdvancedData,
  GamePuzzleData,
  GamePuzzleGridData
} from '@/src/components/games/puzzle/Data';
import { GamePuzzleTileTypes } from '@/src/components/games/puzzle/Data';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type { TransitionTypes } from '@/src/typings/types/types';
import { getDeviceData } from '@/src/hooks/useDevice';

interface GamePuzzleGridState {
  image: string;
  background?: string;
  columns: number;
  rows: number;
}

interface PuzzleDefaultState {
  delay?: number;
  enableBestTime?: boolean;
  enableTimeRanking?: boolean;
  tileType: string;
  grid: GamePuzzleGridState;
}

export interface GamePuzzleAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GamePuzzleAdvancedAnimationState {
  enabled: boolean;
  dragAnimation?: TransitionTypes;
  imageAnimation?: TransitionTypes;
}

export interface GamePuzzleAdvancedState {
  sound: GamePuzzleAdvancedSoundState;
  animation: GamePuzzleAdvancedAnimationState;
}

export interface GamePuzzleState {
  alias: string;
  default: PuzzleDefaultState;
  indicators: GameIndicatorSettingsModel;
  advanced: GamePuzzleAdvancedState;
}

export class GamePuzzleModel
  extends GameModel<GamePuzzleData, GamePuzzleState>
  implements HasGameTimeChallenge, HasSound
{
  parseGame(data: GamePuzzleData) {
    const state = this.state;

    state.default = GamePuzzleModel.constructDefaultState(data.default);

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    }

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GamePuzzleModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GamePuzzleModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructDefaultState(data: DefaultData): PuzzleDefaultState {
    return {
      delay: data.delay && data.delay !== '' ? Number(data.delay) * 1000 : 1000,
      enableBestTime: data.enable_best_time === '1',
      enableTimeRanking: data.enable_time_ranking === '1',
      tileType: data.tile_type ? data.tile_type : GamePuzzleTileTypes.SQUARE_TILES,
      grid: GamePuzzleModel.constructDefaultGridState(data)
    };
  }

  private static constructDefaultGridState(data: DefaultData): GamePuzzleGridState {
    const deviceGridData = getDeviceData<GamePuzzleGridData>(data.grid);

    if (deviceGridData) {
      return {
        image: deviceGridData.image,
        ...(deviceGridData.background && { background: deviceGridData.background }),
        rows: Number(deviceGridData.rows) || 3,
        columns: Number(deviceGridData.columns) || 3
      };
    }

    return {
      image: '',
      rows: 3,
      columns: 3
    };
  }

  private static constructAdvancedSoundState(data: GamePuzzleAdvancedData | undefined): GamePuzzleAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructAdvancedAnimationState(
    data: GamePuzzleAdvancedData | undefined
  ): GamePuzzleAdvancedAnimationState {
    return {
      enabled: !!data?.drag_animation || !!data?.image_animation,
      ...(data?.drag_animation && { dragAnimation: data.drag_animation }),
      ...(data?.image_animation && { imageAnimation: data.image_animation })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit ?? 0,
          time_used: 0
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.default.time === 1,
      ...(data.default.time_limit && { limit: Number(data.default.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
