import type {
  GameSliceitVisualsSizeData,
  GameSliceitData,
  GameSliceitGeneralData,
  GameSliceitVisualsData
} from './Data';
import { getDeviceData } from './../../../hooks/useDevice';
import type { HasGameTimeChallenge, GameIndicator, GameTimeChallenge, GameState } from './../../../models/GameModel';
import { GameModel } from './../../../models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';

interface GameSliceitGeneralState {
  pointsToWin: number;
  spawnSpeed: number;
}

export enum GameSliceitSpecialEvent {
  END_GAME,
  CLEAR_ALL_NO_POINTS,
  CLEAR_ALL_POINTS
}

export enum GameSliceitTargetSlicingVariant {
  TOP_RIGHT,
  TOP_LEFT
}

export interface GameSliceitTargetState {
  id: number;
  image: string;
  points: number;
  height?: number;
  label: {
    text: string;
    color: string;
    size: number;
  };

  slicingVariant: GameSliceitTargetSlicingVariant;

  specialEvent?: GameSliceitSpecialEvent;
}

interface GameSliceitVisualsState {
  height: string;
  slicer: {
    width: number;
    color: string;
  };
  targets: GameSliceitTargetState[];
}

interface GameSliceitState extends GameState {
  general: GameSliceitGeneralState;
  visuals: GameSliceitVisualsState;
}

export class GameSliceitModel extends GameModel<GameSliceitData, GameSliceitState> implements HasGameTimeChallenge {
  parseGame(data: GameSliceitData) {
    const state = this.state;

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    }

    state.general = GameSliceitModel.constructGeneralState(data.general);
    state.visuals = GameSliceitModel.constructVisualsState(data.visuals);
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.general?.time === 1,
      ...(data.general?.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  private static constructVisualsState(data?: GameSliceitVisualsData): GameSliceitVisualsState {
    if (!data) {
      return {
        height: '550px',
        slicer: {
          width: 1,
          color: '#ffffff'
        },
        targets: []
      };
    }

    const deviceData = getDeviceData<GameSliceitVisualsSizeData>(data.size);

    return {
      height: deviceData?.height ?? '500px',
      slicer: {
        width: data.slicer.width ? Number(data.slicer.width.replace('px', '')) : 1,
        color: data.slicer.color ? data.slicer.color : '#ffffff'
      },
      targets: data.targets.map((target) => {
        let specialEvent: GameSliceitSpecialEvent | undefined;

        if (target.special_event.enabled && target.special_event.event) {
          switch (target.special_event.event) {
            case 'end_game':
              specialEvent = GameSliceitSpecialEvent.END_GAME;
              break;

            case 'clear_all_no_points':
              specialEvent = GameSliceitSpecialEvent.CLEAR_ALL_NO_POINTS;
              break;

            case 'clear_all_points':
              specialEvent = GameSliceitSpecialEvent.CLEAR_ALL_POINTS;
              break;
          }
        }

        return {
          id: target.id,
          image: target.image,
          points: Number(target.points),
          height: target.height ? Number(target.height.replace('px', '')) : undefined,
          label: {
            text: target.label.text,
            color: target.label.color,
            size: Number(target.label.size.replace('px', ''))
          },
          slicingVariant:
            target.slicing === 'from_top_left'
              ? GameSliceitTargetSlicingVariant.TOP_LEFT
              : GameSliceitTargetSlicingVariant.TOP_RIGHT,
          specialEvent
        };
      })
    };
  }

  private static constructGeneralState(data?: GameSliceitGeneralData): GameSliceitGeneralState {
    return {
      pointsToWin: data?.points_towin ? data.points_towin : 500,
      spawnSpeed: data?.spawn_speed ? data.spawn_speed : 1250
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.ABSOLUTE,
      bottom: GameIndicatorPositionType.ABSOLUTE
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 0,
          time_used: 0
        }
      },
      {
        indicatorKey: 'score',
        metricKey: {
          score_used: 'points_collected'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          score_used: 0
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }
}
