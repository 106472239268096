import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 2600 131.1",
  preserveAspectRatio: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      class: "elementor-shape-fill",
      d: "M0 0L2600 0 2600 69.1 0 0z"
    }, null, -1),
    _createElementVNode("path", {
      class: "elementor-shape-fill",
      style: {"opacity":"0.5"},
      d: "M0 0L2600 0 2600 69.1 0 69.1z"
    }, null, -1),
    _createElementVNode("path", {
      class: "elementor-shape-fill",
      style: {"opacity":"0.25"},
      d: "M2600 0L0 0 0 130.1 2600 69.1z"
    }, null, -1)
  ])))
}
export default { render: render }