import type { CSSProperties } from 'vue';
import type { AddonMenuData } from '@/src/components/addons/menu/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { formatUrl } from '@/src/utilities/Url';

interface PaddingState {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface MenuDisplayState {
  seperator?: string;
  seperatorColor?: string;
  placement: string;
  logo?: {
    width?: string | null;
    height?: string | null;
    padding?: PaddingState;
  };
  menuitem?: {
    padding?: PaddingState;
    fontSize?: number;
    color?: string;
    hoverColor?: string;
    underlined?: boolean;
    mobile?: {
      fontSize?: number | null;
      color?: string;
      iconBackgroundColor?: string;
      overlayColor?: string;
      menuBarColor?: string;
    };
  };
  overwriteMobile: boolean;
  advanced: {
    class?: string;
  };
}

interface MenuLogoState {
  enabled: boolean;
  image?: string;
  placement?: string;
  url?: string;
  target?: string;
}

export interface MenuItemState {
  name?: string;
  action?: string;
  target?: string;
  url?: string;
  popover?: number;
  section?: string;
  type?: string;
  subItems?: MenuItemState[];
}

interface AddonMenuState extends AddonModelState {
  logo: MenuLogoState;
  items: MenuItemState[];
  display: MenuDisplayState;
  menuClass?: string[];
  elementStyling?: {
    logoImageStyle?: CSSProperties;
    logoStyle?: CSSProperties;
    itemStyle?: CSSProperties;
    itemStyleMobile?: CSSProperties;
    cssStyling?: string;
  };
}

export class AddonMenuModel extends AddonModel<AddonMenuData, AddonMenuState, 'menu'> {
  parseAddon(data: AddonMenuData) {
    const state = this.state;

    state.menuClass = [];
    state.logo = state.logo ?? {};
    state.logo.enabled = data.settings.logo?.enabled === '1';

    if (!state.logo.enabled) {
      state.logo.image = '';
      state.logo.url = '';
    }

    state.logo.url =
      data.settings.logo?.url && data.settings.logo?.url !== '' ? formatUrl(data.settings.logo?.url) : '';
    state.logo.image = data.settings.logo?.image;
    state.logo.target = data.settings.logo?.target ?? '_self';
    state.logo.placement = data.settings.logo?.placement ?? 'left';

    state.items = state.items ?? [];
    if (data.settings.items) {
      state.items = Object.values(data.settings.items).map((item) => {
        return {
          name: item.name,
          action: item.action,
          ...(item.popover && { popover: Number(item.popover) }),
          section: item.section,
          url: item.url,
          type: 'link',
          subItems: item.subitems
            ? Object.values(item.subitems).map((subItem) => {
                return {
                  name: subItem.name,
                  action: subItem.action,
                  popover: Number(subItem.popover),
                  section: subItem.section,
                  url: subItem.url,
                  type: 'link'
                };
              })
            : undefined
        };
      });
    } else {
      const items: MenuItemState[] = [];
      let url = '';
      if (typeof window !== 'undefined') {
        url = window.location.hostname;
      }
      for (let itemCount = 0; itemCount < 3; itemCount++) {
        items.push({
          name: `MenuItem ${itemCount + 1}`,
          type: 'link',
          target: '_self',
          url: `http://${url}`
        });
      }
      state.items = items;
    }

    state.display = state.display ?? {};
    state.display.logo = state.display.logo ?? {};
    state.display.menuitem = state.display.menuitem ?? {};
    state.display.advanced = state.display.advanced ?? {};

    if (data.settings.display?.seperator) {
      state.display.seperator = data.settings.display?.seperator;
    }

    if (data.settings.display?.seperator_color) {
      state.display.seperatorColor = data.settings.display.seperator_color;
    }

    if (data.settings.display?.overwrite_mobile) {
      state.display.overwriteMobile = data.settings.display?.overwrite_mobile === '1';
    }

    if (data.settings.display?.logo?.width && data.settings.display?.logo?.width !== '') {
      state.display.logo.width = data.settings.display.logo.width;
    } else {
      state.display.logo.width = undefined;
    }

    if (data.settings.display?.logo?.height && data.settings.display?.logo?.height !== '') {
      state.display.logo.height = data.settings.display.logo.height;
    } else {
      state.display.logo.height = undefined;
    }

    state.display.logo.padding = {
      top:
        data.settings.display?.logo?.padding?.top && data.settings.display.logo?.padding?.top !== ''
          ? Number(data.settings.display.logo?.padding?.top) ?? 10
          : 10,
      right:
        data.settings.display?.logo?.padding?.right && data.settings.display.logo?.padding?.right !== ''
          ? Number(data.settings.display.logo?.padding?.right) ?? 15
          : 15,
      bottom:
        data.settings.display?.logo?.padding?.bottom && data.settings.display.logo?.padding?.bottom !== ''
          ? Number(data.settings.display.logo?.padding?.bottom) ?? 10
          : 10,
      left:
        data.settings.display?.logo?.padding?.left && data.settings.display.logo?.padding?.left !== ''
          ? Number(data.settings.display.logo?.padding?.left) ?? 15
          : 15
    };

    if (data.settings.display?.menuitem && data.settings.display?.menuitem?.color) {
      state.display.menuitem.color = data.settings.display.menuitem?.color;
    } else {
      state.display.menuitem.color = undefined;
    }
    if (data.settings.display?.menuitem && data.settings.display?.menuitem?.hover_color) {
      state.display.menuitem.hoverColor = data.settings.display.menuitem?.hover_color;
    } else {
      state.display.menuitem.hoverColor = undefined;
    }
    state.display.menuitem.fontSize = data.settings.display?.menuitem?.fontsize
      ? Number(data.settings.display.menuitem?.fontsize)
      : 0;
    state.display.menuitem.underlined = data.settings.display?.menuitem?.underlined === '1';
    state.display.menuitem.padding = {
      top:
        data.settings.display?.menuitem?.padding?.top && data.settings.display.menuitem?.padding?.top !== ''
          ? Number(data.settings.display.menuitem?.padding?.top) ?? 10
          : 10,
      right:
        data.settings.display?.menuitem?.padding?.right && data.settings.display.menuitem?.padding?.right !== ''
          ? Number(data.settings.display.menuitem?.padding?.right) ?? 15
          : 15,
      bottom:
        data.settings.display?.menuitem?.padding?.bottom && data.settings.display.menuitem?.padding?.bottom !== ''
          ? Number(data.settings.display.menuitem?.padding?.bottom) ?? 10
          : 10,
      left:
        data.settings.display?.menuitem?.padding?.left && data.settings.display.menuitem?.padding?.left !== ''
          ? Number(data.settings.display.menuitem?.padding?.left) ?? 15
          : 15
    };

    state.display.menuitem.mobile = state.display.menuitem.mobile ?? {};

    if (data.settings.display?.overwrite_mobile === '0') {
      if (data.settings.display?.menuitem?.mobile?.menu_bar_color) {
        state.display.menuitem.mobile.menuBarColor = data.settings.display.menuitem.mobile.menu_bar_color;
      } else {
        state.display.menuitem.mobile.menuBarColor = undefined;
      }

      if (data.settings.display?.menuitem?.mobile?.icon_background_color) {
        state.display.menuitem.mobile.iconBackgroundColor = data.settings.display.menuitem.mobile.icon_background_color;
      } else {
        state.display.menuitem.mobile.iconBackgroundColor = undefined;
      }

      if (data.settings.display?.menuitem?.mobile?.color) {
        state.display.menuitem.mobile.color = data.settings.display.menuitem.mobile.color;
      } else {
        state.display.menuitem.mobile.color = undefined;
      }

      if (data.settings.display?.menuitem?.mobile?.overlay_color) {
        state.display.menuitem.mobile.overlayColor = data.settings.display.menuitem.mobile.overlay_color;
      } else {
        state.display.menuitem.mobile.overlayColor = undefined;
      }

      if (data.settings.display?.menuitem?.mobile?.fontsize && data.settings.display.menuitem.mobile.fontsize !== '') {
        state.display.menuitem.mobile.fontSize = Number(data.settings.display.menuitem.mobile.fontsize);
      } else {
        state.display.menuitem.mobile.fontSize = undefined;
      }
    } else {
      state.display.menuitem.mobile = undefined;
    }

    state.display.advanced = state.display.advanced ?? {};
    if (data.settings.display?.advanced.class) {
      state.display.advanced.class = data.settings.display.advanced.class;
    } else {
      state.display.advanced.class = undefined;
    }

    if (state.logo && state.logo.image) {
      state.menuClass.push('menubar--has-logo');
      state.menuClass.push(`menubar--logo-placement-${state.logo.placement}`);
    }
    if (data.settings.display?.placement) {
      state.menuClass.push(`menubar--placement-${data.settings.display.placement}`);
    }

    if (state.display.advanced && state.display.advanced.class) {
      state.menuClass.push(state.display.advanced.class);
    }

    /* Do mobile */
    if (state.display.overwriteMobile && state.display.menuitem && state.display.menuitem.mobile) {
      if (state.display?.menuitem?.mobile?.fontSize) {
        state.display.menuitem.fontSize = state.display.menuitem.mobile.fontSize;
      }

      if (state.display?.menuitem?.mobile?.color) {
        state.display.menuitem.color = state.display.menuitem.mobile.color;
      }
    }
    /* End do mobile */

    state.elementStyling = {};
    if (state.display.logo.width || state.display.logo.height) {
      state.elementStyling.logoImageStyle = AddonMenuModel.constructLogoImageStyle(state);
    } else {
      state.elementStyling.logoImageStyle = undefined;
    }
    state.elementStyling.logoStyle = AddonMenuModel.constructLogoStyle(state);
    state.elementStyling.itemStyle = AddonMenuModel.constructItemStyle(state);

    state.elementStyling.itemStyleMobile = {};

    if (!state.display.overwriteMobile) {
      state.elementStyling.itemStyleMobile = {
        ...(state.display.menuitem.mobile?.fontSize && { fontSize: state.display.menuitem.mobile.fontSize + 'px' }),
        ...(state.display.menuitem.mobile?.color && { color: state.display.menuitem.mobile.color }),
        ...(state.display.menuitem?.padding?.top && { paddingTop: state.display.menuitem.padding.top + 'px' }),
        ...(state.display.menuitem?.padding?.right && { paddingRight: state.display.menuitem.padding.right + 'px' }),
        ...(state.display.menuitem?.padding?.bottom && { paddingBottom: state.display.menuitem.padding.bottom + 'px' }),
        ...(state.display.menuitem?.padding?.left && { paddingLeft: state.display.menuitem.padding.left + 'px' })
      };
    } else {
      state.elementStyling.itemStyleMobile = state.elementStyling.itemStyle;
    }

    const sectionId = this.getSection().state.id;
    state.elementStyling.cssStyling = AddonMenuModel.constructCSSStylingString(state, sectionId);
  }

  private static constructLogoImageStyle(state: AddonMenuState): CSSProperties {
    return {
      ...(state.display.logo?.width && { width: state.display.logo.width }),
      ...(state.display.logo?.height && { height: state.display.logo.height })
    };
  }

  private static constructLogoStyle(state: AddonMenuState): CSSProperties {
    return {
      ...(state.display.logo?.padding?.top && { paddingTop: state.display.logo.padding.top + 'px' }),
      ...(state.display.logo?.padding?.right && { paddingRight: state.display.logo.padding.right + 'px' }),
      ...(state.display.logo?.padding?.bottom && { paddingBottom: state.display.logo.padding.bottom + 'px' }),
      ...(state.display.logo?.padding?.left && { paddingLeft: state.display.logo.padding.left + 'px' })
    };
  }

  private static constructItemStyle(state: AddonMenuState): CSSProperties {
    return {
      ...(state.display.menuitem?.padding?.top && { paddingTop: state.display.menuitem.padding.top + 'px' }),
      ...(state.display.menuitem?.padding?.right && { paddingRight: state.display.menuitem.padding.right + 'px' }),
      ...(state.display.menuitem?.padding?.bottom && { paddingBottom: state.display.menuitem.padding.bottom + 'px' }),
      ...(state.display.menuitem?.padding?.left && { paddingLeft: state.display.menuitem.padding.left + 'px' }),
      ...(state.display.menuitem?.underlined && { textDecoration: 'underline' }),
      ...(state.display.menuitem?.fontSize && { fontSize: state.display.menuitem.fontSize + 'px' }),
      ...(state.display.menuitem?.color && { color: state.display.menuitem.color })
    };
  }

  private static constructCSSStylingString(state: AddonMenuState, sectionId: number): string {
    let CSSString = '';

    const CSSMobilePrefix = `.site--mobile.site .grid__addon--${state.id}`;
    const CSSTabletPrefix = `.site--tablet.site .grid__addon--${state.id}`;
    const CSSPrefix = `.category-desktop.category-landingpage .section--original-${sectionId}`;

    if (state.display) {
      if (state.display.menuitem) {
        if (state.display.menuitem.hoverColor) {
          CSSString += `
          ${CSSPrefix} .menubar__navigation .menubar__navigation-item--link .menubar__navigation-link:hover {
            color: ${state.display.menuitem.hoverColor} !important;
          }
        `;
        }

        if (state.display.seperatorColor) {
          CSSString += `
          ${CSSPrefix} .menubar__navigation .menubar__seperator {
            color: ${state.display.seperatorColor} !important;
          }
        `;
        }

        if (state.display.menuitem.fontSize) {
          CSSString += `
          ${CSSPrefix} .menubar__navigation .menubar__navigation-item--seperator .menubar__navigation-link {
            font-size: ${state.display.menuitem.fontSize}px !important;
          }
        `;
        }

        if (state.display.menuitem.mobile && !state.display.menuitem.mobile.overlayColor) {
          CSSString += `
          ${CSSMobilePrefix} .menubar__navigation,
          ${CSSTabletPrefix} .menubar__navigation {
            background-color: #000!important;
          }
        `;
        }

        if (state.display.overwriteMobile) {
          CSSString += `
            ${CSSMobilePrefix} .menubar__navigation,
            ${CSSTabletPrefix} .menubar__navigation {
              background-color: #ffffff !important;
            }
          `;
        }

        if (state.display.menuitem.mobile) {
          if (state.display.menuitem.mobile.iconBackgroundColor) {
            CSSString += `
            ${CSSMobilePrefix} .menubar__toggle,
            ${CSSTabletPrefix} .menubar__toggle {
              background-color: ${state.display.menuitem.mobile.iconBackgroundColor};
            }
          `;
          }

          if (state.display.menuitem.mobile.overlayColor) {
            CSSString += `
            ${CSSMobilePrefix} .menubar__navigation,
            ${CSSTabletPrefix} .menubar__navigation {
              background-color: ${state.display.menuitem.mobile.overlayColor} !important;
            }
          `;
          }

          if (state.display.menuitem.mobile.menuBarColor) {
            CSSString += `
            ${CSSMobilePrefix} .menubar button.menubar__toggle--open,
            ${CSSTabletPrefix} .menubar button.menubar__toggle--open {
              color: ${state.display.menuitem.mobile.menuBarColor} !important;
            }
          `;
          }
        }

        CSSString += `
        .site-mobile ${CSSPrefix} {
          overflow: visible !important;
          z-index: 499;
        }
      `;
      }
    }

    return CSSString;
  }
}
