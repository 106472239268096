import type { AdvancedAddonsStyleData, TypographyData } from '@/src/typings/interfaces/data/settings/settings';
import type { AlignContentType } from '@/src/typings/enums/enums';
import type { DeviceData } from '@/src/hooks/useDevice';

export enum AddonLeaderboardGroup {
  ALL = 'all',
  WINNERS = 'winners',
  LOSERS = 'losers'
}

export enum AddonLeaderboardGameType {
  SLICEIT = 'sliceit',
  QUIZ = 'quiz',
  DROPGAME = 'dropgame',
  SNAKE = 'snake',
  HITTHETARGET = 'hitthetarget',
  SHOOTIT = 'shootit',
  TAPPING = 'tapping',
  BOUNCE_BATTLE = 'bounce_battle',
  HIT_A_MOLE = 'hit_a_mole'
}

export enum AddonLeaderboardScoreKey {
  POINTS = 'points',
  POINTS_COLLECTED = 'points_collected',
  SCORE = 'score',
  CORRECT_ANSWERS = 'correct_answers',
  CLICKS = 'clicks'
}

export enum AddonLeaderboardRankingDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum AddonLeaderboardScoreType {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export enum AddonLeaderboardRankingType {
  SCORE = 'score',
  TIMEUSED = 'timeused',
  TIMEUSED_MS = 'timeused_ms',
  ACCUMULATED_SCORE = 'accumulated_score'
}

export interface LeaderboardTypographyData extends TypographyData {
  background_color?: string;
  highlight?: string;
  highlight_color?: string;
  highlight_background_color?: string;
  secondary?: string;
  secondary_color?: string;
  secondary_background_color?: string;
}

export interface LeaderboardEffectsData {
  enter_animation?: string;
  highlight_top3?: string;
  show_user_rank?: string;
  count_up_score?: string;
}

export interface LeaderboardPlacementIconData {
  enabled?: string;
  image?: string;
  width?: string;
}

export interface AddonSettingsData {
  name_field_id: string;
  unique_field_id: string;
  group: AddonLeaderboardGroup;
  score_type: AddonLeaderboardScoreType;
  ranking_type: AddonLeaderboardRankingType;
  limit?: string;
  min_score?: number;
  max_score?: number;
  headings?: {
    rank?: {
      label?: string;
      alignment?: AlignContentType;
    };
    name?: {
      label?: string;
      alignment?: AlignContentType;
    };
    score?: {
      label?: string;
      alignment?: AlignContentType;
    };
    timeused?: {
      label?: string;
      alignment?: AlignContentType;
    };
  };
  icons?: {
    first_place?: LeaderboardPlacementIconData;
    second_place?: LeaderboardPlacementIconData;
    third_place?: LeaderboardPlacementIconData;
    user_place?: LeaderboardPlacementIconData;
  };
  effects?: LeaderboardEffectsData;
  autoupdate?: {
    enabled?: string | boolean;
    time?: number;
  };
  layout: {
    heading?: DeviceData<LeaderboardTypographyData>;
    body?: DeviceData<LeaderboardTypographyData>;
  };
  advanced?: AdvancedAddonsStyleData;
}

export interface AddonLeaderboardData {
  alias: string;
  settings: AddonSettingsData;
}
