import FontFaceObserver from 'fontfaceobserver';

/**
 * HOW TO USE THIS IN VUE:
 *
 const {isFontLoaded, isFontsLoaded} = useFontObserver();

 let loadedSingleFont = await isFontLoaded('Open Sans');
 console.log('is single font loaded', loadedFont);

 let loadedMultipleFonts = await isFontsLoaded(['Open Sans', 'Roboto']);
 console.log('is all fonts loaded', loadedMultipleFonts);
 *
 */

interface fontConfig {
  style?: string;
  weight?: number;
  color?: string;
  size?: number;
}

export default function useFontObserver() {
  const isFontLoaded = async (font: string, config?: fontConfig) => {
    let loadFont: FontFaceObserver | undefined;

    if (config) {
      loadFont = new FontFaceObserver(font, config);
    } else {
      loadFont = new FontFaceObserver(font);
    }

    try {
      await loadFont.load();
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('[Playable] Unable to load font: ', font, 'with configuration:', config);
      return false;
    }
  };

  const isFontsLoaded = async (fonts: string[]) => {
    const fontPromises: Promise<void>[] = [];

    fonts.forEach((font) => {
      fontPromises.push(new FontFaceObserver(font).load());
    });

    try {
      await Promise.all<Promise<void>>(fontPromises.map((font) => font));
      return true;
    } catch (e) {
      throw new Error(`some fonts are not loaded: ${e}`);
    }
  };

  return {
    isFontLoaded,
    isFontsLoaded
  };
}
