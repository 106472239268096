<template>
  <component is="style" type="text/css">
    {{ state.css }}
  </component>

  <div v-if="state.text" class="lf-text" :style="state.elementStyling?.wrapper">
    <div class="lf-text__content">
      <component :is="asyncAddonLfTextEditor" v-if="isEditModeActive && isDemo" :model="model" />

      <RichTextRenderer
        v-if="!editorLoaded"
        :style="state?.elementStyling?.inline"
        :html="state.text"
        :truncate="state.settings?.truncate"
        :extra-replacement-tags="model.column?.replacementTags"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { watch } from 'vue';
import { ref } from 'vue';
import { defineAsyncComponent } from 'vue';
import { computed, defineComponent, onMounted } from 'vue';
import type { AddonLfTextModel } from '@/src/components/addons/lf-text/Model';
import RichTextRenderer from '@/src/components/RichTextRenderer.vue';
import useFontObserver from '@/src/hooks/useFontObserver';
import { useCampaignStore } from '@/src/store/campaign';
import { useUtilityStore } from '@/src/store/utility';

export default defineComponent({
  name: 'AddonLfText',
  components: { RichTextRenderer },
  props: {
    model: {
      type: Object as PropType<AddonLfTextModel>,
      required: true
    }
  },
  setup(props) {
    const state = props.model.state;
    const campaignStore = useCampaignStore();
    const utilityStore = useUtilityStore();
    const editorLoaded = ref(false);

    const isEditModeActive = computed(() => {
      return campaignStore.model?.state.isEditModeActive;
    });

    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const isDemo = computed(() => {
      return utilityStore.url.includes('/campaign/view/demo');
    });

    const asyncAddonLfTextEditor = defineAsyncComponent({
      loader: () =>
        import('@/src/components/addons/lf-text/Editor.vue').then((component) => {
          editorLoaded.value = true;
          return component;
        })
    });

    watch(
      () => isEditModeActive.value,
      () => {
        editorLoaded.value = isEditModeActive.value ?? false;
      }
    );

    onMounted(async () => {
      const { isFontLoaded } = useFontObserver();

      if (state.settings?.layout && state.settings.layout.typography?.fontFamily) {
        await isFontLoaded(state.settings.layout.typography.fontFamily);
      }

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }
    });

    return {
      editorLoaded,
      isDemo,
      asyncAddonLfTextEditor,
      state,
      isEditModeActive,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>

<style lang="scss">
.lf-text {
  width: 100%;

  img {
    display: inline-block;
  }
}
</style>
