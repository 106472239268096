import type { CSSProperties } from 'vue';
import { VisibilityConditionsModel } from '../conditions/VisibilityConditionsModel';
import { BaseModel } from '@/src/models/BaseModel';
import type {
  AdvancedAddonsStyleData,
  AdvancedElementData,
  AdvancedStyleData,
  PositionData,
  TransitionsData
} from '@/src/typings/interfaces/data/settings/settings';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { TransitionTypes } from '@/src/typings/types/types';
import {
  AdvancedHorizontalAlignmentType,
  AdvancedPositionType,
  AdvancedVerticalAlignmentType,
  AdvancedWidthType,
  CampaignAdsSizeType,
  CampaignDeviceType
} from '@/src/typings/enums/enums';
import { isValidValue } from '@/src/utilities/Utilities';
import { useCampaignStore } from '@/src/store/campaign';

interface AddonAdvancedElementState {
  zIndex?: number;
  elementId?: string;
  className?: string;
}

interface AddonAdvancedGenericState {
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  padding?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  width?: {
    type?: AdvancedWidthType;
    custom?: string;
  };
  minHeight?: string;
  horizontalAlignment?: string;
}

interface AddonAdvancedPositionState {
  position?: AdvancedPositionType;
  horizontal?: {
    offset: string;
    alignment?: AdvancedHorizontalAlignmentType;
  };
  vertical?: {
    offset: string;
    alignment?: AdvancedVerticalAlignmentType;
  };
}

interface AddonAdvancedTransitionsState {
  enabled: boolean;
  type?: TransitionTypes;
  duration?: number;
  delay?: number;
}

export interface AddonsAdvancedSettingsState {
  generic?: AddonAdvancedGenericState;
  advanced?: AddonAdvancedElementState;
  position?: AddonAdvancedPositionState;
  transitions?: AddonAdvancedTransitionsState;

  elementStyling?: {
    general: CSSProperties;
  };

  styling?: CSSProperties;

  visibilityConditions?: VisibilityConditionsModel;
}

export class AddonsAdvancedSettingsModel extends BaseModel<AdvancedAddonsStyleData, AddonsAdvancedSettingsState> {
  parse(data: AdvancedAddonsStyleData) {
    const state = this.state;

    // Create empty objects and set values
    if (data?.generic) {
      state.generic = AddonsAdvancedSettingsModel.parseGenericData(data);
    } else {
      state.generic = undefined;
    }

    if (data?.advanced) {
      state.advanced = AddonsAdvancedSettingsModel.parseAdvancedData(data);
    } else {
      state.advanced = undefined;
    }

    if (data?.position) {
      state.position = AddonsAdvancedSettingsModel.parsePositionData(data);
    } else {
      state.position = undefined;
    }

    if (data?.transitions) {
      state.transitions = AddonsAdvancedSettingsModel.parseTransitionData(data);
    } else {
      state.transitions = undefined;
    }

    if (data.visibility_condition || data.devices) {
      if (state.visibilityConditions) {
        state.visibilityConditions.setData({
          ...(data.visibility_condition ?? {}),
          devices: data.devices
            ? {
                desktop: data.devices.desktop,
                tablet: data.devices.desktop,
                mobile: data.devices.mobile
              }
            : undefined
        });
      } else {
        state.visibilityConditions = new VisibilityConditionsModel({
          ...(data.visibility_condition ?? {}),
          devices: data.devices
            ? {
                desktop: data.devices.desktop,
                tablet: data.devices.desktop,
                mobile: data.devices.mobile
              }
            : undefined
        });
      }
    } else {
      state.visibilityConditions = undefined;
    }

    state.styling = state.styling || {};

    state.styling = AddonsAdvancedSettingsModel.constructElementInlineStyles(state);
  }

  private static parseGenericData(data: AdvancedAddonsStyleData): AddonAdvancedGenericState | undefined {
    if (data?.generic) {
      const useData = getDeviceData(data.generic);

      if (!useData) {
        return undefined;
      }

      return AddonsAdvancedSettingsModel.constructGenericState(useData);
    }
  }

  private static constructGenericState(data: AdvancedStyleData): AddonAdvancedGenericState {
    let customWidth = data.width?.custom;

    if (
      data.width?.type === AdvancedWidthType.CUSTOM &&
      (typeof data.width.custom === 'undefined' || data.width.custom.length === 0)
    ) {
      customWidth = '100%';
    }

    return {
      margin: {
        ...(isValidValue(data.margin?.top) && {
          top: Number(data.margin?.top)
        }),
        ...(isValidValue(data.margin?.right) && {
          right: Number(data.margin?.right)
        }),
        ...(isValidValue(data.margin?.bottom) && {
          bottom: Number(data.margin?.bottom)
        }),
        ...(isValidValue(data.margin?.left) && {
          left: Number(data.margin?.left)
        })
      },
      padding: {
        ...(isValidValue(data.padding?.top) && {
          top: Number(data.padding?.top)
        }),
        ...(isValidValue(data?.padding?.right) && {
          right: Number(data.padding?.right)
        }),
        ...(isValidValue(data?.padding?.bottom) && {
          bottom: Number(data.padding?.bottom)
        }),
        ...(isValidValue(data?.padding?.left) && {
          left: Number(data.padding?.left)
        })
      },

      width: {
        ...(data.width?.type && { type: data.width?.type }),
        ...(customWidth && { custom: customWidth })
      },
      ...(data.min_height && { minHeight: data.min_height }),
      ...(data.horisontal_alignment && {
        horizontalAlignment: data.horisontal_alignment
      })
    };
  }

  private static parseAdvancedData(data: AdvancedAddonsStyleData): AddonAdvancedElementState | undefined {
    if (data.advanced) {
      return AddonsAdvancedSettingsModel.constructAdvancedState(data.advanced);
    }
    return undefined;
  }

  private static constructAdvancedState(data: AdvancedElementData): AddonAdvancedElementState {
    return {
      ...(data.zindex && { zIndex: Number(data.zindex) }),
      ...(data.element_id && { elementId: data.element_id }),
      ...(data.classname && { className: data.classname })
    };
  }

  private static parsePositionData(data: AdvancedAddonsStyleData): AddonAdvancedPositionState | undefined {
    if (data?.position) {
      const campaignStore = useCampaignStore();

      // Resolve issue with all devices device type default not having overwrite_mobile/tablet
      // checked. Here we have to add it manually if it's not present to resolve the issue.
      if (
        (campaignStore.model?.state.deviceType === CampaignDeviceType.ALL_DEVICES ||
          (campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
            campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.RESPONSIVE)) &&
        !data.position.desktop &&
        Object.keys(data.position).length > 0
      ) {
        data.position.desktop = {
          position: AdvancedPositionType.EMPTY,
          overwrite_mobile: '0',
          overwrite_tablet: '0'
        };
      }

      const useData = getDeviceData(data.position);

      if (!useData) {
        return undefined;
      }

      return AddonsAdvancedSettingsModel.constructPositionState(useData);
    }
  }

  private static constructPositionState(data: PositionData): AddonAdvancedPositionState {
    let horizontal = data.horizontal;
    let vertical = data.vertical;

    if (
      (data.position && data.position === AdvancedPositionType.FIXED) ||
      data.position === AdvancedPositionType.ABSOLUTE
    ) {
      if (!horizontal?.offset) {
        horizontal = {
          alignment: horizontal?.alignment ?? AdvancedHorizontalAlignmentType.LEFT,
          offset: '0px'
        };
      }

      if (!vertical?.offset) {
        vertical = {
          alignment: vertical?.alignment ?? AdvancedVerticalAlignmentType.TOP,
          offset: '0px'
        };
      }
    }

    return {
      ...(data.position && { position: data.position }),
      ...(horizontal && { horizontal }),
      ...(vertical && { vertical })
    };
  }

  private static parseTransitionData(data: AdvancedAddonsStyleData): AddonAdvancedTransitionsState | undefined {
    if (data?.transitions) {
      const useData = getDeviceData(data.transitions);

      if (!useData) {
        return undefined;
      }
      return AddonsAdvancedSettingsModel.constructTransitionState(useData);
    }
  }

  private static constructTransitionState(data: TransitionsData): AddonAdvancedTransitionsState {
    const transitions: AddonAdvancedTransitionsState = {
      enabled: false
    };

    if (data.enter && data.enter !== 'none') {
      transitions.enabled = true;
      transitions.type = data.enter;
      if (data.enter_delay) {
        transitions.delay = Number(data.enter_delay);
      }
      if (data.enter_duration) {
        transitions.duration = Number(data.enter_duration);
      }
    }

    return transitions;
  }

  private static constructElementInlineStyles(state: AddonsAdvancedSettingsState): CSSProperties | undefined {
    if (!state) return;

    const genericStyle = state.generic;
    const marginStyle = genericStyle?.margin;
    const paddingStyle = genericStyle?.padding;
    const advancedStyle = state.advanced;
    const positionStyle = state.position;

    let alignSelfValue = 'flex-start';
    switch (genericStyle?.horizontalAlignment) {
      case AdvancedHorizontalAlignmentType.LEFT:
        alignSelfValue = 'flex-start';
        break;
      case AdvancedHorizontalAlignmentType.RIGHT:
        alignSelfValue = 'flex-end';
        break;

      case AdvancedHorizontalAlignmentType.CENTER:
        alignSelfValue = 'center';
        break;
      default:
    }

    return {
      ...(isValidValue(marginStyle?.top) && {
        marginTop: `${marginStyle?.top}px`
      }),
      ...(isValidValue(marginStyle?.bottom) && {
        marginBottom: `${marginStyle?.bottom}px`
      }),
      ...(isValidValue(marginStyle?.right) && {
        marginRight: `${marginStyle?.right}px`
      }),
      ...(isValidValue(marginStyle?.left) && {
        marginLeft: `${marginStyle?.left}px`
      }),

      ...(isValidValue(paddingStyle?.top) && {
        paddingTop: `${paddingStyle?.top}px`
      }),
      ...(isValidValue(paddingStyle?.bottom) && {
        paddingBottom: `${paddingStyle?.bottom}px`
      }),
      ...(isValidValue(paddingStyle?.right) && {
        paddingRight: `${paddingStyle?.right}px`
      }),
      ...(isValidValue(paddingStyle?.left) && {
        paddingLeft: `${paddingStyle?.left}px`
      }),

      ...(positionStyle?.position && {
        position: positionStyle?.position,

        ...(positionStyle?.vertical &&
          (positionStyle.position === AdvancedPositionType.ABSOLUTE ||
            positionStyle.position === AdvancedPositionType.FIXED) && {
            ...(positionStyle.vertical.alignment !== AdvancedVerticalAlignmentType.BOTTOM && {
              top: positionStyle.vertical?.offset ? positionStyle.vertical.offset : 0,
              bottom: 'auto'
            }),
            ...(positionStyle.vertical.alignment === AdvancedVerticalAlignmentType.BOTTOM &&
              (positionStyle.position === AdvancedPositionType.ABSOLUTE ||
                positionStyle.position === AdvancedPositionType.FIXED) && {
                top: 'auto',
                bottom: positionStyle.vertical.offset ? positionStyle.vertical.offset : 0
              })
          }),
        ...(positionStyle?.horizontal && {
          ...(positionStyle.horizontal?.alignment !== AdvancedHorizontalAlignmentType.RIGHT &&
            (positionStyle.position === AdvancedPositionType.ABSOLUTE ||
              positionStyle.position === AdvancedPositionType.FIXED) && {
              left: positionStyle.horizontal?.offset ? positionStyle.horizontal.offset : 0,
              right: 'auto'
            }),
          ...(positionStyle.horizontal?.alignment === AdvancedHorizontalAlignmentType.RIGHT &&
            (positionStyle.position === AdvancedPositionType.ABSOLUTE ||
              positionStyle.position === AdvancedPositionType.FIXED) && {
              left: 'auto',
              right: positionStyle.horizontal?.offset ? positionStyle.horizontal.offset : 0
            })
        })
      }),

      ...(genericStyle?.minHeight && {
        minHeight: genericStyle.minHeight
      }),

      ...(genericStyle?.horizontalAlignment && {
        alignSelf: alignSelfValue
      }),

      ...(advancedStyle?.zIndex && {
        zIndex: advancedStyle.zIndex,
        position: 'relative'
      }),
      ...(genericStyle?.width?.type === AdvancedWidthType.CUSTOM && {
        width: genericStyle.width.custom
      })
    };
  }
}
