<template>
  <div class="lf-section__inner-overlay">
    <div class="lf-section__inner-overlay-headline">{{ cloneOfTitle }}</div>
    <div class="lf-section__inner-overlay-description">
      {{ cloneOfDescription }}
      <a :href="link" target="_blank">{{ cloneOfLink }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { getAdminUiEndpoint } from '@/src/utilities/Url';

export default defineComponent({
  name: 'SectionIsCloneMessage',
  props: {
    cloneOfTitle: {
      type: String,
      required: true,
      default: ''
    },
    cloneOfDescription: {
      type: String,
      required: true,
      default: ''
    },
    cloneOfLink: {
      type: String,
      required: true,
      default: ''
    },
    cloneId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  setup(props) {
    const link = computed(() => {
      return `${getAdminUiEndpoint()}/api/v1/campaign/page-global-redirect/${props.cloneId}`;
    });

    return {
      link
    };
  }
});
</script>
