<template>
  <div v-if="isVisible" class="menu animation animation--fade">
    <button type="button" class="menu__open btn btn--fab" @click="onOpenMenu">
      <i class="menu__open-icon fa fa-bars" aria-hidden="true"></i>
      <span class="sr-only">Open</span>
    </button>

    <transition name="popover" :appear="true">
      <div
        id="menuModal"
        class="menu__modal modal fade modal--page"
        role="dialog"
        data-backdrop="false"
        :class="{ show: isActive }"
      >
        <div class="menu__modal-dialog modal-dialog" role="document">
          <button type="button" class="menu__modal-close btn rounded-circle btn--fab" @click="onCloseMenu">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Close</span>
          </button>

          <ul class="navigation">
            <li v-for="(item, index) in pageItems" :key="index" class="navigation__item">
              <span class="navigation__liner"></span>

              <a
                class="navigation__label"
                :href="'#/?page=' + item.state.id"
                @click.prevent.stop="clickItem($event, item.state.id)"
              >
                {{ item.state.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import type { UnwrapRef } from 'vue';
import { defineComponent, ref } from 'vue';
import type { PopoverModel } from '@/src/models/PopoverModel';
import { useCampaignStore } from '@/src/store/campaign';
import { useUtilityStore } from '@/src/store/utility';

export default defineComponent({
  name: 'NavigationMenu',
  setup() {
    const campaignStore = useCampaignStore();
    const utilityStore = useUtilityStore();
    const isVisible = ref(false);
    const isActive = ref(false);
    const pageItems = ref<PopoverModel[]>([]);
    const shouldOpenPopoverOnClick = ref(false);
    const campaignState = campaignStore.model?.state;

    if (campaignState?.popovers && campaignState.popovers.length > 0) {
      campaignState.popovers.forEach((popover) => {
        const popoverState = popover.state;

        if (popoverState.config.menu && popoverState.config.menu.active) {
          pageItems.value.push(popover as UnwrapRef<PopoverModel>);
        }
      });

      if (pageItems.value && pageItems.value.length > 0) {
        isVisible.value = true;
      }
    }

    const clickItem = (e: MouseEvent, itemId: number) => {
      e.preventDefault();

      utilityStore.removeBodyClasses(['site--popover-active']);
      campaignStore.setActivePopover(itemId);
    };

    const onOpenMenu = () => {
      utilityStore.addBodyClasses(['site--popover-active']);
      shouldOpenPopoverOnClick.value = pageItems.value.length === 1;

      if (shouldOpenPopoverOnClick.value) {
        campaignStore.setActivePopover(pageItems.value[0].state.id);
      } else {
        isActive.value = true;
      }
    };

    const onCloseMenu = () => {
      utilityStore.removeBodyClasses(['site--popover-active']);
      isActive.value = false;
    };

    return {
      pageItems,
      clickItem,
      isVisible,
      isActive,
      onOpenMenu,
      onCloseMenu
    };
  }
});
</script>

<style lang="scss" scoped>
.modal-open {
  padding-right: 0 !important;
}

.menu {
  &__modal {
    display: block;
    background: #000;
    padding: 0 !important;

    &.fade {
      top: 100%;
      transition: opacity 300ms linear, top 300ms ease-out;
    }

    &.fade.show {
      top: 0;
      transition: opacity 300ms linear, top 300ms ease-out;
    }
  }

  &__modal-dialog {
    max-width: 100%;
    margin: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }

  .btn {
    transition: opacity 0.2s linear;
    position: fixed !important;
    bottom: size(30px);
    right: size(30px);
    width: size(60px);
    height: size(60px);
    opacity: 1;
    z-index: 50;
    border: 0 none;
    border-radius: 50%;
    color: #fff;
    background-color: $base-color;

    &:focus,
    &:hover {
      border: 0 none;
      box-shadow: none;
      background-color: darken($base-color, 15);
    }

    .fa {
      font-size: size(22px);
    }

    &.menu__modal-close {
      bottom: auto;
      top: size(30px);
      cursor: pointer;
    }

    .showing-ad & {
      opacity: 0;
      pointer-events: none;
    }
  }

  .navigation {
    transition: opacity 0.3s linear, transform 0.3s $transition-easing-transform;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    //TODO: Refactor to Vue
    &.ng-enter {
      transform: translate(-150%, -50%);
      opacity: 0;
    }

    &.ng-enter-active {
      transform: translate(-50%, -50%);
      opacity: 1;
    }

    &.ng-leave {
      transform: translate(-50%, -50%);
      opacity: 1;
    }

    &.ng-leave-active {
      transform: translate(-150%, -50%);
      opacity: 0;
    }

    &__item {
      display: block;
      width: 100%;
      position: relative;
      text-align: center;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        .navigation__liner {
          display: none;
        }
      }
    }

    &__liner {
      display: block;
      border-radius: 2px;
      position: absolute;
      bottom: 0;
      height: 2px;
      width: size(40px);
      left: 50%;
      margin-left: (-20px);
    }

    &__label {
      display: inline-block;
      text-align: center;
      font-size: size(36px);
      line-height: size(36px);
      padding: size(18px) 0;
      position: relative;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
</style>
