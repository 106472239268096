import type { GamePersonalityTestPersonalityState } from '../Model';

export class SdkPersonalityTestPersonalityModel {
  public readonly id: number;
  public readonly name: string | undefined;
  public readonly image: string | undefined;

  constructor(state: GamePersonalityTestPersonalityState) {
    this.id = state.id;
    this.name = state.name ? state.name : undefined;
    this.image = state.image ? state.image : undefined;
  }
}
