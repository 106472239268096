import type { CSSProperties } from 'vue';
import { useUtilityStore } from '@/src/store/utility';
import { useCampaignStore } from '@/src/store/campaign';
import type {
  AddonAgeGateData,
  AddonAgeGateHeadlineStyleSettingsData,
  AddonAgeGateSettingsData,
  AddonAgeGateStyleSettingsData
} from '@/src/components/addons/age-gate/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import {
  AgeGateCookieTimer,
  AgeGateFormatType,
  AgeGateGroupAliasType,
  AgeGateType
} from '@/src/components/addons/age-gate/enums';
import { AlignContentType, ButtonStyleType } from '@/src/typings/enums/enums';
import { generateUniqueId, replaceFontIdWithFontString } from '@/src/utilities/Utilities';

export interface AddonAgeGateState extends AddonModelState {
  settings: AddonAgeGateSettingsState;
  inlineStyle: AddonAgeGateInlineStyle<CSSProperties>;
  groups: AddonAgeGateDefaultDateGroups[];
  style: AddonAgeGateStylingState;
}

export interface AddonAgeGateSettingsState {
  age: number;
  type: AgeGateType;
  format?: AgeGateFormatType;
  formatYearFirst: boolean;
  cookiePeriod: number;
  showAll: boolean;
  skip: boolean;
  lock: boolean;
  flowPageId?: number;
  field?: number;
  label: AddonAgeGateLabel;
  placeholders?: DateLabels<string>;
  overwriteMobile?: boolean;
  headlineStyle?: AddonAgeGateHeadlineStyleSettingsState;
  overwriteHeadlineMobile?: boolean;
  overwritePlaceholderMobile?: boolean;
}

export interface AddonAgeGateInlineStyle<Style> {
  containerStyle?: Style;
  headlineStyle?: Style;
  valueStyle?: Style;
  boxStyle?: Style;
  placeholderStyle?: Style;
}

interface AddonAgeGateDateLabel {
  title: string;
  error: string;
}

interface DateLabels<Label> {
  year: Label;
  month: Label;
  day: Label;
}

interface AddonAgeGateLabel extends DateLabels<AddonAgeGateDateLabel> {
  oneline: string;
  error: string;
  locked: string;
  accept: {
    headline: string;
    yes: string;
    no: string;
  };
}

interface AddonAgeGateStylingState {
  width?: string;
  height?: string;
  fontFamily?: string;
  textSize?: string;
  textWeight?: number;
  textColor?: string;
  borderColor?: string;
  borderThickness?: string;
  buttonType?: ButtonStyleType;
  buttonAlignment?: AlignContentType;
}

interface AddonAgeGateHeadlineStyleSettingsState {
  fontFamily?: string;
  textSize?: number;
  textWeight?: number;
  textColor?: string;
}

export interface AddonAgeGateDefaultDateGroups {
  alias: AgeGateGroupAliasType;
  index: number;
  error: boolean;
  dates: AddonAgeGateDefaultDate[];
  active?: boolean;
  placeholder?: string;
}

export interface AddonAgeGateDefaultDate {
  id?: string;
  index: number;
  focus: boolean;
  value?: number;
  display?: string;
  placeholder?: string;
}

export class AddonAgeGateModel extends AddonModel<AddonAgeGateData, AddonAgeGateState, 'age-gate'> {
  parseAddon(data: AddonAgeGateData): void {
    const campaignStore = useCampaignStore();
    const utilityStore = useUtilityStore();

    const state = this.state;
    const isEditModeActive = campaignStore.model?.state.isEditModeActive ?? false;

    state.settings = state.settings || {};
    state.settings = AddonAgeGateModel.constructAgeGateSettingsState(data.settings);

    state.style = state.style || {};

    if (data.settings) {
      state.style = AddonAgeGateModel.parseAgeGateStyleSettingsState(data.settings);
    }

    state.inlineStyle = state.inlineStyle || {};
    state.inlineStyle.headlineStyle = AddonAgeGateModel.parseAgeGateHeadlineStyling(data.settings);
    state.inlineStyle.boxStyle = AddonAgeGateModel.constructBoxStyling(state.style);
    state.inlineStyle.valueStyle = AddonAgeGateModel.constructValueStyling(state.style);

    state.inlineStyle.placeholderStyle = AddonAgeGateModel.parseAgeGatePlaceholderSettingsState(data.settings);

    state.groups = AddonAgeGateModel.defaultDateGroups(state.settings, isEditModeActive);

    if (state.inlineStyle.headlineStyle?.fontFamily) {
      utilityStore.loadFont(state.inlineStyle.headlineStyle.fontFamily);
    }

    if (state.inlineStyle.boxStyle?.fontFamily) {
      utilityStore.loadFont(state.inlineStyle.boxStyle.fontFamily);
    }
  }

  private static constructAgeGateStyleSettingsState(data?: AddonAgeGateStyleSettingsData): AddonAgeGateStylingState {
    return {
      ...(data?.width && {
        width: data.width
      }),
      ...(data?.height && {
        height: data.height
      }),
      ...(data?.font_family && {
        fontFamily: replaceFontIdWithFontString(data.font_family)
      }),
      ...(data?.text_size && {
        textSize: data.text_size
      }),
      ...(data?.text_weight && {
        textWeight: Number(data.text_weight)
      }),
      ...(data?.text_color && {
        textColor: data.text_color
      }),
      ...(data?.border_color && {
        borderColor: data.border_color
      }),
      borderThickness: data?.border_thickness || '5px',
      buttonType: data?.button_type || ButtonStyleType.PRIMARY,
      buttonAlignment: data?.buttons_alignment || AlignContentType.CENTER
    };
  }

  private static parseAgeGateStyleSettingsState(data: AddonAgeGateSettingsData): AddonAgeGateStylingState {
    if (data.style) {
      if (useDevice().isMobile && data.overwrite_mobile === '0') {
        return AddonAgeGateModel.constructAgeGateStyleSettingsState(data.style.mobile);
      } else {
        return AddonAgeGateModel.constructAgeGateStyleSettingsState(data.style.desktop);
      }
    }
    return {};
  }

  private static parseAgeGateHeadlineStyling(data: AddonAgeGateSettingsData): CSSProperties {
    if (data.headine_style) {
      if (useDevice().isMobile && data.headine_style?.mobile) {
        return AddonAgeGateModel.constructHeadlineStyling(data.headine_style?.mobile);
      } else if (data.headine_style.desktop) {
        return AddonAgeGateModel.constructHeadlineStyling(data.headine_style.desktop);
      }
    }
    return {};
  }

  private static parseAgeGatePlaceholderSettingsState(data: AddonAgeGateSettingsData): CSSProperties | undefined {
    if (data.placeholder_style) {
      const useData = getDeviceData<{ text_color: string }>(data.placeholder_style);
      if (!useData) {
        return undefined;
      }
      if (useData.text_color) {
        return { color: useData.text_color, opacity: 1 };
      }
    }
  }

  private static constructAgeGateSettingsState(data: AddonAgeGateSettingsData): AddonAgeGateSettingsState {
    const label: AddonAgeGateLabel = {
      year: {
        title: data.label?.year?.title || 'Enter year you were born',
        error: data.label?.year?.error || 'Please enter a valid year'
      },
      month: {
        title: data.label?.month?.title || 'Enter month you were born',
        error: data.label?.month?.error || 'Please enter a valid month'
      },
      day: {
        title: data.label?.day?.title || 'Enter day you were born',
        error: data.label?.day?.error || 'Please enter a valid day'
      },
      error: data.label?.error || "You're not old enough",
      locked: data.label?.locked || "You're locked",
      accept: {
        headline: data?.label?.accept?.headline || 'Are you over 18?',
        yes: data.label?.accept?.yes || 'Yes',
        no: data.label?.accept?.no || 'No'
      },
      oneline: data.label?.oneline || 'Date Headline'
    };

    let cookiePeriod = 0;

    if (data.cookies) {
      switch (data.cookies) {
        case AgeGateCookieTimer.DAY:
          cookiePeriod = 1;
          break;
        case AgeGateCookieTimer.WEEK:
          cookiePeriod = 7;
          break;
        case AgeGateCookieTimer.MONTH:
          cookiePeriod = 30;
          break;
        default:
          break;
      }
    }

    return {
      age: Number(data.age) ?? 18,
      type: data.type || AgeGateType.YEAR,
      cookiePeriod,
      showAll: data.show_all === '1',
      skip: data.skip === '1',
      lock: data.lock === '1',
      ...{ label },
      ...(data.flow_page && { flowPageId: Number(data.flow_page) }),
      ...(data.field && { field: Number(data.field) }),
      ...(data?.placeholders && { placeholders: data.placeholders }),
      ...(data.type === AgeGateType.DATE && {
        format: data.format || AgeGateFormatType.MONTHDAY
      }),
      formatYearFirst: data.format === AgeGateFormatType.YEARMONTH
    };
  }

  private static constructHeadlineStyling(data: AddonAgeGateHeadlineStyleSettingsData): CSSProperties {
    return {
      ...(data.text_size && { fontSize: data.text_size }),
      ...(data.text_weight && { fontWeight: Number(data.text_weight) }),
      ...(data.text_color && { color: data.text_color }),
      ...(data.font_family && {
        fontFamily: `"${replaceFontIdWithFontString(data.font_family)}", "Helvetica Neue", Arial, "sans-serif"`
      })
    };
  }

  private static constructBoxStyling(style: AddonAgeGateStylingState): CSSProperties {
    return {
      ...(style.borderColor && { border: style.borderThickness + ' solid ' + style.borderColor }),
      ...(style.textColor && { color: style.textColor }),
      ...(style.textSize && { fontSize: style.textSize }),
      ...(style.textWeight && { fontWeight: style.textWeight }),
      ...(style.width && { width: style.width }),
      ...(style.height && { height: style.height }),
      ...(style.fontFamily && {
        fontFamily: `"${style.fontFamily}", "Helvetica Neue", Arial, "sans-serif"`
      })
    };
  }

  private static constructValueStyling(style: AddonAgeGateStylingState): CSSProperties {
    return {
      ...(style.borderColor && { backgroundColor: style.borderColor })
    };
  }

  private static formatGroups(
    groups: AddonAgeGateDefaultDateGroups[],
    placeholders?: DateLabels<string | undefined>
  ): AddonAgeGateDefaultDateGroups[] {
    return groups.map((group) => {
      const placeholder = placeholders ? placeholders[group.alias] : undefined;
      return {
        alias: group.alias,
        index: group.index,
        error: group.error,
        dates: group.dates.map((date, index) => {
          return {
            id: generateUniqueId(),
            index,
            display: date.display,
            placeholder: placeholder ? placeholder.split('')[Number(index)] : undefined,
            value: group.dates[Number(index)].placeholder ? undefined : group.dates[Number(index)].value || undefined,
            focus: date.focus
          };
        })
      };
    });
  }

  private static defaultDateGroups(
    settings: AddonAgeGateSettingsState,
    isEditModeActive: boolean
  ): AddonAgeGateDefaultDateGroups[] {
    const groups: AddonAgeGateDefaultDateGroups[] = [
      {
        alias: AgeGateGroupAliasType.YEAR,
        active: true,
        index: 0,
        error: false,
        dates: [
          {
            id: generateUniqueId(),
            index: 0,
            focus:
              AgeGateType.YEAR &&
              settings.format !== AgeGateFormatType.DAYMONTH &&
              settings.format !== AgeGateFormatType.MONTHDAY &&
              !isEditModeActive
            // ...(settings.type === AgeGateType.YEAR &&
            //   (!settings.placeholders || settings.placeholders?.year === '') && { value: 1 }),
            // ...(settings.type === AgeGateType.YEAR &&
            //   (!settings.placeholders || settings.placeholders?.year === '') && { display: '1' })
          },
          {
            id: generateUniqueId(),
            index: 1,
            focus: false
            // ...(settings.type === AgeGateType.YEAR &&
            //   (!settings.placeholders || settings.placeholders?.year === '') && { value: 9 }),
            // ...(settings.type === AgeGateType.YEAR &&
            //   (!settings.placeholders || settings.placeholders?.year === '') && { display: '9' })
          },
          {
            id: generateUniqueId(),
            index: 2,
            focus: false
            // focus: settings.type === AgeGateType.YEAR && !settings.placeholders
          },
          {
            id: generateUniqueId(),
            index: 3,
            focus: false
          }
        ]
      },
      {
        alias: AgeGateGroupAliasType.MONTH,
        error: false,
        index: 1,
        dates: [
          {
            id: generateUniqueId(),
            index: 4,
            focus: settings.format === AgeGateFormatType.MONTHDAY && !isEditModeActive
          },
          {
            id: generateUniqueId(),
            index: 5,
            focus: false
          }
        ]
      },
      {
        alias: AgeGateGroupAliasType.DAY,
        error: false,
        index: 2,
        dates: [
          {
            id: generateUniqueId(),
            index: 6,
            focus: settings.format === AgeGateFormatType.DAYMONTH && !isEditModeActive
          },
          {
            id: generateUniqueId(),
            index: 7,
            focus: false
          }
        ]
      }
    ];

    // reorder groups if the format is changed

    if (settings.format === AgeGateFormatType.MONTHDAY || settings.format === AgeGateFormatType.DAYMONTH) {
      if (settings.format === AgeGateFormatType.MONTHDAY) {
        const year = groups.shift();

        if (year) {
          groups.push(year);
        }
      } else {
        groups.reverse();
      }
    }

    return AddonAgeGateModel.formatGroups(groups, settings.placeholders);
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    return !!data.settings.age;
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
