import type { Component } from 'vue';

import type { IntegrationEspEloquaData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationEspEloquaState extends BaseIntegrationState {
  endpoint?: string;
  purl?: boolean;
  purlWait?: boolean;
}

export class IntegrationEspEloquaModel extends BaseIntegration<IntegrationEspEloquaData, IntegrationEspEloquaState> {
  parse(data: IntegrationEspEloquaData) {
    super.parse(data);
    this.state.endpoint = data.settings?.endpoint;
    this.state.purl = !!data.settings?.purl;
    this.state.purlWait = !!data.settings?.purl_wait;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/esp/eloqua/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Dannie Hansen';
  }
}
