import { createApp } from 'vue';
import './assets/scss/main.scss';
import App from './App.vue';
import { router } from './router';
import LfSection from './components/global/LFSection.vue';

import RegisterClickAway from './plugins/click-away';
import RegisterPinia from './plugins/pinia';
import RegisterSentry from './plugins/sentry';
import RegisterToast from './plugins/toast';
import { createHead } from '@unhead/vue';

declare global {
  interface Window {
    PLAYABLE_CAMPAIGN?: string;
  }
}

const app = createApp(App);

RegisterClickAway(app);
RegisterPinia(app);
RegisterSentry(app);
RegisterToast(app);

app.component('LFSection', LfSection);

const head = createHead();
app.use(head);

app.use(router);
app.mount('#app');
