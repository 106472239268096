import { BaseModel } from '@/src/models/BaseModel';
import type { DeviceData } from '@/src/hooks/useDevice';
import useDevice from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { GameIndicator } from '@/src/models/GameModel';

export enum GameIndicatorDirectionTypes {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export enum GameIndicatorVAlignTypes {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom'
}

export enum GameIndicatorHAlignTypes {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

enum GameIndicatorTypes {
  SCORE_USED = 'score_used',
  SCORE_LEFT = 'score_left',
  TIME_USED = 'time_used',
  TIME_LEFT = 'time_left',
  SPINS_LEFT = 'spins_left',
  SPINS_USED = 'spins_used',
  WINS_USED = 'wins_used',
  ROUND_USED = 'round_used',
  TRIES_USED = 'tries_used',
  TRIES_LEFT = 'tries_left',
  MOVES_USED = 'moves_used',
  MOVES_LEFT = 'moves_left',
  POINTS_USED = 'points_used',
  POINTS_LEFT = 'points_left'
}

enum GameIndicatorPositionTypes {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  CENTER_LEFT = 'center-left',
  CENTER_CENTER = 'center-center',
  CENTER_RIGHT = 'center-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right'
}
export enum GameIndicatorIcon {
  SPINS = 'spins',
  ROUNDS = 'rounds',
  WINS = 'wins',
  TRIES = 'tries',
  TIME = 'time',
  SCORE = 'score',
  MOVES = 'moves',
  POINTS = 'points'
}

export enum GameIndicatorPositionType {
  DEFAULT,
  ABSOLUTE,
  RELATIVE
}

export interface GameIndicatorData {
  [key: string]: DeviceData<GameIndicatorTypeData>;
}

export interface GameIndicatorTimeLabel {
  minute: string;
  minutes: string;
  second: string;
  seconds: string;
}

export type GameIndicatorValueDisplay = 'value' | 'value_circle' | 'value_in_minutes';
/**
 * Data is combined Layout and Indicators as they have to be handle the same way, and we can use generics.
 */
export interface GameIndicatorTypeData {
  enabled: boolean;
  type: GameIndicatorTypes;
  position?: GameIndicatorPositionTypes;
  value: number;
  label?: string;
  icon?: boolean;
  labels?: GameIndicatorTimeLabel;
  font_type?: string;
  font_size?: string;
  dynamic?: boolean;
  value_display_mode: GameIndicatorValueDisplay;
  valign?: GameIndicatorVAlignTypes;
  halign?: GameIndicatorHAlignTypes;
  color?: string;
  direction?: GameIndicatorDirectionTypes;
}

export interface GameIndicatorState {
  layout?: GameIndicatorLayoutState;
  indicators: GameIndicatorTypeState[];
  position: GameIndicatorPosition;
}

export enum GameIndicatorValueDisplayModes {
  VALUE,
  VALUE_CIRCLE,
  VALUE_IN_MINUTES
}

export interface GameIndicatorTypeState {
  enabled: boolean;
  type: GameIndicatorTypes;
  value: number;
  key: string;
  position?: GameIndicatorPositionTypes;
  label?: string;
  valueDisplayMode: GameIndicatorValueDisplayModes;
  timeLabels?: GameIndicatorTimeLabel;
  icon?: GameIndicatorIcon;
  dynamic?: boolean;
  valign?: GameIndicatorVAlignTypes;
  halign?: GameIndicatorHAlignTypes;
}

export interface GameIndicatorPosition {
  top: GameIndicatorPositionType;
  bottom: GameIndicatorPositionType;
}

export interface GameIndicatorLayoutState {
  color?: string;
  direction?: GameIndicatorDirectionTypes;
  fontFamily?: string;
  fontSize?: string;
}

export class GameIndicatorSettingsModel extends BaseModel<GameIndicatorData, GameIndicatorState> {
  parse() {
    const state = this.state;
    state.indicators = [];
  }

  private static constructIndicatorsState(
    data: GameIndicatorTypeData,
    gameData: GameIndicator
  ): GameIndicatorTypeState {
    const valueDisplayModeEnum = this.mapValueDisplayModeToEnum(data.value_display_mode);

    return {
      enabled: data.enabled || false,
      type: data.type,
      ...(data.position && { position: data.position }),
      ...(data.label && { label: data.label }),
      ...(data.dynamic && { dynamic: data.dynamic }),
      ...(data.valign && { valign: data.valign }),
      ...(data.halign && { halign: data.halign }),
      ...(data.icon && { icon: gameData.icon }),
      ...(data.labels && { timeLabels: data.labels }),
      value: data.value,
      key: gameData.metricKey[data.type],
      valueDisplayMode: valueDisplayModeEnum // Include the enum value in the state
    };
  }

  private static mapValueDisplayModeToEnum(mode: GameIndicatorValueDisplay): GameIndicatorValueDisplayModes {
    switch (mode) {
      case 'value':
        return GameIndicatorValueDisplayModes.VALUE;
      case 'value_circle':
        return GameIndicatorValueDisplayModes.VALUE_CIRCLE;
      case 'value_in_minutes':
        return GameIndicatorValueDisplayModes.VALUE_IN_MINUTES;
      default:
        return GameIndicatorValueDisplayModes.VALUE;
    }
  }

  private static constructLayoutState(data: GameIndicatorTypeData): GameIndicatorLayoutState | undefined {
    return {
      ...(data.color && { color: data.color }),
      ...(data.direction && { direction: data.direction }),
      ...(data.font_type && { fontFamily: data.font_type }),
      ...(data.font_size && { fontSize: data.font_size })
    };
  }

  // Set the position for the indicator for each game
  public setIndicatorPosition(position: GameIndicatorPosition): void {
    this.state.position = position;
  }

  setAvailableIndicators(indicators: GameIndicator[]): void {
    if (this.getData().layout) {
      const data = getDeviceData<GameIndicatorTypeData>(this.getData().layout);
      if (data) {
        this.state.layout = GameIndicatorSettingsModel.constructLayoutState(data);
      }
    }

    indicators.forEach((indicator) => {
      const indicatorData = this.getData()[indicator.indicatorKey];

      // data looks a bit different when not enabled and desktop object is the one that defines thats it actually enabled.
      if (indicatorData && (indicatorData.desktop || indicatorData.default)) {
        const data = getDeviceData<GameIndicatorTypeData>(indicatorData);

        if (data) {
          const indicatorItem = GameIndicatorSettingsModel.constructIndicatorsState(data, indicator);

          this.state.indicators.push(indicatorItem);
          return;
        }
      }

      // If the indicator is not enabled on desktop, but enabled on mobile or tablet, we need to check for that.
      if (indicatorData && !indicatorData.desktop && useDevice().isMobile && indicatorData.mobile) {
        const data = indicatorData.mobile;
        if (data) {
          const indicatorItem = GameIndicatorSettingsModel.constructIndicatorsState(data, indicator);

          this.state.indicators.push(indicatorItem);
          return;
        }
      }

      if (indicatorData && !indicatorData.desktop && useDevice().isTablet && indicatorData.tablet) {
        const data = indicatorData.tablet;

        if (data) {
          const indicatorItem = GameIndicatorSettingsModel.constructIndicatorsState(data, indicator);

          this.state.indicators.push(indicatorItem);
          return;
        }
      }
    });
  }
}
