<template>
  <div class="content__item content__item--text">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContentText'
});
</script>

<style lang="scss">
//Moved styling to own .scss component, as we have other "hardcoded"
//HTML who depends on the styling from this component
//However, it can occour that the text component isn't loaded, as it's async
</style>
