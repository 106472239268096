import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 283.5 19.6",
  preserveAspectRatio: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      class: "elementor-shape-fill",
      style: {"opacity":"0.33"},
      d: "M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"
    }, null, -1),
    _createElementVNode("path", {
      class: "elementor-shape-fill",
      style: {"opacity":"0.33"},
      d: "M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"
    }, null, -1),
    _createElementVNode("path", {
      class: "elementor-shape-fill",
      style: {"opacity":"0.33"},
      d: "M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"
    }, null, -1),
    _createElementVNode("path", {
      class: "elementor-shape-fill",
      d: "M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"
    }, null, -1)
  ])))
}
export default { render: render }