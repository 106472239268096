import type { IntegrationLobycoData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationLobcyoState extends BaseIntegrationState {
  enableFakeRegistration: boolean;
  isInstantWinGame: boolean;
  validRedirectDomains: string[];
}

export class IntegrationLobycoModel extends BaseIntegration<IntegrationLobycoData, IntegrationLobcyoState> {
  parse(data: IntegrationLobycoData) {
    super.parse(data);

    this.state.enableFakeRegistration = data.settings?.coop?.enable_fake_registration === '1';
    this.state.isInstantWinGame = !!data.settings?.coop?.isInstantWinGame;

    if (data.settings?.coop?.valid_redirect_domains && Array.isArray(data.settings?.coop?.valid_redirect_domains)) {
      this.state.validRedirectDomains = data.settings?.coop?.valid_redirect_domains;
    } else {
      this.state.validRedirectDomains = [];
    }
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }
}
