import type { Component } from 'vue';
import type { IntegrationSportsAllianceData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationSportsAllianceState extends BaseIntegrationState {
  fieldsMapped: number[];
  loginUrl?: string;
}

export class IntegrationSportsAllianceModel extends BaseIntegration<
  IntegrationSportsAllianceData,
  IntegrationSportsAllianceState
> {
  parse(data: IntegrationSportsAllianceData) {
    super.parse(data);

    this.state.fieldsMapped = data.settings?.sports_alliance_fields_mapped ?? [];
    this.state.loginUrl = data.settings?.sports_alliance_login_url;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/sports-alliance/View.vue');
  }

  hasVueComponent() {
    return true;
  }
}
