import { useEditingStore } from '@/src/store/editing';
import { useClipboardStore } from '@/src/store/clipboard';
import { useCampaignStore } from '@/src/store/campaign';
import { BaseModel } from '@/src/models/BaseModel';
import type { ContentAddon } from '@/src/components/layout/column/ColumnModel';
import type ColumnModel from '@/src/components/layout/column/ColumnModel';
import { AddonsAdvancedSettingsModel } from '@/src/models/settings/AddonsAdvancedSettingsModel';
import type { AddonEditState, AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { CustomCSSModel } from '@/src/models/CustomCSSModel';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { CustomCSS } from '@/src/typings/interfaces/data/settings/settings';
import { createEditFormUrl, generateUniqueId } from '@/src/utilities/Utilities';
import { SectionType } from '@/src/typings/enums/enums';
import useAxios from '@/src/hooks/useAxios';
import { Cache } from '@/src/services/cache';

/**
 * Author: Dannie Hansen | Sebastian Jakobsen | Nicky Christensen
 */
export default abstract class AddonModel<Data, State, Alias extends string> extends BaseModel<
  Data & ContentAddon<Alias>,
  State & AddonModelState
> {
  public readonly campaignId?: number;
  public column?: ColumnModel;

  private deleted = false;

  constructor(data: Data & ContentAddon<Alias>, column?: ColumnModel) {
    super(data);
    this.column = column;
    this.campaignId = column?.campaignId;

    const reservedIds = Cache.get<string[]>('reserved-addon-ids') ?? [];

    // Conflict found or no addon id exists. Some other addon already uses this ID.
    // We need to refresh it to avoid conflicts with style or other functionality.
    if (!data.id || reservedIds.includes(data.id)) {
      data.id = generateUniqueId();
    }

    reservedIds.push(data.id);
    Cache.set('reserved-addon-ids', reservedIds);

    this.parse(data);
  }

  parse(data: Data & ContentAddon<Alias>) {
    const campaignStore = useCampaignStore();

    const state = this.state;
    state.modelId = this.modelId;
    state.alias = data.alias;

    // Constructor handles if no data id exists. But to satisfy the typing we need to also handle it here..
    state.id = data.id ?? generateUniqueId();
    state.classIdentifier = `grid__addon--${state.id}`;
    state.addonValid = this.isAddonValid();

    const editState = campaignStore.model?.state.edit;

    if (!state.edit && editState?.enabled) {
      state.edit = {} as AddonEditState;
      state.edit.icon = '';
      state.edit.label = data.label ?? '';
      state.edit.isActive = false;
      state.edit.isFocus = false;
      state.edit.title = this.getAddonTitle();
    }

    if (data?.settings?.advanced) {
      if (state.genericAdvancedSettings) {
        state.genericAdvancedSettings.setData(data.settings.advanced);
      } else {
        state.genericAdvancedSettings = new AddonsAdvancedSettingsModel(data.settings.advanced);
      }
    }

    if (data.settings?.advanced?.generic?.customcss) {
      state.customCss = this.parseCustomCSSData(data, state.classIdentifier);
    }

    this.parseAddon(data);
  }

  public isAddonValid(): boolean {
    return true;
  }

  public getEditUrl() {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    const editFormUrl = `/edit/campaign/addon/${this.campaignId}/${this.column.sectionId}?row_index=${this.column.rowIndex}&col_index=${this.column.index}&addon_index=${this.index}`;
    return createEditFormUrl(editFormUrl);
  }

  public getAddonTitle(): string {
    const campaignStore = useCampaignStore();
    const addonsData = campaignStore.model?.state.edit?.addons;

    if (addonsData) {
      return addonsData[this.state.alias]?.name;
    }

    return this.state.alias;
  }

  public abstract parseAddon(data: Data): void;

  public getValueRoot(): string {
    return 'settings';
  }

  public getSection() {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    return this.column.row.section;
  }

  canDelete(): boolean {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    return this.column.row.section.canDelete();
  }

  canOpenSectionSettings() {
    return this.getSection().getSectionType() === SectionType.FLOWPAGE;
  }

  canOpenGameSettings() {
    return this.getSection().getSectionType() === SectionType.FLOWPAGE;
  }

  openSectionSettings() {
    this.getSection().openSectionSettings();
  }

  canCopy(): boolean {
    return !this.state.alias.includes('game');
  }

  canDuplicate(): boolean {
    return !!(this.index || (this.index === 0 && this.column));
  }

  canPaste(): boolean {
    const clipboardStore = useClipboardStore();
    const editingStore = useEditingStore();

    const modelSection = editingStore.activeModel?.getSection();

    if (modelSection?.state?.config?.hasRegistrationAddon) {
      return false;
    }

    return clipboardStore.elementInClipboard instanceof AddonModel;
  }

  canMoveUp(): boolean {
    return this.index > 0;
  }

  onMoveUp() {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    this.column.onMoveAddonUp(this.index);
  }

  canMoveDown(): boolean {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    const col = this.column;
    const colState = col.state;

    return this.index < colState.addons.length - 1;
  }

  onMoveDown() {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    this.column.onMoveAddonDown(this.index);
  }

  onPasteElement() {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    const clipboardStore = useClipboardStore();
    const addonData = clipboardStore.dataInClipboard;

    if (addonData) {
      this.column.onPasteAddon(addonData, this.index);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onDelete() {}

  // Delete addon
  async delete() {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    if (this.deleted) {
      return;
    }

    this.deleted = true;
    await this.column.deleteAddon(this.index);
  }

  async saveBlock() {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    return this.column.row.section.saveBlock();
  }

  private parseCustomCSSData(data: ContentAddon<Alias>, identifier: string): CustomCSSModel | undefined {
    if (data.settings?.advanced?.generic?.customcss) {
      const useData = getDeviceData(data.settings.advanced.generic.customcss);

      if (!useData) {
        return undefined;
      }

      if (useData.code && useData.code.length > 0) {
        return this.constructCustomCSSState(useData, identifier);
      }

      return undefined;
    }
  }

  private constructCustomCSSState(data: CustomCSS, identifier: string): CustomCSSModel {
    return new CustomCSSModel(data, identifier);
  }

  public shallSkipInitialParse(): boolean {
    return true;
  }

  public setEditingActive(): void {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    if (this.state.edit) {
      this.state.edit.isActive = true;
      this.state.edit.isCollapsed = false;
    }

    if (this.column.state.edit) {
      this.column.state.edit.isCollapsed = false;
    }
    if (this.column.row.state.edit) {
      this.column.row.state.edit.isCollapsed = false;
    }

    if (this.column.row.section.state.edit) {
      this.column.row.section.state.edit.isCollapsed = false;
    }
  }

  public async callAddonHook<ResponseData>(hook: string, formData?: object): Promise<ResponseData> {
    if (!this.column) {
      throw new Error('Missing column context for addon');
    }

    const campaignStore = useCampaignStore();

    const hookUrl = `${
      campaignStore.model?.state?.config?.campaignApiRoot ?? ''
    }/api/v1/campaign/addonHook?hook=${hook}&campaign_id=${campaignStore.model?.id}&page_id=${
      this.getSection().id
    }&row_index=${this.column.row.index}&col_index=${this.column.index}&addon_index=${this.index}`;

    const { postDataFormData } = useAxios<ResponseData>(hookUrl, formData);

    return await postDataFormData();
  }

  public get index() {
    // @ts-ignore
    const index = this.column.state.addons.indexOf(this);

    if (index === undefined) {
      throw new Error(`Could not find addon index in column addons`);
    }

    return index;
  }

  public get alias() {
    return this.getData().alias;
  }
}
