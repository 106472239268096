<template>
  <teleport to="#app">
    <p-modal-plain
      :ready="contentAddModalReady"
      :show.prop="true"
      :headline="modalTitle"
      position="top"
      @close-request="$emit('close-request')"
    >
      <p-container align-items="flex-start">
        <p-row justify-content="space-between">
          <p-tabs v-if="visibleTabs && visibleTabs.length > 1">
            <p-tabs-item
              v-for="tab in visibleTabs"
              :key="tab.id ?? tab.label"
              :selected.prop="tab.label?.toLocaleLowerCase() === selectedTab"
              :text="tab.label"
              size="medium"
              :disabled.prop="isLoading"
              @select="setActiveTab(tab)"
            />
          </p-tabs>

          <p-row v-if="initialLoadDone" class="content-filtering" gap="small">
            <p-input
              v-if="selectedTab !== 'grid'"
              :value="search"
              :disabled.prop="isLoading || (defaultParameters.show === 'copy' && defaultParameters.subcategory !== '')"
              placeholder="Search"
              @change="search = $event.detail[0]"
            />

            <p-select
              v-if="categories.length > 1"
              searchable
              :disabled.prop="isLoading || (defaultParameters.show === 'copy' && defaultParameters.subcategory !== '')"
              @select="onUpdateCategory($event.detail[0])"
            >
              <p-select-option
                v-for="category in categories"
                :key="category.id"
                :value="String(category.id)"
                :selected.prop="String(category.id) === selectedCategory"
                >{{ category.label }}</p-select-option
              >
            </p-select>

            <p-button v-if="defaultParameters.show === 'copy' && defaultParameters.subcategory" @click="handleGoBack"
              >Back</p-button
            >
          </p-row>
        </p-row>

        <template v-if="!isLoading">
          <template v-if="filteredBlocks.length > 0">
            <p-row v-if="type === 'blocks'" gap="small" align-items="stretch">
              <p-card
                v-for="block in filteredBlocks"
                :key="block.label"
                :headline="block.label"
                :image="block.image"
                :icon="block.icon"
                can-select
                @click="onAddBlock(block)"
              />
            </p-row>
            <p-row v-else gap="small" align-items="stretch">
              <p-card
                v-for="block in filteredBlocks"
                :key="block.label"
                :headline="block.label"
                :image="block.image"
                :icon="block.icon"
                can-select
                @click="
                  defaultParameters.show === 'copy' && !defaultParameters.subcategory
                    ? handleRefetchBlocks(String(block.id))
                    : onAddItem(block)
                "
              />
            </p-row>
          </template>
          <p-message v-else type="info" display="inline" description="No results found" />
        </template>
        <template v-else>
          <p-container align-items="center">
            <p-loading size="extra-large" />
          </p-container>
        </template>
      </p-container>
    </p-modal-plain>

    <p-modal-loader v-if="isAddingItem" />
  </teleport>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import useAxios from '@/src/hooks/useAxios';
import { stringToSlug } from '@/src/utilities/Utilities';
import type { SectionModelType } from '@/src/typings/types/types';
import { BlockTypes } from '@/src/typings/types/types';
import type {
  APIBlock,
  APICategory,
  APIResponse,
  TabItem
} from '@/src/components/components/editing/Modals/interfaces';
import type { ContentColumnData } from '@/src/typings/interfaces/data/grid/column';
import { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import { useCampaignStore } from '@/src/store/campaign';
import { useEditingStore } from '@/src/store/editing';
import { getAdminUiEndpoint } from '@/src/utilities/Url';
import { CampaignDeviceType, SectionType } from '@/src/typings/enums/enums';

const tabItemsSections: TabItem[] = [
  {
    id: 1,
    label: 'Grid',
    show: 'grid',
    placement: 'under',
    relative: 'no-sections'
  },
  {
    id: 2,
    label: 'Reuse section',
    show: 'copy',
    placement: 'under',
    relative: 'no-sections'
  },
  {
    id: 3,
    label: 'Templates',
    show: 'templates',
    placement: 'under',
    relative: 'no-sections'
  },
  {
    id: 4,
    label: 'Global',
    show: 'global',
    placement: 'under',
    relative: 'no-sections'
  }
];
const tabItemsRows: TabItem[] = [
  {
    id: 1,
    label: 'Grid',
    show: 'grid',
    placement: 'under',
    relative: 'no-sections'
  }
];
const tabItemsFlow: TabItem[] = [
  {
    id: 1,
    label: 'Grid',
    show: 'grid'
  },
  {
    id: 2,
    label: 'Reuse flow page',
    show: 'copy'
  },
  {
    id: 3,
    label: 'Templates',
    show: 'templates'
  },
  {
    id: 4,
    label: 'Global',
    show: 'global'
  }
];
const tabItemsPopovers: TabItem[] = [
  {
    id: 1,
    label: 'Grid',
    show: 'grid'
  },
  {
    id: 2,
    label: 'Reuse popover',
    show: 'copy'
  },
  {
    id: 3,
    label: 'Templates',
    show: 'templates'
  },
  {
    id: 4,
    label: 'Global',
    show: 'global'
  }
];

interface DefaultParams {
  category: string;
  subcategory?: string;
  show: string;
  type: BlockTypes;
}

export default defineComponent({
  name: 'ModalContentAddContent',
  inheritAttrs: false,
  props: {
    type: {
      type: String as PropType<BlockTypes>,
      required: true
    },
    contextModel: {
      type: Object as PropType<SectionModelType>
    }
  },
  emits: ['close-request'],
  setup(props, context) {
    const selectedTab = ref('');
    const selectedCategory = ref<string | number>('all');
    const contentAddModalReady = ref(false);

    const editingStore = useEditingStore();
    const campaignStore = useCampaignStore();
    const campaignModel = campaignStore.model;

    const tabs = ref<TabItem[]>([]);
    const isLoading = ref(true);
    const initialLoadDone = ref(false);
    const isAddingItem = ref(false);
    const categories = ref<APICategory[]>([]);
    const blocks = ref<APIBlock[]>([]);

    const search = ref('');

    const defaultParameters = ref<DefaultParams>({
      category: 'all',
      subcategory: '',
      show: 'grid',
      type: props.type
    });

    switch (props.type) {
      case BlockTypes.SECTIONS:
        if (tabItemsSections[0] && tabItemsSections[0]?.label) {
          selectedTab.value = tabItemsSections[0].label.toLocaleLowerCase();
        }
        tabs.value = tabItemsSections;
        break;

      case BlockTypes.BLOCKS:
        if (tabItemsRows[0] && tabItemsRows[0]?.label) {
          selectedTab.value = tabItemsRows[0].label.toLocaleLowerCase();
        }
        tabs.value = tabItemsRows;
        break;

      case BlockTypes.PAGES_FLOW:
        if (tabItemsFlow[0] && tabItemsFlow[0]?.label) {
          selectedTab.value = tabItemsFlow[0].label.toLocaleLowerCase();
        }
        tabs.value = tabItemsFlow;
        break;

      case BlockTypes.PAGES_PAGE:
        if (tabItemsPopovers[0] && tabItemsPopovers[0]?.label) {
          selectedTab.value = tabItemsPopovers[0].label.toLocaleLowerCase();
        }
        tabs.value = tabItemsPopovers;
        break;
    }

    const handleRefetchBlocks = (categoryId: string) => {
      search.value = '';
      isLoading.value = true;
      defaultParameters.value.subcategory = categoryId;
      onFetchEditBlocks();
    };

    const onFetchEditBlocks = async () => {
      const categoriesUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/fetchEditBlocks?campaign_id=${
        campaignModel?.state.id
      }&category=${defaultParameters.value.category}&subcategory=${defaultParameters.value.subcategory}&show=${
        defaultParameters.value.show
      }&type=${defaultParameters.value.type.toLowerCase()}`;

      const { fetchData } = useAxios<APIResponse>(categoriesUrl);
      const response = await fetchData();

      isLoading.value = false;
      categories.value = response.categories;
      blocks.value = response.blocks;

      initialLoadDone.value = true;
    };

    const modalTitle = computed(() => {
      switch (props.type) {
        case BlockTypes.SECTIONS:
          return 'Add new section';

        case BlockTypes.BLOCKS:
          return 'Add new row';

        case BlockTypes.PAGES_FLOW:
          return 'Add new flow page';

        case BlockTypes.PAGES_PAGE:
          return 'Add new popover';
      }

      return '';
    });

    const handleGoBack = () => {
      isLoading.value = true;
      defaultParameters.value.category = 'all';
      defaultParameters.value.subcategory = '';
      onFetchEditBlocks();
    };

    const setActiveTab = async (value: TabItem) => {
      initialLoadDone.value = false;
      categories.value = [];
      defaultParameters.value.subcategory = '';
      blocks.value = [];
      isLoading.value = true;

      if (value?.show) {
        defaultParameters.value.show = value.show;
      }

      selectedCategory.value = 'all';
      defaultParameters.value.category = selectedCategory.value;

      if (value?.label) {
        selectedTab.value = value.label.toLocaleLowerCase();
      }

      await onFetchEditBlocks();
    };

    const onUpdateCategory = async (categoryId: string) => {
      isLoading.value = true;
      defaultParameters.value.category = categoryId;
      defaultParameters.value.subcategory = '';
      await onFetchEditBlocks();
      selectedCategory.value = categoryId;
    };

    const onAddBlock = (block: APIBlock) => {
      if (typeof block.id === 'string' && block.id.startsWith('grid-')) {
        onAddItem(
          block.id
            .replace('grid-', '')
            .split('x')
            .map<ContentColumnData>((column) => {
              return {
                size: parseInt(column, 10),
                addons: []
              };
            })
        );
      }
    };

    const onAddItem = async (value: ContentColumnData[] | APIBlock) => {
      if (!campaignModel) {
        return;
      }

      isAddingItem.value = true;

      const activeModel = editingStore.activeModel;
      let section: SectionModelType | undefined;
      if (activeModel instanceof SectionBaseModel) {
        section = activeModel;
      } else if (props.contextModel) {
        section = props.contextModel;
      } else if (activeModel) {
        section = activeModel.getSection();
      } else if (editingStore.activeTabCategory === SectionType.FLOWPAGE && campaignModel) {
        section = campaignModel.getFirstFlowPage();
      }

      if (Array.isArray(value)) {
        if (section) {
          await section.addNewRow(value);
        }

        context.emit('close-request');
        return;
      }

      let area = 'section';

      switch (props.type) {
        case BlockTypes.SECTIONS:
          area = 'section';
          break;

        case BlockTypes.PAGES_FLOW:
          area = 'flow';
          break;

        case BlockTypes.PAGES_PAGE:
          area = 'page';
          break;
      }

      const orderMap: number[] = area === 'sections' ? campaignModel.state.sections.map((section) => section.id) : [];

      const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page?campaign_id=${campaignModel.state.id}`;
      const { postDataFormData } = useAxios<{
        page_id: number;
      }>(postUrl, {
        ...(orderMap.length > 0 && { order: orderMap }),
        block: value.id,
        area
      });

      const response = await postDataFormData();

      await campaignModel.reloadCampaign();

      if (props.type === BlockTypes.SECTIONS) {
        const newSection = campaignModel.state.sections.find((section) => section.id === response.page_id);
        newSection?.activateEditing();
      } else if (props.type === BlockTypes.PAGES_FLOW) {
        const newFlowPage = campaignModel.state.flowPages.find((flowPage) => flowPage.id === response.page_id);
        newFlowPage?.activateEditing();
      } else if (props.type === BlockTypes.PAGES_PAGE) {
        const newPopover = campaignModel.state.popovers.find((popover) => popover.id === response.page_id);
        newPopover?.activateEditing();
      }

      isAddingItem.value = false;
      context.emit('close-request');
    };

    const filteredBlocks = computed<APIBlock[]>(() => {
      if (search.value !== '') {
        return blocks.value.filter((block) => {
          return block.label.toLowerCase().includes(search.value.toLowerCase());
        });
      }

      return blocks.value;
    });

    const visibleTabs = computed<TabItem[]>(() => {
      if (campaignStore.model?.state.deviceType === CampaignDeviceType.ADS) {
        return tabs.value.filter((tab) => {
          return tab.label === 'Grid';
        });
      }

      return tabs.value;
    });

    onMounted(async () => {
      await onFetchEditBlocks();
      contentAddModalReady.value = true;
    });

    return {
      defaultParameters,
      handleRefetchBlocks,
      onUpdateCategory,
      selectedCategory,
      selectedTab,
      filteredBlocks,
      categories,
      visibleTabs,
      isAddingItem,
      isLoading,
      setActiveTab,
      stringToSlug,
      onAddItem,
      onAddBlock,
      BlockTypes,
      handleGoBack,
      modalTitle,
      contentAddModalReady,
      search,
      initialLoadDone
    };
  }
});
</script>

<style scoped lang="scss">
p-card {
  flex-grow: 1;
  max-width: 165px;
}

.content-filtering {
  width: auto;

  p-input {
    width: 165px;
  }

  p-select {
    width: 165px;
  }
}
</style>
