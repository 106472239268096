import type { AddonLfIconData, IconStyleData } from '@/src/components/addons/lf-icon/Data';
import ActionsModel from '@/src/models/actions/ActionsModel';
import type { AlignContentType, IconShapeType } from '@/src/typings/enums/enums';
import { IconDisplayType } from '@/src/typings/enums/enums';
import { getDeviceData } from '@/src/hooks/useDevice';
import { transformMeasurementToNumber } from '@/src/utilities/Utilities';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

interface IconStyleState {
  icon?: string; // fa-adn
  size?: number;
  color?: string;
  display?: IconDisplayType;
  shape?: IconShapeType;
  stackedColor?: string;
  alignment?: AlignContentType;
  sizeIsPercentage?: boolean;
}

interface AddonLfIconSettingsState {
  layout?: IconStyleState;
}

interface AddonLfIconState extends AddonModelState {
  action?: ActionsModel;
  settings: AddonLfIconSettingsState;
  iconClasses?: { [key: string]: boolean };
}

export class AddonLfIconModel extends AddonModel<AddonLfIconData, AddonLfIconState, 'lf-icon'> {
  parseAddon(data: AddonLfIconData) {
    const state = this.state;
    if (data.settings?.action?.type) {
      if (state.action) {
        state.action.setData(data.settings.action);
      } else {
        state.action = new ActionsModel(data.settings.action);
      }
    } else {
      state.action = undefined;
    }

    state.settings = state.settings ?? {};

    if (data.settings?.general?.styles) {
      state.settings.layout = AddonLfIconModel.parseIconStyle(data);
    } else {
      state.settings.layout = {};
    }

    state.iconClasses = AddonLfIconModel.constructIconClasses(state.settings.layout);
  }

  private static parseIconStyle(data: AddonLfIconData): IconStyleState | undefined {
    const useData = getDeviceData<IconStyleData>(data.settings.general.styles);

    if (!useData) {
      return undefined;
    }
    return AddonLfIconModel.constructStyleState(useData);
  }

  private static constructStyleState(data: IconStyleData): IconStyleState {
    /**
     * Check if the size is in pixels or percentage - strip the measurement, as we need to convert it to a number
     */
    let size: number | undefined;
    if (data.size) {
      size = transformMeasurementToNumber(data.size, 16);
    }

    const color = data.color;
    const bgColor = data.stacked_color;
    const display = data.display || null;
    const shape = data.shape;

    /**
     * Return object
     */
    return {
      ...(data?.alignment && { alignment: data.alignment }),
      ...(color && { color }),
      ...(display && { display }),
      icon: data.icon || 'fa-star',
      ...(shape && { shape }),
      ...(size && { size }),
      ...(bgColor && { stackedColor: bgColor }),
      sizeIsPercentage: !!data?.size?.includes('%')
    };
  }

  private static constructIconClasses(layout: IconStyleState | undefined): { [key: string]: boolean } {
    const defaultIcon = 'fa-star';
    return {
      'lf-icon__shape-icon': true,
      fa: true,
      [!layout?.icon ? defaultIcon : layout?.icon]: true,
      'fa--stack': layout?.display === IconDisplayType.STACKED || layout?.display === IconDisplayType.FRAMED
    };
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
