import { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import { SectionType } from '@/src/typings/enums/enums';
import type { SettingsData } from '@/src/typings/interfaces/data/settings/settings';
import type { SettingsModel } from '@/src/components/layout/SettingsModel';
import { SectionSettingsModel } from '@/src/components/layout/section/SectionSettingsModel';
import { useEditingStore } from '@/src/store/editing';
import { router } from '@/src/router';

export class SectionModel extends SectionBaseModel {
  activateEditing(): void {
    const editingStore = useEditingStore();
    const route = router.currentRoute.value;

    if (route.query?.pageId) {
      const newQuery = { ...route.query };
      delete newQuery.pageId;

      router.push({
        query: newQuery
      });
    }

    editingStore.setActiveModel(this);
  }

  public getSectionType() {
    return SectionType.SECTION;
  }

  public getSettingsModel(data: SettingsData): SettingsModel {
    return new SectionSettingsModel(data, this);
  }

  get index(): number {
    return this.campaignModel.state.sections.indexOf(this);
  }
}
