<template>
  <div class="game-indicator-v2" :class="indicatorClasses">
    <div
      v-if="!hasOnlyCenterItems"
      class="game-indicator-v2__left-content"
      :class="`game-indicator-v2__left-content--${model.state.layout?.direction}`"
    >
      <template v-for="(indicator, index) in leftAlignedItems" :key="index">
        <GameIndicatorItemV2 :indicator="indicator" />
        <div
          v-if="
            index !== leftAlignedItems.length - 1 &&
            !indicator.icon &&
            indicator.valueDisplayMode !== GameIndicatorValueDisplayModes.VALUE_CIRCLE
          "
          class="game-indicator-v2--divider"
          :style="{ background: model.state.layout?.color }"
        ></div>
      </template>
    </div>

    <div class="game-indicator-v2__center-content">
      <template v-for="(indicator, index) in centerAlignedItems" :key="index">
        <GameIndicatorItemV2 :indicator="indicator" :alignment="getCenterItemsAlignment(index)" />
        <div
          v-if="
            index !== centerAlignedItems.length - 1 &&
            !indicator.icon &&
            indicator.valueDisplayMode !== GameIndicatorValueDisplayModes.VALUE_CIRCLE
          "
          class="game-indicator-v2--divider"
          :style="{ background: model.state.layout?.color }"
        ></div>
      </template>
    </div>

    <div
      v-if="!hasOnlyCenterItems"
      class="game-indicator-v2__right-content"
      :class="`game-indicator-v2__right-content--${model.state.layout?.direction}`"
    >
      <template v-for="(indicator, index) in rightAlignedItems" :key="index">
        <GameIndicatorItemV2 :indicator="indicator" />
        <div
          v-if="
            index !== rightAlignedItems.length - 1 &&
            !indicator.icon &&
            indicator.valueDisplayMode !== GameIndicatorValueDisplayModes.VALUE_CIRCLE
          "
          class="game-indicator-v2--divider"
          :style="{ background: model.state.layout?.color }"
        ></div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted } from 'vue';
import type { GameIndicatorSettingsModel, GameIndicatorTypeState } from '@/src/components/indicators/Model';
import {
  GameIndicatorValueDisplayModes,
  GameIndicatorHAlignTypes,
  GameIndicatorPositionType
} from '@/src/components/indicators/Model';
import useFontObserver from '@/src/hooks/useFontObserver';
import { useUtilityStore } from '@/src/store/utility';
import GameIndicatorItemV2 from '@/src/components/indicators/v2/GameIndicatorItem.vue';
import type { ClassList } from '@/src/typings/types/types';

export default defineComponent({
  name: 'GameIndicatorsV2',
  components: {
    GameIndicatorItemV2
  },
  props: {
    model: {
      type: Object as PropType<GameIndicatorSettingsModel>,
      required: true
    },
    placement: {
      type: String as PropType<'top' | 'bottom' | 'center'>,
      required: true
    },
    items: {
      type: Array as PropType<GameIndicatorTypeState[]>,
      required: true
    }
  },
  setup(props) {
    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    if (props.model?.state.layout?.fontFamily) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(props.model?.state.layout?.fontFamily);
    }

    const positionClassMap = {
      [GameIndicatorPositionType.DEFAULT]: 'default',
      [GameIndicatorPositionType.ABSOLUTE]: 'absolute',
      [GameIndicatorPositionType.RELATIVE]: 'relative'
    };

    const leftAlignedItems = computed<GameIndicatorTypeState[]>(() => {
      return props.items.filter((item) => item.halign === GameIndicatorHAlignTypes.LEFT);
    });

    const centerAlignedItems = computed<GameIndicatorTypeState[]>(() => {
      return props.items.filter((item) => item.halign === GameIndicatorHAlignTypes.CENTER);
    });

    const rightAlignedItems = computed<GameIndicatorTypeState[]>(() => {
      return props.items.filter((item) => item.halign === GameIndicatorHAlignTypes.RIGHT);
    });

    const getCenterItemsAlignment = (index: number) => {
      const length = centerAlignedItems.value.length;

      // if only 1 item then we want to center place it
      if (length === 1) {
        return 'center';
        // else we got more than 1 and the first is left
      } else if (index === 0) {
        return 'left';
        // last item is right sided
      } else if (index === length - 1) {
        return 'right';
      } else {
        // else place in the center between left and right
        return 'center';
      }
    };

    const hasOnlyCenterItems = computed<boolean>(() => {
      return (
        leftAlignedItems.value.length === 0 &&
        rightAlignedItems.value.length === 0 &&
        centerAlignedItems.value.length > 0
      );
    });

    const indicatorClasses = computed<ClassList>(() => {
      const classes: ClassList = {};

      const positionClass =
        props.placement === 'center' ? 'absolute' : positionClassMap[props.model.state.position[props.placement]];

      classes[`game-indicator-v2--${positionClass}`] = true;
      classes[`game-indicator-v2--${positionClass}-${props.placement}`] = true;
      classes[`game-indicator-v2--${props.model.state.layout?.direction}`] = true;

      return classes;
    });

    onMounted(async () => {
      if (props.model?.state.layout?.fontFamily) {
        const { isFontLoaded } = useFontObserver();

        await isFontLoaded(props.model?.state.layout.fontFamily).catch((e) => {
          // eslint-disable-next-line no-console
          console.error('Something went wrong with loading the font', e);
        });
      }

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }
    });

    return {
      GameIndicatorValueDisplayModes,
      leftAlignedItems,
      centerAlignedItems,
      rightAlignedItems,
      indicatorClasses,
      hasOnlyCenterItems,
      getCenterItemsAlignment,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>

<style lang="scss">
.game-indicator-v2 {
  position: static;
  width: 100%;
  min-height: 90px;
  align-items: center;
  display: flex;
  gap: 5px;
  height: 100%;
  pointer-events: none;

  &__left-content,
  &__center-content,
  &__right-content {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &--horizontal {
    .game-indicator-v2__left-content,
    .game-indicator-v2__center-content,
    .game-indicator-v2__right-content {
      flex-direction: row;
    }

    .game-indicator-v2--divider {
      width: 2px;
      height: 40px;
    }
  }

  &--vertical {
    .game-indicator-v2__left-content,
    .game-indicator-v2__right-content,
    .game-indicator-v2__center-content {
      flex-direction: column;
    }
    .game-indicator-v2__left-content,
    .game-indicator-v2__right-content {
      width: 90px;
    }

    .game-indicator-v2--divider {
      width: 40px;
      height: 2px;
    }
  }

  &__center-content {
    justify-content: center;
  }

  &__left-content {
    justify-content: flex-start;
  }

  &__right-content {
    justify-content: flex-end;
  }

  &--divider {
    background-color: #fff;
    height: 40px;
    width: 2px;
  }

  &--absolute {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    pointer-events: none;

    &-bottom {
      bottom: 0;
      height: auto;
    }

    &-center {
      justify-content: space-between;
      top: 50%;
      transform: translateY(-50%);
    }

    &-top {
      top: 0;
      height: auto;
    }
  }

  &--relative {
    &-bottom {
      margin-top: 20px;
    }

    &-top {
      margin-bottom: 20px;
    }
  }
}
</style>
