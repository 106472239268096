import { AlignContentType } from '@/src/typings/enums/enums';
import type { GameIndicator, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GamePredictionAdvancedData,
  GamePredictionData,
  GamePredictionGeneralData,
  PredictionData
} from '@/src/components/games/prediction/Data';
import type { GamePredictionAnswerType } from '@/src/components/games/prediction/enums';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { GameEndingConditionType } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import type { TransitionTypes } from '@/src/typings/types/types';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';

export interface GamePredictionState {
  general: GamePredictionGeneralState;
  predictions: Prediction[];
  expiredPredictions: Prediction[];
  advanced: GamePredictionAdvancedState;
}

interface GamePredictionGeneralState {
  contentAlign: AlignContentType;
  answerButtonLabel: string;
  selectAnswerButtonLabel: string;
}

export interface Prediction {
  description: string;
  answerType: GamePredictionAnswerType;
  answers: AnswerState[];
  correctAnswer: number;
  correctAnswer1?: number;
  correctAnswer2?: number;
  dateRange?: DateRangeState;
  id: number;
  isDisabled?: boolean;
  isError?: boolean;
  isValid?: boolean;
  visibilityCondition: VisibilityConditionsModel | undefined;
}

export interface GamePredictionAdvancedState {
  sound: GamePredictionAdvancedSoundState;
  animation: GamePredictionAdvancedAnimationState;
}

export interface GamePredictionAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GamePredictionAdvancedAnimationState {
  enabled: boolean;
  animation?: TransitionTypes;
}

export interface AnswerState {
  id: number;
  value: string;
}

interface DateRangeState {
  from: string;
  fromTime: string;
  to: string;
  toTime: string;
}

export class GamePredictionModel extends GameModel<GamePredictionData, GamePredictionState> implements HasSound {
  parseGame(data: GamePredictionData) {
    const state = this.state;

    state.general = GamePredictionModel.constructGeneralState(data.general);

    state.predictions = this.constructPredictionState(data.predictions.predictions) || [];

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GamePredictionModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GamePredictionModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructAdvancedSoundState(
    data: GamePredictionAdvancedData | undefined
  ): GamePredictionAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructAdvancedAnimationState(
    data: GamePredictionAdvancedData | undefined
  ): GamePredictionAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private static constructGeneralState(data: GamePredictionGeneralData): GamePredictionGeneralState {
    return {
      contentAlign: data?.content_align || AlignContentType.CENTER,
      answerButtonLabel: data?.answer_btn || 'Answer',
      selectAnswerButtonLabel: data?.select_answer_btn || 'Choose an answer'
    };
  }

  private constructPredictionState(data: PredictionData[]): Prediction[] {
    return data.map((prediction) => {
      const existingPredictionState = this.state.predictions?.find(
        (exisitingPrediction) => exisitingPrediction.id === Number(prediction.id)
      );

      let visibilityCondition: VisibilityConditionsModel | undefined;

      if (prediction.date_range.enabled === '1') {
        visibilityCondition = existingPredictionState ? existingPredictionState.visibilityCondition : undefined;

        const visibilityConditionData = {
          condition: GameEndingConditionType.NONE,
          time_range: {
            enabled: prediction.date_range.to_time || prediction.date_range.from_time ? '1' : '0',
            time_from: prediction.date_range.from_time,
            time_to: prediction.date_range.to_time
          },
          date_range: {
            enabled: prediction.date_range.to || prediction.date_range.from ? '1' : '0',
            date_to: prediction.date_range.to,
            date_from: prediction.date_range.from
          }
        };

        if (visibilityCondition) {
          visibilityCondition.setData(visibilityConditionData);
        } else {
          visibilityCondition = new VisibilityConditionsModel(visibilityConditionData);
        }
      }

      return {
        description: prediction.description,
        answers: prediction.answers.map((answer) => {
          return {
            id: Number(answer.id),
            value: answer.value
          };
        }),
        answerType: prediction.answer_type,
        correctAnswer: Number(prediction.correct_answer),
        ...(prediction.correct_answer1 && { correctAnswer1: Number(prediction.correct_answer1) }),
        ...(prediction.correct_answer1 && { correctAnswer2: Number(prediction.correct_answer2) }),
        id: Number(prediction.id),
        isDisabled: false,
        isError: false,
        isValid: false,
        visibilityCondition
      };
    });
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
