import { useCampaignStore } from '@/src/store/campaign';

export class SdkCampaignApi {
  static async getId(): Promise<number | undefined> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    return campaignStore.model?.state.config?.campaignId;
  }

  static async getName(): Promise<string | undefined> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    return campaignStore.model?.state.name;
  }

  static async getType(): Promise<string | undefined> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    return campaignStore.model?.state.config?.gameAlias;
  }

  static async gameEnded(): Promise<boolean> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    return campaignStore.gameEnded;
  }

  static async gameWinner(): Promise<boolean | null> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;

    return campaignStore.gameWinner;
  }
}
