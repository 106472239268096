interface CacheData {
  // eslint-disable-next-line
  [key: string]: any;
}

/**
 * Because we're running SSR & can have values that is being cached on SSR cross-request we end up
 * with collisions on the cache. We would not like it to keep the same cache cross-requests.
 *
 * This class serves as a utility class that will automatically cleanup it's internal caching
 * before initializing a new request.
 */
export class Cache {
  private static data: CacheData = {};

  public static get<T>(alias: string): T | null {
    return Cache.data[`${alias}`] ?? null;
  }

  public static set<T>(alias: string, value: T): void {
    Cache.data[`${alias}`] = value;
  }

  public static exists(alias: string): boolean {
    return typeof Cache.data[`${alias}`] !== 'undefined';
  }

  public static clear(): void {
    Cache.data = {};
  }
}
