<template>
  <div class="content__item content__item--component content__item--text">
    <component :is="comp" />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineAsyncComponent, defineComponent, nextTick } from 'vue';
import type { ContentComponent } from '@/src/components/layout/section/SectionBaseModel';

export default defineComponent({
  name: 'ContentComponent',
  props: {
    content: {
      type: Object as PropType<ContentComponent>,
      required: true
    }
  },
  setup(props) {
    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    return {
      comp: defineAsyncComponent({
        loader: async () => {
          const component = await props.content.loader();

          nextTick().then(() => {
            if (readyPromiseResolve) {
              readyPromiseResolve();
            }
          });

          return component;
        }
      }),
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>
