import type { Component } from 'vue';
import type { IntegrationScandlinesData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export type IntegrationScandlinesState = BaseIntegrationState;

export class IntegrationScandlinesModel extends BaseIntegration<IntegrationScandlinesData, IntegrationScandlinesState> {
  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/scandlines/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
