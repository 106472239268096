import type {
  GameRushRunnerAvatarData,
  GameRushRunnerBackgroundsData,
  GameRushRunnerBackgroundsImagesData,
  GameRushRunnerData,
  GameRushRunnerGeneralData,
  GameRushRunnerGeneralLayoutData,
  GameRushRunnerObstaclesData,
  GameRushRunnerObstaclesImagesData,
  GameRushRunnerPointsData,
  GameRushRunnerPointsImagesData,
  GameRushRunnerVisualsData
} from '@/src/components/games/rush-runner/Data';
import type { HasGameTimeChallenge, GameIndicator, GameTimeChallenge, GameState } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import {
  RushRunnerAnimation,
  RushRunnerGameSpeed,
  RushRunnerVerticalPlacement
} from '@/src/components/games/rush-runner/interfaces';

interface GameRushRunnerState extends GameState {
  general: GameRushRunnerGeneralState;
  visuals: GameRushRunnerVisualsState;
}

interface GameRushRunnerGameSettingsState {
  gameSpeed: RushRunnerGameSpeed;
  backgroundSpawnInterval: number;
  obstacleSpawnInterval: number;
  pointSpawnInterval: number;
}

interface GameRushRunnerGeneralState {
  pointsToWin: number;
  gameSettings?: GameRushRunnerGameSettingsState;
  maxRounds: number;
  layout: GameRushRunnerGeneralLayoutState;
}

interface GameRushRunnerGeneralLayoutState {
  positiveColor?: string;
  negativeColor?: string;
  fontSize?: string;
  gameHeight?: string;
}

interface GameRushRunnerVisualsState {
  avatar?: GameRushRunnerAvatarState;
  backgrounds?: GameRushRunnerBackgroundsState;
  points?: GameRushRunnerPointsState;
  obstacles?: GameRushRunnerObstaclesState;
}

export interface GameRushRunnerAvatarState {
  image?: string;
  maxWidth?: number;
  animation?: RushRunnerAnimation;
  jumpPower: number;
  gravityY: number;
  offsetX: number;
  offsetY: number;
}

export interface GameRushRunnerBackgroundsState {
  images?: GameRushRunnerBackgroundsImagesState[];
}

export interface GameRushRunnerBackgroundsImagesState {
  id: string;
  image: string;
  maxWidth?: number;
  placement: RushRunnerVerticalPlacement;
  offset: number;
  speed: RushRunnerGameSpeed;
}

export interface GameRushRunnerPointsState {
  images?: GameRushRunnerPointsImagesState[];
}

export interface GameRushRunnerPointsImagesState {
  id: string;
  image: string;
  maxWidth?: number;
  score: number;
  label: string;
  offsetTop: number;
  offsetBottom: number;
}

export interface GameRushRunnerObstaclesState {
  images?: GameRushRunnerObstaclesImagesState[];
}

export interface GameRushRunnerObstaclesImagesState {
  id: string;
  image: string;
  maxWidth?: number;
  placement: RushRunnerVerticalPlacement;
  flipVertically: boolean;
  offset: number;
}

export class GameRushRunnerModel
  extends GameModel<GameRushRunnerData, GameRushRunnerState>
  implements HasGameTimeChallenge
{
  parseGame(data: GameRushRunnerData) {
    const state = this.state;

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }

    state.general = GameRushRunnerModel.constructGeneralState(data.general);
    state.visuals = GameRushRunnerModel.constructVisualsState(data.visuals);
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general?.time === 1,
      ...(data.general?.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  private static parseVerticalPlacement(value: string): RushRunnerVerticalPlacement {
    switch (value) {
      case 'top':
        return RushRunnerVerticalPlacement.TOP;
      case 'bottom':
        return RushRunnerVerticalPlacement.BOTTOM;
      case 'middle':
        return RushRunnerVerticalPlacement.MIDDLE;
      case 'random':
        return RushRunnerVerticalPlacement.RANDOM;
      default:
        throw new Error(`Unknown VerticalPlacement value: ${value}`);
    }
  }

  private static parseGameSpeed(value: string): RushRunnerGameSpeed {
    switch (value) {
      case 'slow':
        return RushRunnerGameSpeed.SLOW;
      case 'normal':
        return RushRunnerGameSpeed.NORMAL;
      case 'fast':
        return RushRunnerGameSpeed.FAST;
      default:
        throw new Error(`Unknown RushRunnerGameSpeed value: ${value}`);
    }
  }

  private static parseAnimation(value: string): RushRunnerAnimation {
    switch (value) {
      case 'no_animation':
        return RushRunnerAnimation.NO_ANIMATION;
      case 'pulse':
        return RushRunnerAnimation.PULSE;
      default:
        throw new Error(`Unknown RushRunnerAnimation value: ${value}`);
    }
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.ABSOLUTE,
      bottom: GameIndicatorPositionType.ABSOLUTE
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 0,
          time_used: 0
        }
      },
      {
        indicatorKey: 'score',
        metricKey: {
          score_used: 'score'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          score: 0
        }
      },
      {
        indicatorKey: 'round',
        metricKey: {
          round_used: 'rounds'
        },
        icon: GameIndicatorIcon.ROUNDS,
        value: {
          round_used: 1
        }
      }
    ];
  }

  private static parseGeneralGameSettingsDeviceData(
    data: GameRushRunnerGeneralData
  ): GameRushRunnerGameSettingsState | undefined {
    if (data.game_settings) {
      const useData = getDeviceData(data.game_settings);
      if (!useData) {
        return undefined;
      }

      return {
        gameSpeed: this.parseGameSpeed(useData.game_speed) ?? RushRunnerGameSpeed.NORMAL,
        backgroundSpawnInterval: useData.background_spawn_interval ?? 700,
        obstacleSpawnInterval: useData.obstacle_spawn_interval ?? 1500,
        pointSpawnInterval: useData.point_spawn_interval ?? 3000
      };
    }
  }

  private static constructGeneralState(data: GameRushRunnerGeneralData): GameRushRunnerGeneralState {
    return {
      gameSettings: GameRushRunnerModel.parseGeneralGameSettingsDeviceData(data),
      pointsToWin: data?.points_to_win,
      maxRounds: Number(data?.max_rounds),
      layout: this.constructLayoutState(data.layout)
    };
  }

  private static constructLayoutState(
    data: DeviceData<GameRushRunnerGeneralLayoutData>
  ): GameRushRunnerGeneralLayoutState {
    const layoutData = getDeviceData(data);

    return {
      positiveColor: layoutData?.positive_color ?? '#00ff00',
      negativeColor: layoutData?.negative_color ?? '#b22222',
      fontSize: layoutData?.font_size,
      gameHeight: layoutData?.game_height
    };
  }

  private static constructVisualsState(data: GameRushRunnerVisualsData): GameRushRunnerVisualsState {
    const avatarData = getDeviceData<GameRushRunnerAvatarData>(data?.avatar);
    const obstaclesData = getDeviceData<GameRushRunnerObstaclesData>(data?.obstacles);
    const pointsData = getDeviceData<GameRushRunnerPointsData>(data?.points);
    const backgroundsData = getDeviceData<GameRushRunnerBackgroundsData>(data.backgrounds);

    return {
      avatar: avatarData !== undefined ? this.constructAvatarState(avatarData) : undefined,
      backgrounds: backgroundsData !== undefined ? this.constructBackgroundsState(backgroundsData) : undefined,
      obstacles: obstaclesData !== undefined ? this.constructObstaclesState(obstaclesData) : undefined,
      points: pointsData !== undefined ? this.constructPointsState(pointsData) : undefined
    };
  }

  private static constructAvatarState(data: GameRushRunnerAvatarData): GameRushRunnerAvatarState {
    return {
      image: data?.image,
      maxWidth: data?.max_width,
      animation: this.parseAnimation(data?.animation ?? 'no_animation'),
      // Jump power is multiplied by 10 to make it easier to set in the editor
      // etc 200 in the editor will be 2000 in the game
      jumpPower: -Math.abs((data?.jump_power ?? 150) * 10),
      offsetX: Number(data?.offset_x) || 50,
      offsetY: Number(data?.offset_y) || 0,
      gravityY: data?.gravity_y || 30
    };
  }

  private static constructBackgroundsState(
    data: GameRushRunnerBackgroundsData
  ): GameRushRunnerBackgroundsState | undefined {
    if (data.images === undefined) {
      return undefined;
    }

    return {
      images: data.images.map<GameRushRunnerBackgroundsImagesState>((element: GameRushRunnerBackgroundsImagesData) => ({
        id: element.id,
        image: element.image,
        maxWidth: element.max_width || undefined,
        placement: this.parseVerticalPlacement(String(element.placement)),
        offset: element.offset,
        speed: this.parseGameSpeed(element.speed) ?? RushRunnerGameSpeed.NORMAL
      }))
    };
  }

  private static constructObstaclesState(data: GameRushRunnerObstaclesData): GameRushRunnerObstaclesState | undefined {
    if (data.images === undefined) {
      return undefined;
    }

    return {
      images: data.images.map<GameRushRunnerObstaclesImagesState>((element: GameRushRunnerObstaclesImagesData) => ({
        id: element.id,
        image: element.image,
        maxWidth: element.max_width || undefined,
        placement: this.parseVerticalPlacement(String(element.placement)),
        flipVertically: element.flip_vertically === '1',
        offset: element.offset
      }))
    };
  }

  private static constructPointsState(data: GameRushRunnerPointsData): GameRushRunnerPointsState | undefined {
    if (data.images === undefined) {
      return undefined;
    }

    return {
      images: data.images.map<GameRushRunnerPointsImagesState>((element: GameRushRunnerPointsImagesData) => ({
        id: element.id,
        image: element.image,
        maxWidth: element.max_width ? Number(element.max_width) : undefined,
        score: Number(element.score),
        label: element.label,
        offsetTop: Number(element.offset_top ?? 0),
        offsetBottom: Number(element.offset_bottom ?? 0)
      }))
    };
  }

  public isGameValid(): boolean {
    return true;
  }
}
