import type { IntegrationBezzerwisserData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';
import type { ReplacementTags } from '@/src/store/campaign';
import type { Component } from 'vue';

interface CampaignItemState {
  question: string;
  url: string;
  unixTime: number;
  date: string;
  id: number;
}

export interface IntegrationBezzerwisserState extends BaseIntegrationState {
  replacementTags: ReplacementTags;
  campaignsOverview: CampaignItemState[];
}

export class IntegrationBezzerwisserModel extends BaseIntegration<
  IntegrationBezzerwisserData,
  IntegrationBezzerwisserState
> {
  parse(data: IntegrationBezzerwisserData) {
    super.parse(data);
    this.state.replacementTags = data.settings?.replacementtags ?? {};

    if (data.settings?.campaigns_overview) {
      this.state.campaignsOverview = data.settings.campaigns_overview.map<CampaignItemState>((campaign) => {
        return {
          question: campaign.question,
          url: campaign.url,
          unixTime: campaign.unixtime,
          date: campaign.date,
          id: campaign.id
        };
      });
    } else {
      this.state.campaignsOverview = [];
    }
  }

  hasVueComponent() {
    return true;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/bezzerwisser/View.vue');
  }
}
