import type { Component } from 'vue';
import type { IntegrationCookieData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

interface CookieItemState {
  name: string;
  value: string;
  expire: number;
}

export interface IntegrationCookieState extends BaseIntegrationState {
  cookies: CookieItemState[];
}

export class IntegrationCookieModel extends BaseIntegration<IntegrationCookieData, IntegrationCookieState> {
  parse(data: IntegrationCookieData) {
    super.parse(data);

    if (data.settings?.cookies) {
      this.state.cookies = data.settings.cookies.map<CookieItemState>((cookie) => {
        return {
          name: cookie.name,
          value: cookie.value,
          expire: Number(cookie.expire)
        };
      });
    } else {
      this.state.cookies = [];
    }
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/cookie/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
