import { SdkFlowPageModel } from '../models/flowPage';
import type { FlowpageModel } from '@/src/components/layout/FlowpageModel';

export class SdkFormSubmitEvent {
  #section: SdkFlowPageModel;
  #promises: Promise<void>[] = [];

  constructor(page: FlowpageModel) {
    this.#section = new SdkFlowPageModel(page);
  }

  public suspense(fn: () => Promise<void>) {
    this.#promises.push(fn());
  }

  async waitForPromises() {
    await Promise.all(this.#promises);
  }

  get section() {
    return this.#section;
  }

  get formFields() {
    return this.#section.formFields;
  }
}
