import type { Component } from 'vue';
import type { IntegrationPixelTrackingData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import type {
  FormFieldItem,
  VisibilityConditionsData,
  VisibilityMetricItemData
} from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import { GameEndingConditionType } from '@/src/typings/interfaces/data/conditions/visibilityConditions';

export enum TrackByType {
  CODE,
  FACEBOOK_ID,
  TIKTOK_ID
}

export enum EventType {
  PAGE_VIEW,
  CUSTOM_EVENT,
  CLICK_BUTTON,
  COMPLETE_REGISTRATION,
  INITIATE_CHECKOUT,
  SUBMIT_FORM,
  SUBSCRIBE
}

export enum TriggerType {
  FLOWPAGE,
  PAGELOAD,
  CONFIRM
}

export interface PixelItemState {
  id: string;
  trigger: TriggerType;
  confirmSectionId?: number;
  code: string;
  trackBy?: TrackByType;
  eventType?: EventType;
  eventName?: string;
  devices: {
    desktop: boolean;
    tablet: boolean;
    mobile: boolean;
  };
  visibilityCondition?: VisibilityConditionsModel;
}

export interface IntegrationPixelTrackingState extends BaseIntegrationState {
  pixels?: PixelItemState[];
}

export class IntegrationPixelTrackingModel extends BaseIntegration<
  IntegrationPixelTrackingData,
  IntegrationPixelTrackingState
> {
  parse(data: IntegrationPixelTrackingData) {
    super.parse(data);
    const pixelTracking = data.settings?.pixel_tracking;

    if (pixelTracking) {
      const pixelVisibilityConditionsMap: Record<string, VisibilityConditionsModel> = {};

      if (this.state.pixels) {
        this.state.pixels.forEach((pixel) => {
          if (pixel.visibilityCondition) {
            pixelVisibilityConditionsMap[pixel.id] = pixel.visibilityCondition;
          }
        });
      }

      this.state.pixels = pixelTracking.map<PixelItemState>((pixelItem) => {
        const pixelItemData: PixelItemState = {
          id: pixelItem.id,
          trigger: TriggerType.PAGELOAD,
          code: pixelItem.code,
          eventName: pixelItem.event_name,
          devices: {
            desktop: pixelItem.devices.desktop === '1',
            tablet: pixelItem.devices.tablet === '1',
            mobile: pixelItem.devices.mobile === '1'
          }
        };

        if (pixelItem.trigger === 'flow') {
          pixelItemData.trigger = TriggerType.FLOWPAGE;
        } else if (pixelItem.trigger.includes('_addon_confirmation') && pixelItem.trigger.includes('registration')) {
          pixelItemData.trigger = TriggerType.CONFIRM;
          pixelItemData.confirmSectionId = Number(pixelItem.trigger.replace(/\D/g, ''));
        }

        const flow: { [key: string]: string } = {};

        if (pixelItem.flow) {
          flow[pixelItem.flow] = '1';
        }

        const visibilityConditionData: VisibilityConditionsData = {
          condition: GameEndingConditionType.NONE,
          flow: {
            enabled: pixelItem.flow ? '1' : '0',
            pages: flow
          },
          form_fields: {
            enabled: pixelItem.form_conditions?.length ? '1' : '0',
            items: pixelItem.form_conditions?.map<FormFieldItem>((fieldItem) => {
              let type = 1;

              switch (fieldItem.operator) {
                case '!equal':
                  type = 9;
                  break;

                case 'bigger':
                  type = 4;
                  break;

                case 'smaller':
                  type = 5;
                  break;

                case 'bigger_or_equal':
                  type = 2;
                  break;

                case 'smaller_or_equal':
                  type = 3;
                  break;

                case 'empty':
                case '!checked':
                  type = 7;
                  break;

                case '!empty':
                case 'checked':
                  type = 8;
                  break;
              }

              return {
                field: fieldItem.field,
                type,
                value: fieldItem.value ? fieldItem.value : ''
              };
            })
          },
          metric_data: {
            enabled: pixelItem.metrics_conditions?.length ? '1' : '0',
            operator: 'and',
            items: pixelItem.metrics_conditions?.map<VisibilityMetricItemData>((metricItem) => {
              let type = '1';

              switch (metricItem.operator) {
                case '!equal':
                  type = '9';
                  break;

                case 'bigger':
                  type = '4';
                  break;

                case 'smaller':
                  type = '5';
                  break;

                case 'bigger_or_equal':
                  type = '2';
                  break;

                case 'smaller_or_equal':
                  type = '3';
                  break;

                case 'empty':
                  type = '7';
                  break;

                case '!empty':
                  type = '8';
                  break;
              }

              return {
                field: metricItem.field,
                type,
                value: metricItem.value
              };
            })
          }
        };

        if (pixelVisibilityConditionsMap[pixelItem.id]) {
          pixelItemData.visibilityCondition = pixelVisibilityConditionsMap[pixelItem.id];
          pixelItemData.visibilityCondition.setData(visibilityConditionData);
        } else {
          pixelItemData.visibilityCondition = new VisibilityConditionsModel(visibilityConditionData);
        }

        switch (pixelItem.track_by) {
          case 'code':
            pixelItemData.trackBy = TrackByType.CODE;
            break;

          case 'id':
            pixelItemData.trackBy = TrackByType.FACEBOOK_ID;
            break;

          case 'tiktok':
            pixelItemData.trackBy = TrackByType.TIKTOK_ID;
            break;

          default:
            pixelItemData.trackBy = undefined;
        }

        switch (pixelItem.event_type) {
          case 'track':
          case 'page_view':
            pixelItemData.eventType = EventType.PAGE_VIEW;
            break;

          case 'trackCustom':
            pixelItemData.eventType = EventType.CUSTOM_EVENT;
            break;

          case 'click_button':
            pixelItemData.eventType = EventType.CLICK_BUTTON;
            break;

          case 'complete_registration':
            pixelItemData.eventType = EventType.COMPLETE_REGISTRATION;
            break;

          case 'initiate_checkout':
            pixelItemData.eventType = EventType.INITIATE_CHECKOUT;
            break;

          case 'submit_form':
            pixelItemData.eventType = EventType.SUBMIT_FORM;
            break;

          case 'subscribe':
            pixelItemData.eventType = EventType.SUBSCRIBE;
            break;

          default:
            pixelItemData.eventType = undefined;
        }

        return pixelItemData;
      });
    } else {
      this.state.pixels = undefined;
    }
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/pixel-tracking/View.vue');
  }

  hasVueComponent() {
    return true;
  }
}
