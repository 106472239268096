import { SdkTypeCheck } from '../utilities';
import type { Ref } from 'vue';
import { ref } from 'vue';

interface SdkAuthenticationField {
  id: string;
  label: string;
  type: 'text' | 'email';
}

interface SdkAuthenticationImplementation {
  id: string;
  fields: SdkAuthenticationField[];
  handler: (data: Record<string, unknown>) => Promise<void>;
}

export class SdkAuthenticationApi {
  static #implementations: Ref<SdkAuthenticationImplementation[]> = ref<SdkAuthenticationImplementation[]>([]);

  static getAll(): SdkAuthenticationImplementation[] {
    // Clone all objects to avoid mutation of the original ones
    return [
      ...this.#implementations.value.map<SdkAuthenticationImplementation>((implementation) => {
        return {
          id: implementation.id,
          fields: implementation.fields.map((field) => {
            return {
              id: field.id,
              label: field.label,
              type: field.type
            };
          }),
          handler: implementation.handler
        };
      })
    ];
  }

  static add(
    id: string,
    fields: SdkAuthenticationField[],
    handler: (data: Record<string, unknown>) => Promise<void>
  ): void {
    SdkTypeCheck('string', id);
    SdkTypeCheck('function', handler);

    if (!id) {
      throw new TypeError(`[SDK] id must not be empty`);
    }

    if (!Array.isArray(fields) || fields.length === 0) {
      throw new TypeError(`[SDK] fields must be an array and must not be empty`);
    }

    if (this.#implementations.value.find((implementation) => implementation.id === id)) {
      throw new Error('Unable to add authentication implementation. ID already exists');
    }

    this.validateFields(fields);

    this.#implementations.value.push({
      id,
      fields,
      handler
    });
  }

  static replace(
    id: string,
    fields: SdkAuthenticationField[],
    handler: (data: Record<string, unknown>) => Promise<void>
  ): void {
    SdkTypeCheck('string', id);
    SdkTypeCheck('function', handler);

    if (!id) {
      throw new TypeError(`[SDK] id must not be empty`);
    }

    if (!Array.isArray(fields) || fields.length === 0) {
      throw new TypeError(`[SDK] fields must be an array and must not be empty`);
    }

    if (!this.#implementations.value.find((implementation) => implementation.id === id)) {
      throw new Error('Unable to replace authentication implementation. An implementation does not exist with this ID');
    }

    this.validateFields(fields);

    this.remove(id);

    this.#implementations.value.push({
      id,
      fields,
      handler
    });
  }

  static remove(id: string): void {
    SdkTypeCheck('string', id);

    if (!id) {
      throw new TypeError(`[SDK] id must not be empty`);
    }

    if (!this.#implementations.value.find((implementation) => implementation.id === id)) {
      throw new Error('Unable to remove authentication implementation. An implementation does not exist with this ID');
    }

    this.#implementations.value = this.#implementations.value.filter((implementation) => implementation.id !== id);
  }

  static get(id: string): SdkAuthenticationImplementation | undefined {
    SdkTypeCheck('string', id);

    if (!id) {
      throw new TypeError(`[SDK] id must not be empty`);
    }

    return this.#implementations.value.find((implementation) => implementation.id === id);
  }

  private static validateFields(fields: SdkAuthenticationField[]): void {
    fields.forEach((field) => {
      SdkTypeCheck('string', field.id);
      SdkTypeCheck('string', field.type);
      SdkTypeCheck('string', field.label);

      if (!field.id) {
        throw new TypeError(`[SDK] field.id must not be empty`);
      }

      if (!field.type) {
        throw new TypeError(`[SDK] field.type must not be empty`);
      }

      if (!['text', 'email'].includes(field.type)) {
        throw new TypeError(`[SDK] Unsupported field.type. Only text and email is possible to use`);
      }

      if (!field.label) {
        throw new TypeError(`[SDK] field.label must not be empty`);
      }
    });
  }
}
