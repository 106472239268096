import type {
  GameWofAdvancedData,
  GameWofCampaignLimit,
  GameWofCustomLabelsData,
  GameWofData,
  GameWofFieldItemData,
  GameWofGeneralData,
  GameWofMarkerSize,
  GameWofMobileMarkerData,
  GameWofSpinButtonPositionType,
  GameWofSpinButtonType,
  GameWofVisualData,
  WofInstantWinData
} from '@/src/components/games/wof/Data';
import { GameWofMarkerType, GameWofWheelDisplayType } from '@/src/components/games/wof/Data';
import useDevice from '@/src/hooks/useDevice';
import type { GameIndicator, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { DrawType } from '@/src/typings/enums/enums';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import { useUtilityStore } from '@/src/store/utility';
import { extractUnitFromSize, StringUnit, transformMeasurementToNumber } from '@/src/utilities/Utilities';

export interface GameWofVisualState {
  borderColor?: string;
  borderWidth?: number;
  color1?: string;
  color2?: string;
  color3?: string;
  dividerColor?: string;
  fontColor?: string;
  fontSize?: number;
  fontType?: string;
  wheelSize?: number;
  wheelUnit?: StringUnit;
}

interface GameWofGeneralState {
  image: string;
  imageAltText?: string;
  numFields?: number;
  spins: number;
  delay?: number;
  autoDelay?: number;
  spinButton?: GameWofSpinButtonType;
  spinButtonPosition?: GameWofSpinButtonPositionType;
  markerType: GameWofMarkerType;
  markerImage: string;
  markerAltText?: string;
  markerColor?: string;
  markerSize?: GameWofMarkerSize;
  markerPosition?: number;
  markerAnimation: boolean;
  overwriteMobileMarker?: boolean;
  drawType?: DrawType;
  fields: {
    [key: string]: GameWofFieldItemData;
  };
  fieldsOccupied?: number[];
  campaignLimitInterval?: GameWofCampaignLimit;
  campaignLimit?: string;
  visuals?: GameWofVisualState;
  wheelDisplay: GameWofWheelDisplayType;
}

interface GameWolfcustomLabelsState {
  spinButton?: string;
  spinsLeft?: string;
}

interface GameWofMarkerState {
  markerType: GameWofMarkerType;
  markerImage: string;
  markerColor?: string;
  markerSize?: GameWofMarkerSize;
  markerPosition?: number;
  markerAnimation: boolean;
}

export interface GameWofAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GameWofAdvancedState {
  sound: GameWofAdvancedSoundState;
}

interface GameWofWheelSize {
  size: number;
  unit: StringUnit | undefined;
}

export interface GameWofState {
  general: GameWofGeneralState;
  winnerField: number;
  winner?: boolean; // instant win - if instant win enable then this value will return true / false else undefined
  winnerFieldId: number;
  customLabels?: GameWolfcustomLabelsState;
  advanced: GameWofAdvancedState;
  indicators?: GameIndicatorSettingsModel;
  elementStyling?: {
    wofStyle?: string;
    markerStyle?: string;
    markerClasses?: string;
  };
}

export class GameWofModel extends GameModel<GameWofData, GameWofState> implements HasSound {
  parseGame(data: GameWofData) {
    const utilityStore = useUtilityStore();

    const state = this.state;
    state.general = GameWofModel.constructGeneralState(data.general);

    state.general = {
      ...GameWofModel.constructGeneralState(data.general),
      ...GameWofModel.parseDeviceMarkerState(data.general)
    };

    let fieldsOccupied: number[] = [];

    /**
     *  safely handles array or object type converting to state
     */
    if (data?.general?.fields_occupied && Array.isArray(data?.general?.fields_occupied)) {
      fieldsOccupied = data.general.fields_occupied.map((i: number) => Number(i));
    } else if (data?.general?.fields_occupied && !Array.isArray(data?.general?.fields_occupied)) {
      Object.entries(data.general.fields_occupied).forEach((entry) => {
        fieldsOccupied.push(Number(entry[1]));
      });
    }
    if (fieldsOccupied && fieldsOccupied.length > 0) {
      state.general.fieldsOccupied = fieldsOccupied;
    } else {
      state.general.fieldsOccupied = [];
    }

    if (data.customlabels) {
      state.customLabels = GameWofModel.constructCustomLabelsState(data.customlabels);
    }

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameWofModel.constructAdvancedSoundState(data?.advanced);

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    }

    state.elementStyling = state.elementStyling || {};

    if (state?.general?.markerPosition) {
      state.elementStyling.wofStyle = GameWofModel.constructWofStyles(state.general);
    } else {
      state.elementStyling.wofStyle = undefined;
    }
    if (state?.general?.markerColor) {
      state.elementStyling.markerStyle = GameWofModel.constructMarkerStyles(state.general);
    } else {
      state.elementStyling.markerStyle = undefined;
    }

    if (state?.general?.markerType) {
      state.elementStyling.markerClasses = GameWofModel.constructMarkerClasses(state.general);
    } else {
      state.elementStyling.markerClasses = undefined;
    }

    if (state.general.visuals?.fontType) {
      utilityStore.loadFont(state.general.visuals.fontType);
    }
  }

  private static constructAdvancedSoundState(data: GameWofAdvancedData | undefined): GameWofAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructMarkerClasses(state: GameWofGeneralState): string {
    if (state.markerType === GameWofMarkerType.IMAGE) {
      return ` spin__marker--${state.markerType} `;
    }
    if (state.markerType === GameWofMarkerType.ICON) {
      return ` spin__marker--${state.markerSize} `;
    }

    return '';
  }

  private static constructMarkerStyles(state: GameWofGeneralState): string {
    return ` color:${state.markerColor}; `;
  }

  private static constructWofStyles(state: GameWofGeneralState): string {
    return `
          .spin__contents .spin__marker {
            top:-${state.markerPosition}px !important;
          }
        `;
  }

  private static parseDeviceMarkerState(data: GameWofGeneralData): GameWofMarkerState | undefined {
    if (data) {
      const useData = this.getMobileDeviceData(data);

      if (!useData) {
        return undefined;
      }
      return GameWofModel.constructMarkerState(useData);
    }
  }

  public static getMobileDeviceData(data: GameWofGeneralData): GameWofMobileMarkerData {
    const desktopData = data;
    const { isMobile } = useDevice();

    const overwriteMobile =
      typeof desktopData.overwrite_mobile_marker === 'undefined' || desktopData.overwrite_mobile_marker === '1';

    if (isMobile && !overwriteMobile && data.mobile) {
      return data.mobile;
    }
    return desktopData;
  }

  private static constructMarkerState(data: GameWofMobileMarkerData): GameWofMarkerState {
    return {
      markerType: data.marker_type,
      markerImage: data.marker_image,
      ...(data?.marker_color && { markerColor: data.marker_color }),
      ...(data?.marker_size && { markerSize: data.marker_size }),
      ...(data?.marker_position && {
        markerPosition: parseInt(data.marker_position)
      }),
      markerAnimation: data.marker_animation === '1'
    };
  }

  private static constructCustomLabelsState(data: GameWofCustomLabelsData): GameWolfcustomLabelsState {
    return {
      ...(data?.spin_button && { spinButton: data.spin_button }),
      ...(data?.spins_left && { spinsLeft: data.spins_left })
    };
  }

  private static constructVisualState(
    data: GameWofVisualData,
    wheelDisplay: GameWofWheelDisplayType
  ): GameWofVisualState {
    // Default values for when data.wheel_size is not provided
    const defaultWheelSize = wheelDisplay === GameWofWheelDisplayType.COLOR ? 400 : undefined;
    const defaultWheelUnit = StringUnit.PIXEL;

    // Parse wheelSize and wheelUnit from data.wheel_size if available
    let wheelSizeParsed: GameWofWheelSize | undefined;
    if (data.wheel_size) {
      wheelSizeParsed = {
        size: transformMeasurementToNumber(data.wheel_size),
        unit: extractUnitFromSize(data.wheel_size)
      };
    }

    return {
      ...(data?.border_color && { borderColor: data.border_color }),
      ...(data?.border_thickness && { borderWidth: parseInt(data.border_thickness, 10) ?? 0 }),
      ...(data?.color1 && { color1: data.color1 }),
      ...(data?.color2 && { color2: data.color2 }),
      ...(data?.color3 && { color3: data.color3 }),
      ...(data?.divider && { dividerColor: data.divider }),
      ...(data?.font_color && { fontColor: data.font_color }),
      ...(data?.font_size && { fontSize: parseInt(data.font_size, 10) ?? 16 }),
      ...(data?.font_type && { fontType: data.font_type }),
      ...(data?.wheel_size && { wheelSize: parseInt(data.wheel_size, 10) ?? 400 }),
      wheelSize: wheelSizeParsed ? wheelSizeParsed.size : defaultWheelSize,
      wheelUnit: wheelSizeParsed ? wheelSizeParsed.unit : defaultWheelUnit
    };
  }

  private static constructGeneralState(data: GameWofGeneralData): GameWofGeneralState {
    const wheelDisplay: GameWofWheelDisplayType = data.wheel_display ?? 'image';

    return {
      image: data.image,
      ...(data?.image_alt_text && { imageAltText: data.image_alt_text }),
      markerImage: data.marker_image,
      ...(data?.marker_image_alt_text && { markerAltText: data.marker_image_alt_text }),
      markerType: data.marker_type,
      markerAnimation: data.marker_animation === '1',
      ...(data?.num_fields && { numFields: Number(data.num_fields) }),
      spins: Number(data.spins),
      ...(data?.delay && { delay: Number(data.delay) }),
      ...(data?.auto_delay && { autoDelay: Number(data.auto_delay) }),
      ...(data?.spin_button && { spinButton: data.spin_button }),
      ...(data?.spin_button_position && {
        spinButtonPosition: data.spin_button_position
      }),

      ...(data?.overwrite_mobile_marker && {
        overwriteMobileMarker: data.overwrite_mobile_marker === '1'
      }),
      ...(data?.draw_type && { drawType: data.draw_type }),
      ...(data?.campaign_limit_interval && {
        campaignLimitInterval: data.campaign_limit_interval
      }),
      ...(data?.campaign_limit && {
        campaignLimit: data.campaign_limit
      }),
      fields: data.fields,
      ...(data.visuals && {
        visuals: GameWofModel.constructVisualState(data.visuals, wheelDisplay)
      }),
      wheelDisplay
    };
  }

  public setInstantWinnerData(data: WofInstantWinData) {
    super.setInstantWinnerData(data);

    const winnerField = data.winner_field;
    const winnerFieldId = data.winner_field_id;

    if (winnerField) {
      this.state.winnerField = Number(winnerField);
    }

    if (winnerFieldId) {
      this.state.winnerFieldId = Number(winnerFieldId);
    }
  }

  public hasInstantWin(): boolean {
    const state = this.state;
    return state.general.drawType === DrawType.AUTO;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'spins',
        metricKey: {
          spins_left: 'spins_left',
          spins_used: 'spins_used'
        },
        icon: GameIndicatorIcon.SPINS,
        value: {
          spins_left: this.state.general.spins,
          spins_used: 0
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
