import type { AddonRssData, AddonRssSettingData } from '@/src/components/addons/rss/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

interface AddonRssSettingState {
  url?: string;
  html?: string;
  limit?: number;
  random?: boolean;
  items?: Array<string>;
}

interface AddonRssState extends AddonModelState {
  settings: AddonRssSettingState;
  rssData: string;
}

export class AddonRSSModel extends AddonModel<AddonRssData, AddonRssState, 'rss'> {
  async parseAddon(data: AddonRssData): Promise<void> {
    const state = this.state;
    state.alias = data.alias;
    state.settings = state.settings || {};
    state.settings = AddonRSSModel.constructSettingState(data.settings);

    if (!state.settings?.url || state.settings.url === '') {
      state.settings.html = '';
    }
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    return !(!data.settings.url || data.settings.url === '');
  }

  private static constructSettingState(data: AddonRssSettingData): AddonRssSettingState {
    return {
      url: data.url,
      html: data.html,
      limit: Number(data.limit),
      random: data.random === '1'
    };
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
