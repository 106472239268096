import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import type { AddonGameflowData } from '@/src/components/addons/gameflow/Data';

interface AddonGameflowState extends AddonModelState {
  settings: {
    flowScroll?: boolean;
    // FlowScroll used for 'quiz' || 'personality_test' || 'survey' games
    // TODO: need to implement scroll functionality for these games that have flowScroll
  };
}

export class AddonGameflowModel extends AddonModel<AddonGameflowData, AddonGameflowState, 'gameflow'> {
  parseAddon(data: AddonGameflowData) {
    const state = this.state;
    state.settings = state.settings || {};

    if (data.settings?.flow_scroll) {
      state.settings.flowScroll = data.settings.flow_scroll === '1';
    }
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
