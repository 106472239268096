/* eslint-disable */
import { VNode } from 'vue';
import { getCurrentUrl } from './Url';

/**
 * High level API usage of Vue 3. Please beware that the below function works as a recursive function that
 * traverse through the VNode tree looking for components that contain specific functions their returned state.
 *
 * There is a lot of ignores in this file. This i know. But they're required as we're dealing with VNode
 * APIs that is not exposed in VUE's typing.
 *
 * @author Dannie Hansen <dannie@leadfamly.com>
 */
export const collectFunctionsFromEl = (
  el: VNode,
  func: string,
  functions?: Function[],
  debug?: boolean,
  exclude?: VNode
) => {
  functions = functions || [];

  if (debug) {
    console.log('---------------------');
    console.log(el);
  }

  if (el !== exclude) {
    // @ts-ignore-next-line
    if (el?.component?.setupState[func] && !functions.includes(el.component.setupState[func])) {
      // @ts-ignore-next-line
      functions.push(el.component.setupState[func]);
    } else if (
      // @ts-ignore-next-line
      el?.component?.subTree?.component?.setupState[func] &&
      // @ts-ignore-next-line
      !functions.includes(el.component.subTree.component.setupState[func])
    ) {
      // @ts-ignore-next-line
      functions.push(el.component.subTree.component.setupState[func]);
    }
  }

  // @ts-ignore-next-line
  if (el?.component?.subTree?.children?.length > 0 && Array.isArray(el?.component?.subTree?.children)) {
    // @ts-ignore-next-line
    el.component.subTree.children.forEach((child) => {
      // @ts-ignore-next-line
      if (child.type !== undefined) {
        // @ts-ignore-next-line
        collectFunctionsFromEl(child, func, functions, debug);
      }
    });

    // Support for slots. This was used for ClientOnly component to work.
  } else if (
    // @ts-ignore-next-line
    el?.component?.subTree?.children?.default &&
    // @ts-ignore-next-line
    el?.component?.subTree?.children?.default().length > 0 &&
    // @ts-ignore-next-line
    Array.isArray(el?.component?.subTree?.children?.default())
  ) {
    // @ts-ignore-next-line
    el.component.subTree.children.default().forEach((child) => {
      // @ts-ignore-next-line
      if (child.type !== undefined) {
        // @ts-ignore-next-line
        collectFunctionsFromEl(child, func, functions, debug);
      }
    });
  } else if (
    // @ts-ignore-next-line
    el?.children?.length > 0 &&
    Array.isArray(el?.children)
  ) {
    el.children.forEach((child) => {
      // @ts-ignore-next-line
      if (child.type !== undefined) {
        // @ts-ignore-next-line
        collectFunctionsFromEl(child, func, functions, debug);
      }
    });
  } else if (
    // @ts-ignore-next-line
    el?.dynamicChildren?.length > 0 &&
    // @ts-ignore-next-line
    Array.isArray(el?.dynamicChildren)
  ) {
    // @ts-ignore-next-line
    el.dynamicChildren.forEach((child) => {
      // @ts-ignore-next-line
      if (child.type !== undefined) {
        // @ts-ignore-next-line
        collectFunctionsFromEl(child, func, functions, debug);
      }
    });
  } else if (el?.component?.subTree?.component?.vnode) {
    collectFunctionsFromEl(el.component.subTree.component.vnode, func, functions, debug);
  }

  return functions;
};

export async function recursivelyWaitForPromises(
  node: VNode,
  method: string,
  iterations = 3,
  timeout?: number,
  excludeNode?: VNode
): Promise<void> {
  let resolved = false;
  const promises: { promise: Promise<void>; func: Function }[] = [];

  // If we're local testing we would like to know if these recursively wait for promises timeout.
  if (getCurrentUrl().includes('localhost')) {
    timeout = 10000;
  }

  return new Promise((resolve, reject) => {
    if (timeout) {
      setTimeout(() => {
        if (!resolved) {
          resolved = true;
          console.error(
            '[ERROR] Timed out waiting for promises on vnode:',
            node,
            'on the following promises:',
            promises
          );

          reject('Timed out waiting recursively for promises.');
        }
      }, timeout);
    }

    (async () => {
      for (let i = 0; i < iterations; i++) {
        await Promise.all(
          collectFunctionsFromEl(node, method, [], false, excludeNode ?? node)
            .map((func) => {
              const promise = func();

              if (timeout) {
                promises.push({
                  promise,
                  func
                });
              }

              return promise;
            })
            .filter((returnValue) => returnValue instanceof Promise)
        );
      }

      if (!resolved) {
        resolved = true;
        resolve();
      }
    })();
  });
}
/* eslint-enable */
