import type { InlineElement } from './typing/interfaces';
import { InlineHeightEnum } from './typing/interfaces';
import { useCampaignStore } from '@/src/store/campaign';
import type { AdvancedStyleState, SettingsState } from '@/src/components/layout/SettingsModel';
import { SettingsModel } from '@/src/components/layout/SettingsModel';
import type { AdvancedStyleData, SettingsData } from '@/src/typings/interfaces/data/settings/settings';
import { PopupPositionType } from '@/src/typings/interfaces/data/settings/settings';
import type { VisibilityConditionsData } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import { CampaignAdsSizeType, CampaignDeviceType, HeightType } from '@/src/typings/enums/enums';

export class FlowPageSettingsModel extends SettingsModel {
  constructAdvancedStyleState(data: AdvancedStyleData): AdvancedStyleState {
    const style = super.constructAdvancedStyleState(data);
    const campaignStore = useCampaignStore();

    if (campaignStore.model?.state.isPopup) {
      style.margin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      };

      const position = this.state.basic?.position?.type ?? PopupPositionType.BOTTOM_LEFT;
      const positionX = this.state.basic?.position?.x ?? 0;
      const positionY = this.state.basic?.position?.y ?? 0;

      switch (position) {
        case 'top_left':
          style.margin.left = positionX;
          style.margin.top = positionY;
          break;

        case 'top_center':
          style.margin.left = 0;
          style.margin.top = positionY;
          break;

        case 'top_right':
          style.margin.right = positionX;
          style.margin.top = positionY;
          break;

        case 'center_center':
          style.margin.left = 0;
          style.margin.top = 0;
          break;

        case 'bottom_left':
          style.margin.left = positionX;
          style.margin.bottom = positionY;
          break;

        case 'bottom_center':
          style.margin.left = 0;
          style.margin.bottom = positionY;
          break;

        case 'bottom_right':
          style.margin.right = positionX;
          style.margin.bottom = positionY;
          break;
      }
    }

    return style;
  }

  protected constructElementInlineStyles(state: SettingsState): InlineElement {
    const inlineStyle = super.constructElementInlineStyles(state);
    const campaignStore = useCampaignStore();

    if (state.basic?.height?.type === HeightType.FIXED && state.basic.height.fixed) {
      inlineStyle.height = {
        type: InlineHeightEnum.FIXED,
        value: state.basic.height.fixed
      };
    } else if (state.basic?.height?.type === HeightType.WINDOW) {
      inlineStyle.height = {
        type: InlineHeightEnum.WINDOW,
        subtractWindow: state.basic.height.subtractWindow,
        subtractFormat: state.basic.height.subtractFormat
      };

      if (
        campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
        campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.FIXED
      ) {
        inlineStyle.overflowY = 'hidden';
        inlineStyle.overflowX = 'hidden';
      } else {
        inlineStyle.overflowY = 'auto';
        inlineStyle.overflowX = 'hidden';
      }
    }

    return inlineStyle;
  }

  public getDefaultPadding() {
    return 30;
  }

  protected constructVisibilityConditionData(data: SettingsData): VisibilityConditionsData {
    // Type casting to number so that '0' as a string doesn't get seen as enabled.
    const showOnDesktop = Number(data.show_on_desktop ?? '1') ? '1' : '0';
    const showOnMobile = Number(data.show_on_mobile ?? '1') ? '1' : '0';

    return {
      ...(data.advanced?.visibility_condition ?? {}),
      devices: {
        desktop: showOnDesktop,
        tablet: showOnDesktop,
        mobile: showOnMobile
      }
    };
  }

  authorSignature(): string {
    return 'Dannie Hansen';
  }
}
