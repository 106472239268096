import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import type { AddonTipAFriendData } from '@/src/components/addons/tipafriend/Data';
import type { SectionType } from '@/src/typings/enums/enums';
import {
  AlignContentType,
  ButtonStyleType,
  FieldHtmlInputType,
  FieldInputType,
  FieldType
} from '@/src/typings/enums/enums';
import { InputStyleType } from '@/src/components/addons/registration/enums';
import type { FormElementModel } from '@/src/components/addons/registration/types';
import { FormElementMapping } from '@/src/components/addons/registration/types';

interface AddonTipAFriendState extends AddonModelState {
  isForm: boolean;
  formId: string;
  addonValid: boolean;
  button: TipAFriendButtonState;
  appearance?: TipAFriendAppearanceState;
  appearanceStyling: string;
  fields: FormElementModel[];
  endpoint: string;
  uniqueClass: string;
  sectionType: SectionType;
  confirmMessage?: string;
  sectionId: number;
}

interface TipAFriendButtonState {
  label?: string;
  type: ButtonStyleType;
  alignment?: AlignContentType;
}

interface TipAFriendButtonState {
  label?: string;
  type: ButtonStyleType;
  alignment?: AlignContentType;
}

export interface TipAFriendAppearanceState {
  inputStyle?: InputStyleType;
  backgroundColor?: string;
  strokeColor?: string;
  textColor?: string;
  placeholderColor?: string;
  labelColor?: string;
}

export interface FieldState {
  id: string;
  name: string;
  label: string;
  value: string;
  order: number;
  type: number | string;
  input_type: FieldInputType;
  required: boolean;
  exportable: number;
  default_value?: string;
  input_html_type?: FieldHtmlInputType;
  visible: boolean;
}

export class AddonTipAFriendModel extends AddonModel<AddonTipAFriendData, AddonTipAFriendState, 'tipafriend'> {
  parseAddon(data: AddonTipAFriendData) {
    const state = this.state;
    const section = this.getSection();
    state.isForm = true;

    state.formId = `form-tipafriend_${section.state.id}`;
    state.sectionType = section.getSectionType();
    state.sectionId = section.state.id;
    state.alias = data.alias;
    state.button = AddonTipAFriendModel.parseButtonData(data);

    if (data.settings.confirmation_message) {
      state.confirmMessage = data?.settings.confirmation_message;
    }

    state.uniqueClass = `tipafriend-${state.id}`;

    if (data.settings.appearance) {
      state.appearance = AddonTipAFriendModel.constructAppearanceState(data);

      if (state.appearance) {
        state.appearanceStyling = AddonTipAFriendModel.constructAppearanceStyling(state.appearance, state.uniqueClass);
      }
    }

    if (data.settings.endpoint) {
      state.endpoint = data.settings.endpoint;
    }

    // always add friends email

    const fields: FieldState[] = [];

    if (data.settings.your_name_label === undefined || data.settings.your_name_label) {
      fields.push({
        id: 'tipafriend_name',
        name: 'tipafriend_name',
        label: data.settings.your_name_label || 'Your name',
        value: '',
        default_value: '',
        order: 0,
        type: FieldType.NAME,
        input_type: FieldInputType.TEXT,
        input_html_type: FieldHtmlInputType.TEXT,
        required: false,
        exportable: 0,
        visible: true
      });
    }
    if (data.settings.your_email_label === undefined || data.settings.your_email_label) {
      fields.push({
        id: 'tipafriend_email',
        name: 'tipafriend_email',
        label: data.settings.your_email_label || 'Your email',
        default_value: '',
        value: '',
        order: 0,
        input_type: FieldInputType.TEXT,
        type: FieldType.EMAIL,
        input_html_type: FieldHtmlInputType.EMAIL,
        required: false,
        exportable: 0,
        visible: true
      });
    }
    if (data.settings.friend_name_label === undefined || data.settings.friend_name_label) {
      fields.push({
        id: 'tipafriend_friend_name',
        name: 'tipafriend_friend_name',
        label: data.settings.friend_name_label || "Your friend's name",
        default_value: '',
        value: '',
        order: 1,
        type: FieldType.NAME,
        input_type: FieldInputType.TEXT,
        input_html_type: FieldHtmlInputType.TEXT,
        required: false,
        exportable: 0,
        visible: true
      });
    }
    fields.push({
      id: 'tipafriend_friend_email',
      name: 'tipafriend_friend_email',
      label: data.settings.friend_email_label ? data.settings.friend_email_label : "Your friend's email",
      default_value: '',
      value: '',
      order: 2,
      type: FieldType.EMAIL,
      input_type: FieldInputType.TEXT,
      required: true,
      input_html_type: FieldHtmlInputType.EMAIL,
      exportable: 0,
      visible: true
    });

    if (data.settings.message_label === undefined || data.settings.message_label) {
      fields.push({
        id: 'tipafriend_message',
        name: 'tipafriend_message',
        label: data.settings.message_label || 'Message',
        value: data.settings.prefilled_message ? data.settings.prefilled_message : '',
        default_value: data.settings.prefilled_message ? data.settings.prefilled_message : '',
        order: 3,
        type: FieldType.TEXTAREA,
        input_type: FieldInputType.TEXTAREA,
        input_html_type: FieldHtmlInputType.TEXTAREA,
        required: false,
        exportable: 0,
        visible: true
      });
    }

    const fieldMap: { [key: string]: FormElementModel } = {};
    if (state.fields) {
      state.fields.forEach((field) => {
        fieldMap[field.id] = field;
      });
    }

    if (fields) {
      state.fields = fields.map((field) => {
        if (typeof fieldMap[field.id] !== 'undefined') {
          // TODO: Investigate if we can avoid ts-ignore here with other way of typing.
          // @ts-ignore
          fieldMap[field.id].setData(field);
          return fieldMap[field.id];
        }

        if (typeof FormElementMapping[field.input_type] === 'undefined') {
          throw new TypeError(`Unrecognized field input_type: ${field.input_type}`);
        }

        // @ts-ignore
        return new FormElementMapping[field.input_type](field);
      });
    } else {
      state.fields = [];
    }
  }

  private static parseButtonData(data: AddonTipAFriendData) {
    return AddonTipAFriendModel.constructButtonState(data);
  }

  private static constructButtonState(data: AddonTipAFriendData): TipAFriendButtonState {
    const buttonData = data.settings.button;

    return {
      alignment: buttonData?.alignment || AlignContentType.CENTER,
      label: buttonData?.label || 'Invite',
      type: buttonData?.type || ButtonStyleType.PRIMARY
    };
  }

  private static constructAppearanceState(data: AddonTipAFriendData): TipAFriendAppearanceState | undefined {
    const appearanceData = data.settings.appearance;

    if (!appearanceData) {
      return undefined;
    }

    return {
      ...{
        inputStyle: appearanceData.input_style
      },
      ...(appearanceData.background_color && {
        backgroundColor: appearanceData.background_color
      }),
      ...(appearanceData.stroke_color && {
        strokeColor: appearanceData.stroke_color
      }),
      ...(appearanceData.text_color && {
        textColor: appearanceData.text_color
      }),
      ...(appearanceData.placeholder_color && {
        placeholderColor: appearanceData.placeholder_color
      }),
      ...(appearanceData.label_color && {
        labelColor: appearanceData.label_color
      })
    };
  }

  private static constructAppearanceStyling(appearance: TipAFriendAppearanceState, className: string) {
    const elementStyle: string[] = [];

    if (appearance.backgroundColor) {
      elementStyle.push(`#prefix#.content__item--form .form-group .form-control {
                        background-color: ${appearance.backgroundColor} !important;
                        padding-left: 15px;
                        padding-right: 15px;
                      }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element .content__item-form-group .content__item-form-label:not(.form-check-label) {
              -webkit-transform: translateY(25px) translateX(15px);
              -moz-transform: translateY(25px) translateX(15px);
              transform: translateY(25px) translateX(15px);
            }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .content__item-form-label {
              -webkit-transform: translateY(30px) translateX(15px);
              -moz-transform: translateY(30px) translateX(15px);
              transform: translateY(30px) translateX(15px);
              opacity: 1;
            }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element .form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
              #prefix#.content__item--form .content__item-form-element .form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
              #prefix#.content__item--form .content__item-form-element .form-group--has-value:not(.form-group--fixed-label) .content__item-form-label,
              #prefix#.content__item-form-element .content__item-form-group--select.form-group--has-value .content__item-form-label {
              -webkit-transform: scale(0.8) translateY(-9px) translateX(0);
              -moz-transform: scale(0.8) translateY(-9px) translateX(0);
              transform: scale(0.8) translateY(-9px) translateX(0);
            }`);
    }
    if (appearance.inputStyle) {
      elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-group .form-control,
                      #prefix#.content__item--form .content__item-form-element--textarea .form-group .form-control {
                        border: 1px solid ${
                          appearance.strokeColor ? appearance.strokeColor : 'rgba(0,0,0,.12)'
                        } !important;
                        padding-left: 15px;
                        padding-right: 15px;
                        height: 4rem;
                      }`);
      elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-group.form-group--error .form-control,
                      #prefix#.content__item--form .content__item-form-element--textarea .form-group.form-group--error .form-control,
                      #prefix#.content__item-form-group--select.form-group--error .form-control {
                        border-color: #F44336 !important;
                        color: #F44336 !important;
                      }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-group.form-group--error .content__item-form-label,
                      #prefix#.content__item--form .content__item-form-element--textarea .form-group.form-group--error .content__item-form-label {
                        color: #F44336 !important;
                      }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element--textarea .content__item-form-label {
                        position: absolute;
                        top: -0.6rem;
                      }`);

      elementStyle.push(`#prefix#.form-group.content__item-form-group::before,
                      .form-group.content__item-form-group::after,
                      .content__item-form-group--select::before,
                      .content__item-form-group--select::after {
                        display: none;
                      }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element.content__item-form-element--text .content__item-form-group .content__item-form-label,
                      #prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .content__item-form-group .content__item-form-label,
                      #prefix#.content__item--form .content__item-form-element .content__item-form-group--select .content__item-form-label {
                        -webkit-transform: translateY(25px) translateX(15px);
                        -moz-transform: translateY(25px) translateX(15px);
                        transform: translateY(25px) translateX(15px);
                        opacity: 1;
                        -webkit-transition: none;
                        transition: none;
                        line-height: 3.8rem;
                      }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element-message {
                        margin-top: 0.4rem !important;
                      }`);

      if (
        appearance.inputStyle === InputStyleType.CLASSIC ||
        appearance.inputStyle === InputStyleType.CLASSIC_ROUNDED
      ) {
        elementStyle.push(`#prefix#.content__item--form .content__item-form-element .form-group:before,
                        #prefix#.content__item--form .content__item-form-element .form-group:after {
                          display: none;
                        }`);
      }
      if (
        appearance.inputStyle !== InputStyleType.COLUMNS &&
        appearance.inputStyle !== InputStyleType.COLUMNS_ROUNDED &&
        appearance.inputStyle !== InputStyleType.LABELS_ABOVE
      ) {
        elementStyle.push(`#prefix#.content__item--form .content__item-form-element.content__item-form-element--text .form-group--has-value .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .form-group--has-value .content__item-form-label,
                        #prefix#.content__item-form-element .content__item-form-group--select.form-group--has-value .content__item-form-label {
                          display: none;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element .form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
                #prefix#.content__item--form .content__item-form-element .form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
                #prefix#.content__item--form .content__item-form-element .form-group--has-value:not(.form-group--fixed-label) .content__item-form-label {
                  visibility: hidden;
                  opacity: 0;
                  -webkit-transition: none;
                  transition: none;
                }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-group,
                        #prefix#.content__item--form .content__item-form-element--paragraph .form-group {
                          padding-bottom: 0;
                        }`);
      }
      if (
        appearance.inputStyle === InputStyleType.CLASSIC_ROUNDED ||
        appearance.inputStyle === InputStyleType.COLUMNS_ROUNDED
      ) {
        elementStyle.push(`#prefix#.content__item--form .form-group .form-control {
                          -moz-border-radius: 5px;
                          -webkit-border-radius: 5px;
                          border-radius: 5px;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element-message {
                          -moz-border-radius: 5px;
                          -webkit-border-radius: 5px;
                          border-radius: 5px;
                        }`);
      }
      if (
        appearance.inputStyle === InputStyleType.COLUMNS ||
        appearance.inputStyle === InputStyleType.COLUMNS_ROUNDED
      ) {
        elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-group .form-control,
                        #prefix#.content__item--form .content__item-form-element--textarea .form-group .form-control {
                          margin-left: 15rem;
                          width: -webkit-calc(100% - 15rem);
                          width: -moz-calc(100% - 15rem);
                          width: calc(100% - 15rem);
                          height: 3.8rem;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element.content__item-form-element--text .content__item-form-group .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .content__item-form-group .content__item-form-label {
                          -webkit-transform: translateY(24px);
                          -moz-transform: translateY(24px);
                          transform: translateY(24px);
                          width: 14rem;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group--select.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label {
                          -webkit-transform: translateY(24px) scale(1) !important;
                          -moz-transform: translateY(24px) scale(1) !important;
                          transform: translateY(24px) scale(1) !important;
                          opacity: 1 !important;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element-message {
                          max-width: calc(100% - 15rem) !important;
                          left: 15rem !important;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element .form-group:before,
                          #prefix#.content__item--form .content__item-form-element .form-group:after {
                          display: none;
                        }`);
      }
      if (
        appearance.inputStyle === InputStyleType.COLUMNS ||
        appearance.inputStyle === InputStyleType.COLUMNS_ROUNDED
      ) {
        elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-group .form-control,
                        #prefix#.content__item--form .content__item-form-element--textarea .form-group .form-control {
                          margin-left: 15rem;
                          width: -webkit-calc(100% - 15rem);
                          width: -moz-calc(100% - 15rem);
                          width: calc(100% - 15rem);
                          height: 3.8rem;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element.content__item-form-element--text .content__item-form-group .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .content__item-form-group .content__item-form-label {
                          -webkit-transform: translateY(24px);
                          -moz-transform: translateY(24px);
                          transform: translateY(24px);
                          width: 14rem;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group--select.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label {
                          -webkit-transform: translateY(24px) scale(1) !important;
                          -moz-transform: translateY(24px) scale(1) !important;
                          transform: translateY(24px) scale(1) !important;
                          opacity: 1 !important;
                        }`);

        elementStyle.push(`#prefix#content__item--form .content__item-form-element-message {
                          max-width: calc(100% - 15rem) !important;
                          left: 15rem !important;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element .form-group:before,
                        .content__item--form .content__item-form-element .form-group:after {
                          display: none;
                        }`);
      }
      if (appearance.inputStyle === InputStyleType.LABELS_ABOVE) {
        elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-group,
                        #prefix#.content__item--form .content__item-form-element--typeahead .form-group {
                          padding-top: 0.8rem;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element--textarea .content__item-form-label {
                          position:static;
                          -webkit-transform: translateY(0px) translateX(0px) !important;
                          -moz-transform: translateY(0px) translateX(0px) !important;
                          transform: translateY(0px) translateX(0px) !important;
                          opacity: 1 !important;
                          height: auto;
                        }`);

        elementStyle.push(`#prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label,
                        #prefix#.content__item--form .content__item-form-element .content__item-form-group--select.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label {
                          -webkit-transform: translateY(0px) translateX(0px) !important;
                          -moz-transform: translateY(0px) translateX(0px) !important;
                          transform: translateY(0px) translateX(0px) !important;
                          opacity: 1 !important;
                          visibility: visible !important;
                        }`);

        elementStyle.push(`.content__item--form .content__item-form-element-message {
                          max-width: calc(100% - 15rem) !important;
                          left: 15rem !important;
                        }`);
      }
    } // end of inputStyle
    if (appearance.textColor) {
      elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-control,
                         #prefix#.content__item--form .content__item-form-element--textarea .form-control {
                            color: ${appearance.textColor} !important;
                         }`);
      elementStyle.push(`.content__item--form .form-element__message {
                        color: ${appearance.textColor} !important;
                      }`);
    }
    if (appearance.strokeColor) {
      elementStyle.push(`#prefix#.form-group.content__item-form-group::before {
                        border-color: ${appearance.strokeColor};
                      }`);
      elementStyle.push(`#prefix#.form-group.content__item-form-group::after {
                        background-color:${appearance.strokeColor};
                      }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element-message {
                        background-color:${appearance.strokeColor} !important;
                      }`);

      elementStyle.push(`#prefix#.text-field.text-field--theme-light::before,
                      #prefix# .input-file:before,
                      #prefix# .content-type-form .lx-select::before {
                        border-color: ${appearance.strokeColor} !important;
                      }`);

      elementStyle.push(`#prefix#.text-field.text-field--theme-light:not(.text-field--error):not(.text-field--valid)::after,
                         #prefix#.form-element-type-file:not(.invalid) .input-file::after,
                         #prefix#.content-type-form .lx-select:not(.lx-select--error)::after {
                           background-color: ${appearance.strokeColor} !important;
                         }`);
    }
    if (appearance.placeholderColor) {
      elementStyle.push(`#prefix#.content__item--form .content__item-form-label,
                         #prefix#.content__item-form-group--select .content__item-form-label,
                         #prefix#.content__item-form-element--paragraph {
                          color: ${appearance.placeholderColor} !important;
                         }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element--text .form-group .content__item-form-label:not(.content__item-form-label--checkbox):not(.content__item-form-label--radio):not(.content__item-form-label--textarea),
                         #prefix#.content__item-form-element--paragraph {
                          left: 15px;
                        }`);
    }
    if (appearance.labelColor) {
      elementStyle.push(`#prefix#.content__item--form .content__item-form-element--checkbox .content__item-form-label,
                         #prefix#.content__item--form .content__item-form-element--radio .content__item-form-label,
                         #prefix#.content__item-form-checkboxes-label,
                         #prefix#.content__item-form-element--file .content__item-form-label ,
                         #prefix#.content__item-form-element--paragraph  {
                          color: ${appearance.labelColor} !important;
                         }`);

      elementStyle.push(`#prefix#.content__item--form .content__item-form-element--checkbox .form-check--error .content__item-form-label,
                         #prefix#.content__item-form-element--radio .form-check--error .content__item-form-label {
                          color: #F44336 !important;
                         }`);
    }

    const styleRules: string[] = [];
    const CSSPrefixSection = '.site .section ' + '.' + className + ' ';
    const CSSPrefixPopover = '.site .page-modal ' + '.' + className + ' ';
    const CSSPrefixFlow = '.site .flow-page ' + '.' + className + ' ';

    if (elementStyle.length > 0) {
      for (const styleRule in elementStyle) {
        styleRules.push(elementStyle[Number(styleRule)].replace(/#prefix#/gi, CSSPrefixSection));
        styleRules.push(elementStyle[Number(styleRule)].replace(/#prefix#/gi, CSSPrefixPopover));
        styleRules.push(elementStyle[Number(styleRule)].replace(/#prefix#/gi, CSSPrefixFlow));
      }
    }
    return styleRules.join('');
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
