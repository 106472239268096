import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GamePlaceTheItemAdvancedData,
  GamePlaceTheItemData,
  GamePlaceTheItemGeneralData,
  GamePlaceTheItemPictureData
} from '@/src/components/games/placetheitem/Data';
import useDevice from '@/src/hooks/useDevice';
import type { BackgroundSizeType } from '@/src/typings/enums/enums';
import type { GamePlaceTheItemLayoutType } from '@/src/components/games/placetheitem/enums';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import type { TransitionTypes } from '@/src/typings/types/types';

export interface GamePlaceTheItemGeneralPlacementState {
  backgroundImage: string;
  backgroundPosition: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  overlayColor: string;
  backgroundType: BackgroundSizeType;
  height: number;
}

export interface GamePlaceTheItemGeneralState {
  picturesCount: number;
  correctGuesses: number;
  layout: GamePlaceTheItemLayoutType;
  hideImages: boolean;
  left: GamePlaceTheItemGeneralPlacementState;
  right: GamePlaceTheItemGeneralPlacementState;
  overwriteMobileScreen: boolean;
  screenHeight: number;
  screenHeightOverwrite: boolean;
  showNextButton: boolean;
  nextButton: string;
  enableBestTime: boolean;
  enableTimeRanking: boolean;
}

export interface GamePlaceTheItemPictureState {
  id: number;
  image: string;
  position: string;
  label?: string;
}

export interface DropzoneData {
  backgroundImage: string;
  backgroundPosition: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  backgroundType: BackgroundSizeType;
  height: number;
  id: number;
  isDetected: boolean;
  overlayColor: string;
  position: string;
}

export interface GamePlaceTheItemAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GamePlaceTheItemAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

export interface GamePlaceTheItemAdvancedState {
  sound: GamePlaceTheItemAdvancedSoundState;
  animation: GamePlaceTheItemAdvancedAnimationState;
}

export interface GamePlaceTheItemState {
  general: GamePlaceTheItemGeneralState;
  pictures: GamePlaceTheItemPictureState[];
  advanced: GamePlaceTheItemAdvancedState;
  elementStyling: {
    layoutClass?: string;
  };
}

export class GamePlaceTheItemModel
  extends GameModel<GamePlaceTheItemData, GamePlaceTheItemState>
  implements HasGameTimeChallenge, HasSound
{
  parseGame(data: GamePlaceTheItemData) {
    const state = this.state;

    state.general = state.general ?? {};
    state.general = GamePlaceTheItemModel.constructGeneralState(data.general);

    if (state.general.screenHeight) {
      state.general.right.height = state.general.screenHeight;
      state.general.left.height = state.general.screenHeight;
    }

    state.pictures = state.pictures || [];

    if (data.pictures.picture) {
      state.pictures = [];
      for (const key in data.pictures.picture) {
        if (Object.hasOwnProperty.call(data.pictures.picture, key)) {
          state.pictures.push(
            GamePlaceTheItemModel.constructPictureState(data.pictures.picture[Number(key)], state.pictures)
          );
        }
      }
    }

    state.advanced = state.advanced ?? {};

    state.advanced.sound = GamePlaceTheItemModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GamePlaceTheItemModel.constructAdvancedAnimationState(data?.advanced);

    state.elementStyling = {};
    state.elementStyling.layoutClass = `placetheitem placetheitem--layout-${state.general.layout.replace('_', '-')}`;
  }

  private static constructAdvancedAnimationState(
    data: GamePlaceTheItemAdvancedData | undefined
  ): GamePlaceTheItemAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private static constructAdvancedSoundState(
    data: GamePlaceTheItemAdvancedData | undefined
  ): GamePlaceTheItemAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructPictureState(
    data: GamePlaceTheItemPictureData,
    existingPictures?: GamePlaceTheItemPictureState[]
  ): GamePlaceTheItemPictureState {
    const id = Number(data.id);
    const existingPicture = existingPictures ? existingPictures.find((picture) => picture.id === id) : undefined;

    if (existingPicture) {
      existingPicture.image = data.image;
      existingPicture.position = data.position;

      if (data.label) {
        existingPicture.label = data.label;
      } else if (existingPicture.label !== undefined) {
        delete existingPicture.label;
      }

      return existingPicture;
    }

    return {
      id,
      image: data.image,
      position: data.position,
      ...(data.label && {
        label: data.label
      })
    };
  }

  private static constructGeneralPlacementLeftState(
    data: GamePlaceTheItemGeneralData
  ): GamePlaceTheItemGeneralPlacementState {
    if (useDevice().isMobile && data.mobile?.left) {
      const leftData = data.mobile?.left;
      return {
        backgroundImage: leftData?.background_image,
        backgroundType: leftData?.background_type,
        backgroundPosition: leftData?.background_position,
        overlayColor: leftData?.overlay_color,
        height: 250
      };
    }

    return {
      backgroundImage: data.left.background_image,
      backgroundType: data.left.background_type,
      backgroundPosition: data.left.background_position,
      overlayColor: data.left.overlay_color,
      height: 500
    };
  }

  private static constructGeneralPlacementRightState(
    data: GamePlaceTheItemGeneralData
  ): GamePlaceTheItemGeneralPlacementState {
    if (useDevice().isMobile && data.mobile?.right) {
      const rightData = data.mobile?.right;
      return {
        backgroundImage: rightData?.background_image,
        backgroundType: rightData?.background_type,
        backgroundPosition: rightData?.background_position,
        overlayColor: rightData?.overlay_color,
        height: 250
      };
    }

    return {
      backgroundImage: data.right.background_image,
      backgroundType: data.right.background_type,
      backgroundPosition: data.right.background_position,
      overlayColor: data.right.overlay_color,
      height: 500
    };
  }

  private static parseScreenHeight(data: GamePlaceTheItemGeneralData) {
    if (useDevice().isMobile && data.screen_height_mobile) {
      if (data.screen_height_mobile === '') {
        return 250;
      }
      return Number(data.screen_height_mobile);
    }

    if (data.screen_height === '') {
      return 500;
    } else {
      return Number(data.screen_height);
    }
  }

  private static constructGeneralState(data: GamePlaceTheItemGeneralData): GamePlaceTheItemGeneralState {
    return {
      picturesCount: Number(data.pictures),
      correctGuesses: Number(data.correct_guesses),
      layout: data.layout,
      hideImages: data.hide_images === '1',
      left: GamePlaceTheItemModel.constructGeneralPlacementLeftState(data),
      right: GamePlaceTheItemModel.constructGeneralPlacementRightState(data),
      overwriteMobileScreen: data.overwrite_mobile_screen === '1',
      screenHeight: GamePlaceTheItemModel.parseScreenHeight(data),
      screenHeightOverwrite: data.screen_height_overwrite === '1',
      showNextButton: data.show_next_button === '1',
      nextButton: data.next_button,
      enableBestTime: data.enable_best_time === '1',
      enableTimeRanking: data.enable_time_ranking === '1'
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit ?? 0,
          time_used: 1
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
