import type { GameLuckyNumberData, GameLuckyNumberGeneralData } from '@/src/components/games/luckynumber/Data';
import type { GameIndicator } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';

export interface GameLuckyNumberGeneralState {
  defaultValue: string; // backend makes it possible to write an alternative label here.
  maxLength?: number;
  inputLabel?: string;
  submitBtn?: string;
}

export interface GameLuckyNumberState {
  general: GameLuckyNumberGeneralState;
}

export class GameLuckyNumberModel extends GameModel<GameLuckyNumberData, GameLuckyNumberState> {
  parseGame(data: GameLuckyNumberData): void {
    const state = this.state;
    state.general = GameLuckyNumberModel.constructGeneralState(data.general);
  }

  private static constructGeneralState(data: GameLuckyNumberGeneralData): GameLuckyNumberGeneralState {
    return {
      defaultValue: data.default_value ?? '',
      ...(data?.max_length && { maxLength: Number(data.max_length) }),
      ...(data?.input_label && { inputLabel: data.input_label }),
      ...(data?.submit_btn && { submitBtn: data.submit_btn })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }
}
