<template>
  <svg class="game-indicator-item__icon-path" width="41px" height="37px" viewBox="0 0 41 37">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M10.6564337,2.11840293 C12.8140618,2.11840293 14.9830202,2.99251467 16.6588373,4.74073815 L19.727212,7.95155228 C20.1297103,8.37542923 20.8937016,8.37542923 21.2956603,7.95155228 L24.3473093,4.75741351 C27.6989435,1.26042864 32.9837563,1.26042864 36.3353906,4.75741351 C39.6870248,8.25439837 39.6870248,13.9014292 36.3353906,17.398414 C31.0586709,22.9039732 25.779793,28.4170632 20.5030733,33.9226223 L4.67075596,17.3817387 C1.32181943,13.8804505 1.31912173,8.23772301 4.67075596,4.74073815 C6.34657308,2.99251467 8.49880569,2.11840293 10.6564337,2.11840293 L10.6564337,2.11840293 Z M10.6564337,9.39951584e-05 C7.91825862,9.39951584e-05 5.19519068,1.07915132 3.11957302,3.24479677 C-1.03112276,7.57554975 -1.04299268,14.5270242 3.10284722,18.8610047 L19.7110258,36.225974 C20.1129845,36.649313 20.8769758,36.649313 21.278395,36.225974 C26.8189507,30.4455403 32.3465574,24.6581137 37.8865735,18.87768 C42.0378088,14.5469271 42.0378088,7.59276303 37.8865735,3.26147213 C33.7353382,-1.06928086 26.9473616,-1.06928086 22.7961263,3.26147213 L20.5030733,5.63152525 L18.2100202,3.24479677 C15.97254,0.901101479 13.1998342,-0.0106643032 10.6564337,9.39951584e-05 Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ScoreIndicator'
});
</script>
