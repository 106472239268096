/*
Emailable.com email verification
Docs: https://emailable.com/docs/api/#emails
*/
import axios from 'axios';
import type { BaseEmailValidationData, BaseEmailValidationState } from '../BaseValidationModel';
import { BaseValidationModel } from '../BaseValidationModel';
import EmailValidationApiError from '../EmailValidationApiError';
import EmailValidationError from '../EmailValidationError';
import useAxios from '@/src/hooks/useAxios';
import { useCampaignStore } from '@/src/store/campaign';

export interface EmailableValidationData extends BaseEmailValidationData {
  score: number;
}

interface EmailableValidationState extends BaseEmailValidationState {
  score: number;
}

interface EmailableValidationResponseData {
  score: number;
  state: string;
  [key: string]: string | number;
}

export class EmailableValidationModel extends BaseValidationModel<EmailableValidationData, EmailableValidationState> {
  public parseValidationSettings(data: EmailableValidationData): void {
    this.state.score = data.score;
  }

  public async validate(email: string): Promise<string> {
    const validation = 'https://api.emailable.com/v1/verify';
    const campaignStore = useCampaignStore();
    let response: EmailableValidationResponseData | undefined;

    try {
      const { fetchData } = useAxios<EmailableValidationResponseData>(validation, {
        email, // The email you want verified.
        api_key: this.state.apiKey, // Your API key.
        smtp: true, // The SMTP step takes up the majority of the API's response time. If you would like to speed up your response times, you can disable this step. However, this will significantly decrease verification accuracy. Default: true
        accept_all: false, // Whether or not an accept-all check is performed. Heavily impacts API's response time. Default: false
        timeout: 10 // Optional timeout to wait for response (in seconds). Min: 2, Max: 30. Default: 5
      });

      response = await fetchData();
      campaignStore.addStaticFormData({
        emailValidationResponse: response
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        campaignStore.addStaticFormData({
          emailValidationResponse: { ...e.response?.data, statusCode: e.response?.status }
        });

        throw new EmailValidationApiError('Not validated', 'Error connecting to Emailable');
      }
      throw e;
    }

    if (!response) {
      throw new EmailValidationApiError('Not validated', 'No result from Emailable');
    }

    if (
      !(
        (this.state.status.length > 0 && this.state.status.includes(response.state)) ||
        response.score >= this.state.score
      )
    ) {
      throw new EmailValidationError(response.state, 'ok');
    }

    return response.state;
  }
}
