import type { Component } from 'vue';
import type { IntegrationGrundfosAdobeItemData, IntegrationGrundfosData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

interface AdobeItemState {
  companyCode: string;
  language: string;
  pageName: string;
  rsid: string;
  siteCountry: string;
  permissions?: { [key: number]: string[] };
}

export interface IntegrationGrundfosState extends BaseIntegrationState {
  tracking?: {
    adobe?: AdobeItemState;
  };
}

export class IntegrationGrundfosModel extends BaseIntegration<IntegrationGrundfosData, IntegrationGrundfosState> {
  parse(data: IntegrationGrundfosData) {
    super.parse(data);

    this.state.tracking = this.state.tracking || {};

    if (data.settings?.tracking?.adobe) {
      this.state.tracking.adobe = this.constructsAdobeState(data.settings.tracking.adobe, this.state.tracking.adobe);
    } else {
      this.state.tracking = undefined;
    }
  }

  constructsAdobeState(data: IntegrationGrundfosAdobeItemData, adobeState?: AdobeItemState): AdobeItemState {
    if (adobeState) {
      adobeState.companyCode = data.company_code;
      adobeState.language = data.language;
      adobeState.pageName = data.page_name;
      adobeState.rsid = data.rsid;
      adobeState.siteCountry = data.site_country;

      return adobeState;
    } else {
      return {
        companyCode: data.company_code,
        language: data.language,
        pageName: data.page_name,
        rsid: data.rsid,
        siteCountry: data.site_country,
        ...(data.permissions && {
          permissions: data.permissions
        })
      };
    }
  }

  hasVueComponent() {
    return true;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/grundfos/View.vue');
  }
}
