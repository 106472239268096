<!--
  Note: This isn't an actual slider - Naming might be confusing - Used in eg: Section.vue
-->
<template>
  <div class="section__background-item" :style="videoSectionBackground">
    <video
      v-if="video?.type === 'external' && isReady"
      ref="externalVideo"
      playsinline
      autoplay
      muted
      :controls="false"
      :loop="!video.disableLooping"
    >
      <source :src="video.url" type="video/mp4" />
    </video>

    <div
      v-if="video?.url && video?.type !== 'external'"
      class="video-embed-wrap"
      :class="{
        'video-embed-wrap--not-ratio': !ratio
      }"
      :style="{ width: videoBackgroundStyles?.width }"
    >
      <div ref="videoPlayerRef" class="video-embed-element" :style="{ width: sectionBackgroundStyles?.width }"></div>
    </div>
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType, Raw } from 'vue';
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import YouTubePlayerConstructor from 'youtube-player';
import type { YouTubePlayer } from 'youtube-player/dist/types';
import VimeoPlayer from '@vimeo/player';
import PlayerStates from 'youtube-player/dist/constants/PlayerStates';
import type { VideoState } from '@/src/components/layout/SettingsModel';
import { BehaviorTypes, VideoFormatType } from '@/src/typings/enums/enums';
import { applyReplacementTags, getVimeoIdFromUrl, getYouTubeVideoIdFromUrl } from '@/src/utilities/Utilities';

export default defineComponent({
  name: 'BackgroundVideoSlider',
  props: {
    sectionBackgroundStyles: {
      type: Object as PropType<CSSProperties>
    },
    videoBackgroundStyles: {
      type: Object as PropType<CSSProperties>
    },
    video: {
      type: Object as PropType<VideoState>
    },
    ratio: {
      type: Boolean
    },
    videoWidth: {
      type: [String, Number]
    }
  },
  setup(props) {
    const videoPlayerRef = ref<Raw<HTMLElement> | null>(null);
    const externalVideo = ref<HTMLMediaElement | null>(null);
    let player: YouTubePlayer | VimeoPlayer | null = null;

    const src = computed(() => {
      return applyReplacementTags(props.video?.url ?? '');
    });

    const youtubePlayer = () => {
      if (videoPlayerRef.value && src.value) {
        player = YouTubePlayerConstructor(videoPlayerRef.value, {
          videoId: getYouTubeVideoIdFromUrl(src.value),
          playerVars: {
            origin: window.location.origin,
            widget_referrer: window.location.origin,
            rel: 0,
            controls: 0,
            modestbranding: 1,
            playsinline: 1
          }
        });

        player.on('ready', (event) => {
          if (player && 'setVolume' in player) {
            player.setVolume(0);
          }

          // TODO: Find out it's accessing the video data like this
          // @ts-ignore
          if (event?.target?.getVideoData().video_id && player && 'playVideo' in player) {
            player.playVideo();
          } else if (player && 'destroy' in player) {
            player.destroy();
          }
        });
        player.on('stateChange', (event) => {
          if (!props.video?.disableLooping && event.data === PlayerStates.ENDED && player && 'playVideo' in player) {
            player.playVideo();
          }
        });
      }
    };

    const vimeoPlayer = () => {
      if (src.value && videoPlayerRef.value) {
        player = new VimeoPlayer(videoPlayerRef.value, {
          id: Number(getVimeoIdFromUrl(src.value)),
          loop: !props.video?.disableLooping,
          autoplay: true,
          muted: true,
          controls: false,
          background: true
        });
      }
    };

    const externalPlayer = async () => {
      isReady.value = true;
    };

    const videoSectionBackground = computed(() => {
      const position = props.video?.fallbackImage?.position?.replace('_', ' ');

      let backgroundBehavior: string;

      switch (props.video?.fallbackImage?.behavior) {
        case BehaviorTypes.CONTAIN:
          backgroundBehavior = 'contain';
          break;
        case BehaviorTypes.STRETCH:
          backgroundBehavior = 'stretch';
          break;
        case BehaviorTypes.TILE:
          backgroundBehavior = 'tile';
          break;
        case BehaviorTypes.ACTUAL_SIZE:
          backgroundBehavior = 'actual_size';
          break;
        default:
          backgroundBehavior = 'cover';
          break;
      }

      return {
        ...props.sectionBackgroundStyles,
        ...(props.video?.fallbackImage?.enabled && {
          ...(props.video?.fallbackImage.url && {
            backgroundImage: `url(${props.video?.fallbackImage.url})`
          }),
          ...(position && { backgroundPosition: position }),
          backgroundSize: backgroundBehavior
        })
      };
    });

    const initVideoEmbed = () => {
      if (props.video?.type !== undefined) {
        switch (props.video.type) {
          case VideoFormatType.EXTERNAL:
            externalPlayer();
            break;

          case VideoFormatType.YOUTUBE:
            youtubePlayer();
            break;

          case VideoFormatType.VIMEO:
            vimeoPlayer();
            break;
        }
      }
    };

    const isReady = ref(false);

    const reload = () => {
      if (!src.value || player !== null || (player && 'destroy' in player)) {
        if (player) {
          player.destroy();
          player = null;
        }
      }
      nextTick(() => {
        if (src.value && !player) {
          initVideoEmbed();
        }
      });
    };

    watch(src, () => {
      if (!src.value) {
        isReady.value = false;
      }
      reload();
    });

    watch(
      () => props.video?.disableLooping,
      () => {
        isReady.value = false;
        reload();
      }
    );

    watch(
      () => props.video?.url,
      (newUrl) => {
        if (newUrl && externalVideo.value instanceof HTMLVideoElement) {
          externalVideo.value.load();
        }
      }
    );

    onMounted(() => {
      initVideoEmbed();
    });

    // Video
    return {
      videoPlayerRef,
      VideoFormatType,
      videoSectionBackground,
      isReady,
      externalVideo
    };
  }
});
</script>
