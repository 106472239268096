import type { CSSProperties } from 'vue';
import { useUtilityStore } from '@/src/store/utility';
import type {
  AddonLeaderboardData,
  AddonLeaderboardGroup,
  AddonLeaderboardScoreType,
  LeaderboardEffectsData,
  LeaderboardTypographyData
} from './Data';
import {
  AddonLeaderboardGameType,
  AddonLeaderboardScoreKey,
  AddonLeaderboardRankingDirection,
  AddonLeaderboardRankingType
} from './Data';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import { replaceFontIdWithFontString } from '@/src/utilities/Utilities';
import type { TextTypographyState } from '@/src/typings/interfaces/state/SharedAddons';
import type { TypographyData } from '@/src/typings/interfaces/data/settings/settings';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import type { AlignContentType } from '@/src/typings/enums/enums';
import AddonModel from '@/src/models/grid/AddonModel';
import { useCampaignStore } from '@/src/store/campaign';

interface LeaderboardTextTypographyState extends TextTypographyState {
  backgroundColor?: string;
  highlight?: {
    color?: string;
    backgroundColor?: string;
  };
  secondary?: {
    color?: string;
    backgroundColor?: string;
  };
}

interface LeaderboardEffectsState {
  enterEnimation?: boolean;
  highlightTop3?: boolean;
  showUserRank?: boolean;
  countUpScore?: boolean;
}

interface LeaderboardLayoutState {
  heading?: LeaderboardTextTypographyState;
  body?: LeaderboardTextTypographyState;
}

interface LeaderboardHeadingItemState {
  label?: string;
  alignment?: AlignContentType;
}

interface LeaderboardHeadingsState {
  rank?: LeaderboardHeadingItemState;
  name?: LeaderboardHeadingItemState;
  score?: LeaderboardHeadingItemState;
  timeused?: LeaderboardHeadingItemState;
}

interface LeaderboardIconState {
  enabled?: boolean;
  image?: string;
  width?: string;
}

interface LeaderboardIconsState {
  first: LeaderboardIconState;
  second?: LeaderboardIconState;
  third?: LeaderboardIconState;
  user?: LeaderboardIconState;
}

interface LeaderboardAutoupdateState {
  enabled: boolean;
  time: number;
}

export interface AddonLeaderboardState extends AddonModelState {
  gameEnded: boolean;
  winner: boolean;
  settings?: {
    group?: AddonLeaderboardGroup;
    scoreType?: AddonLeaderboardScoreType;
    rankingType?: AddonLeaderboardRankingType;
    scoreKey?: AddonLeaderboardScoreKey;
    rankingDirection?: AddonLeaderboardRankingDirection;
    nameFieldId?: string;
    uniqueFieldId?: string;
    limit?: string;
    minScore?: number;
    maxScore?: number;
    autoupdate?: LeaderboardAutoupdateState;
    headings?: LeaderboardHeadingsState;
    icons?: LeaderboardIconsState;
    effects?: LeaderboardEffectsState;
    layout?: LeaderboardLayoutState;
  };
  css: string;
  elementStyling?: {
    inline?: CSSProperties;
    wrapper?: CSSProperties;
  };
}

export class AddonLeaderboardModel extends AddonModel<AddonLeaderboardData, AddonLeaderboardState, 'leaderboard'> {
  parseAddon(data: AddonLeaderboardData): void {
    const utilityStore = useUtilityStore();
    const campaignStore = useCampaignStore();
    const campaignState = campaignStore.model?.state;

    const state = this.state;
    state.alias = data.alias;

    state.gameEnded = false;
    state.winner = false;
    state.settings = state.settings ?? {};
    state.settings.group = data.settings.group;
    state.settings.scoreType = data.settings.score_type;
    state.settings.rankingType = data.settings.ranking_type;
    state.settings.nameFieldId = data.settings.name_field_id;
    state.settings.uniqueFieldId = data.settings.unique_field_id;
    state.settings.limit = data.settings.limit;
    state.settings.minScore = data.settings.min_score;
    state.settings.maxScore = data.settings.max_score;
    state.settings.autoupdate = {
      enabled: !!data.settings.autoupdate?.enabled,
      time: data.settings.autoupdate?.time ?? 0
    };

    const gameAlias = campaignState?.config?.gameAlias ?? 'quiz';
    const gameType: AddonLeaderboardGameType = gameAlias as AddonLeaderboardGameType;

    state.settings.scoreKey = AddonLeaderboardModel.getScoreKey(gameType);
    state.settings.rankingDirection = AddonLeaderboardModel.getRankingDirection(state.settings?.rankingType);

    state.settings.headings = {
      rank: {
        label: data.settings?.headings?.rank?.label ?? undefined,
        alignment: data.settings?.headings?.rank?.alignment ?? undefined
      },
      name: {
        label: data.settings?.headings?.name?.label ?? undefined,
        alignment: data.settings?.headings?.name?.alignment ?? undefined
      },
      score: {
        label: data.settings?.headings?.score?.label ?? undefined,
        alignment: data.settings?.headings?.score?.alignment ?? undefined
      },
      timeused: {
        label: data.settings?.headings?.timeused?.label ?? undefined,
        alignment: data.settings?.headings?.timeused?.alignment ?? undefined
      }
    };

    state.settings.icons = {
      first: {
        enabled: data.settings.icons?.first_place?.enabled === '1',
        image: data.settings.icons?.first_place?.image,
        width: data.settings.icons?.first_place?.width
      },
      second: {
        enabled: data.settings.icons?.second_place?.enabled === '1',
        image: data.settings.icons?.second_place?.image,
        width: data.settings.icons?.second_place?.width
      },
      third: {
        enabled: data.settings.icons?.third_place?.enabled === '1',
        image: data.settings.icons?.third_place?.image,
        width: data.settings.icons?.third_place?.width
      },
      user: {
        enabled: data.settings.icons?.user_place?.enabled === '1',
        image: data.settings.icons?.user_place?.image,
        width: data.settings.icons?.user_place?.width
      }
    };

    state.settings.effects = state.settings.effects ?? {};

    if (data.settings?.effects) {
      state.settings.effects = AddonLeaderboardModel.parseEffectsData(data.settings.effects);
    }

    state.settings.layout = state.settings.layout ?? {};
    state.settings.layout.heading = state.settings.layout.heading ?? {};
    state.settings.layout.body = state.settings.layout.body ?? {};

    if (data.settings?.layout?.heading && Object.keys(data.settings.layout.heading).length > 0) {
      state.settings.layout.heading = AddonLeaderboardModel.parseTypographyData(data.settings.layout.heading);
    } else {
      state.settings.layout.heading = {};
    }

    if (data.settings?.layout?.body && Object.keys(data.settings.layout.body).length > 0) {
      state.settings.layout.body = AddonLeaderboardModel.parseTypographyData(data.settings.layout.body);
    } else {
      state.settings.layout.body = {};
    }

    state.css = '';

    if (state.settings.layout.heading && this.state.classIdentifier) {
      state.css += AddonLeaderboardModel.constructHeadingStyle(
        state.settings.layout.heading,
        this.state.classIdentifier,
        state.settings.headings,
        state.settings.rankingType
      );
    }

    if (state.settings.layout.body && this.state.classIdentifier) {
      state.css += AddonLeaderboardModel.constructBodyStyle(state.settings.layout.body, this.state.classIdentifier);
    }

    if (state.settings.layout?.heading?.fontFamily) {
      utilityStore.loadFont(state.settings.layout.heading.fontFamily);
    }

    if (state.settings.layout?.body?.fontFamily) {
      utilityStore.loadFont(state.settings.layout.body.fontFamily);
    }
  }

  private static getScoreKey(gameType: AddonLeaderboardGameType): AddonLeaderboardScoreKey | undefined {
    switch (gameType) {
      case AddonLeaderboardGameType.DROPGAME:
        return AddonLeaderboardScoreKey.POINTS;
      case AddonLeaderboardGameType.SHOOTIT:
        return AddonLeaderboardScoreKey.SCORE;
      case AddonLeaderboardGameType.HITTHETARGET:
      case AddonLeaderboardGameType.SNAKE:
      case AddonLeaderboardGameType.SLICEIT:
        return AddonLeaderboardScoreKey.POINTS_COLLECTED;
      case AddonLeaderboardGameType.QUIZ:
        return AddonLeaderboardScoreKey.CORRECT_ANSWERS;
      case AddonLeaderboardGameType.TAPPING:
        return AddonLeaderboardScoreKey.CLICKS;
      case AddonLeaderboardGameType.BOUNCE_BATTLE:
        return AddonLeaderboardScoreKey.SCORE;
      case AddonLeaderboardGameType.HIT_A_MOLE:
        return AddonLeaderboardScoreKey.POINTS;
      default:
        return undefined;
    }
  }

  private static getRankingDirection(rankingType?: AddonLeaderboardRankingType): AddonLeaderboardRankingDirection {
    switch (rankingType) {
      case AddonLeaderboardRankingType.SCORE:
      case AddonLeaderboardRankingType.ACCUMULATED_SCORE:
        return AddonLeaderboardRankingDirection.DESC;
      case AddonLeaderboardRankingType.TIMEUSED:
      case AddonLeaderboardRankingType.TIMEUSED_MS:
        return AddonLeaderboardRankingDirection.ASC;
      default:
        return AddonLeaderboardRankingDirection.DESC;
    }
  }

  private static parseTypographyData(data: DeviceData<TypographyData>): TypographyData | undefined {
    const useData = getDeviceData(data) as TypographyData | undefined;

    if (!useData) {
      return undefined;
    }

    return AddonLeaderboardModel.constructTypographyState(useData);
  }

  private static constructTypographyState(data: LeaderboardTypographyData): LeaderboardTextTypographyState {
    return {
      ...(data.color && { color: data.color }),
      ...(data.background_color && { backgroundColor: data.background_color }),
      ...(data.font_family && { fontFamily: replaceFontIdWithFontString(data.font_family) }),
      ...(data.font_weight && {
        fontWeight: Number(data.font_weight)
      }),
      ...(data.letter_spacing && {
        letterSpacing: parseFloat(data.letter_spacing)
      }),
      ...(data.line_height && {
        lineHeight: parseFloat(data.line_height)
      }),
      ...(data.size && { fontSize: data.size }),
      ...(data.text_decoration && {
        textDecoration: data.text_decoration
      }),
      ...(data.text_style && { textStyle: data.text_style }),
      ...(data.transform && { transform: data.transform }),
      ...(data.highlight &&
        data.highlight === '1' && {
          highlight: {
            color: data.highlight_color,
            backgroundColor: data.highlight_background_color ?? 'rgba(255, 255, 255, 0.2)'
          }
        }),
      ...(data.secondary &&
        data.secondary === '1' && {
          secondary: {
            color: data.secondary_color,
            backgroundColor: data.secondary_background_color ?? 'rgba(255, 255, 255, 0.05)'
          }
        })
    };
  }

  private static parseEffectsData(data: LeaderboardEffectsData): LeaderboardEffectsState {
    return {
      enterEnimation: !!(data.enter_animation === undefined || data.enter_animation === '1'),
      highlightTop3: data.highlight_top3 === '1',
      showUserRank: data.show_user_rank === '1',
      countUpScore: data.count_up_score === '1'
    };
  }

  private static constructHeadingStyle(
    state: LeaderboardTextTypographyState,
    identifier: string,
    headings: LeaderboardHeadingsState,
    rankingType: AddonLeaderboardRankingType
  ): string {
    const styles: CSSProperties = {
      ...(state.fontSize && { fontSize: `${state.fontSize}` }),
      ...(state.color && { color: state.color }),
      ...(state.backgroundColor && { backgroundColor: state.backgroundColor }),
      ...(state.fontWeight && { fontWeight: state.fontWeight }),
      ...(state.lineHeight && { lineHeight: state.lineHeight }),
      ...(state.letterSpacing && {
        letterSpacing: `${state.letterSpacing}px`
      }),
      ...(state.fontFamily && { fontFamily: state.fontFamily }),
      ...(state.textDecoration && { textDecoration: state.textDecoration }),
      ...(state.textStyle && { fontStyle: state.textStyle }),
      ...(state.transform && { textTransform: state.transform as CSSProperties['textTransform'] })
    };

    return `
      .${identifier} lf-leaderboard {
        ${styles.fontSize ? '--leaderboard-header-font-size: ' + styles.fontSize + ';' : ''}
        ${styles.color ? '--leaderboard-header-text-color: ' + styles.color + ';' : ''}
        ${styles.backgroundColor ? '--leaderboard-header-background-color: ' + styles.backgroundColor + ';' : ''}
        ${styles.fontWeight ? '--leaderboard-header-font-weight: ' + styles.fontWeight + ';' : ''}
        ${styles.lineHeight ? '--leaderboard-header-line-height: ' + styles.lineHeight + ';' : ''}
        ${styles.letterSpacing ? '--leaderboard-header-letter-spacing: ' + styles.letterSpacing + ';' : ''}
        ${styles.fontFamily ? '--leaderboard-header-font-family: ' + styles.fontFamily + ';' : ''}
        ${styles.textDecoration ? '--leaderboard-header-text-decoration: ' + styles.textDecoration + ';' : ''}
        ${styles.fontStyle ? '--leaderboard-header-font-style: ' + styles.fontStyle + ';' : ''}
        ${styles.textTransform ? '--leaderboard-header-text-transform: ' + styles.textTransform + ';' : ''}
        --leaderboard-header-rank-alignment: ${headings.rank?.alignment ? headings.rank?.alignment : 'center'};
        --leaderboard-header-name-alignment: ${headings.name?.alignment ? headings.name?.alignment : 'left'};
        ${
          rankingType === AddonLeaderboardRankingType.SCORE ||
          rankingType === AddonLeaderboardRankingType.ACCUMULATED_SCORE
            ? '--leaderboard-header-score-alignment:' +
              (headings.score?.alignment ? headings.score?.alignment : 'center') +
              ';'
            : ''
        }
        ${
          [AddonLeaderboardRankingType.TIMEUSED, AddonLeaderboardRankingType.TIMEUSED_MS].includes(rankingType)
            ? '--leaderboard-header-score-alignment:' +
              (headings.timeused?.alignment ? headings.timeused?.alignment : 'center') +
              ';'
            : ''
        }
      }
    `;
  }

  private static constructBodyStyle(state: LeaderboardTextTypographyState, identifier: string): string {
    const styles: CSSProperties = {
      ...(state.fontSize && { fontSize: `${state.fontSize}` }),
      ...(state.color && { color: state.color }),
      ...(state.backgroundColor && { backgroundColor: state.backgroundColor }),
      ...(state.fontWeight && { fontWeight: state.fontWeight }),
      ...(state.lineHeight && { lineHeight: state.lineHeight }),
      ...(state.letterSpacing && {
        letterSpacing: `${state.letterSpacing}px`
      }),
      ...(state.fontFamily && { fontFamily: state.fontFamily }),
      ...(state.textDecoration && { textDecoration: state.textDecoration }),
      ...(state.textStyle && { fontStyle: state.textStyle }),
      ...(state.transform && { textTransform: state.transform as CSSProperties['textTransform'] })
    };

    const highlightStyles: CSSProperties = {
      ...(state.highlight && state.highlight.color && { color: state.highlight.color }),
      ...(state.highlight && state.highlight.backgroundColor && { backgroundColor: state.highlight.backgroundColor })
    };

    const secondaryStyles: CSSProperties = {
      ...(state.secondary && state.secondary.color && { color: state.secondary.color }),
      ...(state.secondary && state.secondary.backgroundColor && { backgroundColor: state.secondary.backgroundColor })
    };

    return `
      .${identifier} lf-leaderboard {
        ${styles.fontSize ? '--leaderboard-row-font-size: ' + styles.fontSize + ';' : ''}
        ${styles.color ? '--leaderboard-row-text-color: ' + styles.color + ';' : ''}
        ${styles.backgroundColor ? '--leaderboard-row-background-color: ' + styles.backgroundColor + ';' : ''}
        ${styles.fontWeight ? '--leaderboard-row-font-weight: ' + styles.fontWeight + ';' : ''}
        ${styles.lineHeight ? '--leaderboard-row-line-height: ' + styles.lineHeight + ';' : ''}
        ${styles.letterSpacing ? '--leaderboard-row-letter-spacing: ' + styles.letterSpacing + ';' : ''}
        ${styles.fontFamily ? '--leaderboard-row-font-family: ' + styles.fontFamily + ';' : ''}
        ${styles.textDecoration ? '--leaderboard-row-text-decoration: ' + styles.textDecoration + ';' : ''}
        ${styles.fontStyle ? '--leaderboard-row-font-style: ' + styles.fontStyle + ';' : ''}
        ${styles.textTransform ? '--leaderboard-row-text-transform: ' + styles.textTransform + ';' : ''}
        ${highlightStyles.color ? '--leaderboard-row-highlight-text-color: ' + highlightStyles.color + ';' : ''}
        ${
          highlightStyles.backgroundColor
            ? '--leaderboard-row-highlight-background-color: ' + highlightStyles.backgroundColor + ';'
            : ''
        }
        ${secondaryStyles.color ? '--leaderboard-row-secondary-text-color: ' + secondaryStyles.color + ';' : ''}
        ${
          secondaryStyles.backgroundColor
            ? '--leaderboard-row-secondary-background-color: ' + secondaryStyles.backgroundColor + ';'
            : ''
        }
      }
    `;
  }

  authorSignature(): string {
    return 'Jesper Daugaard';
  }
}
