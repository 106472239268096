import type { GameIndicator, GameState } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GameBankoData,
  GameBankoGeneralData,
  GameBankoLabels,
  GameBankoLayoutData,
  GameBankoNumberSelectionType
} from '@/src/components/games/banko/Data';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType, GameIndicatorSettingsModel } from '@/src/components/indicators/Model';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import { useUtilityStore } from '@/src/store/utility';
import { DrawType } from '@/src/typings/enums/enums';

export type IBankoCard = Array<Array<number | null>>;

export interface GameBankoVisualState {
  font: {
    color?: string;
    type?: string;
  };
  grid: {
    color: string;
  };
  selectedNumberColor: string;
}

interface GameBankoGeneralState {
  drawType: string;
  winnerChance: string;
  numberSelection: GameBankoNumberSelectionType;
}

interface GameBankoLabelsState {
  computerCard: string;
  nextNumber: string;
  previousNumbers: string;
  yourCard: string;
}

interface GameBankoState extends GameState {
  general: GameBankoGeneralState;
  customLabels: GameBankoLabelsState;
  visuals: GameBankoVisualState | undefined;
}

export class GameBankoModel extends GameModel<GameBankoData, GameBankoState> {
  parseGame(data: GameBankoData) {
    const utilityStore = useUtilityStore();
    const state = this.state;

    state.general = GameBankoModel.constructGeneralState(data.general);
    state.visuals = GameBankoModel.parseLayoutData(data.general.layout);
    state.customLabels = GameBankoModel.constructLabelsState(data.customLabels?.labels);

    if (state.visuals?.font.type) {
      utilityStore.loadFont(state.visuals.font.type);
    }

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }
  }

  private static constructLabelsState(data: GameBankoLabels | undefined): GameBankoLabelsState {
    return {
      computerCard: data?.computer_card || 'Computer Banko card',
      nextNumber: data?.next_number || 'Next number',
      previousNumbers: data?.previous_numbers || 'Previous numbers',
      yourCard: data?.your_card || 'Your Banko card'
    };
  }

  private static constructGeneralState(data: GameBankoGeneralData): GameBankoGeneralState {
    return {
      drawType: data.draw_type,
      winnerChance: data.winner_chance || 'random',
      numberSelection: data.draw_type === 'auto' ? 'automatically' : data.number_selection || 'automatically'
    };
  }

  private static parseLayoutData(data: DeviceData<GameBankoLayoutData>): GameBankoVisualState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return GameBankoModel.constructLayoutState(useData);
    }
  }

  private static constructLayoutState(data: GameBankoLayoutData): GameBankoVisualState {
    return {
      font: {
        color: data.font_color,
        type: data.font_type
      },
      grid: {
        color: data.grid_color
      },
      selectedNumberColor: data.selected_number_color
    };
  }

  public hasInstantWin(): boolean {
    return this.state.general?.drawType === DrawType.AUTO;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }
}
