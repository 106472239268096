<template>
  <div class="content-item__add">
    <div class="content-item__add-icon" @click.stop="onAdd">
      <p-icon icon="plus" size="small" display="block" />
    </div>

    <ModalContentAddAddon v-if="showContentAddAddonModal" :column-model="columnModel" @close-request="onCloseModal" />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, ref } from 'vue';
import type ColumnModel from '@/src/components/layout/column/ColumnModel';
import ModalContentAddAddon from '@/src/components/components/editing/Modals/ModalContentAddAddon.vue';

export default defineComponent({
  name: 'LFNavigatorContentAdd',
  components: {
    ModalContentAddAddon
  },
  props: {
    columnModel: {
      type: Object as PropType<ColumnModel>,
      required: true
    }
  },
  emits: ['modalOpen', 'modalClose'],
  setup(_props, context) {
    const showContentAddAddonModal = ref(false);

    const onAdd = () => {
      showContentAddAddonModal.value = true;
      context.emit('modalOpen');
    };

    const onCloseModal = () => {
      showContentAddAddonModal.value = false;
      context.emit('modalClose');
    };

    return {
      showContentAddAddonModal,
      onAdd,
      onCloseModal
    };
  }
});
</script>

<style lang="scss" scoped>
.content-item__add {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  padding: 0;
  z-index: 11;
}

.content-item__add-icon {
  display: flex;
  margin: 0 auto;
  background: $edit-border-color;
  width: 25px;
  height: 25px;
  border-radius: 0;
  font-size: 10px;
  line-height: 26px;
  color: #fff;
  text-align: center;
  transition: background-color 125ms linear;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    background: darken($edit-border-color, 10%);
  }
}
</style>
