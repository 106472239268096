<template>
  <div class="content__item content__item--grid grid" :class="{ container: gridType === 'grid' }">
    <Row v-for="row in rows" :key="row.state.modelId" :model="row" />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { ContentGrid } from '@/src/components/layout/section/SectionBaseModel';
import Row from '@/src/components/layout/row/Row.vue';
import type { GridTypes } from '@/src/typings/types/types';

export default defineComponent({
  name: 'Grid',
  components: {
    Row
  },
  props: {
    content: {
      type: Object as PropType<ContentGrid>,
      required: true
    },
    sectionIndex: Number,
    sectionId: Number,
    gridType: {
      type: String as PropType<GridTypes>,
      default: 'grid'
    }
  },
  setup(props) {
    const rows = computed(() => {
      return props.content.rows.filter((content) => {
        const visibilityConditions = content.state.settings?.state?.advanced?.visibilityCondition;

        return visibilityConditions ? visibilityConditions.check() : true;
      });
    });

    return {
      rows
    };
  }
});
</script>

<style lang="scss">
.content__item--grid.grid {
  display: flex;
  flex-direction: column;
}
</style>
