import type { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import { SdkRowModel } from '@/src/sdk/models/row';
import { ContentType } from '@/src/components/layout/section/SectionBaseModel';
import type { SdkFormFieldModel } from '@/src/sdk/models/formField';
import { SdkAddonRegistration } from '@/src/sdk/models/addons/registration';

export class SdkSectionModel {
  #model: SectionBaseModel;

  constructor(model: SectionBaseModel) {
    this.#model = model;
  }

  public get rows(): SdkRowModel[] {
    if (this.#model.state.content[0] && this.#model.state.content[0].type === ContentType.GRID) {
      return this.#model.state.content[0].rows.map((row) => new SdkRowModel(row));
    } else {
      // eslint-disable-next-line no-console
      console.error('[SDK] Not able to find any rows on the section');
      return [];
    }
  }

  public get formFields(): SdkFormFieldModel[] {
    const formFields: SdkFormFieldModel[] = [];

    this.rows.forEach((row) => {
      row.columns.forEach((column) => {
        column.addons.forEach((addon) => {
          if (addon instanceof SdkAddonRegistration) {
            formFields.push(...addon.formFields);
          }
        });
      });
    });

    return formFields;
  }

  public get id(): number {
    return this.#model.state.id;
  }

  public get title(): string | undefined {
    return this.#model.state.title;
  }
}
