import { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import { SectionType } from '@/src/typings/enums/enums';
import type { SettingsData } from '@/src/typings/interfaces/data/settings/settings';
import type { SettingsModel } from '@/src/components/layout/SettingsModel';
import { SectionSettingsModel } from '@/src/components/layout/section/SectionSettingsModel';
import { useEditingStore } from '@/src/store/editing';
import { router } from '@/src/router';

export class PopoverModel extends SectionBaseModel {
  activateEditing(): void {
    const route = router.currentRoute.value;

    if (!route.query.pageId || Number(route.query.pageId) !== this.id) {
      router.push({
        query: {
          ...route.query,
          pageId: this.id
        }
      });
    }

    const editingStore = useEditingStore();
    editingStore.setActiveModel(this);
  }

  public getSectionType() {
    return SectionType.POPOVER;
  }

  public getSettingsModel(data: SettingsData): SettingsModel {
    return new SectionSettingsModel(data, this);
  }

  get index(): number {
    return this.campaignModel.state.popovers.indexOf(this);
  }
}
