import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 512 512",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "Cookie" }, [
      _createElementVNode("path", { d: "M466.5376,250.3356c-.2313-8.3766-8.2694-14.8078-16.376-12.6955a57.5807,57.5807,0,0,1-55.1994-14.8935,58.98,58.98,0,0,1-6.8554-8.3937,13.7161,13.7161,0,0,0-14.9021-5.7757A57.3178,57.3178,0,0,1,303.4889,138.84a13.7245,13.7245,0,0,0-5.7758-14.8892,56.5137,56.5137,0,0,1-8.3122-6.8554,57.3,57.3,0,0,1-14.9235-55.0709c2.1209-8.1451-4.349-16.1917-12.764-16.4188a209.89,209.89,0,0,0-154.6551,61.5749c-84.5922,84.5922-82.1371,223.1455,7.1939,304.6656,79.8534,72.8351,203.6674,72.8351,283.5208,0A209.9871,209.9871,0,0,0,466.5376,250.3356Zm-296.6318-7.0012a46.0255,46.0255,0,1,1,55.315-55.3365A45.7083,45.7083,0,0,1,169.9058,243.3344Zm66.9608,111.98a26.8273,26.8273,0,1,1-31.4838-31.9422A26.8434,26.8434,0,0,1,236.8666,355.3142Zm151.0945-53.0485a34.5,34.5,0,1,1-39.5132-39.53A34.6558,34.6558,0,0,1,387.9611,302.2657Z" })
    ], -1)
  ])))
}
export default { render: render }