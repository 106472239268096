import type { GameProductSelectorAdvancedData, GameProductSelectorData } from './Data';
import type { GameIndicator, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import useDevice from '@/src/hooks/useDevice';
import type { TransitionTypes } from '@/src/typings/types/types';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';

interface GridState {
  columns: string;
  rows: string;
  space: string;
  center: string;
}

interface GameProductSelectorGeneralState {
  grid: GridState | undefined;
  loop: boolean;
  showDots: boolean;
  dotColor: string;
  activeState: string;
  bgColor: string;
  hoverColor: string;
  showAnswerButton: boolean;
  answerBtn: string;
  selectAnswerBtn: string;
  espEnabled: boolean;
}

interface GameProductSelectorImageState {
  id: string;
  image?: string;
  label?: string;
}

export interface GameProductSelectorAdvancedSoundState {
  enabled: boolean;
  src?: string;
}
export interface GameProductSelectorAdvancedAnimationState {
  enabled: boolean;
  animation?: TransitionTypes;
}
export interface GameProductSelectorAdvancedState {
  sound: GameProductSelectorAdvancedSoundState;
  animation: GameProductSelectorAdvancedAnimationState;
}

export interface GameProductSelectorState {
  general: GameProductSelectorGeneralState;
  images: GameProductSelectorImageState[];
  advanced: GameProductSelectorAdvancedState;
  sliderOptions: {
    pagination: boolean;
    slidesPerView: number;
    slidesPerColumn: number;
    spaceBetween: number;
    centeredSlides: boolean;
    loop: boolean;
  };
  elementStyling: {
    generalStyling?: string;
  };
}

export class GameProductSelectorModel
  extends GameModel<GameProductSelectorData, GameProductSelectorState>
  implements HasSound
{
  parseGame(data: GameProductSelectorData) {
    const state = this.state;

    // Set default slider options
    state.sliderOptions = state.sliderOptions ?? {};
    state.sliderOptions.slidesPerView = 1;
    state.sliderOptions.slidesPerColumn = 1;
    state.sliderOptions.pagination = false;
    // End set default slider options

    state.general = state.general ?? {};
    state.general.activeState = data.general?.active_state;
    state.general.bgColor = data.general?.bg_color;
    if (data.general?.answer_btn) {
      state.general.answerBtn = data.general?.answer_btn;
    }
    state.general.espEnabled = data.general?.esp?.enabled === '1';
    if (data.general?.dot_color) {
      state.general.dotColor = data.general?.dot_color;
    }
    state.general.hoverColor = data.general?.hover_color;
    state.general.loop = data.general?.loop === '1';
    state.general.showAnswerButton = data.general?.show_answer_button === '1';
    if (data.general?.select_answer_btn) {
      state.general.selectAnswerBtn = data.general?.select_answer_btn;
    }
    state.general.showDots = data.general?.show_dots === '1';

    const { isMobile } = useDevice();

    if (isMobile && data.general?.grid.overwrite_mobile !== '1') {
      if (data.general?.grid?.mobile) {
        state.general.grid = data.general?.grid?.mobile;
      }
    } else {
      state.general.grid = data.general?.grid?.desktop ?? undefined;
    }

    // Set images
    if (data.images.images) {
      state.images = data.images.images;
    }

    // Custom slider options
    if (state.general.grid && state.general.grid.columns) {
      state.sliderOptions.slidesPerColumn = parseInt(state.general.grid.rows);
    }

    if (state.general.grid && state.general.grid.rows) {
      state.sliderOptions.slidesPerView = parseInt(state.general.grid.columns);
    }

    if (state.general.showDots) {
      state.sliderOptions.pagination = true;
    }

    if ((state.general.grid?.center && state.general.grid.center === 'center') || state.general.grid?.center === '') {
      state.sliderOptions.centeredSlides = true;
    }

    if (!state.general.grid?.center) {
      state.sliderOptions.centeredSlides = true;
    }

    if (state.general.grid?.space && state.general.grid.space !== '') {
      state.sliderOptions.spaceBetween = parseInt(state.general.grid.space);
    }

    if (state.general.loop) {
      state.sliderOptions.loop = true;
    }

    if (state.images && state.general.grid) {
      if (state.images.length <= Number(state.general.grid.columns) * Number(state.general.grid.rows)) {
        state.sliderOptions.loop = false;
      }
    }
    // End custom slider options

    state.elementStyling = {};
    state.elementStyling.generalStyling = GameProductSelectorModel.constructGeneralStyle(state);

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameProductSelectorModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GameProductSelectorModel.constructAdvancedAnimationState(data?.advanced);
  }

  private static constructAdvancedSoundState(
    data: GameProductSelectorAdvancedData | undefined
  ): GameProductSelectorAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructAdvancedAnimationState(
    data: GameProductSelectorAdvancedData | undefined
  ): GameProductSelectorAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private static constructGeneralStyle(state: GameProductSelectorState): string {
    let CSSString = '';

    if (state.general.dotColor && state.general.dotColor !== '') {
      CSSString += `.swiper-pagination-bullet-active {
                  background-color: ${state.general.dotColor};
                }`;
    }

    if (state.general.activeState !== 'alfa') {
      CSSString += `.product-selector .product-selector__image {
                    background-color: ${state.general.bgColor};
                  }`;

      CSSString += `
        .no-touch .product-selector .product-selector__image:hover,
                  .touch .product-selector .product-selector__image:active,
                  .product-selector .product-selector__image--selected {
                    background-color: ${state.general.hoverColor};
                  }';
      `;
    }

    return CSSString;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  public isGameValid(): boolean {
    return true;
  }

  public authorSignature() {
    return 'Nicky Christensen';
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
