import type {
  AddonAuthenticationData,
  AddonAuthenticationFieldData,
  AddonAuthenticationSettingsData
} from '@/src/components/addons/authentication/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { AlignContentType, FieldHtmlInputType, FieldInputType } from '@/src/typings/enums/enums';

import { AuthError, AuthSuccess } from '@/src/components/addons/authentication/enums';
import type { FormElementModel } from '@/src/components/addons/registration/types';
import { FormElementMapping } from '@/src/components/addons/registration/types';

interface AddonAuthenticationState extends AddonModelState {
  formId: string;
  settings: AddonAuthenticationSettingsState;
  fields?: FormElementModel[];
}

export interface AddonAuthenticationSettingsState {
  type?: string;
  customIdentifier?: string;
  onAuthSuccess?: AuthSuccess;
  onAuthError?: AuthError;
  label?: string;
  button: {
    label: string;
    alignment: AlignContentType;
  };
  messages: {
    incorrect: string;
  };
  endpoint?: string;
}

export class AddonAuthenticationModel extends AddonModel<
  AddonAuthenticationData,
  AddonAuthenticationState,
  'authentication'
> {
  parseAddon(data: AddonAuthenticationData): void {
    const state = this.state;

    const section = this.getSection();
    state.formId = 'form-authentication_' + section.state.id;
    state.settings = state.settings || {};
    state.settings = AddonAuthenticationModel.constructAuthenticationSettings(data.settings);

    if (data.settings.fields && data.settings.fields.length > 0) {
      const formFields = data.settings.fields.map<AddonAuthenticationFieldData>((field) => {
        return {
          id: field.id,
          name: field.name,
          input_type: FieldInputType.TEXT,
          input_html_type: FieldHtmlInputType.TEXT,
          label: field.label || 'Keyphrase',
          type: 1,
          default_value: '',
          value: '',
          required: true,
          weight: 0,
          exportable: 0
        };
      });

      if (formFields) {
        state.fields = AddonAuthenticationModel.constructFields(formFields, state.fields ?? []);
      }
    }
  }

  private static constructAuthenticationSettings(
    data: AddonAuthenticationSettingsData
  ): AddonAuthenticationSettingsState {
    const button = {
      label: data?.button?.label || 'Authenticate',
      alignment: data?.button?.alignment || AlignContentType.LEFT
    };
    const messages = {
      incorrect: data?.messages?.incorrect || 'Supplied authentication is invalid'
    };

    return {
      customIdentifier: data.custom_identifier,
      ...{ label: data?.label || 'Keyphrase' },
      ...{ button },
      ...{ messages },
      ...{ onAuthSuccess: data?.on_auth_success || AuthSuccess.NEXT_PAGE },
      ...{ onAuthError: data?.on_auth_error || AuthError.MESSAGE },
      ...{ type: data?.type }
    };
  }

  private static constructFields(
    fields: AddonAuthenticationFieldData[],
    existingFields: FormElementModel[]
  ): FormElementModel[] {
    const fieldMapping: { [key: string]: FormElementModel } = {};

    if (existingFields !== undefined) {
      existingFields.forEach((field) => {
        fieldMapping[field.id] = field;
      });
    }

    return fields.map((field) => {
      const newField: AddonAuthenticationFieldData = field;
      newField.input_html_type = field.input_html_type;
      newField.visible = true;

      if (fieldMapping[field.id] !== undefined) {
        // TODO: Refactor this. See comment about the same on tip a friend
        // @ts-ignore
        fieldMapping[field.id].setData(newField as unknown as FormElementData);

        return fieldMapping[field.id];
      }

      if (typeof FormElementMapping[field.input_type] === 'undefined') {
        throw new TypeError(`Unrecognized field input_type: ${field.input_type}`);
      }

      // TODO: Refactor this. See comment about the same on tip a friend
      // @ts-ignore
      return new FormElementMapping[field.input_type](newField as unknown as FormElementData);
    });
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
