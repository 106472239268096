import { createRouter, createWebHistory } from 'vue-router';

import CampaignLiveView from '@/src/routes/CampaignLiveView.vue';
import CampaignIframeQueueView from '@/src/routes/CampaignIframeQueueView.vue';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/campaign/view/demo/:campaignId/token:token',
      name: 'Campaign demo',
      component: () => import('@/src/routes/CampaignDemo.vue')
    },
    {
      path: '/campaign/view/preview/:campaignId/token:token',
      name: 'Campaign preview',
      component: () => import('@/src/routes/CampaignLivePreview.vue')
    },
    {
      path: '/campaign/view/iframe/0/hash:hash',
      name: 'Campaign view iframe queue',
      component: CampaignIframeQueueView
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Campaign view',
      component: CampaignLiveView
    }
  ]
});
