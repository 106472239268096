import type {
  GameSnakeData,
  GameSnakeApperanceType,
  GameSnakeGeneralData,
  GameSnakeLayoutData,
  GameSnakeAppleAppearanceData,
  GameSnakeSnakeAppearanceData,
  GameSnakeAdvancedData
} from '@/src/components/games/snake/Data';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import type { GameIndicatorPosition, GameIndicatorSettingsModel } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { transformMeasurementToNumber } from '@/src/utilities/Utilities';

export interface GameSnakeLayoutState {
  size: string;
  sizeRow: number;
  sizeCol: number;
  width: number;
  widthSizeIsPercentage: boolean;
  backgroundImage?: string;
  backgroundColor: string;
  backgroundType?: string;
  backgroundPosition?: string;
  borderWidth: number;
  borderColor: string;
  grid: boolean;
  gridColor?: string;
  gridWidth?: number;
}

export interface GameSnakeSnakeAppearanceState {
  body: {
    appearance: GameSnakeApperanceType;
    color: string;
    borderRadius: number;
    image?: string;
  };
  head: {
    appearance?: GameSnakeApperanceType;
    color?: string;
    borderRadius?: number;
    image?: string;
    enabled: boolean;
  };
}

export interface GameSnakeAppleAppearanceState {
  appleAppearance: GameSnakeApperanceType;
  appleColor: string;
  appleBorderRadius: number;
  appleImage?: string;
}

export interface GameSnakeGeneralState {
  speedUpTime?: boolean;
  layout?: GameSnakeLayoutState;
  snake?: GameSnakeSnakeAppearanceState;
  apple?: GameSnakeAppleAppearanceState;
  enableBestTime?: boolean;
  enableTimeRanking?: boolean;
  instructions?: string;
}

export interface GameSnakeAdvancedSoundState {
  enabled: boolean;
  src?: string;
}
export interface GameSnakeAdvancedState {
  sound: GameSnakeAdvancedSoundState;
}

interface PointsToWin {
  enabled: boolean;
  value: number;
}

export interface GameSnakeState {
  isMobile: boolean;
  snakeSize: number;
  general: GameSnakeGeneralState;
  pointsToWin: PointsToWin;
  advanced: GameSnakeAdvancedState;
  indicators?: GameIndicatorSettingsModel;
}

export enum KeyCode {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight'
}

export enum SnakeBackgroundType {
  TILE = 'tile',
  ACTUAL_SIZE = 'actual_size',
  STRETCH = 'stretch',
  CONTAIN = 'contain',
  COVER = 'cover'
}

export class GameSnakeModel extends GameModel<GameSnakeData, GameSnakeState> implements HasGameTimeChallenge, HasSound {
  parseGame(data: GameSnakeData) {
    const state = this.state;

    let isMobile = useDevice().isMobile;

    if (typeof isMobile === 'string') {
      isMobile = isMobile === '1';
    }

    state.isMobile = isMobile;
    state.general = GameSnakeModel.constructGeneralState(data.general);
    state.general.layout = GameSnakeModel.parseLayoutDeviceData(data.general);
    state.general.snake = GameSnakeModel.parseSnakeDeviceData(data.general);
    state.general.apple = GameSnakeModel.parseAppleDeviceData(data.general);

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameSnakeModel.constructAdvancedSoundState(data?.advanced);

    state.pointsToWin = {
      enabled: !!(data.general.points_to_win && Number(data.general.points_to_win) > 0),
      value: Number(data.general.points_to_win)
    };
  }

  private static constructAdvancedSoundState(data: GameSnakeAdvancedData | undefined): GameSnakeAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static parseAppleDeviceData(data: GameSnakeGeneralData): GameSnakeAppleAppearanceState | undefined {
    if (data.apple) {
      const useData = getDeviceData(data.apple) as GameSnakeAppleAppearanceData | undefined;

      if (!useData) {
        return undefined;
      }

      return GameSnakeModel.constructAppleAppearanceState(useData);
    }
  }

  private static constructAppleAppearanceState(data: GameSnakeAppleAppearanceData): GameSnakeAppleAppearanceState {
    return {
      appleAppearance: data.apple_appearance,
      ...(data?.apple_image && { appleImage: data.apple_image }),
      appleColor: data.apple_color,
      appleBorderRadius: Number(data.apple_border_radius)
    };
  }

  private static parseSnakeDeviceData(data: GameSnakeGeneralData): GameSnakeSnakeAppearanceState | undefined {
    if (data) {
      const useData = getDeviceData(data.snake);

      if (!useData) {
        return undefined;
      }

      return GameSnakeModel.constructSnakeAppearanceState(useData);
    }
  }

  private static constructSnakeAppearanceState(data: GameSnakeSnakeAppearanceData): GameSnakeSnakeAppearanceState {
    return {
      body: {
        appearance: data.snake_appearance,
        color: data.snake_color,
        borderRadius: Number(data.snake_border_radius),
        ...(data?.snake_image && { image: data.snake_image })
      },
      head: {
        enabled: data.snake_appearance_head_enabled === '1',
        ...(data.snake_appearance_head_enabled === '1' && {
          appearance: data.snake_appearance_head,
          color: data.snake_color_head,
          borderRadius: Number(data.snake_border_radius_head),
          ...(data?.snake_image_head && { image: data.snake_image_head })
        })
      }
    };
  }

  private static parseLayoutDeviceData(data: GameSnakeGeneralData): GameSnakeLayoutState | undefined {
    if (data) {
      const useData = getDeviceData(data.layout) as GameSnakeLayoutData | undefined;

      if (!useData) {
        return undefined;
      }
      return GameSnakeModel.constructLayoutSnakeState(useData);
    }
  }

  private static constructLayoutSnakeState(data: GameSnakeLayoutData): GameSnakeLayoutState {
    const widthSize = transformMeasurementToNumber(data.width);
    const gridSize = data?.size ?? '20';
    const sizeArr = gridSize.split('x');

    if (sizeArr.length === 1) {
      sizeArr.push(sizeArr[0]);
    }

    return {
      size: gridSize,
      sizeCol: Number(sizeArr[0]),
      sizeRow: Number(sizeArr[1]),
      width: widthSize,
      widthSizeIsPercentage: !!data.width?.includes('%'),
      ...(data?.background_type && {
        backgroundType: data?.background_type
      }),
      ...(data?.background_image !== '' && {
        backgroundImage: data.background_image
      }),
      ...(data?.background_position !== '' && {
        backgroundPosition: data.background_position
      }),
      backgroundColor: data.background_color,
      borderWidth: Number(data.border_width),
      borderColor: data.border_color,
      grid: data.grid === '1',
      ...(data?.grid_color && { gridColor: data.grid_color }),
      ...(data?.grid_width && { gridWidth: Number(data.grid_width) })
    };
  }

  private static constructGeneralState(data: GameSnakeGeneralData): GameSnakeGeneralState {
    const device = useDevice();
    return {
      ...(device.isDesktop && {
        instructions: data?.desktop_instructions
      }),

      ...(device.isMobile && {
        instructions: data?.mobile_instructions
      }),
      ...(data?.speed_up_time && {
        speedUpTime: data.speed_up_time === '1'
      }),
      ...(data?.enable_best_time && {
        enableBestTime: data.enable_best_time === '1'
      }),
      ...(data?.enable_time_ranking && {
        enableTimeRanking: data.enable_time_ranking === '1'
      })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_used: 0,
          time_left: this.state.timeChallenge?.limit ?? 0
        }
      },
      {
        indicatorKey: 'score',
        metricKey: {
          score_used: 'points'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          score_used: 0
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
