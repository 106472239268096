import type { CSSProperties } from 'vue';
import type { AddonLfShareData, ShareLayoutSetting } from '@/src/components/addons/lf-share/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { getDeviceData } from '@/src/hooks/useDevice';
import { AlignContentType } from '@/src/typings/enums/enums';

export interface ShareFacebook {
  enabled: boolean;
}

export interface ShareSnapchat {
  enabled: boolean;
  title?: string;
  description?: string;
  image?: string;
}

export interface ShareTwitter {
  enabled: boolean;
  message?: string;
}

export interface ShareLinkedin {
  enabled: boolean;
  title?: string;
  description?: string;
}

export interface ShareMessenger {
  enabled: boolean;
}

export interface ShareWhatsapp {
  enabled: boolean;
  message?: string;
}

export interface ShareEmail {
  enabled: boolean;
  subject?: string;
  body?: string;
}

export interface ShareSMS {
  enabled: boolean;
  message?: string;
}

export interface CustomShareUrl {
  enabled?: boolean;
  url?: string;
}

export interface Layout {
  iconColor?: string;
  iconBackgroundColor?: string;
  iconSize?: string;
  alignment?: string;
}

interface AddonLFShareState extends AddonModelState {
  facebook?: ShareFacebook;
  snapchat?: ShareSnapchat;
  twitter?: ShareTwitter;
  linkedin?: ShareLinkedin;
  messenger?: ShareMessenger;
  whatsapp?: ShareWhatsapp;
  email?: ShareEmail;
  sms?: ShareSMS;
  customShareUrl?: CustomShareUrl;
  layout?: Layout;
  title?: string;
  description?: string;
  image?: string;
  campaignShareUrl?: string;
  shareClasses: string[];
  elementStyling: {
    shareIconStyle?: CSSProperties;
    shareItemStyle?: CSSProperties;
  };
}

export interface FacebookResponse {
  message: string;
}

export class AddonLFShareModel extends AddonModel<AddonLfShareData, AddonLFShareState, 'lf-share'> {
  parseAddon(data: AddonLfShareData) {
    const state = this.state;

    state.title = data.settings?.title;
    state.campaignShareUrl = data.settings?.campaign_share_url;
    state.description = data.settings?.description;

    state.facebook = {
      enabled: data.settings?.facebook?.enabled === '1'
    };

    state.snapchat = {
      enabled: data.settings?.snapchat?.enabled === '1'
    };

    state.sms = {
      enabled: data.settings?.sms?.enabled === '1',
      message: data.settings?.sms?.message ?? ''
    };

    state.email = {
      enabled: data.settings?.email?.enabled === '1',
      body: data.settings?.email?.body ?? '',
      subject: data.settings?.email?.subject ?? ''
    };

    state.twitter = {
      enabled: data.settings?.twitter?.enabled === '1',
      message: data.settings?.twitter?.message ?? undefined
    };

    state.linkedin = {
      enabled: data.settings?.linkedin?.enabled === '1',
      title: data.settings?.linkedin?.title,
      description: data.settings?.linkedin?.description
    };

    state.whatsapp = {
      enabled: data.settings?.whatsapp?.enabled === '1',
      message: data.settings?.whatsapp?.message ?? undefined
    };

    state.messenger = {
      enabled: data.settings?.messenger?.enabled === '1'
    };

    state.customShareUrl = {
      enabled: !!data.settings?.custom_share_url?.url,
      url: data.settings?.custom_share_url?.url
    };

    if (data.settings?.image) {
      state.image = data.settings.image;
    } else {
      state.image = undefined;
    }

    if (data.settings?.layout) {
      state.layout = AddonLFShareModel.parseLayoutSettings(data);
    } else {
      state.layout = undefined;
    }
    state.shareClasses = [];
    if (state.layout && state.layout?.alignment) {
      state.shareClasses.push(`content__item--share-${state.layout.alignment}`);
    } else {
      state.shareClasses.push(`content__item--share-${AlignContentType.CENTER}`);
    }
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    const hasEnabledMethod =
      data.settings?.facebook?.enabled === '1' ||
      data.settings?.twitter?.enabled === '1' ||
      data.settings?.linkedin?.enabled === '1' ||
      data.settings?.messenger?.enabled === '1' ||
      data.settings?.whatsapp?.enabled === '1' ||
      data.settings?.email?.enabled === '1' ||
      data.settings?.sms?.enabled === '1' ||
      data.settings?.snapchat?.enabled === '1';

    return hasEnabledMethod;
  }

  private static parseLayoutSettings(data: AddonLfShareData): Layout | undefined {
    if (data.settings?.layout?.settings) {
      const useData = getDeviceData(data.settings.layout.settings);
      return AddonLFShareModel.constructLayout(useData);
    }

    return undefined;
  }

  private static constructLayout(data?: ShareLayoutSetting): Layout {
    return {
      alignment: data?.alignment,
      iconBackgroundColor: data?.icon_background_color,
      iconColor: data?.icon_color,
      iconSize: data?.icon_size
    };
  }
}
