import type { AddonTrustPilotData, AddonTrustPilotSettingsData } from '@/src/components/addons/trustpilot/Data';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';
import { TrustPilotSkinType } from '@/src/typings/enums/enums';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import useDevice from '@/src/hooks/useDevice';

interface AddonTrustPilotState extends AddonModelState {
  settings: AddonTrustPilotSettings;
  height: number;
}

interface AddonTrustPilotTemplate {
  templateID?: string;
  businessUnitID?: string;
  skin?: TrustPilotSkinType;
  stars?: number[];
}

export interface AddonTrustPilotSettings {
  template?: AddonTrustPilotTemplate;
  advanced?: AdvancedAddonsStyleData;
}

export class AddonTrustPilotModel extends AddonModel<AddonTrustPilotData, AddonTrustPilotState, 'trustpilot'> {
  parseAddon(data: AddonTrustPilotData) {
    const state = this.state;
    const { isTablet, isMobile } = useDevice();

    state.settings = state.settings || {};
    state.settings.template = AddonTrustPilotModel.constructTrustPilotSettingsState(data.settings, state);

    const defaultHeight: number = Number(data.settings.height) || 130;
    if (isMobile) {
      const mobileHeight = Number(data.settings.device_height?.mobile) || defaultHeight;
      state.height = mobileHeight || Number(data.settings.height);
    } else if (isTablet) {
      state.height = Number(data.settings.device_height?.tablet) || defaultHeight;
    } else {
      state.height = defaultHeight;
    }
  }

  public isAddonValid(): boolean {
    const data = this.getData();

    return !(
      !data.settings.template_id ||
      !data.settings.business_unit_id ||
      data.settings.template_id === '' ||
      data.settings.business_unit_id === ''
    );
  }

  private static constructTrustPilotSettingsState(
    data: AddonTrustPilotSettingsData,
    currentState: AddonTrustPilotState
  ) {
    let stars = [4, 5];

    if (data.stars) {
      stars = data.stars.map((x) => +x);
    }

    const settings = currentState.settings.template || {};

    if (data.business_unit_id) {
      settings.businessUnitID = data.business_unit_id;
    } else {
      settings.businessUnitID = '';
    }

    if (data.template_id) {
      settings.templateID = data.template_id;
    } else {
      settings.templateID = '';
    }

    if (data.business_unit_id) {
      settings.skin = data.skin || TrustPilotSkinType.LIGHT;
    }

    if (!(settings.stars && settings.stars.join() === stars.join())) {
      settings.stars = stars;
    }

    return settings;
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
