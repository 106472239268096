import type { Component } from 'vue';
import type { IntegrationOpenIdConnectData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationOpenIdConnectState extends BaseIntegrationState {
  loginUrl?: string;
  fieldsMapped?: number[];
  responseType: 'code' | 'id_token';
  tokenEndpoint?: string;
  clientId?: string;
  redirectUri: string;
  rememberMe: boolean;
}

export class IntegrationOpenIdConnectModel extends BaseIntegration<
  IntegrationOpenIdConnectData,
  IntegrationOpenIdConnectState
> {
  parse(data: IntegrationOpenIdConnectData) {
    super.parse(data);
    this.state.loginUrl = data.settings?.openid_connect_login_url;
    this.state.tokenEndpoint = data.settings?.token_endpoint;
    this.state.fieldsMapped = data.settings?.openid_connect_fields_mapped ?? [];
    this.state.responseType = data.settings?.response_type ?? 'id_token';
    this.state.clientId = data.settings?.client_id;
    this.state.redirectUri = data.settings?.redirect_uri ?? '';
    this.state.rememberMe = !!data.settings?.remember_me;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/open-id-connect/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Ani Mikova';
  }
}
