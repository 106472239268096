import type { GameIndicator, GameState } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { GameAdvancedSettingsModel } from '@/src/models/settings/GameAdvancedSettingsModel';
import { AlignContentType, VideoSizeType } from '@/src/typings/enums/enums';
import { AnswerLayoutSettingsModel } from '@/src/models/settings/AnswerLayoutSettingsModel';

import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import type {
  GameVideoQuizData,
  GameVideoQuizGeneralData,
  GameVideoQuizQuestionData,
  GameVideoQuizVideoData,
  GameVideoQuizVideoQuestionData
} from '@/src/components/games/video-quiz/Data';
import { formatUrl } from '@/src/utilities/Url';

export enum QuizVideoPositionTypes {
  ABOVE,
  LEFT,
  RIGHT
}

export interface CustomLabelState {
  buttons: {
    answer?: string;
    lastAnswer?: string;
    selectAnswer?: string;
  };
  linkLabel?: string;
}

export interface GameVideoQuizGeneralState {
  contentAlign: AlignContentType;
  answerAlignment: AlignContentType;
  questionsShown?: number;
  loserOnIncorrectAnswer: boolean;
  showAnswerButton: boolean;
  showCorrectAnswer: boolean;
  correctAnswersRequired?: number;
  disableSound: boolean;
  customLabels: CustomLabelState;
  activeStateColor?: string;
  activeStateBackgroundColor?: string;
  finishLastVideo: boolean;
  videoHeight?: number;
  videoWidth?: number;
  videoSizeUnit?: VideoSizeType;
  time: {
    enabled: boolean;
    limit?: number;
    perQuestion: boolean;
  };
}

export interface GameVideoQuizAnswerState {
  id: number;
  text: string;
  description?: string;
}

export interface GameVideoQuizQuestionState {
  id: number;
  question: string;
  questionNumber: number;
  questionButtonLabel: string;
  enableDescription: boolean;
  answers: GameVideoQuizAnswerState[];
  correctAnswers: number[];
  link: {
    enabled: boolean;
    url?: string;
  };
  questionString?: string;
  correctString?: string;
  wrongString?: string;
  answerLayout: AnswerLayoutSettingsModel;
  answerColor?: {
    correct?: string;
    wrong?: string;
  };
}

export interface GameVideoQuizVideoQuestionState {
  questionId: number;
  timestamp: string;
}

export interface GameVideoQuizVideosState {
  id: number;
  videoURL: string;
  muted: boolean;
  dimension: {
    videoHeight?: number;
    videoWidth?: number;
    videoSizeUnit?: VideoSizeType;
  };
  questions: GameVideoQuizVideoQuestionState[];
}

export interface GameVideoQuizVideoState {
  video: GameVideoQuizVideosState[];
  tableData: {
    sort: string;
  };
}

export interface GameVideoQuizState extends GameState {
  general: GameVideoQuizGeneralState;
  questions: GameVideoQuizQuestionState[];
  videos: GameVideoQuizVideoState;
  advanced?: GameAdvancedSettingsModel;
}

export class GameVideoQuizModel extends GameModel<GameVideoQuizData, GameVideoQuizState> {
  parseGame(data: GameVideoQuizData) {
    const state = this.state;
    state.general = GameVideoQuizModel.constructGeneralState(data.general);

    const questions: GameVideoQuizQuestionState[] = [];

    let i = 1;
    if (Array.isArray(data.questions.question)) {
      data.questions.question.forEach((element) => {
        questions.push(this.constructQuestionState(element, data.general, i));
        i++;
      });
    } else {
      for (const property in data.questions.question) {
        if (Object.hasOwnProperty.call(data.questions.question, property)) {
          questions.push(this.constructQuestionState(data.questions.question[Number(property)], data.general, i));
          i++;
        }
      }
    }

    state.questions = questions;

    state.videos = GameVideoQuizModel.constructVideoState(data.videos, data.general);

    if (data.advanced) {
      if (state.advanced) {
        state.advanced.setData(data.advanced);
      } else {
        state.advanced = new GameAdvancedSettingsModel(data.advanced);
      }
    } else {
      state.advanced = undefined;
    }
  }

  private static constructVideoQuestionState(
    data: GameVideoQuizVideoQuestionData[]
  ): GameVideoQuizVideoQuestionState[] {
    if (data) {
      return data.map((question) => {
        return {
          questionId: Number(question.question_id),
          timestamp: question.timestamp
        };
      });
    }
    return [];
  }

  private static constructVideoState(
    data: GameVideoQuizVideoData,
    generelData: GameVideoQuizGeneralData
  ): GameVideoQuizVideoState {
    return {
      video: GameVideoQuizModel.constructVideoQuizVideosState(data, generelData),
      tableData: {
        sort: ''
      }
    };
  }

  private static constructVideoQuizVideosState(
    data: GameVideoQuizVideoData,
    generelData: GameVideoQuizGeneralData
  ): GameVideoQuizVideosState[] {
    return data.video.map((video) => {
      return {
        id: Number(video.id),
        videoURL: video.video_url,
        muted: generelData.disable_sound === '1',
        dimension: {
          ...(generelData?.video_height && { videoHeight: parseInt(generelData.video_height, 10) }),
          ...(generelData?.video_width && {
            videoWidth: parseInt(generelData.video_width, 10),
            videoSizeUnit: generelData.video_width.includes('%') ? VideoSizeType.PERCENT : VideoSizeType.PIXEL
          })
        },
        questions: GameVideoQuizModel.constructVideoQuestionState(video.questions)
      };
    });
  }

  private static constructGeneralState(data: GameVideoQuizGeneralData): GameVideoQuizGeneralState {
    let showCorrectAnswer = false;

    // old templates used different data format, so have to check if any of them match -_-
    if (typeof data.show_correct_answer === 'object') {
      showCorrectAnswer = data.show_correct_answer?.enabled === '1';
    } else if (typeof data.show_correct_answer === 'number') {
      showCorrectAnswer = data.show_correct_answer === 1;
    } else if (typeof data.show_correct_answer === 'boolean') {
      showCorrectAnswer = data.show_correct_answer;
    } else if (typeof data.show_correct_answer === 'string') {
      showCorrectAnswer = data.show_correct_answer === '1';
    }

    return {
      contentAlign: data.quiz_align ?? AlignContentType.CENTER,
      answerAlignment: data.answer_alignment ?? AlignContentType.CENTER,
      loserOnIncorrectAnswer: data?.loser_on_incorrect_answer === '1',
      showAnswerButton: data.show_answer_button ? data.show_answer_button === '1' : true,
      showCorrectAnswer,
      ...(data?.correct_answers && { correctAnswersRequired: Number(data.correct_answers) }),
      finishLastVideo: data.finish_last_video === '1',
      disableSound: data.disable_sound === '1',
      customLabels: {
        buttons: {
          answer: data.answer_btn,
          lastAnswer: data.answer_btn_last,
          selectAnswer: data.select_answer_btn ? data.select_answer_btn : 'Pick an answer'
        },
        linkLabel: data?.link
      },
      activeStateColor: data.active_state_color,
      activeStateBackgroundColor: data.active_state_bg_color,
      time: {
        enabled: data.time === 1,
        ...(data?.time_limit && { limit: Number(data.time_limit) }),
        perQuestion: data.time_per_question === '1'
      },

      ...(data?.video_height && { videoHeight: parseInt(data.video_height, 10) }),
      ...(data?.video_width && {
        videoWidth: parseInt(data.video_width, 10),
        videoSizeUnit: data.video_width.includes('%') ? VideoSizeType.PERCENT : VideoSizeType.PIXEL
      })
    };
  }

  private constructQuestionState(
    data: GameVideoQuizQuestionData,
    generalData: GameVideoQuizGeneralData,
    index: number
  ): GameVideoQuizQuestionState {
    const existingQuestionState = this.state?.questions?.find(
      (existingQuestion) => existingQuestion.id === Number(data.id)
    );

    let answerLayout: AnswerLayoutSettingsModel | undefined;
    answerLayout = existingQuestionState ? existingQuestionState.answerLayout : undefined;
    if (answerLayout) {
      answerLayout.setData(generalData);
    } else {
      answerLayout = new AnswerLayoutSettingsModel(generalData);
    }

    let correctAnswers: number[] = [];

    if (data.correct_answer instanceof Array) {
      correctAnswers = data.correct_answer.map(function (x) {
        return parseInt(x.replace(/^\D+/g, ''), 10);
      });
    } else if (typeof data.correct_answer === 'string') {
      correctAnswers.push(parseInt(data.correct_answer.replace(/^\D+/g, ''), 10));
    }

    return {
      id: Number(data.id),
      questionNumber: index,
      question: data.question,
      enableDescription: data.additional_description === '1',
      answers: GameVideoQuizModel.constructAnswerState(data),
      correctAnswers,
      questionButtonLabel: data.question_button_label,
      link: {
        enabled: !!data.link,
        ...(!!data.link && {
          url: formatUrl(`${data.link}`)
        })
      },
      questionString: data.question_string,
      correctString: data.correct_string,
      wrongString: data.wrong_string,
      answerLayout,
      answerColor: {
        correct: generalData.correct_answer_color ? generalData.correct_answer_color : '#68bb5a',
        wrong: generalData.wrong_answer_color ? generalData.wrong_answer_color : '#bb5a80'
      }
    };
  }

  private static constructAnswerState(data: GameVideoQuizQuestionData): GameVideoQuizAnswerState[] {
    const answers: GameVideoQuizAnswerState[] = [];

    if (data.answer_1) {
      answers.push({
        id: 1,
        text: data.answer_1,
        ...(data?.answer_1_additional && { description: data.answer_1_additional })
      });
    }

    if (data.answer_2) {
      answers.push({
        id: 2,
        text: data.answer_2,
        ...(data?.answer_2_additional && { description: data.answer_2_additional })
      });
    }

    if (data.answer_3) {
      answers.push({
        id: 3,
        text: data.answer_3,
        ...(data?.answer_3_additional && { description: data.answer_3_additional })
      });
    }

    if (data.answer_4) {
      answers.push({
        id: 4,
        text: data.answer_4,
        ...(data?.answer_4_additional && { description: data.answer_4_additional })
      });
    }

    if (data.answer_5) {
      answers.push({
        id: 5,
        text: data.answer_5,
        ...(data?.answer_5_additional && { description: data.answer_5_additional })
      });
    }

    if (data.answer_6) {
      answers.push({
        id: 6,
        text: data.answer_6,
        ...(data?.answer_6_additional && { description: data.answer_6_additional })
      });
    }

    if (data.answer_7) {
      answers.push({
        id: 7,
        text: data.answer_7,
        ...(data?.answer_7_additional && { description: data.answer_7_additional })
      });
    }

    if (data.answer_8) {
      answers.push({
        id: 8,
        text: data.answer_8,
        ...(data?.answer_8_additional && { description: data.answer_8_additional })
      });
    }

    if (data.answer_9) {
      answers.push({
        id: 9,
        text: data.answer_9,
        ...(data?.answer_9_additional && { description: data.answer_9_additional })
      });
    }

    if (data.answer_10) {
      answers.push({
        id: 10,
        text: data.answer_10,
        ...(data?.answer_10_additional && { description: data.answer_10_additional })
      });
    }

    if (data.answer_11) {
      answers.push({
        id: 11,
        text: data.answer_11,
        ...(data?.answer_11_additional && { description: data.answer_11_additional })
      });
    }

    if (data.answer_12) {
      answers.push({
        id: 12,
        text: data.answer_12,
        ...(data?.answer_12_additional && { description: data.answer_12_additional })
      });
    }

    if (data.answer_13) {
      answers.push({
        id: 13,
        text: data.answer_13,
        ...(data?.answer_13_additional && { description: data.answer_13_additional })
      });
    }

    if (data.answer_14) {
      answers.push({
        id: 14,
        text: data.answer_14,
        ...(data?.answer_14_additional && { description: data.answer_14_additional })
      });
    }

    if (data.answer_15) {
      answers.push({
        id: 15,
        text: data.answer_15,
        ...(data?.answer_15_additional && { description: data.answer_15_additional })
      });
    }

    if (data.answer_16) {
      answers.push({
        id: 16,
        text: data.answer_16,
        ...(data?.answer_16_additional && { description: data.answer_16_additional })
      });
    }

    if (data.answer_17) {
      answers.push({
        id: 17,
        text: data.answer_17,
        ...(data?.answer_17_additional && { description: data.answer_17_additional })
      });
    }

    if (data.answer_18) {
      answers.push({
        id: 18,
        text: data.answer_18,
        ...(data?.answer_18_additional && { description: data.answer_18_additional })
      });
    }

    if (data.answer_19) {
      answers.push({
        id: 19,
        text: data.answer_19,
        ...(data?.answer_19_additional && { description: data.answer_19_additional })
      });
    }

    if (data.answer_20) {
      answers.push({
        id: 20,
        text: data.answer_20,
        ...(data?.answer_20_additional && { description: data.answer_20_additional })
      });
    }

    if (data.answer_21) {
      answers.push({
        id: 21,
        text: data.answer_21,
        ...(data?.answer_21_additional && { description: data.answer_21_additional })
      });
    }

    if (data.answer_22) {
      answers.push({
        id: 22,
        text: data.answer_22,
        ...(data?.answer_22_additional && { description: data.answer_22_additional })
      });
    }

    if (data.answer_23) {
      answers.push({
        id: 23,
        text: data.answer_23,
        ...(data?.answer_23_additional && { description: data.answer_23_additional })
      });
    }

    if (data.answer_24) {
      answers.push({
        id: 24,
        text: data.answer_24,
        ...(data?.answer_24_additional && { description: data.answer_24_additional })
      });
    }

    if (data.answer_25) {
      answers.push({
        id: 25,
        text: data.answer_25,
        ...(data?.answer_25_additional && { description: data.answer_25_additional })
      });
    }

    return answers;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.general.time.limit ?? 0,
          time_used: 1
        }
      }
    ];
  }

  public isGameValid(): boolean {
    return true;
  }
}
