export enum GameWordRiddleLangType {
  ENGLISH,
  DANISH,
  GERMAN,
  SWEDISH,
  DUTCH,
  FINNISH
}

export const enum GameWordRiddleLetterType {
  INITIAL,
  CORRECT,
  PRESENT,
  ABSENT
}

export type GameWordRiddleWordList = string[];

export interface WordRiddleTile {
  letter: string;
  state: GameWordRiddleLetterType;
  tileClass: string;
}

export type WordRiddleBoard = WordRiddleTile[][];
