import type { Component } from 'vue';
import type { IntegrationCarlsJrSettingsData } from './Data';
import type { BaseIntegrationData, BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationCarlsJrData extends BaseIntegrationData {
  settings: null | IntegrationCarlsJrSettingsData;
}

export interface IntegrationCarlsJrState extends BaseIntegrationState {
  type: string;
}

export class IntegrationCarlsJrModel extends BaseIntegration<IntegrationCarlsJrData, IntegrationCarlsJrState> {
  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/custom/carlsjr/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
