import { BaseModel } from '@/src/models/BaseModel';
import { RowRepeatableSortDirection } from '@/src/components/layout/row/RowModel';
import type { RowRepeatableData } from '@/src/typings/interfaces/data/settings/row-settings';

export interface RepeatableState {
  enabled?: boolean;
  type?: string;
  columnGrid?: number;
  feedUrl?: string;
  limit?: number;

  sort?: {
    by?: string;
    direction?: RowRepeatableSortDirection;
  };
}

export class RepeatableModel extends BaseModel<RowRepeatableData, RepeatableState> {
  parse(data: RowRepeatableData) {
    const state = this.state;

    if (!data.enabled) {
      state.enabled = false;
      return state;
    }

    state.enabled = data.enabled === '1';
    state.limit = typeof data.limit !== 'undefined' ? Number(data.limit) : undefined;
    state.type = typeof data.data !== 'undefined' ? data.data : undefined;
    state.columnGrid = typeof data.column_grid !== 'undefined' ? Number(data.column_grid) : undefined;
    state.feedUrl = typeof data.feed_url !== 'undefined' ? data.feed_url : undefined;

    state.sort = state.sort || {};
    state.sort.by = data.sort?.by;
    state.sort.direction =
      data.sort?.direction === 'desc' ? RowRepeatableSortDirection.DESC : RowRepeatableSortDirection.ASC;

    return state;
  }

  authorSignature(): string {
    return 'Dannie Hansen';
  }
}
