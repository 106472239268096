import type {
  GameSlotAdvancedData,
  GameSlotData,
  GameSlotGeneralData,
  GameSlotLayoutData,
  GameSlotSlotsData
} from '@/src/components/games/slot-machine/Data';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import useDevice from '@/src/hooks/useDevice';
import type { GameIndicator, HasSound, InstantWinData } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { CampaignAdsSizeType, CampaignDeviceType } from '@/src/typings/enums/enums';
import { shuffle } from '@/src/utilities/Utilities';
import { useCampaignStore } from '@/src/store/campaign';

export interface GameSlotGeneralState {
  amountOfSpins: number;
  amountOfSlots: number;
  gameEndedDelay: number;
  winnerSettings: {
    drawType: DrawType;
    winnerChance: number;
    winnerSlotId?: number | 'all';
    winnerSlot?: number;
  };
  goButton: GoButton;
  campaignLimits: {
    interval: CampaigLimitInterval;
    prize: number;
  };
  holdButton: HoldButton;
}

export interface HoldButton {
  enabled: boolean;
  label?: string;
}

export interface GoButton {
  type: ButtonType;
  label?: string;
  imagePath?: string;
  position: ButtonPosition;
}

export interface GameSlotSlotState {
  id: number;
  image: string;
}
export interface GameSlotState {
  general: GameSlotGeneralState;
  slots: GameSlotSlotState[];
  rollers: GameSlotRoller[];
  customLayout?: GameSlotCustomLayout;
  winner: boolean;
  advanced: GameSlotAdvancedState;
}

interface GameSlotAdvancedState {
  sound: {
    enabled: boolean;
    url?: string;
  };
}

export interface GameSlotCustomLayout {
  enabled: boolean;
  backgroundImage?: string;
}

export enum DrawType {
  AUTO,
  MANUAL
}
export enum ButtonType {
  LABEL,
  IMAGE
}
export enum ButtonPosition {
  BELOW,
  ABOVE
}
enum CampaigLimitInterval {
  DAY,
  CAMPAIGN_PERIOD,
  WEEKLY,
  MONTHLY
}

export interface GameSlotRoller {
  id: number;
  placement?: GameSlotLayoutPlaceState;
}

export interface GameSlotLayoutPlaceState {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  label: string;
}

export class GameSlotModel extends GameModel<GameSlotData, GameSlotState> implements HasSound {
  parseGame(data: GameSlotData): void {
    const state = this.state;
    state.general = GameSlotModel.constructGeneralState(data.general);

    /**
     * Rollers is default 3, having an array before constructing, makes easy to convert
     * it in the future. If we later on want to support more rollers.
     */
    const rollers: GameSlotRoller[] = [{ id: 1 }, { id: 2 }, { id: 3 }];

    state.rollers = GameSlotModel.parseRollerState(rollers, data);
    if (data.layout) {
      state.customLayout = GameSlotModel.parseLayoutImage(data);
    }

    state.slots = shuffle(GameSlotModel.constructSlotsState(data.slots));

    state.advanced = GameSlotModel.constructAdvancedState(data.advanced);
  }

  private static constructGeneralState(data: GameSlotGeneralData): GameSlotGeneralState {
    let drawType: DrawType | undefined;
    switch (data.draw_type) {
      case 'auto':
        drawType = DrawType.AUTO;
        break;

      default:
        drawType = DrawType.MANUAL;
        break;
    }

    let buttonType: ButtonType | undefined;
    let buttonImagePath: string | undefined;
    let buttonLabel: string | undefined;
    switch (data.go_type) {
      case 'image':
        buttonType = ButtonType.IMAGE;
        buttonImagePath = data.go_btn_img;
        break;

      default:
        buttonType = ButtonType.LABEL;
        buttonLabel = data.go_btn;
        break;
    }
    let buttonPosition: ButtonPosition | undefined;

    switch (data.button_position) {
      case 'above':
        buttonPosition = ButtonPosition.ABOVE;
        break;
      default:
        buttonPosition = ButtonPosition.BELOW;
        break;
    }

    let campaignLimitInterval: CampaigLimitInterval | undefined;

    switch (data.campaign_limit_interval) {
      case 'DAY':
        campaignLimitInterval = CampaigLimitInterval.DAY;
        break;

      case 'WEEKLY':
        campaignLimitInterval = CampaigLimitInterval.WEEKLY;
        break;
      case 'MONTHLY':
        campaignLimitInterval = CampaigLimitInterval.MONTHLY;
        break;

      default:
        campaignLimitInterval = CampaigLimitInterval.CAMPAIGN_PERIOD;
        break;
    }

    let winnerSlotId: number | 'all' | undefined;

    switch (data.winner_slot) {
      case 'all':
        winnerSlotId = data.winner_slot;
        break;

      default:
        winnerSlotId = data.winner_slot ? Number(data.winner_slot) : undefined;
        break;
    }

    return {
      amountOfSpins: Number(data.turns),
      amountOfSlots: Number(data.slots),
      gameEndedDelay: Number(data.delay),
      winnerSettings: {
        drawType,
        winnerChance: data.winner_chance ? Number(data.winner_chance) : 0,
        winnerSlotId
      },
      goButton: {
        type: buttonType,
        label: buttonLabel,
        imagePath: buttonImagePath,
        position: buttonPosition
      },
      campaignLimits: {
        interval: campaignLimitInterval,
        prize: Number(data.campaign_limit)
      },
      holdButton: {
        enabled: data.hold?.active === '1' || false,
        label: data.hold?.text
      }
    };
  }

  private static parseLayoutImage(data: GameSlotData): GameSlotCustomLayout {
    let layout: GameSlotCustomLayout | undefined;

    const { isMobile } = useDevice();
    const campaignStore = useCampaignStore();

    layout =
      // Support for ads 'default' setting
      campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
      campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.FIXED
        ? {
            enabled: !!data.layout?.layout.default?.image,
            backgroundImage: data.layout?.layout.default?.image
          }
        : {
            enabled: !!data.layout?.layout.desktop?.image,
            backgroundImage: data.layout?.layout.desktop?.image
          };

    // Only overwrite with mobile settings if it's configured for all devices
    if (
      isMobile &&
      (campaignStore.model?.state.deviceType === CampaignDeviceType.ALL_DEVICES ||
        (campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
          campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.RESPONSIVE))
    ) {
      layout = {
        enabled: !!data.layout?.layout.mobile?.image,
        backgroundImage: data.layout?.layout.mobile?.image
      };
    }

    return layout;
  }

  private static parseRollerState(rollers: GameSlotRoller[], data: GameSlotData) {
    let useData: GameSlotLayoutData | undefined;

    const { isMobile } = useDevice();
    const campaignStore = useCampaignStore();

    if (
      isMobile &&
      (campaignStore.model?.state.deviceType === CampaignDeviceType.ALL_DEVICES ||
        (campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
          campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.RESPONSIVE))
    ) {
      useData = data.layout?.layout.mobile;
    } else if (isMobile && campaignStore.model?.state.deviceType === CampaignDeviceType.ADS) {
      useData = data.layout?.layout.default;
    } else {
      useData = data.layout?.layout.desktop;
    }

    return rollers.map((roller) => GameSlotModel.constructRollerState(roller, useData));
  }

  private static constructRollerState(roller: GameSlotRoller, layout?: GameSlotLayoutData): GameSlotRoller {
    const position = layout?.place.find((pos) => Number(pos.id) === roller.id);

    return {
      id: roller.id,
      ...(position && {
        placement: {
          id: Number(position.id),
          x: Number(position.x),
          y: Number(position.y),
          width: Number(position.w),
          height: Number(position.h),
          label: position.label
        }
      })
    };
  }

  private static constructSlotsState(data: GameSlotSlotsData): GameSlotSlotState[] {
    const slots: GameSlotSlotState[] = [];

    for (const key in data.slot) {
      if (Object.prototype.hasOwnProperty.call(data.slot, key)) {
        slots.push({
          id: Number(key),
          image: data.slot[`${key}`].image
        });
      }
    }

    return slots;
  }

  private static constructAdvancedState(data?: GameSlotAdvancedData): GameSlotAdvancedState {
    return {
      sound: {
        enabled: !!data?.sound,
        url: data?.sound
      }
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    // disable indicator if turns(spins) is set to 1

    if (this.getData().general.turns === '1') {
      return [];
    }
    return [
      {
        indicatorKey: 'spins',
        metricKey: {
          spins_left: 'rolls_left',
          spins_used: 'rolls'
        },
        icon: GameIndicatorIcon.SPINS,
        value: {
          spins_left: this.state.general.amountOfSpins,
          spins_used: 0
        }
      }
    ];
  }

  public setInstantWinnerData(data: InstantWinData) {
    super.setInstantWinnerData(data);

    const winnerSlot = data.winner_slot;
    const winnerSlotId = data.winner_slot_id;

    if (winnerSlotId) {
      this.state.general.winnerSettings.winnerSlotId = Number(winnerSlotId);
    }

    if (winnerSlot) {
      this.state.general.winnerSettings.winnerSlot = Number(winnerSlot);
    }
  }

  public hasInstantWin(): boolean {
    return this.getData().general.draw_type === 'auto';
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.url) {
      sounds.push(this.state.advanced.sound.url);
    }

    return sounds;
  }
}
