import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1000 100",
  preserveAspectRatio: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      class: "elementor-shape-fill",
      d: "M737.9,94.7L0,0v100h1000V0L737.9,94.7z"
    }, null, -1)
  ])))
}
export default { render: render }