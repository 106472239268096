<template>
  <svg
    class="game-indicator-item__icon-path game-indicator-item__icon-path--dice"
    width="46px"
    height="47px"
    viewBox="0 0 46 47"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1022.000000, -423.000000)">
        <g id="Group-11" transform="translate(373.000000, 338.000000)">
          <g id="Group-9" transform="translate(649.000000, 49.000000)">
            <g id="Group-8" transform="translate(0.000000, 36.441945)">
              <rect id="Rectangle" stroke="#FFFFFF" stroke-width="2" x="1" y="1" width="44" height="44" rx="8"></rect>

              <g id="Group-7" transform="translate(11.000000, 9.000000)" fill="#FFFFFF">
                <g id="Group-6">
                  <circle id="Oval" cx="3.40740741" cy="3.40740741" r="3.40740741"></circle>
                  <circle id="Oval-Copy" cx="21.4074074" cy="3.40740741" r="3.40740741"></circle>
                </g>

                <g id="Group-4" transform="translate(0.000000, 19.000000)">
                  <circle id="Oval" cx="3.40740741" cy="3.74074074" r="3.40740741"></circle>

                  <circle id="Oval-Copy" cx="21.4074074" cy="3.40740741" r="3.40740741"></circle>
                </g>
                <circle id="Oval-Copy-2" cx="12.4074074" cy="13.4074074" r="3.40740741"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DiceIndicator'
});
</script>
