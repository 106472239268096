import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GameSpotTheDifferenceAdvancedData,
  GameSpotTheDifferenceData,
  GameSpotTheDifferenceGeneralData,
  GameSpotTheDifferenceLayoutLayoutData,
  GameSpotTheDifferenceLayoutLayoutDeviceData,
  GameSpotTheDifferenceLayoutLayoutPlaceData
} from '@/src/components/games/spotthedifference/Data';
import { GameSpotTheDifferencePositionType } from '@/src/components/games/spotthedifference/enums';
import type { GameIndicatorPosition, GameIndicatorSettingsModel } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import useDevice from '@/src/hooks/useDevice';
import type { TransitionTypes } from '@/src/typings/types/types';

export interface GameSpotTheDifferenceGeneralState {
  errors: {
    count: number;
    clicks: number;
    effect: {
      drawCircle: boolean;
      shine: boolean;
      transition: boolean;
    };
  };
  positioning: GameSpotTheDifferencePositionType;
  oneImage: boolean;
  enableBestTime: boolean;
  enableTimeRanking: boolean;
}

export interface GameSpotTheDifferenceLayoutLayoutPlaceState {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  taken: boolean;
}

export interface GameSpotTheDifferenceLayoutLayoutDeviceState {
  backgroundLeft: string;
  place: GameSpotTheDifferenceLayoutLayoutPlaceState[];
  backgroundRight: string;
}

export interface GameSpotTheDifferenceAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GameSpotTheDifferenceAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

export interface GameSpotTheDifferenceAdvancedState {
  sound: GameSpotTheDifferenceAdvancedSoundState;
  animation: GameSpotTheDifferenceAdvancedAnimationState;
}

export interface GameSpotTheDifferenceState {
  general: GameSpotTheDifferenceGeneralState;
  layout?: GameSpotTheDifferenceLayoutLayoutDeviceState;
  advanced: GameSpotTheDifferenceAdvancedState;
  indicators?: GameIndicatorSettingsModel;
  elementStyling: {
    inlineRightImageClasses?: string[];
    inlineLeftImageClasses?: string;
    inlineContentClasses?: string[];
    inlineGameClasses?: string;
  };
}

export class GameSpotTheDifferenceModel
  extends GameModel<GameSpotTheDifferenceData, GameSpotTheDifferenceState>
  implements HasGameTimeChallenge, HasSound
{
  parseGame(data: GameSpotTheDifferenceData): void {
    const state = this.state;
    state.general = GameSpotTheDifferenceModel.constructGeneralState(data.general);
    state.layout = GameSpotTheDifferenceModel.parseLayoutLayoutData(data.layout.layout);

    state.elementStyling = {};

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameSpotTheDifferenceModel.constructAdvancedSoundState(data?.advanced);

    if (state.general) {
      state.elementStyling.inlineRightImageClasses = GameSpotTheDifferenceModel.constructInlineRightImage(
        state.general
      );
      state.elementStyling.inlineLeftImageClasses = GameSpotTheDifferenceModel.constructInlineLeftImage(state.general);
      state.elementStyling.inlineContentClasses = GameSpotTheDifferenceModel.constructInlineContent(state.general);
      state.elementStyling.inlineGameClasses = GameSpotTheDifferenceModel.constructInlineGame(state.general);
    }
  }

  private static constructAdvancedAnimationState(
    data: GameSpotTheDifferenceAdvancedData
  ): GameSpotTheDifferenceAdvancedAnimationState {
    return {
      enabled: !!data.animation,
      ...(data.animation && { type: data.animation })
    };
  }

  private static constructAdvancedSoundState(
    data: GameSpotTheDifferenceAdvancedData | undefined
  ): GameSpotTheDifferenceAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructInlineGame(state: GameSpotTheDifferenceGeneralState): string {
    let htmlClasses = '';

    if (state.oneImage) {
      htmlClasses = 'finderrors--one-image';
    }

    return htmlClasses;
  }

  private static constructInlineContent(state: GameSpotTheDifferenceGeneralState): string[] {
    const htmlClasses: string[] = [];
    if (state.positioning === GameSpotTheDifferencePositionType.NEXT_TO) {
      htmlClasses.push(`row`);
    }

    if (state.positioning === GameSpotTheDifferencePositionType.ABOVE || state.oneImage) {
      htmlClasses.push(`finderrors__game-contents--above`);
    }

    return htmlClasses;
  }

  private static constructInlineLeftImage(state: GameSpotTheDifferenceGeneralState): string {
    let htmlClasses = '';

    if (state.positioning === GameSpotTheDifferencePositionType.NEXT_TO) {
      htmlClasses = 'col-md-6';
    }

    return htmlClasses;
  }

  private static constructInlineRightImage(state: GameSpotTheDifferenceGeneralState): string[] {
    const htmlClasses: string[] = [];

    if (state.positioning === GameSpotTheDifferencePositionType.NEXT_TO && !state.oneImage) {
      htmlClasses.push(`col-md-6`);
    }

    if (state.oneImage) {
      htmlClasses.push(`col-md-12`);
    }

    return htmlClasses;
  }

  private static constructGeneralState(data: GameSpotTheDifferenceGeneralData): GameSpotTheDifferenceGeneralState {
    return {
      errors: {
        count: Number(data.errors.count),
        clicks: Number(data.errors.clicks),
        effect: {
          drawCircle: data.errors.effect.draw_circle === '1',
          shine: data.errors.effect.shine === '1',
          transition: data.errors.effect.transition === '1'
        }
      },
      positioning: data.positioning,
      oneImage: data.one_image === '1',
      enableBestTime: data.enable_best_time === '1',
      enableTimeRanking: data.enable_time_ranking === '1'
    };
  }

  private static parseLayoutLayoutData(
    data: GameSpotTheDifferenceLayoutLayoutData
  ): GameSpotTheDifferenceLayoutLayoutDeviceState | undefined {
    if (useDevice().isMobile && data.mobile?.background_left && data.mobile?.background_right) {
      return GameSpotTheDifferenceModel.constructLayoutLayoutState(data.mobile);
    }
    return GameSpotTheDifferenceModel.constructLayoutLayoutState(data.desktop);
  }

  private static constructPlaceState(
    data: GameSpotTheDifferenceLayoutLayoutPlaceData
  ): GameSpotTheDifferenceLayoutLayoutPlaceState {
    return {
      id: Number(data.id),
      x: Number(data.x),
      y: Number(data.y),
      width: Number(data.w),
      height: Number(data.h),
      taken: false
    };
  }

  private static constructLayoutLayoutState(
    data: GameSpotTheDifferenceLayoutLayoutDeviceData
  ): GameSpotTheDifferenceLayoutLayoutDeviceState {
    return {
      backgroundLeft: data.background_left,
      backgroundRight: data.background_right,
      place: data.place.map((place) => {
        return GameSpotTheDifferenceModel.constructPlaceState(place);
      })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_used: 'timeused',
          time_left: 'timeleft'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_used: 1,
          time_left: this.state.timeChallenge?.limit ?? 0
        }
      },
      {
        indicatorKey: 'tries',
        metricKey: {
          tries_used: 'clicksused',
          tries_left: 'clicksleft'
        },
        icon: GameIndicatorIcon.TRIES,
        value: {
          tries_used: 0,
          tries_left: this.state.general.errors.clicks
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.general.errors?.time === '1',
      ...(data.general.errors.time_limit && { limit: Number(data.general.errors.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }
}
