import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';
import type { App } from 'vue';
import AppConfig from '@/app.config';
import { router } from '@/src/router';

export default (app: App<Element>) => {
  Sentry.init({
    app: [app],
    dsn: AppConfig.sentry.dsn,
    environment: AppConfig.sentry.environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 0.0,
    allowUrls: [/\/_assets\/.+\.js/i],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error('[Exception handled by Sentry]:', hint.originalException, { event, hint });
      }

      // Continue sending to Sentry
      return event;
    }
  });

  app.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update']
    })
  );

  Sentry.attachErrorHandler(app, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  };
};
