import { SdkActionModel } from '../models/action';
import type ActionsModel from '@/src/models/actions/ActionsModel';
import type { ModelTypes } from '@/src/typings/types/types';

export class SdkActionEvent {
  #action: SdkActionModel;
  #promises: Promise<void>[] = [];
  #defaultPrevented = false;

  constructor(action: ActionsModel, model: ModelTypes) {
    this.#action = new SdkActionModel(action, model);
  }

  public suspense(fn: () => Promise<void>) {
    this.#promises.push(fn());
  }

  async waitForPromises() {
    await Promise.all(this.#promises);
  }

  public preventDefault() {
    this.#defaultPrevented = true;
  }

  public get defaultPrevented() {
    return this.#defaultPrevented;
  }

  public get origin() {
    return this.#action.origin;
  }

  public get action(): SdkActionModel {
    return this.#action;
  }
}
