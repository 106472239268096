import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';
import ActionsModel from '@/src/models/actions/ActionsModel';
import type { ActionData } from '@/src/typings/interfaces/data/settings/actions';
import { ActionType } from '@/src/typings/enums/enums';

export interface FormElementParagraphSettingsData extends BaseFormElementSettingsData {
  cta_target: string;
  cta_type: ActionType;
  cta_url: string;
  cta_popover: string;
}

export interface FormElementParagraphData extends BaseFormElementData {
  settings?: FormElementParagraphSettingsData;
}

interface FormElementParagraphState extends BaseFormElementState<string> {
  cta: {
    target?: string;
    type?: ActionType;
    url?: string;
    popover?: string;
  };
  useAction: boolean;
  action: ActionsModel;
}

export class FormElementParagraphModel extends BaseFormElementModel<
  string,
  FormElementParagraphData,
  FormElementParagraphState
> {
  parseFormElement(data: FormElementParagraphData) {
    const state = this.state;
    state.cta = state.cta ?? {};
    if (data.settings) {
      if (data.settings?.cta_target) {
        state.cta.target = data.settings?.cta_target;
      } else {
        state.cta.target = undefined;
      }
      if (data.settings?.cta_url) {
        state.cta.url = data.settings?.cta_url;
      } else {
        state.cta.url = undefined;
      }
      if (data.settings?.cta_type) {
        state.cta.type = data.settings?.cta_type;
      } else {
        state.cta.type = undefined;
      }
      if (data.settings.cta_popover) {
        state.cta.popover = data.settings.cta_popover;
      }
    } else {
      state.cta = {};
    }

    const actionObject: ActionData = {
      ...(state.cta.url && { url: state.cta.url }),
      ...(state.cta.target && { target: state.cta.target }),
      type: state.cta.type || ActionType.NONE,
      ...(state.cta.popover && { popover: state.cta.popover })
    };

    if (state.action) {
      state.action.setData(actionObject);
    } else {
      state.action = new ActionsModel(actionObject);
    }

    state.useAction = state.action.state.type !== ActionType.NONE;
  }

  getInitialValue(): string | undefined {
    return undefined;
  }

  getStringifiedValue(): string | undefined {
    return undefined;
  }

  parseStringValue(): string | undefined {
    return undefined;
  }

  getSerializedPostValue(): string {
    return '';
  }

  getSerializedCookieValue(): string {
    return '';
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
