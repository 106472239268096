import { useCampaignStore } from '@/src/store/campaign';

type ButtonType = 'btn--primary' | 'btn--secondary';

export default function useButtonEffects() {
  const applyButtonEffects = (buttonType: ButtonType) => {
    const campaignStore = useCampaignStore();
    const campaignState = campaignStore.model?.state;
    let buttonEffect = '';

    if (
      campaignState?.layout?.buttons?.buttonEffect &&
      buttonType === 'btn--primary' &&
      campaignState.layout?.buttons?.buttonEffect === 'use-global'
    ) {
      buttonEffect = campaignState.layout.buttons.buttonEffect;
      if (
        campaignState.layout?.buttons?.buttonEffect === 'use-global' &&
        campaignState.globalLayout?.buttons?.buttonEffect
      ) {
        buttonEffect = campaignState.globalLayout?.buttons?.buttonEffect;
      }
    }

    if (
      campaignState?.layout?.buttons?.secondary?.buttonEffect &&
      buttonType === 'btn--secondary' &&
      campaignState.layout?.buttons?.secondary?.buttonEffect === 'use-global'
    ) {
      buttonEffect = campaignState.layout.buttons.secondary.buttonEffect;
      if (
        campaignState.layout?.buttons?.secondary?.buttonEffect === 'use-global' &&
        campaignState.globalLayout?.buttons?.secondary?.buttonEffect
      ) {
        buttonEffect = campaignState.globalLayout.buttons.secondary.buttonEffect;
      }
    }

    if (
      campaignState?.layout?.buttons?.buttonEffect &&
      buttonType === 'btn--primary' &&
      campaignState.layout?.buttons?.buttonEffect !== 'use-global'
    ) {
      buttonEffect = campaignState.layout.buttons.buttonEffect;
    }

    if (
      campaignState?.layout?.buttons?.secondary?.buttonEffect &&
      buttonType === 'btn--secondary' &&
      campaignState.layout?.buttons?.secondary?.buttonEffect !== 'use-global'
    ) {
      buttonEffect = campaignState.layout.buttons.secondary.buttonEffect;
    }

    return buttonEffect;
  };

  return {
    applyButtonEffects
  };
}
