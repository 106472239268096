import type { Component } from 'vue';
import type { IntegrationClerkData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationClerkState extends BaseIntegrationState {
  apiKey?: string;
}

export class IntegrationClerkModel extends BaseIntegration<IntegrationClerkData, IntegrationClerkState> {
  parse(data: IntegrationClerkData) {
    super.parse(data);

    if (data.settings?.clerk) {
      this.state.apiKey = data.settings?.clerk?.api_key;
    } else {
      this.state.apiKey = undefined;
    }
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/clerk/View.vue');
  }

  hasVueComponent() {
    return true;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }
}
