import type { IntegrationKraeftensBekaempelseData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationKraeftensBekaempelseState extends BaseIntegrationState {
  bearer?: string;
  luckyNumber: {
    enabled: boolean;
    messages: {
      errorNetwork?: string;
      errorNotFound?: string;
    };
  };
}

export class IntegrationKraeftensBekaempelseModel extends BaseIntegration<
  IntegrationKraeftensBekaempelseData,
  IntegrationKraeftensBekaempelseState
> {
  parse(data: IntegrationKraeftensBekaempelseData) {
    super.parse(data);

    this.state.luckyNumber = this.state.luckyNumber || {};
    this.state.luckyNumber.messages = this.state.luckyNumber.messages || {};
    this.state.luckyNumber.enabled = !!data.settings?.kb_feature_luckynumber;

    this.state.bearer = data.settings?.kb_bearer;
    this.state.luckyNumber.messages.errorNetwork = data.settings?.luckynumber_error_network_message;
    this.state.luckyNumber.messages.errorNotFound = data.settings?.luckynumber_error_notfound_message;
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }
}
