import type { CSSProperties } from 'vue';

export enum PopoverPosition {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export interface CookieConsentOptionsData {
  content?: {
    headline?: string;
    message?: string;
    allow?: string;
    allowselected?: string;
    deny?: string;
    link?: string;
    href?: string;
    enable_functional?: string;
    enable_statistics?: string;
    enable_marketing?: string;
    functional?: string;
    statistics?: string;
    marketing?: string;
  };
  position?: PopoverPosition;
  palette?: {
    popup?: {
      font?: CSSProperties['font-family'];
      background?: CSSProperties['background'];
      text?: CSSProperties['color'];
    };

    button?: {
      background?: CSSProperties['color'];
      text?: {
        accept?: CSSProperties['color'];
        deny?: CSSProperties['color'];
      };
      radius?: CSSProperties['border-radius'];
    };
  };
}

export interface CookieConsentOptionsState {
  content?: {
    headline?: string;
    message?: string;
    allow?: string;
    allowselected?: string;
    deny?: string;
    link?: string;
    href?: string;
    enableFunctional?: boolean;
    enableStatistics?: boolean;
    enableMarketing?: boolean;
    functional?: string;
    statistics?: string;
    marketing?: string;
  };
  position?: PopoverPosition;
  palette?: {
    popup?: {
      font?: CSSProperties['font-family'];
      background?: CSSProperties['background'];
      text?: CSSProperties['color'];
    };

    button?: {
      background?: CSSProperties['color'];
      text?: {
        accept?: CSSProperties['color'];
        deny?: CSSProperties['color'];
      };
      radius?: CSSProperties['border-radius'];
    };
  };
}
