import type { GameIndicator, GameState, GameTimeChallenge, HasGameTimeChallenge } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { useUtilityStore } from '@/src/store/utility';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type {
  GameSudokuData,
  GameSudokuGeneralSettingsData,
  GameSudokuLayoutData
} from '@/src/components/games/sudoku/Data';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { SudokuGameLevel } from '@/src/components/games/sudoku/Interfaces';

interface DifficultyLevels {
  easy: number;
  normal: number;
  hard: number;
  custom: number;
}

interface GameSudokuState extends GameState {
  general?: GameSudokuGeneralState;
  layout?: GameSudokuLayoutState;
}

interface GameSudokuGeneralState {
  level: {
    difficulty: SudokuGameLevel;
    numberRemoved: number;
  };

  showNumberPad: boolean;
}

export interface GameSudokuLayoutState {
  font: {
    size: string;
    color: string;
    type: string | undefined;
  };
  grid: {
    color: string;
  };
  selectedNumberColor: string;
}

export class GameSudokuModel extends GameModel<GameSudokuData, GameSudokuState> implements HasGameTimeChallenge {
  parseGame(data: GameSudokuData) {
    const utilityStore = useUtilityStore();

    const state = this.state;

    state.general = GameSudokuModel.parseGeneralData(data.general.settings);
    state.layout = GameSudokuModel.parseLayoutData(data.general.layout);

    if (state.layout?.font?.type) {
      utilityStore.loadFont(state.layout.font.type);
    }

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }
  }

  private static parseGeneralData(data: DeviceData<GameSudokuGeneralSettingsData>): GameSudokuGeneralState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return GameSudokuModel.constructGeneralState(useData);
    }
  }

  private static constructGeneralState(data: GameSudokuGeneralSettingsData): GameSudokuGeneralState {
    const defaultDifficulties: DifficultyLevels = {
      easy: 5,
      normal: 10,
      hard: 20,
      custom: data.custom_number_removed ?? 5
    };

    const difficulty = data?.level ?? 'easy';

    let numberRemoved: number;
    switch (difficulty) {
      case 'easy':
        numberRemoved = defaultDifficulties.easy;
        break;

      case 'normal':
        numberRemoved = defaultDifficulties.normal;
        break;

      case 'hard':
        numberRemoved = defaultDifficulties.hard;
        break;

      case 'custom':
        numberRemoved = defaultDifficulties.custom;
        break;

      default:
        numberRemoved = defaultDifficulties.easy;
    }

    return {
      level: {
        difficulty,
        numberRemoved
      },
      showNumberPad: data.show_number_pad === '1'
    };
  }

  private static parseLayoutData(data: DeviceData<GameSudokuLayoutData>): GameSudokuLayoutState | undefined {
    if (data) {
      const useData = getDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return GameSudokuModel.constructLayoutState(useData);
    }
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  private static constructLayoutState(data: GameSudokuLayoutData): GameSudokuLayoutState {
    return {
      font: {
        size: data.font_size ?? '22px',
        color: data.font_color ?? '#ffffff',
        type: data.font_type
      },
      grid: {
        color: data.grid_color ?? '#000000'
      },
      selectedNumberColor: data.selected_number_color ?? '#FF0000'
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 0,
          time_used: 0
        }
      }
    ];
  }

  public isGameValid() {
    return true;
  }
}
