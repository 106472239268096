import { SdkBaseAddonModel } from '.';
import type { AddonLfButtonModel } from '@/src/components/addons/lf-button/Model';
import { SdkTypeCheck } from '@/src/sdk/utilities';

export class SdkAddonButton extends SdkBaseAddonModel {
  #model: AddonLfButtonModel;

  constructor(model: AddonLfButtonModel) {
    super(model);
    this.#model = model;
  }

  public get label(): string {
    return this.#model.state.label;
  }

  public set label(value: string) {
    try {
      SdkTypeCheck('string', value);

      this.#model.state.label = value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
