import type { AddonShareData, AddonShareMobileData, AddonShareSettingData } from '@/src/components/addons/share/Data';
import { AlignContentType } from '@/src/typings/enums/enums';

import useDevice from '@/src/hooks/useDevice';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { formatUrl } from '@/src/utilities/Url';

interface AddonShareSettingState {
  iconColor?: string;
  iconBackgroundColor?: string;
  iconSize?: number;
  roundedButtons?: boolean;
  borderRadius?: string;
  mobile?: AddonShareMobileState;
  alignment?: AlignContentType;
  isFacebookActive: boolean;
  isTwitterActive: boolean;
  isInstagramActive: boolean;
  isLinkedinActive: boolean;
  isYoutubeActive: boolean;
  isVimeoActive: boolean;
  isPinterestActive: boolean;
  isWebsiteActive: boolean;
  isTikTokActive: boolean;
  isOtherActive: boolean;
  facebookUrl: string;
  facebookOriginalUrl: string;
  twitterUrl: string;
  twitterOriginalUrl: string;
  instagramUrl: string;
  instagramOriginalUrl: string;
  linkedinUrl: string;
  linkedinOriginalUrl: string;
  youtubeUrl: string;
  youtubeOriginalUrl: string;
  vimeoUrl: string;
  vimeoOriginalUrl: string;
  pinterestUrl: string;
  pinterestOriginalUrl: string;
  websiteUrl: string;
  websiteOriginalUrl: string;
  tiktokUrl: string;
  tiktokOriginalUrl: string;
  otherUrl: string;
  otherOriginalUrl: string;
  otherImageUrl?: string;
  otherIcon?: string;
}

interface AddonShareMobileState {
  alignment: AlignContentType;
  iconBackgroundColor?: string;
  iconColor?: string;
  iconSize: number;
  roundedButtons: boolean;
  borderRadius?: string;
}

interface AddonShareState extends AddonModelState {
  settings: AddonShareSettingState;
}

export class AddonShareModel extends AddonModel<AddonShareData, AddonShareState, 'share'> {
  parseAddon(data: AddonShareData) {
    const state = this.state;
    const { isMobile } = useDevice();

    state.settings = state.settings ?? {};

    const mobileSettings = AddonShareModel.parseMobileSettingData(data.settings);
    const defaultSettings = AddonShareModel.constructSettingState(data.settings);

    if (isMobile) {
      state.settings = { ...defaultSettings, ...mobileSettings };
    } else if (defaultSettings) {
      state.settings = defaultSettings;
    }
  }

  public isAddonValid(): boolean {
    const data = this.getData();

    let numberOfActiveShareButtons = 0;

    if (data.settings.facebook === '1') {
      numberOfActiveShareButtons++;
    }

    if (data.settings.twitter === '1') {
      numberOfActiveShareButtons++;
    }

    if (data.settings.instagram === '1') {
      numberOfActiveShareButtons++;
    }

    if (data.settings.linkedin === '1') {
      numberOfActiveShareButtons++;
    }

    if (data.settings.youtube === '1') {
      numberOfActiveShareButtons++;
    }

    if (data.settings.pinterest === '1') {
      numberOfActiveShareButtons++;
    }

    if (data.settings.other === '1') {
      numberOfActiveShareButtons++;
    }

    if (data.settings.website === '1') {
      numberOfActiveShareButtons++;
    }

    if (data.settings.tiktok === '1') {
      numberOfActiveShareButtons++;
    }

    return numberOfActiveShareButtons !== 0;
  }

  private static parseMobileSettingData(data: AddonShareSettingData): AddonShareMobileState | undefined {
    if (data.mobile) {
      const useData = this.getMobileDeviceData(data);

      if (!useData) {
        return undefined;
      }
      return AddonShareModel.constructMobileSettingState(useData);
    }
    return undefined;
  }

  private static constructMobileSettingState(data: AddonShareMobileData): AddonShareMobileState {
    return {
      alignment: data?.alignment ? data.alignment : AlignContentType.CENTER,
      ...(data.icon_background_color && { iconBackgroundColor: data.icon_background_color }),
      ...(data.icon_color && { iconColor: data.icon_color }),
      iconSize: data?.icon_size ? Number(data.icon_size) : 28,
      roundedButtons: data?.rounded_buttons ? data.rounded_buttons === '1' : true,
      ...(data.border_radius && {
        borderRadius: data.border_radius
      })
    };
  }

  private static constructSettingState(data: AddonShareSettingData): AddonShareSettingState {
    return {
      alignment: data.alignment ? data.alignment : AlignContentType.CENTER,
      ...(data.icon_background_color && { iconBackgroundColor: data.icon_background_color }),
      ...(data.icon_color && { iconColor: data.icon_color }),
      iconSize: data.icon_size ? Number(data.icon_size) : 28,
      roundedButtons: data.rounded_buttons ? data.rounded_buttons === '1' : true,
      ...(data.border_radius && {
        borderRadius: data.border_radius
      }),
      isFacebookActive: data.facebook ? data.facebook === '1' : false,
      facebookUrl: formatUrl(data.facebook_url) ?? '#',
      facebookOriginalUrl: data.facebook_url ? data.facebook_url : '#',
      isTwitterActive: data.twitter ? data.twitter === '1' : false,
      twitterUrl: formatUrl(data.twitter_url) ?? '#',
      twitterOriginalUrl: data.twitter_url ? data.twitter_url : '#',
      isLinkedinActive: data.linkedin ? data.linkedin === '1' : false,
      linkedinUrl: formatUrl(data.linkedin_url) ?? '#',
      linkedinOriginalUrl: data.linkedin_url ? data.linkedin_url : '#',
      isInstagramActive: data.instagram ? data.instagram === '1' : false,
      instagramUrl: formatUrl(data.instagram_url) ?? '#',
      instagramOriginalUrl: data.instagram_url ? data.instagram_url : '#',
      isYoutubeActive: data.youtube ? data.youtube === '1' : false,
      youtubeUrl: formatUrl(data.youtube_url) ?? '#',
      youtubeOriginalUrl: data.youtube_url ? data.youtube_url : '#',
      isVimeoActive: data.vimeo ? data.vimeo === '1' : false,
      vimeoUrl: formatUrl(data.vimeo_url) ?? '#',
      vimeoOriginalUrl: data.vimeo_url ? data.vimeo_url : '#',
      isPinterestActive: data.pinterest ? data.pinterest === '1' : false,
      pinterestUrl: formatUrl(data.pinterest_url) ?? '#',
      pinterestOriginalUrl: data.pinterest_url ? data.pinterest_url : '#',
      isWebsiteActive: data.website ? data.website === '1' : false,
      websiteUrl: formatUrl(data.website_url) ?? '#',
      websiteOriginalUrl: data.website_url ? data.website_url : '#',
      isTikTokActive: data.tiktok ? data.tiktok === '1' : false,
      tiktokUrl: formatUrl(data.tiktok_url) ?? '#',
      tiktokOriginalUrl: data.tiktok_url ? data.tiktok_url : '#',
      isOtherActive: data.other ? data.other === '1' : false,
      otherUrl: formatUrl(data.other_url) ?? '#',
      otherOriginalUrl: data.other_url ? data.other_url : '#',
      ...(data.other_image && {
        otherImageUrl: data.other_image
      }),
      otherIcon: data.other_icon
    };
  }

  public static getMobileDeviceData(data: AddonShareSettingData): AddonShareMobileData | undefined {
    const desktopData = data;

    const { isMobile } = useDevice();

    const overwriteMobile = typeof desktopData.overwrite_mobile === 'undefined' || desktopData.overwrite_mobile === '1';

    if (isMobile && !overwriteMobile && data.mobile) {
      return data.mobile;
    }

    return undefined;
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
