<template>
  <svg class="game-indicator-item__icon-path" width="46px" height="40px" viewBox="0 0 46 40">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        id="Path"
        d="M45,24.9 C44.8,24.5 44.4,24.4 44,24.5 L29.2,31.2 C28.7,30.4 27.9,29.9 27,29.7 L27,1.3 C27,0.9 26.7,0.5 26.2,0.5 C25.7,0.5 25.4,0.8 25.4,1.3 L25.4,29.8 C24.9,30 24.4,30.2 24,30.6 L2,17 C1.6,16.8 1.2,16.9 1,17.2 C0.8,17.6 0.9,18 1.2,18.2 L23.2,31.8 C23.1,32.2 23,32.6 23,33 C23,34.9 24.5,36.4 26.4,36.4 C27.4,36.4 28.2,36 28.8,35.3 L35.9,39.7 C36,39.8 36.2,39.8 36.3,39.8 C36.6,39.8 36.8,39.7 36.9,39.4 C37.1,39 37,38.6 36.7,38.4 L29.6,34 C29.7,33.7 29.8,33.3 29.8,33 C29.8,32.9 29.8,32.7 29.8,32.6 L44.7,25.9 C45,25.7 45.2,25.3 45,24.9 Z"
      />
      \
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TimeIndicator'
});
</script>
