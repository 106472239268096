import { Cache } from '@/src/services/cache';

interface AudioItem {
  buffer: AudioBuffer;
  source: AudioBufferSourceNode | null;
  isPlaying: boolean;
  src: string;
}

const getCollection = (): AudioItem[] => {
  let collection = Cache.get<AudioItem[]>('audioCollection');

  if (!collection || collection.length === 0) {
    collection = [];
    Cache.set('audioCollection', collection);
  }

  return collection;
};

const updateCollection = (collection: AudioItem[]) => {
  Cache.set<AudioItem[]>('audioCollection', collection);
};

const preloadSounds = async (sounds: string[]) => {
  const audioCtx = Cache.get<AudioContext>('audio-context');
  if (!audioCtx) {
    return;
  }

  const collection: AudioItem[] = await Promise.all(
    sounds.map(async (src) => {
      const response = await fetch(src);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer);
      return {
        buffer: audioBuffer,
        source: null,
        isPlaying: false,
        src
      };
    })
  );
  updateCollection(collection);
};

export const initAudio = async (sounds: string[]) => {
  if (Cache.get<boolean>('audio-listener-enabled')) {
    return;
  }

  Cache.set<boolean>('audio-listener-enabled', true);

  // @ts-ignore
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioCtx = new AudioContext();

  Cache.set('audio-context', audioCtx);

  await preloadSounds(sounds);

  // Resume audio context on first user interaction
  const resumeAudioContext = () => {
    if (audioCtx.state === 'suspended') {
      audioCtx.resume();
    }
    window.removeEventListener('click', resumeAudioContext);
    window.removeEventListener('touchstart', resumeAudioContext);
  };

  window.addEventListener('click', resumeAudioContext);
  window.addEventListener('touchstart', resumeAudioContext);
};

const ensureAudioBuffer = async (src: string, audioCtx: AudioContext): Promise<AudioBuffer | null> => {
  const collection = getCollection();
  let audioItem = collection.find((item) => item.src === src);

  if (!audioItem) {
    try {
      const response = await fetch(src);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer);

      audioItem = { buffer: audioBuffer, source: null, isPlaying: false, src };
      collection.push(audioItem);
      updateCollection(collection);
      return audioBuffer;
    } catch (error) {
      return null;
    }
  } else {
    return audioItem.buffer;
  }
};

export const playAudio = async (src: string) => {
  if (!Cache.get<boolean>('audio-listener-enabled')) {
    return;
  }

  const audioCtx = Cache.get<AudioContext>('audio-context');
  if (!audioCtx) {
    return;
  }

  const audioBuffer = await ensureAudioBuffer(src, audioCtx);
  if (!audioBuffer) {
    return;
  }

  if (audioCtx.state === 'suspended') {
    await audioCtx.resume();
  }

  const source = audioCtx.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(audioCtx.destination);
  source.onended = () => {
    source.disconnect();
  };

  source.start();
};

export const removeAudio = () => {
  if (!Cache.get<boolean>('audio-listener-enabled')) {
    return;
  }

  const collection: AudioItem[] = Cache.get<AudioItem[]>('audio-collection') || [];
  collection.forEach((item) => {
    if (item.source) {
      item.source.stop();
      item.source.disconnect();
      item.source = null;
    }
  });

  Cache.set('audio-collection', []);
  Cache.set('audio-context', null);
  Cache.set('audio-listener-enabled', false);
};
