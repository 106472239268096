export enum QuestionRedirects {
  GOTO_FLOWPAGE = 'goto_flow_page',
  SHOW_MESSAGE = 'show_message',
  POPOVER = 'popover'
}
export enum QuestionAnswers {
  ONE = 1,
  TWO,
  THREE,
  FOUR
}
export enum QuestionTransition {
  SLIDE_IN = 'slide_in',
  FADE = 'fade'
}
