<template>
  <svg class="game-indicator-item__icon-path" width="38" height="39" viewBox="0 0 1024 1024">
    <path
      d="M852.192 128h155.808c8.832 0 16-7.168 16-16s-7.168-16-16-16h-192c-8.832 0-16 7.168-16 16v192c0 8.832 7.168 16 16 16s16-7.168 16-16v-150.944l19.552 19.552c90.656 90.656 140.608 211.2 140.608 339.392s-49.952 248.736-140.608 339.392c-134.56 134.592-336.128 177.024-513.632 108-8.224-3.264-17.504 0.864-20.704 9.088-3.2 8.256 0.864 17.536 9.12 20.704 60.288 23.424 123.136 34.816 185.504 34.816 133.44 0 264.512-52.16 362.336-150.016 96.704-96.672 149.984-225.28 149.984-362.016s-53.28-265.312-149.984-361.984l-21.984-21.984zM208 704c-8.832 0-16 7.168-16 16v154.88l-22.816-22.816c-90.656-90.656-140.608-211.2-140.608-339.392s49.952-248.768 140.608-339.424c132.32-132.352 331.232-175.84 506.752-110.592 8.192 3.072 17.472-1.12 20.576-9.44 3.072-8.288-1.152-17.504-9.44-20.576-187.168-69.504-399.36-23.2-540.512 117.984-96.704 96.672-149.984 225.28-149.984 362.016s53.28 265.344 149.984 362.016l21.312 21.344h-151.872c-8.832 0-16 7.168-16 16s7.168 16 16 16h187.392c1.28 0.32 12.032-0.8 15.168-3.936 0.128-0.128 0.16-0.288 0.288-0.416 3.136-2.944 5.152-7.008 5.152-11.648v-192c0-8.832-7.168-16-16-16z"
    ></path>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RoundIndicator'
});
</script>
