import type { CSSProperties } from 'vue';
import type { AlignContentType, BorderType } from '@/src/typings/enums/enums';
import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export interface FormElementDividerSettingsData extends BaseFormElementSettingsData {
  divider?: {
    type?: BorderType;
    color?: string;
    weight?: string;
    width?: string;
    alignment?: AlignContentType;
  };
}

export interface FormElementDividerData extends BaseFormElementData {
  settings?: FormElementDividerSettingsData;
}

export interface FormElementDividerState extends BaseFormElementState<string> {
  divider?: {
    type?: BorderType;
    color?: string;
    weight?: string;
    width?: string;
    alignment?: AlignContentType;
  };
  elementStyling: CSSProperties;
}

export class FormElementDividerModel extends BaseFormElementModel<
  string,
  FormElementDividerData,
  FormElementDividerState
> {
  parseFormElement(data: FormElementDividerData) {
    if (data.settings?.divider) {
      this.state.divider = this.state.divider ?? {};
      this.state.divider.type = data.settings.divider.type;
      this.state.divider.color = data.settings.divider.color;
      this.state.divider.weight = data.settings.divider.weight;
      this.state.divider.width = data.settings.divider.width;
      this.state.divider.alignment = data.settings.divider.alignment;
    } else {
      this.state.divider = {};
    }

    this.state.elementStyling = FormElementDividerModel.constructDividerStyles(this.state);
  }

  private static constructDividerStyles(state: FormElementDividerState): CSSProperties {
    const divider = state.divider;
    return {
      width: state.divider?.width,
      borderWidth: '100%',
      ...(divider && {
        borderBottom: `${divider.weight}px ${divider.type} ${divider.color}`
      })
    };
  }

  getInitialValue(): undefined {
    return undefined;
  }

  parseStringValue(): undefined {
    return undefined;
  }

  getSerializedPostValue(): string {
    return this.state.value ?? '';
  }

  getStringifiedValue(): string | undefined {
    return this.state.value && this.state.value !== '' ? JSON.stringify(this.state.value) : undefined;
  }

  getSerializedCookieValue(): string {
    return this.state.value ?? '';
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
