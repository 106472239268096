import type { IntegrationDanskeSpilData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export interface IntegrationDanskeSpilState extends BaseIntegrationState {
  brand?: string;
}

export class IntegrationDanskeSpilModel extends BaseIntegration<IntegrationDanskeSpilData, IntegrationDanskeSpilState> {
  parse(data: IntegrationDanskeSpilData) {
    super.parse(data);

    if (data.settings) {
      this.state.brand = data.settings.ensigthen.brand;
    } else {
      this.state.brand = undefined;
    }
  }

  hasVueComponent() {
    return false;
  }

  authorSignature(): string {
    return 'Istvan Bovan';
  }
}
