import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export interface FormElementCvrSettingsData extends BaseFormElementSettingsData {
  cvr_name?: string;
}

export interface FormElementCvrData extends BaseFormElementData {
  settings?: FormElementCvrSettingsData;
}

export interface FormElementCvrValue {
  vat: string;
  name: string;
  cvr_name: string;
  productionunits?: {
    [key: string]: string;
  };
  error?: string;
  message?: string;
}

export interface FormElementCvrState extends BaseFormElementState<FormElementCvrValue> {
  cvrName?: string;
}

export class FormElementCVRModel extends BaseFormElementModel<
  FormElementCvrValue,
  FormElementCvrData,
  FormElementCvrState
> {
  parseFormElement(data: FormElementCvrData) {
    this.state.cvrName = data.settings?.cvr_name;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value ? `${this.state.value.vat}, ${this.state.value.name}` : undefined;
  }

  getInitialValue(): FormElementCvrValue | undefined {
    return this.parseStringValue(this.getInitialStringValue() ?? '');
  }

  parseStringValue(value: string): FormElementCvrValue | undefined {
    if (value && value[0] === '{') {
      return JSON.parse(value) as FormElementCvrValue;
    }

    return undefined;
  }

  getSerializedPostValue(): string {
    return typeof this.state.value !== 'undefined' ? JSON.stringify(this.state.value) : '';
  }

  getSerializedCookieValue(): string {
    return this.getSerializedPostValue();
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
