import type { Component } from 'vue';
import { BaseModel } from '@/src/models/BaseModel';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BaseIntegrationSettingsData {}

export interface BaseIntegrationData {
  id: number;
  namespace: string;
  settings: null | BaseIntegrationSettingsData;
}

export interface BaseIntegrationState {
  id: number;
  namespace: string;
}

export abstract class BaseIntegration<
  Data extends BaseIntegrationData,
  State extends BaseIntegrationState
> extends BaseModel<Data, State> {
  parse(data: Data) {
    this.state.namespace = data.namespace;
    this.state.id = data.id;
  }

  /**
   * Whether or not integration has a vue component attached.
   */
  abstract hasVueComponent(): boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getVueComponent(): Promise<Component> | void {}
}
