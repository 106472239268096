import type { CSSProperties } from 'vue';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonIframeGamesData } from '@/src/components/addons/iframe-games/Data';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';

interface AddonIframeGamesState {
  isReady: boolean;
  alias: string;
  alignment: string;
  search: string;
  showGameOnly: boolean;
  showScrollbars: boolean;
  responsiveHeight: boolean;
  width: string;
  fixedHeight?: number;
  fixedWidth?: number;
  minHeight?: number;
  advanced: AdvancedAddonsStyleData;
  gameId: number | null;
  gameName: string;
  gameUrlLive: string;
  gameUrlDemo: string;
  iframeClass: string;
  iframeScrolling: string;
  iframeStyling: CSSProperties;
}

export class AddonIFrameGamesModel extends AddonModel<AddonIframeGamesData, AddonIframeGamesState, 'iframe-games'> {
  parseAddon(data: AddonIframeGamesData): void {
    const defaultHeight = 300;
    const state = this.state;
    state.search = data.settings.search ?? '';
    state.showGameOnly = data.settings.show_game_only === '1';
    state.showScrollbars = data.settings.show_scrollbars === '1';
    state.responsiveHeight = data.settings.responsive_height === '1';
    state.iframeScrolling = 'no';

    if (data.settings.width) {
      state.width = data.settings.width;

      if (data.settings.width === 'fixed') {
        state.fixedWidth = Number(data.settings.fixed_width);
      }
    } else {
      state.width = 'content';
    }

    if (data.settings.fixed_height) {
      state.fixedHeight = Number(data.settings.fixed_height);
    }

    if (data.settings.min_height) {
      state.minHeight = Number(data.settings.min_height);
    } else {
      state.minHeight = defaultHeight;
    }

    if (data.settings.game_id) {
      state.gameId = Number(data.settings.game_id);
    }

    if (data.settings.game_name) {
      state.gameName = data.settings.game_name;
    } else {
      state.gameName = '';
    }

    if (data.settings.game_url) {
      state.gameUrlLive = data.settings.game_url;
    } else {
      state.gameUrlLive = '';
    }

    if (data.settings.game_url_demo) {
      state.gameUrlDemo = data.settings.game_url_demo;
    } else {
      state.gameUrlDemo = '';
    }

    state.iframeStyling = state.iframeStyling ?? {};

    if (state.showScrollbars) {
      state.iframeClass = 'iframe-game-wrapper--show-scrollbars';
      state.iframeScrolling = 'auto';
    }

    if (state.width && state.width === 'fixed') {
      state.iframeClass = 'iframe-game-wrapper--fixed-width';

      if (state.fixedWidth && state.fixedWidth !== 0) {
        state.iframeStyling.width = state.fixedWidth + 'px';
      }
    }

    if (state.minHeight) {
      state.iframeStyling.height = state.minHeight + 'px';
    }

    state.alignment = data.settings?.alignment || 'center';
    if (state.alignment) {
      state.iframeClass = `iframe-game-wrapper--align-${state.alignment}`;
    }

    if (state.width && state.width === 'window') {
      state.iframeClass = 'iframe-game-wrapper--full-width';
    }

    if (!state.responsiveHeight) {
      if (state.minHeight) {
        state.iframeStyling.minHeight = state.minHeight + 'px';
      }

      state.iframeStyling.height = state.fixedHeight + 'px';
    }
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    return !!data.settings.game_url;
  }
}
