import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export type FormElementFileExtensions = Array<
  | 'xlsx'
  | 'doc'
  | 'docx'
  | 'xls'
  | 'pdf'
  | 'eps'
  | 'svg'
  | 'psd'
  | 'png'
  | 'jpeg'
  | 'jpg'
  | 'gif'
  | 'ico'
  | 'txt'
  | 'mov'
  | 'mp4'
>;

export interface FormElementFileSettingsData extends BaseFormElementSettingsData {
  multiple?: string;
  extensions?: FormElementFileExtensions;
}

export interface FormElementFileData extends BaseFormElementData {
  settings?: FormElementFileSettingsData;
}

export interface FormElementFileValue {
  name: string;
  url: string;
}

export interface FormElementFileState extends BaseFormElementState<FormElementFileValue[]> {
  multiple?: boolean;
  extensions?: FormElementFileExtensions;
}

export class FormElementFileModel extends BaseFormElementModel<
  FormElementFileValue[],
  FormElementFileData,
  FormElementFileState
> {
  parseFormElement(data: FormElementFileData) {
    this.state.multiple = data.settings?.multiple === '1';
    this.state.extensions = data.settings?.extensions;
  }

  getInitialValue(): undefined {
    return undefined;
  }

  parseStringValue(): undefined {
    return undefined;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value ? this.state.value.map((item) => item.url + ',' + item.name).join('\n') : undefined;
  }

  getSerializedPostValue(): string {
    return (this.state.value ?? [])
      .map((file) => {
        return file.url;
      })
      .join(',');
  }

  getSerializedCookieValue(): string {
    return JSON.stringify(this.getSerializedPostValue());
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
