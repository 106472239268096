import type { GamePersonalityTestAnswerState, GamePersonalityTestQuestionState } from '../Model';
import { PersonalityTestAnswerType } from '../Model';

export class SdkPersonalityTestQuestionAnswerModel {
  public readonly id: number;
  public readonly value: string;
  public readonly description: string | undefined;
  public readonly percentageAnswered: number | undefined;

  constructor(questionState: GamePersonalityTestQuestionState, state: GamePersonalityTestAnswerState) {
    this.id = state.id;

    // Re-format the answer text for swipe questions as they're left/right instead of answer_1/answer_2
    if (questionState.answerType === PersonalityTestAnswerType.SWIPE) {
      this.value = state.text === 'answer_1' ? 'Left' : 'Right';
    } else {
      this.value = state.text;
    }

    this.description = state.description;
    this.percentageAnswered = state.percentage;
  }
}
