import type { CampaignModel } from '@/src/models/CampaignModel';
export async function signJws(campaignModel: CampaignModel, obj: object): Promise<string> {
  if (!campaignModel.state.config?.hash) {
    return Promise.reject(new Error('No hash found in campaign config.'));
  }

  const header = JSON.stringify({
    typ: 'JWT',
    alg: 'HS512'
  });

  const payload = encodeURIComponent(JSON.stringify(obj));

  const base64UrlHeader = base64UrlEncode(header);
  const base64Urlpayload = base64UrlEncode(payload);

  const signature = await hmac(campaignModel.state.config.hash, `${base64UrlHeader}.${base64Urlpayload}`);
  const base64UrlSignature = base64UrlEncode(signature);

  return `${base64UrlHeader}.${base64Urlpayload}.${base64UrlSignature}`;
}

function base64UrlEncode(value: string): string {
  return btoa(value).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

async function hmac(secret: string, data: string): Promise<string> {
  const enc = new window.TextEncoder();

  let subtle = window.crypto.subtle;

  // Before Safari 11.1, the subtle object was available under the webkitSubtle property.
  if (typeof subtle === 'undefined' && 'webkitSubtle' in window.crypto) {
    subtle = window.crypto.webkitSubtle as SubtleCrypto;
  }

  const key = await subtle.importKey(
    'raw',
    enc.encode(secret),
    {
      name: 'HMAC',
      hash: { name: 'SHA-512' }
    },
    false,
    ['sign']
  );

  const signature = await subtle.sign('HMAC', key, enc.encode(data));
  return String.fromCharCode(...new Uint8Array(signature));
}
