import type { GameIndicatorData } from '@/src/components/indicators/Model';
import type { DeviceData } from '@/src/hooks/useDevice';
import type { DrawType, GameActionType } from '@/src/typings/enums/enums';

export enum GameRouletteBallType {
  IMAGE = 'image',
  ICON = 'icon'
}

export enum GameRouletteInputPositionType {
  BELOW = 'below',
  ABOVE = 'above'
}

export interface GameRouletteInputLayoutData {
  font_type?: string;
  text_size?: string;
  width?: string;
  vertical_padding?: string;
  horizontal_padding?: string;
  background_color?: string;
  text_color?: string;
  border_color?: string;
  border_thickness?: string;
  corner_radius?: string;
}

export interface GameRouletteFieldItemData {
  action?: GameActionType;
  value: string;
}

export interface GameRouletteInputData {
  input_layout?: GameRouletteInputLayoutData;
  placement?: GameRouletteInputPositionType;
}

export interface GameRouletteIndicatorData {
  display_type?: GameRouletteBallType;
  image?: string;
  end_position?: string;
  start_position?: string;
  icon_color?: string;
  size?: string;
}
export interface GameRouletteWheelData {
  image?: string;
  fields_number?: string;
}

export interface GameRouletteVisualData {
  input_box: DeviceData<GameRouletteInputData>;
  indicator: DeviceData<GameRouletteIndicatorData>;
  wheel: DeviceData<GameRouletteWheelData>;
  fields: {
    [key: number]: GameRouletteFieldItemData;
  };
}

export interface GameRouletteGeneralData {
  spins: string;
  draw_type: DrawType;
  winner_chance?: string;
}

export interface GameRouletteData {
  general: GameRouletteGeneralData;
  visuals: GameRouletteVisualData;
  indicators: GameIndicatorData;
}
